#todo {
    overflow: hidden;
    height: 100%;
    min-height: 100%;
    .container {
        height: 100%;
    }
    .empty-state {
        width: 100%;
        height: 100%;
        text-align: center;
        margin: 24px 0;
        .title {
            color: $color_petronas_green;
        }
    }
    .search-wrapper {
        position: relative;
        .progress-linear {
            position: absolute;
            bottom: 0px;
            left: 0px;
            .md-mode-indeterminate {
                height: 3px;
            }
        }
        width: 100%;
        height: 48px;
        min-height: 48px;
        line-height: 48px;
        padding: 0 16px;
        background: white;
        margin-bottom: 8px;
        border-bottom: 1px solid rgba(0,0,0,.12);
        md-icon {
            margin-right: 8px;
        }
        .search-bar {
            md-autocomplete-wrap {
                box-shadow: none;
            }
        }
        .search-bar::-ms-clear {
            display: none !important;
        }
        input::-ms-clear {
            display: none !important;
        }
    }
    .view {
        height: 48px;
        min-height: 48px;
        line-height: 48px;
        text-align: right;
        span {
            margin-right: 16px;
        }
        md-icon {
            margin-right: 4px;
        }
        .selected {
            color: $color_petronas_green;
        }
    }
    .todo-list {
        overflow: auto;
        margin: 0;
        background: white;
        box-shadow: 0 -1px 2px rgba(0, 0, 0, .12),  0 0 2px rgba(0, 0, 0, .12), 0px 2px 4px rgba(0, 0, 0, .24);
        .header {
            height: 48px;
            min-height: 48px;
            line-height: 48px;
            align-items: center;
            padding: 0 128px 0 16px;
            border-bottom: 1px solid rgba(0,0,0,.12);
        }
        .header-by-me {
            padding-right: 128px;
        }
        .content {
            overflow-y: overlay;
            .topic {
                margin: 0 16px 8px 0;
            }
            .due-date {
                color: rgba(0,0,0,.54);
            }
            .single-list {
                position: relative;
                align-items: flex-start;
                md-checkbox {
                    .md-label {
                        display: block;
                        margin-left: 40px;
                    }
                }
                .todo-item {
                    padding: 16px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.12);    
                    .tag-container {
                        margin: 8px 0 0;
                        .tag {
                            cursor: pointer;
                            padding: 2px 4px;
                            margin-right: 8px;
                            background: rgba(0,0,0,.08);
                            border-radius: 4px;
                        }
                        
                    }
                    .topic {
                         p {
                            margin: 0px;
                            font-family: 'Museo Sans 700', "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
                            font-size: 1.6rem;
                        }
                    }
                }
                .pet-green {
                    color: $color_petronas_green;
                }
                .pet-green-disabled {
                    color: rgba($color_petronas_green, .38);
                }
                .done {
                    position: relative;
                    width: 104px;
                    text-align: center;
                }
                .done:hover:after {
                    content:'Undo?';
                    position: absolute;
                    width: 100%;
                    left: 0;
                    background: white;
                    font-family: 'Museo Sans 300',sans-serif;
                    font-size: 1.3rem;
                    color: $color_petronas_yellow;
                    cursor: pointer;
                }
            }
            .group-list {
                padding: 16px;
                .title {
                    font-weight: bold;
                    // margin-bottom: 8px;
                    padding-bottom: 10px;
                    cursor: pointer;
                    border-bottom: 1px solid rgba(0,0,0,.12);
                    color: rgba(0, 0, 0, 0.54);
                }
                .item {
                    display: flex;
                    align-items: flex-start;
                    padding: 10px 0 10px 15px;
                    border-bottom: 1px dotted rgba(0,0,0,.12);
                    .checked-completed {
                        width: 48px;
                        margin-top: 0;
                        margin-right: 16px;
                        text-align: center;
                        color: $color_petronas_green;
                    }
                    .topic {
                        display: flex;
                        p {
                            margin: 0px;
                            font-size: 1.6rem;
                        }
                    }
                }
                .item:last-of-type {
                    border-bottom: 2px solid rgba(0,0,0,.12);
                }
                .closed {
                    position: relative;
                    width: 104px;
                    text-align: center;
                }
                // .closed:hover:after {
                //     content:'Undo?';
                //     position: absolute;
                //     width: 100%;
                //     left: 0;
                //     background: white;
                //     color: $color_petronas_yellow;
                //     cursor: pointer;
                // }
            }
        }
        .md-button {
            margin-top: 0;
        }
        .width-104 {
            width: 104px;
        }
        .content::-webkit-scrollbar {
            width: 8px;
        }
        .content::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 1px solid slategrey;
        }
    }
}
#todo-new {
    .container{
        padding: 40px 0;
        .action-tabs{
            margin: 0 5%;
            border-bottom: 1px solid #E2E5ED;
            padding-bottom: 30px;
            .tab-item{
                box-sizing: border-box;
                height: 81px;
                width: 361px;
                border: 1px solid #00A19C;
                border-radius: 4px;
                background-color: #00A19C;
                box-shadow: 0 1px 2px 0 rgba(0,0,0,0.07);
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
                .tab-name{
                    color: #FFFFFF;
                    font-family: $font_Inter;
                    font-size: 20px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 32px;
                    margin-left: 9%;
                }
                .action-number{
                    height: 44px;
                    width: 44px;
                    background-color: #FFFFFF;
                    border-radius: 50%;
                    padding: 9px;
                    text-align: center;
                    margin-right: 4%;
                    span{
                        color: #00A19C;
                        font-family: $font_Inter;
                        font-size: 20px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 24px;
                        text-align: center;
                    }
                }
            }
        }
    }
}
@media(min-width:600px) {
    #todo {
        .view, .todo-list {
            width: 90%;
            margin: 0 auto;
        }
    }
}
@media(min-width:960px) {
    #todo {
        .container {
            padding: 24px 24px 0;
            .search-container{
                width: 90%;
                margin: 16px auto 24px;
                .search-wrapper {
                    width: 40%;
                    height: 40px;
                    min-height: 40px;
                    line-height: 40px;
                    align-self: flex-end;
                    margin: auto;
                    margin-right: 0;
                    border-bottom: none;
                    box-shadow: 0 -1px 2px rgba(0, 0, 0, .12),  0 0 2px rgba(0, 0, 0, .12), 0px 2px 4px rgba(0, 0, 0, .24);
                }
                .search-focus {
                    box-shadow: 0 -1px 2px rgba(0, 0, 0, .14), 0 0 4px rgba(0, 0, 0, .14), 0px 4px 8px rgba(0, 0, 0, .28);
                }
            }
            .assigned {
                width: 160px;
                max-width: 160px;
                margin-right: 16px;
            }
            .content {
                .topic {
                    margin: 0 16px 0 0;
                }
                .due-date {
                    width: 48px;
                    text-align: center;
                    margin-right: 16px;
                }
            }
        }
    }
    #todo-new{
        .container {
            padding: 24px 24px 0;
            .search-container{
                width: 90%;
                margin: 16px auto 24px;
                .search-wrapper {
                    width: 40%;
                    height: 40px;
                    min-height: 40px;
                    line-height: 40px;
                    align-self: flex-start;
                    margin: auto;
                    margin-left: 0;
                    border-bottom: none;
                    box-shadow: 0 -1px 2px rgba(0, 0, 0, .12),  0 0 2px rgba(0, 0, 0, .12), 0px 2px 4px rgba(0, 0, 0, .24);
                }
                .search-focus {
                    box-shadow: 0 -1px 2px rgba(0, 0, 0, .14), 0 0 4px rgba(0, 0, 0, .14), 0px 4px 8px rgba(0, 0, 0, .28);
                }
            }
            .assigned {
                width: 160px;
                max-width: 160px;
                margin-right: 16px;
            }
            .content {
                .topic {
                    margin: 0 16px 0 0;
                }
                .due-date {
                    width: 48px;
                    text-align: center;
                    margin-right: 16px;
                }
            }
        }
    }
}

// @media(max-width:415px) {
//     .group-list, .single-list{
//        padding: 16px 0px !important;
//         }
//   }

.todo-loader {
    svg path,
    svg rect {
      fill: #00A19C;
    }
    svg {
      width: 40px;
      height: 40px;
    }
    height: 50px;
    padding: 1em;
    margin: 0 auto 1em;
    display: inline-block;
    vertical-align: top;
    /*
      Set the color of the icon
    */
  }