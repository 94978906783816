#error-unsupport-domain {
  height: 100vh;
  width: 100vw;

  .content {
    .logo-box img {
      width: 140px;
      height: auto;
    }

    .title-box,
    .message-box {
      color: #e6e6e6;
    }

    .title-box {
      padding-top: 20px;
      font-size: 23px;
      font-weight: 500;
    }

    .message-box {
      padding-top: 5px;
      font-size: 16px;
    }

    .logout-button-box {
      padding-top: 20px;

      button {
        background-color: white !important;
        padding: 2px 20px !important;
      }

      button span {
        font-family: Roboto, sans-serif !important;
        font-weight: bolder;
        font-size: 11px;
      }
    }
  }
}
