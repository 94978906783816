$margin: 8px;
#new-toolbar {
    background-color: #ffffff;
    // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

    .progress-container {
        width: 5%;
        background-color: blue;
        .progress-icon {
            position: relative;
            width: 100%;
            float: left;
            height: $toolbarHeight;

            #toolbar-progress-icon {
                margin: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            }
        }
    }

    .nt-container {
        background-color: #ffffff;
        margin-left: 5%;
        margin-right: 5px;
        height: 64px;
        // box-shadow: 0 2px rgba($color: gray, $alpha: 0.01);

        .my-minutes {
            height: 20px;
            width: 156px;
            color: #000000;
            font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
                "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: $toolbarHeight;
        }

        .bell {
            background-color: red;
            text-align: end;
            line-height: $toolbarHeight;
            position: relative;
            // border-right: solid 1px #D8D8D8;
            margin-right: 20px;

            .badge {
                display: inline-block;
                width: 20px;
                height: 20px;
                border-radius: 10px;
                background-color: #ffc003;
                text-align: center;
                position: relative;
                left: -15px;
                top: -5px;

                span {
                    position: absolute;
                    top: 50%;
                    right: 50%;
                    transform: translate(50%, -50%);
                    color: #000000;
                }
            }
        }

        #menu-container {
            margin-right: 60px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 15px;
            .guide-container{
                height: 38px;
                border-right: 1px solid #D8D8D8;
                padding: 5px 10px;
            }
            #menu-btn {
                height: 38px;
                .text {
                    margin-right: 8px;
                    margin-left: $margin;
                    min-width: 60px;
                    .title {
                        color: #3e3f42;
                        font-family: $font_Inter;
                        font-size: 14px;
                        font-weight: 600;
                        letter-spacing: 0;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        line-height: 19px;
                    }

                    .content {
                        opacity: 0.75;
                        color: #4b4b4b;
                        font-family: $font_Inter;
                        font-size: 11px;
                        letter-spacing: 0;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        line-height: 14px;
                    }
                }
            }

            #menu {
                position: absolute;
                background-color: white;
                width: 100%;
                left: 0;
                top: -100px;
                padding-top: 20px;
                box-shadow: 0 1px 10px 0 rgba(0,0,0,.12);
                .md-button {
                    width: 100%;
                    text-align: left;
                    margin: 0;
                }
            }

            .avatar-wrapper {
                img {
                    width: 36px;
                    height: 36px;
                }
                position: relative;

                .status {
                    position: absolute;
                    bottom: -3px;
                    right: 5px;
                }
                .image {
                    width: 36px;
                    min-width: 36px;
                    max-width: 36px;
                    line-height: 36px;
                    margin: 0;
                    border-radius: 50%;
                    text-align: center;
                    color: white;
                    background-color: $color_petronas_purple;
                }
            }
        }
    }
}
.btn-logout {
    > span {
        margin-left: $margin !important;
    }
}

.tolowercase {
    text-transform: lowercase;
}
