#vertical-navigation {
    width: $navigationWidth;
    min-width: $navigationWidth;
    max-width: $navigationWidth;
    overflow-x: hidden;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2), width 0.1s linear, min-width 0.1s linear,
        max-width 0.1s linear;
    box-shadow: $whiteframe-shadow-6dp;

    .navigation-header {
        height: 64px;
        min-height: 64px;
        background-color: #000000 !important;
        padding: 0 16px 0 24px;

        .logo {
            min-width: 36px !important;
            .logo-image {
                margin: 0 2px 0 0;
                img {
                    width: 100% !important;
                    height: auto;
                    object-fit: cover;
                }
                display: block;
                width: 28.69px !important;
                height: 35.54px !important;
                line-height: 54px;
                font-size: 16px;
                font-weight: 500;
                border-radius: 2px;
            }
            .logo-text{
                font-weight: bold;
                color: white;
            }
        }

        .fold-toggle {
            transition: transform 0.3s ease-in-out 0.1s;
            transform: rotate(0deg);
            margin: 0;
            padding: 8px;
            width: 34px !important;
            height: 34px !important;
            cursor: pointer;
        }
    }

    &:not(.md-locked-open) {
        box-shadow: $whiteframe-shadow-8dp;
    }

    background-color: #171717 !important;
}

// Folded navigation
@media only screen and (min-width: $layout-breakpoint-sm) {
    .ms-navigation-folded {
        #content-container {
            margin-left: $navigationFoldedWidth;
        }

        #vertical-navigation {
            position: absolute;

            .navigation-header {
                .fold-toggle {
                    transform: rotate(180deg);
                    opacity: 0;
                }
            }
        }

        &.ms-navigation-folded-open {
            #vertical-navigation {
                .navigation-header {
                    .fold-toggle {
                        transition: transform 0.3s ease-in-out 0.1s, opacity 0.3s ease-in-out 0.2s;
                        opacity: 1;
                    }
                }
            }
        }

        &:not(.ms-navigation-folded-open) {
            #vertical-navigation {
                position: absolute;
                width: $navigationFoldedWidth;
                min-width: $navigationFoldedWidth;
                max-width: $navigationFoldedWidth;

                .navigation-header {
                    padding: 0 16px;

                    .logo-text {
                        display: none;
                    }
                }
            }
        }
    }
}


