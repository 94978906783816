/*----------------------------------------------------------------*/

/*  ms-navigation
/*----------------------------------------------------------------*/
.md-autocomplete-suggestions {
  // padding-left: 16px;
}
.ms-navigation {
  display: block;
  position: relative;
  color: rgba(255, 255, 255, 0.7);
  user-select: none;
  ul,
  li {
    padding: 0;
    margin: 0;
  }
  ul {
    display: block;
    overflow: hidden;
    li {
      position: relative;
      &.collapsed {
        ul {
          display: none;
        }
      }
      &.tag-header {
        padding: 24px 0 0 24px;
        height: 18px;
        width: 29px;
        color: #9ea0a5;
        font-family: $font_Inter;

        font-size: 11px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 18px;
        margin-bottom: 8px;
      }
      &.tag-section {
        padding: 12px 0;
        md-chips-wrap {
          margin: 4px 24px;
          padding: 0;
          md-chip {
            background-color: #333333;
            .search-tagname {
              font-size: 12px;
              color: #fff;
              letter-spacing: 0;
              line-height: 16px;
            }
          }
          font-family: "Inter Medium", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue",
            Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          font-size: 12px;
        }
        md-autocomplete input::-webkit-input-placeholder {
          color: #999999;
          font-family: $font_Inter;
          font-weight: 400;
          font-size: 13px;
          letter-spacing: 0;
          line-height: 16px;
      }
      md-autocomplete input:-moz-placeholder { /* Firefox 18- */
        color: #999999;
        font-family: $font_Inter;
        font-weight: 400;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 12px;
    }
    
    md-autocomplete input::-moz-placeholder {  /* Firefox 19+ */
        color: #999999;
        font-family: $font_Inter;
        font-weight: 400;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 12px;
    }
    
    md-autocomplete input:-ms-input-placeholder { 
        color: #999999;
        font-family: $font_Inter;
        font-weight: 400;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 12px;
    }
        md-icon {
          color: rgba(255, 255, 255, 0.7);
          &.icon-information {
            padding-left: 8px;
          }
        }
      }
      .tag-list {
        color: rgba(255, 255, 255, 0.7);
        padding-left: 12px;
        margin-bottom: 8px;
      }
      .search-box {
        margin: auto;
        margin-top: 8px;
        margin-bottom: 8px;
        color: #999999;
        font-size: 12px;
        line-height: 12px;

        box-sizing: border-box;
        height: 36px;
        width: 140px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        background-color: #ffffff;

        md-autocomplete-wrap {
          box-shadow: none;
          height: 100%;
        }

        button {
          margin-top: 2.5px !important;
        }
        input {
          height: 100%;
          padding: 8px;
          width: 100%;
          border-radius: 4px;
          background-color: transparent !important;
        }
      }

      .md-chip-content {
        padding: 0px 12px;
        md-chip-template {
          span {
            font-size: 10px;
          }
        }
      }
      .md-chip-input-container {
        display: none;
      }
      md-chip {
        background-color: rgba(255, 255, 255, 0.05);
        border: 1px solid rgba(255, 255, 255, 0.24);
        color: rgba(255, 255, 255, 0.5);
        margin: 0 8px 8px 0;
        padding: 0;
        md-icon {
          margin-left: 3px;
          height: 14px !important;
          width: 14px !important;
          min-height: 14px;
          min-width: 14px;
        }
      }
      md-chip:hover {
        cursor: pointer;
        border: 1px solid $color_petronas_green;
        color: $color_petronas_green;
      }
      .md-chip-remove-container {
        line-height: 14px; // right: 6px;
        .md-chip-remove {
          top: 8px;
          right: 6px;
          height: 14px;
          width: 14px;
        }
      }
    }
  }
  .create-button {
    display: flex;
    justify-content: center;
    padding: 16px 16px 0px;
    .md-button {
      width: 100%;
    }
    margin-top: 10px;
    margin-bottom: 10px;

    .button-green {
      color: #ffffff;
      font-family: $font_Inter;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 15px;
      text-transform: none;
      span {
        font-size: 12px;
      }
    }
  } // .parent:nth-child(1) {
  //     li:nth-child(1) {
  //        display: none;
  //     }
  // }
  .parent:nth-child(2) {
    display: none;
  }
  .animate-height {
    transition: height 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .ms-navigation-node {
    .ms-navigation-item {
      display: block;
      position: relative;
      cursor: pointer;
      color: rgba(255, 255, 255, 0.7);
      &:hover {
        // background: rgba(0, 0, 0, 0.1);
        background-color: rgba(0, 161, 156, 0.05);
      } // Non-link & Link Common
      > .ms-navigation-button {
        display: flex;
        position: relative;
        width: 100%;
        height: 48px;
        line-height: 48px;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding-right: 16px;
        color: rgba(255, 255, 255, 0.7);
        text-decoration: none;
        white-space: nowrap;
        overflow: hidden;
        .icon {
          color: #6d7278;
          margin: 0 16px 0 0;
        } // Non-link
        span {
          white-space: nowrap;
          overflow: hidden;
          opacity: 1;
          transition: opacity 0.25s ease-in-out 0.1s;
        } // Link

        a {
          white-space: nowrap;
          overflow: hidden;
          opacity: 1;
          transition: opacity 0.25s ease-in-out 0.1s;
        } // Arrow
        .arrow {
          margin: 0;
          opacity: 1;
          transition: transform 0.3s ease-in-out, opacity 0.25s ease-in-out 0.1s;
        } // Badge
        .badge {
          position: absolute;
          top: 50%;
          right: 16px;
          min-width: 20px;
          height: 20px;
          line-height: 20px;
          padding: 0 7px;
          font-size: 11px;
          font-weight: bold;
          border-radius: 20px;
          transform: translateY(-50%);
          transition: opacity 0.2s ease-in-out 0.1s;
        }
        .border {
          position: absolute;
          top: 0;
          left: 0;
          width: 3px;
          height: 100%;
          background-color: transparent;
        }
        &.active {
          color: #ffffff;
          background-color: rgba(0, 161, 156, 0.05) !important;

          .border {
            background-color: #00a19c;
          }
          .icon {
            color: #00a19c;
          }
          .badge {
            background: #ffffff !important;
            color: rgba(0, 0, 0, 0.87) !important;
          }
          .img-icon {
            display: none;
          }
          .img-icon-active {
            display: block;
          }
        }
        .img-icon-active {
          display: none;
        }
        .title {
          color: #ffffff;
          font-family: "Inter Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
            "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          font-size: 13px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 22px;
        }
        .title-nav {
          height: 18px;
          width: 68px;
          color: #9ea0a5;
          font-family: "Inter Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
            "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          font-size: 11px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 18px;
        }

        .img-icon,
        .img-icon-active {
          width: auto;
          height: 14px;
          margin-right: 16px;
          margin-left: 2px;
        }
      }
    } // Arrow on expanded node
    &:not(.collapsed) {
      > .ms-navigation-item {
        > .ms-navigation-button {
          .arrow {
            transform: rotate(90deg);
          }
        }
      }
    } // Group
    &.group {
      > .ms-navigation-item {
        font-size: 12px;
        font-weight: 500;
        margin-top: 8px;
        cursor: auto;
        background: none !important;
        text-transform: uppercase;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        > .ms-navigation-button {
          height: 32px;
          line-height: 32px;
          color: rgba(255, 255, 255, 0.3);
        }
      }
    } // Background color on expanded node
    &:not(.group).has-children {
      &:not(.collapsed) {
        background-color: rgba(0, 0, 0, 0.12);
      }
      &.collapsing {
        background-color: rgba(0, 0, 0, 0.12);
      }
      .has-children {
        &:not(.collapsed) {
          background: none;
        }
      }
    }
  } // Level 1
  li {
    .ms-navigation-button {
      padding-left: 24px;
    } // Level 2
    li {
      .ms-navigation-button {
        padding-left: 56px;
      } // Level 3
      li {
        .ms-navigation-button {
          padding-left: 72px;
        } // Level 4
        li {
          .ms-navigation-button {
            padding-left: 88px;
          } // Level 5
          li {
            .ms-navigation-button {
              padding-left: 104px;
            }
          }
        }
      }
    }
  } // Level 1 (group-title)
  li.group {
    .ms-navigation-button {
      padding-left: 24px;
    } // Level 2
    li {
      .ms-navigation-button {
        padding-left: 24px;
      } // Level 3
      li {
        .ms-navigation-button {
          padding-left: 56px;
        } // Level 4
        li {
          .ms-navigation-button {
            padding-left: 72px;
          } // Level 5
          li {
            .ms-navigation-button {
              padding-left: 88px;
            }
          }
        }
      }
    }
  }
}

// Horizontal navigation
#horizontal-navigation {
  background: #ffffff;
  z-index: 54;
  .ms-navigation-horizontal {
    display: flex;
    position: relative;
    user-select: none;
    ul,
    li {
      padding: 0;
      margin: 0;
      list-style: none;
    } // First <ul>
    .horizontal {
      display: flex;
      flex: 1;
      li {
        position: relative;
        cursor: pointer;
        &:not(.active):hover {
          background: rgba(0, 0, 0, 0.12);
        }
      } // Root menu items
      > li {
        > .ms-navigation-horizontal-item {
          > .ms-navigation-horizontal-button {
            padding: 20px 24px;
            .arrow {
              display: none;
            }
          }
        }
        &:last-child {
          margin-right: 0;
        }
      } // Every menu item that has children
      .has-children {
        ul {
          display: none;
          position: absolute;
          background: #ffffff;
          z-index: 999;
          min-width: 200px;
          box-shadow: $whiteframe-shadow-8dp;
          top: 0;
          left: 100%;
        }
        &:hover {
          > ul {
            display: block;
          }
        }
      } // Root menu items that has children
      > .has-children {
        > ul {
          top: 100%;
          left: 0;
        }
      }
      .ms-navigation-horizontal-item {
        .ms-navigation-horizontal-button {
          display: flex;
          align-items: center;
          width: 100%;
          color: rgba(0, 0, 0, 0.87);
          white-space: nowrap;
          padding: 14px 16px;
          text-decoration: none;
          .icon {
            color: rgba(0, 0, 0, 0.54);
            margin-right: 8px;
          } // Badge
          .badge {
            min-width: 20px;
            height: 20px;
            line-height: 20px;
            padding: 0 7px;
            font-size: 11px;
            font-weight: bold;
            border-radius: 20px;
            transition: opacity 0.2s ease-in-out 0.1s;
          }
          .arrow {
            margin-left: 8px;
          }
          &.active {
            color: #ffffff;
            .badge {
              background: #ffffff !important;
              color: rgba(0, 0, 0, 0.87) !important;
            }
            .icon {
              color: #ffffff !important;
            }
          }
        }
      }
    }
  }
}

// Folded navigation
@media only screen and (min-width: $layout-breakpoint-sm) {
  body {
    &.ms-navigation-folded {
      &:not(.ms-navigation-folded-open) {
        .tag-header,
        .tag-section,
        .create-button {
          display: none;
        }
        .ms-navigation {
          .animate-height {
            transition: none;
          }
          .ms-navigation-node {
            .ms-navigation-item {
              .ms-navigation-button {
                .icon {
                  margin: 0;
                }
                .title,
                .arrow,
                .badge {
                  opacity: 0;
                  visibility: hidden;
                }
              }
            }
            &.group {
              > .ms-navigation-item {
                .ms-navigation-button {
                  &:before {
                    content: "";
                    display: block;
                    position: relative;
                    width: 16px;
                    border-top: 1px solid rgba(255, 255, 255, 0.12);
                  }
                }
              }
            }
          }
        }
        #ms-navigation-fold-expander {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 999;
        }
      }
      #ms-navigation-fold-collapser {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 59;
      }
    }
  }
}

// Mobile horizontal navigation
@media only screen and (max-width: $layout-breakpoint-sm) {
  body {
    &.ms-navigation-horizontal-mobile-menu-active {
      #horizontal-navigation {
        .navigation-toggle {
          height: $toolbarHeight;
          margin-right: 16px;
          .md-button {
            margin: 0;
            width: $toolbarHeight;
            height: $toolbarHeight;
          }
        }
        .ms-navigation-horizontal {
          display: block !important;
          background: #ffffff;
        }
      }
    }
  }
  #horizontal-navigation {
    z-index: 60;
    .ms-navigation-horizontal {
      position: absolute;
      display: none;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 0 0 16px 0;
      z-index: 9998;
      overflow-x: hidden;
      overflow-y: scroll;
      .horizontal {
        flex-direction: column;
        min-height: 100%;
        width: 100%;
        li {
          &:not(.active) {
            &:hover {
              background: none;
            }
            .ms-navigation-horizontal-item {
              margin: 4px;
              &:hover {
                background: rgba(0, 0, 0, 0.12);
              }
            }
          }
        }
        > li {
          padding: 0 12px;
          > .ms-navigation-horizontal-item {
            > .ms-navigation-horizontal-button {
              .arrow {
                display: block;
              }
            }
          }
        } // Every menu item that has children
        .has-children {
          ul {
            position: relative;
            top: 0;
            left: 0;
            box-shadow: none;
          }
          &:hover {
            > ul {
              display: none;
            }
          }
          .expanded {
            > .ms-navigation-horizontal-button {
              .arrow {
                transform: rotate(90deg);
              }
            }
            + ul {
              display: block;
              margin-left: 16px;
            }
          }
        }
        .ms-navigation-horizontal-item {
          min-height: 48px;
          height: 48px;
        }
      }
    }
  }
}

.help {
  // margin-left: 24px;
  // margin-top: 40px;
  margin-bottom: 40px;
  margin-top: 20px;
  .help-link {
    :link {
      text-decoration: none;
    }

    :visited {
      text-decoration: none;
    }

    &:hover {
      cursor: pointer;
      text-decoration: none;
    }

    :active {
      text-decoration: none !important;
    }

    div {
      display: flex;
      position: relative;
      width: 100%;
      height: 48px;
      line-height: 48px;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding-right: 16px;
      color: rgba(255, 255, 255, 0.7);
      text-decoration: none;
      white-space: nowrap;
      overflow: hidden;
      padding-left: 26px;

      .icon-help {
        margin: 0 16px 0 0;
        width: auto;
        height: 14px;
      }

      span {
        color: #ffffff;
        font-family: $font_Inter;

        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 22px;
      }
    }
  }
}
