@charset "UTF-8";
@import url("https://rsms.me/inter/inter.css");
body {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

/******************************** Font ********************************/
/*#region Fonts*/
@font-face {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  src: url("/assets/icons/fonts/MuseoSans_300.otf") format("opentype");
}
@font-face {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  src: url("/assets/icons/fonts/MuseoSans_700.otf") format("opentype");
}
@font-face {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  src: url("/assets/icons/fonts/MuseoSans_900.otf") format("opentype");
}
.fr-view strong {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.fr-view strong em,
.fr-view strong u {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

* {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.4rem;
}

@media (min-width: 960px) {
  * {
    font-size: 1.3rem;
  }
}
.mb-24 {
  margin-bottom: 24px;
}

input {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.museo-700 {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.museo-900 {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.font-12 {
  font-size: 1.2rem;
}

.font-16 {
  font-size: 1.6rem;
}

.font-20 {
  font-size: 2rem;
}

.font-24 {
  font-size: 2.4rem;
}

.font-36 {
  font-size: 3.6rem;
}

.font-48 {
  font-size: 4.8rem;
}

.font-56 {
  font-size: 5.6rem;
}

.uppercase {
  text-transform: uppercase;
}

.pet-green {
  color: #00a19c;
}

.pet-yellow {
  color: #fdb924;
}

.pet-blue {
  color: #94bde5;
}

.pet-red {
  color: #d9272c;
}

.blue {
  color: #4286f5;
}

.green {
  color: #34a853;
}

.grey {
  color: rgba(0, 0, 0, 0.54);
}

.white {
  color: white;
}

.line-through {
  text-decoration: line-through;
}

.text-right {
  text-align: right;
}

/******************************** End of Font ********************************/
/******************************** Background ********************************/
.back-pet-green {
  background: #00a19c;
}

.back-pet-violet {
  background: #68468b;
}

.back-pet-purple {
  background: #402b53;
}

.back-pet-yellow {
  background: #fdb924;
}

.back-pet-blue {
  background: #94bde5;
}

.back-blue {
  background: #4caf50;
}

.back-green {
  background: #34a853;
}

.back-grey {
  background: #9e9e9e;
}

.back-light-grey {
  background: rgba(0, 0, 0, 0.04);
}

.back-apprpoved {
  background: #4caf50;
}

.back-review-in-progress {
  background: #0b69ff;
}

.back-draft {
  background: #9e9e9e;
}

.back-pending-approval {
  background: #fdb924;
}

/******************************** End of background ********************************/
/******************************** Grid ********************************/
@media (min-width: 0px) {
  .col-mob-1 {
    width: 25%;
  }
  .col-mob-2 {
    width: 50%;
  }
  .col-mob-3 {
    width: 75%;
  }
  .col-mob-4 {
    width: 100%;
  }
}
@media (min-width: 600px) {
  .col-tab-1 {
    width: 12.5%;
  }
  .col-tab-2 {
    width: 25%;
  }
  .col-tab-3 {
    width: 37.5%;
  }
  .col-tab-4 {
    width: 50%;
  }
  .col-tab-5 {
    width: 62.5%;
  }
  .col-tab-6 {
    width: 75%;
  }
  .col-tab-7 {
    width: 87.5%;
  }
  .col-tab-8 {
    width: 100%;
  }
}
@media (min-width: 960px) {
  .col-com-1 {
    width: 8.333333%;
  }
  .col-com-2 {
    width: 16.666667%;
  }
  .col-com-3 {
    width: 25%;
  }
  .col-com-4 {
    width: 33.333333%;
  }
  .col-com-5 {
    width: 41.666667%;
  }
  .col-com-6 {
    width: 50%;
  }
  .col-com-7 {
    width: 58.333333%;
  }
  .col-com-8 {
    width: 66.666667%;
  }
  .col-com-9 {
    width: 75%;
  }
  .col-com-10 {
    width: 83.333333%;
  }
  .col-com-11 {
    width: 91.666667%;
  }
  .col-com-12 {
    width: 100%;
  }
}
@media (min-width: 1280px) {
  .col-lcom-1 {
    width: 8.333333%;
  }
  .col-lcom-2 {
    width: 16.666667%;
  }
  .col-lcom-3 {
    width: 25%;
  }
  .col-lcom-4 {
    width: 33.333333%;
  }
  .col-lcom-5 {
    width: 41.666667%;
  }
  .col-lcom-6 {
    width: 50%;
  }
  .col-lcom-7 {
    width: 58.333333%;
  }
  .col-lcom-8 {
    width: 66.666667%;
  }
  .col-lcom-9 {
    width: 75%;
  }
  .col-lcom-10 {
    width: 83.333333%;
  }
  .col-com-11 {
    width: 91.666667%;
  }
  .col-com-12 {
    width: 100%;
  }
}
/******************************** End of Grid ********************************/
.icon-green {
  color: #00a19c !important;
  font-size: 48px !important;
  width: 48px !important;
  height: 48px !important;
  line-height: 48px !important;
}

.icon-red {
  color: #d9272c !important;
  font-size: 48px !important;
  width: 48px !important;
  height: 48px !important;
}

/******************************** Button ********************************/
.md-button {
  padding: 0 8px !important;
}
.md-button md-icon {
  margin-right: 8px;
}

.md-button.md-icon-button md-icon {
  margin: auto;
}

.button-green {
  background-color: #00a19c !important;
  color: white !important;
}
.button-green md-icon {
  color: white;
}

.button-green:hover,
.button-green:focus {
  background-color: #009f98 !important;
  color: white !important;
}

.button-green[disabled],
.button-green[disabled]:hover {
  color: white !important;
  background-color: #00a19c !important;
  opacity: 0.38;
  cursor: not-allowed;
}

.button-violet {
  background-color: #68468b !important;
  color: white !important;
}

.button-violet:hover,
.button-violet:focus {
  background-color: #5d3f7d !important;
  color: white !important;
}

.button-violet[disabled],
.button-violet[disabled]:hover {
  color: white !important;
  background-color: #68468b !important;
  opacity: 0.38;
  cursor: not-allowed;
}

.md-button.md-primary.md-raised {
  background-color: #3c3835 !important;
}

.md-button.md-default-theme.md-accent.md-raised,
.md-button.md-accent.md-raised {
  background-color: #00a19c !important;
}

.md-button.md-default-theme.md-raised,
.md-button.md-raised {
  background-color: rgb(245, 245, 245);
}

.md-button.md-raised {
  background-color: #ededed;
}

[md-theme=default] .md-accent-bg {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

md-chips md-chip.md-focused {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

md-checkbox.md-default-theme.md-checked .md-ink-ripple,
md-checkbox.md-checked .md-ink-ripple {
  color: #00a19c !important;
}

md-checkbox.md-default-theme.md-checked .md-icon,
md-checkbox.md-checked .md-icon {
  background-color: #00a19c !important;
}

.select-none {
  border: 1px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
}

.select-type-discussion {
  border: 1px solid #D8DCE6;
  border-radius: 4px;
  height: 36px;
}
.select-type-discussion .md-select-value {
  padding: 5px;
  height: 22px;
  width: 51.7px;
  color: #3E3F42;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  min-height: auto;
}

.select-yellow,
.select-grey,
.select-violet {
  padding-left: 8px;
  border: none !important;
  border-radius: 15px;
}

.select-yellow .md-select-value,
.select-grey .md-select-value,
.select-violet .md-select-value {
  padding-bottom: 2px !important;
  color: white !important;
}

.select-yellow .md-select-icon,
.select-grey .md-select-icon,
.select-violet .md-select-icon {
  width: auto !important;
}

.select-yellow {
  background: #fdb924;
}

.select-grey {
  background: rgba(0, 0, 0, 0.54);
}

.select-violet {
  background: #68468b;
}

.btn:disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.btn-border-green {
  box-sizing: border-box;
  border: 1px solid #00a19c;
  border-radius: 4px;
  box-shadow: none;
  color: #00a19c;
  background-color: #ffffff;
}
.btn-border-green:hover {
  cursor: pointer;
}

/******************************** End of Button ********************************/
md-card {
  background: white !important;
}

md-input-container.md-default-theme:not(.md-input-focused):not(.md-input-invalid) label.md-required:after,
md-input-container:not(.md-input-focused):not(.md-input-invalid) label.md-required:after {
  color: rgba(0, 0, 0, 0.54);
}

md-input-container label:not(.md-container-ignore).md-required:after {
  content: " *";
  font-size: 13px;
  vertical-align: top;
}

md-input-container.md-default-theme label.md-required:after,
md-input-container label.md-required:after {
  color: rgb(221, 44, 0);
}

md-input-container.md-input-invalid label {
  color: rgb(221, 44, 0);
}

md-input-container input:-ms-input-placeholder {
  color: #999 !important;
}

md-chips.md-default-theme .md-chips .md-chip-input-container input, md-chips .md-chips .md-chip-input-container input {
  color: #000000;
}

md-tooltip .md-content {
  height: auto !important;
}
md-tooltip .md-content img {
  padding: 8px 0;
}

md-icon.icon-logout {
  position: relative !important;
  top: 0px !important;
  left: 0px !important;
}

.logo:hover {
  cursor: pointer;
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

/**
 *  Do not remove this comments bellow. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
/**
 *  Do not remove this comments bellow. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
html, body {
  height: 100%;
  position: relative;
}

body {
  margin: 0;
  padding: 0;
}

[tabindex="-1"]:focus {
  outline: none;
}

.inset {
  padding: 10px;
}

a.md-no-style,
button.md-no-style {
  font-weight: normal;
  background-color: inherit;
  text-align: left;
  border: none;
  padding: 0;
  margin: 0;
}

select,
button,
textarea,
input {
  vertical-align: baseline;
}

input[type=reset],
input[type=submit],
html input[type=button],
button {
  cursor: pointer;
  -webkit-appearance: button;
}
input[type=reset][disabled],
input[type=submit][disabled],
html input[type=button][disabled],
button[disabled] {
  cursor: default;
}

textarea {
  vertical-align: top;
  overflow: auto;
}

input[type=search] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
  -webkit-box-sizing: content-box;
}
input[type=search]::-webkit-search-decoration, input[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
input:-webkit-autofill {
  text-shadow: none;
}

.md-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  text-transform: none;
  width: 1px;
}

.md-shadow {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: inherit;
  pointer-events: none;
}

.md-shadow-bottom-z-1 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.md-shadow-bottom-z-2 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
}

.md-shadow-animated.md-shadow {
  transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}

/*
 * A container inside of a rippling element (eg a button),
 * which contains all of the individual ripples
 */
.md-ripple-container {
  pointer-events: none;
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.55s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.md-ripple {
  position: absolute;
  transform: translate(-50%, -50%) scale(0);
  transform-origin: 50% 50%;
  opacity: 0;
  border-radius: 50%;
}
.md-ripple.md-ripple-placed {
  transition: margin 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), border 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), width 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), height 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), opacity 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.9s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.md-ripple.md-ripple-scaled {
  transform: translate(-50%, -50%) scale(1);
}
.md-ripple.md-ripple-active, .md-ripple.md-ripple-full, .md-ripple.md-ripple-visible {
  opacity: 0.2;
}
.md-ripple.md-ripple-remove {
  animation: md-remove-ripple 0.9s cubic-bezier(0.25, 0.8, 0.25, 1);
}

@keyframes md-remove-ripple {
  0% {
    opacity: 0.15;
  }
  100% {
    opacity: 0;
  }
}
.md-padding {
  padding: 8px;
}

.md-margin {
  margin: 8px;
}

.md-scroll-mask {
  position: absolute;
  background-color: transparent;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
}
.md-scroll-mask > .md-scroll-mask-bar {
  display: block;
  position: absolute;
  background-color: #fafafa;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 65;
  box-shadow: inset 0px 0px 1px rgba(0, 0, 0, 0.3);
}

.md-no-momentum {
  -webkit-overflow-scrolling: auto;
}

.md-no-flicker {
  -webkit-filter: blur(0px);
}

@media (min-width: 960px) {
  .md-padding {
    padding: 16px;
  }
}
html[dir=rtl], html[dir=ltr], body[dir=rtl], body[dir=ltr] {
  unicode-bidi: embed;
}

bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

html, body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/************
 * Headings
 ************/
.md-display-4 {
  font-size: 112px;
  font-weight: 300;
  letter-spacing: -0.01em;
  line-height: 112px;
}

.md-display-3 {
  font-size: 56px;
  font-weight: 400;
  letter-spacing: -0.005em;
  line-height: 56px;
}

.md-display-2 {
  font-size: 45px;
  font-weight: 400;
  line-height: 64px;
}

.md-display-1 {
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
}

.md-headline {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
}

.md-title {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.005em;
}

.md-subhead {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 24px;
}

/************
 * Body Copy
 ************/
.md-body-1 {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 20px;
}

.md-body-2 {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.01em;
  line-height: 24px;
}

.md-caption {
  font-size: 12px;
  letter-spacing: 0.02em;
}

.md-button {
  letter-spacing: 0.01em;
}

/************
 * Defaults
 ************/
button,
select,
html,
textarea,
input {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

select,
button,
textarea,
input {
  font-size: 100%;
}

/*
*
*  Responsive attributes
*
*  References:
*  1) https://scotch.io/tutorials/a-visual-guide-to-css3-flexbox-properties#flex
*  2) https://css-tricks.com/almanac/properties/f/flex/
*  3) https://css-tricks.com/snippets/css/a-guide-to-flexbox/
*  4) https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
*  5) http://godban.com.ua/projects/flexgrid
*
*
*/
@keyframes md-autocomplete-list-out {
  0% {
    animation-timing-function: linear;
  }
  50% {
    opacity: 0;
    height: 40px;
    animation-timing-function: ease-in;
  }
  100% {
    height: 0;
    opacity: 0;
  }
}
@keyframes md-autocomplete-list-in {
  0% {
    opacity: 0;
    height: 0;
    animation-timing-function: ease-out;
  }
  50% {
    opacity: 0;
    height: 40px;
  }
  100% {
    opacity: 1;
    height: 40px;
  }
}
md-autocomplete {
  border-radius: 2px;
  display: block;
  height: 40px;
  position: relative;
  overflow: visible;
  min-width: 190px;
}
md-autocomplete[disabled] input {
  cursor: default;
}
md-autocomplete[md-floating-label] {
  border-radius: 0;
  background: transparent;
  height: auto;
}
md-autocomplete[md-floating-label] md-input-container {
  padding-bottom: 0px;
}
md-autocomplete[md-floating-label] md-autocomplete-wrap {
  height: auto;
}
md-autocomplete[md-floating-label] button {
  position: absolute;
  top: auto;
  bottom: 0;
  right: 0;
  width: 30px;
  height: 30px;
}
md-autocomplete md-autocomplete-wrap {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  position: relative;
  overflow: visible;
  height: 40px;
}
md-autocomplete md-autocomplete-wrap.md-menu-showing {
  z-index: 51;
}
md-autocomplete md-autocomplete-wrap md-input-container, md-autocomplete md-autocomplete-wrap input {
  flex: 1 1 0%;
  box-sizing: border-box;
  min-width: 0;
}
md-autocomplete md-autocomplete-wrap md-progress-linear {
  position: absolute;
  bottom: -2px;
  left: 0;
}
md-autocomplete md-autocomplete-wrap md-progress-linear.md-inline {
  bottom: 40px;
  right: 2px;
  left: 2px;
  width: auto;
}
md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  transition: none;
}
md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate .md-container {
  transition: none;
  height: 3px;
}
md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate.ng-enter {
  transition: opacity 0.15s linear;
}
md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate.ng-enter.ng-enter-active {
  opacity: 1;
}
md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate.ng-leave {
  transition: opacity 0.15s linear;
}
md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate.ng-leave.ng-leave-active {
  opacity: 0;
}
md-autocomplete input:not(.md-input) {
  font-size: 14px;
  box-sizing: border-box;
  border: none;
  box-shadow: none;
  outline: none;
  background: transparent;
  width: 100%;
  padding: 0 15px;
  line-height: 40px;
  height: 40px;
}
md-autocomplete input:not(.md-input)::-ms-clear {
  display: none;
}
md-autocomplete button {
  position: relative;
  line-height: 20px;
  text-align: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  padding: 0;
  font-size: 12px;
  background: transparent;
  margin: auto 5px;
}
md-autocomplete button:after {
  content: "";
  position: absolute;
  top: -6px;
  right: -6px;
  bottom: -6px;
  left: -6px;
  border-radius: 50%;
  transform: scale(0);
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
md-autocomplete button:focus {
  outline: none;
}
md-autocomplete button:focus:after {
  transform: scale(1);
  opacity: 1;
}
md-autocomplete button md-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0) scale(0.9);
}
md-autocomplete button md-icon path {
  stroke-width: 0;
}
md-autocomplete button.ng-enter {
  transform: scale(0);
  transition: transform 0.15s ease-out;
}
md-autocomplete button.ng-enter.ng-enter-active {
  transform: scale(1);
}
md-autocomplete button.ng-leave {
  transition: transform 0.15s ease-out;
}
md-autocomplete button.ng-leave.ng-leave-active {
  transform: scale(0);
}
@media screen and (-ms-high-contrast: active) {
  md-autocomplete input {
    border: 1px solid #fff;
  }
  md-autocomplete li:focus {
    color: #fff;
  }
}

.md-virtual-repeat-container.md-autocomplete-suggestions-container {
  position: absolute;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  height: 225.5px;
  max-height: 225.5px;
  z-index: 100;
}

.md-virtual-repeat-container.md-not-found {
  height: 48px;
}

.md-autocomplete-suggestions {
  margin: 0;
  list-style: none;
  padding: 0;
}
.md-autocomplete-suggestions li {
  font-size: 14px;
  overflow: hidden;
  padding: 0 15px;
  line-height: 48px;
  height: 48px;
  transition: background 0.15s linear;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.md-autocomplete-suggestions li:focus {
  outline: none;
}
.md-autocomplete-suggestions li:not(.md-not-found-wrapper) {
  cursor: pointer;
}

@media screen and (-ms-high-contrast: active) {
  md-autocomplete,
  .md-autocomplete-suggestions {
    border: 1px solid #fff;
  }
}
md-backdrop {
  transition: opacity 450ms;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50;
}
md-backdrop.md-menu-backdrop {
  position: fixed !important;
  z-index: 99;
}
md-backdrop.md-select-backdrop {
  z-index: 81;
  transition-duration: 0;
}
md-backdrop.md-dialog-backdrop {
  z-index: 79;
}
md-backdrop.md-bottom-sheet-backdrop {
  z-index: 69;
}
md-backdrop.md-sidenav-backdrop {
  z-index: 59;
}
md-backdrop.md-click-catcher {
  position: absolute;
}
md-backdrop.md-opaque {
  opacity: 0.48;
}
md-backdrop.md-opaque.ng-enter {
  opacity: 0;
}
md-backdrop.md-opaque.ng-enter.md-opaque.ng-enter-active {
  opacity: 0.48;
}
md-backdrop.md-opaque.ng-leave {
  opacity: 0.48;
  transition: opacity 400ms;
}
md-backdrop.md-opaque.ng-leave.md-opaque.ng-leave-active {
  opacity: 0;
}

md-bottom-sheet {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 8px 16px 88px 16px;
  z-index: 70;
  border-top-width: 1px;
  border-top-style: solid;
  transform: translate3d(0, 80px, 0);
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-property: transform;
}
md-bottom-sheet.md-has-header {
  padding-top: 0;
}
md-bottom-sheet.ng-enter {
  opacity: 0;
  transform: translate3d(0, 100%, 0);
}
md-bottom-sheet.ng-enter-active {
  opacity: 1;
  display: block;
  transform: translate3d(0, 80px, 0) !important;
}
md-bottom-sheet.ng-leave-active {
  transform: translate3d(0, 100%, 0) !important;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
}
md-bottom-sheet .md-subheader {
  background-color: transparent;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  line-height: 56px;
  padding: 0;
  white-space: nowrap;
}
md-bottom-sheet md-inline-icon {
  display: inline-block;
  height: 24px;
  width: 24px;
  fill: #444;
}
md-bottom-sheet md-list-item {
  display: flex;
  outline: none;
}
md-bottom-sheet md-list-item:hover {
  cursor: pointer;
}
md-bottom-sheet.md-list md-list-item {
  padding: 0;
  align-items: center;
  height: 48px;
}
md-bottom-sheet.md-grid {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 0;
}
md-bottom-sheet.md-grid md-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  transition: all 0.5s;
  align-items: center;
}
md-bottom-sheet.md-grid md-list-item {
  flex-direction: column;
  align-items: center;
  transition: all 0.5s;
  height: 96px;
  margin-top: 8px;
  margin-bottom: 8px;
  /* Mixin for how many grid items to show per row */
}
@media (max-width: 960px) {
  md-bottom-sheet.md-grid md-list-item {
    flex: 1 1 33.3333333333%;
    max-width: 33.3333333333%;
  }
  md-bottom-sheet.md-grid md-list-item:nth-of-type(3n + 1) {
    align-items: flex-start;
  }
  md-bottom-sheet.md-grid md-list-item:nth-of-type(3n) {
    align-items: flex-end;
  }
}
@media (min-width: 960px) and (max-width: 1279px) {
  md-bottom-sheet.md-grid md-list-item {
    flex: 1 1 25%;
    max-width: 25%;
  }
}
@media (min-width: 1280px) and (max-width: 1919px) {
  md-bottom-sheet.md-grid md-list-item {
    flex: 1 1 16.6666666667%;
    max-width: 16.6666666667%;
  }
}
@media (min-width: 1920px) {
  md-bottom-sheet.md-grid md-list-item {
    flex: 1 1 14.2857142857%;
    max-width: 14.2857142857%;
  }
}
md-bottom-sheet.md-grid md-list-item::before {
  display: none;
}
md-bottom-sheet.md-grid md-list-item .md-list-item-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 48px;
  padding-bottom: 16px;
}
md-bottom-sheet.md-grid md-list-item .md-grid-item-content {
  border: 1px solid transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
}
md-bottom-sheet.md-grid md-list-item .md-grid-text {
  font-weight: 400;
  line-height: 16px;
  font-size: 13px;
  margin: 0;
  white-space: nowrap;
  width: 64px;
  text-align: center;
  text-transform: none;
  padding-top: 8px;
}

@media screen and (-ms-high-contrast: active) {
  md-bottom-sheet {
    border: 1px solid #fff;
  }
}
button.md-button::-moz-focus-inner {
  border: 0;
}

.md-button {
  display: inline-block;
  position: relative;
  cursor: pointer;
  /** Alignment adjustments */
  min-height: 36px;
  min-width: 88px;
  line-height: 36px;
  vertical-align: middle;
  align-items: center;
  text-align: center;
  border-radius: 3px;
  box-sizing: border-box;
  /* Reset default button appearance */
  user-select: none;
  outline: none;
  border: 0;
  /** Custom styling for button */
  padding: 0 6px;
  margin: 6px 8px;
  background: transparent;
  color: currentColor;
  white-space: nowrap;
  /* Uppercase text content */
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  font-style: inherit;
  font-variant: inherit;
  font-family: inherit;
  text-decoration: none;
  overflow: hidden;
  transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.md-button:focus {
  outline: none;
}
.md-button:hover, .md-button:focus {
  text-decoration: none;
}
.md-button.ng-hide, .md-button.ng-leave {
  transition: none;
}
.md-button.md-cornered {
  border-radius: 0;
}
.md-button.md-icon {
  padding: 0;
  background: none;
}
.md-button.md-raised:not([disabled]) {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}
.md-button.md-icon-button {
  margin: 0 6px;
  height: 40px;
  min-width: 0;
  line-height: 24px;
  padding: 8px;
  width: 40px;
  border-radius: 50%;
}
.md-button.md-icon-button .md-ripple-container {
  border-radius: 50%;
  background-clip: padding-box;
  overflow: hidden;
  -webkit-mask-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC");
}
.md-button.md-fab {
  z-index: 20;
  line-height: 56px;
  min-width: 0;
  width: 56px;
  height: 56px;
  vertical-align: middle;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  border-radius: 50%;
  background-clip: padding-box;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  transition-property: background-color, box-shadow, transform;
}
.md-button.md-fab.md-fab-bottom-right {
  top: auto;
  right: 20px;
  bottom: 20px;
  left: auto;
  position: absolute;
}
.md-button.md-fab.md-fab-bottom-left {
  top: auto;
  right: auto;
  bottom: 20px;
  left: 20px;
  position: absolute;
}
.md-button.md-fab.md-fab-top-right {
  top: 20px;
  right: 20px;
  bottom: auto;
  left: auto;
  position: absolute;
}
.md-button.md-fab.md-fab-top-left {
  top: 20px;
  right: auto;
  bottom: auto;
  left: 20px;
  position: absolute;
}
.md-button.md-fab .md-ripple-container {
  border-radius: 50%;
  background-clip: padding-box;
  overflow: hidden;
  -webkit-mask-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC");
}
.md-button.md-fab.md-mini {
  line-height: 40px;
  width: 40px;
  height: 40px;
}
.md-button.md-fab.ng-hide, .md-button.md-fab.ng-leave {
  transition: none;
}
.md-button:not([disabled]).md-raised.md-focused, .md-button:not([disabled]).md-fab.md-focused {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}
.md-button:not([disabled]).md-raised:active, .md-button:not([disabled]).md-fab:active {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
}
.md-button .md-ripple-container {
  border-radius: 3px;
  background-clip: padding-box;
  overflow: hidden;
  -webkit-mask-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC");
}

.md-button.md-icon-button md-icon,
button.md-button.md-fab md-icon {
  display: block;
}

.md-toast-open-top .md-button.md-fab-top-left,
.md-toast-open-top .md-button.md-fab-top-right {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: translate3d(0, 42px, 0);
}
.md-toast-open-top .md-button.md-fab-top-left:not([disabled]).md-focused, .md-toast-open-top .md-button.md-fab-top-left:not([disabled]):hover,
.md-toast-open-top .md-button.md-fab-top-right:not([disabled]).md-focused,
.md-toast-open-top .md-button.md-fab-top-right:not([disabled]):hover {
  transform: translate3d(0, 41px, 0);
}

.md-toast-open-bottom .md-button.md-fab-bottom-left,
.md-toast-open-bottom .md-button.md-fab-bottom-right {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: translate3d(0, -42px, 0);
}
.md-toast-open-bottom .md-button.md-fab-bottom-left:not([disabled]).md-focused, .md-toast-open-bottom .md-button.md-fab-bottom-left:not([disabled]):hover,
.md-toast-open-bottom .md-button.md-fab-bottom-right:not([disabled]).md-focused,
.md-toast-open-bottom .md-button.md-fab-bottom-right:not([disabled]):hover {
  transform: translate3d(0, -43px, 0);
}

.md-button-group {
  display: flex;
  flex: 1;
  width: 100%;
}
.md-button-group > .md-button {
  flex: 1;
  display: block;
  overflow: hidden;
  width: 0;
  border-width: 1px 0px 1px 1px;
  border-radius: 0;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.md-button-group > .md-button:first-child {
  border-radius: 2px 0px 0px 2px;
}
.md-button-group > .md-button:last-child {
  border-right-width: 1px;
  border-radius: 0px 2px 2px 0px;
}

@media screen and (-ms-high-contrast: active) {
  .md-button.md-raised,
  .md-button.md-fab {
    border: 1px solid #fff;
  }
}
md-card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 8px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}
md-card md-card-header {
  padding: 16px;
  display: flex;
  flex-direction: row;
}
md-card md-card-header:first-child md-card-avatar {
  margin-right: 12px;
}
[dir=rtl] md-card md-card-header:first-child md-card-avatar {
  margin-right: auto;
  margin-left: 12px;
}
md-card md-card-header:last-child md-card-avatar {
  margin-left: 12px;
}
[dir=rtl] md-card md-card-header:last-child md-card-avatar {
  margin-left: auto;
  margin-right: 12px;
}
md-card md-card-header md-card-avatar {
  width: 40px;
  height: 40px;
}
md-card md-card-header md-card-avatar .md-user-avatar,
md-card md-card-header md-card-avatar md-icon {
  border-radius: 50%;
}
md-card md-card-header md-card-avatar md-icon {
  padding: 8px;
}
md-card md-card-header md-card-avatar + md-card-header-text {
  max-height: 40px;
}
md-card md-card-header md-card-avatar + md-card-header-text .md-title {
  font-size: 14px;
}
md-card md-card-header md-card-header-text {
  display: flex;
  flex: 1;
  flex-direction: column;
}
md-card md-card-header md-card-header-text .md-subhead {
  font-size: 14px;
}
md-card > img,
md-card > md-card-header img,
md-card md-card-title-media img {
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
}
md-card md-card-title {
  padding: 24px 16px 16px;
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
}
md-card md-card-title + md-card-content {
  padding-top: 0;
}
md-card md-card-title md-card-title-text {
  flex: 1;
  flex-direction: column;
  display: flex;
}
md-card md-card-title md-card-title-text .md-subhead {
  padding-top: 0;
  font-size: 14px;
}
md-card md-card-title md-card-title-text:only-child .md-subhead {
  padding-top: 12px;
}
md-card md-card-title md-card-title-media {
  margin-top: -8px;
}
md-card md-card-title md-card-title-media .md-media-sm {
  height: 80px;
  width: 80px;
}
md-card md-card-title md-card-title-media .md-media-md {
  height: 112px;
  width: 112px;
}
md-card md-card-title md-card-title-media .md-media-lg {
  height: 152px;
  width: 152px;
}
md-card md-card-content {
  display: block;
  padding: 16px;
}
md-card md-card-content > p:first-child {
  margin-top: 0;
}
md-card md-card-content > p:last-child {
  margin-bottom: 0;
}
md-card md-card-content .md-media-xl {
  height: 240px;
  width: 240px;
}
md-card .md-actions, md-card md-card-actions {
  margin: 8px;
}
md-card .md-actions.layout-column .md-button:not(.md-icon-button), md-card md-card-actions.layout-column .md-button:not(.md-icon-button) {
  margin: 2px 0;
}
md-card .md-actions.layout-column .md-button:not(.md-icon-button):first-of-type, md-card md-card-actions.layout-column .md-button:not(.md-icon-button):first-of-type {
  margin-top: 0;
}
md-card .md-actions.layout-column .md-button:not(.md-icon-button):last-of-type, md-card md-card-actions.layout-column .md-button:not(.md-icon-button):last-of-type {
  margin-bottom: 0;
}
md-card .md-actions.layout-column .md-button.md-icon-button, md-card md-card-actions.layout-column .md-button.md-icon-button {
  margin-top: 6px;
  margin-bottom: 6px;
}
md-card .md-actions md-card-icon-actions, md-card md-card-actions md-card-icon-actions {
  flex: 1;
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
}
md-card .md-actions:not(.layout-column) .md-button:not(.md-icon-button), md-card md-card-actions:not(.layout-column) .md-button:not(.md-icon-button) {
  margin: 0 4px;
}
md-card .md-actions:not(.layout-column) .md-button:not(.md-icon-button):first-of-type, md-card md-card-actions:not(.layout-column) .md-button:not(.md-icon-button):first-of-type {
  margin-left: 0;
}
[dir=rtl] md-card .md-actions:not(.layout-column) .md-button:not(.md-icon-button):first-of-type, [dir=rtl] md-card md-card-actions:not(.layout-column) .md-button:not(.md-icon-button):first-of-type {
  margin-left: auto;
  margin-right: 0;
}
md-card .md-actions:not(.layout-column) .md-button:not(.md-icon-button):last-of-type, md-card md-card-actions:not(.layout-column) .md-button:not(.md-icon-button):last-of-type {
  margin-right: 0;
}
[dir=rtl] md-card .md-actions:not(.layout-column) .md-button:not(.md-icon-button):last-of-type, [dir=rtl] md-card md-card-actions:not(.layout-column) .md-button:not(.md-icon-button):last-of-type {
  margin-right: auto;
  margin-left: 0;
}
md-card .md-actions:not(.layout-column) .md-button.md-icon-button, md-card md-card-actions:not(.layout-column) .md-button.md-icon-button {
  margin-left: 6px;
  margin-right: 6px;
}
md-card .md-actions:not(.layout-column) .md-button.md-icon-button:first-of-type, md-card md-card-actions:not(.layout-column) .md-button.md-icon-button:first-of-type {
  margin-left: 12px;
}
[dir=rtl] md-card .md-actions:not(.layout-column) .md-button.md-icon-button:first-of-type, [dir=rtl] md-card md-card-actions:not(.layout-column) .md-button.md-icon-button:first-of-type {
  margin-left: auto;
  margin-right: 12px;
}
md-card .md-actions:not(.layout-column) .md-button.md-icon-button:last-of-type, md-card md-card-actions:not(.layout-column) .md-button.md-icon-button:last-of-type {
  margin-right: 12px;
}
[dir=rtl] md-card .md-actions:not(.layout-column) .md-button.md-icon-button:last-of-type, [dir=rtl] md-card md-card-actions:not(.layout-column) .md-button.md-icon-button:last-of-type {
  margin-right: auto;
  margin-left: 12px;
}
md-card .md-actions:not(.layout-column) .md-button + md-card-icon-actions, md-card md-card-actions:not(.layout-column) .md-button + md-card-icon-actions {
  flex: 1;
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
}
md-card md-card-footer {
  margin-top: auto;
  padding: 16px;
}

@media screen and (-ms-high-contrast: active) {
  md-card {
    border: 1px solid #fff;
  }
}
.md-image-no-fill > img {
  width: auto;
  height: auto;
}

.md-contact-chips .md-chips md-chip {
  padding: 0 25px 0 0;
}
[dir=rtl] .md-contact-chips .md-chips md-chip {
  padding: 0 0 0 25px;
}
.md-contact-chips .md-chips md-chip .md-contact-avatar {
  float: left;
}
[dir=rtl] .md-contact-chips .md-chips md-chip .md-contact-avatar {
  float: right;
}
.md-contact-chips .md-chips md-chip .md-contact-avatar img {
  height: 32px;
  border-radius: 16px;
}
.md-contact-chips .md-chips md-chip .md-contact-name {
  display: inline-block;
  height: 32px;
  margin-left: 8px;
}
[dir=rtl] .md-contact-chips .md-chips md-chip .md-contact-name {
  margin-left: auto;
  margin-right: 8px;
}

.md-contact-suggestion {
  height: 56px;
}
.md-contact-suggestion img {
  height: 40px;
  border-radius: 20px;
  margin-top: 8px;
}
.md-contact-suggestion .md-contact-name {
  margin-left: 8px;
  width: 120px;
}
[dir=rtl] .md-contact-suggestion .md-contact-name {
  margin-left: auto;
  margin-right: 8px;
}
.md-contact-suggestion .md-contact-name, .md-contact-suggestion .md-contact-email {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.md-contact-chips-suggestions li {
  height: 100%;
}

.md-chips {
  display: block;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
  padding: 0 0 8px 3px;
  vertical-align: middle;
}
.md-chips:after {
  content: "";
  display: table;
  clear: both;
}
[dir=rtl] .md-chips {
  padding: 0 3px 8px 0;
}
.md-chips.md-readonly .md-chip-input-container {
  min-height: 32px;
}
.md-chips:not(.md-readonly) {
  cursor: text;
}
.md-chips.md-removable md-chip {
  padding-right: 22px;
}
[dir=rtl] .md-chips.md-removable md-chip {
  padding-right: 0;
  padding-left: 22px;
}
.md-chips.md-removable md-chip .md-chip-content {
  padding-right: 4px;
}
[dir=rtl] .md-chips.md-removable md-chip .md-chip-content {
  padding-right: 0;
  padding-left: 4px;
}
.md-chips md-chip {
  cursor: default;
  border-radius: 16px;
  display: block;
  height: 32px;
  line-height: 32px;
  margin: 8px 8px 0 0;
  padding: 0 12px 0 12px;
  float: left;
  box-sizing: border-box;
  max-width: 100%;
  position: relative;
}
[dir=rtl] .md-chips md-chip {
  margin: 8px 0 0 8px;
}
[dir=rtl] .md-chips md-chip {
  float: right;
}
.md-chips md-chip .md-chip-content {
  display: block;
  float: left;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
[dir=rtl] .md-chips md-chip .md-chip-content {
  float: right;
}
.md-chips md-chip .md-chip-content:focus {
  outline: none;
}
.md-chips md-chip._md-chip-content-edit-is-enabled {
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}
.md-chips md-chip .md-chip-remove-container {
  position: absolute;
  right: 0;
  line-height: 22px;
}
[dir=rtl] .md-chips md-chip .md-chip-remove-container {
  right: auto;
  left: 0;
}
.md-chips md-chip .md-chip-remove {
  text-align: center;
  width: 32px;
  height: 32px;
  min-width: 0;
  padding: 0;
  background: transparent;
  border: none;
  box-shadow: none;
  margin: 0;
  position: relative;
}
.md-chips md-chip .md-chip-remove md-icon {
  height: 18px;
  width: 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.md-chips .md-chip-input-container {
  display: block;
  line-height: 32px;
  margin: 8px 8px 0 0;
  padding: 0;
  float: left;
}
[dir=rtl] .md-chips .md-chip-input-container {
  margin: 8px 0 0 8px;
}
[dir=rtl] .md-chips .md-chip-input-container {
  float: right;
}
.md-chips .md-chip-input-container input:not([type]), .md-chips .md-chip-input-container input[type=email], .md-chips .md-chip-input-container input[type=number], .md-chips .md-chip-input-container input[type=tel], .md-chips .md-chip-input-container input[type=url], .md-chips .md-chip-input-container input[type=text] {
  border: 0;
  height: 32px;
  line-height: 32px;
  padding: 0;
}
.md-chips .md-chip-input-container input:not([type]):focus, .md-chips .md-chip-input-container input[type=email]:focus, .md-chips .md-chip-input-container input[type=number]:focus, .md-chips .md-chip-input-container input[type=tel]:focus, .md-chips .md-chip-input-container input[type=url]:focus, .md-chips .md-chip-input-container input[type=text]:focus {
  outline: none;
}
.md-chips .md-chip-input-container md-autocomplete, .md-chips .md-chip-input-container md-autocomplete-wrap {
  background: transparent;
  height: 32px;
}
.md-chips .md-chip-input-container md-autocomplete md-autocomplete-wrap {
  box-shadow: none;
}
.md-chips .md-chip-input-container md-autocomplete input {
  position: relative;
}
.md-chips .md-chip-input-container input {
  border: 0;
  height: 32px;
  line-height: 32px;
  padding: 0;
}
.md-chips .md-chip-input-container input:focus {
  outline: none;
}
.md-chips .md-chip-input-container md-autocomplete, .md-chips .md-chip-input-container md-autocomplete-wrap {
  height: 32px;
}
.md-chips .md-chip-input-container md-autocomplete {
  box-shadow: none;
}
.md-chips .md-chip-input-container md-autocomplete input {
  position: relative;
}
.md-chips .md-chip-input-container:not(:first-child) {
  margin: 8px 8px 0 0;
}
[dir=rtl] .md-chips .md-chip-input-container:not(:first-child) {
  margin: 8px 0 0 8px;
}
.md-chips .md-chip-input-container input {
  background: transparent;
  border-width: 0;
}
.md-chips md-autocomplete button {
  display: none;
}

@media screen and (-ms-high-contrast: active) {
  .md-chip-input-container,
  md-chip {
    border: 1px solid #fff;
  }
  .md-chip-input-container md-autocomplete {
    border: none;
  }
}
.md-inline-form md-checkbox {
  margin: 19px 0 18px;
}

md-checkbox {
  box-sizing: border-box;
  display: inline-block;
  margin-bottom: 16px;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  user-select: none;
  position: relative;
  min-width: 20px;
  min-height: 20px;
  margin-left: 0;
  margin-right: 16px;
}
[dir=rtl] md-checkbox {
  margin-left: 16px;
}
[dir=rtl] md-checkbox {
  margin-right: 0;
}
md-checkbox:last-of-type {
  margin-left: 0;
  margin-right: 0;
}
md-checkbox.md-focused:not([disabled]) .md-container:before {
  left: -8px;
  top: -8px;
  right: -8px;
  bottom: -8px;
}
md-checkbox.md-focused:not([disabled]):not(.md-checked) .md-container:before {
  background-color: rgba(0, 0, 0, 0.12);
}
md-checkbox.md-align-top-left > div.md-container {
  top: 12px;
}
md-checkbox .md-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
  display: inline-block;
  width: 20px;
  height: 20px;
  left: 0;
  right: auto;
}
[dir=rtl] md-checkbox .md-container {
  left: auto;
}
[dir=rtl] md-checkbox .md-container {
  right: 0;
}
md-checkbox .md-container:before {
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 50%;
  content: "";
  position: absolute;
  display: block;
  height: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition: all 0.5s;
  width: auto;
}
md-checkbox .md-container:after {
  box-sizing: border-box;
  content: "";
  position: absolute;
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
}
md-checkbox .md-container .md-ripple-container {
  position: absolute;
  display: block;
  width: auto;
  height: auto;
  left: -15px;
  top: -15px;
  right: -15px;
  bottom: -15px;
}
md-checkbox .md-icon {
  box-sizing: border-box;
  transition: 240ms;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-width: 2px;
  border-style: solid;
  border-radius: 2px;
}
md-checkbox.md-checked .md-icon {
  border-color: transparent;
}
md-checkbox.md-checked .md-icon:after {
  box-sizing: border-box;
  transform: rotate(45deg);
  position: absolute;
  left: 4.6666666667px;
  top: 0.2222222222px;
  display: table;
  width: 6.6666666667px;
  height: 13.3333333333px;
  border-width: 2px;
  border-style: solid;
  border-top: 0;
  border-left: 0;
  content: "";
}
md-checkbox[disabled] {
  cursor: default;
}
md-checkbox.md-indeterminate .md-icon:after {
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: table;
  width: 12px;
  height: 2px;
  border-width: 2px;
  border-style: solid;
  border-top: 0;
  border-left: 0;
  content: "";
}
md-checkbox .md-label {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  user-select: text;
  margin-left: 30px;
  margin-right: 0;
}
[dir=rtl] md-checkbox .md-label {
  margin-left: 0;
}
[dir=rtl] md-checkbox .md-label {
  margin-right: 30px;
}

md-content {
  display: block;
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
md-content[md-scroll-y] {
  overflow-y: auto;
  overflow-x: hidden;
}
md-content[md-scroll-x] {
  overflow-x: auto;
  overflow-y: hidden;
}
@media print {
  md-content {
    overflow: visible !important;
  }
}

/** Styles for mdCalendar. */
md-calendar {
  font-size: 13px;
  user-select: none;
}

.md-calendar-scroll-mask {
  display: inline-block;
  overflow: hidden;
  height: 308px;
}
.md-calendar-scroll-mask .md-virtual-repeat-scroller {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.md-calendar-scroll-mask .md-virtual-repeat-scroller::-webkit-scrollbar {
  display: none;
}
.md-calendar-scroll-mask .md-virtual-repeat-offsetter {
  width: 100%;
}

.md-calendar-scroll-container {
  box-shadow: inset -3px 3px 6px rgba(0, 0, 0, 0.2);
  display: inline-block;
  height: 308px;
  width: 346px;
}

.md-calendar-date {
  height: 44px;
  width: 44px;
  text-align: center;
  padding: 0;
  border: none;
  box-sizing: content-box;
}
.md-calendar-date:first-child {
  padding-left: 16px;
}
[dir=rtl] .md-calendar-date:first-child {
  padding-left: 0;
  padding-right: 16px;
}
.md-calendar-date:last-child {
  padding-right: 16px;
}
[dir=rtl] .md-calendar-date:last-child {
  padding-right: 0;
  padding-left: 16px;
}
.md-calendar-date.md-calendar-date-disabled {
  cursor: default;
}

.md-calendar-date-selection-indicator {
  transition: background-color, color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  border-radius: 50%;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.md-calendar-date:not(.md-disabled) .md-calendar-date-selection-indicator {
  cursor: pointer;
}

.md-calendar-month-label {
  height: 44px;
  font-size: 14px;
  font-weight: 500;
  padding: 0 0 0 24px;
}
[dir=rtl] .md-calendar-month-label {
  padding: 0 24px 0 0;
}
md-calendar-month .md-calendar-month-label:not(.md-calendar-month-label-disabled) {
  cursor: pointer;
}
.md-calendar-month-label md-icon {
  transform: rotate(180deg);
}
[dir=rtl] .md-calendar-month-label md-icon {
  transform: none;
}
.md-calendar-month-label span {
  vertical-align: middle;
}

.md-calendar-day-header {
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse;
}
.md-calendar-day-header th {
  height: 40px;
  width: 44px;
  text-align: center;
  padding: 0;
  border: none;
  box-sizing: content-box;
  font-weight: normal;
}
.md-calendar-day-header th:first-child {
  padding-left: 16px;
}
[dir=rtl] .md-calendar-day-header th:first-child {
  padding-left: 0;
  padding-right: 16px;
}
.md-calendar-day-header th:last-child {
  padding-right: 16px;
}
[dir=rtl] .md-calendar-day-header th:last-child {
  padding-right: 0;
  padding-left: 16px;
}

.md-calendar {
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse;
}
.md-calendar tr:last-child td {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.md-calendar:first-child {
  border-top: 1px solid transparent;
}
.md-calendar tbody, .md-calendar td, .md-calendar tr {
  vertical-align: middle;
  box-sizing: content-box;
}

/** Styles for mdDatepicker. */
md-datepicker {
  white-space: nowrap;
  overflow: hidden;
  padding-right: 18px;
  margin-right: -18px;
  vertical-align: middle;
}
[dir=rtl] md-datepicker {
  padding-right: 0;
  padding-left: 18px;
}
[dir=rtl] md-datepicker {
  margin-right: auto;
  margin-left: -18px;
}

.md-inline-form md-datepicker {
  margin-top: 12px;
}

.md-datepicker-button {
  display: inline-block;
  box-sizing: border-box;
  background: none;
  vertical-align: middle;
  position: relative;
}
.md-datepicker-button:before {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  content: "";
  speak: none;
}

.md-datepicker-input {
  font-size: 14px;
  box-sizing: border-box;
  border: none;
  box-shadow: none;
  outline: none;
  background: transparent;
  min-width: 120px;
  max-width: 328px;
  padding: 0 0 5px;
}
.md-datepicker-input::-ms-clear {
  display: none;
}

._md-datepicker-floating-label > md-datepicker {
  overflow: visible;
}
._md-datepicker-floating-label > md-datepicker .md-datepicker-input-container {
  border: none;
}
._md-datepicker-floating-label > md-datepicker .md-datepicker-button {
  float: left;
  margin-top: -2.5px;
}
[dir=rtl] ._md-datepicker-floating-label > md-datepicker .md-datepicker-button {
  float: right;
}
._md-datepicker-floating-label._md-datepicker-has-calendar-icon > label:not(.md-no-float):not(.md-container-ignore) {
  right: 18px;
  left: auto;
  width: calc(100% - 84px);
}
[dir=rtl] ._md-datepicker-floating-label._md-datepicker-has-calendar-icon > label:not(.md-no-float):not(.md-container-ignore) {
  right: auto;
}
[dir=rtl] ._md-datepicker-floating-label._md-datepicker-has-calendar-icon > label:not(.md-no-float):not(.md-container-ignore) {
  left: 18px;
}
._md-datepicker-floating-label._md-datepicker-has-calendar-icon .md-input-message-animation {
  margin-left: 64px;
}
[dir=rtl] ._md-datepicker-floating-label._md-datepicker-has-calendar-icon .md-input-message-animation {
  margin-left: auto;
  margin-right: 64px;
}

.md-datepicker-input-container {
  position: relative;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  display: inline-block;
  width: auto;
}
.md-icon-button + .md-datepicker-input-container {
  margin-left: 12px;
}
[dir=rtl] .md-icon-button + .md-datepicker-input-container {
  margin-left: auto;
  margin-right: 12px;
}
.md-datepicker-input-container.md-datepicker-focused {
  border-bottom-width: 2px;
}

.md-datepicker-is-showing .md-scroll-mask {
  z-index: 99;
}

.md-datepicker-calendar-pane {
  position: absolute;
  top: 0;
  left: -100%;
  z-index: 100;
  border-width: 1px;
  border-style: solid;
  background: transparent;
  transform: scale(0);
  transform-origin: 0 0;
  transition: transform 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.md-datepicker-calendar-pane.md-pane-open {
  transform: scale(1);
}

.md-datepicker-input-mask {
  height: 40px;
  width: 340px;
  position: relative;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
  cursor: text;
}

.md-datepicker-calendar {
  opacity: 0;
  transition: opacity 0.2s cubic-bezier(0.5, 0, 0.25, 1);
}
.md-pane-open .md-datepicker-calendar {
  opacity: 1;
}
.md-datepicker-calendar md-calendar:focus {
  outline: none;
}

.md-datepicker-expand-triangle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid;
}

.md-datepicker-triangle-button {
  position: absolute;
  right: 0;
  top: 5px;
  transform: translateY(-25%) translateX(45%);
}
[dir=rtl] .md-datepicker-triangle-button {
  right: auto;
  left: 0;
}
[dir=rtl] .md-datepicker-triangle-button {
  transform: translateY(-25%) translateX(-45%);
}

.md-datepicker-triangle-button.md-button.md-icon-button {
  height: 36px;
  width: 36px;
  position: absolute;
  padding: 8px;
}

md-datepicker[disabled] .md-datepicker-input-container {
  border-bottom-color: transparent;
}
md-datepicker[disabled] .md-datepicker-triangle-button {
  display: none;
}

.md-datepicker-open {
  overflow: hidden;
}
.md-datepicker-open .md-datepicker-input-container,
.md-datepicker-open input.md-input {
  border-bottom-color: transparent;
}
.md-datepicker-open .md-datepicker-triangle-button, .md-datepicker-open.md-input-has-value > label, .md-datepicker-open.md-input-has-placeholder > label {
  display: none;
}

.md-datepicker-pos-adjusted .md-datepicker-input-mask {
  display: none;
}

.md-datepicker-calendar-pane .md-calendar {
  transform: translateY(-85px);
  transition: transform 0.65s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-delay: 0.125s;
}
.md-datepicker-calendar-pane.md-pane-open .md-calendar {
  transform: translateY(0);
}

.md-dialog-is-showing {
  max-height: 100%;
}

.md-dialog-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 80;
  overflow: hidden;
}

md-dialog {
  opacity: 0;
  min-width: 240px;
  max-width: 80%;
  max-height: 80%;
  position: relative;
  overflow: auto;
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
}
md-dialog.md-transition-in {
  opacity: 1;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: translate(0, 0) scale(1);
}
md-dialog.md-transition-out {
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: translate(0, 100%) scale(0.2);
}
md-dialog > form {
  display: flex;
  flex-direction: column;
  overflow: auto;
}
md-dialog .md-dialog-content {
  padding: 24px;
}
md-dialog md-dialog-content {
  order: 1;
  flex-direction: column;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
md-dialog md-dialog-content:not([layout=row]) > *:first-child:not(.md-subheader) {
  margin-top: 0;
}
md-dialog md-dialog-content:focus {
  outline: none;
}
md-dialog md-dialog-content .md-subheader {
  margin: 0;
}
md-dialog md-dialog-content .md-dialog-content-body {
  width: 100%;
}
md-dialog md-dialog-content .md-prompt-input-container {
  width: 100%;
  box-sizing: border-box;
}
md-dialog .md-actions, md-dialog md-dialog-actions {
  display: flex;
  order: 2;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0;
  padding-right: 8px;
  padding-left: 16px;
  min-height: 52px;
  overflow: hidden;
}
[dir=rtl] md-dialog .md-actions, [dir=rtl] md-dialog md-dialog-actions {
  padding-right: 16px;
}
[dir=rtl] md-dialog .md-actions, [dir=rtl] md-dialog md-dialog-actions {
  padding-left: 8px;
}
md-dialog .md-actions .md-button, md-dialog md-dialog-actions .md-button {
  margin-bottom: 8px;
  margin-left: 8px;
  margin-right: 0;
  margin-top: 8px;
}
[dir=rtl] md-dialog .md-actions .md-button, [dir=rtl] md-dialog md-dialog-actions .md-button {
  margin-left: 0;
}
[dir=rtl] md-dialog .md-actions .md-button, [dir=rtl] md-dialog md-dialog-actions .md-button {
  margin-right: 8px;
}
md-dialog.md-content-overflow .md-actions, md-dialog.md-content-overflow md-dialog-actions {
  border-top-width: 1px;
  border-top-style: solid;
}

@media screen and (-ms-high-contrast: active) {
  md-dialog {
    border: 1px solid #fff;
  }
}
@media (max-width: 959px) {
  md-dialog.md-dialog-fullscreen {
    min-height: 100%;
    min-width: 100%;
    border-radius: 0;
  }
}
md-divider {
  display: block;
  border-top-width: 1px;
  border-top-style: solid;
  margin: 0;
}
md-divider[md-inset] {
  margin-left: 80px;
}
[dir=rtl] md-divider[md-inset] {
  margin-left: auto;
  margin-right: 80px;
}

.layout-row > md-divider,
.layout-xs-row > md-divider, .layout-gt-xs-row > md-divider,
.layout-sm-row > md-divider, .layout-gt-sm-row > md-divider,
.layout-md-row > md-divider, .layout-gt-md-row > md-divider,
.layout-lg-row > md-divider, .layout-gt-lg-row > md-divider,
.layout-xl-row > md-divider {
  border-top-width: 0;
  border-right-width: 1px;
  border-right-style: solid;
}

md-fab-speed-dial {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 20;
  /*
   * Hide some graphics glitches if switching animation types
   */
  /*
   * Handle the animations
   */
}
md-fab-speed-dial.md-fab-bottom-right {
  top: auto;
  right: 20px;
  bottom: 20px;
  left: auto;
  position: absolute;
}
md-fab-speed-dial.md-fab-bottom-left {
  top: auto;
  right: auto;
  bottom: 20px;
  left: 20px;
  position: absolute;
}
md-fab-speed-dial.md-fab-top-right {
  top: 20px;
  right: 20px;
  bottom: auto;
  left: auto;
  position: absolute;
}
md-fab-speed-dial.md-fab-top-left {
  top: 20px;
  right: auto;
  bottom: auto;
  left: 20px;
  position: absolute;
}
md-fab-speed-dial:not(.md-hover-full) {
  pointer-events: none;
}
md-fab-speed-dial:not(.md-hover-full) md-fab-trigger, md-fab-speed-dial:not(.md-hover-full) .md-fab-action-item {
  pointer-events: auto;
}
md-fab-speed-dial:not(.md-hover-full).md-is-open {
  pointer-events: auto;
}
md-fab-speed-dial ._md-css-variables {
  z-index: 20;
}
md-fab-speed-dial.md-is-open .md-fab-action-item {
  align-items: center;
}
md-fab-speed-dial md-fab-actions {
  display: flex;
  height: auto;
}
md-fab-speed-dial md-fab-actions .md-fab-action-item {
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
}
md-fab-speed-dial.md-down {
  flex-direction: column;
}
md-fab-speed-dial.md-down md-fab-trigger {
  order: 1;
}
md-fab-speed-dial.md-down md-fab-actions {
  flex-direction: column;
  order: 2;
}
md-fab-speed-dial.md-up {
  flex-direction: column;
}
md-fab-speed-dial.md-up md-fab-trigger {
  order: 2;
}
md-fab-speed-dial.md-up md-fab-actions {
  flex-direction: column-reverse;
  order: 1;
}
md-fab-speed-dial.md-left {
  flex-direction: row;
}
md-fab-speed-dial.md-left md-fab-trigger {
  order: 2;
}
md-fab-speed-dial.md-left md-fab-actions {
  flex-direction: row-reverse;
  order: 1;
}
md-fab-speed-dial.md-left md-fab-actions .md-fab-action-item {
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
}
md-fab-speed-dial.md-right {
  flex-direction: row;
}
md-fab-speed-dial.md-right md-fab-trigger {
  order: 1;
}
md-fab-speed-dial.md-right md-fab-actions {
  flex-direction: row;
  order: 2;
}
md-fab-speed-dial.md-right md-fab-actions .md-fab-action-item {
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
}
md-fab-speed-dial.md-fling-remove .md-fab-action-item > *, md-fab-speed-dial.md-scale-remove .md-fab-action-item > * {
  visibility: hidden;
}
md-fab-speed-dial.md-fling .md-fab-action-item {
  opacity: 1;
}
md-fab-speed-dial.md-fling.md-animations-waiting .md-fab-action-item {
  opacity: 0;
  transition-duration: 0s;
}
md-fab-speed-dial.md-scale .md-fab-action-item {
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  transition-duration: 0.1428571429s;
}

md-fab-toolbar {
  display: block;
  /*
   * Closed styling
   */
  /*
   * Hover styling
   */
}
md-fab-toolbar.md-fab-bottom-right {
  top: auto;
  right: 20px;
  bottom: 20px;
  left: auto;
  position: absolute;
}
md-fab-toolbar.md-fab-bottom-left {
  top: auto;
  right: auto;
  bottom: 20px;
  left: 20px;
  position: absolute;
}
md-fab-toolbar.md-fab-top-right {
  top: 20px;
  right: 20px;
  bottom: auto;
  left: auto;
  position: absolute;
}
md-fab-toolbar.md-fab-top-left {
  top: 20px;
  right: auto;
  bottom: auto;
  left: 20px;
  position: absolute;
}
md-fab-toolbar .md-fab-toolbar-wrapper {
  display: block;
  position: relative;
  overflow: hidden;
  height: 68px;
}
md-fab-toolbar md-fab-trigger {
  position: absolute;
  z-index: 20;
}
md-fab-toolbar md-fab-trigger button {
  overflow: visible !important;
}
md-fab-toolbar md-fab-trigger .md-fab-toolbar-background {
  display: block;
  position: absolute;
  z-index: 21;
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
}
md-fab-toolbar md-fab-trigger md-icon {
  position: relative;
  z-index: 22;
  opacity: 1;
  transition: all 200ms ease-in;
}
md-fab-toolbar.md-left md-fab-trigger {
  right: 0;
}
[dir=rtl] md-fab-toolbar.md-left md-fab-trigger {
  right: auto;
  left: 0;
}
md-fab-toolbar.md-left .md-toolbar-tools {
  flex-direction: row-reverse;
}
md-fab-toolbar.md-left .md-toolbar-tools > .md-button:first-child {
  margin-right: 0.6rem;
}
[dir=rtl] md-fab-toolbar.md-left .md-toolbar-tools > .md-button:first-child {
  margin-right: auto;
  margin-left: 0.6rem;
}
md-fab-toolbar.md-left .md-toolbar-tools > .md-button:first-child {
  margin-left: -0.8rem;
}
[dir=rtl] md-fab-toolbar.md-left .md-toolbar-tools > .md-button:first-child {
  margin-left: auto;
  margin-right: -0.8rem;
}
md-fab-toolbar.md-left .md-toolbar-tools > .md-button:last-child {
  margin-right: 8px;
}
[dir=rtl] md-fab-toolbar.md-left .md-toolbar-tools > .md-button:last-child {
  margin-right: auto;
  margin-left: 8px;
}
md-fab-toolbar.md-right md-fab-trigger {
  left: 0;
}
[dir=rtl] md-fab-toolbar.md-right md-fab-trigger {
  left: auto;
  right: 0;
}
md-fab-toolbar.md-right .md-toolbar-tools {
  flex-direction: row;
}
md-fab-toolbar md-toolbar {
  background-color: transparent !important;
  pointer-events: none;
  z-index: 23;
}
md-fab-toolbar md-toolbar .md-toolbar-tools {
  padding: 0 20px;
  margin-top: 3px;
}
md-fab-toolbar md-toolbar .md-fab-action-item {
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  transition-duration: 0.15s;
}
md-fab-toolbar.md-is-open md-fab-trigger > button {
  box-shadow: none;
}
md-fab-toolbar.md-is-open md-fab-trigger > button md-icon {
  opacity: 0;
}
md-fab-toolbar.md-is-open .md-fab-action-item {
  opacity: 1;
  transform: scale(1);
}

md-grid-list {
  box-sizing: border-box;
  display: block;
  position: relative;
}
md-grid-list md-grid-tile,
md-grid-list md-grid-tile > figure,
md-grid-list md-grid-tile-header,
md-grid-list md-grid-tile-footer {
  box-sizing: border-box;
}
md-grid-list md-grid-tile {
  display: block;
  position: absolute;
}
md-grid-list md-grid-tile figure {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  margin: 0;
}
md-grid-list md-grid-tile md-grid-tile-header,
md-grid-list md-grid-tile md-grid-tile-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  color: #fff;
  background: rgba(0, 0, 0, 0.18);
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
}
md-grid-list md-grid-tile md-grid-tile-header h3,
md-grid-list md-grid-tile md-grid-tile-header h4,
md-grid-list md-grid-tile md-grid-tile-footer h3,
md-grid-list md-grid-tile md-grid-tile-footer h4 {
  font-weight: 400;
  margin: 0 0 0 16px;
}
md-grid-list md-grid-tile md-grid-tile-header h3,
md-grid-list md-grid-tile md-grid-tile-footer h3 {
  font-size: 14px;
}
md-grid-list md-grid-tile md-grid-tile-header h4,
md-grid-list md-grid-tile md-grid-tile-footer h4 {
  font-size: 12px;
}
md-grid-list md-grid-tile md-grid-tile-header {
  top: 0;
}
md-grid-list md-grid-tile md-grid-tile-footer {
  bottom: 0;
}

@media screen and (-ms-high-contrast: active) {
  md-grid-tile {
    border: 1px solid #fff;
  }
  md-grid-tile-footer {
    border-top: 1px solid #fff;
  }
}
md-icon {
  margin: auto;
  background-repeat: no-repeat no-repeat;
  display: inline-block;
  vertical-align: middle;
  fill: currentColor;
  height: 24px;
  width: 24px;
  min-height: 24px;
  min-width: 24px;
}
md-icon svg {
  pointer-events: none;
  display: block;
}
md-icon[md-font-icon] {
  line-height: 24px;
  width: auto;
}

md-input-container {
  display: inline-block;
  position: relative;
  padding: 2px;
  margin: 18px 0;
  vertical-align: middle;
  /*
   * The .md-input class is added to the input/textarea
   */
}
md-input-container:after {
  content: "";
  display: table;
  clear: both;
}
md-input-container.md-block {
  display: block;
}
md-input-container .md-errors-spacer {
  float: right;
  min-height: 24px;
  min-width: 1px;
}
[dir=rtl] md-input-container .md-errors-spacer {
  float: left;
}
md-input-container > md-icon {
  position: absolute;
  top: 8px;
  left: 2px;
  right: auto;
}
[dir=rtl] md-input-container > md-icon {
  left: auto;
}
[dir=rtl] md-input-container > md-icon {
  right: 2px;
}
md-input-container textarea,
md-input-container input[type=text],
md-input-container input[type=password],
md-input-container input[type=datetime],
md-input-container input[type=datetime-local],
md-input-container input[type=date],
md-input-container input[type=month],
md-input-container input[type=time],
md-input-container input[type=week],
md-input-container input[type=number],
md-input-container input[type=email],
md-input-container input[type=url],
md-input-container input[type=search],
md-input-container input[type=tel],
md-input-container input[type=color] {
  /* remove default appearance from all input/textarea */
  -moz-appearance: none;
  -webkit-appearance: none;
}
md-input-container input[type=date],
md-input-container input[type=datetime-local],
md-input-container input[type=month],
md-input-container input[type=time],
md-input-container input[type=week] {
  min-height: 26px;
}
md-input-container textarea {
  resize: none;
  overflow: hidden;
}
md-input-container textarea.md-input {
  min-height: 26px;
  -ms-flex-preferred-size: auto;
}
md-input-container textarea[md-no-autogrow] {
  height: auto;
  overflow: auto;
}
md-input-container label:not(.md-container-ignore) {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: auto;
}
[dir=rtl] md-input-container label:not(.md-container-ignore) {
  left: auto;
}
[dir=rtl] md-input-container label:not(.md-container-ignore) {
  right: 0;
}
md-input-container label:not(.md-container-ignore).md-required:after {
  content: " *";
  font-size: 13px;
  vertical-align: top;
}
md-input-container label:not(.md-no-float):not(.md-container-ignore),
md-input-container .md-placeholder {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  order: 1;
  pointer-events: none;
  -webkit-font-smoothing: antialiased;
  padding-left: 3px;
  padding-right: 0;
  z-index: 1;
  transform: translate3d(0, 28px, 0) scale(1);
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  max-width: 100%;
  transform-origin: left top;
}
[dir=rtl] md-input-container label:not(.md-no-float):not(.md-container-ignore),
[dir=rtl] md-input-container .md-placeholder {
  padding-left: 0;
}
[dir=rtl] md-input-container label:not(.md-no-float):not(.md-container-ignore),
[dir=rtl] md-input-container .md-placeholder {
  padding-right: 3px;
}
[dir=rtl] md-input-container label:not(.md-no-float):not(.md-container-ignore),
[dir=rtl] md-input-container .md-placeholder {
  transform-origin: right top;
}
md-input-container .md-placeholder {
  position: absolute;
  top: 0;
  opacity: 0;
  transition-property: opacity, transform;
  transform: translate3d(0, 30px, 0);
}
md-input-container.md-input-focused .md-placeholder {
  opacity: 1;
  transform: translate3d(0, 24px, 0);
}
md-input-container.md-input-has-value .md-placeholder {
  transition: none;
  opacity: 0;
}
md-input-container:not(.md-input-has-value) input:not(:focus), md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-ampm-field, md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-day-field, md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-hour-field, md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-millisecond-field, md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-minute-field, md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-month-field, md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-second-field, md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-week-field, md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-year-field, md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-text {
  color: transparent;
}
md-input-container .md-input {
  order: 2;
  display: block;
  margin-top: 0;
  background: none;
  padding-top: 2px;
  padding-bottom: 1px;
  padding-left: 2px;
  padding-right: 2px;
  border-width: 0 0 1px 0;
  line-height: 26px;
  height: 30px;
  -ms-flex-preferred-size: 26px;
  border-radius: 0;
  border-style: solid;
  width: 100%;
  box-sizing: border-box;
  float: left;
}
[dir=rtl] md-input-container .md-input {
  float: right;
}
md-input-container .md-input:focus {
  outline: none;
}
md-input-container .md-input:invalid {
  outline: none;
  box-shadow: none;
}
md-input-container .md-input.md-no-flex {
  flex: none !important;
}
md-input-container .md-char-counter {
  text-align: right;
  padding-right: 2px;
  padding-left: 0;
}
[dir=rtl] md-input-container .md-char-counter {
  text-align: left;
}
[dir=rtl] md-input-container .md-char-counter {
  padding-right: 0;
}
[dir=rtl] md-input-container .md-char-counter {
  padding-left: 2px;
}
md-input-container .md-input-messages-animation {
  position: relative;
  order: 4;
  overflow: hidden;
  clear: left;
}
[dir=rtl] md-input-container .md-input-messages-animation {
  clear: right;
}
md-input-container .md-input-messages-animation.ng-enter .md-input-message-animation {
  opacity: 0;
  margin-top: -100px;
}
md-input-container .md-input-message-animation, md-input-container .md-char-counter {
  font-size: 12px;
  line-height: 14px;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  opacity: 1;
  margin-top: 0;
  padding-top: 5px;
}
md-input-container .md-input-message-animation:not(.md-char-counter), md-input-container .md-char-counter:not(.md-char-counter) {
  padding-right: 5px;
  padding-left: 0;
}
[dir=rtl] md-input-container .md-input-message-animation:not(.md-char-counter), [dir=rtl] md-input-container .md-char-counter:not(.md-char-counter) {
  padding-right: 0;
}
[dir=rtl] md-input-container .md-input-message-animation:not(.md-char-counter), [dir=rtl] md-input-container .md-char-counter:not(.md-char-counter) {
  padding-left: 5px;
}
md-input-container:not(.md-input-invalid) .md-auto-hide .md-input-message-animation {
  opacity: 0;
  margin-top: -100px;
}
md-input-container .md-input-message-animation:not(.ng-animate) {
  opacity: 0;
  margin-top: -100px;
}
md-input-container .md-input-message-animation.ng-enter {
  opacity: 0;
  margin-top: -100px;
}
md-input-container.md-input-focused label:not(.md-no-float), md-input-container.md-input-has-placeholder label:not(.md-no-float), md-input-container.md-input-has-value label:not(.md-no-float) {
  transform: translate3d(0, 6px, 0) scale(0.75);
  transition: transform cubic-bezier(0.25, 0.8, 0.25, 1) 0.4s, width cubic-bezier(0.25, 0.8, 0.25, 1) 0.4s;
}
md-input-container.md-input-has-value label {
  transition: none;
}
md-input-container.md-input-focused .md-input,
md-input-container .md-input.ng-invalid.ng-dirty, md-input-container.md-input-resized .md-input {
  padding-bottom: 0;
  border-width: 0 0 2px 0;
}
md-input-container .md-input[disabled], [disabled] md-input-container .md-input {
  background-position: bottom -1px left 0;
  background-size: 4px 1px;
  background-repeat: repeat-x;
}
md-input-container.md-icon-float {
  transition: margin-top 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
md-input-container.md-icon-float > label {
  pointer-events: none;
  position: absolute;
}
md-input-container.md-icon-float > md-icon {
  top: 8px;
  left: 2px;
  right: auto;
}
[dir=rtl] md-input-container.md-icon-float > md-icon {
  left: auto;
}
[dir=rtl] md-input-container.md-icon-float > md-icon {
  right: 2px;
}
md-input-container.md-icon-left > label:not(.md-no-float):not(.md-container-ignore),
md-input-container.md-icon-left > label .md-placeholder, md-input-container.md-icon-right > label:not(.md-no-float):not(.md-container-ignore),
md-input-container.md-icon-right > label .md-placeholder {
  width: calc(100% - 36px - 18px);
}
md-input-container.md-icon-left {
  padding-left: 36px;
  padding-right: 0;
}
[dir=rtl] md-input-container.md-icon-left {
  padding-left: 0;
}
[dir=rtl] md-input-container.md-icon-left {
  padding-right: 36px;
}
md-input-container.md-icon-left > label {
  left: 36px;
  right: auto;
}
[dir=rtl] md-input-container.md-icon-left > label {
  left: auto;
}
[dir=rtl] md-input-container.md-icon-left > label {
  right: 36px;
}
md-input-container.md-icon-right {
  padding-left: 0;
  padding-right: 36px;
}
[dir=rtl] md-input-container.md-icon-right {
  padding-left: 36px;
}
[dir=rtl] md-input-container.md-icon-right {
  padding-right: 0;
}
md-input-container.md-icon-right > md-icon:last-of-type {
  margin: 0;
  right: 2px;
  left: auto;
}
[dir=rtl] md-input-container.md-icon-right > md-icon:last-of-type {
  right: auto;
}
[dir=rtl] md-input-container.md-icon-right > md-icon:last-of-type {
  left: 2px;
}
md-input-container.md-icon-left.md-icon-right {
  padding-left: 36px;
  padding-right: 36px;
}
md-input-container.md-icon-left.md-icon-right > label:not(.md-no-float):not(.md-container-ignore),
md-input-container.md-icon-left.md-icon-right > label .md-placeholder {
  width: calc(100% - 36px * 2);
}

.md-resize-wrapper {
  position: relative;
}
.md-resize-wrapper:after {
  content: "";
  display: table;
  clear: both;
}

.md-resize-handle {
  position: absolute;
  bottom: -5px;
  left: 0;
  height: 10px;
  background: transparent;
  width: 100%;
  cursor: ns-resize;
}

@media screen and (-ms-high-contrast: active) {
  md-input-container.md-default-theme > md-icon {
    fill: #fff;
  }
}
md-list {
  display: block;
  padding: 8px 0px 8px 0px;
}
md-list .md-subheader {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.01em;
  line-height: 1.2em;
}
md-list.md-dense md-list-item,
md-list.md-dense md-list-item .md-list-item-inner {
  min-height: 48px;
}
md-list.md-dense md-list-item::before,
md-list.md-dense md-list-item .md-list-item-inner::before {
  content: "";
  min-height: 48px;
  visibility: hidden;
  display: inline-block;
}
md-list.md-dense md-list-item md-icon:first-child,
md-list.md-dense md-list-item .md-list-item-inner md-icon:first-child {
  width: 20px;
  height: 20px;
}
md-list.md-dense md-list-item > md-icon:first-child:not(.md-avatar-icon),
md-list.md-dense md-list-item .md-list-item-inner > md-icon:first-child:not(.md-avatar-icon) {
  margin-right: 36px;
}
[dir=rtl] md-list.md-dense md-list-item > md-icon:first-child:not(.md-avatar-icon),
[dir=rtl] md-list.md-dense md-list-item .md-list-item-inner > md-icon:first-child:not(.md-avatar-icon) {
  margin-right: auto;
  margin-left: 36px;
}
md-list.md-dense md-list-item .md-avatar, md-list.md-dense md-list-item .md-avatar-icon,
md-list.md-dense md-list-item .md-list-item-inner .md-avatar,
md-list.md-dense md-list-item .md-list-item-inner .md-avatar-icon {
  margin-right: 20px;
}
[dir=rtl] md-list.md-dense md-list-item .md-avatar, [dir=rtl] md-list.md-dense md-list-item .md-avatar-icon,
[dir=rtl] md-list.md-dense md-list-item .md-list-item-inner .md-avatar,
[dir=rtl] md-list.md-dense md-list-item .md-list-item-inner .md-avatar-icon {
  margin-right: auto;
  margin-left: 20px;
}
md-list.md-dense md-list-item .md-avatar,
md-list.md-dense md-list-item .md-list-item-inner .md-avatar {
  flex: none;
  width: 36px;
  height: 36px;
}
md-list.md-dense md-list-item.md-2-line .md-list-item-text.md-offset, md-list.md-dense md-list-item.md-2-line > .md-no-style .md-list-item-text.md-offset, md-list.md-dense md-list-item.md-3-line .md-list-item-text.md-offset, md-list.md-dense md-list-item.md-3-line > .md-no-style .md-list-item-text.md-offset {
  margin-left: 56px;
}
[dir=rtl] md-list.md-dense md-list-item.md-2-line .md-list-item-text.md-offset, [dir=rtl] md-list.md-dense md-list-item.md-2-line > .md-no-style .md-list-item-text.md-offset, [dir=rtl] md-list.md-dense md-list-item.md-3-line .md-list-item-text.md-offset, [dir=rtl] md-list.md-dense md-list-item.md-3-line > .md-no-style .md-list-item-text.md-offset {
  margin-left: auto;
  margin-right: 56px;
}
md-list.md-dense md-list-item.md-2-line .md-list-item-text h3,
md-list.md-dense md-list-item.md-2-line .md-list-item-text h4,
md-list.md-dense md-list-item.md-2-line .md-list-item-text p, md-list.md-dense md-list-item.md-2-line > .md-no-style .md-list-item-text h3,
md-list.md-dense md-list-item.md-2-line > .md-no-style .md-list-item-text h4,
md-list.md-dense md-list-item.md-2-line > .md-no-style .md-list-item-text p, md-list.md-dense md-list-item.md-3-line .md-list-item-text h3,
md-list.md-dense md-list-item.md-3-line .md-list-item-text h4,
md-list.md-dense md-list-item.md-3-line .md-list-item-text p, md-list.md-dense md-list-item.md-3-line > .md-no-style .md-list-item-text h3,
md-list.md-dense md-list-item.md-3-line > .md-no-style .md-list-item-text h4,
md-list.md-dense md-list-item.md-3-line > .md-no-style .md-list-item-text p {
  line-height: 1.05;
  font-size: 12px;
}
md-list.md-dense md-list-item.md-2-line .md-list-item-text h3, md-list.md-dense md-list-item.md-2-line > .md-no-style .md-list-item-text h3, md-list.md-dense md-list-item.md-3-line .md-list-item-text h3, md-list.md-dense md-list-item.md-3-line > .md-no-style .md-list-item-text h3 {
  font-size: 13px;
}
md-list.md-dense md-list-item.md-2-line, md-list.md-dense md-list-item.md-2-line > .md-no-style {
  min-height: 60px;
}
md-list.md-dense md-list-item.md-2-line::before, md-list.md-dense md-list-item.md-2-line > .md-no-style::before {
  content: "";
  min-height: 60px;
  visibility: hidden;
  display: inline-block;
}
md-list.md-dense md-list-item.md-2-line > .md-avatar, md-list.md-dense md-list-item.md-2-line .md-avatar-icon, md-list.md-dense md-list-item.md-2-line > .md-no-style > .md-avatar, md-list.md-dense md-list-item.md-2-line > .md-no-style .md-avatar-icon {
  margin-top: 12px;
}
md-list.md-dense md-list-item.md-3-line, md-list.md-dense md-list-item.md-3-line > .md-no-style {
  min-height: 76px;
}
md-list.md-dense md-list-item.md-3-line::before, md-list.md-dense md-list-item.md-3-line > .md-no-style::before {
  content: "";
  min-height: 76px;
  visibility: hidden;
  display: inline-block;
}
md-list.md-dense md-list-item.md-3-line > md-icon:first-child,
md-list.md-dense md-list-item.md-3-line > .md-avatar, md-list.md-dense md-list-item.md-3-line > .md-no-style > md-icon:first-child,
md-list.md-dense md-list-item.md-3-line > .md-no-style > .md-avatar {
  margin-top: 16px;
}

md-list-item {
  position: relative;
}
md-list-item.md-proxy-focus.md-focused .md-no-style {
  transition: background-color 0.15s linear;
}
md-list-item._md-button-wrap {
  position: relative;
}
md-list-item._md-button-wrap > div.md-button:first-child {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  margin: 0;
  font-weight: 400;
  text-align: left;
  border: medium none;
}
[dir=rtl] md-list-item._md-button-wrap > div.md-button:first-child {
  text-align: right;
}
md-list-item._md-button-wrap > div.md-button:first-child > .md-button:first-child {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  margin: 0;
  padding: 0;
}
md-list-item._md-button-wrap > div.md-button:first-child .md-list-item-inner {
  width: 100%;
  min-height: inherit;
}
md-list-item.md-no-proxy,
md-list-item .md-no-style {
  position: relative;
  padding: 0px 16px;
  flex: 1 1 auto;
}
md-list-item.md-no-proxy.md-button,
md-list-item .md-no-style.md-button {
  font-size: inherit;
  height: inherit;
  text-align: left;
  text-transform: none;
  width: 100%;
  white-space: normal;
  flex-direction: inherit;
  align-items: inherit;
  border-radius: 0;
  margin: 0;
}
[dir=rtl] md-list-item.md-no-proxy.md-button,
[dir=rtl] md-list-item .md-no-style.md-button {
  text-align: right;
}
md-list-item.md-no-proxy.md-button > .md-ripple-container,
md-list-item .md-no-style.md-button > .md-ripple-container {
  border-radius: 0;
}
md-list-item.md-no-proxy:focus,
md-list-item .md-no-style:focus {
  outline: none;
}
md-list-item.md-clickable:hover {
  cursor: pointer;
}
md-list-item md-divider {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
[dir=rtl] md-list-item md-divider {
  left: auto;
  right: 0;
}
md-list-item md-divider[md-inset] {
  left: 72px;
  width: calc(100% - 72px);
  margin: 0 !important;
}
[dir=rtl] md-list-item md-divider[md-inset] {
  left: auto;
  right: 72px;
}
md-list-item,
md-list-item .md-list-item-inner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 48px;
  height: auto;
}
md-list-item::before,
md-list-item .md-list-item-inner::before {
  content: "";
  min-height: 48px;
  visibility: hidden;
  display: inline-block;
}
md-list-item > div.md-primary > md-icon:not(.md-avatar-icon), md-list-item > div.md-secondary > md-icon:not(.md-avatar-icon), md-list-item > md-icon:first-child:not(.md-avatar-icon),
md-list-item > md-icon.md-secondary:not(.md-avatar-icon),
md-list-item .md-list-item-inner > div.md-primary > md-icon:not(.md-avatar-icon),
md-list-item .md-list-item-inner > div.md-secondary > md-icon:not(.md-avatar-icon),
md-list-item .md-list-item-inner > md-icon:first-child:not(.md-avatar-icon),
md-list-item .md-list-item-inner > md-icon.md-secondary:not(.md-avatar-icon) {
  width: 24px;
  margin-top: 16px;
  margin-bottom: 12px;
  box-sizing: content-box;
}
md-list-item > div.md-primary > md-checkbox, md-list-item > div.md-secondary > md-checkbox, md-list-item > md-checkbox,
md-list-item md-checkbox.md-secondary,
md-list-item .md-list-item-inner > div.md-primary > md-checkbox,
md-list-item .md-list-item-inner > div.md-secondary > md-checkbox,
md-list-item .md-list-item-inner > md-checkbox,
md-list-item .md-list-item-inner md-checkbox.md-secondary {
  align-self: center;
}
md-list-item > div.md-primary > md-checkbox .md-label, md-list-item > div.md-secondary > md-checkbox .md-label, md-list-item > md-checkbox .md-label,
md-list-item md-checkbox.md-secondary .md-label,
md-list-item .md-list-item-inner > div.md-primary > md-checkbox .md-label,
md-list-item .md-list-item-inner > div.md-secondary > md-checkbox .md-label,
md-list-item .md-list-item-inner > md-checkbox .md-label,
md-list-item .md-list-item-inner md-checkbox.md-secondary .md-label {
  display: none;
}
md-list-item > md-icon:first-child:not(.md-avatar-icon),
md-list-item .md-list-item-inner > md-icon:first-child:not(.md-avatar-icon) {
  margin-right: 32px;
}
[dir=rtl] md-list-item > md-icon:first-child:not(.md-avatar-icon),
[dir=rtl] md-list-item .md-list-item-inner > md-icon:first-child:not(.md-avatar-icon) {
  margin-right: auto;
  margin-left: 32px;
}
md-list-item .md-avatar, md-list-item .md-avatar-icon,
md-list-item .md-list-item-inner .md-avatar,
md-list-item .md-list-item-inner .md-avatar-icon {
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 16px;
  border-radius: 50%;
  box-sizing: content-box;
}
[dir=rtl] md-list-item .md-avatar, [dir=rtl] md-list-item .md-avatar-icon,
[dir=rtl] md-list-item .md-list-item-inner .md-avatar,
[dir=rtl] md-list-item .md-list-item-inner .md-avatar-icon {
  margin-right: auto;
  margin-left: 16px;
}
md-list-item .md-avatar,
md-list-item .md-list-item-inner .md-avatar {
  flex: none;
  width: 40px;
  height: 40px;
}
md-list-item .md-avatar-icon,
md-list-item .md-list-item-inner .md-avatar-icon {
  padding: 8px;
}
md-list-item .md-avatar-icon svg,
md-list-item .md-list-item-inner .md-avatar-icon svg {
  width: 24px;
  height: 24px;
}
md-list-item > md-checkbox,
md-list-item .md-list-item-inner > md-checkbox {
  width: 24px;
  margin-left: 3px;
  margin-right: 29px;
  margin-top: 16px;
}
[dir=rtl] md-list-item > md-checkbox,
[dir=rtl] md-list-item .md-list-item-inner > md-checkbox {
  margin-left: 29px;
}
[dir=rtl] md-list-item > md-checkbox,
[dir=rtl] md-list-item .md-list-item-inner > md-checkbox {
  margin-right: 3px;
}
md-list-item .md-secondary-container,
md-list-item .md-list-item-inner .md-secondary-container {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin: auto;
  margin-right: 0;
  margin-left: auto;
}
[dir=rtl] md-list-item .md-secondary-container,
[dir=rtl] md-list-item .md-list-item-inner .md-secondary-container {
  margin-right: auto;
}
[dir=rtl] md-list-item .md-secondary-container,
[dir=rtl] md-list-item .md-list-item-inner .md-secondary-container {
  margin-left: 0;
}
md-list-item .md-secondary-container .md-button:last-of-type, md-list-item .md-secondary-container .md-icon-button:last-of-type,
md-list-item .md-list-item-inner .md-secondary-container .md-button:last-of-type,
md-list-item .md-list-item-inner .md-secondary-container .md-icon-button:last-of-type {
  margin-right: 0;
}
[dir=rtl] md-list-item .md-secondary-container .md-button:last-of-type, [dir=rtl] md-list-item .md-secondary-container .md-icon-button:last-of-type,
[dir=rtl] md-list-item .md-list-item-inner .md-secondary-container .md-button:last-of-type,
[dir=rtl] md-list-item .md-list-item-inner .md-secondary-container .md-icon-button:last-of-type {
  margin-right: auto;
  margin-left: 0;
}
md-list-item .md-secondary-container md-checkbox,
md-list-item .md-list-item-inner .md-secondary-container md-checkbox {
  margin-top: 0;
  margin-bottom: 0;
}
md-list-item .md-secondary-container md-checkbox:last-child,
md-list-item .md-list-item-inner .md-secondary-container md-checkbox:last-child {
  width: 24px;
  margin-right: 0;
}
[dir=rtl] md-list-item .md-secondary-container md-checkbox:last-child,
[dir=rtl] md-list-item .md-list-item-inner .md-secondary-container md-checkbox:last-child {
  margin-right: auto;
  margin-left: 0;
}
md-list-item .md-secondary-container md-switch,
md-list-item .md-list-item-inner .md-secondary-container md-switch {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: -6px;
}
[dir=rtl] md-list-item .md-secondary-container md-switch,
[dir=rtl] md-list-item .md-list-item-inner .md-secondary-container md-switch {
  margin-right: auto;
  margin-left: -6px;
}
md-list-item > p, md-list-item > .md-list-item-inner > p,
md-list-item .md-list-item-inner > p,
md-list-item .md-list-item-inner > .md-list-item-inner > p {
  flex: 1 1 auto;
  margin: 0;
}
md-list-item.md-2-line, md-list-item.md-2-line > .md-no-style, md-list-item.md-3-line, md-list-item.md-3-line > .md-no-style {
  align-items: flex-start;
  justify-content: center;
}
md-list-item.md-2-line.md-long-text, md-list-item.md-2-line > .md-no-style.md-long-text, md-list-item.md-3-line.md-long-text, md-list-item.md-3-line > .md-no-style.md-long-text {
  margin-top: 8px;
  margin-bottom: 8px;
}
md-list-item.md-2-line .md-list-item-text, md-list-item.md-2-line > .md-no-style .md-list-item-text, md-list-item.md-3-line .md-list-item-text, md-list-item.md-3-line > .md-no-style .md-list-item-text {
  flex: 1 1 auto;
  margin: auto;
  text-overflow: ellipsis;
  overflow: hidden;
}
md-list-item.md-2-line .md-list-item-text.md-offset, md-list-item.md-2-line > .md-no-style .md-list-item-text.md-offset, md-list-item.md-3-line .md-list-item-text.md-offset, md-list-item.md-3-line > .md-no-style .md-list-item-text.md-offset {
  margin-left: 56px;
}
[dir=rtl] md-list-item.md-2-line .md-list-item-text.md-offset, [dir=rtl] md-list-item.md-2-line > .md-no-style .md-list-item-text.md-offset, [dir=rtl] md-list-item.md-3-line .md-list-item-text.md-offset, [dir=rtl] md-list-item.md-3-line > .md-no-style .md-list-item-text.md-offset {
  margin-left: auto;
  margin-right: 56px;
}
md-list-item.md-2-line .md-list-item-text h3, md-list-item.md-2-line > .md-no-style .md-list-item-text h3, md-list-item.md-3-line .md-list-item-text h3, md-list-item.md-3-line > .md-no-style .md-list-item-text h3 {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.01em;
  margin: 0 0 0px 0;
  line-height: 1.2em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
md-list-item.md-2-line .md-list-item-text h4, md-list-item.md-2-line > .md-no-style .md-list-item-text h4, md-list-item.md-3-line .md-list-item-text h4, md-list-item.md-3-line > .md-no-style .md-list-item-text h4 {
  font-size: 14px;
  letter-spacing: 0.01em;
  margin: 3px 0 1px 0;
  font-weight: 400;
  line-height: 1.2em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
md-list-item.md-2-line .md-list-item-text p, md-list-item.md-2-line > .md-no-style .md-list-item-text p, md-list-item.md-3-line .md-list-item-text p, md-list-item.md-3-line > .md-no-style .md-list-item-text p {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.01em;
  margin: 0 0 0 0;
  line-height: 1.6em;
}
md-list-item.md-2-line, md-list-item.md-2-line > .md-no-style {
  height: auto;
  min-height: 72px;
}
md-list-item.md-2-line::before, md-list-item.md-2-line > .md-no-style::before {
  content: "";
  min-height: 72px;
  visibility: hidden;
  display: inline-block;
}
md-list-item.md-2-line > .md-avatar, md-list-item.md-2-line .md-avatar-icon, md-list-item.md-2-line > .md-no-style > .md-avatar, md-list-item.md-2-line > .md-no-style .md-avatar-icon {
  margin-top: 12px;
}
md-list-item.md-2-line > md-icon:first-child, md-list-item.md-2-line > .md-no-style > md-icon:first-child {
  align-self: flex-start;
}
md-list-item.md-2-line .md-list-item-text, md-list-item.md-2-line > .md-no-style .md-list-item-text {
  flex: 1 1 auto;
}
md-list-item.md-3-line, md-list-item.md-3-line > .md-no-style {
  height: auto;
  min-height: 88px;
}
md-list-item.md-3-line::before, md-list-item.md-3-line > .md-no-style::before {
  content: "";
  min-height: 88px;
  visibility: hidden;
  display: inline-block;
}
md-list-item.md-3-line > md-icon:first-child,
md-list-item.md-3-line > .md-avatar, md-list-item.md-3-line > .md-no-style > md-icon:first-child,
md-list-item.md-3-line > .md-no-style > .md-avatar {
  margin-top: 16px;
}

.md-open-menu-container {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  opacity: 0;
  border-radius: 2px;
}
.md-open-menu-container md-menu-divider {
  margin-top: 4px;
  margin-bottom: 4px;
  height: 1px;
  min-height: 1px;
  max-height: 1px;
  width: 100%;
}
.md-open-menu-container md-menu-content > * {
  opacity: 0;
}
.md-open-menu-container:not(.md-clickable) {
  pointer-events: none;
}
.md-open-menu-container.md-active {
  opacity: 1;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 200ms;
}
.md-open-menu-container.md-active > md-menu-content > * {
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  transition-duration: 200ms;
  transition-delay: 100ms;
}
.md-open-menu-container.md-leave {
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  transition-duration: 250ms;
}

md-menu-content {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  max-height: 304px;
  overflow-y: auto;
}
md-menu-content.md-dense {
  max-height: 208px;
}
md-menu-content.md-dense md-menu-item {
  height: 32px;
  min-height: 0px;
}

md-menu-item {
  display: flex;
  flex-direction: row;
  min-height: 48px;
  height: 48px;
  align-content: center;
  justify-content: flex-start;
  /*
   * We cannot use flex on <button> elements due to a bug in Firefox, so we also can't use it on
   * <a> elements. Add some top padding to fix alignment since buttons automatically align their
   * text vertically.
   */
}
md-menu-item > * {
  width: 100%;
  margin: auto 0;
  padding-left: 16px;
  padding-right: 16px;
}
md-menu-item > a.md-button {
  padding-top: 5px;
}
md-menu-item > .md-button {
  text-align: left;
  display: inline-block;
  border-radius: 0;
  margin: auto 0;
  font-size: 15px;
  text-transform: none;
  font-weight: 400;
  height: 100%;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
}
md-menu-item > .md-button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
[dir=rtl] md-menu-item > .md-button {
  text-align: right;
}
md-menu-item > .md-button md-icon {
  margin: auto 16px auto 0;
}
[dir=rtl] md-menu-item > .md-button md-icon {
  margin: auto 0 auto 16px;
}
md-menu-item > .md-button p {
  display: inline-block;
  margin: auto;
}
md-menu-item > .md-button span {
  margin-top: auto;
  margin-bottom: auto;
}
md-menu-item > .md-button .md-ripple-container {
  border-radius: inherit;
}

md-toolbar .md-menu {
  height: auto;
  margin: auto;
  padding: 0;
}

@media (max-width: 959px) {
  md-menu-content {
    min-width: 112px;
  }
  md-menu-content[width="3"] {
    min-width: 168px;
  }
  md-menu-content[width="4"] {
    min-width: 224px;
  }
  md-menu-content[width="5"] {
    min-width: 280px;
  }
  md-menu-content[width="6"] {
    min-width: 336px;
  }
  md-menu-content[width="7"] {
    min-width: 392px;
  }
}
@media (min-width: 960px) {
  md-menu-content {
    min-width: 96px;
  }
  md-menu-content[width="3"] {
    min-width: 192px;
  }
  md-menu-content[width="4"] {
    min-width: 256px;
  }
  md-menu-content[width="5"] {
    min-width: 320px;
  }
  md-menu-content[width="6"] {
    min-width: 384px;
  }
  md-menu-content[width="7"] {
    min-width: 448px;
  }
}
md-toolbar.md-menu-toolbar h2.md-toolbar-tools {
  line-height: 1rem;
  height: auto;
  padding: 28px;
  padding-bottom: 12px;
}
md-toolbar.md-has-open-menu {
  position: relative;
  z-index: 100;
}

md-menu-bar {
  padding: 0 20px;
  display: block;
  position: relative;
  z-index: 2;
}
md-menu-bar .md-menu {
  display: inline-block;
  padding: 0;
  position: relative;
}
md-menu-bar button {
  font-size: 14px;
  padding: 0 10px;
  margin: 0;
  border: 0;
  background-color: transparent;
  height: 40px;
}
md-menu-bar md-backdrop.md-menu-backdrop {
  z-index: -2;
}

md-menu-content.md-menu-bar-menu.md-dense {
  max-height: none;
  padding: 16px 0;
}
md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent {
  position: relative;
}
md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent > md-icon {
  position: absolute;
  padding: 0;
  width: 24px;
  top: 6px;
  left: 24px;
}
[dir=rtl] md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent > md-icon {
  left: auto;
  right: 24px;
}
md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent > .md-button, md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent .md-menu > .md-button {
  padding: 0 32px 0 64px;
}
[dir=rtl] md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent > .md-button, [dir=rtl] md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent .md-menu > .md-button {
  padding: 0 64px 0 32px;
}
md-menu-content.md-menu-bar-menu.md-dense .md-button {
  min-height: 0;
  height: 32px;
  display: flex;
}
md-menu-content.md-menu-bar-menu.md-dense .md-button span {
  flex-grow: 1;
}
md-menu-content.md-menu-bar-menu.md-dense .md-button span.md-alt-text {
  flex-grow: 0;
  align-self: flex-end;
  margin: 0 8px;
}
md-menu-content.md-menu-bar-menu.md-dense md-menu-divider {
  margin: 8px 0;
}
md-menu-content.md-menu-bar-menu.md-dense md-menu-item > .md-button, md-menu-content.md-menu-bar-menu.md-dense .md-menu > .md-button {
  text-align: left;
}
[dir=rtl] md-menu-content.md-menu-bar-menu.md-dense md-menu-item > .md-button, [dir=rtl] md-menu-content.md-menu-bar-menu.md-dense .md-menu > .md-button {
  text-align: right;
}
md-menu-content.md-menu-bar-menu.md-dense .md-menu {
  padding: 0;
}
md-menu-content.md-menu-bar-menu.md-dense .md-menu > .md-button {
  position: relative;
  margin: 0;
  width: 100%;
  text-transform: none;
  font-weight: normal;
  border-radius: 0px;
  padding-left: 16px;
}
[dir=rtl] md-menu-content.md-menu-bar-menu.md-dense .md-menu > .md-button {
  padding-left: 0;
  padding-right: 16px;
}
md-menu-content.md-menu-bar-menu.md-dense .md-menu > .md-button:after {
  display: block;
  content: "▼";
  position: absolute;
  top: 0px;
  speak: none;
  transform: rotate(270deg) scaleY(0.45) scaleX(0.9);
  right: 28px;
}
[dir=rtl] md-menu-content.md-menu-bar-menu.md-dense .md-menu > .md-button:after {
  transform: rotate(90deg) scaleY(0.45) scaleX(0.9);
}
[dir=rtl] md-menu-content.md-menu-bar-menu.md-dense .md-menu > .md-button:after {
  right: auto;
  left: 28px;
}

/** Matches "md-tabs md-tabs-wrapper" style. */
.md-nav-bar {
  border-style: solid;
  border-width: 0 0 1px;
  height: 48px;
  position: relative;
}

._md-nav-bar-list {
  outline: none;
  list-style: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}

.md-nav-item:first-of-type {
  margin-left: 8px;
}

.md-button._md-nav-button {
  line-height: 24px;
  margin: 0 4px;
  padding: 12px 16px;
  transition: background-color 0.35s cubic-bezier(0.35, 0, 0.25, 1);
}
.md-button._md-nav-button:focus {
  outline: none;
}
.md-button._md-nav-button:hover {
  background-color: inherit;
}

md-nav-ink-bar {
  bottom: 0;
  height: 2px;
  left: auto;
  position: absolute;
  right: auto;
  background-color: black;
}
md-nav-ink-bar._md-left {
  transition: left 0.125s cubic-bezier(0.35, 0, 0.25, 1), right 0.25s cubic-bezier(0.35, 0, 0.25, 1);
}
md-nav-ink-bar._md-right {
  transition: left 0.25s cubic-bezier(0.35, 0, 0.25, 1), right 0.125s cubic-bezier(0.35, 0, 0.25, 1);
}

md-nav-extra-content {
  min-height: 48px;
  padding-right: 12px;
}

.md-panel-outer-wrapper {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

._md-panel-hidden {
  display: none;
}

._md-panel-fullscreen {
  border-radius: 0;
  left: 0;
  min-height: 100%;
  min-width: 100%;
  position: fixed;
  top: 0;
}

._md-panel-shown .md-panel {
  opacity: 1;
  transition: none;
}

.md-panel {
  opacity: 0;
  position: fixed;
}
.md-panel._md-panel-shown {
  opacity: 1;
  transition: none;
}
.md-panel._md-panel-animate-enter {
  opacity: 1;
  transition: all 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.md-panel._md-panel-animate-leave {
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.4, 0, 1, 1);
}
.md-panel._md-panel-animate-scale-out, .md-panel._md-panel-animate-fade-out {
  opacity: 0;
}
.md-panel._md-panel-backdrop {
  height: 100%;
  position: absolute;
  width: 100%;
}
.md-panel._md-opaque-enter {
  opacity: 0.48;
  transition: opacity 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.md-panel._md-opaque-leave {
  transition: opacity 0.3s cubic-bezier(0.4, 0, 1, 1);
}

@keyframes indeterminate-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
md-progress-circular {
  position: relative;
  display: block;
}
md-progress-circular._md-progress-circular-disabled {
  visibility: hidden;
}
md-progress-circular.md-mode-indeterminate svg {
  animation: indeterminate-rotate 2.9s linear infinite;
}
md-progress-circular svg {
  position: absolute;
  overflow: visible;
  top: 0;
  left: 0;
}

md-progress-linear {
  display: block;
  position: relative;
  width: 100%;
  height: 5px;
  padding-top: 0 !important;
  margin-bottom: 0 !important;
}
md-progress-linear._md-progress-linear-disabled {
  visibility: hidden;
}
md-progress-linear .md-container {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 5px;
  transform: translate(0, 0) scale(1, 1);
}
md-progress-linear .md-container .md-bar {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
}
md-progress-linear .md-container .md-dashed:before {
  content: "";
  display: none;
  position: absolute;
  margin-top: 0;
  height: 5px;
  width: 100%;
  background-color: transparent;
  background-size: 10px 10px !important;
  background-position: 0px -23px;
}
md-progress-linear .md-container .md-bar1, md-progress-linear .md-container .md-bar2 {
  transition: transform 0.2s linear;
}
md-progress-linear .md-container.md-mode-query .md-bar1 {
  display: none;
}
md-progress-linear .md-container.md-mode-query .md-bar2 {
  transition: all 0.2s linear;
  animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
}
md-progress-linear .md-container.md-mode-determinate .md-bar1 {
  display: none;
}
md-progress-linear .md-container.md-mode-indeterminate .md-bar1 {
  animation: md-progress-linear-indeterminate-scale-1 4s infinite, md-progress-linear-indeterminate-1 4s infinite;
}
md-progress-linear .md-container.md-mode-indeterminate .md-bar2 {
  animation: md-progress-linear-indeterminate-scale-2 4s infinite, md-progress-linear-indeterminate-2 4s infinite;
}
md-progress-linear .md-container.ng-hide ._md-progress-linear-disabled md-progress-linear .md-container {
  animation: none;
}
md-progress-linear .md-container.ng-hide ._md-progress-linear-disabled md-progress-linear .md-container .md-bar1 {
  animation-name: none;
}
md-progress-linear .md-container.ng-hide ._md-progress-linear-disabled md-progress-linear .md-container .md-bar2 {
  animation-name: none;
}
md-progress-linear .md-container.md-mode-buffer {
  background-color: transparent !important;
  transition: all 0.2s linear;
}
md-progress-linear .md-container.md-mode-buffer .md-dashed:before {
  display: block;
  animation: buffer 3s infinite linear;
}

@keyframes query {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(0.3, 1);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1);
  }
}
@keyframes buffer {
  0% {
    opacity: 1;
    background-position: 0px -23px;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    background-position: -200px -23px;
  }
}
@keyframes md-progress-linear-indeterminate-scale-1 {
  0% {
    transform: scaleX(0.1);
    animation-timing-function: linear;
  }
  36.6% {
    transform: scaleX(0.1);
    animation-timing-function: cubic-bezier(0.334731432, 0.124819821, 0.785843996, 1);
  }
  69.15% {
    transform: scaleX(0.83);
    animation-timing-function: cubic-bezier(0.225732004, 0, 0.233648906, 1.3709798);
  }
  100% {
    transform: scaleX(0.1);
  }
}
@keyframes md-progress-linear-indeterminate-1 {
  0% {
    left: -105.1666666667%;
    animation-timing-function: linear;
  }
  20% {
    left: -105.1666666667%;
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495818703);
  }
  69.15% {
    left: 21.5%;
    animation-timing-function: cubic-bezier(0.302435, 0.38135197, 0.55, 0.956352125);
  }
  100% {
    left: 95.4444444444%;
  }
}
@keyframes md-progress-linear-indeterminate-scale-2 {
  0% {
    transform: scaleX(0.1);
    animation-timing-function: cubic-bezier(0.205028172, 0.057050836, 0.57660995, 0.453970841);
  }
  19.15% {
    transform: scaleX(0.57);
    animation-timing-function: cubic-bezier(0.152312994, 0.196431957, 0.648373778, 1.00431535);
  }
  44.15% {
    transform: scaleX(0.91);
    animation-timing-function: cubic-bezier(0.25775882, -0.003163357, 0.211761916, 1.38178961);
  }
  100% {
    transform: scaleX(0.1);
  }
}
@keyframes md-progress-linear-indeterminate-2 {
  0% {
    left: -54.8888888889%;
    animation-timing-function: cubic-bezier(0.15, 0, 0.5150584, 0.409684966);
  }
  25% {
    left: -17.25%;
    animation-timing-function: cubic-bezier(0.3103299, 0.284057684, 0.8, 0.733718979);
  }
  48.35% {
    left: 29.5%;
    animation-timing-function: cubic-bezier(0.4, 0.627034903, 0.6, 0.902025796);
  }
  100% {
    left: 117.3888888889%;
  }
}
md-radio-button {
  box-sizing: border-box;
  display: block;
  margin-bottom: 16px;
  white-space: nowrap;
  cursor: pointer;
  position: relative;
}
md-radio-button[disabled] {
  cursor: default;
}
md-radio-button[disabled] .md-container {
  cursor: default;
}
md-radio-button .md-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;
  left: 0;
  right: auto;
}
[dir=rtl] md-radio-button .md-container {
  left: auto;
}
[dir=rtl] md-radio-button .md-container {
  right: 0;
}
md-radio-button .md-container .md-ripple-container {
  position: absolute;
  display: block;
  width: auto;
  height: auto;
  left: -15px;
  top: -15px;
  right: -15px;
  bottom: -15px;
}
md-radio-button .md-container:before {
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 50%;
  content: "";
  position: absolute;
  display: block;
  height: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition: all 0.5s;
  width: auto;
}
md-radio-button.md-align-top-left > div.md-container {
  top: 12px;
}
md-radio-button .md-off {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
  transition: border-color ease 0.28s;
}
md-radio-button .md-on {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: transform ease 0.28s;
  transform: scale(0);
}
md-radio-button.md-checked .md-on {
  transform: scale(0.5);
}
md-radio-button .md-label {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  margin-left: 30px;
  margin-right: 0;
  vertical-align: middle;
  white-space: normal;
  pointer-events: none;
  width: auto;
}
[dir=rtl] md-radio-button .md-label {
  margin-left: 0;
}
[dir=rtl] md-radio-button .md-label {
  margin-right: 30px;
}

md-radio-group {
  /** Layout adjustments for the radio group. */
}
md-radio-group.layout-column md-radio-button, md-radio-group.layout-xs-column md-radio-button, md-radio-group.layout-gt-xs-column md-radio-button, md-radio-group.layout-sm-column md-radio-button, md-radio-group.layout-gt-sm-column md-radio-button, md-radio-group.layout-md-column md-radio-button, md-radio-group.layout-gt-md-column md-radio-button, md-radio-group.layout-lg-column md-radio-button, md-radio-group.layout-gt-lg-column md-radio-button, md-radio-group.layout-xl-column md-radio-button {
  margin-bottom: 16px;
}
md-radio-group.layout-row md-radio-button, md-radio-group.layout-xs-row md-radio-button, md-radio-group.layout-gt-xs-row md-radio-button, md-radio-group.layout-sm-row md-radio-button, md-radio-group.layout-gt-sm-row md-radio-button, md-radio-group.layout-md-row md-radio-button, md-radio-group.layout-gt-md-row md-radio-button, md-radio-group.layout-lg-row md-radio-button, md-radio-group.layout-gt-lg-row md-radio-button, md-radio-group.layout-xl-row md-radio-button {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 16px;
}
[dir=rtl] md-radio-group.layout-row md-radio-button, [dir=rtl] md-radio-group.layout-xs-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-xs-row md-radio-button, [dir=rtl] md-radio-group.layout-sm-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-sm-row md-radio-button, [dir=rtl] md-radio-group.layout-md-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-md-row md-radio-button, [dir=rtl] md-radio-group.layout-lg-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-lg-row md-radio-button, [dir=rtl] md-radio-group.layout-xl-row md-radio-button {
  margin-left: 16px;
}
[dir=rtl] md-radio-group.layout-row md-radio-button, [dir=rtl] md-radio-group.layout-xs-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-xs-row md-radio-button, [dir=rtl] md-radio-group.layout-sm-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-sm-row md-radio-button, [dir=rtl] md-radio-group.layout-md-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-md-row md-radio-button, [dir=rtl] md-radio-group.layout-lg-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-lg-row md-radio-button, [dir=rtl] md-radio-group.layout-xl-row md-radio-button {
  margin-right: 0;
}
md-radio-group.layout-row md-radio-button:last-of-type, md-radio-group.layout-xs-row md-radio-button:last-of-type, md-radio-group.layout-gt-xs-row md-radio-button:last-of-type, md-radio-group.layout-sm-row md-radio-button:last-of-type, md-radio-group.layout-gt-sm-row md-radio-button:last-of-type, md-radio-group.layout-md-row md-radio-button:last-of-type, md-radio-group.layout-gt-md-row md-radio-button:last-of-type, md-radio-group.layout-lg-row md-radio-button:last-of-type, md-radio-group.layout-gt-lg-row md-radio-button:last-of-type, md-radio-group.layout-xl-row md-radio-button:last-of-type {
  margin-left: 0;
  margin-right: 0;
}
md-radio-group:focus {
  outline: none;
}
md-radio-group.md-focused .md-checked .md-container:before {
  left: -8px;
  top: -8px;
  right: -8px;
  bottom: -8px;
}
md-radio-group[disabled] md-radio-button {
  cursor: default;
}
md-radio-group[disabled] md-radio-button .md-container {
  cursor: default;
}

.md-inline-form md-radio-group {
  margin: 18px 0 19px;
}
.md-inline-form md-radio-group md-radio-button {
  display: inline-block;
  height: 30px;
  padding: 2px;
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 0;
}

@media screen and (-ms-high-contrast: active) {
  md-radio-button.md-default-theme .md-on {
    background-color: #fff;
  }
}
md-input-container:not([md-no-float]) .md-select-placeholder span:first-child {
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform-origin: left top;
}
[dir=rtl] md-input-container:not([md-no-float]) .md-select-placeholder span:first-child {
  transform-origin: right top;
}
md-input-container.md-input-focused:not([md-no-float]) .md-select-placeholder span:first-child {
  transform: translateY(-22px) translateX(-2px) scale(0.75);
}

.md-select-menu-container {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 90;
  opacity: 0;
  display: none;
  transform: translateY(-1px);
}
.md-select-menu-container:not(.md-clickable) {
  pointer-events: none;
}
.md-select-menu-container md-progress-circular {
  display: table;
  margin: 24px auto !important;
}
.md-select-menu-container.md-active {
  display: block;
  opacity: 1;
}
.md-select-menu-container.md-active md-select-menu {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 150ms;
}
.md-select-menu-container.md-active md-select-menu > * {
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  transition-duration: 150ms;
  transition-delay: 100ms;
}
.md-select-menu-container.md-leave {
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  transition-duration: 250ms;
}

md-input-container > md-select {
  margin: 0;
  order: 2;
}

md-input-container:not(.md-input-has-value) md-select[required]:not(.md-no-asterisk) .md-select-value span:first-child:after, md-input-container:not(.md-input-has-value) md-select.ng-required:not(.md-no-asterisk) .md-select-value span:first-child:after {
  content: " *";
  font-size: 13px;
  vertical-align: top;
}

md-input-container.md-input-invalid md-select .md-select-value {
  border-bottom-style: solid;
  padding-bottom: 1px;
}

md-select {
  display: flex;
  margin: 20px 0 26px 0;
}
md-select[required].ng-invalid:not(.md-no-asterisk) .md-select-value span:first-child:after, md-select.ng-required.ng-invalid:not(.md-no-asterisk) .md-select-value span:first-child:after {
  content: " *";
  font-size: 13px;
  vertical-align: top;
}
md-select[disabled] .md-select-value {
  background-position: 0 bottom;
  background-size: 4px 1px;
  background-repeat: repeat-x;
  margin-bottom: -1px;
}
md-select:focus {
  outline: none;
}
md-select[disabled]:hover {
  cursor: default;
}
md-select:not([disabled]):hover {
  cursor: pointer;
}
md-select:not([disabled]).ng-invalid.ng-touched .md-select-value {
  border-bottom-style: solid;
  padding-bottom: 1px;
}
md-select:not([disabled]):focus .md-select-value {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  padding-bottom: 0;
}
md-select:not([disabled]):focus.ng-invalid.ng-touched .md-select-value {
  padding-bottom: 0;
}

md-input-container.md-input-has-value .md-select-value > span:not(.md-select-icon) {
  transform: translate3d(0, 1px, 0);
}

.md-select-value {
  display: flex;
  align-items: center;
  padding: 2px 2px 1px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  background-color: rgba(0, 0, 0, 0);
  position: relative;
  box-sizing: content-box;
  min-width: 64px;
  min-height: 26px;
  flex-grow: 1;
}
.md-select-value > span:not(.md-select-icon) {
  max-width: 100%;
  flex: 1 1 auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.md-select-value > span:not(.md-select-icon) .md-text {
  display: inline;
}
.md-select-value .md-select-icon {
  display: block;
  align-items: flex-end;
  text-align: end;
  width: 24px;
  margin: 0 4px;
  transform: translate3d(0, -2px, 0);
  font-size: 1.2rem;
}
.md-select-value .md-select-icon:after {
  display: block;
  content: "▼";
  position: relative;
  top: 2px;
  speak: none;
  font-size: 13px;
  transform: scaleY(0.5) scaleX(1);
}
.md-select-value.md-select-placeholder {
  display: flex;
  order: 1;
  pointer-events: none;
  -webkit-font-smoothing: antialiased;
  padding-left: 2px;
  z-index: 1;
}

md-select-menu {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  max-height: 256px;
  min-height: 48px;
  overflow-y: hidden;
  transform-origin: left top;
  transform: scale(1);
}
md-select-menu.md-reverse {
  flex-direction: column-reverse;
}
md-select-menu:not(.md-overflow) md-content {
  padding-top: 8px;
  padding-bottom: 8px;
}
[dir=rtl] md-select-menu {
  transform-origin: right top;
}
md-select-menu md-content {
  min-width: 136px;
  min-height: 48px;
  max-height: 256px;
  overflow-y: auto;
}
md-select-menu > * {
  opacity: 0;
}

md-option {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
  transition: background 0.15s linear;
  padding: 0 16px 0 16px;
  height: 48px;
}
md-option[disabled] {
  cursor: default;
}
md-option:focus {
  outline: none;
}
md-option .md-text {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

md-optgroup {
  display: block;
}
md-optgroup label {
  display: block;
  font-size: 14px;
  text-transform: uppercase;
  padding: 16px;
  font-weight: 500;
}
md-optgroup md-option {
  padding-left: 32px;
  padding-right: 32px;
}

@media screen and (-ms-high-contrast: active) {
  .md-select-backdrop {
    background-color: transparent;
  }
  md-select-menu {
    border: 1px solid #fff;
  }
}
md-select-menu[multiple] md-option.md-checkbox-enabled {
  padding-left: 40px;
  padding-right: 16px;
}
[dir=rtl] md-select-menu[multiple] md-option.md-checkbox-enabled {
  padding-left: 16px;
}
[dir=rtl] md-select-menu[multiple] md-option.md-checkbox-enabled {
  padding-right: 40px;
}
md-select-menu[multiple] md-option.md-checkbox-enabled .md-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
  display: inline-block;
  width: 20px;
  height: 20px;
  left: 0;
  right: auto;
}
[dir=rtl] md-select-menu[multiple] md-option.md-checkbox-enabled .md-container {
  left: auto;
}
[dir=rtl] md-select-menu[multiple] md-option.md-checkbox-enabled .md-container {
  right: 0;
}
md-select-menu[multiple] md-option.md-checkbox-enabled .md-container:before {
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 50%;
  content: "";
  position: absolute;
  display: block;
  height: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition: all 0.5s;
  width: auto;
}
md-select-menu[multiple] md-option.md-checkbox-enabled .md-container:after {
  box-sizing: border-box;
  content: "";
  position: absolute;
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
}
md-select-menu[multiple] md-option.md-checkbox-enabled .md-container .md-ripple-container {
  position: absolute;
  display: block;
  width: auto;
  height: auto;
  left: -15px;
  top: -15px;
  right: -15px;
  bottom: -15px;
}
md-select-menu[multiple] md-option.md-checkbox-enabled .md-icon {
  box-sizing: border-box;
  transition: 240ms;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-width: 2px;
  border-style: solid;
  border-radius: 2px;
}
md-select-menu[multiple] md-option.md-checkbox-enabled[selected] .md-icon {
  border-color: transparent;
}
md-select-menu[multiple] md-option.md-checkbox-enabled[selected] .md-icon:after {
  box-sizing: border-box;
  transform: rotate(45deg);
  position: absolute;
  left: 4.6666666667px;
  top: 0.2222222222px;
  display: table;
  width: 6.6666666667px;
  height: 13.3333333333px;
  border-width: 2px;
  border-style: solid;
  border-top: 0;
  border-left: 0;
  content: "";
}
md-select-menu[multiple] md-option.md-checkbox-enabled[disabled] {
  cursor: default;
}
md-select-menu[multiple] md-option.md-checkbox-enabled.md-indeterminate .md-icon:after {
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: table;
  width: 12px;
  height: 2px;
  border-width: 2px;
  border-style: solid;
  border-top: 0;
  border-left: 0;
  content: "";
}
md-select-menu[multiple] md-option.md-checkbox-enabled .md-container {
  margin-left: 10.6666666667px;
  margin-right: auto;
}
[dir=rtl] md-select-menu[multiple] md-option.md-checkbox-enabled .md-container {
  margin-left: auto;
}
[dir=rtl] md-select-menu[multiple] md-option.md-checkbox-enabled .md-container {
  margin-right: 10.6666666667px;
}

md-sidenav {
  box-sizing: border-box;
  position: absolute;
  flex-direction: column;
  z-index: 60;
  width: 320px;
  max-width: 320px;
  bottom: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
md-sidenav ul {
  list-style: none;
}
md-sidenav.md-closed {
  display: none;
}
md-sidenav.md-closed-add, md-sidenav.md-closed-remove {
  display: flex;
  transition: 0.2s ease-in all;
}
md-sidenav.md-closed-add.md-closed-add-active, md-sidenav.md-closed-remove.md-closed-remove-active {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
md-sidenav.md-locked-open-add, md-sidenav.md-locked-open-remove {
  position: static;
  display: flex;
  transform: translate3d(0, 0, 0);
}
md-sidenav.md-locked-open, md-sidenav.md-locked-open.md-closed, md-sidenav.md-locked-open.md-closed.md-sidenav-left, md-sidenav.md-locked-open.md-closed.md-sidenav-right, md-sidenav.md-locked-open-remove.md-closed {
  position: static;
  display: flex;
  transform: translate3d(0, 0, 0);
}
md-sidenav.md-locked-open-remove-active {
  transition: width 0.3s cubic-bezier(0.55, 0, 0.55, 0.2), min-width 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  width: 0 !important;
  min-width: 0 !important;
}
md-sidenav.md-closed.md-locked-open-add {
  width: 0 !important;
  min-width: 0 !important;
  transform: translate3d(0%, 0, 0);
}
md-sidenav.md-closed.md-locked-open-add-active {
  transition: width 0.3s cubic-bezier(0.55, 0, 0.55, 0.2), min-width 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  width: 320px;
  min-width: 320px;
  transform: translate3d(0%, 0, 0);
}

.md-sidenav-backdrop.md-locked-open {
  display: none;
}

.md-sidenav-left, md-sidenav {
  left: 0;
  top: 0;
  transform: translate3d(0%, 0, 0);
}
.md-sidenav-left.md-closed, md-sidenav.md-closed {
  transform: translate3d(-100%, 0, 0);
}

.md-sidenav-right {
  left: 100%;
  top: 0;
  transform: translate(-100%, 0);
}
.md-sidenav-right.md-closed {
  transform: translate(0%, 0);
}

@media (min-width: 600px) {
  md-sidenav {
    max-width: 400px;
  }
}
@media (max-width: 456px) {
  md-sidenav {
    width: calc(100% - 56px);
    min-width: calc(100% - 56px);
    max-width: calc(100% - 56px);
  }
}
@media screen and (-ms-high-contrast: active) {
  .md-sidenav-left, md-sidenav {
    border-right: 1px solid #fff;
  }
  .md-sidenav-right {
    border-left: 1px solid #fff;
  }
}
@keyframes sliderFocusThumb {
  0% {
    transform: scale(0.7);
  }
  30% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.7);
  }
}
@keyframes sliderDiscreteFocusThumb {
  0% {
    transform: scale(0.7);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes sliderDiscreteFocusRing {
  0% {
    transform: scale(0.7);
    opacity: 0;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
  }
}
md-slider {
  height: 48px;
  min-width: 128px;
  position: relative;
  margin-left: 4px;
  margin-right: 4px;
  padding: 0;
  display: block;
  flex-direction: row;
  /**
   * Track
   */
  /**
   * Slider thumb
   */
  /* The sign that's focused in discrete mode */
  /**
   * The border/background that comes in when focused in non-discrete mode
   */
  /* Don't animate left/right while panning */
}
md-slider *, md-slider *:after {
  box-sizing: border-box;
}
md-slider .md-slider-wrapper {
  outline: none;
  width: 100%;
  height: 100%;
}
md-slider .md-slider-content {
  position: relative;
}
md-slider .md-track-container {
  width: 100%;
  position: absolute;
  top: 23px;
  height: 2px;
}
md-slider .md-track {
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
}
md-slider .md-track-fill {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-property: width, height;
}
md-slider .md-track-ticks {
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
}
md-slider .md-track-ticks canvas {
  width: 100%;
  height: 100%;
}
md-slider .md-thumb-container {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-property: left, right, bottom;
}
[dir=rtl] md-slider .md-thumb-container {
  left: auto;
  right: 0;
}
md-slider .md-thumb {
  z-index: 1;
  position: absolute;
  left: -10px;
  top: 14px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  transform: scale(0.7);
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
[dir=rtl] md-slider .md-thumb {
  left: auto;
  right: -10px;
}
md-slider .md-thumb:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border-width: 3px;
  border-style: solid;
  transition: inherit;
}
md-slider .md-sign {
  /* Center the children (slider-thumb-text) */
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -14px;
  top: -17px;
  width: 28px;
  height: 28px;
  border-radius: 28px;
  transform: scale(0.4) translate3d(0, 67.5px, 0);
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  /* The arrow pointing down under the sign */
}
md-slider .md-sign:after {
  position: absolute;
  content: "";
  left: 0px;
  border-radius: 16px;
  top: 19px;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-top-width: 16px;
  border-top-style: solid;
  opacity: 0;
  transform: translate3d(0, -8px, 0);
  transition: all 0.2s cubic-bezier(0.35, 0, 0.25, 1);
}
[dir=rtl] md-slider .md-sign:after {
  left: auto;
  right: 0px;
}
md-slider .md-sign .md-thumb-text {
  z-index: 1;
  font-size: 12px;
  font-weight: bold;
}
md-slider .md-focus-ring {
  position: absolute;
  left: -17px;
  top: 7px;
  width: 34px;
  height: 34px;
  border-radius: 34px;
  transform: scale(0.7);
  opacity: 0;
  transition: all 0.35s cubic-bezier(0.35, 0, 0.25, 1);
}
[dir=rtl] md-slider .md-focus-ring {
  left: auto;
  right: -17px;
}
md-slider .md-disabled-thumb {
  position: absolute;
  left: -14px;
  top: 10px;
  width: 28px;
  height: 28px;
  border-radius: 28px;
  transform: scale(0.5);
  border-width: 4px;
  border-style: solid;
  display: none;
}
[dir=rtl] md-slider .md-disabled-thumb {
  left: auto;
  right: -14px;
}
md-slider.md-min .md-sign {
  opacity: 0;
}
md-slider:focus {
  outline: none;
}
md-slider.md-dragging .md-thumb-container,
md-slider.md-dragging .md-track-fill {
  transition: none;
}
md-slider:not([md-discrete]) {
  /* Hide the sign and ticks in non-discrete mode */
}
md-slider:not([md-discrete]) .md-track-ticks,
md-slider:not([md-discrete]) .md-sign {
  display: none;
}
md-slider:not([md-discrete]):not([disabled]) .md-slider-wrapper .md-thumb:hover {
  transform: scale(0.8);
}
md-slider:not([md-discrete]):not([disabled]) .md-slider-wrapper.md-focused .md-focus-ring {
  transform: scale(1);
  opacity: 1;
}
md-slider:not([md-discrete]):not([disabled]) .md-slider-wrapper.md-focused .md-thumb {
  animation: sliderFocusThumb 0.7s cubic-bezier(0.35, 0, 0.25, 1);
}
md-slider:not([md-discrete]):not([disabled]).md-active .md-slider-wrapper .md-thumb {
  transform: scale(1);
}
md-slider[md-discrete]:not([disabled]) .md-slider-wrapper.md-focused .md-focus-ring {
  transform: scale(0);
  animation: sliderDiscreteFocusRing 0.5s cubic-bezier(0.35, 0, 0.25, 1);
}
md-slider[md-discrete]:not([disabled]) .md-slider-wrapper.md-focused .md-thumb {
  animation: sliderDiscreteFocusThumb 0.5s cubic-bezier(0.35, 0, 0.25, 1);
}
md-slider[md-discrete]:not([disabled]) .md-slider-wrapper.md-focused .md-thumb, md-slider[md-discrete]:not([disabled]).md-active .md-thumb {
  transform: scale(0);
}
md-slider[md-discrete]:not([disabled]) .md-slider-wrapper.md-focused .md-sign,
md-slider[md-discrete]:not([disabled]) .md-slider-wrapper.md-focused .md-sign:after, md-slider[md-discrete]:not([disabled]).md-active .md-sign,
md-slider[md-discrete]:not([disabled]).md-active .md-sign:after {
  opacity: 1;
  transform: translate3d(0, 0, 0) scale(1);
}
md-slider[md-discrete][disabled][readonly] .md-thumb {
  transform: scale(0);
}
md-slider[md-discrete][disabled][readonly] .md-sign,
md-slider[md-discrete][disabled][readonly] .md-sign:after {
  opacity: 1;
  transform: translate3d(0, 0, 0) scale(1);
}
md-slider[disabled] .md-track-fill {
  display: none;
}
md-slider[disabled] .md-track-ticks {
  opacity: 0;
}
md-slider[disabled]:not([readonly]) .md-sign {
  opacity: 0;
}
md-slider[disabled] .md-thumb {
  transform: scale(0.5);
}
md-slider[disabled] .md-disabled-thumb {
  display: block;
}
md-slider[md-vertical] {
  flex-direction: column;
  min-height: 128px;
  min-width: 0;
}
md-slider[md-vertical] .md-slider-wrapper {
  flex: 1;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 48px;
  align-self: center;
  display: flex;
  justify-content: center;
}
md-slider[md-vertical] .md-track-container {
  height: 100%;
  width: 2px;
  top: 0;
  left: calc(50% - 2px / 2);
}
md-slider[md-vertical] .md-thumb-container {
  top: auto;
  margin-bottom: 23px;
  left: calc(50% - 1px);
  bottom: 0;
}
md-slider[md-vertical] .md-thumb-container .md-thumb:after {
  left: 1px;
}
md-slider[md-vertical] .md-thumb-container .md-focus-ring {
  left: -16px;
}
md-slider[md-vertical] .md-track-fill {
  bottom: 0;
}
md-slider[md-vertical][md-discrete] .md-sign {
  left: -40px;
  top: 9.5px;
  transform: scale(0.4) translate3d(67.5px, 0, 0);
  /* The arrow pointing left next the sign */
}
md-slider[md-vertical][md-discrete] .md-sign:after {
  top: 9.5px;
  left: 19px;
  border-top: 14px solid transparent;
  border-right: 0;
  border-bottom: 14px solid transparent;
  border-left-width: 16px;
  border-left-style: solid;
  opacity: 0;
  transform: translate3d(0, -8px, 0);
  transition: all 0.2s ease-in-out;
}
md-slider[md-vertical][md-discrete] .md-sign .md-thumb-text {
  z-index: 1;
  font-size: 12px;
  font-weight: bold;
}
md-slider[md-vertical][md-discrete].md-active .md-sign:after,
md-slider[md-vertical][md-discrete] .md-focused .md-sign:after, md-slider[md-vertical][md-discrete][disabled][readonly] .md-sign:after {
  top: 0;
}
md-slider[md-vertical][disabled][readonly] .md-thumb {
  transform: scale(0);
}
md-slider[md-vertical][disabled][readonly] .md-sign,
md-slider[md-vertical][disabled][readonly] .md-sign:after {
  opacity: 1;
  transform: translate3d(0, 0, 0) scale(1);
}
md-slider[md-invert]:not([md-vertical]) .md-track-fill {
  left: auto;
  right: 0;
}
[dir=rtl] md-slider[md-invert]:not([md-vertical]) .md-track-fill {
  left: 0;
}
[dir=rtl] md-slider[md-invert]:not([md-vertical]) .md-track-fill {
  right: auto;
}
md-slider[md-invert][md-vertical] .md-track-fill {
  bottom: auto;
  top: 0;
}

md-slider-container {
  display: flex;
  align-items: center;
  flex-direction: row;
}
md-slider-container > *:first-child:not(md-slider), md-slider-container > *:last-child:not(md-slider) {
  min-width: 25px;
  max-width: 42px;
  height: 25px;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-property: color, max-width;
}
md-slider-container > *:first-child:not(md-slider) {
  margin-right: 16px;
}
[dir=rtl] md-slider-container > *:first-child:not(md-slider) {
  margin-right: auto;
  margin-left: 16px;
}
md-slider-container > *:last-child:not(md-slider) {
  margin-left: 16px;
}
[dir=rtl] md-slider-container > *:last-child:not(md-slider) {
  margin-left: auto;
  margin-right: 16px;
}
md-slider-container[md-vertical] {
  flex-direction: column;
}
md-slider-container[md-vertical] > *:first-child:not(md-slider), md-slider-container[md-vertical] > *:last-child:not(md-slider) {
  margin-right: 0;
  margin-left: 0;
  text-align: center;
}
md-slider-container md-input-container input[type=number] {
  text-align: center;
  padding-left: 15px;
  height: 50px;
  margin-top: -25px;
}
[dir=rtl] md-slider-container md-input-container input[type=number] {
  padding-left: 0;
  padding-right: 15px;
}

@media screen and (-ms-high-contrast: active) {
  md-slider.md-default-theme .md-track {
    border-bottom: 1px solid #fff;
  }
}
.md-sticky-clone {
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  position: absolute !important;
  transform: translate3d(-9999px, -9999px, 0);
}
.md-sticky-clone[sticky-state=active] {
  transform: translate3d(0, 0, 0);
}
.md-sticky-clone[sticky-state=active]:not(.md-sticky-no-effect) .md-subheader-inner {
  animation: subheaderStickyHoverIn 0.3s ease-out both;
}

@keyframes subheaderStickyHoverIn {
  0% {
    box-shadow: 0 0 0 0 transparent;
  }
  100% {
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.16);
  }
}
@keyframes subheaderStickyHoverOut {
  0% {
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.16);
  }
  100% {
    box-shadow: 0 0 0 0 transparent;
  }
}
.md-subheader-wrapper:not(.md-sticky-no-effect) {
  transition: 0.2s ease-out margin;
}
.md-subheader-wrapper:not(.md-sticky-no-effect) .md-subheader {
  margin: 0;
}
.md-subheader-wrapper:not(.md-sticky-no-effect).md-sticky-clone {
  z-index: 2;
}
.md-subheader-wrapper:not(.md-sticky-no-effect)[sticky-state=active] {
  margin-top: -2px;
}
.md-subheader-wrapper:not(.md-sticky-no-effect):not(.md-sticky-clone)[sticky-prev-state=active] .md-subheader-inner:after {
  animation: subheaderStickyHoverOut 0.3s ease-out both;
}

.md-subheader {
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1em;
  margin: 0 0 0 0;
  position: relative;
}
.md-subheader .md-subheader-inner {
  display: block;
  padding: 16px;
}
.md-subheader .md-subheader-content {
  display: block;
  z-index: 1;
  position: relative;
}

.md-inline-form md-switch {
  margin-top: 18px;
  margin-bottom: 19px;
}

md-switch {
  margin: 16px 0;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  user-select: none;
  height: 30px;
  line-height: 28px;
  align-items: center;
  display: flex;
  margin-left: inherit;
  margin-right: 16px;
}
[dir=rtl] md-switch {
  margin-left: 16px;
}
[dir=rtl] md-switch {
  margin-right: inherit;
}
md-switch:last-of-type {
  margin-left: inherit;
  margin-right: 0;
}
[dir=rtl] md-switch:last-of-type {
  margin-left: 0;
}
[dir=rtl] md-switch:last-of-type {
  margin-right: inherit;
}
md-switch[disabled] {
  cursor: default;
}
md-switch[disabled] .md-container {
  cursor: default;
}
md-switch .md-container {
  cursor: grab;
  width: 36px;
  height: 24px;
  position: relative;
  user-select: none;
  margin-right: 8px;
  float: left;
}
[dir=rtl] md-switch .md-container {
  margin-right: auto;
  margin-left: 8px;
}
md-switch:not([disabled]) .md-dragging, md-switch:not([disabled]).md-dragging .md-container {
  cursor: grabbing;
}
md-switch.md-focused:not([disabled]) .md-thumb:before {
  left: -8px;
  top: -8px;
  right: -8px;
  bottom: -8px;
}
md-switch.md-focused:not([disabled]):not(.md-checked) .md-thumb:before {
  background-color: rgba(0, 0, 0, 0.12);
}
md-switch .md-label {
  border-color: transparent;
  border-width: 0;
  float: left;
}
md-switch .md-bar {
  left: 1px;
  width: 34px;
  top: 5px;
  height: 14px;
  border-radius: 8px;
  position: absolute;
}
md-switch .md-thumb-container {
  top: 2px;
  left: 0;
  width: 16px;
  position: absolute;
  transform: translate3d(0, 0, 0);
  z-index: 1;
}
md-switch.md-checked .md-thumb-container {
  transform: translate3d(100%, 0, 0);
}
md-switch .md-thumb {
  position: absolute;
  margin: 0;
  left: 0;
  top: 0;
  outline: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}
md-switch .md-thumb:before {
  background-color: transparent;
  border-radius: 50%;
  content: "";
  position: absolute;
  display: block;
  height: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition: all 0.5s;
  width: auto;
}
md-switch .md-thumb .md-ripple-container {
  position: absolute;
  display: block;
  width: auto;
  height: auto;
  left: -20px;
  top: -20px;
  right: -20px;
  bottom: -20px;
}
md-switch:not(.md-dragging) .md-bar,
md-switch:not(.md-dragging) .md-thumb-container,
md-switch:not(.md-dragging) .md-thumb {
  transition: all 0.08s linear;
  transition-property: transform, background-color;
}
md-switch:not(.md-dragging) .md-bar,
md-switch:not(.md-dragging) .md-thumb {
  transition-delay: 0.05s;
}

@media screen and (-ms-high-contrast: active) {
  md-switch.md-default-theme .md-bar {
    background-color: #666;
  }
  md-switch.md-default-theme.md-checked .md-bar {
    background-color: #9E9E9E;
  }
  md-switch.md-default-theme .md-thumb {
    background-color: #fff;
  }
}
@keyframes md-tab-content-hide {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
md-tab-data {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0;
}

md-tabs {
  display: block;
  margin: 0;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
}
md-tabs:not(.md-no-tab-content):not(.md-dynamic-height) {
  min-height: 248px;
}
md-tabs[md-align-tabs=bottom] {
  padding-bottom: 48px;
}
md-tabs[md-align-tabs=bottom] md-tabs-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 48px;
  z-index: 2;
}
md-tabs[md-align-tabs=bottom] md-tabs-content-wrapper {
  top: 0;
  bottom: 48px;
}
md-tabs.md-dynamic-height md-tabs-content-wrapper {
  min-height: 0;
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  overflow: visible;
}
md-tabs.md-dynamic-height md-tab-content.md-active {
  position: relative;
}
md-tabs[md-border-bottom] md-tabs-wrapper {
  border-width: 0 0 1px;
  border-style: solid;
}
md-tabs[md-border-bottom]:not(.md-dynamic-height) md-tabs-content-wrapper {
  top: 49px;
}

md-tabs-wrapper {
  display: block;
  position: relative;
  transform: translate3d(0, 0, 0);
}
md-tabs-wrapper md-prev-button, md-tabs-wrapper md-next-button {
  height: 100%;
  width: 32px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  line-height: 1em;
  z-index: 2;
  cursor: pointer;
  font-size: 16px;
  background: transparent no-repeat center center;
  transition: all 0.5s cubic-bezier(0.35, 0, 0.25, 1);
}
md-tabs-wrapper md-prev-button:focus, md-tabs-wrapper md-next-button:focus {
  outline: none;
}
md-tabs-wrapper md-prev-button.md-disabled, md-tabs-wrapper md-next-button.md-disabled {
  opacity: 0.25;
  cursor: default;
}
md-tabs-wrapper md-prev-button.ng-leave, md-tabs-wrapper md-next-button.ng-leave {
  transition: none;
}
md-tabs-wrapper md-prev-button md-icon, md-tabs-wrapper md-next-button md-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}
md-tabs-wrapper md-prev-button {
  left: 0;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPiA8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPiA8c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjQgMjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPiA8ZyBpZD0iSGVhZGVyIj4gPGc+IDxyZWN0IHg9Ii02MTgiIHk9Ii0xMjA4IiBmaWxsPSJub25lIiB3aWR0aD0iMTQwMCIgaGVpZ2h0PSIzNjAwIi8+IDwvZz4gPC9nPiA8ZyBpZD0iTGFiZWwiPiA8L2c+IDxnIGlkPSJJY29uIj4gPGc+IDxwb2x5Z29uIHBvaW50cz0iMTUuNCw3LjQgMTQsNiA4LDEyIDE0LDE4IDE1LjQsMTYuNiAxMC44LDEyIAkJIiBzdHlsZT0iZmlsbDp3aGl0ZTsiLz4gPHJlY3QgZmlsbD0ibm9uZSIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ii8+IDwvZz4gPC9nPiA8ZyBpZD0iR3JpZCIgZGlzcGxheT0ibm9uZSI+IDxnIGRpc3BsYXk9ImlubGluZSI+IDwvZz4gPC9nPiA8L3N2Zz4NCg==");
}
[dir=rtl] md-tabs-wrapper md-prev-button {
  left: auto;
  right: 0;
}
md-tabs-wrapper md-next-button {
  right: 0;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPiA8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPiA8c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjQgMjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPiA8ZyBpZD0iSGVhZGVyIj4gPGc+IDxyZWN0IHg9Ii02MTgiIHk9Ii0xMzM2IiBmaWxsPSJub25lIiB3aWR0aD0iMTQwMCIgaGVpZ2h0PSIzNjAwIi8+IDwvZz4gPC9nPiA8ZyBpZD0iTGFiZWwiPiA8L2c+IDxnIGlkPSJJY29uIj4gPGc+IDxwb2x5Z29uIHBvaW50cz0iMTAsNiA4LjYsNy40IDEzLjIsMTIgOC42LDE2LjYgMTAsMTggMTYsMTIgCQkiIHN0eWxlPSJmaWxsOndoaXRlOyIvPiA8cmVjdCBmaWxsPSJub25lIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiLz4gPC9nPiA8L2c+IDxnIGlkPSJHcmlkIiBkaXNwbGF5PSJub25lIj4gPGcgZGlzcGxheT0iaW5saW5lIj4gPC9nPiA8L2c+IDwvc3ZnPg0K");
}
[dir=rtl] md-tabs-wrapper md-next-button {
  right: auto;
  left: 0;
}
md-tabs-wrapper md-next-button md-icon {
  transform: translate3d(-50%, -50%, 0) rotate(180deg);
}
md-tabs-wrapper.md-stretch-tabs md-pagination-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
}
md-tabs-wrapper.md-stretch-tabs md-pagination-wrapper md-tab-item {
  flex-grow: 1;
}

md-tabs-canvas {
  position: relative;
  overflow: hidden;
  display: block;
  height: 48px;
}
md-tabs-canvas:after {
  content: "";
  display: table;
  clear: both;
}
md-tabs-canvas .md-dummy-wrapper {
  position: absolute;
  top: 0;
  left: 0;
}
[dir=rtl] md-tabs-canvas .md-dummy-wrapper {
  left: auto;
  right: 0;
}
md-tabs-canvas.md-paginated {
  margin: 0 32px;
}
md-tabs-canvas.md-center-tabs {
  display: flex;
  flex-direction: column;
  text-align: center;
}
md-tabs-canvas.md-center-tabs .md-tab {
  float: none;
  display: inline-block;
}

md-pagination-wrapper {
  height: 48px;
  display: block;
  transition: transform 0.5s cubic-bezier(0.35, 0, 0.25, 1);
  position: absolute;
  width: 999999px;
  left: 0;
  transform: translate3d(0, 0, 0);
}
md-pagination-wrapper:after {
  content: "";
  display: table;
  clear: both;
}
[dir=rtl] md-pagination-wrapper {
  left: auto;
  right: 0;
}
md-pagination-wrapper.md-center-tabs {
  position: relative;
  width: auto;
  margin: 0 auto;
}

md-tabs-content-wrapper {
  display: block;
  position: absolute;
  top: 48px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

md-tab-content {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: transform 0.5s cubic-bezier(0.35, 0, 0.25, 1);
  overflow: auto;
  transform: translate3d(0, 0, 0);
}
md-tab-content.md-no-scroll {
  bottom: auto;
  overflow: hidden;
}
md-tab-content.ng-leave, md-tab-content.md-no-transition {
  transition: none;
}
md-tab-content.md-left:not(.md-active) {
  transform: translateX(-100%);
  animation: 1s md-tab-content-hide;
  opacity: 0;
}
[dir=rtl] md-tab-content.md-left:not(.md-active) {
  transform: translateX(100%);
}
md-tab-content.md-left:not(.md-active) * {
  transition: visibility 0s linear;
  transition-delay: 0.5s;
  visibility: hidden;
}
md-tab-content.md-right:not(.md-active) {
  transform: translateX(100%);
  animation: 1s md-tab-content-hide;
  opacity: 0;
}
[dir=rtl] md-tab-content.md-right:not(.md-active) {
  transform: translateX(-100%);
}
md-tab-content.md-right:not(.md-active) * {
  transition: visibility 0s linear;
  transition-delay: 0.5s;
  visibility: hidden;
}
md-tab-content > div.ng-leave {
  animation: 1s md-tab-content-hide;
}

md-ink-bar {
  position: absolute;
  left: auto;
  right: auto;
  bottom: 0;
  height: 2px;
}
md-ink-bar.md-left {
  transition: left 0.125s cubic-bezier(0.35, 0, 0.25, 1), right 0.25s cubic-bezier(0.35, 0, 0.25, 1);
}
md-ink-bar.md-right {
  transition: left 0.25s cubic-bezier(0.35, 0, 0.25, 1), right 0.125s cubic-bezier(0.35, 0, 0.25, 1);
}

md-tab {
  position: absolute;
  z-index: -1;
  left: -9999px;
}

.md-tab {
  font-size: 14px;
  text-align: center;
  line-height: 24px;
  padding: 12px 24px;
  transition: background-color 0.35s cubic-bezier(0.35, 0, 0.25, 1);
  cursor: pointer;
  white-space: nowrap;
  position: relative;
  text-transform: uppercase;
  float: left;
  font-weight: 500;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
}
[dir=rtl] .md-tab {
  float: right;
}
.md-tab.md-focused {
  box-shadow: none;
  outline: none;
}
.md-tab.md-active {
  cursor: default;
}
.md-tab.md-disabled {
  pointer-events: none;
  touch-action: pan-y;
  user-select: none;
  -webkit-user-drag: none;
  opacity: 0.5;
  cursor: default;
}
.md-tab.ng-leave {
  transition: none;
}

md-toolbar + md-tabs {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.md-toast-text {
  padding: 0 6px;
}

md-toast {
  position: absolute;
  z-index: 105;
  box-sizing: border-box;
  cursor: default;
  overflow: hidden;
  padding: 8px;
  opacity: 1;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  /* Transition differently when swiping */
  /*
   * When the toast doesn't take up the whole screen,
   * make it rotate when the user swipes it away
   */
}
md-toast .md-toast-content {
  display: flex;
  direction: row;
  align-items: center;
  max-height: 168px;
  max-width: 100%;
  min-height: 48px;
  padding: 0 18px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  border-radius: 2px;
  font-size: 14px;
  overflow: hidden;
  transform: translate3d(0, 0, 0) rotateZ(0deg);
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  justify-content: flex-start;
}
md-toast .md-toast-content::before {
  content: "";
  min-height: 48px;
  visibility: hidden;
  display: inline-block;
}
[dir=rtl] md-toast .md-toast-content {
  justify-content: flex-end;
}
md-toast .md-toast-content span {
  flex: 1 1 0%;
  box-sizing: border-box;
  min-width: 0;
}
md-toast.md-capsule {
  border-radius: 24px;
}
md-toast.md-capsule .md-toast-content {
  border-radius: 24px;
}
md-toast.ng-leave-active .md-toast-content {
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
}
md-toast.md-swipeleft .md-toast-content, md-toast.md-swiperight .md-toast-content, md-toast.md-swipeup .md-toast-content, md-toast.md-swipedown .md-toast-content {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
md-toast.ng-enter {
  opacity: 0;
}
md-toast.ng-enter .md-toast-content {
  transform: translate3d(0, 100%, 0);
}
md-toast.ng-enter.md-top .md-toast-content {
  transform: translate3d(0, -100%, 0);
}
md-toast.ng-enter.ng-enter-active {
  opacity: 1;
}
md-toast.ng-enter.ng-enter-active .md-toast-content {
  transform: translate3d(0, 0, 0);
}
md-toast.ng-leave.ng-leave-active .md-toast-content {
  opacity: 0;
  transform: translate3d(0, 100%, 0);
}
md-toast.ng-leave.ng-leave-active.md-swipeup .md-toast-content {
  transform: translate3d(0, -50%, 0);
}
md-toast.ng-leave.ng-leave-active.md-swipedown .md-toast-content {
  transform: translate3d(0, 50%, 0);
}
md-toast.ng-leave.ng-leave-active.md-top .md-toast-content {
  transform: translate3d(0, -100%, 0);
}
md-toast .md-action {
  line-height: 19px;
  margin-left: 24px;
  margin-right: 0;
  cursor: pointer;
  text-transform: uppercase;
  float: right;
}
md-toast .md-button {
  min-width: 0;
  margin-right: 0;
  margin-left: 12px;
}
[dir=rtl] md-toast .md-button {
  margin-right: 12px;
}
[dir=rtl] md-toast .md-button {
  margin-left: 0;
}

@media (max-width: 959px) {
  md-toast {
    left: 0;
    right: 0;
    width: 100%;
    max-width: 100%;
    min-width: 0;
    border-radius: 0;
    bottom: 0;
    padding: 0;
  }
  md-toast.ng-leave.ng-leave-active.md-swipeup .md-toast-content {
    transform: translate3d(0, -50%, 0);
  }
  md-toast.ng-leave.ng-leave-active.md-swipedown .md-toast-content {
    transform: translate3d(0, 50%, 0);
  }
}
@media (min-width: 960px) {
  md-toast {
    min-width: 304px;
    /*
    * When the toast doesn't take up the whole screen,
    * make it rotate when the user swipes it away
    */
  }
  md-toast.md-bottom {
    bottom: 0;
  }
  md-toast.md-left {
    left: 0;
  }
  md-toast.md-right {
    right: 0;
  }
  md-toast.md-top {
    top: 0;
  }
  md-toast._md-start {
    left: 0;
  }
  [dir=rtl] md-toast._md-start {
    left: auto;
    right: 0;
  }
  md-toast._md-end {
    right: 0;
  }
  [dir=rtl] md-toast._md-end {
    right: auto;
    left: 0;
  }
  md-toast.ng-leave.ng-leave-active.md-swipeleft .md-toast-content {
    transform: translate3d(-50%, 0, 0);
  }
  md-toast.ng-leave.ng-leave-active.md-swiperight .md-toast-content {
    transform: translate3d(50%, 0, 0);
  }
}
@media (min-width: 1920px) {
  md-toast .md-toast-content {
    max-width: 568px;
  }
}
@media screen and (-ms-high-contrast: active) {
  md-toast {
    border: 1px solid #fff;
  }
}
.md-toast-animating {
  overflow: hidden !important;
}

md-toolbar {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  font-size: 20px;
  min-height: 64px;
  width: 100%;
}
md-toolbar._md-toolbar-transitions {
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
  transition-property: background-color, fill, color;
}
md-toolbar.md-whiteframe-z1-add, md-toolbar.md-whiteframe-z1-remove {
  transition: box-shadow 0.5s linear;
}
md-toolbar md-toolbar-filler {
  width: 72px;
}
md-toolbar *,
md-toolbar *:before,
md-toolbar *:after {
  box-sizing: border-box;
}
md-toolbar.ng-animate {
  transition: none;
}
md-toolbar.md-tall {
  height: 128px;
  min-height: 128px;
  max-height: 128px;
}
md-toolbar.md-medium-tall {
  height: 88px;
  min-height: 88px;
  max-height: 88px;
}
md-toolbar.md-medium-tall .md-toolbar-tools {
  height: 48px;
  min-height: 48px;
  max-height: 48px;
}
md-toolbar > .md-indent {
  margin-left: 64px;
}
[dir=rtl] md-toolbar > .md-indent {
  margin-left: auto;
  margin-right: 64px;
}
md-toolbar ~ md-content > md-list {
  padding: 0;
}
md-toolbar ~ md-content > md-list md-list-item:last-child md-divider {
  display: none;
}

.md-toolbar-tools {
  font-size: 20px;
  letter-spacing: 0.005em;
  box-sizing: border-box;
  font-weight: 400;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 64px;
  max-height: 64px;
  padding: 0 16px;
  margin: 0;
}
.md-toolbar-tools h1, .md-toolbar-tools h2, .md-toolbar-tools h3 {
  font-size: inherit;
  font-weight: inherit;
  margin: inherit;
}
.md-toolbar-tools a {
  color: inherit;
  text-decoration: none;
}
.md-toolbar-tools .fill-height {
  display: flex;
  align-items: center;
}
.md-toolbar-tools .md-button {
  margin-top: 0;
  margin-bottom: 0;
}
.md-toolbar-tools .md-button, .md-toolbar-tools .md-button.md-icon-button md-icon {
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
  transition-property: background-color, fill, color;
}
.md-toolbar-tools .md-button.ng-animate, .md-toolbar-tools .md-button.md-icon-button md-icon.ng-animate {
  transition: none;
}
.md-toolbar-tools > .md-button:first-child {
  margin-left: -8px;
}
[dir=rtl] .md-toolbar-tools > .md-button:first-child {
  margin-left: auto;
  margin-right: -8px;
}
.md-toolbar-tools > .md-button:last-child {
  margin-right: -8px;
}
[dir=rtl] .md-toolbar-tools > .md-button:last-child {
  margin-right: auto;
  margin-left: -8px;
}
.md-toolbar-tools > md-menu:last-child {
  margin-right: -8px;
}
[dir=rtl] .md-toolbar-tools > md-menu:last-child {
  margin-right: auto;
  margin-left: -8px;
}
.md-toolbar-tools > md-menu:last-child > .md-button {
  margin-right: 0;
}
[dir=rtl] .md-toolbar-tools > md-menu:last-child > .md-button {
  margin-right: auto;
  margin-left: 0;
}
@media screen and (-ms-high-contrast: active) {
  .md-toolbar-tools {
    border-bottom: 1px solid #fff;
  }
}

@media (min-width: 0) and (max-width: 959px) and (orientation: portrait) {
  md-toolbar {
    min-height: 56px;
  }
  .md-toolbar-tools {
    height: 56px;
    max-height: 56px;
  }
}
@media (min-width: 0) and (max-width: 959px) and (orientation: landscape) {
  md-toolbar {
    min-height: 48px;
  }
  .md-toolbar-tools {
    height: 48px;
    max-height: 48px;
  }
}
md-tooltip {
  position: absolute;
  z-index: 100;
  overflow: hidden;
  pointer-events: none;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
}
@media (min-width: 960px) {
  md-tooltip {
    font-size: 10px;
  }
}
md-tooltip .md-content {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform-origin: center top;
  transform: scale(0);
  opacity: 0;
  height: 32px;
  line-height: 32px;
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 960px) {
  md-tooltip .md-content {
    height: 22px;
    line-height: 22px;
    padding-left: 8px;
    padding-right: 8px;
  }
}
md-tooltip .md-content.md-show-add {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 0.2s;
  transform: scale(0);
  opacity: 0;
}
md-tooltip .md-content.md-show, md-tooltip .md-content.md-show-add-active {
  transform: scale(1);
  opacity: 0.9;
  transform-origin: center top;
}
md-tooltip .md-content.md-show-remove {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 0.2s;
}
md-tooltip .md-content.md-show-remove.md-show-remove-active {
  transform: scale(0);
  opacity: 0;
}
md-tooltip.md-hide {
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
}
md-tooltip.md-show {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  pointer-events: auto;
}

.md-virtual-repeat-container {
  box-sizing: border-box;
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
}
.md-virtual-repeat-container .md-virtual-repeat-scroller {
  bottom: 0;
  box-sizing: border-box;
  left: 0;
  margin: 0;
  overflow-x: hidden;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-overflow-scrolling: touch;
}
.md-virtual-repeat-container .md-virtual-repeat-sizer {
  box-sizing: border-box;
  height: 1px;
  display: block;
  margin: 0;
  padding: 0;
  width: 1px;
}
.md-virtual-repeat-container .md-virtual-repeat-offsetter {
  box-sizing: border-box;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.md-virtual-repeat-container.md-orient-horizontal .md-virtual-repeat-scroller {
  overflow-x: auto;
  overflow-y: hidden;
}
.md-virtual-repeat-container.md-orient-horizontal .md-virtual-repeat-offsetter {
  bottom: 16px;
  right: auto;
  white-space: nowrap;
}
[dir=rtl] .md-virtual-repeat-container.md-orient-horizontal .md-virtual-repeat-offsetter {
  right: auto;
  left: auto;
}

.md-whiteframe-1dp, .md-whiteframe-z1 {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-2dp {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-3dp {
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-4dp, .md-whiteframe-z2 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-5dp {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-6dp {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-7dp, .md-whiteframe-z3 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-8dp {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-9dp {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-10dp, .md-whiteframe-z4 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-11dp {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-12dp {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-13dp, .md-whiteframe-z5 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-14dp {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-15dp {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-16dp {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-17dp {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-18dp {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-19dp {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-20dp {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-21dp {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-22dp {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-23dp {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-24dp {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

@media screen and (-ms-high-contrast: active) {
  md-whiteframe {
    border: 1px solid #fff;
  }
}
@media print {
  md-whiteframe, [md-whiteframe] {
    background-color: #ffffff;
  }
}
/*
* Since Layout API uses ng-cloak to hide the dom elements while layouts are adjusted
*
*/
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

/*
*
*  Responsive attributes
*
*  References:
*  1) https://scotch.io/tutorials/a-visual-guide-to-css3-flexbox-properties#flex
*  2) https://css-tricks.com/almanac/properties/f/flex/
*  3) https://css-tricks.com/snippets/css/a-guide-to-flexbox/
*  4) https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
*  5) http://godban.com.ua/projects/flexgrid
*
*
*/
@-moz-document url-prefix() {
  .layout-fill {
    margin: 0;
    width: 100%;
    min-height: 100%;
    height: 100%;
  }
}
/*
 *  Apply Mixins to create Layout/Flexbox styles
 *
 */
.flex-order {
  order: 0;
}

.flex-order--20 {
  order: -20;
}

.flex-order--19 {
  order: -19;
}

.flex-order--18 {
  order: -18;
}

.flex-order--17 {
  order: -17;
}

.flex-order--16 {
  order: -16;
}

.flex-order--15 {
  order: -15;
}

.flex-order--14 {
  order: -14;
}

.flex-order--13 {
  order: -13;
}

.flex-order--12 {
  order: -12;
}

.flex-order--11 {
  order: -11;
}

.flex-order--10 {
  order: -10;
}

.flex-order--9 {
  order: -9;
}

.flex-order--8 {
  order: -8;
}

.flex-order--7 {
  order: -7;
}

.flex-order--6 {
  order: -6;
}

.flex-order--5 {
  order: -5;
}

.flex-order--4 {
  order: -4;
}

.flex-order--3 {
  order: -3;
}

.flex-order--2 {
  order: -2;
}

.flex-order--1 {
  order: -1;
}

.flex-order-0 {
  order: 0;
}

.flex-order-1 {
  order: 1;
}

.flex-order-2 {
  order: 2;
}

.flex-order-3 {
  order: 3;
}

.flex-order-4 {
  order: 4;
}

.flex-order-5 {
  order: 5;
}

.flex-order-6 {
  order: 6;
}

.flex-order-7 {
  order: 7;
}

.flex-order-8 {
  order: 8;
}

.flex-order-9 {
  order: 9;
}

.flex-order-10 {
  order: 10;
}

.flex-order-11 {
  order: 11;
}

.flex-order-12 {
  order: 12;
}

.flex-order-13 {
  order: 13;
}

.flex-order-14 {
  order: 14;
}

.flex-order-15 {
  order: 15;
}

.flex-order-16 {
  order: 16;
}

.flex-order-17 {
  order: 17;
}

.flex-order-18 {
  order: 18;
}

.flex-order-19 {
  order: 19;
}

.flex-order-20 {
  order: 20;
}

.offset-0, .flex-offset-0 {
  margin-left: 0;
}
[dir=rtl] .offset-0, [dir=rtl] .flex-offset-0 {
  margin-left: auto;
  margin-right: 0;
}

.offset-5, .flex-offset-5 {
  margin-left: 5%;
}
[dir=rtl] .offset-5, [dir=rtl] .flex-offset-5 {
  margin-left: auto;
  margin-right: 5%;
}

.offset-10, .flex-offset-10 {
  margin-left: 10%;
}
[dir=rtl] .offset-10, [dir=rtl] .flex-offset-10 {
  margin-left: auto;
  margin-right: 10%;
}

.offset-15, .flex-offset-15 {
  margin-left: 15%;
}
[dir=rtl] .offset-15, [dir=rtl] .flex-offset-15 {
  margin-left: auto;
  margin-right: 15%;
}

.offset-20, .flex-offset-20 {
  margin-left: 20%;
}
[dir=rtl] .offset-20, [dir=rtl] .flex-offset-20 {
  margin-left: auto;
  margin-right: 20%;
}

.offset-25, .flex-offset-25 {
  margin-left: 25%;
}
[dir=rtl] .offset-25, [dir=rtl] .flex-offset-25 {
  margin-left: auto;
  margin-right: 25%;
}

.offset-30, .flex-offset-30 {
  margin-left: 30%;
}
[dir=rtl] .offset-30, [dir=rtl] .flex-offset-30 {
  margin-left: auto;
  margin-right: 30%;
}

.offset-35, .flex-offset-35 {
  margin-left: 35%;
}
[dir=rtl] .offset-35, [dir=rtl] .flex-offset-35 {
  margin-left: auto;
  margin-right: 35%;
}

.offset-40, .flex-offset-40 {
  margin-left: 40%;
}
[dir=rtl] .offset-40, [dir=rtl] .flex-offset-40 {
  margin-left: auto;
  margin-right: 40%;
}

.offset-45, .flex-offset-45 {
  margin-left: 45%;
}
[dir=rtl] .offset-45, [dir=rtl] .flex-offset-45 {
  margin-left: auto;
  margin-right: 45%;
}

.offset-50, .flex-offset-50 {
  margin-left: 50%;
}
[dir=rtl] .offset-50, [dir=rtl] .flex-offset-50 {
  margin-left: auto;
  margin-right: 50%;
}

.offset-55, .flex-offset-55 {
  margin-left: 55%;
}
[dir=rtl] .offset-55, [dir=rtl] .flex-offset-55 {
  margin-left: auto;
  margin-right: 55%;
}

.offset-60, .flex-offset-60 {
  margin-left: 60%;
}
[dir=rtl] .offset-60, [dir=rtl] .flex-offset-60 {
  margin-left: auto;
  margin-right: 60%;
}

.offset-65, .flex-offset-65 {
  margin-left: 65%;
}
[dir=rtl] .offset-65, [dir=rtl] .flex-offset-65 {
  margin-left: auto;
  margin-right: 65%;
}

.offset-70, .flex-offset-70 {
  margin-left: 70%;
}
[dir=rtl] .offset-70, [dir=rtl] .flex-offset-70 {
  margin-left: auto;
  margin-right: 70%;
}

.offset-75, .flex-offset-75 {
  margin-left: 75%;
}
[dir=rtl] .offset-75, [dir=rtl] .flex-offset-75 {
  margin-left: auto;
  margin-right: 75%;
}

.offset-80, .flex-offset-80 {
  margin-left: 80%;
}
[dir=rtl] .offset-80, [dir=rtl] .flex-offset-80 {
  margin-left: auto;
  margin-right: 80%;
}

.offset-85, .flex-offset-85 {
  margin-left: 85%;
}
[dir=rtl] .offset-85, [dir=rtl] .flex-offset-85 {
  margin-left: auto;
  margin-right: 85%;
}

.offset-90, .flex-offset-90 {
  margin-left: 90%;
}
[dir=rtl] .offset-90, [dir=rtl] .flex-offset-90 {
  margin-left: auto;
  margin-right: 90%;
}

.offset-95, .flex-offset-95 {
  margin-left: 95%;
}
[dir=rtl] .offset-95, [dir=rtl] .flex-offset-95 {
  margin-left: auto;
  margin-right: 95%;
}

.offset-33, .flex-offset-33 {
  margin-left: 33.3333333333%;
}

.offset-66, .flex-offset-66 {
  margin-left: 66.6666666667%;
}
[dir=rtl] .offset-66, [dir=rtl] .flex-offset-66 {
  margin-left: auto;
  margin-right: 66.6666666667%;
}

.layout-align,
.layout-align-start-stretch {
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
}

.layout-align-start,
.layout-align-start-start,
.layout-align-start-center,
.layout-align-start-end,
.layout-align-start-stretch {
  justify-content: flex-start;
}

.layout-align-center,
.layout-align-center-start,
.layout-align-center-center,
.layout-align-center-end,
.layout-align-center-stretch {
  justify-content: center;
}

.layout-align-end,
.layout-align-end-start,
.layout-align-end-center,
.layout-align-end-end,
.layout-align-end-stretch {
  justify-content: flex-end;
}

.layout-align-space-around,
.layout-align-space-around-center,
.layout-align-space-around-start,
.layout-align-space-around-end,
.layout-align-space-around-stretch {
  justify-content: space-around;
}

.layout-align-space-between,
.layout-align-space-between-center,
.layout-align-space-between-start,
.layout-align-space-between-end,
.layout-align-space-between-stretch {
  justify-content: space-between;
}

.layout-align-start-start,
.layout-align-center-start,
.layout-align-end-start,
.layout-align-space-between-start,
.layout-align-space-around-start {
  align-items: flex-start;
  align-content: flex-start;
}

.layout-align-start-center,
.layout-align-center-center,
.layout-align-end-center,
.layout-align-space-between-center,
.layout-align-space-around-center {
  align-items: center;
  align-content: center;
  max-width: 100%;
}

.layout-align-start-center > *,
.layout-align-center-center > *,
.layout-align-end-center > *,
.layout-align-space-between-center > *,
.layout-align-space-around-center > * {
  max-width: 100%;
  box-sizing: border-box;
}

.layout-align-start-end,
.layout-align-center-end,
.layout-align-end-end,
.layout-align-space-between-end,
.layout-align-space-around-end {
  align-items: flex-end;
  align-content: flex-end;
}

.layout-align-start-stretch,
.layout-align-center-stretch,
.layout-align-end-stretch,
.layout-align-space-between-stretch,
.layout-align-space-around-stretch {
  align-items: stretch;
  align-content: stretch;
}

.flex {
  flex: 1;
  box-sizing: border-box;
}

.flex-grow {
  flex: 1 1 100%;
  box-sizing: border-box;
}

.flex-initial {
  flex: 0 1 auto;
  box-sizing: border-box;
}

.flex-auto {
  flex: 1 1 auto;
  box-sizing: border-box;
}

.flex-none {
  flex: 0 0 auto;
  box-sizing: border-box;
}

.flex-noshrink {
  flex: 1 0 auto;
  box-sizing: border-box;
}

.flex-nogrow {
  flex: 0 1 auto;
  box-sizing: border-box;
}

.flex-0 {
  flex: 1 1 0%;
  max-width: 0%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-0 {
  flex: 1 1 0%;
  max-width: 0%;
  max-height: 100%;
  box-sizing: border-box;
  min-width: 0;
}

.layout-column > .flex-0 {
  flex: 1 1 0%;
  max-width: 100%;
  max-height: 0%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}
.layout-row > .flex-66, .layout-row > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}
.layout-column > .flex-66, .layout-column > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-0 {
  flex: 1 1 0%;
  max-width: 0%;
  max-height: 100%;
  box-sizing: border-box;
  min-width: 0;
}

.layout-column > .flex-0 {
  flex: 1 1 0%;
  max-width: 100%;
  max-height: 0%;
  box-sizing: border-box;
  min-height: 0;
}

.flex-5 {
  flex: 1 1 5%;
  max-width: 5%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-5 {
  flex: 1 1 5%;
  max-width: 5%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-5 {
  flex: 1 1 5%;
  max-width: 100%;
  max-height: 5%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}
.layout-row > .flex-66, .layout-row > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}
.layout-column > .flex-66, .layout-column > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-5 {
  flex: 1 1 5%;
  max-width: 5%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-5 {
  flex: 1 1 5%;
  max-width: 100%;
  max-height: 5%;
  box-sizing: border-box;
}

.flex-10 {
  flex: 1 1 10%;
  max-width: 10%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-10 {
  flex: 1 1 10%;
  max-width: 10%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-10 {
  flex: 1 1 10%;
  max-width: 100%;
  max-height: 10%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}
.layout-row > .flex-66, .layout-row > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}
.layout-column > .flex-66, .layout-column > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-10 {
  flex: 1 1 10%;
  max-width: 10%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-10 {
  flex: 1 1 10%;
  max-width: 100%;
  max-height: 10%;
  box-sizing: border-box;
}

.flex-15 {
  flex: 1 1 15%;
  max-width: 15%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-15 {
  flex: 1 1 15%;
  max-width: 15%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-15 {
  flex: 1 1 15%;
  max-width: 100%;
  max-height: 15%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}
.layout-row > .flex-66, .layout-row > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}
.layout-column > .flex-66, .layout-column > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-15 {
  flex: 1 1 15%;
  max-width: 15%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-15 {
  flex: 1 1 15%;
  max-width: 100%;
  max-height: 15%;
  box-sizing: border-box;
}

.flex-20 {
  flex: 1 1 20%;
  max-width: 20%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-20 {
  flex: 1 1 20%;
  max-width: 20%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-20 {
  flex: 1 1 20%;
  max-width: 100%;
  max-height: 20%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}
.layout-row > .flex-66, .layout-row > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}
.layout-column > .flex-66, .layout-column > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-20 {
  flex: 1 1 20%;
  max-width: 20%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-20 {
  flex: 1 1 20%;
  max-width: 100%;
  max-height: 20%;
  box-sizing: border-box;
}

.flex-25 {
  flex: 1 1 25%;
  max-width: 25%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-25 {
  flex: 1 1 25%;
  max-width: 25%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-25 {
  flex: 1 1 25%;
  max-width: 100%;
  max-height: 25%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}
.layout-row > .flex-66, .layout-row > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}
.layout-column > .flex-66, .layout-column > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-25 {
  flex: 1 1 25%;
  max-width: 25%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-25 {
  flex: 1 1 25%;
  max-width: 100%;
  max-height: 25%;
  box-sizing: border-box;
}

.flex-30 {
  flex: 1 1 30%;
  max-width: 30%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-30 {
  flex: 1 1 30%;
  max-width: 30%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-30 {
  flex: 1 1 30%;
  max-width: 100%;
  max-height: 30%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}
.layout-row > .flex-66, .layout-row > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}
.layout-column > .flex-66, .layout-column > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-30 {
  flex: 1 1 30%;
  max-width: 30%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-30 {
  flex: 1 1 30%;
  max-width: 100%;
  max-height: 30%;
  box-sizing: border-box;
}

.flex-35 {
  flex: 1 1 35%;
  max-width: 35%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-35 {
  flex: 1 1 35%;
  max-width: 35%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-35 {
  flex: 1 1 35%;
  max-width: 100%;
  max-height: 35%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}
.layout-row > .flex-66, .layout-row > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}
.layout-column > .flex-66, .layout-column > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-35 {
  flex: 1 1 35%;
  max-width: 35%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-35 {
  flex: 1 1 35%;
  max-width: 100%;
  max-height: 35%;
  box-sizing: border-box;
}

.flex-40 {
  flex: 1 1 40%;
  max-width: 40%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-40 {
  flex: 1 1 40%;
  max-width: 40%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-40 {
  flex: 1 1 40%;
  max-width: 100%;
  max-height: 40%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}
.layout-row > .flex-66, .layout-row > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}
.layout-column > .flex-66, .layout-column > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-40 {
  flex: 1 1 40%;
  max-width: 40%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-40 {
  flex: 1 1 40%;
  max-width: 100%;
  max-height: 40%;
  box-sizing: border-box;
}

.flex-45 {
  flex: 1 1 45%;
  max-width: 45%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-45 {
  flex: 1 1 45%;
  max-width: 45%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-45 {
  flex: 1 1 45%;
  max-width: 100%;
  max-height: 45%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}
.layout-row > .flex-66, .layout-row > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}
.layout-column > .flex-66, .layout-column > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-45 {
  flex: 1 1 45%;
  max-width: 45%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-45 {
  flex: 1 1 45%;
  max-width: 100%;
  max-height: 45%;
  box-sizing: border-box;
}

.flex-50 {
  flex: 1 1 50%;
  max-width: 50%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-50 {
  flex: 1 1 50%;
  max-width: 50%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-50 {
  flex: 1 1 50%;
  max-width: 100%;
  max-height: 50%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}
.layout-row > .flex-66, .layout-row > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}
.layout-column > .flex-66, .layout-column > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-50 {
  flex: 1 1 50%;
  max-width: 50%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-50 {
  flex: 1 1 50%;
  max-width: 100%;
  max-height: 50%;
  box-sizing: border-box;
}

.flex-55 {
  flex: 1 1 55%;
  max-width: 55%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-55 {
  flex: 1 1 55%;
  max-width: 55%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-55 {
  flex: 1 1 55%;
  max-width: 100%;
  max-height: 55%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}
.layout-row > .flex-66, .layout-row > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}
.layout-column > .flex-66, .layout-column > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-55 {
  flex: 1 1 55%;
  max-width: 55%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-55 {
  flex: 1 1 55%;
  max-width: 100%;
  max-height: 55%;
  box-sizing: border-box;
}

.flex-60 {
  flex: 1 1 60%;
  max-width: 60%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-60 {
  flex: 1 1 60%;
  max-width: 60%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-60 {
  flex: 1 1 60%;
  max-width: 100%;
  max-height: 60%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}
.layout-row > .flex-66, .layout-row > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}
.layout-column > .flex-66, .layout-column > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-60 {
  flex: 1 1 60%;
  max-width: 60%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-60 {
  flex: 1 1 60%;
  max-width: 100%;
  max-height: 60%;
  box-sizing: border-box;
}

.flex-65 {
  flex: 1 1 65%;
  max-width: 65%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-65 {
  flex: 1 1 65%;
  max-width: 65%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-65 {
  flex: 1 1 65%;
  max-width: 100%;
  max-height: 65%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}
.layout-row > .flex-66, .layout-row > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}
.layout-column > .flex-66, .layout-column > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-65 {
  flex: 1 1 65%;
  max-width: 65%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-65 {
  flex: 1 1 65%;
  max-width: 100%;
  max-height: 65%;
  box-sizing: border-box;
}

.flex-70 {
  flex: 1 1 70%;
  max-width: 70%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-70 {
  flex: 1 1 70%;
  max-width: 70%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-70 {
  flex: 1 1 70%;
  max-width: 100%;
  max-height: 70%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}
.layout-row > .flex-66, .layout-row > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}
.layout-column > .flex-66, .layout-column > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-70 {
  flex: 1 1 70%;
  max-width: 70%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-70 {
  flex: 1 1 70%;
  max-width: 100%;
  max-height: 70%;
  box-sizing: border-box;
}

.flex-75 {
  flex: 1 1 75%;
  max-width: 75%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-75 {
  flex: 1 1 75%;
  max-width: 75%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-75 {
  flex: 1 1 75%;
  max-width: 100%;
  max-height: 75%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}
.layout-row > .flex-66, .layout-row > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}
.layout-column > .flex-66, .layout-column > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-75 {
  flex: 1 1 75%;
  max-width: 75%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-75 {
  flex: 1 1 75%;
  max-width: 100%;
  max-height: 75%;
  box-sizing: border-box;
}

.flex-80 {
  flex: 1 1 80%;
  max-width: 80%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-80 {
  flex: 1 1 80%;
  max-width: 80%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-80 {
  flex: 1 1 80%;
  max-width: 100%;
  max-height: 80%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}
.layout-row > .flex-66, .layout-row > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}
.layout-column > .flex-66, .layout-column > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-80 {
  flex: 1 1 80%;
  max-width: 80%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-80 {
  flex: 1 1 80%;
  max-width: 100%;
  max-height: 80%;
  box-sizing: border-box;
}

.flex-85 {
  flex: 1 1 85%;
  max-width: 85%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-85 {
  flex: 1 1 85%;
  max-width: 85%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-85 {
  flex: 1 1 85%;
  max-width: 100%;
  max-height: 85%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}
.layout-row > .flex-66, .layout-row > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}
.layout-column > .flex-66, .layout-column > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-85 {
  flex: 1 1 85%;
  max-width: 85%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-85 {
  flex: 1 1 85%;
  max-width: 100%;
  max-height: 85%;
  box-sizing: border-box;
}

.flex-90 {
  flex: 1 1 90%;
  max-width: 90%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-90 {
  flex: 1 1 90%;
  max-width: 90%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-90 {
  flex: 1 1 90%;
  max-width: 100%;
  max-height: 90%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}
.layout-row > .flex-66, .layout-row > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}
.layout-column > .flex-66, .layout-column > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-90 {
  flex: 1 1 90%;
  max-width: 90%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-90 {
  flex: 1 1 90%;
  max-width: 100%;
  max-height: 90%;
  box-sizing: border-box;
}

.flex-95 {
  flex: 1 1 95%;
  max-width: 95%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-95 {
  flex: 1 1 95%;
  max-width: 95%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-95 {
  flex: 1 1 95%;
  max-width: 100%;
  max-height: 95%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}
.layout-row > .flex-66, .layout-row > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}
.layout-column > .flex-66, .layout-column > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-95 {
  flex: 1 1 95%;
  max-width: 95%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-95 {
  flex: 1 1 95%;
  max-width: 100%;
  max-height: 95%;
  box-sizing: border-box;
}

.flex-100 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-100 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-100 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}
.layout-row > .flex-66, .layout-row > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}
.layout-column > .flex-66, .layout-column > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-100 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-100 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}
.layout-row > .flex-66, .layout-row > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}
.layout-row > .flex {
  min-width: 0;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}
.layout-column > .flex-66, .layout-column > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}
.layout-column > .flex {
  min-height: 0;
}

.layout, .layout-column, .layout-row {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.layout-column {
  flex-direction: column;
}

.layout-row {
  flex-direction: row;
}

.layout-padding-sm > *,
.layout-padding > .flex-sm {
  padding: 4px;
}

.layout-padding,
.layout-padding-gt-sm,
.layout-padding-md,
.layout-padding > *,
.layout-padding-gt-sm > *,
.layout-padding-md > *,
.layout-padding > .flex,
.layout-padding > .flex-gt-sm,
.layout-padding > .flex-md {
  padding: 8px;
}

.layout-padding-gt-md > *,
.layout-padding-lg > *,
.layout-padding-gt-lg > *,
.layout-padding > .flex-gt-md,
.layout-padding > .flex-lg,
.layout-padding > .flex-lg,
.layout-padding > .flex-gt-lg {
  padding: 16px;
}

.layout-margin-sm > *,
.layout-margin > .flex-sm {
  margin: 4px;
}

.layout-margin,
.layout-margin-gt-sm,
.layout-margin-md,
.layout-margin > *,
.layout-margin-gt-sm > *,
.layout-margin-md > *,
.layout-margin > .flex,
.layout-margin > .flex-gt-sm,
.layout-margin > .flex-md {
  margin: 8px;
}

.layout-margin-gt-md > *,
.layout-margin-lg > *,
.layout-margin-gt-lg > *,
.layout-margin > .flex-gt-md,
.layout-margin > .flex-lg,
.layout-margin > .flex-gt-lg {
  margin: 16px;
}

.layout-wrap {
  flex-wrap: wrap;
}

.layout-nowrap {
  flex-wrap: nowrap;
}

.layout-fill {
  margin: 0;
  width: 100%;
  min-height: 100%;
  height: 100%;
}

/**
 * `hide-gt-sm show-gt-lg` should hide from 600px to 1200px
 * `show-md hide-gt-sm` should show from 0px to 960px and hide at >960px
 * `hide-gt-md show-gt-sm` should show everywhere (show overrides hide)`
 *
 *  hide means hide everywhere
 *  Sizes:
 *         $layout-breakpoint-xs:     600px !default;
 *         $layout-breakpoint-sm:     960px !default;
 *         $layout-breakpoint-md:     1280px !default;
 *         $layout-breakpoint-lg:     1920px !default;
 */
@media (max-width: 599px) {
  .hide-xs:not(.show-xs):not(.show), .hide:not(.show-xs):not(.show) {
    display: none;
  }
  .flex-order-xs--20 {
    order: -20;
  }
  .flex-order-xs--19 {
    order: -19;
  }
  .flex-order-xs--18 {
    order: -18;
  }
  .flex-order-xs--17 {
    order: -17;
  }
  .flex-order-xs--16 {
    order: -16;
  }
  .flex-order-xs--15 {
    order: -15;
  }
  .flex-order-xs--14 {
    order: -14;
  }
  .flex-order-xs--13 {
    order: -13;
  }
  .flex-order-xs--12 {
    order: -12;
  }
  .flex-order-xs--11 {
    order: -11;
  }
  .flex-order-xs--10 {
    order: -10;
  }
  .flex-order-xs--9 {
    order: -9;
  }
  .flex-order-xs--8 {
    order: -8;
  }
  .flex-order-xs--7 {
    order: -7;
  }
  .flex-order-xs--6 {
    order: -6;
  }
  .flex-order-xs--5 {
    order: -5;
  }
  .flex-order-xs--4 {
    order: -4;
  }
  .flex-order-xs--3 {
    order: -3;
  }
  .flex-order-xs--2 {
    order: -2;
  }
  .flex-order-xs--1 {
    order: -1;
  }
  .flex-order-xs-0 {
    order: 0;
  }
  .flex-order-xs-1 {
    order: 1;
  }
  .flex-order-xs-2 {
    order: 2;
  }
  .flex-order-xs-3 {
    order: 3;
  }
  .flex-order-xs-4 {
    order: 4;
  }
  .flex-order-xs-5 {
    order: 5;
  }
  .flex-order-xs-6 {
    order: 6;
  }
  .flex-order-xs-7 {
    order: 7;
  }
  .flex-order-xs-8 {
    order: 8;
  }
  .flex-order-xs-9 {
    order: 9;
  }
  .flex-order-xs-10 {
    order: 10;
  }
  .flex-order-xs-11 {
    order: 11;
  }
  .flex-order-xs-12 {
    order: 12;
  }
  .flex-order-xs-13 {
    order: 13;
  }
  .flex-order-xs-14 {
    order: 14;
  }
  .flex-order-xs-15 {
    order: 15;
  }
  .flex-order-xs-16 {
    order: 16;
  }
  .flex-order-xs-17 {
    order: 17;
  }
  .flex-order-xs-18 {
    order: 18;
  }
  .flex-order-xs-19 {
    order: 19;
  }
  .flex-order-xs-20 {
    order: 20;
  }
  .offset-xs-0, .flex-offset-xs-0 {
    margin-left: 0;
  }
  [dir=rtl] .offset-xs-0, [dir=rtl] .flex-offset-xs-0 {
    margin-left: auto;
    margin-right: 0;
  }
  .offset-xs-5, .flex-offset-xs-5 {
    margin-left: 5%;
  }
  [dir=rtl] .offset-xs-5, [dir=rtl] .flex-offset-xs-5 {
    margin-left: auto;
    margin-right: 5%;
  }
  .offset-xs-10, .flex-offset-xs-10 {
    margin-left: 10%;
  }
  [dir=rtl] .offset-xs-10, [dir=rtl] .flex-offset-xs-10 {
    margin-left: auto;
    margin-right: 10%;
  }
  .offset-xs-15, .flex-offset-xs-15 {
    margin-left: 15%;
  }
  [dir=rtl] .offset-xs-15, [dir=rtl] .flex-offset-xs-15 {
    margin-left: auto;
    margin-right: 15%;
  }
  .offset-xs-20, .flex-offset-xs-20 {
    margin-left: 20%;
  }
  [dir=rtl] .offset-xs-20, [dir=rtl] .flex-offset-xs-20 {
    margin-left: auto;
    margin-right: 20%;
  }
  .offset-xs-25, .flex-offset-xs-25 {
    margin-left: 25%;
  }
  [dir=rtl] .offset-xs-25, [dir=rtl] .flex-offset-xs-25 {
    margin-left: auto;
    margin-right: 25%;
  }
  .offset-xs-30, .flex-offset-xs-30 {
    margin-left: 30%;
  }
  [dir=rtl] .offset-xs-30, [dir=rtl] .flex-offset-xs-30 {
    margin-left: auto;
    margin-right: 30%;
  }
  .offset-xs-35, .flex-offset-xs-35 {
    margin-left: 35%;
  }
  [dir=rtl] .offset-xs-35, [dir=rtl] .flex-offset-xs-35 {
    margin-left: auto;
    margin-right: 35%;
  }
  .offset-xs-40, .flex-offset-xs-40 {
    margin-left: 40%;
  }
  [dir=rtl] .offset-xs-40, [dir=rtl] .flex-offset-xs-40 {
    margin-left: auto;
    margin-right: 40%;
  }
  .offset-xs-45, .flex-offset-xs-45 {
    margin-left: 45%;
  }
  [dir=rtl] .offset-xs-45, [dir=rtl] .flex-offset-xs-45 {
    margin-left: auto;
    margin-right: 45%;
  }
  .offset-xs-50, .flex-offset-xs-50 {
    margin-left: 50%;
  }
  [dir=rtl] .offset-xs-50, [dir=rtl] .flex-offset-xs-50 {
    margin-left: auto;
    margin-right: 50%;
  }
  .offset-xs-55, .flex-offset-xs-55 {
    margin-left: 55%;
  }
  [dir=rtl] .offset-xs-55, [dir=rtl] .flex-offset-xs-55 {
    margin-left: auto;
    margin-right: 55%;
  }
  .offset-xs-60, .flex-offset-xs-60 {
    margin-left: 60%;
  }
  [dir=rtl] .offset-xs-60, [dir=rtl] .flex-offset-xs-60 {
    margin-left: auto;
    margin-right: 60%;
  }
  .offset-xs-65, .flex-offset-xs-65 {
    margin-left: 65%;
  }
  [dir=rtl] .offset-xs-65, [dir=rtl] .flex-offset-xs-65 {
    margin-left: auto;
    margin-right: 65%;
  }
  .offset-xs-70, .flex-offset-xs-70 {
    margin-left: 70%;
  }
  [dir=rtl] .offset-xs-70, [dir=rtl] .flex-offset-xs-70 {
    margin-left: auto;
    margin-right: 70%;
  }
  .offset-xs-75, .flex-offset-xs-75 {
    margin-left: 75%;
  }
  [dir=rtl] .offset-xs-75, [dir=rtl] .flex-offset-xs-75 {
    margin-left: auto;
    margin-right: 75%;
  }
  .offset-xs-80, .flex-offset-xs-80 {
    margin-left: 80%;
  }
  [dir=rtl] .offset-xs-80, [dir=rtl] .flex-offset-xs-80 {
    margin-left: auto;
    margin-right: 80%;
  }
  .offset-xs-85, .flex-offset-xs-85 {
    margin-left: 85%;
  }
  [dir=rtl] .offset-xs-85, [dir=rtl] .flex-offset-xs-85 {
    margin-left: auto;
    margin-right: 85%;
  }
  .offset-xs-90, .flex-offset-xs-90 {
    margin-left: 90%;
  }
  [dir=rtl] .offset-xs-90, [dir=rtl] .flex-offset-xs-90 {
    margin-left: auto;
    margin-right: 90%;
  }
  .offset-xs-95, .flex-offset-xs-95 {
    margin-left: 95%;
  }
  [dir=rtl] .offset-xs-95, [dir=rtl] .flex-offset-xs-95 {
    margin-left: auto;
    margin-right: 95%;
  }
  .offset-xs-33, .flex-offset-xs-33 {
    margin-left: 33.3333333333%;
  }
  .offset-xs-66, .flex-offset-xs-66 {
    margin-left: 66.6666666667%;
  }
  [dir=rtl] .offset-xs-66, [dir=rtl] .flex-offset-xs-66 {
    margin-left: auto;
    margin-right: 66.6666666667%;
  }
  .layout-align-xs,
  .layout-align-xs-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
  }
  .layout-align-xs-start,
  .layout-align-xs-start-start,
  .layout-align-xs-start-center,
  .layout-align-xs-start-end,
  .layout-align-xs-start-stretch {
    justify-content: flex-start;
  }
  .layout-align-xs-center,
  .layout-align-xs-center-start,
  .layout-align-xs-center-center,
  .layout-align-xs-center-end,
  .layout-align-xs-center-stretch {
    justify-content: center;
  }
  .layout-align-xs-end,
  .layout-align-xs-end-start,
  .layout-align-xs-end-center,
  .layout-align-xs-end-end,
  .layout-align-xs-end-stretch {
    justify-content: flex-end;
  }
  .layout-align-xs-space-around,
  .layout-align-xs-space-around-center,
  .layout-align-xs-space-around-start,
  .layout-align-xs-space-around-end,
  .layout-align-xs-space-around-stretch {
    justify-content: space-around;
  }
  .layout-align-xs-space-between,
  .layout-align-xs-space-between-center,
  .layout-align-xs-space-between-start,
  .layout-align-xs-space-between-end,
  .layout-align-xs-space-between-stretch {
    justify-content: space-between;
  }
  .layout-align-xs-start-start,
  .layout-align-xs-center-start,
  .layout-align-xs-end-start,
  .layout-align-xs-space-between-start,
  .layout-align-xs-space-around-start {
    align-items: flex-start;
    align-content: flex-start;
  }
  .layout-align-xs-start-center,
  .layout-align-xs-center-center,
  .layout-align-xs-end-center,
  .layout-align-xs-space-between-center,
  .layout-align-xs-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%;
  }
  .layout-align-xs-start-center > *,
  .layout-align-xs-center-center > *,
  .layout-align-xs-end-center > *,
  .layout-align-xs-space-between-center > *,
  .layout-align-xs-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box;
  }
  .layout-align-xs-start-end,
  .layout-align-xs-center-end,
  .layout-align-xs-end-end,
  .layout-align-xs-space-between-end,
  .layout-align-xs-space-around-end {
    align-items: flex-end;
    align-content: flex-end;
  }
  .layout-align-xs-start-stretch,
  .layout-align-xs-center-stretch,
  .layout-align-xs-end-stretch,
  .layout-align-xs-space-between-stretch,
  .layout-align-xs-space-around-stretch {
    align-items: stretch;
    align-content: stretch;
  }
  .flex-xs {
    flex: 1;
    box-sizing: border-box;
  }
  .flex-xs-grow {
    flex: 1 1 100%;
    box-sizing: border-box;
  }
  .flex-xs-initial {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .flex-xs-auto {
    flex: 1 1 auto;
    box-sizing: border-box;
  }
  .flex-xs-none {
    flex: 0 0 auto;
    box-sizing: border-box;
  }
  .flex-xs-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box;
  }
  .flex-xs-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .flex-xs-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .layout-column > .flex-xs-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xs-row > .flex-xs-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .layout-xs-column > .flex-xs-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0;
  }
  .flex-xs-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xs-row > .flex-xs-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xs-column > .flex-xs-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .flex-xs-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xs-row > .flex-xs-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xs-column > .flex-xs-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .flex-xs-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xs-row > .flex-xs-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xs-column > .flex-xs-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .flex-xs-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xs-row > .flex-xs-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xs-column > .flex-xs-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .flex-xs-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xs-row > .flex-xs-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xs-column > .flex-xs-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .flex-xs-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xs-row > .flex-xs-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xs-column > .flex-xs-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .flex-xs-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xs-row > .flex-xs-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xs-column > .flex-xs-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .flex-xs-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xs-row > .flex-xs-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xs-column > .flex-xs-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .flex-xs-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xs-row > .flex-xs-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xs-column > .flex-xs-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .flex-xs-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xs-row > .flex-xs-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xs-column > .flex-xs-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .flex-xs-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xs-row > .flex-xs-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xs-column > .flex-xs-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .flex-xs-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xs-row > .flex-xs-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xs-column > .flex-xs-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .flex-xs-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xs-row > .flex-xs-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xs-column > .flex-xs-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .flex-xs-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xs-row > .flex-xs-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xs-column > .flex-xs-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .flex-xs-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xs-row > .flex-xs-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xs-column > .flex-xs-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .flex-xs-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xs-row > .flex-xs-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xs-column > .flex-xs-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .flex-xs-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xs-row > .flex-xs-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xs-column > .flex-xs-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .flex-xs-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xs-row > .flex-xs-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xs-column > .flex-xs-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .flex-xs-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xs-row > .flex-xs-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xs-column > .flex-xs-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .flex-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xs-row > .flex-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xs-column > .flex-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xs-row > .flex-xs-33, .layout-xs-row > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xs-row > .flex-xs-66, .layout-xs-row > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xs-row > .flex {
    min-width: 0;
  }
  .layout-xs-column > .flex-xs-33, .layout-xs-column > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-xs-column > .flex-xs-66, .layout-xs-column > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xs-column > .flex {
    min-height: 0;
  }
  .layout-xs, .layout-xs-column, .layout-xs-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }
  .layout-xs-column {
    flex-direction: column;
  }
  .layout-xs-row {
    flex-direction: row;
  }
}
@media (min-width: 600px) {
  .flex-order-gt-xs--20 {
    order: -20;
  }
  .flex-order-gt-xs--19 {
    order: -19;
  }
  .flex-order-gt-xs--18 {
    order: -18;
  }
  .flex-order-gt-xs--17 {
    order: -17;
  }
  .flex-order-gt-xs--16 {
    order: -16;
  }
  .flex-order-gt-xs--15 {
    order: -15;
  }
  .flex-order-gt-xs--14 {
    order: -14;
  }
  .flex-order-gt-xs--13 {
    order: -13;
  }
  .flex-order-gt-xs--12 {
    order: -12;
  }
  .flex-order-gt-xs--11 {
    order: -11;
  }
  .flex-order-gt-xs--10 {
    order: -10;
  }
  .flex-order-gt-xs--9 {
    order: -9;
  }
  .flex-order-gt-xs--8 {
    order: -8;
  }
  .flex-order-gt-xs--7 {
    order: -7;
  }
  .flex-order-gt-xs--6 {
    order: -6;
  }
  .flex-order-gt-xs--5 {
    order: -5;
  }
  .flex-order-gt-xs--4 {
    order: -4;
  }
  .flex-order-gt-xs--3 {
    order: -3;
  }
  .flex-order-gt-xs--2 {
    order: -2;
  }
  .flex-order-gt-xs--1 {
    order: -1;
  }
  .flex-order-gt-xs-0 {
    order: 0;
  }
  .flex-order-gt-xs-1 {
    order: 1;
  }
  .flex-order-gt-xs-2 {
    order: 2;
  }
  .flex-order-gt-xs-3 {
    order: 3;
  }
  .flex-order-gt-xs-4 {
    order: 4;
  }
  .flex-order-gt-xs-5 {
    order: 5;
  }
  .flex-order-gt-xs-6 {
    order: 6;
  }
  .flex-order-gt-xs-7 {
    order: 7;
  }
  .flex-order-gt-xs-8 {
    order: 8;
  }
  .flex-order-gt-xs-9 {
    order: 9;
  }
  .flex-order-gt-xs-10 {
    order: 10;
  }
  .flex-order-gt-xs-11 {
    order: 11;
  }
  .flex-order-gt-xs-12 {
    order: 12;
  }
  .flex-order-gt-xs-13 {
    order: 13;
  }
  .flex-order-gt-xs-14 {
    order: 14;
  }
  .flex-order-gt-xs-15 {
    order: 15;
  }
  .flex-order-gt-xs-16 {
    order: 16;
  }
  .flex-order-gt-xs-17 {
    order: 17;
  }
  .flex-order-gt-xs-18 {
    order: 18;
  }
  .flex-order-gt-xs-19 {
    order: 19;
  }
  .flex-order-gt-xs-20 {
    order: 20;
  }
  .offset-gt-xs-0, .flex-offset-gt-xs-0 {
    margin-left: 0;
  }
  [dir=rtl] .offset-gt-xs-0, [dir=rtl] .flex-offset-gt-xs-0 {
    margin-left: auto;
    margin-right: 0;
  }
  .offset-gt-xs-5, .flex-offset-gt-xs-5 {
    margin-left: 5%;
  }
  [dir=rtl] .offset-gt-xs-5, [dir=rtl] .flex-offset-gt-xs-5 {
    margin-left: auto;
    margin-right: 5%;
  }
  .offset-gt-xs-10, .flex-offset-gt-xs-10 {
    margin-left: 10%;
  }
  [dir=rtl] .offset-gt-xs-10, [dir=rtl] .flex-offset-gt-xs-10 {
    margin-left: auto;
    margin-right: 10%;
  }
  .offset-gt-xs-15, .flex-offset-gt-xs-15 {
    margin-left: 15%;
  }
  [dir=rtl] .offset-gt-xs-15, [dir=rtl] .flex-offset-gt-xs-15 {
    margin-left: auto;
    margin-right: 15%;
  }
  .offset-gt-xs-20, .flex-offset-gt-xs-20 {
    margin-left: 20%;
  }
  [dir=rtl] .offset-gt-xs-20, [dir=rtl] .flex-offset-gt-xs-20 {
    margin-left: auto;
    margin-right: 20%;
  }
  .offset-gt-xs-25, .flex-offset-gt-xs-25 {
    margin-left: 25%;
  }
  [dir=rtl] .offset-gt-xs-25, [dir=rtl] .flex-offset-gt-xs-25 {
    margin-left: auto;
    margin-right: 25%;
  }
  .offset-gt-xs-30, .flex-offset-gt-xs-30 {
    margin-left: 30%;
  }
  [dir=rtl] .offset-gt-xs-30, [dir=rtl] .flex-offset-gt-xs-30 {
    margin-left: auto;
    margin-right: 30%;
  }
  .offset-gt-xs-35, .flex-offset-gt-xs-35 {
    margin-left: 35%;
  }
  [dir=rtl] .offset-gt-xs-35, [dir=rtl] .flex-offset-gt-xs-35 {
    margin-left: auto;
    margin-right: 35%;
  }
  .offset-gt-xs-40, .flex-offset-gt-xs-40 {
    margin-left: 40%;
  }
  [dir=rtl] .offset-gt-xs-40, [dir=rtl] .flex-offset-gt-xs-40 {
    margin-left: auto;
    margin-right: 40%;
  }
  .offset-gt-xs-45, .flex-offset-gt-xs-45 {
    margin-left: 45%;
  }
  [dir=rtl] .offset-gt-xs-45, [dir=rtl] .flex-offset-gt-xs-45 {
    margin-left: auto;
    margin-right: 45%;
  }
  .offset-gt-xs-50, .flex-offset-gt-xs-50 {
    margin-left: 50%;
  }
  [dir=rtl] .offset-gt-xs-50, [dir=rtl] .flex-offset-gt-xs-50 {
    margin-left: auto;
    margin-right: 50%;
  }
  .offset-gt-xs-55, .flex-offset-gt-xs-55 {
    margin-left: 55%;
  }
  [dir=rtl] .offset-gt-xs-55, [dir=rtl] .flex-offset-gt-xs-55 {
    margin-left: auto;
    margin-right: 55%;
  }
  .offset-gt-xs-60, .flex-offset-gt-xs-60 {
    margin-left: 60%;
  }
  [dir=rtl] .offset-gt-xs-60, [dir=rtl] .flex-offset-gt-xs-60 {
    margin-left: auto;
    margin-right: 60%;
  }
  .offset-gt-xs-65, .flex-offset-gt-xs-65 {
    margin-left: 65%;
  }
  [dir=rtl] .offset-gt-xs-65, [dir=rtl] .flex-offset-gt-xs-65 {
    margin-left: auto;
    margin-right: 65%;
  }
  .offset-gt-xs-70, .flex-offset-gt-xs-70 {
    margin-left: 70%;
  }
  [dir=rtl] .offset-gt-xs-70, [dir=rtl] .flex-offset-gt-xs-70 {
    margin-left: auto;
    margin-right: 70%;
  }
  .offset-gt-xs-75, .flex-offset-gt-xs-75 {
    margin-left: 75%;
  }
  [dir=rtl] .offset-gt-xs-75, [dir=rtl] .flex-offset-gt-xs-75 {
    margin-left: auto;
    margin-right: 75%;
  }
  .offset-gt-xs-80, .flex-offset-gt-xs-80 {
    margin-left: 80%;
  }
  [dir=rtl] .offset-gt-xs-80, [dir=rtl] .flex-offset-gt-xs-80 {
    margin-left: auto;
    margin-right: 80%;
  }
  .offset-gt-xs-85, .flex-offset-gt-xs-85 {
    margin-left: 85%;
  }
  [dir=rtl] .offset-gt-xs-85, [dir=rtl] .flex-offset-gt-xs-85 {
    margin-left: auto;
    margin-right: 85%;
  }
  .offset-gt-xs-90, .flex-offset-gt-xs-90 {
    margin-left: 90%;
  }
  [dir=rtl] .offset-gt-xs-90, [dir=rtl] .flex-offset-gt-xs-90 {
    margin-left: auto;
    margin-right: 90%;
  }
  .offset-gt-xs-95, .flex-offset-gt-xs-95 {
    margin-left: 95%;
  }
  [dir=rtl] .offset-gt-xs-95, [dir=rtl] .flex-offset-gt-xs-95 {
    margin-left: auto;
    margin-right: 95%;
  }
  .offset-gt-xs-33, .flex-offset-gt-xs-33 {
    margin-left: 33.3333333333%;
  }
  .offset-gt-xs-66, .flex-offset-gt-xs-66 {
    margin-left: 66.6666666667%;
  }
  [dir=rtl] .offset-gt-xs-66, [dir=rtl] .flex-offset-gt-xs-66 {
    margin-left: auto;
    margin-right: 66.6666666667%;
  }
  .layout-align-gt-xs,
  .layout-align-gt-xs-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
  }
  .layout-align-gt-xs-start,
  .layout-align-gt-xs-start-start,
  .layout-align-gt-xs-start-center,
  .layout-align-gt-xs-start-end,
  .layout-align-gt-xs-start-stretch {
    justify-content: flex-start;
  }
  .layout-align-gt-xs-center,
  .layout-align-gt-xs-center-start,
  .layout-align-gt-xs-center-center,
  .layout-align-gt-xs-center-end,
  .layout-align-gt-xs-center-stretch {
    justify-content: center;
  }
  .layout-align-gt-xs-end,
  .layout-align-gt-xs-end-start,
  .layout-align-gt-xs-end-center,
  .layout-align-gt-xs-end-end,
  .layout-align-gt-xs-end-stretch {
    justify-content: flex-end;
  }
  .layout-align-gt-xs-space-around,
  .layout-align-gt-xs-space-around-center,
  .layout-align-gt-xs-space-around-start,
  .layout-align-gt-xs-space-around-end,
  .layout-align-gt-xs-space-around-stretch {
    justify-content: space-around;
  }
  .layout-align-gt-xs-space-between,
  .layout-align-gt-xs-space-between-center,
  .layout-align-gt-xs-space-between-start,
  .layout-align-gt-xs-space-between-end,
  .layout-align-gt-xs-space-between-stretch {
    justify-content: space-between;
  }
  .layout-align-gt-xs-start-start,
  .layout-align-gt-xs-center-start,
  .layout-align-gt-xs-end-start,
  .layout-align-gt-xs-space-between-start,
  .layout-align-gt-xs-space-around-start {
    align-items: flex-start;
    align-content: flex-start;
  }
  .layout-align-gt-xs-start-center,
  .layout-align-gt-xs-center-center,
  .layout-align-gt-xs-end-center,
  .layout-align-gt-xs-space-between-center,
  .layout-align-gt-xs-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%;
  }
  .layout-align-gt-xs-start-center > *,
  .layout-align-gt-xs-center-center > *,
  .layout-align-gt-xs-end-center > *,
  .layout-align-gt-xs-space-between-center > *,
  .layout-align-gt-xs-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box;
  }
  .layout-align-gt-xs-start-end,
  .layout-align-gt-xs-center-end,
  .layout-align-gt-xs-end-end,
  .layout-align-gt-xs-space-between-end,
  .layout-align-gt-xs-space-around-end {
    align-items: flex-end;
    align-content: flex-end;
  }
  .layout-align-gt-xs-start-stretch,
  .layout-align-gt-xs-center-stretch,
  .layout-align-gt-xs-end-stretch,
  .layout-align-gt-xs-space-between-stretch,
  .layout-align-gt-xs-space-around-stretch {
    align-items: stretch;
    align-content: stretch;
  }
  .flex-gt-xs {
    flex: 1;
    box-sizing: border-box;
  }
  .flex-gt-xs-grow {
    flex: 1 1 100%;
    box-sizing: border-box;
  }
  .flex-gt-xs-initial {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .flex-gt-xs-auto {
    flex: 1 1 auto;
    box-sizing: border-box;
  }
  .flex-gt-xs-none {
    flex: 0 0 auto;
    box-sizing: border-box;
  }
  .flex-gt-xs-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box;
  }
  .flex-gt-xs-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .flex-gt-xs-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .layout-column > .flex-gt-xs-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-xs-row > .flex-gt-xs-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .layout-gt-xs-column > .flex-gt-xs-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0;
  }
  .flex-gt-xs-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-xs-row > .flex-gt-xs-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-xs-column > .flex-gt-xs-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .flex-gt-xs-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-xs-row > .flex-gt-xs-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-xs-column > .flex-gt-xs-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .flex-gt-xs-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-xs-row > .flex-gt-xs-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-xs-column > .flex-gt-xs-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .flex-gt-xs-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-xs-row > .flex-gt-xs-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-xs-column > .flex-gt-xs-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .flex-gt-xs-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-xs-row > .flex-gt-xs-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-xs-column > .flex-gt-xs-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .flex-gt-xs-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-xs-row > .flex-gt-xs-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-xs-column > .flex-gt-xs-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .flex-gt-xs-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-xs-row > .flex-gt-xs-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-xs-column > .flex-gt-xs-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .flex-gt-xs-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-xs-row > .flex-gt-xs-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-xs-column > .flex-gt-xs-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .flex-gt-xs-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-xs-row > .flex-gt-xs-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-xs-column > .flex-gt-xs-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .flex-gt-xs-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-xs-row > .flex-gt-xs-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-xs-column > .flex-gt-xs-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .flex-gt-xs-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-xs-row > .flex-gt-xs-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-xs-column > .flex-gt-xs-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .flex-gt-xs-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-xs-row > .flex-gt-xs-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-xs-column > .flex-gt-xs-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .flex-gt-xs-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-xs-row > .flex-gt-xs-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-xs-column > .flex-gt-xs-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .flex-gt-xs-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-xs-row > .flex-gt-xs-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-xs-column > .flex-gt-xs-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .flex-gt-xs-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-xs-row > .flex-gt-xs-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-xs-column > .flex-gt-xs-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .flex-gt-xs-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-xs-row > .flex-gt-xs-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-xs-column > .flex-gt-xs-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .flex-gt-xs-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-xs-row > .flex-gt-xs-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-xs-column > .flex-gt-xs-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .flex-gt-xs-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-xs-row > .flex-gt-xs-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-xs-column > .flex-gt-xs-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .flex-gt-xs-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-xs-row > .flex-gt-xs-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-xs-column > .flex-gt-xs-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .flex-gt-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-xs-row > .flex-gt-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-xs-column > .flex-gt-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-xs-row > .flex-gt-xs-33, .layout-gt-xs-row > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-xs-row > .flex-gt-xs-66, .layout-gt-xs-row > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-xs-row > .flex {
    min-width: 0;
  }
  .layout-gt-xs-column > .flex-gt-xs-33, .layout-gt-xs-column > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-gt-xs-column > .flex-gt-xs-66, .layout-gt-xs-column > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-xs-column > .flex {
    min-height: 0;
  }
  .layout-gt-xs, .layout-gt-xs-column, .layout-gt-xs-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }
  .layout-gt-xs-column {
    flex-direction: column;
  }
  .layout-gt-xs-row {
    flex-direction: row;
  }
}
@media (min-width: 600px) and (max-width: 959px) {
  .hide:not(.show-gt-xs):not(.show-sm):not(.show), .hide-gt-xs:not(.show-gt-xs):not(.show-sm):not(.show) {
    display: none;
  }
  .hide-sm:not(.show-gt-xs):not(.show-sm):not(.show) {
    display: none;
  }
  .flex-order-sm--20 {
    order: -20;
  }
  .flex-order-sm--19 {
    order: -19;
  }
  .flex-order-sm--18 {
    order: -18;
  }
  .flex-order-sm--17 {
    order: -17;
  }
  .flex-order-sm--16 {
    order: -16;
  }
  .flex-order-sm--15 {
    order: -15;
  }
  .flex-order-sm--14 {
    order: -14;
  }
  .flex-order-sm--13 {
    order: -13;
  }
  .flex-order-sm--12 {
    order: -12;
  }
  .flex-order-sm--11 {
    order: -11;
  }
  .flex-order-sm--10 {
    order: -10;
  }
  .flex-order-sm--9 {
    order: -9;
  }
  .flex-order-sm--8 {
    order: -8;
  }
  .flex-order-sm--7 {
    order: -7;
  }
  .flex-order-sm--6 {
    order: -6;
  }
  .flex-order-sm--5 {
    order: -5;
  }
  .flex-order-sm--4 {
    order: -4;
  }
  .flex-order-sm--3 {
    order: -3;
  }
  .flex-order-sm--2 {
    order: -2;
  }
  .flex-order-sm--1 {
    order: -1;
  }
  .flex-order-sm-0 {
    order: 0;
  }
  .flex-order-sm-1 {
    order: 1;
  }
  .flex-order-sm-2 {
    order: 2;
  }
  .flex-order-sm-3 {
    order: 3;
  }
  .flex-order-sm-4 {
    order: 4;
  }
  .flex-order-sm-5 {
    order: 5;
  }
  .flex-order-sm-6 {
    order: 6;
  }
  .flex-order-sm-7 {
    order: 7;
  }
  .flex-order-sm-8 {
    order: 8;
  }
  .flex-order-sm-9 {
    order: 9;
  }
  .flex-order-sm-10 {
    order: 10;
  }
  .flex-order-sm-11 {
    order: 11;
  }
  .flex-order-sm-12 {
    order: 12;
  }
  .flex-order-sm-13 {
    order: 13;
  }
  .flex-order-sm-14 {
    order: 14;
  }
  .flex-order-sm-15 {
    order: 15;
  }
  .flex-order-sm-16 {
    order: 16;
  }
  .flex-order-sm-17 {
    order: 17;
  }
  .flex-order-sm-18 {
    order: 18;
  }
  .flex-order-sm-19 {
    order: 19;
  }
  .flex-order-sm-20 {
    order: 20;
  }
  .offset-sm-0, .flex-offset-sm-0 {
    margin-left: 0;
  }
  [dir=rtl] .offset-sm-0, [dir=rtl] .flex-offset-sm-0 {
    margin-left: auto;
    margin-right: 0;
  }
  .offset-sm-5, .flex-offset-sm-5 {
    margin-left: 5%;
  }
  [dir=rtl] .offset-sm-5, [dir=rtl] .flex-offset-sm-5 {
    margin-left: auto;
    margin-right: 5%;
  }
  .offset-sm-10, .flex-offset-sm-10 {
    margin-left: 10%;
  }
  [dir=rtl] .offset-sm-10, [dir=rtl] .flex-offset-sm-10 {
    margin-left: auto;
    margin-right: 10%;
  }
  .offset-sm-15, .flex-offset-sm-15 {
    margin-left: 15%;
  }
  [dir=rtl] .offset-sm-15, [dir=rtl] .flex-offset-sm-15 {
    margin-left: auto;
    margin-right: 15%;
  }
  .offset-sm-20, .flex-offset-sm-20 {
    margin-left: 20%;
  }
  [dir=rtl] .offset-sm-20, [dir=rtl] .flex-offset-sm-20 {
    margin-left: auto;
    margin-right: 20%;
  }
  .offset-sm-25, .flex-offset-sm-25 {
    margin-left: 25%;
  }
  [dir=rtl] .offset-sm-25, [dir=rtl] .flex-offset-sm-25 {
    margin-left: auto;
    margin-right: 25%;
  }
  .offset-sm-30, .flex-offset-sm-30 {
    margin-left: 30%;
  }
  [dir=rtl] .offset-sm-30, [dir=rtl] .flex-offset-sm-30 {
    margin-left: auto;
    margin-right: 30%;
  }
  .offset-sm-35, .flex-offset-sm-35 {
    margin-left: 35%;
  }
  [dir=rtl] .offset-sm-35, [dir=rtl] .flex-offset-sm-35 {
    margin-left: auto;
    margin-right: 35%;
  }
  .offset-sm-40, .flex-offset-sm-40 {
    margin-left: 40%;
  }
  [dir=rtl] .offset-sm-40, [dir=rtl] .flex-offset-sm-40 {
    margin-left: auto;
    margin-right: 40%;
  }
  .offset-sm-45, .flex-offset-sm-45 {
    margin-left: 45%;
  }
  [dir=rtl] .offset-sm-45, [dir=rtl] .flex-offset-sm-45 {
    margin-left: auto;
    margin-right: 45%;
  }
  .offset-sm-50, .flex-offset-sm-50 {
    margin-left: 50%;
  }
  [dir=rtl] .offset-sm-50, [dir=rtl] .flex-offset-sm-50 {
    margin-left: auto;
    margin-right: 50%;
  }
  .offset-sm-55, .flex-offset-sm-55 {
    margin-left: 55%;
  }
  [dir=rtl] .offset-sm-55, [dir=rtl] .flex-offset-sm-55 {
    margin-left: auto;
    margin-right: 55%;
  }
  .offset-sm-60, .flex-offset-sm-60 {
    margin-left: 60%;
  }
  [dir=rtl] .offset-sm-60, [dir=rtl] .flex-offset-sm-60 {
    margin-left: auto;
    margin-right: 60%;
  }
  .offset-sm-65, .flex-offset-sm-65 {
    margin-left: 65%;
  }
  [dir=rtl] .offset-sm-65, [dir=rtl] .flex-offset-sm-65 {
    margin-left: auto;
    margin-right: 65%;
  }
  .offset-sm-70, .flex-offset-sm-70 {
    margin-left: 70%;
  }
  [dir=rtl] .offset-sm-70, [dir=rtl] .flex-offset-sm-70 {
    margin-left: auto;
    margin-right: 70%;
  }
  .offset-sm-75, .flex-offset-sm-75 {
    margin-left: 75%;
  }
  [dir=rtl] .offset-sm-75, [dir=rtl] .flex-offset-sm-75 {
    margin-left: auto;
    margin-right: 75%;
  }
  .offset-sm-80, .flex-offset-sm-80 {
    margin-left: 80%;
  }
  [dir=rtl] .offset-sm-80, [dir=rtl] .flex-offset-sm-80 {
    margin-left: auto;
    margin-right: 80%;
  }
  .offset-sm-85, .flex-offset-sm-85 {
    margin-left: 85%;
  }
  [dir=rtl] .offset-sm-85, [dir=rtl] .flex-offset-sm-85 {
    margin-left: auto;
    margin-right: 85%;
  }
  .offset-sm-90, .flex-offset-sm-90 {
    margin-left: 90%;
  }
  [dir=rtl] .offset-sm-90, [dir=rtl] .flex-offset-sm-90 {
    margin-left: auto;
    margin-right: 90%;
  }
  .offset-sm-95, .flex-offset-sm-95 {
    margin-left: 95%;
  }
  [dir=rtl] .offset-sm-95, [dir=rtl] .flex-offset-sm-95 {
    margin-left: auto;
    margin-right: 95%;
  }
  .offset-sm-33, .flex-offset-sm-33 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-66, .flex-offset-sm-66 {
    margin-left: 66.6666666667%;
  }
  [dir=rtl] .offset-sm-66, [dir=rtl] .flex-offset-sm-66 {
    margin-left: auto;
    margin-right: 66.6666666667%;
  }
  .layout-align-sm,
  .layout-align-sm-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
  }
  .layout-align-sm-start,
  .layout-align-sm-start-start,
  .layout-align-sm-start-center,
  .layout-align-sm-start-end,
  .layout-align-sm-start-stretch {
    justify-content: flex-start;
  }
  .layout-align-sm-center,
  .layout-align-sm-center-start,
  .layout-align-sm-center-center,
  .layout-align-sm-center-end,
  .layout-align-sm-center-stretch {
    justify-content: center;
  }
  .layout-align-sm-end,
  .layout-align-sm-end-start,
  .layout-align-sm-end-center,
  .layout-align-sm-end-end,
  .layout-align-sm-end-stretch {
    justify-content: flex-end;
  }
  .layout-align-sm-space-around,
  .layout-align-sm-space-around-center,
  .layout-align-sm-space-around-start,
  .layout-align-sm-space-around-end,
  .layout-align-sm-space-around-stretch {
    justify-content: space-around;
  }
  .layout-align-sm-space-between,
  .layout-align-sm-space-between-center,
  .layout-align-sm-space-between-start,
  .layout-align-sm-space-between-end,
  .layout-align-sm-space-between-stretch {
    justify-content: space-between;
  }
  .layout-align-sm-start-start,
  .layout-align-sm-center-start,
  .layout-align-sm-end-start,
  .layout-align-sm-space-between-start,
  .layout-align-sm-space-around-start {
    align-items: flex-start;
    align-content: flex-start;
  }
  .layout-align-sm-start-center,
  .layout-align-sm-center-center,
  .layout-align-sm-end-center,
  .layout-align-sm-space-between-center,
  .layout-align-sm-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%;
  }
  .layout-align-sm-start-center > *,
  .layout-align-sm-center-center > *,
  .layout-align-sm-end-center > *,
  .layout-align-sm-space-between-center > *,
  .layout-align-sm-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box;
  }
  .layout-align-sm-start-end,
  .layout-align-sm-center-end,
  .layout-align-sm-end-end,
  .layout-align-sm-space-between-end,
  .layout-align-sm-space-around-end {
    align-items: flex-end;
    align-content: flex-end;
  }
  .layout-align-sm-start-stretch,
  .layout-align-sm-center-stretch,
  .layout-align-sm-end-stretch,
  .layout-align-sm-space-between-stretch,
  .layout-align-sm-space-around-stretch {
    align-items: stretch;
    align-content: stretch;
  }
  .flex-sm {
    flex: 1;
    box-sizing: border-box;
  }
  .flex-sm-grow {
    flex: 1 1 100%;
    box-sizing: border-box;
  }
  .flex-sm-initial {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .flex-sm-auto {
    flex: 1 1 auto;
    box-sizing: border-box;
  }
  .flex-sm-none {
    flex: 0 0 auto;
    box-sizing: border-box;
  }
  .flex-sm-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box;
  }
  .flex-sm-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .flex-sm-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .layout-column > .flex-sm-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-sm-row > .flex-sm-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .layout-sm-column > .flex-sm-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0;
  }
  .flex-sm-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-sm-row > .flex-sm-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-sm-column > .flex-sm-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .flex-sm-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-sm-row > .flex-sm-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-sm-column > .flex-sm-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .flex-sm-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-sm-row > .flex-sm-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-sm-column > .flex-sm-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .flex-sm-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-sm-row > .flex-sm-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-sm-column > .flex-sm-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .flex-sm-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-sm-row > .flex-sm-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-sm-column > .flex-sm-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .flex-sm-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-sm-row > .flex-sm-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-sm-column > .flex-sm-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .flex-sm-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-sm-row > .flex-sm-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-sm-column > .flex-sm-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .flex-sm-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-sm-row > .flex-sm-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-sm-column > .flex-sm-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .flex-sm-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-sm-row > .flex-sm-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-sm-column > .flex-sm-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .flex-sm-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-sm-row > .flex-sm-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-sm-column > .flex-sm-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .flex-sm-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-sm-row > .flex-sm-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-sm-column > .flex-sm-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .flex-sm-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-sm-row > .flex-sm-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-sm-column > .flex-sm-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .flex-sm-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-sm-row > .flex-sm-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-sm-column > .flex-sm-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .flex-sm-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-sm-row > .flex-sm-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-sm-column > .flex-sm-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .flex-sm-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-sm-row > .flex-sm-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-sm-column > .flex-sm-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .flex-sm-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-sm-row > .flex-sm-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-sm-column > .flex-sm-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .flex-sm-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-sm-row > .flex-sm-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-sm-column > .flex-sm-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .flex-sm-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-sm-row > .flex-sm-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-sm-column > .flex-sm-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .flex-sm-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-sm-row > .flex-sm-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-sm-column > .flex-sm-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .flex-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-sm-row > .flex-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-sm-column > .flex-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-sm-row > .flex-sm-33, .layout-sm-row > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-sm-row > .flex-sm-66, .layout-sm-row > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-sm-row > .flex {
    min-width: 0;
  }
  .layout-sm-column > .flex-sm-33, .layout-sm-column > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-sm-column > .flex-sm-66, .layout-sm-column > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-sm-column > .flex {
    min-height: 0;
  }
  .layout-sm, .layout-sm-column, .layout-sm-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }
  .layout-sm-column {
    flex-direction: column;
  }
  .layout-sm-row {
    flex-direction: row;
  }
}
@media (min-width: 960px) {
  .flex-order-gt-sm--20 {
    order: -20;
  }
  .flex-order-gt-sm--19 {
    order: -19;
  }
  .flex-order-gt-sm--18 {
    order: -18;
  }
  .flex-order-gt-sm--17 {
    order: -17;
  }
  .flex-order-gt-sm--16 {
    order: -16;
  }
  .flex-order-gt-sm--15 {
    order: -15;
  }
  .flex-order-gt-sm--14 {
    order: -14;
  }
  .flex-order-gt-sm--13 {
    order: -13;
  }
  .flex-order-gt-sm--12 {
    order: -12;
  }
  .flex-order-gt-sm--11 {
    order: -11;
  }
  .flex-order-gt-sm--10 {
    order: -10;
  }
  .flex-order-gt-sm--9 {
    order: -9;
  }
  .flex-order-gt-sm--8 {
    order: -8;
  }
  .flex-order-gt-sm--7 {
    order: -7;
  }
  .flex-order-gt-sm--6 {
    order: -6;
  }
  .flex-order-gt-sm--5 {
    order: -5;
  }
  .flex-order-gt-sm--4 {
    order: -4;
  }
  .flex-order-gt-sm--3 {
    order: -3;
  }
  .flex-order-gt-sm--2 {
    order: -2;
  }
  .flex-order-gt-sm--1 {
    order: -1;
  }
  .flex-order-gt-sm-0 {
    order: 0;
  }
  .flex-order-gt-sm-1 {
    order: 1;
  }
  .flex-order-gt-sm-2 {
    order: 2;
  }
  .flex-order-gt-sm-3 {
    order: 3;
  }
  .flex-order-gt-sm-4 {
    order: 4;
  }
  .flex-order-gt-sm-5 {
    order: 5;
  }
  .flex-order-gt-sm-6 {
    order: 6;
  }
  .flex-order-gt-sm-7 {
    order: 7;
  }
  .flex-order-gt-sm-8 {
    order: 8;
  }
  .flex-order-gt-sm-9 {
    order: 9;
  }
  .flex-order-gt-sm-10 {
    order: 10;
  }
  .flex-order-gt-sm-11 {
    order: 11;
  }
  .flex-order-gt-sm-12 {
    order: 12;
  }
  .flex-order-gt-sm-13 {
    order: 13;
  }
  .flex-order-gt-sm-14 {
    order: 14;
  }
  .flex-order-gt-sm-15 {
    order: 15;
  }
  .flex-order-gt-sm-16 {
    order: 16;
  }
  .flex-order-gt-sm-17 {
    order: 17;
  }
  .flex-order-gt-sm-18 {
    order: 18;
  }
  .flex-order-gt-sm-19 {
    order: 19;
  }
  .flex-order-gt-sm-20 {
    order: 20;
  }
  .offset-gt-sm-0, .flex-offset-gt-sm-0 {
    margin-left: 0;
  }
  [dir=rtl] .offset-gt-sm-0, [dir=rtl] .flex-offset-gt-sm-0 {
    margin-left: auto;
    margin-right: 0;
  }
  .offset-gt-sm-5, .flex-offset-gt-sm-5 {
    margin-left: 5%;
  }
  [dir=rtl] .offset-gt-sm-5, [dir=rtl] .flex-offset-gt-sm-5 {
    margin-left: auto;
    margin-right: 5%;
  }
  .offset-gt-sm-10, .flex-offset-gt-sm-10 {
    margin-left: 10%;
  }
  [dir=rtl] .offset-gt-sm-10, [dir=rtl] .flex-offset-gt-sm-10 {
    margin-left: auto;
    margin-right: 10%;
  }
  .offset-gt-sm-15, .flex-offset-gt-sm-15 {
    margin-left: 15%;
  }
  [dir=rtl] .offset-gt-sm-15, [dir=rtl] .flex-offset-gt-sm-15 {
    margin-left: auto;
    margin-right: 15%;
  }
  .offset-gt-sm-20, .flex-offset-gt-sm-20 {
    margin-left: 20%;
  }
  [dir=rtl] .offset-gt-sm-20, [dir=rtl] .flex-offset-gt-sm-20 {
    margin-left: auto;
    margin-right: 20%;
  }
  .offset-gt-sm-25, .flex-offset-gt-sm-25 {
    margin-left: 25%;
  }
  [dir=rtl] .offset-gt-sm-25, [dir=rtl] .flex-offset-gt-sm-25 {
    margin-left: auto;
    margin-right: 25%;
  }
  .offset-gt-sm-30, .flex-offset-gt-sm-30 {
    margin-left: 30%;
  }
  [dir=rtl] .offset-gt-sm-30, [dir=rtl] .flex-offset-gt-sm-30 {
    margin-left: auto;
    margin-right: 30%;
  }
  .offset-gt-sm-35, .flex-offset-gt-sm-35 {
    margin-left: 35%;
  }
  [dir=rtl] .offset-gt-sm-35, [dir=rtl] .flex-offset-gt-sm-35 {
    margin-left: auto;
    margin-right: 35%;
  }
  .offset-gt-sm-40, .flex-offset-gt-sm-40 {
    margin-left: 40%;
  }
  [dir=rtl] .offset-gt-sm-40, [dir=rtl] .flex-offset-gt-sm-40 {
    margin-left: auto;
    margin-right: 40%;
  }
  .offset-gt-sm-45, .flex-offset-gt-sm-45 {
    margin-left: 45%;
  }
  [dir=rtl] .offset-gt-sm-45, [dir=rtl] .flex-offset-gt-sm-45 {
    margin-left: auto;
    margin-right: 45%;
  }
  .offset-gt-sm-50, .flex-offset-gt-sm-50 {
    margin-left: 50%;
  }
  [dir=rtl] .offset-gt-sm-50, [dir=rtl] .flex-offset-gt-sm-50 {
    margin-left: auto;
    margin-right: 50%;
  }
  .offset-gt-sm-55, .flex-offset-gt-sm-55 {
    margin-left: 55%;
  }
  [dir=rtl] .offset-gt-sm-55, [dir=rtl] .flex-offset-gt-sm-55 {
    margin-left: auto;
    margin-right: 55%;
  }
  .offset-gt-sm-60, .flex-offset-gt-sm-60 {
    margin-left: 60%;
  }
  [dir=rtl] .offset-gt-sm-60, [dir=rtl] .flex-offset-gt-sm-60 {
    margin-left: auto;
    margin-right: 60%;
  }
  .offset-gt-sm-65, .flex-offset-gt-sm-65 {
    margin-left: 65%;
  }
  [dir=rtl] .offset-gt-sm-65, [dir=rtl] .flex-offset-gt-sm-65 {
    margin-left: auto;
    margin-right: 65%;
  }
  .offset-gt-sm-70, .flex-offset-gt-sm-70 {
    margin-left: 70%;
  }
  [dir=rtl] .offset-gt-sm-70, [dir=rtl] .flex-offset-gt-sm-70 {
    margin-left: auto;
    margin-right: 70%;
  }
  .offset-gt-sm-75, .flex-offset-gt-sm-75 {
    margin-left: 75%;
  }
  [dir=rtl] .offset-gt-sm-75, [dir=rtl] .flex-offset-gt-sm-75 {
    margin-left: auto;
    margin-right: 75%;
  }
  .offset-gt-sm-80, .flex-offset-gt-sm-80 {
    margin-left: 80%;
  }
  [dir=rtl] .offset-gt-sm-80, [dir=rtl] .flex-offset-gt-sm-80 {
    margin-left: auto;
    margin-right: 80%;
  }
  .offset-gt-sm-85, .flex-offset-gt-sm-85 {
    margin-left: 85%;
  }
  [dir=rtl] .offset-gt-sm-85, [dir=rtl] .flex-offset-gt-sm-85 {
    margin-left: auto;
    margin-right: 85%;
  }
  .offset-gt-sm-90, .flex-offset-gt-sm-90 {
    margin-left: 90%;
  }
  [dir=rtl] .offset-gt-sm-90, [dir=rtl] .flex-offset-gt-sm-90 {
    margin-left: auto;
    margin-right: 90%;
  }
  .offset-gt-sm-95, .flex-offset-gt-sm-95 {
    margin-left: 95%;
  }
  [dir=rtl] .offset-gt-sm-95, [dir=rtl] .flex-offset-gt-sm-95 {
    margin-left: auto;
    margin-right: 95%;
  }
  .offset-gt-sm-33, .flex-offset-gt-sm-33 {
    margin-left: 33.3333333333%;
  }
  .offset-gt-sm-66, .flex-offset-gt-sm-66 {
    margin-left: 66.6666666667%;
  }
  [dir=rtl] .offset-gt-sm-66, [dir=rtl] .flex-offset-gt-sm-66 {
    margin-left: auto;
    margin-right: 66.6666666667%;
  }
  .layout-align-gt-sm,
  .layout-align-gt-sm-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
  }
  .layout-align-gt-sm-start,
  .layout-align-gt-sm-start-start,
  .layout-align-gt-sm-start-center,
  .layout-align-gt-sm-start-end,
  .layout-align-gt-sm-start-stretch {
    justify-content: flex-start;
  }
  .layout-align-gt-sm-center,
  .layout-align-gt-sm-center-start,
  .layout-align-gt-sm-center-center,
  .layout-align-gt-sm-center-end,
  .layout-align-gt-sm-center-stretch {
    justify-content: center;
  }
  .layout-align-gt-sm-end,
  .layout-align-gt-sm-end-start,
  .layout-align-gt-sm-end-center,
  .layout-align-gt-sm-end-end,
  .layout-align-gt-sm-end-stretch {
    justify-content: flex-end;
  }
  .layout-align-gt-sm-space-around,
  .layout-align-gt-sm-space-around-center,
  .layout-align-gt-sm-space-around-start,
  .layout-align-gt-sm-space-around-end,
  .layout-align-gt-sm-space-around-stretch {
    justify-content: space-around;
  }
  .layout-align-gt-sm-space-between,
  .layout-align-gt-sm-space-between-center,
  .layout-align-gt-sm-space-between-start,
  .layout-align-gt-sm-space-between-end,
  .layout-align-gt-sm-space-between-stretch {
    justify-content: space-between;
  }
  .layout-align-gt-sm-start-start,
  .layout-align-gt-sm-center-start,
  .layout-align-gt-sm-end-start,
  .layout-align-gt-sm-space-between-start,
  .layout-align-gt-sm-space-around-start {
    align-items: flex-start;
    align-content: flex-start;
  }
  .layout-align-gt-sm-start-center,
  .layout-align-gt-sm-center-center,
  .layout-align-gt-sm-end-center,
  .layout-align-gt-sm-space-between-center,
  .layout-align-gt-sm-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%;
  }
  .layout-align-gt-sm-start-center > *,
  .layout-align-gt-sm-center-center > *,
  .layout-align-gt-sm-end-center > *,
  .layout-align-gt-sm-space-between-center > *,
  .layout-align-gt-sm-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box;
  }
  .layout-align-gt-sm-start-end,
  .layout-align-gt-sm-center-end,
  .layout-align-gt-sm-end-end,
  .layout-align-gt-sm-space-between-end,
  .layout-align-gt-sm-space-around-end {
    align-items: flex-end;
    align-content: flex-end;
  }
  .layout-align-gt-sm-start-stretch,
  .layout-align-gt-sm-center-stretch,
  .layout-align-gt-sm-end-stretch,
  .layout-align-gt-sm-space-between-stretch,
  .layout-align-gt-sm-space-around-stretch {
    align-items: stretch;
    align-content: stretch;
  }
  .flex-gt-sm {
    flex: 1;
    box-sizing: border-box;
  }
  .flex-gt-sm-grow {
    flex: 1 1 100%;
    box-sizing: border-box;
  }
  .flex-gt-sm-initial {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .flex-gt-sm-auto {
    flex: 1 1 auto;
    box-sizing: border-box;
  }
  .flex-gt-sm-none {
    flex: 0 0 auto;
    box-sizing: border-box;
  }
  .flex-gt-sm-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box;
  }
  .flex-gt-sm-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .flex-gt-sm-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .layout-column > .flex-gt-sm-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-sm-row > .flex-gt-sm-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .layout-gt-sm-column > .flex-gt-sm-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0;
  }
  .flex-gt-sm-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-sm-row > .flex-gt-sm-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-sm-column > .flex-gt-sm-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .flex-gt-sm-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-sm-row > .flex-gt-sm-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-sm-column > .flex-gt-sm-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .flex-gt-sm-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-sm-row > .flex-gt-sm-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-sm-column > .flex-gt-sm-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .flex-gt-sm-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-sm-row > .flex-gt-sm-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-sm-column > .flex-gt-sm-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .flex-gt-sm-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-sm-row > .flex-gt-sm-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-sm-column > .flex-gt-sm-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .flex-gt-sm-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-sm-row > .flex-gt-sm-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-sm-column > .flex-gt-sm-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .flex-gt-sm-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-sm-row > .flex-gt-sm-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-sm-column > .flex-gt-sm-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .flex-gt-sm-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-sm-row > .flex-gt-sm-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-sm-column > .flex-gt-sm-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .flex-gt-sm-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-sm-row > .flex-gt-sm-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-sm-column > .flex-gt-sm-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .flex-gt-sm-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-sm-row > .flex-gt-sm-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-sm-column > .flex-gt-sm-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .flex-gt-sm-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-sm-row > .flex-gt-sm-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-sm-column > .flex-gt-sm-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .flex-gt-sm-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-sm-row > .flex-gt-sm-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-sm-column > .flex-gt-sm-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .flex-gt-sm-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-sm-row > .flex-gt-sm-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-sm-column > .flex-gt-sm-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .flex-gt-sm-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-sm-row > .flex-gt-sm-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-sm-column > .flex-gt-sm-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .flex-gt-sm-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-sm-row > .flex-gt-sm-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-sm-column > .flex-gt-sm-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .flex-gt-sm-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-sm-row > .flex-gt-sm-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-sm-column > .flex-gt-sm-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .flex-gt-sm-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-sm-row > .flex-gt-sm-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-sm-column > .flex-gt-sm-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .flex-gt-sm-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-sm-row > .flex-gt-sm-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-sm-column > .flex-gt-sm-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .flex-gt-sm-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-sm-row > .flex-gt-sm-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-sm-column > .flex-gt-sm-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .flex-gt-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-sm-row > .flex-gt-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-sm-column > .flex-gt-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-sm-row > .flex-gt-sm-33, .layout-gt-sm-row > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-sm-row > .flex-gt-sm-66, .layout-gt-sm-row > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-sm-row > .flex {
    min-width: 0;
  }
  .layout-gt-sm-column > .flex-gt-sm-33, .layout-gt-sm-column > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-gt-sm-column > .flex-gt-sm-66, .layout-gt-sm-column > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-sm-column > .flex {
    min-height: 0;
  }
  .layout-gt-sm, .layout-gt-sm-column, .layout-gt-sm-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }
  .layout-gt-sm-column {
    flex-direction: column;
  }
  .layout-gt-sm-row {
    flex-direction: row;
  }
}
@media (min-width: 960px) and (max-width: 1279px) {
  .hide:not(.show-gt-xs):not(.show-gt-sm):not(.show-md):not(.show), .hide-gt-xs:not(.show-gt-xs):not(.show-gt-sm):not(.show-md):not(.show), .hide-gt-sm:not(.show-gt-xs):not(.show-gt-sm):not(.show-md):not(.show) {
    display: none;
  }
  .hide-md:not(.show-md):not(.show-gt-sm):not(.show-gt-xs):not(.show) {
    display: none;
  }
  .flex-order-md--20 {
    order: -20;
  }
  .flex-order-md--19 {
    order: -19;
  }
  .flex-order-md--18 {
    order: -18;
  }
  .flex-order-md--17 {
    order: -17;
  }
  .flex-order-md--16 {
    order: -16;
  }
  .flex-order-md--15 {
    order: -15;
  }
  .flex-order-md--14 {
    order: -14;
  }
  .flex-order-md--13 {
    order: -13;
  }
  .flex-order-md--12 {
    order: -12;
  }
  .flex-order-md--11 {
    order: -11;
  }
  .flex-order-md--10 {
    order: -10;
  }
  .flex-order-md--9 {
    order: -9;
  }
  .flex-order-md--8 {
    order: -8;
  }
  .flex-order-md--7 {
    order: -7;
  }
  .flex-order-md--6 {
    order: -6;
  }
  .flex-order-md--5 {
    order: -5;
  }
  .flex-order-md--4 {
    order: -4;
  }
  .flex-order-md--3 {
    order: -3;
  }
  .flex-order-md--2 {
    order: -2;
  }
  .flex-order-md--1 {
    order: -1;
  }
  .flex-order-md-0 {
    order: 0;
  }
  .flex-order-md-1 {
    order: 1;
  }
  .flex-order-md-2 {
    order: 2;
  }
  .flex-order-md-3 {
    order: 3;
  }
  .flex-order-md-4 {
    order: 4;
  }
  .flex-order-md-5 {
    order: 5;
  }
  .flex-order-md-6 {
    order: 6;
  }
  .flex-order-md-7 {
    order: 7;
  }
  .flex-order-md-8 {
    order: 8;
  }
  .flex-order-md-9 {
    order: 9;
  }
  .flex-order-md-10 {
    order: 10;
  }
  .flex-order-md-11 {
    order: 11;
  }
  .flex-order-md-12 {
    order: 12;
  }
  .flex-order-md-13 {
    order: 13;
  }
  .flex-order-md-14 {
    order: 14;
  }
  .flex-order-md-15 {
    order: 15;
  }
  .flex-order-md-16 {
    order: 16;
  }
  .flex-order-md-17 {
    order: 17;
  }
  .flex-order-md-18 {
    order: 18;
  }
  .flex-order-md-19 {
    order: 19;
  }
  .flex-order-md-20 {
    order: 20;
  }
  .offset-md-0, .flex-offset-md-0 {
    margin-left: 0;
  }
  [dir=rtl] .offset-md-0, [dir=rtl] .flex-offset-md-0 {
    margin-left: auto;
    margin-right: 0;
  }
  .offset-md-5, .flex-offset-md-5 {
    margin-left: 5%;
  }
  [dir=rtl] .offset-md-5, [dir=rtl] .flex-offset-md-5 {
    margin-left: auto;
    margin-right: 5%;
  }
  .offset-md-10, .flex-offset-md-10 {
    margin-left: 10%;
  }
  [dir=rtl] .offset-md-10, [dir=rtl] .flex-offset-md-10 {
    margin-left: auto;
    margin-right: 10%;
  }
  .offset-md-15, .flex-offset-md-15 {
    margin-left: 15%;
  }
  [dir=rtl] .offset-md-15, [dir=rtl] .flex-offset-md-15 {
    margin-left: auto;
    margin-right: 15%;
  }
  .offset-md-20, .flex-offset-md-20 {
    margin-left: 20%;
  }
  [dir=rtl] .offset-md-20, [dir=rtl] .flex-offset-md-20 {
    margin-left: auto;
    margin-right: 20%;
  }
  .offset-md-25, .flex-offset-md-25 {
    margin-left: 25%;
  }
  [dir=rtl] .offset-md-25, [dir=rtl] .flex-offset-md-25 {
    margin-left: auto;
    margin-right: 25%;
  }
  .offset-md-30, .flex-offset-md-30 {
    margin-left: 30%;
  }
  [dir=rtl] .offset-md-30, [dir=rtl] .flex-offset-md-30 {
    margin-left: auto;
    margin-right: 30%;
  }
  .offset-md-35, .flex-offset-md-35 {
    margin-left: 35%;
  }
  [dir=rtl] .offset-md-35, [dir=rtl] .flex-offset-md-35 {
    margin-left: auto;
    margin-right: 35%;
  }
  .offset-md-40, .flex-offset-md-40 {
    margin-left: 40%;
  }
  [dir=rtl] .offset-md-40, [dir=rtl] .flex-offset-md-40 {
    margin-left: auto;
    margin-right: 40%;
  }
  .offset-md-45, .flex-offset-md-45 {
    margin-left: 45%;
  }
  [dir=rtl] .offset-md-45, [dir=rtl] .flex-offset-md-45 {
    margin-left: auto;
    margin-right: 45%;
  }
  .offset-md-50, .flex-offset-md-50 {
    margin-left: 50%;
  }
  [dir=rtl] .offset-md-50, [dir=rtl] .flex-offset-md-50 {
    margin-left: auto;
    margin-right: 50%;
  }
  .offset-md-55, .flex-offset-md-55 {
    margin-left: 55%;
  }
  [dir=rtl] .offset-md-55, [dir=rtl] .flex-offset-md-55 {
    margin-left: auto;
    margin-right: 55%;
  }
  .offset-md-60, .flex-offset-md-60 {
    margin-left: 60%;
  }
  [dir=rtl] .offset-md-60, [dir=rtl] .flex-offset-md-60 {
    margin-left: auto;
    margin-right: 60%;
  }
  .offset-md-65, .flex-offset-md-65 {
    margin-left: 65%;
  }
  [dir=rtl] .offset-md-65, [dir=rtl] .flex-offset-md-65 {
    margin-left: auto;
    margin-right: 65%;
  }
  .offset-md-70, .flex-offset-md-70 {
    margin-left: 70%;
  }
  [dir=rtl] .offset-md-70, [dir=rtl] .flex-offset-md-70 {
    margin-left: auto;
    margin-right: 70%;
  }
  .offset-md-75, .flex-offset-md-75 {
    margin-left: 75%;
  }
  [dir=rtl] .offset-md-75, [dir=rtl] .flex-offset-md-75 {
    margin-left: auto;
    margin-right: 75%;
  }
  .offset-md-80, .flex-offset-md-80 {
    margin-left: 80%;
  }
  [dir=rtl] .offset-md-80, [dir=rtl] .flex-offset-md-80 {
    margin-left: auto;
    margin-right: 80%;
  }
  .offset-md-85, .flex-offset-md-85 {
    margin-left: 85%;
  }
  [dir=rtl] .offset-md-85, [dir=rtl] .flex-offset-md-85 {
    margin-left: auto;
    margin-right: 85%;
  }
  .offset-md-90, .flex-offset-md-90 {
    margin-left: 90%;
  }
  [dir=rtl] .offset-md-90, [dir=rtl] .flex-offset-md-90 {
    margin-left: auto;
    margin-right: 90%;
  }
  .offset-md-95, .flex-offset-md-95 {
    margin-left: 95%;
  }
  [dir=rtl] .offset-md-95, [dir=rtl] .flex-offset-md-95 {
    margin-left: auto;
    margin-right: 95%;
  }
  .offset-md-33, .flex-offset-md-33 {
    margin-left: 33.3333333333%;
  }
  .offset-md-66, .flex-offset-md-66 {
    margin-left: 66.6666666667%;
  }
  [dir=rtl] .offset-md-66, [dir=rtl] .flex-offset-md-66 {
    margin-left: auto;
    margin-right: 66.6666666667%;
  }
  .layout-align-md,
  .layout-align-md-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
  }
  .layout-align-md-start,
  .layout-align-md-start-start,
  .layout-align-md-start-center,
  .layout-align-md-start-end,
  .layout-align-md-start-stretch {
    justify-content: flex-start;
  }
  .layout-align-md-center,
  .layout-align-md-center-start,
  .layout-align-md-center-center,
  .layout-align-md-center-end,
  .layout-align-md-center-stretch {
    justify-content: center;
  }
  .layout-align-md-end,
  .layout-align-md-end-start,
  .layout-align-md-end-center,
  .layout-align-md-end-end,
  .layout-align-md-end-stretch {
    justify-content: flex-end;
  }
  .layout-align-md-space-around,
  .layout-align-md-space-around-center,
  .layout-align-md-space-around-start,
  .layout-align-md-space-around-end,
  .layout-align-md-space-around-stretch {
    justify-content: space-around;
  }
  .layout-align-md-space-between,
  .layout-align-md-space-between-center,
  .layout-align-md-space-between-start,
  .layout-align-md-space-between-end,
  .layout-align-md-space-between-stretch {
    justify-content: space-between;
  }
  .layout-align-md-start-start,
  .layout-align-md-center-start,
  .layout-align-md-end-start,
  .layout-align-md-space-between-start,
  .layout-align-md-space-around-start {
    align-items: flex-start;
    align-content: flex-start;
  }
  .layout-align-md-start-center,
  .layout-align-md-center-center,
  .layout-align-md-end-center,
  .layout-align-md-space-between-center,
  .layout-align-md-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%;
  }
  .layout-align-md-start-center > *,
  .layout-align-md-center-center > *,
  .layout-align-md-end-center > *,
  .layout-align-md-space-between-center > *,
  .layout-align-md-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box;
  }
  .layout-align-md-start-end,
  .layout-align-md-center-end,
  .layout-align-md-end-end,
  .layout-align-md-space-between-end,
  .layout-align-md-space-around-end {
    align-items: flex-end;
    align-content: flex-end;
  }
  .layout-align-md-start-stretch,
  .layout-align-md-center-stretch,
  .layout-align-md-end-stretch,
  .layout-align-md-space-between-stretch,
  .layout-align-md-space-around-stretch {
    align-items: stretch;
    align-content: stretch;
  }
  .flex-md {
    flex: 1;
    box-sizing: border-box;
  }
  .flex-md-grow {
    flex: 1 1 100%;
    box-sizing: border-box;
  }
  .flex-md-initial {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .flex-md-auto {
    flex: 1 1 auto;
    box-sizing: border-box;
  }
  .flex-md-none {
    flex: 0 0 auto;
    box-sizing: border-box;
  }
  .flex-md-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box;
  }
  .flex-md-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .flex-md-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .layout-column > .flex-md-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-md-row > .flex-md-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .layout-md-column > .flex-md-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0;
  }
  .flex-md-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-md-row > .flex-md-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-md-column > .flex-md-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .flex-md-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-md-row > .flex-md-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-md-column > .flex-md-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .flex-md-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-md-row > .flex-md-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-md-column > .flex-md-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .flex-md-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-md-row > .flex-md-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-md-column > .flex-md-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .flex-md-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-md-row > .flex-md-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-md-column > .flex-md-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .flex-md-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-md-row > .flex-md-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-md-column > .flex-md-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .flex-md-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-md-row > .flex-md-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-md-column > .flex-md-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .flex-md-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-md-row > .flex-md-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-md-column > .flex-md-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .flex-md-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-md-row > .flex-md-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-md-column > .flex-md-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .flex-md-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-md-row > .flex-md-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-md-column > .flex-md-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .flex-md-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-md-row > .flex-md-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-md-column > .flex-md-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .flex-md-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-md-row > .flex-md-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-md-column > .flex-md-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .flex-md-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-md-row > .flex-md-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-md-column > .flex-md-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .flex-md-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-md-row > .flex-md-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-md-column > .flex-md-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .flex-md-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-md-row > .flex-md-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-md-column > .flex-md-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .flex-md-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-md-row > .flex-md-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-md-column > .flex-md-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .flex-md-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-md-row > .flex-md-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-md-column > .flex-md-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .flex-md-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-md-row > .flex-md-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-md-column > .flex-md-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .flex-md-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-md-row > .flex-md-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-md-column > .flex-md-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .flex-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-md-row > .flex-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-md-column > .flex-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-md-row > .flex-md-33, .layout-md-row > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-md-row > .flex-md-66, .layout-md-row > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-md-row > .flex {
    min-width: 0;
  }
  .layout-md-column > .flex-md-33, .layout-md-column > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-md-column > .flex-md-66, .layout-md-column > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-md-column > .flex {
    min-height: 0;
  }
  .layout-md, .layout-md-column, .layout-md-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }
  .layout-md-column {
    flex-direction: column;
  }
  .layout-md-row {
    flex-direction: row;
  }
}
@media (min-width: 1280px) {
  .flex-order-gt-md--20 {
    order: -20;
  }
  .flex-order-gt-md--19 {
    order: -19;
  }
  .flex-order-gt-md--18 {
    order: -18;
  }
  .flex-order-gt-md--17 {
    order: -17;
  }
  .flex-order-gt-md--16 {
    order: -16;
  }
  .flex-order-gt-md--15 {
    order: -15;
  }
  .flex-order-gt-md--14 {
    order: -14;
  }
  .flex-order-gt-md--13 {
    order: -13;
  }
  .flex-order-gt-md--12 {
    order: -12;
  }
  .flex-order-gt-md--11 {
    order: -11;
  }
  .flex-order-gt-md--10 {
    order: -10;
  }
  .flex-order-gt-md--9 {
    order: -9;
  }
  .flex-order-gt-md--8 {
    order: -8;
  }
  .flex-order-gt-md--7 {
    order: -7;
  }
  .flex-order-gt-md--6 {
    order: -6;
  }
  .flex-order-gt-md--5 {
    order: -5;
  }
  .flex-order-gt-md--4 {
    order: -4;
  }
  .flex-order-gt-md--3 {
    order: -3;
  }
  .flex-order-gt-md--2 {
    order: -2;
  }
  .flex-order-gt-md--1 {
    order: -1;
  }
  .flex-order-gt-md-0 {
    order: 0;
  }
  .flex-order-gt-md-1 {
    order: 1;
  }
  .flex-order-gt-md-2 {
    order: 2;
  }
  .flex-order-gt-md-3 {
    order: 3;
  }
  .flex-order-gt-md-4 {
    order: 4;
  }
  .flex-order-gt-md-5 {
    order: 5;
  }
  .flex-order-gt-md-6 {
    order: 6;
  }
  .flex-order-gt-md-7 {
    order: 7;
  }
  .flex-order-gt-md-8 {
    order: 8;
  }
  .flex-order-gt-md-9 {
    order: 9;
  }
  .flex-order-gt-md-10 {
    order: 10;
  }
  .flex-order-gt-md-11 {
    order: 11;
  }
  .flex-order-gt-md-12 {
    order: 12;
  }
  .flex-order-gt-md-13 {
    order: 13;
  }
  .flex-order-gt-md-14 {
    order: 14;
  }
  .flex-order-gt-md-15 {
    order: 15;
  }
  .flex-order-gt-md-16 {
    order: 16;
  }
  .flex-order-gt-md-17 {
    order: 17;
  }
  .flex-order-gt-md-18 {
    order: 18;
  }
  .flex-order-gt-md-19 {
    order: 19;
  }
  .flex-order-gt-md-20 {
    order: 20;
  }
  .offset-gt-md-0, .flex-offset-gt-md-0 {
    margin-left: 0;
  }
  [dir=rtl] .offset-gt-md-0, [dir=rtl] .flex-offset-gt-md-0 {
    margin-left: auto;
    margin-right: 0;
  }
  .offset-gt-md-5, .flex-offset-gt-md-5 {
    margin-left: 5%;
  }
  [dir=rtl] .offset-gt-md-5, [dir=rtl] .flex-offset-gt-md-5 {
    margin-left: auto;
    margin-right: 5%;
  }
  .offset-gt-md-10, .flex-offset-gt-md-10 {
    margin-left: 10%;
  }
  [dir=rtl] .offset-gt-md-10, [dir=rtl] .flex-offset-gt-md-10 {
    margin-left: auto;
    margin-right: 10%;
  }
  .offset-gt-md-15, .flex-offset-gt-md-15 {
    margin-left: 15%;
  }
  [dir=rtl] .offset-gt-md-15, [dir=rtl] .flex-offset-gt-md-15 {
    margin-left: auto;
    margin-right: 15%;
  }
  .offset-gt-md-20, .flex-offset-gt-md-20 {
    margin-left: 20%;
  }
  [dir=rtl] .offset-gt-md-20, [dir=rtl] .flex-offset-gt-md-20 {
    margin-left: auto;
    margin-right: 20%;
  }
  .offset-gt-md-25, .flex-offset-gt-md-25 {
    margin-left: 25%;
  }
  [dir=rtl] .offset-gt-md-25, [dir=rtl] .flex-offset-gt-md-25 {
    margin-left: auto;
    margin-right: 25%;
  }
  .offset-gt-md-30, .flex-offset-gt-md-30 {
    margin-left: 30%;
  }
  [dir=rtl] .offset-gt-md-30, [dir=rtl] .flex-offset-gt-md-30 {
    margin-left: auto;
    margin-right: 30%;
  }
  .offset-gt-md-35, .flex-offset-gt-md-35 {
    margin-left: 35%;
  }
  [dir=rtl] .offset-gt-md-35, [dir=rtl] .flex-offset-gt-md-35 {
    margin-left: auto;
    margin-right: 35%;
  }
  .offset-gt-md-40, .flex-offset-gt-md-40 {
    margin-left: 40%;
  }
  [dir=rtl] .offset-gt-md-40, [dir=rtl] .flex-offset-gt-md-40 {
    margin-left: auto;
    margin-right: 40%;
  }
  .offset-gt-md-45, .flex-offset-gt-md-45 {
    margin-left: 45%;
  }
  [dir=rtl] .offset-gt-md-45, [dir=rtl] .flex-offset-gt-md-45 {
    margin-left: auto;
    margin-right: 45%;
  }
  .offset-gt-md-50, .flex-offset-gt-md-50 {
    margin-left: 50%;
  }
  [dir=rtl] .offset-gt-md-50, [dir=rtl] .flex-offset-gt-md-50 {
    margin-left: auto;
    margin-right: 50%;
  }
  .offset-gt-md-55, .flex-offset-gt-md-55 {
    margin-left: 55%;
  }
  [dir=rtl] .offset-gt-md-55, [dir=rtl] .flex-offset-gt-md-55 {
    margin-left: auto;
    margin-right: 55%;
  }
  .offset-gt-md-60, .flex-offset-gt-md-60 {
    margin-left: 60%;
  }
  [dir=rtl] .offset-gt-md-60, [dir=rtl] .flex-offset-gt-md-60 {
    margin-left: auto;
    margin-right: 60%;
  }
  .offset-gt-md-65, .flex-offset-gt-md-65 {
    margin-left: 65%;
  }
  [dir=rtl] .offset-gt-md-65, [dir=rtl] .flex-offset-gt-md-65 {
    margin-left: auto;
    margin-right: 65%;
  }
  .offset-gt-md-70, .flex-offset-gt-md-70 {
    margin-left: 70%;
  }
  [dir=rtl] .offset-gt-md-70, [dir=rtl] .flex-offset-gt-md-70 {
    margin-left: auto;
    margin-right: 70%;
  }
  .offset-gt-md-75, .flex-offset-gt-md-75 {
    margin-left: 75%;
  }
  [dir=rtl] .offset-gt-md-75, [dir=rtl] .flex-offset-gt-md-75 {
    margin-left: auto;
    margin-right: 75%;
  }
  .offset-gt-md-80, .flex-offset-gt-md-80 {
    margin-left: 80%;
  }
  [dir=rtl] .offset-gt-md-80, [dir=rtl] .flex-offset-gt-md-80 {
    margin-left: auto;
    margin-right: 80%;
  }
  .offset-gt-md-85, .flex-offset-gt-md-85 {
    margin-left: 85%;
  }
  [dir=rtl] .offset-gt-md-85, [dir=rtl] .flex-offset-gt-md-85 {
    margin-left: auto;
    margin-right: 85%;
  }
  .offset-gt-md-90, .flex-offset-gt-md-90 {
    margin-left: 90%;
  }
  [dir=rtl] .offset-gt-md-90, [dir=rtl] .flex-offset-gt-md-90 {
    margin-left: auto;
    margin-right: 90%;
  }
  .offset-gt-md-95, .flex-offset-gt-md-95 {
    margin-left: 95%;
  }
  [dir=rtl] .offset-gt-md-95, [dir=rtl] .flex-offset-gt-md-95 {
    margin-left: auto;
    margin-right: 95%;
  }
  .offset-gt-md-33, .flex-offset-gt-md-33 {
    margin-left: 33.3333333333%;
  }
  .offset-gt-md-66, .flex-offset-gt-md-66 {
    margin-left: 66.6666666667%;
  }
  [dir=rtl] .offset-gt-md-66, [dir=rtl] .flex-offset-gt-md-66 {
    margin-left: auto;
    margin-right: 66.6666666667%;
  }
  .layout-align-gt-md,
  .layout-align-gt-md-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
  }
  .layout-align-gt-md-start,
  .layout-align-gt-md-start-start,
  .layout-align-gt-md-start-center,
  .layout-align-gt-md-start-end,
  .layout-align-gt-md-start-stretch {
    justify-content: flex-start;
  }
  .layout-align-gt-md-center,
  .layout-align-gt-md-center-start,
  .layout-align-gt-md-center-center,
  .layout-align-gt-md-center-end,
  .layout-align-gt-md-center-stretch {
    justify-content: center;
  }
  .layout-align-gt-md-end,
  .layout-align-gt-md-end-start,
  .layout-align-gt-md-end-center,
  .layout-align-gt-md-end-end,
  .layout-align-gt-md-end-stretch {
    justify-content: flex-end;
  }
  .layout-align-gt-md-space-around,
  .layout-align-gt-md-space-around-center,
  .layout-align-gt-md-space-around-start,
  .layout-align-gt-md-space-around-end,
  .layout-align-gt-md-space-around-stretch {
    justify-content: space-around;
  }
  .layout-align-gt-md-space-between,
  .layout-align-gt-md-space-between-center,
  .layout-align-gt-md-space-between-start,
  .layout-align-gt-md-space-between-end,
  .layout-align-gt-md-space-between-stretch {
    justify-content: space-between;
  }
  .layout-align-gt-md-start-start,
  .layout-align-gt-md-center-start,
  .layout-align-gt-md-end-start,
  .layout-align-gt-md-space-between-start,
  .layout-align-gt-md-space-around-start {
    align-items: flex-start;
    align-content: flex-start;
  }
  .layout-align-gt-md-start-center,
  .layout-align-gt-md-center-center,
  .layout-align-gt-md-end-center,
  .layout-align-gt-md-space-between-center,
  .layout-align-gt-md-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%;
  }
  .layout-align-gt-md-start-center > *,
  .layout-align-gt-md-center-center > *,
  .layout-align-gt-md-end-center > *,
  .layout-align-gt-md-space-between-center > *,
  .layout-align-gt-md-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box;
  }
  .layout-align-gt-md-start-end,
  .layout-align-gt-md-center-end,
  .layout-align-gt-md-end-end,
  .layout-align-gt-md-space-between-end,
  .layout-align-gt-md-space-around-end {
    align-items: flex-end;
    align-content: flex-end;
  }
  .layout-align-gt-md-start-stretch,
  .layout-align-gt-md-center-stretch,
  .layout-align-gt-md-end-stretch,
  .layout-align-gt-md-space-between-stretch,
  .layout-align-gt-md-space-around-stretch {
    align-items: stretch;
    align-content: stretch;
  }
  .flex-gt-md {
    flex: 1;
    box-sizing: border-box;
  }
  .flex-gt-md-grow {
    flex: 1 1 100%;
    box-sizing: border-box;
  }
  .flex-gt-md-initial {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .flex-gt-md-auto {
    flex: 1 1 auto;
    box-sizing: border-box;
  }
  .flex-gt-md-none {
    flex: 0 0 auto;
    box-sizing: border-box;
  }
  .flex-gt-md-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box;
  }
  .flex-gt-md-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .flex-gt-md-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .layout-column > .flex-gt-md-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-md-row > .flex-gt-md-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .layout-gt-md-column > .flex-gt-md-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0;
  }
  .flex-gt-md-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-md-row > .flex-gt-md-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-md-column > .flex-gt-md-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .flex-gt-md-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-md-row > .flex-gt-md-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-md-column > .flex-gt-md-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .flex-gt-md-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-md-row > .flex-gt-md-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-md-column > .flex-gt-md-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .flex-gt-md-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-md-row > .flex-gt-md-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-md-column > .flex-gt-md-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .flex-gt-md-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-md-row > .flex-gt-md-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-md-column > .flex-gt-md-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .flex-gt-md-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-md-row > .flex-gt-md-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-md-column > .flex-gt-md-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .flex-gt-md-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-md-row > .flex-gt-md-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-md-column > .flex-gt-md-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .flex-gt-md-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-md-row > .flex-gt-md-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-md-column > .flex-gt-md-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .flex-gt-md-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-md-row > .flex-gt-md-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-md-column > .flex-gt-md-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .flex-gt-md-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-md-row > .flex-gt-md-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-md-column > .flex-gt-md-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .flex-gt-md-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-md-row > .flex-gt-md-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-md-column > .flex-gt-md-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .flex-gt-md-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-md-row > .flex-gt-md-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-md-column > .flex-gt-md-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .flex-gt-md-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-md-row > .flex-gt-md-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-md-column > .flex-gt-md-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .flex-gt-md-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-md-row > .flex-gt-md-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-md-column > .flex-gt-md-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .flex-gt-md-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-md-row > .flex-gt-md-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-md-column > .flex-gt-md-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .flex-gt-md-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-md-row > .flex-gt-md-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-md-column > .flex-gt-md-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .flex-gt-md-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-md-row > .flex-gt-md-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-md-column > .flex-gt-md-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .flex-gt-md-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-md-row > .flex-gt-md-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-md-column > .flex-gt-md-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .flex-gt-md-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-md-row > .flex-gt-md-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-md-column > .flex-gt-md-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .flex-gt-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-md-row > .flex-gt-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-md-column > .flex-gt-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-md-row > .flex-gt-md-33, .layout-gt-md-row > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-md-row > .flex-gt-md-66, .layout-gt-md-row > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-md-row > .flex {
    min-width: 0;
  }
  .layout-gt-md-column > .flex-gt-md-33, .layout-gt-md-column > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-gt-md-column > .flex-gt-md-66, .layout-gt-md-column > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-md-column > .flex {
    min-height: 0;
  }
  .layout-gt-md, .layout-gt-md-column, .layout-gt-md-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }
  .layout-gt-md-column {
    flex-direction: column;
  }
  .layout-gt-md-row {
    flex-direction: row;
  }
}
@media (min-width: 1280px) and (max-width: 1919px) {
  .hide:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-lg):not(.show), .hide-gt-xs:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-lg):not(.show), .hide-gt-sm:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-lg):not(.show), .hide-gt-md:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-lg):not(.show) {
    display: none;
  }
  .hide-lg:not(.show-lg):not(.show-gt-md):not(.show-gt-sm):not(.show-gt-xs):not(.show) {
    display: none;
  }
  .flex-order-lg--20 {
    order: -20;
  }
  .flex-order-lg--19 {
    order: -19;
  }
  .flex-order-lg--18 {
    order: -18;
  }
  .flex-order-lg--17 {
    order: -17;
  }
  .flex-order-lg--16 {
    order: -16;
  }
  .flex-order-lg--15 {
    order: -15;
  }
  .flex-order-lg--14 {
    order: -14;
  }
  .flex-order-lg--13 {
    order: -13;
  }
  .flex-order-lg--12 {
    order: -12;
  }
  .flex-order-lg--11 {
    order: -11;
  }
  .flex-order-lg--10 {
    order: -10;
  }
  .flex-order-lg--9 {
    order: -9;
  }
  .flex-order-lg--8 {
    order: -8;
  }
  .flex-order-lg--7 {
    order: -7;
  }
  .flex-order-lg--6 {
    order: -6;
  }
  .flex-order-lg--5 {
    order: -5;
  }
  .flex-order-lg--4 {
    order: -4;
  }
  .flex-order-lg--3 {
    order: -3;
  }
  .flex-order-lg--2 {
    order: -2;
  }
  .flex-order-lg--1 {
    order: -1;
  }
  .flex-order-lg-0 {
    order: 0;
  }
  .flex-order-lg-1 {
    order: 1;
  }
  .flex-order-lg-2 {
    order: 2;
  }
  .flex-order-lg-3 {
    order: 3;
  }
  .flex-order-lg-4 {
    order: 4;
  }
  .flex-order-lg-5 {
    order: 5;
  }
  .flex-order-lg-6 {
    order: 6;
  }
  .flex-order-lg-7 {
    order: 7;
  }
  .flex-order-lg-8 {
    order: 8;
  }
  .flex-order-lg-9 {
    order: 9;
  }
  .flex-order-lg-10 {
    order: 10;
  }
  .flex-order-lg-11 {
    order: 11;
  }
  .flex-order-lg-12 {
    order: 12;
  }
  .flex-order-lg-13 {
    order: 13;
  }
  .flex-order-lg-14 {
    order: 14;
  }
  .flex-order-lg-15 {
    order: 15;
  }
  .flex-order-lg-16 {
    order: 16;
  }
  .flex-order-lg-17 {
    order: 17;
  }
  .flex-order-lg-18 {
    order: 18;
  }
  .flex-order-lg-19 {
    order: 19;
  }
  .flex-order-lg-20 {
    order: 20;
  }
  .offset-lg-0, .flex-offset-lg-0 {
    margin-left: 0;
  }
  [dir=rtl] .offset-lg-0, [dir=rtl] .flex-offset-lg-0 {
    margin-left: auto;
    margin-right: 0;
  }
  .offset-lg-5, .flex-offset-lg-5 {
    margin-left: 5%;
  }
  [dir=rtl] .offset-lg-5, [dir=rtl] .flex-offset-lg-5 {
    margin-left: auto;
    margin-right: 5%;
  }
  .offset-lg-10, .flex-offset-lg-10 {
    margin-left: 10%;
  }
  [dir=rtl] .offset-lg-10, [dir=rtl] .flex-offset-lg-10 {
    margin-left: auto;
    margin-right: 10%;
  }
  .offset-lg-15, .flex-offset-lg-15 {
    margin-left: 15%;
  }
  [dir=rtl] .offset-lg-15, [dir=rtl] .flex-offset-lg-15 {
    margin-left: auto;
    margin-right: 15%;
  }
  .offset-lg-20, .flex-offset-lg-20 {
    margin-left: 20%;
  }
  [dir=rtl] .offset-lg-20, [dir=rtl] .flex-offset-lg-20 {
    margin-left: auto;
    margin-right: 20%;
  }
  .offset-lg-25, .flex-offset-lg-25 {
    margin-left: 25%;
  }
  [dir=rtl] .offset-lg-25, [dir=rtl] .flex-offset-lg-25 {
    margin-left: auto;
    margin-right: 25%;
  }
  .offset-lg-30, .flex-offset-lg-30 {
    margin-left: 30%;
  }
  [dir=rtl] .offset-lg-30, [dir=rtl] .flex-offset-lg-30 {
    margin-left: auto;
    margin-right: 30%;
  }
  .offset-lg-35, .flex-offset-lg-35 {
    margin-left: 35%;
  }
  [dir=rtl] .offset-lg-35, [dir=rtl] .flex-offset-lg-35 {
    margin-left: auto;
    margin-right: 35%;
  }
  .offset-lg-40, .flex-offset-lg-40 {
    margin-left: 40%;
  }
  [dir=rtl] .offset-lg-40, [dir=rtl] .flex-offset-lg-40 {
    margin-left: auto;
    margin-right: 40%;
  }
  .offset-lg-45, .flex-offset-lg-45 {
    margin-left: 45%;
  }
  [dir=rtl] .offset-lg-45, [dir=rtl] .flex-offset-lg-45 {
    margin-left: auto;
    margin-right: 45%;
  }
  .offset-lg-50, .flex-offset-lg-50 {
    margin-left: 50%;
  }
  [dir=rtl] .offset-lg-50, [dir=rtl] .flex-offset-lg-50 {
    margin-left: auto;
    margin-right: 50%;
  }
  .offset-lg-55, .flex-offset-lg-55 {
    margin-left: 55%;
  }
  [dir=rtl] .offset-lg-55, [dir=rtl] .flex-offset-lg-55 {
    margin-left: auto;
    margin-right: 55%;
  }
  .offset-lg-60, .flex-offset-lg-60 {
    margin-left: 60%;
  }
  [dir=rtl] .offset-lg-60, [dir=rtl] .flex-offset-lg-60 {
    margin-left: auto;
    margin-right: 60%;
  }
  .offset-lg-65, .flex-offset-lg-65 {
    margin-left: 65%;
  }
  [dir=rtl] .offset-lg-65, [dir=rtl] .flex-offset-lg-65 {
    margin-left: auto;
    margin-right: 65%;
  }
  .offset-lg-70, .flex-offset-lg-70 {
    margin-left: 70%;
  }
  [dir=rtl] .offset-lg-70, [dir=rtl] .flex-offset-lg-70 {
    margin-left: auto;
    margin-right: 70%;
  }
  .offset-lg-75, .flex-offset-lg-75 {
    margin-left: 75%;
  }
  [dir=rtl] .offset-lg-75, [dir=rtl] .flex-offset-lg-75 {
    margin-left: auto;
    margin-right: 75%;
  }
  .offset-lg-80, .flex-offset-lg-80 {
    margin-left: 80%;
  }
  [dir=rtl] .offset-lg-80, [dir=rtl] .flex-offset-lg-80 {
    margin-left: auto;
    margin-right: 80%;
  }
  .offset-lg-85, .flex-offset-lg-85 {
    margin-left: 85%;
  }
  [dir=rtl] .offset-lg-85, [dir=rtl] .flex-offset-lg-85 {
    margin-left: auto;
    margin-right: 85%;
  }
  .offset-lg-90, .flex-offset-lg-90 {
    margin-left: 90%;
  }
  [dir=rtl] .offset-lg-90, [dir=rtl] .flex-offset-lg-90 {
    margin-left: auto;
    margin-right: 90%;
  }
  .offset-lg-95, .flex-offset-lg-95 {
    margin-left: 95%;
  }
  [dir=rtl] .offset-lg-95, [dir=rtl] .flex-offset-lg-95 {
    margin-left: auto;
    margin-right: 95%;
  }
  .offset-lg-33, .flex-offset-lg-33 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-66, .flex-offset-lg-66 {
    margin-left: 66.6666666667%;
  }
  [dir=rtl] .offset-lg-66, [dir=rtl] .flex-offset-lg-66 {
    margin-left: auto;
    margin-right: 66.6666666667%;
  }
  .layout-align-lg,
  .layout-align-lg-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
  }
  .layout-align-lg-start,
  .layout-align-lg-start-start,
  .layout-align-lg-start-center,
  .layout-align-lg-start-end,
  .layout-align-lg-start-stretch {
    justify-content: flex-start;
  }
  .layout-align-lg-center,
  .layout-align-lg-center-start,
  .layout-align-lg-center-center,
  .layout-align-lg-center-end,
  .layout-align-lg-center-stretch {
    justify-content: center;
  }
  .layout-align-lg-end,
  .layout-align-lg-end-start,
  .layout-align-lg-end-center,
  .layout-align-lg-end-end,
  .layout-align-lg-end-stretch {
    justify-content: flex-end;
  }
  .layout-align-lg-space-around,
  .layout-align-lg-space-around-center,
  .layout-align-lg-space-around-start,
  .layout-align-lg-space-around-end,
  .layout-align-lg-space-around-stretch {
    justify-content: space-around;
  }
  .layout-align-lg-space-between,
  .layout-align-lg-space-between-center,
  .layout-align-lg-space-between-start,
  .layout-align-lg-space-between-end,
  .layout-align-lg-space-between-stretch {
    justify-content: space-between;
  }
  .layout-align-lg-start-start,
  .layout-align-lg-center-start,
  .layout-align-lg-end-start,
  .layout-align-lg-space-between-start,
  .layout-align-lg-space-around-start {
    align-items: flex-start;
    align-content: flex-start;
  }
  .layout-align-lg-start-center,
  .layout-align-lg-center-center,
  .layout-align-lg-end-center,
  .layout-align-lg-space-between-center,
  .layout-align-lg-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%;
  }
  .layout-align-lg-start-center > *,
  .layout-align-lg-center-center > *,
  .layout-align-lg-end-center > *,
  .layout-align-lg-space-between-center > *,
  .layout-align-lg-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box;
  }
  .layout-align-lg-start-end,
  .layout-align-lg-center-end,
  .layout-align-lg-end-end,
  .layout-align-lg-space-between-end,
  .layout-align-lg-space-around-end {
    align-items: flex-end;
    align-content: flex-end;
  }
  .layout-align-lg-start-stretch,
  .layout-align-lg-center-stretch,
  .layout-align-lg-end-stretch,
  .layout-align-lg-space-between-stretch,
  .layout-align-lg-space-around-stretch {
    align-items: stretch;
    align-content: stretch;
  }
  .flex-lg {
    flex: 1;
    box-sizing: border-box;
  }
  .flex-lg-grow {
    flex: 1 1 100%;
    box-sizing: border-box;
  }
  .flex-lg-initial {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .flex-lg-auto {
    flex: 1 1 auto;
    box-sizing: border-box;
  }
  .flex-lg-none {
    flex: 0 0 auto;
    box-sizing: border-box;
  }
  .flex-lg-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box;
  }
  .flex-lg-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .flex-lg-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .layout-column > .flex-lg-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-lg-row > .flex-lg-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .layout-lg-column > .flex-lg-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0;
  }
  .flex-lg-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-lg-row > .flex-lg-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-lg-column > .flex-lg-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .flex-lg-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-lg-row > .flex-lg-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-lg-column > .flex-lg-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .flex-lg-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-lg-row > .flex-lg-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-lg-column > .flex-lg-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .flex-lg-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-lg-row > .flex-lg-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-lg-column > .flex-lg-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .flex-lg-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-lg-row > .flex-lg-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-lg-column > .flex-lg-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .flex-lg-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-lg-row > .flex-lg-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-lg-column > .flex-lg-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .flex-lg-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-lg-row > .flex-lg-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-lg-column > .flex-lg-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .flex-lg-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-lg-row > .flex-lg-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-lg-column > .flex-lg-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .flex-lg-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-lg-row > .flex-lg-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-lg-column > .flex-lg-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .flex-lg-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-lg-row > .flex-lg-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-lg-column > .flex-lg-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .flex-lg-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-lg-row > .flex-lg-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-lg-column > .flex-lg-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .flex-lg-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-lg-row > .flex-lg-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-lg-column > .flex-lg-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .flex-lg-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-lg-row > .flex-lg-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-lg-column > .flex-lg-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .flex-lg-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-lg-row > .flex-lg-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-lg-column > .flex-lg-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .flex-lg-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-lg-row > .flex-lg-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-lg-column > .flex-lg-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .flex-lg-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-lg-row > .flex-lg-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-lg-column > .flex-lg-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .flex-lg-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-lg-row > .flex-lg-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-lg-column > .flex-lg-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .flex-lg-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-lg-row > .flex-lg-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-lg-column > .flex-lg-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .flex-lg-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-lg-row > .flex-lg-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-lg-column > .flex-lg-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .flex-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-lg-row > .flex-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-lg-column > .flex-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-lg-row > .flex-lg-33, .layout-lg-row > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-lg-row > .flex-lg-66, .layout-lg-row > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-lg-row > .flex {
    min-width: 0;
  }
  .layout-lg-column > .flex-lg-33, .layout-lg-column > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-lg-column > .flex-lg-66, .layout-lg-column > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-lg-column > .flex {
    min-height: 0;
  }
  .layout-lg, .layout-lg-column, .layout-lg-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }
  .layout-lg-column {
    flex-direction: column;
  }
  .layout-lg-row {
    flex-direction: row;
  }
}
@media (min-width: 1920px) {
  .flex-order-gt-lg--20 {
    order: -20;
  }
  .flex-order-gt-lg--19 {
    order: -19;
  }
  .flex-order-gt-lg--18 {
    order: -18;
  }
  .flex-order-gt-lg--17 {
    order: -17;
  }
  .flex-order-gt-lg--16 {
    order: -16;
  }
  .flex-order-gt-lg--15 {
    order: -15;
  }
  .flex-order-gt-lg--14 {
    order: -14;
  }
  .flex-order-gt-lg--13 {
    order: -13;
  }
  .flex-order-gt-lg--12 {
    order: -12;
  }
  .flex-order-gt-lg--11 {
    order: -11;
  }
  .flex-order-gt-lg--10 {
    order: -10;
  }
  .flex-order-gt-lg--9 {
    order: -9;
  }
  .flex-order-gt-lg--8 {
    order: -8;
  }
  .flex-order-gt-lg--7 {
    order: -7;
  }
  .flex-order-gt-lg--6 {
    order: -6;
  }
  .flex-order-gt-lg--5 {
    order: -5;
  }
  .flex-order-gt-lg--4 {
    order: -4;
  }
  .flex-order-gt-lg--3 {
    order: -3;
  }
  .flex-order-gt-lg--2 {
    order: -2;
  }
  .flex-order-gt-lg--1 {
    order: -1;
  }
  .flex-order-gt-lg-0 {
    order: 0;
  }
  .flex-order-gt-lg-1 {
    order: 1;
  }
  .flex-order-gt-lg-2 {
    order: 2;
  }
  .flex-order-gt-lg-3 {
    order: 3;
  }
  .flex-order-gt-lg-4 {
    order: 4;
  }
  .flex-order-gt-lg-5 {
    order: 5;
  }
  .flex-order-gt-lg-6 {
    order: 6;
  }
  .flex-order-gt-lg-7 {
    order: 7;
  }
  .flex-order-gt-lg-8 {
    order: 8;
  }
  .flex-order-gt-lg-9 {
    order: 9;
  }
  .flex-order-gt-lg-10 {
    order: 10;
  }
  .flex-order-gt-lg-11 {
    order: 11;
  }
  .flex-order-gt-lg-12 {
    order: 12;
  }
  .flex-order-gt-lg-13 {
    order: 13;
  }
  .flex-order-gt-lg-14 {
    order: 14;
  }
  .flex-order-gt-lg-15 {
    order: 15;
  }
  .flex-order-gt-lg-16 {
    order: 16;
  }
  .flex-order-gt-lg-17 {
    order: 17;
  }
  .flex-order-gt-lg-18 {
    order: 18;
  }
  .flex-order-gt-lg-19 {
    order: 19;
  }
  .flex-order-gt-lg-20 {
    order: 20;
  }
  .offset-gt-lg-0, .flex-offset-gt-lg-0 {
    margin-left: 0;
  }
  [dir=rtl] .offset-gt-lg-0, [dir=rtl] .flex-offset-gt-lg-0 {
    margin-left: auto;
    margin-right: 0;
  }
  .offset-gt-lg-5, .flex-offset-gt-lg-5 {
    margin-left: 5%;
  }
  [dir=rtl] .offset-gt-lg-5, [dir=rtl] .flex-offset-gt-lg-5 {
    margin-left: auto;
    margin-right: 5%;
  }
  .offset-gt-lg-10, .flex-offset-gt-lg-10 {
    margin-left: 10%;
  }
  [dir=rtl] .offset-gt-lg-10, [dir=rtl] .flex-offset-gt-lg-10 {
    margin-left: auto;
    margin-right: 10%;
  }
  .offset-gt-lg-15, .flex-offset-gt-lg-15 {
    margin-left: 15%;
  }
  [dir=rtl] .offset-gt-lg-15, [dir=rtl] .flex-offset-gt-lg-15 {
    margin-left: auto;
    margin-right: 15%;
  }
  .offset-gt-lg-20, .flex-offset-gt-lg-20 {
    margin-left: 20%;
  }
  [dir=rtl] .offset-gt-lg-20, [dir=rtl] .flex-offset-gt-lg-20 {
    margin-left: auto;
    margin-right: 20%;
  }
  .offset-gt-lg-25, .flex-offset-gt-lg-25 {
    margin-left: 25%;
  }
  [dir=rtl] .offset-gt-lg-25, [dir=rtl] .flex-offset-gt-lg-25 {
    margin-left: auto;
    margin-right: 25%;
  }
  .offset-gt-lg-30, .flex-offset-gt-lg-30 {
    margin-left: 30%;
  }
  [dir=rtl] .offset-gt-lg-30, [dir=rtl] .flex-offset-gt-lg-30 {
    margin-left: auto;
    margin-right: 30%;
  }
  .offset-gt-lg-35, .flex-offset-gt-lg-35 {
    margin-left: 35%;
  }
  [dir=rtl] .offset-gt-lg-35, [dir=rtl] .flex-offset-gt-lg-35 {
    margin-left: auto;
    margin-right: 35%;
  }
  .offset-gt-lg-40, .flex-offset-gt-lg-40 {
    margin-left: 40%;
  }
  [dir=rtl] .offset-gt-lg-40, [dir=rtl] .flex-offset-gt-lg-40 {
    margin-left: auto;
    margin-right: 40%;
  }
  .offset-gt-lg-45, .flex-offset-gt-lg-45 {
    margin-left: 45%;
  }
  [dir=rtl] .offset-gt-lg-45, [dir=rtl] .flex-offset-gt-lg-45 {
    margin-left: auto;
    margin-right: 45%;
  }
  .offset-gt-lg-50, .flex-offset-gt-lg-50 {
    margin-left: 50%;
  }
  [dir=rtl] .offset-gt-lg-50, [dir=rtl] .flex-offset-gt-lg-50 {
    margin-left: auto;
    margin-right: 50%;
  }
  .offset-gt-lg-55, .flex-offset-gt-lg-55 {
    margin-left: 55%;
  }
  [dir=rtl] .offset-gt-lg-55, [dir=rtl] .flex-offset-gt-lg-55 {
    margin-left: auto;
    margin-right: 55%;
  }
  .offset-gt-lg-60, .flex-offset-gt-lg-60 {
    margin-left: 60%;
  }
  [dir=rtl] .offset-gt-lg-60, [dir=rtl] .flex-offset-gt-lg-60 {
    margin-left: auto;
    margin-right: 60%;
  }
  .offset-gt-lg-65, .flex-offset-gt-lg-65 {
    margin-left: 65%;
  }
  [dir=rtl] .offset-gt-lg-65, [dir=rtl] .flex-offset-gt-lg-65 {
    margin-left: auto;
    margin-right: 65%;
  }
  .offset-gt-lg-70, .flex-offset-gt-lg-70 {
    margin-left: 70%;
  }
  [dir=rtl] .offset-gt-lg-70, [dir=rtl] .flex-offset-gt-lg-70 {
    margin-left: auto;
    margin-right: 70%;
  }
  .offset-gt-lg-75, .flex-offset-gt-lg-75 {
    margin-left: 75%;
  }
  [dir=rtl] .offset-gt-lg-75, [dir=rtl] .flex-offset-gt-lg-75 {
    margin-left: auto;
    margin-right: 75%;
  }
  .offset-gt-lg-80, .flex-offset-gt-lg-80 {
    margin-left: 80%;
  }
  [dir=rtl] .offset-gt-lg-80, [dir=rtl] .flex-offset-gt-lg-80 {
    margin-left: auto;
    margin-right: 80%;
  }
  .offset-gt-lg-85, .flex-offset-gt-lg-85 {
    margin-left: 85%;
  }
  [dir=rtl] .offset-gt-lg-85, [dir=rtl] .flex-offset-gt-lg-85 {
    margin-left: auto;
    margin-right: 85%;
  }
  .offset-gt-lg-90, .flex-offset-gt-lg-90 {
    margin-left: 90%;
  }
  [dir=rtl] .offset-gt-lg-90, [dir=rtl] .flex-offset-gt-lg-90 {
    margin-left: auto;
    margin-right: 90%;
  }
  .offset-gt-lg-95, .flex-offset-gt-lg-95 {
    margin-left: 95%;
  }
  [dir=rtl] .offset-gt-lg-95, [dir=rtl] .flex-offset-gt-lg-95 {
    margin-left: auto;
    margin-right: 95%;
  }
  .offset-gt-lg-33, .flex-offset-gt-lg-33 {
    margin-left: 33.3333333333%;
  }
  .offset-gt-lg-66, .flex-offset-gt-lg-66 {
    margin-left: 66.6666666667%;
  }
  [dir=rtl] .offset-gt-lg-66, [dir=rtl] .flex-offset-gt-lg-66 {
    margin-left: auto;
    margin-right: 66.6666666667%;
  }
  .layout-align-gt-lg,
  .layout-align-gt-lg-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
  }
  .layout-align-gt-lg-start,
  .layout-align-gt-lg-start-start,
  .layout-align-gt-lg-start-center,
  .layout-align-gt-lg-start-end,
  .layout-align-gt-lg-start-stretch {
    justify-content: flex-start;
  }
  .layout-align-gt-lg-center,
  .layout-align-gt-lg-center-start,
  .layout-align-gt-lg-center-center,
  .layout-align-gt-lg-center-end,
  .layout-align-gt-lg-center-stretch {
    justify-content: center;
  }
  .layout-align-gt-lg-end,
  .layout-align-gt-lg-end-start,
  .layout-align-gt-lg-end-center,
  .layout-align-gt-lg-end-end,
  .layout-align-gt-lg-end-stretch {
    justify-content: flex-end;
  }
  .layout-align-gt-lg-space-around,
  .layout-align-gt-lg-space-around-center,
  .layout-align-gt-lg-space-around-start,
  .layout-align-gt-lg-space-around-end,
  .layout-align-gt-lg-space-around-stretch {
    justify-content: space-around;
  }
  .layout-align-gt-lg-space-between,
  .layout-align-gt-lg-space-between-center,
  .layout-align-gt-lg-space-between-start,
  .layout-align-gt-lg-space-between-end,
  .layout-align-gt-lg-space-between-stretch {
    justify-content: space-between;
  }
  .layout-align-gt-lg-start-start,
  .layout-align-gt-lg-center-start,
  .layout-align-gt-lg-end-start,
  .layout-align-gt-lg-space-between-start,
  .layout-align-gt-lg-space-around-start {
    align-items: flex-start;
    align-content: flex-start;
  }
  .layout-align-gt-lg-start-center,
  .layout-align-gt-lg-center-center,
  .layout-align-gt-lg-end-center,
  .layout-align-gt-lg-space-between-center,
  .layout-align-gt-lg-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%;
  }
  .layout-align-gt-lg-start-center > *,
  .layout-align-gt-lg-center-center > *,
  .layout-align-gt-lg-end-center > *,
  .layout-align-gt-lg-space-between-center > *,
  .layout-align-gt-lg-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box;
  }
  .layout-align-gt-lg-start-end,
  .layout-align-gt-lg-center-end,
  .layout-align-gt-lg-end-end,
  .layout-align-gt-lg-space-between-end,
  .layout-align-gt-lg-space-around-end {
    align-items: flex-end;
    align-content: flex-end;
  }
  .layout-align-gt-lg-start-stretch,
  .layout-align-gt-lg-center-stretch,
  .layout-align-gt-lg-end-stretch,
  .layout-align-gt-lg-space-between-stretch,
  .layout-align-gt-lg-space-around-stretch {
    align-items: stretch;
    align-content: stretch;
  }
  .flex-gt-lg {
    flex: 1;
    box-sizing: border-box;
  }
  .flex-gt-lg-grow {
    flex: 1 1 100%;
    box-sizing: border-box;
  }
  .flex-gt-lg-initial {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .flex-gt-lg-auto {
    flex: 1 1 auto;
    box-sizing: border-box;
  }
  .flex-gt-lg-none {
    flex: 0 0 auto;
    box-sizing: border-box;
  }
  .flex-gt-lg-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box;
  }
  .flex-gt-lg-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .flex-gt-lg-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .layout-column > .flex-gt-lg-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-lg-row > .flex-gt-lg-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .layout-gt-lg-column > .flex-gt-lg-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0;
  }
  .flex-gt-lg-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-lg-row > .flex-gt-lg-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-lg-column > .flex-gt-lg-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .flex-gt-lg-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-lg-row > .flex-gt-lg-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-lg-column > .flex-gt-lg-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .flex-gt-lg-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-lg-row > .flex-gt-lg-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-lg-column > .flex-gt-lg-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .flex-gt-lg-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-lg-row > .flex-gt-lg-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-lg-column > .flex-gt-lg-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .flex-gt-lg-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-lg-row > .flex-gt-lg-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-lg-column > .flex-gt-lg-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .flex-gt-lg-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-lg-row > .flex-gt-lg-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-lg-column > .flex-gt-lg-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .flex-gt-lg-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-lg-row > .flex-gt-lg-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-lg-column > .flex-gt-lg-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .flex-gt-lg-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-lg-row > .flex-gt-lg-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-lg-column > .flex-gt-lg-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .flex-gt-lg-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-lg-row > .flex-gt-lg-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-lg-column > .flex-gt-lg-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .flex-gt-lg-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-lg-row > .flex-gt-lg-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-lg-column > .flex-gt-lg-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .flex-gt-lg-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-lg-row > .flex-gt-lg-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-lg-column > .flex-gt-lg-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .flex-gt-lg-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-lg-row > .flex-gt-lg-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-lg-column > .flex-gt-lg-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .flex-gt-lg-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-lg-row > .flex-gt-lg-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-lg-column > .flex-gt-lg-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .flex-gt-lg-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-lg-row > .flex-gt-lg-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-lg-column > .flex-gt-lg-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .flex-gt-lg-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-lg-row > .flex-gt-lg-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-lg-column > .flex-gt-lg-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .flex-gt-lg-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-lg-row > .flex-gt-lg-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-lg-column > .flex-gt-lg-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .flex-gt-lg-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-lg-row > .flex-gt-lg-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-lg-column > .flex-gt-lg-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .flex-gt-lg-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-lg-row > .flex-gt-lg-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-lg-column > .flex-gt-lg-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .flex-gt-lg-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-lg-row > .flex-gt-lg-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-lg-column > .flex-gt-lg-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .flex-gt-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-lg-row > .flex-gt-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-lg-column > .flex-gt-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-lg-row > .flex-gt-lg-33, .layout-gt-lg-row > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-lg-row > .flex-gt-lg-66, .layout-gt-lg-row > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-gt-lg-row > .flex {
    min-width: 0;
  }
  .layout-gt-lg-column > .flex-gt-lg-33, .layout-gt-lg-column > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-gt-lg-column > .flex-gt-lg-66, .layout-gt-lg-column > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-gt-lg-column > .flex {
    min-height: 0;
  }
  .layout-gt-lg, .layout-gt-lg-column, .layout-gt-lg-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }
  .layout-gt-lg-column {
    flex-direction: column;
  }
  .layout-gt-lg-row {
    flex-direction: row;
  }
  .flex-order-xl--20 {
    order: -20;
  }
  .flex-order-xl--19 {
    order: -19;
  }
  .flex-order-xl--18 {
    order: -18;
  }
  .flex-order-xl--17 {
    order: -17;
  }
  .flex-order-xl--16 {
    order: -16;
  }
  .flex-order-xl--15 {
    order: -15;
  }
  .flex-order-xl--14 {
    order: -14;
  }
  .flex-order-xl--13 {
    order: -13;
  }
  .flex-order-xl--12 {
    order: -12;
  }
  .flex-order-xl--11 {
    order: -11;
  }
  .flex-order-xl--10 {
    order: -10;
  }
  .flex-order-xl--9 {
    order: -9;
  }
  .flex-order-xl--8 {
    order: -8;
  }
  .flex-order-xl--7 {
    order: -7;
  }
  .flex-order-xl--6 {
    order: -6;
  }
  .flex-order-xl--5 {
    order: -5;
  }
  .flex-order-xl--4 {
    order: -4;
  }
  .flex-order-xl--3 {
    order: -3;
  }
  .flex-order-xl--2 {
    order: -2;
  }
  .flex-order-xl--1 {
    order: -1;
  }
  .flex-order-xl-0 {
    order: 0;
  }
  .flex-order-xl-1 {
    order: 1;
  }
  .flex-order-xl-2 {
    order: 2;
  }
  .flex-order-xl-3 {
    order: 3;
  }
  .flex-order-xl-4 {
    order: 4;
  }
  .flex-order-xl-5 {
    order: 5;
  }
  .flex-order-xl-6 {
    order: 6;
  }
  .flex-order-xl-7 {
    order: 7;
  }
  .flex-order-xl-8 {
    order: 8;
  }
  .flex-order-xl-9 {
    order: 9;
  }
  .flex-order-xl-10 {
    order: 10;
  }
  .flex-order-xl-11 {
    order: 11;
  }
  .flex-order-xl-12 {
    order: 12;
  }
  .flex-order-xl-13 {
    order: 13;
  }
  .flex-order-xl-14 {
    order: 14;
  }
  .flex-order-xl-15 {
    order: 15;
  }
  .flex-order-xl-16 {
    order: 16;
  }
  .flex-order-xl-17 {
    order: 17;
  }
  .flex-order-xl-18 {
    order: 18;
  }
  .flex-order-xl-19 {
    order: 19;
  }
  .flex-order-xl-20 {
    order: 20;
  }
  .offset-xl-0, .flex-offset-xl-0 {
    margin-left: 0;
  }
  [dir=rtl] .offset-xl-0, [dir=rtl] .flex-offset-xl-0 {
    margin-left: auto;
    margin-right: 0;
  }
  .offset-xl-5, .flex-offset-xl-5 {
    margin-left: 5%;
  }
  [dir=rtl] .offset-xl-5, [dir=rtl] .flex-offset-xl-5 {
    margin-left: auto;
    margin-right: 5%;
  }
  .offset-xl-10, .flex-offset-xl-10 {
    margin-left: 10%;
  }
  [dir=rtl] .offset-xl-10, [dir=rtl] .flex-offset-xl-10 {
    margin-left: auto;
    margin-right: 10%;
  }
  .offset-xl-15, .flex-offset-xl-15 {
    margin-left: 15%;
  }
  [dir=rtl] .offset-xl-15, [dir=rtl] .flex-offset-xl-15 {
    margin-left: auto;
    margin-right: 15%;
  }
  .offset-xl-20, .flex-offset-xl-20 {
    margin-left: 20%;
  }
  [dir=rtl] .offset-xl-20, [dir=rtl] .flex-offset-xl-20 {
    margin-left: auto;
    margin-right: 20%;
  }
  .offset-xl-25, .flex-offset-xl-25 {
    margin-left: 25%;
  }
  [dir=rtl] .offset-xl-25, [dir=rtl] .flex-offset-xl-25 {
    margin-left: auto;
    margin-right: 25%;
  }
  .offset-xl-30, .flex-offset-xl-30 {
    margin-left: 30%;
  }
  [dir=rtl] .offset-xl-30, [dir=rtl] .flex-offset-xl-30 {
    margin-left: auto;
    margin-right: 30%;
  }
  .offset-xl-35, .flex-offset-xl-35 {
    margin-left: 35%;
  }
  [dir=rtl] .offset-xl-35, [dir=rtl] .flex-offset-xl-35 {
    margin-left: auto;
    margin-right: 35%;
  }
  .offset-xl-40, .flex-offset-xl-40 {
    margin-left: 40%;
  }
  [dir=rtl] .offset-xl-40, [dir=rtl] .flex-offset-xl-40 {
    margin-left: auto;
    margin-right: 40%;
  }
  .offset-xl-45, .flex-offset-xl-45 {
    margin-left: 45%;
  }
  [dir=rtl] .offset-xl-45, [dir=rtl] .flex-offset-xl-45 {
    margin-left: auto;
    margin-right: 45%;
  }
  .offset-xl-50, .flex-offset-xl-50 {
    margin-left: 50%;
  }
  [dir=rtl] .offset-xl-50, [dir=rtl] .flex-offset-xl-50 {
    margin-left: auto;
    margin-right: 50%;
  }
  .offset-xl-55, .flex-offset-xl-55 {
    margin-left: 55%;
  }
  [dir=rtl] .offset-xl-55, [dir=rtl] .flex-offset-xl-55 {
    margin-left: auto;
    margin-right: 55%;
  }
  .offset-xl-60, .flex-offset-xl-60 {
    margin-left: 60%;
  }
  [dir=rtl] .offset-xl-60, [dir=rtl] .flex-offset-xl-60 {
    margin-left: auto;
    margin-right: 60%;
  }
  .offset-xl-65, .flex-offset-xl-65 {
    margin-left: 65%;
  }
  [dir=rtl] .offset-xl-65, [dir=rtl] .flex-offset-xl-65 {
    margin-left: auto;
    margin-right: 65%;
  }
  .offset-xl-70, .flex-offset-xl-70 {
    margin-left: 70%;
  }
  [dir=rtl] .offset-xl-70, [dir=rtl] .flex-offset-xl-70 {
    margin-left: auto;
    margin-right: 70%;
  }
  .offset-xl-75, .flex-offset-xl-75 {
    margin-left: 75%;
  }
  [dir=rtl] .offset-xl-75, [dir=rtl] .flex-offset-xl-75 {
    margin-left: auto;
    margin-right: 75%;
  }
  .offset-xl-80, .flex-offset-xl-80 {
    margin-left: 80%;
  }
  [dir=rtl] .offset-xl-80, [dir=rtl] .flex-offset-xl-80 {
    margin-left: auto;
    margin-right: 80%;
  }
  .offset-xl-85, .flex-offset-xl-85 {
    margin-left: 85%;
  }
  [dir=rtl] .offset-xl-85, [dir=rtl] .flex-offset-xl-85 {
    margin-left: auto;
    margin-right: 85%;
  }
  .offset-xl-90, .flex-offset-xl-90 {
    margin-left: 90%;
  }
  [dir=rtl] .offset-xl-90, [dir=rtl] .flex-offset-xl-90 {
    margin-left: auto;
    margin-right: 90%;
  }
  .offset-xl-95, .flex-offset-xl-95 {
    margin-left: 95%;
  }
  [dir=rtl] .offset-xl-95, [dir=rtl] .flex-offset-xl-95 {
    margin-left: auto;
    margin-right: 95%;
  }
  .offset-xl-33, .flex-offset-xl-33 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-66, .flex-offset-xl-66 {
    margin-left: 66.6666666667%;
  }
  [dir=rtl] .offset-xl-66, [dir=rtl] .flex-offset-xl-66 {
    margin-left: auto;
    margin-right: 66.6666666667%;
  }
  .layout-align-xl,
  .layout-align-xl-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
  }
  .layout-align-xl-start,
  .layout-align-xl-start-start,
  .layout-align-xl-start-center,
  .layout-align-xl-start-end,
  .layout-align-xl-start-stretch {
    justify-content: flex-start;
  }
  .layout-align-xl-center,
  .layout-align-xl-center-start,
  .layout-align-xl-center-center,
  .layout-align-xl-center-end,
  .layout-align-xl-center-stretch {
    justify-content: center;
  }
  .layout-align-xl-end,
  .layout-align-xl-end-start,
  .layout-align-xl-end-center,
  .layout-align-xl-end-end,
  .layout-align-xl-end-stretch {
    justify-content: flex-end;
  }
  .layout-align-xl-space-around,
  .layout-align-xl-space-around-center,
  .layout-align-xl-space-around-start,
  .layout-align-xl-space-around-end,
  .layout-align-xl-space-around-stretch {
    justify-content: space-around;
  }
  .layout-align-xl-space-between,
  .layout-align-xl-space-between-center,
  .layout-align-xl-space-between-start,
  .layout-align-xl-space-between-end,
  .layout-align-xl-space-between-stretch {
    justify-content: space-between;
  }
  .layout-align-xl-start-start,
  .layout-align-xl-center-start,
  .layout-align-xl-end-start,
  .layout-align-xl-space-between-start,
  .layout-align-xl-space-around-start {
    align-items: flex-start;
    align-content: flex-start;
  }
  .layout-align-xl-start-center,
  .layout-align-xl-center-center,
  .layout-align-xl-end-center,
  .layout-align-xl-space-between-center,
  .layout-align-xl-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%;
  }
  .layout-align-xl-start-center > *,
  .layout-align-xl-center-center > *,
  .layout-align-xl-end-center > *,
  .layout-align-xl-space-between-center > *,
  .layout-align-xl-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box;
  }
  .layout-align-xl-start-end,
  .layout-align-xl-center-end,
  .layout-align-xl-end-end,
  .layout-align-xl-space-between-end,
  .layout-align-xl-space-around-end {
    align-items: flex-end;
    align-content: flex-end;
  }
  .layout-align-xl-start-stretch,
  .layout-align-xl-center-stretch,
  .layout-align-xl-end-stretch,
  .layout-align-xl-space-between-stretch,
  .layout-align-xl-space-around-stretch {
    align-items: stretch;
    align-content: stretch;
  }
  .flex-xl {
    flex: 1;
    box-sizing: border-box;
  }
  .flex-xl-grow {
    flex: 1 1 100%;
    box-sizing: border-box;
  }
  .flex-xl-initial {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .flex-xl-auto {
    flex: 1 1 auto;
    box-sizing: border-box;
  }
  .flex-xl-none {
    flex: 0 0 auto;
    box-sizing: border-box;
  }
  .flex-xl-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box;
  }
  .flex-xl-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .flex-xl-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .layout-column > .flex-xl-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xl-row > .flex-xl-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .layout-xl-column > .flex-xl-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0;
  }
  .flex-xl-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xl-row > .flex-xl-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xl-column > .flex-xl-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .flex-xl-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xl-row > .flex-xl-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xl-column > .flex-xl-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .flex-xl-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xl-row > .flex-xl-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xl-column > .flex-xl-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .flex-xl-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xl-row > .flex-xl-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xl-column > .flex-xl-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .flex-xl-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xl-row > .flex-xl-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xl-column > .flex-xl-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .flex-xl-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xl-row > .flex-xl-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xl-column > .flex-xl-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .flex-xl-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xl-row > .flex-xl-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xl-column > .flex-xl-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .flex-xl-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xl-row > .flex-xl-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xl-column > .flex-xl-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .flex-xl-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xl-row > .flex-xl-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xl-column > .flex-xl-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .flex-xl-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xl-row > .flex-xl-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xl-column > .flex-xl-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .flex-xl-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xl-row > .flex-xl-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xl-column > .flex-xl-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .flex-xl-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xl-row > .flex-xl-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xl-column > .flex-xl-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .flex-xl-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xl-row > .flex-xl-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xl-column > .flex-xl-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .flex-xl-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xl-row > .flex-xl-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xl-column > .flex-xl-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .flex-xl-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xl-row > .flex-xl-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xl-column > .flex-xl-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .flex-xl-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xl-row > .flex-xl-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xl-column > .flex-xl-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .flex-xl-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xl-row > .flex-xl-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xl-column > .flex-xl-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .flex-xl-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xl-row > .flex-xl-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xl-column > .flex-xl-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .flex-xl-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xl-row > .flex-xl-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xl-column > .flex-xl-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .flex-xl-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xl-row > .flex-xl-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xl-column > .flex-xl-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xl-row > .flex-xl-33, .layout-xl-row > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xl-row > .flex-xl-66, .layout-xl-row > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-xl-row > .flex {
    min-width: 0;
  }
  .layout-xl-column > .flex-xl-33, .layout-xl-column > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-xl-column > .flex-xl-66, .layout-xl-column > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-xl-column > .flex {
    min-height: 0;
  }
  .layout-xl, .layout-xl-column, .layout-xl-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }
  .layout-xl-column {
    flex-direction: column;
  }
  .layout-xl-row {
    flex-direction: row;
  }
  .hide:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show), .hide-gt-xs:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show), .hide-gt-sm:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show), .hide-gt-md:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show), .hide-gt-lg:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show) {
    display: none;
  }
  .hide-xl:not(.show-xl):not(.show-gt-lg):not(.show-gt-md):not(.show-gt-sm):not(.show-gt-xs):not(.show) {
    display: none;
  }
}
@media print {
  .hide-print:not(.show-print):not(.show) {
    display: none !important;
  }
}
/*----------------------------------------------------------------*/
/*  Reset
/*----------------------------------------------------------------*/
* {
  text-rendering: optimizeLegibility;
  -o-text-rendering: optimizeLegibility;
  -ms-text-rendering: optimizeLegibility;
  -moz-text-rendering: optimizeLegibility;
  -webkit-text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
}
*:before, *:after {
  box-sizing: border-box;
}
*:focus {
  outline: none;
}

*:not(md-input-container) > input[type=text],
*:not(md-input-container) > input[type=tel],
*:not(md-input-container) > input[type=email],
*:not(md-input-container) > input[type=search],
*:not(md-input-container) > input[type=password],
*:not(md-input-container) > input[type=button],
*:not(md-input-container) > button,
*:not(md-input-container) > input[type=submit],
*:not(md-input-container) > input[type=image],
*:not(md-input-container) > textarea {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: none;
  padding: 0;
  margin: 0;
}
*:not(md-input-container) > input[type=button],
*:not(md-input-container) > button,
*:not(md-input-container) > input[type=submit] {
  background: none;
}

button {
  border-radius: 0;
  font-family: inherit;
  font-size: inherit;
  padding: 0;
  margin: 0;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

/*----------------------------------------------------------------*/
/*  Variables
/*----------------------------------------------------------------*/
/*----------------------------------------------------------------*/
/*  Mixins
/*----------------------------------------------------------------*/
/*----------------------------------------------------------------*/
/*  Angular Material Extend
/*  Adds and/or modifies Angular Material styles
/*----------------------------------------------------------------*/
/* --- FIXES --- */
[layout] > * {
  min-height: auto;
  min-height: initial;
}

md-card > img,
md-card > :not(md-card-content) img {
  height: inherit;
  height: initial;
}

.md-datepicker-calendar-pane * {
  box-sizing: initial;
}

/* --- MODIFICATIONS --- */
md-autocomplete-wrap > input[type=search] {
  background: #FFFFFF;
  padding: 0 15px !important;
}

md-backdrop.md-opaque {
  background-color: rgba(33, 33, 33, 0.48) !important;
}

md-card md-card-header md-card-avatar md-icon {
  width: 40px;
  height: 40px;
}

md-checkbox.no-label {
  margin: 0;
}

md-datepicker .md-datepicker-input-container {
  margin-left: 0;
}
md-datepicker .md-datepicker-input-container .md-datepicker-input {
  background: transparent !important;
}

md-dialog md-dialog-content {
  padding: 32px 24px 24px 24px;
}
md-dialog md-dialog-actions {
  min-height: 64px;
  padding-top: 8px;
  padding-bottom: 8px;
}
md-dialog md-dialog-actions .md-button {
  margin: 0 8px;
}

md-input-container.no-errors-spacer .md-errors-spacer {
  display: none;
}

md-list-item .md-list-item-text p {
  font-size: 13px !important;
}

.md-menu {
  padding: 0;
}

.md-open-menu-container md-menu-content md-menu-item.selected {
  opacity: 0.4;
}

md-menu-content md-menu-item .md-button {
  text-align: left;
}

md-progress-linear .md-container {
  top: 0 !important;
}

md-select.simplified {
  margin: 0 8px;
}
md-select.simplified .md-select-value {
  border: none !important;
  box-sizing: border-box;
  padding: 0;
}
md-select.simplified .md-select-value *:first-child {
  transform: none;
  height: auto;
}
md-select.simplified .md-select-value .md-select-icon {
  transform: none;
  font-size: 16px;
}
md-select.simplified .md-select-value .md-select-icon:after {
  top: 1px;
}

md-sidenav {
  -webkit-overflow-scrolling: touch;
}

md-toolbar.colored-toolbar {
  color: rgba(255, 255, 255, 0.87);
}
md-toolbar.colored-toolbar md-menu-bar md-menu.md-open > button {
  background: rgba(0, 0, 0, 0.12);
}
md-toolbar.colored-toolbar md-menu-bar md-menu .md-open-menu-container {
  background: #FFFFFF;
}
md-toolbar.colored-toolbar md-menu-bar md-menu .md-open-menu-container md-menu-item button {
  color: rgba(0, 0, 0, 0.87) !important;
}
md-toolbar.colored-toolbar md-menu-bar md-menu .md-open-menu-container md-menu-item md-icon {
  color: rgba(0, 0, 0, 0.54);
}
md-toolbar.colored-toolbar md-menu.md-open > button {
  background: rgba(0, 0, 0, 0.12);
}
md-toolbar.colored-toolbar input {
  color: rgba(255, 255, 255, 0.87) !important;
}

/*----------------------------------------------------------------*/
/*  Template Layouts
/*----------------------------------------------------------------*/
html,
body {
  overflow: hidden !important;
}

#main {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

body.boxed {
  background: #3F4450;
}
body.boxed #main {
  overflow: hidden !important;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.45);
}

#layout-content-only {
  position: relative;
  height: 100%;
  max-height: 100%;
}
#layout-content-only #content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#layout-content-with-toolbar {
  position: relative;
  height: 100%;
  max-height: 100%;
}
#layout-content-with-toolbar #content {
  position: absolute;
  top: 64px;
  right: 0;
  bottom: 0;
  left: 0;
}

#layout-vertical-navigation {
  height: 100%;
  max-height: 100%;
}
#layout-vertical-navigation #content-container {
  position: relative;
  overflow: hidden;
}
#layout-vertical-navigation #content-container .md-menu-toolbar {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !important;
}
#layout-vertical-navigation #content-container #content {
  position: absolute;
  top: 64px;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fbfbfd;
}

#layout-horizontal-navigation {
  height: 100%;
  max-height: 100%;
}
#layout-horizontal-navigation #content-container {
  position: relative;
  overflow: hidden;
}
#layout-horizontal-navigation #content-container #content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/*----------------------------------------------------------------*/
/*  Page Layouts
/*----------------------------------------------------------------*/
.page-layout {
  position: relative;
  overflow: hidden;
}
.page-layout .top-bg {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  height: 200px;
  background-image: url("/assets/images/backgrounds/header-bg.png");
  background-size: cover;
}
.page-layout md-backdrop {
  z-index: 50;
}
.page-layout > .header .breadcrumb {
  margin-bottom: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
}
.page-layout > .header .breadcrumb md-icon {
  margin: 0;
}
.page-layout > .header .breadcrumb .separator {
  margin: 0 8px;
}
.page-layout > .header .title {
  font-size: 34px;
}
.page-layout.carded {
  min-height: 100%;
  height: 100%;
}
.page-layout.carded.fullwidth.single-scroll {
  height: auto;
}
.page-layout.carded.fullwidth.single-scroll > .center .content-card .content {
  overflow: hidden;
}
.page-layout.carded.fullwidth > .center {
  position: relative;
  z-index: 2;
  margin-left: 32px;
  margin-right: 32px;
}
.page-layout.carded.fullwidth > .center .header {
  height: 136px;
  min-height: 136px;
  max-height: 136px;
  padding: 24px;
}
.page-layout.carded.fullwidth > .center .content-card {
  background: #FFFFFF;
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  overflow: hidden;
}
.page-layout.carded.fullwidth > .center .content-card .toolbar {
  padding: 8px 24px;
  height: 64px;
  min-height: 64px;
  max-height: 64px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.page-layout.carded.fullwidth > .center .content-card .content {
  padding: 24px;
  background: #FFFFFF;
}
.page-layout.carded.left-sidenav.single-scroll {
  height: auto;
}
.page-layout.carded.left-sidenav.single-scroll > .center .content-card .content {
  overflow: hidden;
}
.page-layout.carded.left-sidenav > .sidenav {
  width: 240px;
  min-width: 240px;
  max-width: 240px;
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  z-index: 51;
}
.page-layout.carded.left-sidenav > .sidenav .header {
  height: 200px;
  min-height: 200px;
  max-height: 200px;
  padding: 24px;
}
.page-layout.carded.left-sidenav > .sidenav .content {
  background: transparent;
  padding: 24px;
}
.page-layout.carded.left-sidenav > .sidenav.md-locked-open {
  width: 220px;
  min-width: 220px;
  max-width: 220px;
  z-index: 2;
  background: transparent;
  box-shadow: none;
}
.page-layout.carded.left-sidenav > .sidenav.md-locked-open + .center {
  margin-left: 0;
}
.page-layout.carded.left-sidenav > .sidenav:not(.md-locked-open) .header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.page-layout.carded.left-sidenav > .sidenav:not(.md-locked-open) .content {
  overflow: hidden;
}
.page-layout.carded.left-sidenav > .center {
  position: relative;
  z-index: 2;
  margin-left: 32px;
  margin-right: 32px;
}
.page-layout.carded.left-sidenav > .center .header {
  height: 136px;
  min-height: 136px;
  max-height: 136px;
  padding: 24px;
}
.page-layout.carded.left-sidenav > .center .content-card {
  background: #FFFFFF;
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  overflow: hidden;
}
.page-layout.carded.left-sidenav > .center .content-card .toolbar {
  padding: 8px 24px;
  height: 64px;
  min-height: 64px;
  max-height: 64px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.page-layout.carded.left-sidenav > .center .content-card .toolbar .sidenav-toggle {
  height: 64px;
  line-height: 64px;
  margin: 0 !important;
  padding: 0 8px 0 0 !important;
  border-radius: 0;
}
.page-layout.carded.left-sidenav > .center .content-card .content {
  padding: 24px;
  background: #FFFFFF;
}
.page-layout.carded.right-sidenav.single-scroll {
  height: auto;
}
.page-layout.carded.right-sidenav.single-scroll > .center .content-card .content {
  overflow: hidden;
}
.page-layout.carded.right-sidenav > .sidenav {
  width: 240px;
  min-width: 240px;
  max-width: 240px;
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  z-index: 51;
}
.page-layout.carded.right-sidenav > .sidenav .header {
  height: 200px;
  min-height: 200px;
  max-height: 200px;
  padding: 24px 24px 24px 0;
}
.page-layout.carded.right-sidenav > .sidenav .content {
  background: transparent;
  padding: 24px 24px 24px 0;
}
.page-layout.carded.right-sidenav > .sidenav.md-locked-open {
  width: 196px;
  min-width: 196px;
  max-width: 196px;
  z-index: 2;
  background: transparent;
  box-shadow: none;
}
.page-layout.carded.right-sidenav > .sidenav:not(.md-locked-open) .header {
  padding: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.page-layout.carded.right-sidenav > .sidenav:not(.md-locked-open) .content {
  overflow: hidden;
  padding: 24px;
}
.page-layout.carded.right-sidenav > .center {
  position: relative;
  z-index: 2;
  margin-left: 32px;
  margin-right: 32px;
}
.page-layout.carded.right-sidenav > .center .header {
  height: 136px;
  min-height: 136px;
  max-height: 136px;
  padding: 24px;
}
.page-layout.carded.right-sidenav > .center .content-card {
  background: #FFFFFF;
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  overflow: hidden;
}
.page-layout.carded.right-sidenav > .center .content-card .toolbar {
  padding: 8px 24px;
  height: 64px;
  min-height: 64px;
  max-height: 64px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.page-layout.carded.right-sidenav > .center .content-card .toolbar .sidenav-toggle {
  height: 64px;
  line-height: 64px;
  margin: 0 !important;
  padding: 0 0 0 8px !important;
  border-radius: 0;
}
.page-layout.carded.right-sidenav > .center .content-card .content {
  padding: 24px;
  background: #FFFFFF;
}
.page-layout.simple.fullwidth {
  min-height: 100%;
}
.page-layout.simple.fullwidth > .header {
  height: 200px;
  min-height: 200px;
  max-height: 200px;
  padding: 24px;
  background-image: url("/assets/images/backgrounds/header-bg.png");
  background-size: cover;
}
.page-layout.simple.fullwidth > .content {
  padding: 24px;
}
.page-layout.simple.left-sidenav {
  min-height: 100%;
  height: 100%;
}
.page-layout.simple.left-sidenav.single-scroll {
  height: auto;
}
.page-layout.simple.left-sidenav.single-scroll > .center {
  overflow: hidden;
}
.page-layout.simple.left-sidenav .sidenav {
  width: 240px;
  min-width: 240px;
  max-width: 240px;
  padding: 24px;
  z-index: 51;
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.page-layout.simple.left-sidenav .sidenav.md-locked-open {
  width: 220px;
  min-width: 220px;
  max-width: 220px;
  z-index: 2;
  box-shadow: none;
  background: transparent;
}
.page-layout.simple.left-sidenav > .center {
  position: relative;
  overflow: auto;
  z-index: 3;
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.page-layout.simple.left-sidenav > .center .header {
  height: 200px;
  min-height: 200px;
  max-height: 200px;
  padding: 24px;
  background-size: cover;
}
.page-layout.simple.left-sidenav > .center .content {
  padding: 24px;
  background: #FFFFFF;
}
.page-layout.simple.right-sidenav {
  min-height: 100%;
  height: 100%;
}
.page-layout.simple.right-sidenav.single-scroll {
  height: auto;
}
.page-layout.simple.right-sidenav.single-scroll > .center {
  overflow: hidden;
}
.page-layout.simple.right-sidenav > .center {
  position: relative;
  overflow: auto;
  z-index: 3;
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.page-layout.simple.right-sidenav > .center .header {
  height: 200px;
  min-height: 200px;
  max-height: 200px;
  padding: 24px;
  background-size: cover;
}
.page-layout.simple.right-sidenav > .center .content {
  padding: 24px;
  background: #FFFFFF;
}
.page-layout.simple.right-sidenav > .sidenav {
  width: 240px;
  min-width: 240px;
  max-width: 240px;
  padding: 24px;
  z-index: 51;
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.page-layout.simple.right-sidenav > .sidenav.md-locked-open {
  width: 220px;
  min-width: 220px;
  max-width: 220px;
  z-index: 2;
  box-shadow: none;
  background: transparent;
}
.page-layout.simple.tabbed {
  min-height: 100%;
}
.page-layout.simple.tabbed > .header {
  height: 200px;
  min-height: 200px;
  max-height: 200px;
  padding: 24px;
  background-image: url("/assets/images/backgrounds/header-bg.png");
  background-size: cover;
}
.page-layout.simple.tabbed > .content > md-tabs > md-tabs-wrapper {
  background: #FFFFFF;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  padding: 0 24px;
}
.page-layout.simple.tabbed > .content > md-tabs > md-tabs-wrapper md-tabs-canvas {
  height: 56px;
}
.page-layout.simple.tabbed > .content > md-tabs > md-tabs-wrapper md-tabs-canvas md-pagination-wrapper {
  height: 56px;
}
.page-layout.simple.tabbed > .content > md-tabs > md-tabs-wrapper md-tabs-canvas .md-tab {
  padding: 16px 24px;
  text-transform: none;
}
.page-layout.simple.tabbed > .content > md-tabs:not(.md-dynamic-height) md-tabs-content-wrapper {
  top: 56px;
}
.page-layout.simple.tabbed > .content > md-tabs > md-tabs-content-wrapper > md-tab-content {
  padding: 24px;
}
.page-layout.blank {
  min-height: 100%;
  padding: 24px;
}

.single-scroll .carded.fullwidth {
  height: auto;
}
.single-scroll .carded.fullwidth > .center .content-card .content {
  overflow: hidden;
}
.single-scroll .carded.left-sidenav {
  height: auto;
}
.single-scroll .carded.left-sidenav > .center .content-card .content {
  overflow: hidden;
}
.single-scroll .carded.right-sidenav {
  height: auto;
}
.single-scroll .carded.right-sidenav > .center .content-card .content {
  overflow: hidden;
}
.single-scroll .simple.left-sidenav {
  height: auto;
}
.single-scroll .simple.left-sidenav > .center {
  overflow: hidden;
}
.single-scroll .simple.right-sidenav {
  height: auto;
}
.single-scroll .simple.right-sidenav > .center {
  overflow: hidden;
}

@media screen and (max-width: 600px) {
  .page-layout .top-bg {
    height: 160px;
  }
  .page-layout.carded.right-sidenav > .center, .page-layout.carded.left-sidenav > .center, .page-layout.carded.fullwidth > .center {
    margin-left: 16px;
    margin-right: 16px;
  }
  .page-layout.carded.right-sidenav > .center .header, .page-layout.carded.left-sidenav > .center .header, .page-layout.carded.fullwidth > .center .header {
    height: 96px;
    min-height: 96px;
    max-height: 96px;
    padding: 16px;
  }
  .page-layout.carded.fullwidth {
    height: auto;
  }
  .page-layout.carded.fullwidth > .center .content-card .content {
    overflow: hidden;
  }
  .page-layout.carded.right-sidenav, .page-layout.carded.left-sidenav {
    height: auto;
  }
  .page-layout.carded.right-sidenav > .center .content-card .content, .page-layout.carded.left-sidenav > .center .content-card .content {
    overflow: hidden;
  }
  .page-layout.simple.fullwidth > .header {
    height: 160px;
    min-height: 160px;
    max-height: 160px;
    padding: 16px;
  }
  .page-layout.simple.left-sidenav, .page-layout.simple.right-sidenav {
    height: auto;
  }
  .page-layout.simple.left-sidenav > .center, .page-layout.simple.right-sidenav > .center {
    overflow: hidden;
  }
  .page-layout.simple.left-sidenav > .center .header, .page-layout.simple.right-sidenav > .center .header {
    height: 160px;
    min-height: 160px;
    max-height: 160px;
    padding: 16px;
  }
}
/*----------------------------------------------------------------*/
/*  Animations
/*----------------------------------------------------------------*/
.animate-slide-up {
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000px;
  transition-property: none;
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1;
}
.animate-slide-up.ng-enter {
  transform: translateY(100%);
  opacity: 0;
}
.animate-slide-up.ng-enter.ng-enter-active {
  transition-property: all;
  transform: translateY(0);
  opacity: 1;
}
.animate-slide-up.ng-leave {
  transform: translateY(0);
  opacity: 1;
}
.animate-slide-up.ng-leave.ng-leave-active {
  transition-property: all;
  transform: translateY(-100%);
  opacity: 0;
}
.animate-slide-up.ng-hide {
  transform: translateY(-100%);
  opacity: 0;
}
.animate-slide-up.ng-hide-add-active, .animate-slide-up.ng-hide-remove-active {
  transition-property: all;
}

.animate-slide-down {
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000px;
  transition-property: none;
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1;
}
.animate-slide-down.ng-enter {
  transform: translateY(-100%);
  opacity: 0;
}
.animate-slide-down.ng-enter.ng-enter-active {
  transition-property: all;
  transform: translateY(0);
  opacity: 1;
}
.animate-slide-down.ng-leave {
  transform: translateY(0);
  opacity: 1;
}
.animate-slide-down.ng-leave.ng-leave-active {
  transition-property: all;
  transform: translateY(100%);
  opacity: 0;
}
.animate-slide-down.ng-hide {
  transform: translateY(-100%);
  opacity: 0;
}
.animate-slide-down.ng-hide-add-active, .animate-slide-down.ng-hide-remove-active {
  transition-property: all;
}

.animate-slide-left {
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000px;
  transition-property: none;
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1;
}
.animate-slide-left.ng-enter {
  transform: translateX(100%);
  opacity: 0;
}
.animate-slide-left.ng-enter.ng-enter-active {
  transition-property: all;
  transform: translateX(0);
  opacity: 1;
}
.animate-slide-left.ng-leave {
  transform: translateX(0);
  opacity: 1;
}
.animate-slide-left.ng-leave.ng-leave-active {
  transition-property: all;
  transform: translateX(-100%);
  opacity: 0;
}
.animate-slide-left.ng-hide {
  transform: translateX(-100%);
  opacity: 0;
}
.animate-slide-left.ng-hide-add-active, .animate-slide-left.ng-hide-remove-active {
  transition-property: all;
}

.animate-slide-right {
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000px;
  transition-property: none;
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1;
}
.animate-slide-right.ng-enter {
  transform: translateX(-100%);
  opacity: 0;
}
.animate-slide-right.ng-enter.ng-enter-active {
  transition-property: all;
  transform: translateX(0);
  opacity: 1;
}
.animate-slide-right.ng-leave {
  transform: translateX(0);
  opacity: 1;
}
.animate-slide-right.ng-leave.ng-leave-active {
  transition-property: all;
  transform: translateX(100%);
  opacity: 0;
}
.animate-slide-right.ng-hide {
  transform: translateX(100%);
  opacity: 0;
}
.animate-slide-right.ng-hide-add-active, .animate-slide-right.ng-hide-remove-active {
  transition-property: all;
}

.animate-fade-in-out {
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000px;
  transition-property: none;
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1;
}
.animate-fade-in-out.ng-enter {
  opacity: 0;
}
.animate-fade-in-out.ng-enter.ng-enter-active {
  transition-property: all;
  opacity: 1;
}
.animate-fade-in-out.ng-leave {
  opacity: 1;
}
.animate-fade-in-out.ng-leave.ng-leave-active {
  transition-property: all;
  opacity: 0;
}
.animate-fade-in-out.ng-hide {
  opacity: 0;
}
.animate-fade-in-out.ng-hide-add-active, .animate-fade-in-out.ng-hide-remove-active {
  transition-property: all;
}

/*----------------------------------------------------------------*/
/*  @ Custom Animation Keyframes
/*----------------------------------------------------------------*/
@keyframes slide-in-left {
  0% {
    opacity: 0;
    transform: translateX(-500px);
  }
  65% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slide-in-right {
  0% {
    opacity: 0;
    transform: translateX(500px);
  }
  65% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slide-in-bottom {
  0% {
    opacity: 0;
    transform: translateY(200px);
  }
  65% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}
.animate-rotate {
  animation: rotate 1.75s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*----------------------------------------------------------------*/
/*  Colors
/*----------------------------------------------------------------*/
.black-fg,
.black-text {
  color: rgba(0, 0, 0, 0.87) !important;
}
.black-fg.secondary-text,
.black-fg .secondary-text, .black-fg.icon,
.black-fg .icon,
.black-text.secondary-text,
.black-text .secondary-text,
.black-text.icon,
.black-text .icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.black-fg.hint-text,
.black-fg .hint-text, .black-fg.disabled-text,
.black-fg .disabled-text,
.black-text.hint-text,
.black-text .hint-text,
.black-text.disabled-text,
.black-text .disabled-text {
  color: rgba(0, 0, 0, 0.26) !important;
}
.black-fg.divider,
.black-fg .divider, .black-fg.faint-text,
.black-fg .faint-text,
.black-text.divider,
.black-text .divider,
.black-text.faint-text,
.black-text .faint-text {
  color: rgba(0, 0, 0, 0.12) !important;
}

.white-fg,
.white-text {
  color: rgb(255, 255, 255) !important;
}
.white-fg.secondary-text,
.white-fg .secondary-text, .white-fg.icon,
.white-fg .icon,
.white-text.secondary-text,
.white-text .secondary-text,
.white-text.icon,
.white-text .icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.white-fg.hint-text,
.white-fg .hint-text, .white-fg.disabled-text,
.white-fg .disabled-text,
.white-text.hint-text,
.white-text .hint-text,
.white-text.disabled-text,
.white-text .disabled-text {
  color: rgba(255, 255, 255, 0.3) !important;
}
.white-fg.divider,
.white-fg .divider, .white-fg.faint-text,
.white-fg .faint-text,
.white-text.divider,
.white-text .divider,
.white-text.faint-text,
.white-text .faint-text {
  color: rgba(255, 255, 255, 0.12) !important;
}
.white-fg md-icon,
.white-text md-icon {
  color: #FFFFFF;
}
.white-fg md-progress-linear .md-container,
.white-text md-progress-linear .md-container {
  background: rgba(255, 255, 255, 0.3);
}
.white-fg md-progress-linear .md-bar,
.white-text md-progress-linear .md-bar {
  background: rgba(255, 255, 255, 0.7);
}

.red-50-bg {
  background-color: #FFEBEE !important;
}

.md-red-50-bg {
  background-color: #FFEBEE !important;
  color: rgba(0, 0, 0, 0.87);
}

.red-50-fg {
  color: #FFEBEE !important;
}

.red-50-border {
  border-color: #FFEBEE !important;
}

.red-50-border-top {
  border-top-color: #FFEBEE !important;
}

.red-50-border-right {
  border-right-color: #FFEBEE !important;
}

.red-50-border-bottom {
  border-bottom-color: #FFEBEE !important;
}

.red-50-border-left {
  border-left-color: #FFEBEE !important;
}

.red-100-bg {
  background-color: #FFCDD2 !important;
}

.md-red-100-bg {
  background-color: #FFCDD2 !important;
  color: rgba(0, 0, 0, 0.87);
}

.red-100-fg {
  color: #FFCDD2 !important;
}

.red-100-border {
  border-color: #FFCDD2 !important;
}

.red-100-border-top {
  border-top-color: #FFCDD2 !important;
}

.red-100-border-right {
  border-right-color: #FFCDD2 !important;
}

.red-100-border-bottom {
  border-bottom-color: #FFCDD2 !important;
}

.red-100-border-left {
  border-left-color: #FFCDD2 !important;
}

.red-200-bg {
  background-color: #EF9A9A !important;
}

.md-red-200-bg {
  background-color: #EF9A9A !important;
  color: rgba(0, 0, 0, 0.87);
}

.red-200-fg {
  color: #EF9A9A !important;
}

.red-200-border {
  border-color: #EF9A9A !important;
}

.red-200-border-top {
  border-top-color: #EF9A9A !important;
}

.red-200-border-right {
  border-right-color: #EF9A9A !important;
}

.red-200-border-bottom {
  border-bottom-color: #EF9A9A !important;
}

.red-200-border-left {
  border-left-color: #EF9A9A !important;
}

.red-300-bg {
  background-color: #E57373 !important;
}

.md-red-300-bg {
  background-color: #E57373 !important;
  color: rgba(0, 0, 0, 0.87);
}

.red-300-fg {
  color: #E57373 !important;
}

.red-300-border {
  border-color: #E57373 !important;
}

.red-300-border-top {
  border-top-color: #E57373 !important;
}

.red-300-border-right {
  border-right-color: #E57373 !important;
}

.red-300-border-bottom {
  border-bottom-color: #E57373 !important;
}

.red-300-border-left {
  border-left-color: #E57373 !important;
}

.red-400-bg {
  background-color: #EF5350 !important;
}

.md-red-400-bg {
  background-color: #EF5350 !important;
  color: rgb(255, 255, 255);
}

.red-400-fg {
  color: #EF5350 !important;
}

.red-400-border {
  border-color: #EF5350 !important;
}

.red-400-border-top {
  border-top-color: #EF5350 !important;
}

.red-400-border-right {
  border-right-color: #EF5350 !important;
}

.red-400-border-bottom {
  border-bottom-color: #EF5350 !important;
}

.red-400-border-left {
  border-left-color: #EF5350 !important;
}

.red-500-bg {
  background-color: #F44336 !important;
}

.md-red-500-bg {
  background-color: #F44336 !important;
  color: rgb(255, 255, 255);
}

.red-500-fg {
  color: #F44336 !important;
}

.red-500-border {
  border-color: #F44336 !important;
}

.red-500-border-top {
  border-top-color: #F44336 !important;
}

.red-500-border-right {
  border-right-color: #F44336 !important;
}

.red-500-border-bottom {
  border-bottom-color: #F44336 !important;
}

.red-500-border-left {
  border-left-color: #F44336 !important;
}

.md-red-bg {
  background-color: #F44336 !important;
  color: rgb(255, 255, 255);
}

.red-bg {
  background-color: #F44336 !important;
}

.red-fg {
  color: #F44336 !important;
}

.red-border {
  border-color: #F44336 !important;
}

.red-border-top {
  border-top-color: #F44336 !important;
}

.red-border-right {
  border-right-color: #F44336 !important;
}

.red-border-bottom {
  border-bottom-color: #F44336 !important;
}

.red-border-left {
  border-left-color: #F44336 !important;
}

.red-600-bg {
  background-color: #E53935 !important;
}

.md-red-600-bg {
  background-color: #E53935 !important;
  color: rgb(255, 255, 255);
}

.red-600-fg {
  color: #E53935 !important;
}

.red-600-border {
  border-color: #E53935 !important;
}

.red-600-border-top {
  border-top-color: #E53935 !important;
}

.red-600-border-right {
  border-right-color: #E53935 !important;
}

.red-600-border-bottom {
  border-bottom-color: #E53935 !important;
}

.red-600-border-left {
  border-left-color: #E53935 !important;
}

.red-700-bg {
  background-color: #D32F2F !important;
}

.md-red-700-bg {
  background-color: #D32F2F !important;
  color: rgb(255, 255, 255);
}

.red-700-fg {
  color: #D32F2F !important;
}

.red-700-border {
  border-color: #D32F2F !important;
}

.red-700-border-top {
  border-top-color: #D32F2F !important;
}

.red-700-border-right {
  border-right-color: #D32F2F !important;
}

.red-700-border-bottom {
  border-bottom-color: #D32F2F !important;
}

.red-700-border-left {
  border-left-color: #D32F2F !important;
}

.red-800-bg {
  background-color: #C62828 !important;
}

.md-red-800-bg {
  background-color: #C62828 !important;
  color: rgba(255, 255, 255, 0.87);
}

.red-800-fg {
  color: #C62828 !important;
}

.red-800-border {
  border-color: #C62828 !important;
}

.red-800-border-top {
  border-top-color: #C62828 !important;
}

.red-800-border-right {
  border-right-color: #C62828 !important;
}

.red-800-border-bottom {
  border-bottom-color: #C62828 !important;
}

.red-800-border-left {
  border-left-color: #C62828 !important;
}

.red-900-bg {
  background-color: #B71C1C !important;
}

.md-red-900-bg {
  background-color: #B71C1C !important;
  color: rgba(255, 255, 255, 0.87);
}

.red-900-fg {
  color: #B71C1C !important;
}

.red-900-border {
  border-color: #B71C1C !important;
}

.red-900-border-top {
  border-top-color: #B71C1C !important;
}

.red-900-border-right {
  border-right-color: #B71C1C !important;
}

.red-900-border-bottom {
  border-bottom-color: #B71C1C !important;
}

.red-900-border-left {
  border-left-color: #B71C1C !important;
}

.red-A100-bg {
  background-color: #FF8A80 !important;
}

.md-red-A100-bg {
  background-color: #FF8A80 !important;
  color: rgba(0, 0, 0, 0.87);
}

.red-A100-fg {
  color: #FF8A80 !important;
}

.red-A100-border {
  border-color: #FF8A80 !important;
}

.red-A100-border-top {
  border-top-color: #FF8A80 !important;
}

.red-A100-border-right {
  border-right-color: #FF8A80 !important;
}

.red-A100-border-bottom {
  border-bottom-color: #FF8A80 !important;
}

.red-A100-border-left {
  border-left-color: #FF8A80 !important;
}

.red-A200-bg {
  background-color: #FF5252 !important;
}

.md-red-A200-bg {
  background-color: #FF5252 !important;
  color: rgb(255, 255, 255);
}

.red-A200-fg {
  color: #FF5252 !important;
}

.red-A200-border {
  border-color: #FF5252 !important;
}

.red-A200-border-top {
  border-top-color: #FF5252 !important;
}

.red-A200-border-right {
  border-right-color: #FF5252 !important;
}

.red-A200-border-bottom {
  border-bottom-color: #FF5252 !important;
}

.red-A200-border-left {
  border-left-color: #FF5252 !important;
}

.red-A400-bg {
  background-color: #FF1744 !important;
}

.md-red-A400-bg {
  background-color: #FF1744 !important;
  color: rgb(255, 255, 255);
}

.red-A400-fg {
  color: #FF1744 !important;
}

.red-A400-border {
  border-color: #FF1744 !important;
}

.red-A400-border-top {
  border-top-color: #FF1744 !important;
}

.red-A400-border-right {
  border-right-color: #FF1744 !important;
}

.red-A400-border-bottom {
  border-bottom-color: #FF1744 !important;
}

.red-A400-border-left {
  border-left-color: #FF1744 !important;
}

.red-A700-bg {
  background-color: #D50000 !important;
}

.md-red-A700-bg {
  background-color: #D50000 !important;
  color: rgb(255, 255, 255);
}

.red-A700-fg {
  color: #D50000 !important;
}

.red-A700-border {
  border-color: #D50000 !important;
}

.red-A700-border-top {
  border-top-color: #D50000 !important;
}

.red-A700-border-right {
  border-right-color: #D50000 !important;
}

.red-A700-border-bottom {
  border-bottom-color: #D50000 !important;
}

.red-A700-border-left {
  border-left-color: #D50000 !important;
}

.pink-50-bg {
  background-color: #FCE4EC !important;
}

.md-pink-50-bg {
  background-color: #FCE4EC !important;
  color: rgba(0, 0, 0, 0.87);
}

.pink-50-fg {
  color: #FCE4EC !important;
}

.pink-50-border {
  border-color: #FCE4EC !important;
}

.pink-50-border-top {
  border-top-color: #FCE4EC !important;
}

.pink-50-border-right {
  border-right-color: #FCE4EC !important;
}

.pink-50-border-bottom {
  border-bottom-color: #FCE4EC !important;
}

.pink-50-border-left {
  border-left-color: #FCE4EC !important;
}

.pink-100-bg {
  background-color: #F8BBD0 !important;
}

.md-pink-100-bg {
  background-color: #F8BBD0 !important;
  color: rgba(0, 0, 0, 0.87);
}

.pink-100-fg {
  color: #F8BBD0 !important;
}

.pink-100-border {
  border-color: #F8BBD0 !important;
}

.pink-100-border-top {
  border-top-color: #F8BBD0 !important;
}

.pink-100-border-right {
  border-right-color: #F8BBD0 !important;
}

.pink-100-border-bottom {
  border-bottom-color: #F8BBD0 !important;
}

.pink-100-border-left {
  border-left-color: #F8BBD0 !important;
}

.pink-200-bg {
  background-color: #F48FB1 !important;
}

.md-pink-200-bg {
  background-color: #F48FB1 !important;
  color: rgba(0, 0, 0, 0.87);
}

.pink-200-fg {
  color: #F48FB1 !important;
}

.pink-200-border {
  border-color: #F48FB1 !important;
}

.pink-200-border-top {
  border-top-color: #F48FB1 !important;
}

.pink-200-border-right {
  border-right-color: #F48FB1 !important;
}

.pink-200-border-bottom {
  border-bottom-color: #F48FB1 !important;
}

.pink-200-border-left {
  border-left-color: #F48FB1 !important;
}

.pink-300-bg {
  background-color: #F06292 !important;
}

.md-pink-300-bg {
  background-color: #F06292 !important;
  color: rgba(255, 255, 255, 0.87);
}

.pink-300-fg {
  color: #F06292 !important;
}

.pink-300-border {
  border-color: #F06292 !important;
}

.pink-300-border-top {
  border-top-color: #F06292 !important;
}

.pink-300-border-right {
  border-right-color: #F06292 !important;
}

.pink-300-border-bottom {
  border-bottom-color: #F06292 !important;
}

.pink-300-border-left {
  border-left-color: #F06292 !important;
}

.pink-400-bg {
  background-color: #EC407A !important;
}

.md-pink-400-bg {
  background-color: #EC407A !important;
  color: rgba(255, 255, 255, 0.87);
}

.pink-400-fg {
  color: #EC407A !important;
}

.pink-400-border {
  border-color: #EC407A !important;
}

.pink-400-border-top {
  border-top-color: #EC407A !important;
}

.pink-400-border-right {
  border-right-color: #EC407A !important;
}

.pink-400-border-bottom {
  border-bottom-color: #EC407A !important;
}

.pink-400-border-left {
  border-left-color: #EC407A !important;
}

.pink-500-bg {
  background-color: #E91E63 !important;
}

.md-pink-500-bg {
  background-color: #E91E63 !important;
  color: rgb(255, 255, 255);
}

.pink-500-fg {
  color: #E91E63 !important;
}

.pink-500-border {
  border-color: #E91E63 !important;
}

.pink-500-border-top {
  border-top-color: #E91E63 !important;
}

.pink-500-border-right {
  border-right-color: #E91E63 !important;
}

.pink-500-border-bottom {
  border-bottom-color: #E91E63 !important;
}

.pink-500-border-left {
  border-left-color: #E91E63 !important;
}

.md-pink-bg {
  background-color: #E91E63 !important;
  color: rgb(255, 255, 255);
}

.pink-bg {
  background-color: #E91E63 !important;
}

.pink-fg {
  color: #E91E63 !important;
}

.pink-border {
  border-color: #E91E63 !important;
}

.pink-border-top {
  border-top-color: #E91E63 !important;
}

.pink-border-right {
  border-right-color: #E91E63 !important;
}

.pink-border-bottom {
  border-bottom-color: #E91E63 !important;
}

.pink-border-left {
  border-left-color: #E91E63 !important;
}

.pink-600-bg {
  background-color: #D81B60 !important;
}

.md-pink-600-bg {
  background-color: #D81B60 !important;
  color: rgb(255, 255, 255);
}

.pink-600-fg {
  color: #D81B60 !important;
}

.pink-600-border {
  border-color: #D81B60 !important;
}

.pink-600-border-top {
  border-top-color: #D81B60 !important;
}

.pink-600-border-right {
  border-right-color: #D81B60 !important;
}

.pink-600-border-bottom {
  border-bottom-color: #D81B60 !important;
}

.pink-600-border-left {
  border-left-color: #D81B60 !important;
}

.pink-700-bg {
  background-color: #C2185B !important;
}

.md-pink-700-bg {
  background-color: #C2185B !important;
  color: rgba(255, 255, 255, 0.87);
}

.pink-700-fg {
  color: #C2185B !important;
}

.pink-700-border {
  border-color: #C2185B !important;
}

.pink-700-border-top {
  border-top-color: #C2185B !important;
}

.pink-700-border-right {
  border-right-color: #C2185B !important;
}

.pink-700-border-bottom {
  border-bottom-color: #C2185B !important;
}

.pink-700-border-left {
  border-left-color: #C2185B !important;
}

.pink-800-bg {
  background-color: #AD1457 !important;
}

.md-pink-800-bg {
  background-color: #AD1457 !important;
  color: rgba(255, 255, 255, 0.87);
}

.pink-800-fg {
  color: #AD1457 !important;
}

.pink-800-border {
  border-color: #AD1457 !important;
}

.pink-800-border-top {
  border-top-color: #AD1457 !important;
}

.pink-800-border-right {
  border-right-color: #AD1457 !important;
}

.pink-800-border-bottom {
  border-bottom-color: #AD1457 !important;
}

.pink-800-border-left {
  border-left-color: #AD1457 !important;
}

.pink-900-bg {
  background-color: #880E4F !important;
}

.md-pink-900-bg {
  background-color: #880E4F !important;
  color: rgba(255, 255, 255, 0.87);
}

.pink-900-fg {
  color: #880E4F !important;
}

.pink-900-border {
  border-color: #880E4F !important;
}

.pink-900-border-top {
  border-top-color: #880E4F !important;
}

.pink-900-border-right {
  border-right-color: #880E4F !important;
}

.pink-900-border-bottom {
  border-bottom-color: #880E4F !important;
}

.pink-900-border-left {
  border-left-color: #880E4F !important;
}

.pink-A100-bg {
  background-color: #FF80AB !important;
}

.md-pink-A100-bg {
  background-color: #FF80AB !important;
  color: rgba(0, 0, 0, 0.87);
}

.pink-A100-fg {
  color: #FF80AB !important;
}

.pink-A100-border {
  border-color: #FF80AB !important;
}

.pink-A100-border-top {
  border-top-color: #FF80AB !important;
}

.pink-A100-border-right {
  border-right-color: #FF80AB !important;
}

.pink-A100-border-bottom {
  border-bottom-color: #FF80AB !important;
}

.pink-A100-border-left {
  border-left-color: #FF80AB !important;
}

.pink-A200-bg {
  background-color: #FF4081 !important;
}

.md-pink-A200-bg {
  background-color: #FF4081 !important;
  color: rgb(255, 255, 255);
}

.pink-A200-fg {
  color: #FF4081 !important;
}

.pink-A200-border {
  border-color: #FF4081 !important;
}

.pink-A200-border-top {
  border-top-color: #FF4081 !important;
}

.pink-A200-border-right {
  border-right-color: #FF4081 !important;
}

.pink-A200-border-bottom {
  border-bottom-color: #FF4081 !important;
}

.pink-A200-border-left {
  border-left-color: #FF4081 !important;
}

.pink-A400-bg {
  background-color: #F50057 !important;
}

.md-pink-A400-bg {
  background-color: #F50057 !important;
  color: rgb(255, 255, 255);
}

.pink-A400-fg {
  color: #F50057 !important;
}

.pink-A400-border {
  border-color: #F50057 !important;
}

.pink-A400-border-top {
  border-top-color: #F50057 !important;
}

.pink-A400-border-right {
  border-right-color: #F50057 !important;
}

.pink-A400-border-bottom {
  border-bottom-color: #F50057 !important;
}

.pink-A400-border-left {
  border-left-color: #F50057 !important;
}

.pink-A700-bg {
  background-color: #C51162 !important;
}

.md-pink-A700-bg {
  background-color: #C51162 !important;
  color: rgb(255, 255, 255);
}

.pink-A700-fg {
  color: #C51162 !important;
}

.pink-A700-border {
  border-color: #C51162 !important;
}

.pink-A700-border-top {
  border-top-color: #C51162 !important;
}

.pink-A700-border-right {
  border-right-color: #C51162 !important;
}

.pink-A700-border-bottom {
  border-bottom-color: #C51162 !important;
}

.pink-A700-border-left {
  border-left-color: #C51162 !important;
}

.purple-50-bg {
  background-color: #F3E5F5 !important;
}

.md-purple-50-bg {
  background-color: #F3E5F5 !important;
  color: rgba(0, 0, 0, 0.87);
}

.purple-50-fg {
  color: #F3E5F5 !important;
}

.purple-50-border {
  border-color: #F3E5F5 !important;
}

.purple-50-border-top {
  border-top-color: #F3E5F5 !important;
}

.purple-50-border-right {
  border-right-color: #F3E5F5 !important;
}

.purple-50-border-bottom {
  border-bottom-color: #F3E5F5 !important;
}

.purple-50-border-left {
  border-left-color: #F3E5F5 !important;
}

.purple-100-bg {
  background-color: #E1BEE7 !important;
}

.md-purple-100-bg {
  background-color: #E1BEE7 !important;
  color: rgba(0, 0, 0, 0.87);
}

.purple-100-fg {
  color: #E1BEE7 !important;
}

.purple-100-border {
  border-color: #E1BEE7 !important;
}

.purple-100-border-top {
  border-top-color: #E1BEE7 !important;
}

.purple-100-border-right {
  border-right-color: #E1BEE7 !important;
}

.purple-100-border-bottom {
  border-bottom-color: #E1BEE7 !important;
}

.purple-100-border-left {
  border-left-color: #E1BEE7 !important;
}

.purple-200-bg {
  background-color: #CE93D8 !important;
}

.md-purple-200-bg {
  background-color: #CE93D8 !important;
  color: rgba(0, 0, 0, 0.87);
}

.purple-200-fg {
  color: #CE93D8 !important;
}

.purple-200-border {
  border-color: #CE93D8 !important;
}

.purple-200-border-top {
  border-top-color: #CE93D8 !important;
}

.purple-200-border-right {
  border-right-color: #CE93D8 !important;
}

.purple-200-border-bottom {
  border-bottom-color: #CE93D8 !important;
}

.purple-200-border-left {
  border-left-color: #CE93D8 !important;
}

.purple-300-bg {
  background-color: #BA68C8 !important;
}

.md-purple-300-bg {
  background-color: #BA68C8 !important;
  color: rgb(255, 255, 255);
}

.purple-300-fg {
  color: #BA68C8 !important;
}

.purple-300-border {
  border-color: #BA68C8 !important;
}

.purple-300-border-top {
  border-top-color: #BA68C8 !important;
}

.purple-300-border-right {
  border-right-color: #BA68C8 !important;
}

.purple-300-border-bottom {
  border-bottom-color: #BA68C8 !important;
}

.purple-300-border-left {
  border-left-color: #BA68C8 !important;
}

.purple-400-bg {
  background-color: #AB47BC !important;
}

.md-purple-400-bg {
  background-color: #AB47BC !important;
  color: rgb(255, 255, 255);
}

.purple-400-fg {
  color: #AB47BC !important;
}

.purple-400-border {
  border-color: #AB47BC !important;
}

.purple-400-border-top {
  border-top-color: #AB47BC !important;
}

.purple-400-border-right {
  border-right-color: #AB47BC !important;
}

.purple-400-border-bottom {
  border-bottom-color: #AB47BC !important;
}

.purple-400-border-left {
  border-left-color: #AB47BC !important;
}

.purple-500-bg {
  background-color: #9C27B0 !important;
}

.md-purple-500-bg {
  background-color: #9C27B0 !important;
  color: rgba(255, 255, 255, 0.87);
}

.purple-500-fg {
  color: #9C27B0 !important;
}

.purple-500-border {
  border-color: #9C27B0 !important;
}

.purple-500-border-top {
  border-top-color: #9C27B0 !important;
}

.purple-500-border-right {
  border-right-color: #9C27B0 !important;
}

.purple-500-border-bottom {
  border-bottom-color: #9C27B0 !important;
}

.purple-500-border-left {
  border-left-color: #9C27B0 !important;
}

.md-purple-bg {
  background-color: #9C27B0 !important;
  color: rgba(255, 255, 255, 0.87);
}

.purple-bg {
  background-color: #9C27B0 !important;
}

.purple-fg {
  color: #9C27B0 !important;
}

.purple-border {
  border-color: #9C27B0 !important;
}

.purple-border-top {
  border-top-color: #9C27B0 !important;
}

.purple-border-right {
  border-right-color: #9C27B0 !important;
}

.purple-border-bottom {
  border-bottom-color: #9C27B0 !important;
}

.purple-border-left {
  border-left-color: #9C27B0 !important;
}

.purple-600-bg {
  background-color: #8E24AA !important;
}

.md-purple-600-bg {
  background-color: #8E24AA !important;
  color: rgba(255, 255, 255, 0.87);
}

.purple-600-fg {
  color: #8E24AA !important;
}

.purple-600-border {
  border-color: #8E24AA !important;
}

.purple-600-border-top {
  border-top-color: #8E24AA !important;
}

.purple-600-border-right {
  border-right-color: #8E24AA !important;
}

.purple-600-border-bottom {
  border-bottom-color: #8E24AA !important;
}

.purple-600-border-left {
  border-left-color: #8E24AA !important;
}

.purple-700-bg {
  background-color: #7B1FA2 !important;
}

.md-purple-700-bg {
  background-color: #7B1FA2 !important;
  color: rgba(255, 255, 255, 0.87);
}

.purple-700-fg {
  color: #7B1FA2 !important;
}

.purple-700-border {
  border-color: #7B1FA2 !important;
}

.purple-700-border-top {
  border-top-color: #7B1FA2 !important;
}

.purple-700-border-right {
  border-right-color: #7B1FA2 !important;
}

.purple-700-border-bottom {
  border-bottom-color: #7B1FA2 !important;
}

.purple-700-border-left {
  border-left-color: #7B1FA2 !important;
}

.purple-800-bg {
  background-color: #6A1B9A !important;
}

.md-purple-800-bg {
  background-color: #6A1B9A !important;
  color: rgba(255, 255, 255, 0.87);
}

.purple-800-fg {
  color: #6A1B9A !important;
}

.purple-800-border {
  border-color: #6A1B9A !important;
}

.purple-800-border-top {
  border-top-color: #6A1B9A !important;
}

.purple-800-border-right {
  border-right-color: #6A1B9A !important;
}

.purple-800-border-bottom {
  border-bottom-color: #6A1B9A !important;
}

.purple-800-border-left {
  border-left-color: #6A1B9A !important;
}

.purple-900-bg {
  background-color: #4A148C !important;
}

.md-purple-900-bg {
  background-color: #4A148C !important;
  color: rgba(255, 255, 255, 0.87);
}

.purple-900-fg {
  color: #4A148C !important;
}

.purple-900-border {
  border-color: #4A148C !important;
}

.purple-900-border-top {
  border-top-color: #4A148C !important;
}

.purple-900-border-right {
  border-right-color: #4A148C !important;
}

.purple-900-border-bottom {
  border-bottom-color: #4A148C !important;
}

.purple-900-border-left {
  border-left-color: #4A148C !important;
}

.purple-A100-bg {
  background-color: #EA80FC !important;
}

.md-purple-A100-bg {
  background-color: #EA80FC !important;
  color: rgba(0, 0, 0, 0.87);
}

.purple-A100-fg {
  color: #EA80FC !important;
}

.purple-A100-border {
  border-color: #EA80FC !important;
}

.purple-A100-border-top {
  border-top-color: #EA80FC !important;
}

.purple-A100-border-right {
  border-right-color: #EA80FC !important;
}

.purple-A100-border-bottom {
  border-bottom-color: #EA80FC !important;
}

.purple-A100-border-left {
  border-left-color: #EA80FC !important;
}

.purple-A200-bg {
  background-color: #E040FB !important;
}

.md-purple-A200-bg {
  background-color: #E040FB !important;
  color: rgb(255, 255, 255);
}

.purple-A200-fg {
  color: #E040FB !important;
}

.purple-A200-border {
  border-color: #E040FB !important;
}

.purple-A200-border-top {
  border-top-color: #E040FB !important;
}

.purple-A200-border-right {
  border-right-color: #E040FB !important;
}

.purple-A200-border-bottom {
  border-bottom-color: #E040FB !important;
}

.purple-A200-border-left {
  border-left-color: #E040FB !important;
}

.purple-A400-bg {
  background-color: #D500F9 !important;
}

.md-purple-A400-bg {
  background-color: #D500F9 !important;
  color: rgb(255, 255, 255);
}

.purple-A400-fg {
  color: #D500F9 !important;
}

.purple-A400-border {
  border-color: #D500F9 !important;
}

.purple-A400-border-top {
  border-top-color: #D500F9 !important;
}

.purple-A400-border-right {
  border-right-color: #D500F9 !important;
}

.purple-A400-border-bottom {
  border-bottom-color: #D500F9 !important;
}

.purple-A400-border-left {
  border-left-color: #D500F9 !important;
}

.purple-A700-bg {
  background-color: #AA00FF !important;
}

.md-purple-A700-bg {
  background-color: #AA00FF !important;
  color: rgb(255, 255, 255);
}

.purple-A700-fg {
  color: #AA00FF !important;
}

.purple-A700-border {
  border-color: #AA00FF !important;
}

.purple-A700-border-top {
  border-top-color: #AA00FF !important;
}

.purple-A700-border-right {
  border-right-color: #AA00FF !important;
}

.purple-A700-border-bottom {
  border-bottom-color: #AA00FF !important;
}

.purple-A700-border-left {
  border-left-color: #AA00FF !important;
}

.deep-purple-50-bg {
  background-color: #EDE7F6 !important;
}

.md-deep-purple-50-bg {
  background-color: #EDE7F6 !important;
  color: rgba(0, 0, 0, 0.87);
}

.deep-purple-50-fg {
  color: #EDE7F6 !important;
}

.deep-purple-50-border {
  border-color: #EDE7F6 !important;
}

.deep-purple-50-border-top {
  border-top-color: #EDE7F6 !important;
}

.deep-purple-50-border-right {
  border-right-color: #EDE7F6 !important;
}

.deep-purple-50-border-bottom {
  border-bottom-color: #EDE7F6 !important;
}

.deep-purple-50-border-left {
  border-left-color: #EDE7F6 !important;
}

.deep-purple-100-bg {
  background-color: #D1C4E9 !important;
}

.md-deep-purple-100-bg {
  background-color: #D1C4E9 !important;
  color: rgba(0, 0, 0, 0.87);
}

.deep-purple-100-fg {
  color: #D1C4E9 !important;
}

.deep-purple-100-border {
  border-color: #D1C4E9 !important;
}

.deep-purple-100-border-top {
  border-top-color: #D1C4E9 !important;
}

.deep-purple-100-border-right {
  border-right-color: #D1C4E9 !important;
}

.deep-purple-100-border-bottom {
  border-bottom-color: #D1C4E9 !important;
}

.deep-purple-100-border-left {
  border-left-color: #D1C4E9 !important;
}

.deep-purple-200-bg {
  background-color: #B39DDB !important;
}

.md-deep-purple-200-bg {
  background-color: #B39DDB !important;
  color: rgba(0, 0, 0, 0.87);
}

.deep-purple-200-fg {
  color: #B39DDB !important;
}

.deep-purple-200-border {
  border-color: #B39DDB !important;
}

.deep-purple-200-border-top {
  border-top-color: #B39DDB !important;
}

.deep-purple-200-border-right {
  border-right-color: #B39DDB !important;
}

.deep-purple-200-border-bottom {
  border-bottom-color: #B39DDB !important;
}

.deep-purple-200-border-left {
  border-left-color: #B39DDB !important;
}

.deep-purple-300-bg {
  background-color: #9575CD !important;
}

.md-deep-purple-300-bg {
  background-color: #9575CD !important;
  color: rgb(255, 255, 255);
}

.deep-purple-300-fg {
  color: #9575CD !important;
}

.deep-purple-300-border {
  border-color: #9575CD !important;
}

.deep-purple-300-border-top {
  border-top-color: #9575CD !important;
}

.deep-purple-300-border-right {
  border-right-color: #9575CD !important;
}

.deep-purple-300-border-bottom {
  border-bottom-color: #9575CD !important;
}

.deep-purple-300-border-left {
  border-left-color: #9575CD !important;
}

.deep-purple-400-bg {
  background-color: #7E57C2 !important;
}

.md-deep-purple-400-bg {
  background-color: #7E57C2 !important;
  color: rgb(255, 255, 255);
}

.deep-purple-400-fg {
  color: #7E57C2 !important;
}

.deep-purple-400-border {
  border-color: #7E57C2 !important;
}

.deep-purple-400-border-top {
  border-top-color: #7E57C2 !important;
}

.deep-purple-400-border-right {
  border-right-color: #7E57C2 !important;
}

.deep-purple-400-border-bottom {
  border-bottom-color: #7E57C2 !important;
}

.deep-purple-400-border-left {
  border-left-color: #7E57C2 !important;
}

.deep-purple-500-bg {
  background-color: #673AB7 !important;
}

.md-deep-purple-500-bg {
  background-color: #673AB7 !important;
  color: rgba(255, 255, 255, 0.87);
}

.deep-purple-500-fg {
  color: #673AB7 !important;
}

.deep-purple-500-border {
  border-color: #673AB7 !important;
}

.deep-purple-500-border-top {
  border-top-color: #673AB7 !important;
}

.deep-purple-500-border-right {
  border-right-color: #673AB7 !important;
}

.deep-purple-500-border-bottom {
  border-bottom-color: #673AB7 !important;
}

.deep-purple-500-border-left {
  border-left-color: #673AB7 !important;
}

.md-deep-purple-bg {
  background-color: #673AB7 !important;
  color: rgba(255, 255, 255, 0.87);
}

.deep-purple-bg {
  background-color: #673AB7 !important;
}

.deep-purple-fg {
  color: #673AB7 !important;
}

.deep-purple-border {
  border-color: #673AB7 !important;
}

.deep-purple-border-top {
  border-top-color: #673AB7 !important;
}

.deep-purple-border-right {
  border-right-color: #673AB7 !important;
}

.deep-purple-border-bottom {
  border-bottom-color: #673AB7 !important;
}

.deep-purple-border-left {
  border-left-color: #673AB7 !important;
}

.deep-purple-600-bg {
  background-color: #5E35B1 !important;
}

.md-deep-purple-600-bg {
  background-color: #5E35B1 !important;
  color: rgba(255, 255, 255, 0.87);
}

.deep-purple-600-fg {
  color: #5E35B1 !important;
}

.deep-purple-600-border {
  border-color: #5E35B1 !important;
}

.deep-purple-600-border-top {
  border-top-color: #5E35B1 !important;
}

.deep-purple-600-border-right {
  border-right-color: #5E35B1 !important;
}

.deep-purple-600-border-bottom {
  border-bottom-color: #5E35B1 !important;
}

.deep-purple-600-border-left {
  border-left-color: #5E35B1 !important;
}

.deep-purple-700-bg {
  background-color: #512DA8 !important;
}

.md-deep-purple-700-bg {
  background-color: #512DA8 !important;
  color: rgba(255, 255, 255, 0.87);
}

.deep-purple-700-fg {
  color: #512DA8 !important;
}

.deep-purple-700-border {
  border-color: #512DA8 !important;
}

.deep-purple-700-border-top {
  border-top-color: #512DA8 !important;
}

.deep-purple-700-border-right {
  border-right-color: #512DA8 !important;
}

.deep-purple-700-border-bottom {
  border-bottom-color: #512DA8 !important;
}

.deep-purple-700-border-left {
  border-left-color: #512DA8 !important;
}

.deep-purple-800-bg {
  background-color: #4527A0 !important;
}

.md-deep-purple-800-bg {
  background-color: #4527A0 !important;
  color: rgba(255, 255, 255, 0.87);
}

.deep-purple-800-fg {
  color: #4527A0 !important;
}

.deep-purple-800-border {
  border-color: #4527A0 !important;
}

.deep-purple-800-border-top {
  border-top-color: #4527A0 !important;
}

.deep-purple-800-border-right {
  border-right-color: #4527A0 !important;
}

.deep-purple-800-border-bottom {
  border-bottom-color: #4527A0 !important;
}

.deep-purple-800-border-left {
  border-left-color: #4527A0 !important;
}

.deep-purple-900-bg {
  background-color: #311B92 !important;
}

.md-deep-purple-900-bg {
  background-color: #311B92 !important;
  color: rgba(255, 255, 255, 0.87);
}

.deep-purple-900-fg {
  color: #311B92 !important;
}

.deep-purple-900-border {
  border-color: #311B92 !important;
}

.deep-purple-900-border-top {
  border-top-color: #311B92 !important;
}

.deep-purple-900-border-right {
  border-right-color: #311B92 !important;
}

.deep-purple-900-border-bottom {
  border-bottom-color: #311B92 !important;
}

.deep-purple-900-border-left {
  border-left-color: #311B92 !important;
}

.deep-purple-A100-bg {
  background-color: #B388FF !important;
}

.md-deep-purple-A100-bg {
  background-color: #B388FF !important;
  color: rgba(0, 0, 0, 0.87);
}

.deep-purple-A100-fg {
  color: #B388FF !important;
}

.deep-purple-A100-border {
  border-color: #B388FF !important;
}

.deep-purple-A100-border-top {
  border-top-color: #B388FF !important;
}

.deep-purple-A100-border-right {
  border-right-color: #B388FF !important;
}

.deep-purple-A100-border-bottom {
  border-bottom-color: #B388FF !important;
}

.deep-purple-A100-border-left {
  border-left-color: #B388FF !important;
}

.deep-purple-A200-bg {
  background-color: #7C4DFF !important;
}

.md-deep-purple-A200-bg {
  background-color: #7C4DFF !important;
  color: rgb(255, 255, 255);
}

.deep-purple-A200-fg {
  color: #7C4DFF !important;
}

.deep-purple-A200-border {
  border-color: #7C4DFF !important;
}

.deep-purple-A200-border-top {
  border-top-color: #7C4DFF !important;
}

.deep-purple-A200-border-right {
  border-right-color: #7C4DFF !important;
}

.deep-purple-A200-border-bottom {
  border-bottom-color: #7C4DFF !important;
}

.deep-purple-A200-border-left {
  border-left-color: #7C4DFF !important;
}

.deep-purple-A400-bg {
  background-color: #651FFF !important;
}

.md-deep-purple-A400-bg {
  background-color: #651FFF !important;
  color: rgba(255, 255, 255, 0.87);
}

.deep-purple-A400-fg {
  color: #651FFF !important;
}

.deep-purple-A400-border {
  border-color: #651FFF !important;
}

.deep-purple-A400-border-top {
  border-top-color: #651FFF !important;
}

.deep-purple-A400-border-right {
  border-right-color: #651FFF !important;
}

.deep-purple-A400-border-bottom {
  border-bottom-color: #651FFF !important;
}

.deep-purple-A400-border-left {
  border-left-color: #651FFF !important;
}

.deep-purple-A700-bg {
  background-color: #6200EA !important;
}

.md-deep-purple-A700-bg {
  background-color: #6200EA !important;
  color: rgba(255, 255, 255, 0.87);
}

.deep-purple-A700-fg {
  color: #6200EA !important;
}

.deep-purple-A700-border {
  border-color: #6200EA !important;
}

.deep-purple-A700-border-top {
  border-top-color: #6200EA !important;
}

.deep-purple-A700-border-right {
  border-right-color: #6200EA !important;
}

.deep-purple-A700-border-bottom {
  border-bottom-color: #6200EA !important;
}

.deep-purple-A700-border-left {
  border-left-color: #6200EA !important;
}

.indigo-50-bg {
  background-color: #E8EAF6 !important;
}

.md-indigo-50-bg {
  background-color: #E8EAF6 !important;
  color: rgba(0, 0, 0, 0.87);
}

.indigo-50-fg {
  color: #E8EAF6 !important;
}

.indigo-50-border {
  border-color: #E8EAF6 !important;
}

.indigo-50-border-top {
  border-top-color: #E8EAF6 !important;
}

.indigo-50-border-right {
  border-right-color: #E8EAF6 !important;
}

.indigo-50-border-bottom {
  border-bottom-color: #E8EAF6 !important;
}

.indigo-50-border-left {
  border-left-color: #E8EAF6 !important;
}

.indigo-100-bg {
  background-color: #C5CAE9 !important;
}

.md-indigo-100-bg {
  background-color: #C5CAE9 !important;
  color: rgba(0, 0, 0, 0.87);
}

.indigo-100-fg {
  color: #C5CAE9 !important;
}

.indigo-100-border {
  border-color: #C5CAE9 !important;
}

.indigo-100-border-top {
  border-top-color: #C5CAE9 !important;
}

.indigo-100-border-right {
  border-right-color: #C5CAE9 !important;
}

.indigo-100-border-bottom {
  border-bottom-color: #C5CAE9 !important;
}

.indigo-100-border-left {
  border-left-color: #C5CAE9 !important;
}

.indigo-200-bg {
  background-color: #9FA8DA !important;
}

.md-indigo-200-bg {
  background-color: #9FA8DA !important;
  color: rgba(0, 0, 0, 0.87);
}

.indigo-200-fg {
  color: #9FA8DA !important;
}

.indigo-200-border {
  border-color: #9FA8DA !important;
}

.indigo-200-border-top {
  border-top-color: #9FA8DA !important;
}

.indigo-200-border-right {
  border-right-color: #9FA8DA !important;
}

.indigo-200-border-bottom {
  border-bottom-color: #9FA8DA !important;
}

.indigo-200-border-left {
  border-left-color: #9FA8DA !important;
}

.indigo-300-bg {
  background-color: #7986CB !important;
}

.md-indigo-300-bg {
  background-color: #7986CB !important;
  color: rgb(255, 255, 255);
}

.indigo-300-fg {
  color: #7986CB !important;
}

.indigo-300-border {
  border-color: #7986CB !important;
}

.indigo-300-border-top {
  border-top-color: #7986CB !important;
}

.indigo-300-border-right {
  border-right-color: #7986CB !important;
}

.indigo-300-border-bottom {
  border-bottom-color: #7986CB !important;
}

.indigo-300-border-left {
  border-left-color: #7986CB !important;
}

.indigo-400-bg {
  background-color: #5C6BC0 !important;
}

.md-indigo-400-bg {
  background-color: #5C6BC0 !important;
  color: rgb(255, 255, 255);
}

.indigo-400-fg {
  color: #5C6BC0 !important;
}

.indigo-400-border {
  border-color: #5C6BC0 !important;
}

.indigo-400-border-top {
  border-top-color: #5C6BC0 !important;
}

.indigo-400-border-right {
  border-right-color: #5C6BC0 !important;
}

.indigo-400-border-bottom {
  border-bottom-color: #5C6BC0 !important;
}

.indigo-400-border-left {
  border-left-color: #5C6BC0 !important;
}

.indigo-500-bg {
  background-color: #3F51B5 !important;
}

.md-indigo-500-bg {
  background-color: #3F51B5 !important;
  color: rgba(255, 255, 255, 0.87);
}

.indigo-500-fg {
  color: #3F51B5 !important;
}

.indigo-500-border {
  border-color: #3F51B5 !important;
}

.indigo-500-border-top {
  border-top-color: #3F51B5 !important;
}

.indigo-500-border-right {
  border-right-color: #3F51B5 !important;
}

.indigo-500-border-bottom {
  border-bottom-color: #3F51B5 !important;
}

.indigo-500-border-left {
  border-left-color: #3F51B5 !important;
}

.md-indigo-bg {
  background-color: #3F51B5 !important;
  color: rgba(255, 255, 255, 0.87);
}

.indigo-bg {
  background-color: #3F51B5 !important;
}

.indigo-fg {
  color: #3F51B5 !important;
}

.indigo-border {
  border-color: #3F51B5 !important;
}

.indigo-border-top {
  border-top-color: #3F51B5 !important;
}

.indigo-border-right {
  border-right-color: #3F51B5 !important;
}

.indigo-border-bottom {
  border-bottom-color: #3F51B5 !important;
}

.indigo-border-left {
  border-left-color: #3F51B5 !important;
}

.indigo-600-bg {
  background-color: #3949AB !important;
}

.md-indigo-600-bg {
  background-color: #3949AB !important;
  color: rgba(255, 255, 255, 0.87);
}

.indigo-600-fg {
  color: #3949AB !important;
}

.indigo-600-border {
  border-color: #3949AB !important;
}

.indigo-600-border-top {
  border-top-color: #3949AB !important;
}

.indigo-600-border-right {
  border-right-color: #3949AB !important;
}

.indigo-600-border-bottom {
  border-bottom-color: #3949AB !important;
}

.indigo-600-border-left {
  border-left-color: #3949AB !important;
}

.indigo-700-bg {
  background-color: #303F9F !important;
}

.md-indigo-700-bg {
  background-color: #303F9F !important;
  color: rgba(255, 255, 255, 0.87);
}

.indigo-700-fg {
  color: #303F9F !important;
}

.indigo-700-border {
  border-color: #303F9F !important;
}

.indigo-700-border-top {
  border-top-color: #303F9F !important;
}

.indigo-700-border-right {
  border-right-color: #303F9F !important;
}

.indigo-700-border-bottom {
  border-bottom-color: #303F9F !important;
}

.indigo-700-border-left {
  border-left-color: #303F9F !important;
}

.indigo-800-bg {
  background-color: #283593 !important;
}

.md-indigo-800-bg {
  background-color: #283593 !important;
  color: rgba(255, 255, 255, 0.87);
}

.indigo-800-fg {
  color: #283593 !important;
}

.indigo-800-border {
  border-color: #283593 !important;
}

.indigo-800-border-top {
  border-top-color: #283593 !important;
}

.indigo-800-border-right {
  border-right-color: #283593 !important;
}

.indigo-800-border-bottom {
  border-bottom-color: #283593 !important;
}

.indigo-800-border-left {
  border-left-color: #283593 !important;
}

.indigo-900-bg {
  background-color: #1A237E !important;
}

.md-indigo-900-bg {
  background-color: #1A237E !important;
  color: rgba(255, 255, 255, 0.87);
}

.indigo-900-fg {
  color: #1A237E !important;
}

.indigo-900-border {
  border-color: #1A237E !important;
}

.indigo-900-border-top {
  border-top-color: #1A237E !important;
}

.indigo-900-border-right {
  border-right-color: #1A237E !important;
}

.indigo-900-border-bottom {
  border-bottom-color: #1A237E !important;
}

.indigo-900-border-left {
  border-left-color: #1A237E !important;
}

.indigo-A100-bg {
  background-color: #8C9EFF !important;
}

.md-indigo-A100-bg {
  background-color: #8C9EFF !important;
  color: rgba(0, 0, 0, 0.87);
}

.indigo-A100-fg {
  color: #8C9EFF !important;
}

.indigo-A100-border {
  border-color: #8C9EFF !important;
}

.indigo-A100-border-top {
  border-top-color: #8C9EFF !important;
}

.indigo-A100-border-right {
  border-right-color: #8C9EFF !important;
}

.indigo-A100-border-bottom {
  border-bottom-color: #8C9EFF !important;
}

.indigo-A100-border-left {
  border-left-color: #8C9EFF !important;
}

.indigo-A200-bg {
  background-color: #536DFE !important;
}

.md-indigo-A200-bg {
  background-color: #536DFE !important;
  color: rgb(255, 255, 255);
}

.indigo-A200-fg {
  color: #536DFE !important;
}

.indigo-A200-border {
  border-color: #536DFE !important;
}

.indigo-A200-border-top {
  border-top-color: #536DFE !important;
}

.indigo-A200-border-right {
  border-right-color: #536DFE !important;
}

.indigo-A200-border-bottom {
  border-bottom-color: #536DFE !important;
}

.indigo-A200-border-left {
  border-left-color: #536DFE !important;
}

.indigo-A400-bg {
  background-color: #3D5AFE !important;
}

.md-indigo-A400-bg {
  background-color: #3D5AFE !important;
  color: rgb(255, 255, 255);
}

.indigo-A400-fg {
  color: #3D5AFE !important;
}

.indigo-A400-border {
  border-color: #3D5AFE !important;
}

.indigo-A400-border-top {
  border-top-color: #3D5AFE !important;
}

.indigo-A400-border-right {
  border-right-color: #3D5AFE !important;
}

.indigo-A400-border-bottom {
  border-bottom-color: #3D5AFE !important;
}

.indigo-A400-border-left {
  border-left-color: #3D5AFE !important;
}

.indigo-A700-bg {
  background-color: #304FFE !important;
}

.md-indigo-A700-bg {
  background-color: #304FFE !important;
  color: rgba(255, 255, 255, 0.87);
}

.indigo-A700-fg {
  color: #304FFE !important;
}

.indigo-A700-border {
  border-color: #304FFE !important;
}

.indigo-A700-border-top {
  border-top-color: #304FFE !important;
}

.indigo-A700-border-right {
  border-right-color: #304FFE !important;
}

.indigo-A700-border-bottom {
  border-bottom-color: #304FFE !important;
}

.indigo-A700-border-left {
  border-left-color: #304FFE !important;
}

.blue-50-bg {
  background-color: #E3F2FD !important;
}

.md-blue-50-bg {
  background-color: #E3F2FD !important;
  color: rgba(0, 0, 0, 0.87);
}

.blue-50-fg {
  color: #E3F2FD !important;
}

.blue-50-border {
  border-color: #E3F2FD !important;
}

.blue-50-border-top {
  border-top-color: #E3F2FD !important;
}

.blue-50-border-right {
  border-right-color: #E3F2FD !important;
}

.blue-50-border-bottom {
  border-bottom-color: #E3F2FD !important;
}

.blue-50-border-left {
  border-left-color: #E3F2FD !important;
}

.blue-100-bg {
  background-color: #BBDEFB !important;
}

.md-blue-100-bg {
  background-color: #BBDEFB !important;
  color: rgba(0, 0, 0, 0.87);
}

.blue-100-fg {
  color: #BBDEFB !important;
}

.blue-100-border {
  border-color: #BBDEFB !important;
}

.blue-100-border-top {
  border-top-color: #BBDEFB !important;
}

.blue-100-border-right {
  border-right-color: #BBDEFB !important;
}

.blue-100-border-bottom {
  border-bottom-color: #BBDEFB !important;
}

.blue-100-border-left {
  border-left-color: #BBDEFB !important;
}

.blue-200-bg {
  background-color: #90CAF9 !important;
}

.md-blue-200-bg {
  background-color: #90CAF9 !important;
  color: rgba(0, 0, 0, 0.87);
}

.blue-200-fg {
  color: #90CAF9 !important;
}

.blue-200-border {
  border-color: #90CAF9 !important;
}

.blue-200-border-top {
  border-top-color: #90CAF9 !important;
}

.blue-200-border-right {
  border-right-color: #90CAF9 !important;
}

.blue-200-border-bottom {
  border-bottom-color: #90CAF9 !important;
}

.blue-200-border-left {
  border-left-color: #90CAF9 !important;
}

.blue-300-bg {
  background-color: #64B5F6 !important;
}

.md-blue-300-bg {
  background-color: #64B5F6 !important;
  color: rgba(0, 0, 0, 0.87);
}

.blue-300-fg {
  color: #64B5F6 !important;
}

.blue-300-border {
  border-color: #64B5F6 !important;
}

.blue-300-border-top {
  border-top-color: #64B5F6 !important;
}

.blue-300-border-right {
  border-right-color: #64B5F6 !important;
}

.blue-300-border-bottom {
  border-bottom-color: #64B5F6 !important;
}

.blue-300-border-left {
  border-left-color: #64B5F6 !important;
}

.blue-400-bg {
  background-color: #42A5F5 !important;
}

.md-blue-400-bg {
  background-color: #42A5F5 !important;
  color: rgba(0, 0, 0, 0.87);
}

.blue-400-fg {
  color: #42A5F5 !important;
}

.blue-400-border {
  border-color: #42A5F5 !important;
}

.blue-400-border-top {
  border-top-color: #42A5F5 !important;
}

.blue-400-border-right {
  border-right-color: #42A5F5 !important;
}

.blue-400-border-bottom {
  border-bottom-color: #42A5F5 !important;
}

.blue-400-border-left {
  border-left-color: #42A5F5 !important;
}

.blue-500-bg {
  background-color: #2196F3 !important;
}

.md-blue-500-bg {
  background-color: #2196F3 !important;
  color: rgb(255, 255, 255);
}

.blue-500-fg {
  color: #2196F3 !important;
}

.blue-500-border {
  border-color: #2196F3 !important;
}

.blue-500-border-top {
  border-top-color: #2196F3 !important;
}

.blue-500-border-right {
  border-right-color: #2196F3 !important;
}

.blue-500-border-bottom {
  border-bottom-color: #2196F3 !important;
}

.blue-500-border-left {
  border-left-color: #2196F3 !important;
}

.md-blue-bg {
  background-color: #2196F3 !important;
  color: rgb(255, 255, 255);
}

.blue-bg {
  background-color: #2196F3 !important;
}

.blue-fg {
  color: #2196F3 !important;
}

.blue-border {
  border-color: #2196F3 !important;
}

.blue-border-top {
  border-top-color: #2196F3 !important;
}

.blue-border-right {
  border-right-color: #2196F3 !important;
}

.blue-border-bottom {
  border-bottom-color: #2196F3 !important;
}

.blue-border-left {
  border-left-color: #2196F3 !important;
}

.blue-600-bg {
  background-color: #1E88E5 !important;
}

.md-blue-600-bg {
  background-color: #1E88E5 !important;
  color: rgb(255, 255, 255);
}

.blue-600-fg {
  color: #1E88E5 !important;
}

.blue-600-border {
  border-color: #1E88E5 !important;
}

.blue-600-border-top {
  border-top-color: #1E88E5 !important;
}

.blue-600-border-right {
  border-right-color: #1E88E5 !important;
}

.blue-600-border-bottom {
  border-bottom-color: #1E88E5 !important;
}

.blue-600-border-left {
  border-left-color: #1E88E5 !important;
}

.blue-700-bg {
  background-color: #1976D2 !important;
}

.md-blue-700-bg {
  background-color: #1976D2 !important;
  color: rgb(255, 255, 255);
}

.blue-700-fg {
  color: #1976D2 !important;
}

.blue-700-border {
  border-color: #1976D2 !important;
}

.blue-700-border-top {
  border-top-color: #1976D2 !important;
}

.blue-700-border-right {
  border-right-color: #1976D2 !important;
}

.blue-700-border-bottom {
  border-bottom-color: #1976D2 !important;
}

.blue-700-border-left {
  border-left-color: #1976D2 !important;
}

.blue-800-bg {
  background-color: #1565C0 !important;
}

.md-blue-800-bg {
  background-color: #1565C0 !important;
  color: rgba(255, 255, 255, 0.87);
}

.blue-800-fg {
  color: #1565C0 !important;
}

.blue-800-border {
  border-color: #1565C0 !important;
}

.blue-800-border-top {
  border-top-color: #1565C0 !important;
}

.blue-800-border-right {
  border-right-color: #1565C0 !important;
}

.blue-800-border-bottom {
  border-bottom-color: #1565C0 !important;
}

.blue-800-border-left {
  border-left-color: #1565C0 !important;
}

.blue-900-bg {
  background-color: #0D47A1 !important;
}

.md-blue-900-bg {
  background-color: #0D47A1 !important;
  color: rgba(255, 255, 255, 0.87);
}

.blue-900-fg {
  color: #0D47A1 !important;
}

.blue-900-border {
  border-color: #0D47A1 !important;
}

.blue-900-border-top {
  border-top-color: #0D47A1 !important;
}

.blue-900-border-right {
  border-right-color: #0D47A1 !important;
}

.blue-900-border-bottom {
  border-bottom-color: #0D47A1 !important;
}

.blue-900-border-left {
  border-left-color: #0D47A1 !important;
}

.blue-A100-bg {
  background-color: #82B1FF !important;
}

.md-blue-A100-bg {
  background-color: #82B1FF !important;
  color: rgba(0, 0, 0, 0.87);
}

.blue-A100-fg {
  color: #82B1FF !important;
}

.blue-A100-border {
  border-color: #82B1FF !important;
}

.blue-A100-border-top {
  border-top-color: #82B1FF !important;
}

.blue-A100-border-right {
  border-right-color: #82B1FF !important;
}

.blue-A100-border-bottom {
  border-bottom-color: #82B1FF !important;
}

.blue-A100-border-left {
  border-left-color: #82B1FF !important;
}

.blue-A200-bg {
  background-color: #448AFF !important;
}

.md-blue-A200-bg {
  background-color: #448AFF !important;
  color: rgb(255, 255, 255);
}

.blue-A200-fg {
  color: #448AFF !important;
}

.blue-A200-border {
  border-color: #448AFF !important;
}

.blue-A200-border-top {
  border-top-color: #448AFF !important;
}

.blue-A200-border-right {
  border-right-color: #448AFF !important;
}

.blue-A200-border-bottom {
  border-bottom-color: #448AFF !important;
}

.blue-A200-border-left {
  border-left-color: #448AFF !important;
}

.blue-A400-bg {
  background-color: #2979FF !important;
}

.md-blue-A400-bg {
  background-color: #2979FF !important;
  color: rgb(255, 255, 255);
}

.blue-A400-fg {
  color: #2979FF !important;
}

.blue-A400-border {
  border-color: #2979FF !important;
}

.blue-A400-border-top {
  border-top-color: #2979FF !important;
}

.blue-A400-border-right {
  border-right-color: #2979FF !important;
}

.blue-A400-border-bottom {
  border-bottom-color: #2979FF !important;
}

.blue-A400-border-left {
  border-left-color: #2979FF !important;
}

.blue-A700-bg {
  background-color: #2962FF !important;
}

.md-blue-A700-bg {
  background-color: #2962FF !important;
  color: rgb(255, 255, 255);
}

.blue-A700-fg {
  color: #2962FF !important;
}

.blue-A700-border {
  border-color: #2962FF !important;
}

.blue-A700-border-top {
  border-top-color: #2962FF !important;
}

.blue-A700-border-right {
  border-right-color: #2962FF !important;
}

.blue-A700-border-bottom {
  border-bottom-color: #2962FF !important;
}

.blue-A700-border-left {
  border-left-color: #2962FF !important;
}

.light-blue-50-bg {
  background-color: #E1F5FE !important;
}

.md-light-blue-50-bg {
  background-color: #E1F5FE !important;
  color: rgba(0, 0, 0, 0.87);
}

.light-blue-50-fg {
  color: #E1F5FE !important;
}

.light-blue-50-border {
  border-color: #E1F5FE !important;
}

.light-blue-50-border-top {
  border-top-color: #E1F5FE !important;
}

.light-blue-50-border-right {
  border-right-color: #E1F5FE !important;
}

.light-blue-50-border-bottom {
  border-bottom-color: #E1F5FE !important;
}

.light-blue-50-border-left {
  border-left-color: #E1F5FE !important;
}

.light-blue-100-bg {
  background-color: #B3E5FC !important;
}

.md-light-blue-100-bg {
  background-color: #B3E5FC !important;
  color: rgba(0, 0, 0, 0.87);
}

.light-blue-100-fg {
  color: #B3E5FC !important;
}

.light-blue-100-border {
  border-color: #B3E5FC !important;
}

.light-blue-100-border-top {
  border-top-color: #B3E5FC !important;
}

.light-blue-100-border-right {
  border-right-color: #B3E5FC !important;
}

.light-blue-100-border-bottom {
  border-bottom-color: #B3E5FC !important;
}

.light-blue-100-border-left {
  border-left-color: #B3E5FC !important;
}

.light-blue-200-bg {
  background-color: #81D4FA !important;
}

.md-light-blue-200-bg {
  background-color: #81D4FA !important;
  color: rgba(0, 0, 0, 0.87);
}

.light-blue-200-fg {
  color: #81D4FA !important;
}

.light-blue-200-border {
  border-color: #81D4FA !important;
}

.light-blue-200-border-top {
  border-top-color: #81D4FA !important;
}

.light-blue-200-border-right {
  border-right-color: #81D4FA !important;
}

.light-blue-200-border-bottom {
  border-bottom-color: #81D4FA !important;
}

.light-blue-200-border-left {
  border-left-color: #81D4FA !important;
}

.light-blue-300-bg {
  background-color: #4FC3F7 !important;
}

.md-light-blue-300-bg {
  background-color: #4FC3F7 !important;
  color: rgba(0, 0, 0, 0.87);
}

.light-blue-300-fg {
  color: #4FC3F7 !important;
}

.light-blue-300-border {
  border-color: #4FC3F7 !important;
}

.light-blue-300-border-top {
  border-top-color: #4FC3F7 !important;
}

.light-blue-300-border-right {
  border-right-color: #4FC3F7 !important;
}

.light-blue-300-border-bottom {
  border-bottom-color: #4FC3F7 !important;
}

.light-blue-300-border-left {
  border-left-color: #4FC3F7 !important;
}

.light-blue-400-bg {
  background-color: #29B6F6 !important;
}

.md-light-blue-400-bg {
  background-color: #29B6F6 !important;
  color: rgba(0, 0, 0, 0.87);
}

.light-blue-400-fg {
  color: #29B6F6 !important;
}

.light-blue-400-border {
  border-color: #29B6F6 !important;
}

.light-blue-400-border-top {
  border-top-color: #29B6F6 !important;
}

.light-blue-400-border-right {
  border-right-color: #29B6F6 !important;
}

.light-blue-400-border-bottom {
  border-bottom-color: #29B6F6 !important;
}

.light-blue-400-border-left {
  border-left-color: #29B6F6 !important;
}

.light-blue-500-bg {
  background-color: #03A9F4 !important;
}

.md-light-blue-500-bg {
  background-color: #03A9F4 !important;
  color: rgba(0, 0, 0, 0.87);
}

.light-blue-500-fg {
  color: #03A9F4 !important;
}

.light-blue-500-border {
  border-color: #03A9F4 !important;
}

.light-blue-500-border-top {
  border-top-color: #03A9F4 !important;
}

.light-blue-500-border-right {
  border-right-color: #03A9F4 !important;
}

.light-blue-500-border-bottom {
  border-bottom-color: #03A9F4 !important;
}

.light-blue-500-border-left {
  border-left-color: #03A9F4 !important;
}

.md-light-blue-bg {
  background-color: #03A9F4 !important;
  color: rgba(0, 0, 0, 0.87);
}

.light-blue-bg {
  background-color: #03A9F4 !important;
}

.light-blue-fg {
  color: #03A9F4 !important;
}

.light-blue-border {
  border-color: #03A9F4 !important;
}

.light-blue-border-top {
  border-top-color: #03A9F4 !important;
}

.light-blue-border-right {
  border-right-color: #03A9F4 !important;
}

.light-blue-border-bottom {
  border-bottom-color: #03A9F4 !important;
}

.light-blue-border-left {
  border-left-color: #03A9F4 !important;
}

.light-blue-600-bg {
  background-color: #039BE5 !important;
}

.md-light-blue-600-bg {
  background-color: #039BE5 !important;
  color: rgb(255, 255, 255);
}

.light-blue-600-fg {
  color: #039BE5 !important;
}

.light-blue-600-border {
  border-color: #039BE5 !important;
}

.light-blue-600-border-top {
  border-top-color: #039BE5 !important;
}

.light-blue-600-border-right {
  border-right-color: #039BE5 !important;
}

.light-blue-600-border-bottom {
  border-bottom-color: #039BE5 !important;
}

.light-blue-600-border-left {
  border-left-color: #039BE5 !important;
}

.light-blue-700-bg {
  background-color: #0288D1 !important;
}

.md-light-blue-700-bg {
  background-color: #0288D1 !important;
  color: rgb(255, 255, 255);
}

.light-blue-700-fg {
  color: #0288D1 !important;
}

.light-blue-700-border {
  border-color: #0288D1 !important;
}

.light-blue-700-border-top {
  border-top-color: #0288D1 !important;
}

.light-blue-700-border-right {
  border-right-color: #0288D1 !important;
}

.light-blue-700-border-bottom {
  border-bottom-color: #0288D1 !important;
}

.light-blue-700-border-left {
  border-left-color: #0288D1 !important;
}

.light-blue-800-bg {
  background-color: #0277BD !important;
}

.md-light-blue-800-bg {
  background-color: #0277BD !important;
  color: rgb(255, 255, 255);
}

.light-blue-800-fg {
  color: #0277BD !important;
}

.light-blue-800-border {
  border-color: #0277BD !important;
}

.light-blue-800-border-top {
  border-top-color: #0277BD !important;
}

.light-blue-800-border-right {
  border-right-color: #0277BD !important;
}

.light-blue-800-border-bottom {
  border-bottom-color: #0277BD !important;
}

.light-blue-800-border-left {
  border-left-color: #0277BD !important;
}

.light-blue-900-bg {
  background-color: #01579B !important;
}

.md-light-blue-900-bg {
  background-color: #01579B !important;
  color: rgb(255, 255, 255);
}

.light-blue-900-fg {
  color: #01579B !important;
}

.light-blue-900-border {
  border-color: #01579B !important;
}

.light-blue-900-border-top {
  border-top-color: #01579B !important;
}

.light-blue-900-border-right {
  border-right-color: #01579B !important;
}

.light-blue-900-border-bottom {
  border-bottom-color: #01579B !important;
}

.light-blue-900-border-left {
  border-left-color: #01579B !important;
}

.light-blue-A100-bg {
  background-color: #80D8FF !important;
}

.md-light-blue-A100-bg {
  background-color: #80D8FF !important;
  color: rgba(0, 0, 0, 0.87);
}

.light-blue-A100-fg {
  color: #80D8FF !important;
}

.light-blue-A100-border {
  border-color: #80D8FF !important;
}

.light-blue-A100-border-top {
  border-top-color: #80D8FF !important;
}

.light-blue-A100-border-right {
  border-right-color: #80D8FF !important;
}

.light-blue-A100-border-bottom {
  border-bottom-color: #80D8FF !important;
}

.light-blue-A100-border-left {
  border-left-color: #80D8FF !important;
}

.light-blue-A200-bg {
  background-color: #40C4FF !important;
}

.md-light-blue-A200-bg {
  background-color: #40C4FF !important;
  color: rgba(0, 0, 0, 0.87);
}

.light-blue-A200-fg {
  color: #40C4FF !important;
}

.light-blue-A200-border {
  border-color: #40C4FF !important;
}

.light-blue-A200-border-top {
  border-top-color: #40C4FF !important;
}

.light-blue-A200-border-right {
  border-right-color: #40C4FF !important;
}

.light-blue-A200-border-bottom {
  border-bottom-color: #40C4FF !important;
}

.light-blue-A200-border-left {
  border-left-color: #40C4FF !important;
}

.light-blue-A400-bg {
  background-color: #00B0FF !important;
}

.md-light-blue-A400-bg {
  background-color: #00B0FF !important;
  color: rgba(0, 0, 0, 0.87);
}

.light-blue-A400-fg {
  color: #00B0FF !important;
}

.light-blue-A400-border {
  border-color: #00B0FF !important;
}

.light-blue-A400-border-top {
  border-top-color: #00B0FF !important;
}

.light-blue-A400-border-right {
  border-right-color: #00B0FF !important;
}

.light-blue-A400-border-bottom {
  border-bottom-color: #00B0FF !important;
}

.light-blue-A400-border-left {
  border-left-color: #00B0FF !important;
}

.light-blue-A700-bg {
  background-color: #0091EA !important;
}

.md-light-blue-A700-bg {
  background-color: #0091EA !important;
  color: rgb(255, 255, 255);
}

.light-blue-A700-fg {
  color: #0091EA !important;
}

.light-blue-A700-border {
  border-color: #0091EA !important;
}

.light-blue-A700-border-top {
  border-top-color: #0091EA !important;
}

.light-blue-A700-border-right {
  border-right-color: #0091EA !important;
}

.light-blue-A700-border-bottom {
  border-bottom-color: #0091EA !important;
}

.light-blue-A700-border-left {
  border-left-color: #0091EA !important;
}

.cyan-50-bg {
  background-color: #E0F7FA !important;
}

.md-cyan-50-bg {
  background-color: #E0F7FA !important;
  color: rgba(0, 0, 0, 0.87);
}

.cyan-50-fg {
  color: #E0F7FA !important;
}

.cyan-50-border {
  border-color: #E0F7FA !important;
}

.cyan-50-border-top {
  border-top-color: #E0F7FA !important;
}

.cyan-50-border-right {
  border-right-color: #E0F7FA !important;
}

.cyan-50-border-bottom {
  border-bottom-color: #E0F7FA !important;
}

.cyan-50-border-left {
  border-left-color: #E0F7FA !important;
}

.cyan-100-bg {
  background-color: #B2EBF2 !important;
}

.md-cyan-100-bg {
  background-color: #B2EBF2 !important;
  color: rgba(0, 0, 0, 0.87);
}

.cyan-100-fg {
  color: #B2EBF2 !important;
}

.cyan-100-border {
  border-color: #B2EBF2 !important;
}

.cyan-100-border-top {
  border-top-color: #B2EBF2 !important;
}

.cyan-100-border-right {
  border-right-color: #B2EBF2 !important;
}

.cyan-100-border-bottom {
  border-bottom-color: #B2EBF2 !important;
}

.cyan-100-border-left {
  border-left-color: #B2EBF2 !important;
}

.cyan-200-bg {
  background-color: #80DEEA !important;
}

.md-cyan-200-bg {
  background-color: #80DEEA !important;
  color: rgba(0, 0, 0, 0.87);
}

.cyan-200-fg {
  color: #80DEEA !important;
}

.cyan-200-border {
  border-color: #80DEEA !important;
}

.cyan-200-border-top {
  border-top-color: #80DEEA !important;
}

.cyan-200-border-right {
  border-right-color: #80DEEA !important;
}

.cyan-200-border-bottom {
  border-bottom-color: #80DEEA !important;
}

.cyan-200-border-left {
  border-left-color: #80DEEA !important;
}

.cyan-300-bg {
  background-color: #4DD0E1 !important;
}

.md-cyan-300-bg {
  background-color: #4DD0E1 !important;
  color: rgba(0, 0, 0, 0.87);
}

.cyan-300-fg {
  color: #4DD0E1 !important;
}

.cyan-300-border {
  border-color: #4DD0E1 !important;
}

.cyan-300-border-top {
  border-top-color: #4DD0E1 !important;
}

.cyan-300-border-right {
  border-right-color: #4DD0E1 !important;
}

.cyan-300-border-bottom {
  border-bottom-color: #4DD0E1 !important;
}

.cyan-300-border-left {
  border-left-color: #4DD0E1 !important;
}

.cyan-400-bg {
  background-color: #26C6DA !important;
}

.md-cyan-400-bg {
  background-color: #26C6DA !important;
  color: rgba(0, 0, 0, 0.87);
}

.cyan-400-fg {
  color: #26C6DA !important;
}

.cyan-400-border {
  border-color: #26C6DA !important;
}

.cyan-400-border-top {
  border-top-color: #26C6DA !important;
}

.cyan-400-border-right {
  border-right-color: #26C6DA !important;
}

.cyan-400-border-bottom {
  border-bottom-color: #26C6DA !important;
}

.cyan-400-border-left {
  border-left-color: #26C6DA !important;
}

.cyan-500-bg {
  background-color: #00BCD4 !important;
}

.md-cyan-500-bg {
  background-color: #00BCD4 !important;
  color: rgba(0, 0, 0, 0.87);
}

.cyan-500-fg {
  color: #00BCD4 !important;
}

.cyan-500-border {
  border-color: #00BCD4 !important;
}

.cyan-500-border-top {
  border-top-color: #00BCD4 !important;
}

.cyan-500-border-right {
  border-right-color: #00BCD4 !important;
}

.cyan-500-border-bottom {
  border-bottom-color: #00BCD4 !important;
}

.cyan-500-border-left {
  border-left-color: #00BCD4 !important;
}

.md-cyan-bg {
  background-color: #00BCD4 !important;
  color: rgba(0, 0, 0, 0.87);
}

.cyan-bg {
  background-color: #00BCD4 !important;
}

.cyan-fg {
  color: #00BCD4 !important;
}

.cyan-border {
  border-color: #00BCD4 !important;
}

.cyan-border-top {
  border-top-color: #00BCD4 !important;
}

.cyan-border-right {
  border-right-color: #00BCD4 !important;
}

.cyan-border-bottom {
  border-bottom-color: #00BCD4 !important;
}

.cyan-border-left {
  border-left-color: #00BCD4 !important;
}

.cyan-600-bg {
  background-color: #00ACC1 !important;
}

.md-cyan-600-bg {
  background-color: #00ACC1 !important;
  color: rgba(0, 0, 0, 0.87);
}

.cyan-600-fg {
  color: #00ACC1 !important;
}

.cyan-600-border {
  border-color: #00ACC1 !important;
}

.cyan-600-border-top {
  border-top-color: #00ACC1 !important;
}

.cyan-600-border-right {
  border-right-color: #00ACC1 !important;
}

.cyan-600-border-bottom {
  border-bottom-color: #00ACC1 !important;
}

.cyan-600-border-left {
  border-left-color: #00ACC1 !important;
}

.cyan-700-bg {
  background-color: #0097A7 !important;
}

.md-cyan-700-bg {
  background-color: #0097A7 !important;
  color: rgb(255, 255, 255);
}

.cyan-700-fg {
  color: #0097A7 !important;
}

.cyan-700-border {
  border-color: #0097A7 !important;
}

.cyan-700-border-top {
  border-top-color: #0097A7 !important;
}

.cyan-700-border-right {
  border-right-color: #0097A7 !important;
}

.cyan-700-border-bottom {
  border-bottom-color: #0097A7 !important;
}

.cyan-700-border-left {
  border-left-color: #0097A7 !important;
}

.cyan-800-bg {
  background-color: #00838F !important;
}

.md-cyan-800-bg {
  background-color: #00838F !important;
  color: rgb(255, 255, 255);
}

.cyan-800-fg {
  color: #00838F !important;
}

.cyan-800-border {
  border-color: #00838F !important;
}

.cyan-800-border-top {
  border-top-color: #00838F !important;
}

.cyan-800-border-right {
  border-right-color: #00838F !important;
}

.cyan-800-border-bottom {
  border-bottom-color: #00838F !important;
}

.cyan-800-border-left {
  border-left-color: #00838F !important;
}

.cyan-900-bg {
  background-color: #006064 !important;
}

.md-cyan-900-bg {
  background-color: #006064 !important;
  color: rgb(255, 255, 255);
}

.cyan-900-fg {
  color: #006064 !important;
}

.cyan-900-border {
  border-color: #006064 !important;
}

.cyan-900-border-top {
  border-top-color: #006064 !important;
}

.cyan-900-border-right {
  border-right-color: #006064 !important;
}

.cyan-900-border-bottom {
  border-bottom-color: #006064 !important;
}

.cyan-900-border-left {
  border-left-color: #006064 !important;
}

.cyan-A100-bg {
  background-color: #84FFFF !important;
}

.md-cyan-A100-bg {
  background-color: #84FFFF !important;
  color: rgba(0, 0, 0, 0.87);
}

.cyan-A100-fg {
  color: #84FFFF !important;
}

.cyan-A100-border {
  border-color: #84FFFF !important;
}

.cyan-A100-border-top {
  border-top-color: #84FFFF !important;
}

.cyan-A100-border-right {
  border-right-color: #84FFFF !important;
}

.cyan-A100-border-bottom {
  border-bottom-color: #84FFFF !important;
}

.cyan-A100-border-left {
  border-left-color: #84FFFF !important;
}

.cyan-A200-bg {
  background-color: #18FFFF !important;
}

.md-cyan-A200-bg {
  background-color: #18FFFF !important;
  color: rgba(0, 0, 0, 0.87);
}

.cyan-A200-fg {
  color: #18FFFF !important;
}

.cyan-A200-border {
  border-color: #18FFFF !important;
}

.cyan-A200-border-top {
  border-top-color: #18FFFF !important;
}

.cyan-A200-border-right {
  border-right-color: #18FFFF !important;
}

.cyan-A200-border-bottom {
  border-bottom-color: #18FFFF !important;
}

.cyan-A200-border-left {
  border-left-color: #18FFFF !important;
}

.cyan-A400-bg {
  background-color: #00E5FF !important;
}

.md-cyan-A400-bg {
  background-color: #00E5FF !important;
  color: rgba(0, 0, 0, 0.87);
}

.cyan-A400-fg {
  color: #00E5FF !important;
}

.cyan-A400-border {
  border-color: #00E5FF !important;
}

.cyan-A400-border-top {
  border-top-color: #00E5FF !important;
}

.cyan-A400-border-right {
  border-right-color: #00E5FF !important;
}

.cyan-A400-border-bottom {
  border-bottom-color: #00E5FF !important;
}

.cyan-A400-border-left {
  border-left-color: #00E5FF !important;
}

.cyan-A700-bg {
  background-color: #00B8D4 !important;
}

.md-cyan-A700-bg {
  background-color: #00B8D4 !important;
  color: rgba(0, 0, 0, 0.87);
}

.cyan-A700-fg {
  color: #00B8D4 !important;
}

.cyan-A700-border {
  border-color: #00B8D4 !important;
}

.cyan-A700-border-top {
  border-top-color: #00B8D4 !important;
}

.cyan-A700-border-right {
  border-right-color: #00B8D4 !important;
}

.cyan-A700-border-bottom {
  border-bottom-color: #00B8D4 !important;
}

.cyan-A700-border-left {
  border-left-color: #00B8D4 !important;
}

.teal-50-bg {
  background-color: #E0F2F1 !important;
}

.md-teal-50-bg {
  background-color: #E0F2F1 !important;
  color: rgba(0, 0, 0, 0.87);
}

.teal-50-fg {
  color: #E0F2F1 !important;
}

.teal-50-border {
  border-color: #E0F2F1 !important;
}

.teal-50-border-top {
  border-top-color: #E0F2F1 !important;
}

.teal-50-border-right {
  border-right-color: #E0F2F1 !important;
}

.teal-50-border-bottom {
  border-bottom-color: #E0F2F1 !important;
}

.teal-50-border-left {
  border-left-color: #E0F2F1 !important;
}

.teal-100-bg {
  background-color: #B2DFDB !important;
}

.md-teal-100-bg {
  background-color: #B2DFDB !important;
  color: rgba(0, 0, 0, 0.87);
}

.teal-100-fg {
  color: #B2DFDB !important;
}

.teal-100-border {
  border-color: #B2DFDB !important;
}

.teal-100-border-top {
  border-top-color: #B2DFDB !important;
}

.teal-100-border-right {
  border-right-color: #B2DFDB !important;
}

.teal-100-border-bottom {
  border-bottom-color: #B2DFDB !important;
}

.teal-100-border-left {
  border-left-color: #B2DFDB !important;
}

.teal-200-bg {
  background-color: #80CBC4 !important;
}

.md-teal-200-bg {
  background-color: #80CBC4 !important;
  color: rgba(0, 0, 0, 0.87);
}

.teal-200-fg {
  color: #80CBC4 !important;
}

.teal-200-border {
  border-color: #80CBC4 !important;
}

.teal-200-border-top {
  border-top-color: #80CBC4 !important;
}

.teal-200-border-right {
  border-right-color: #80CBC4 !important;
}

.teal-200-border-bottom {
  border-bottom-color: #80CBC4 !important;
}

.teal-200-border-left {
  border-left-color: #80CBC4 !important;
}

.teal-300-bg {
  background-color: #4DB6AC !important;
}

.md-teal-300-bg {
  background-color: #4DB6AC !important;
  color: rgba(0, 0, 0, 0.87);
}

.teal-300-fg {
  color: #4DB6AC !important;
}

.teal-300-border {
  border-color: #4DB6AC !important;
}

.teal-300-border-top {
  border-top-color: #4DB6AC !important;
}

.teal-300-border-right {
  border-right-color: #4DB6AC !important;
}

.teal-300-border-bottom {
  border-bottom-color: #4DB6AC !important;
}

.teal-300-border-left {
  border-left-color: #4DB6AC !important;
}

.teal-400-bg {
  background-color: #26A69A !important;
}

.md-teal-400-bg {
  background-color: #26A69A !important;
  color: rgba(0, 0, 0, 0.87);
}

.teal-400-fg {
  color: #26A69A !important;
}

.teal-400-border {
  border-color: #26A69A !important;
}

.teal-400-border-top {
  border-top-color: #26A69A !important;
}

.teal-400-border-right {
  border-right-color: #26A69A !important;
}

.teal-400-border-bottom {
  border-bottom-color: #26A69A !important;
}

.teal-400-border-left {
  border-left-color: #26A69A !important;
}

.teal-500-bg {
  background-color: #009688 !important;
}

.md-teal-500-bg {
  background-color: #009688 !important;
  color: rgb(255, 255, 255);
}

.teal-500-fg {
  color: #009688 !important;
}

.teal-500-border {
  border-color: #009688 !important;
}

.teal-500-border-top {
  border-top-color: #009688 !important;
}

.teal-500-border-right {
  border-right-color: #009688 !important;
}

.teal-500-border-bottom {
  border-bottom-color: #009688 !important;
}

.teal-500-border-left {
  border-left-color: #009688 !important;
}

.md-teal-bg {
  background-color: #009688 !important;
  color: rgb(255, 255, 255);
}

.teal-bg {
  background-color: #009688 !important;
}

.teal-fg {
  color: #009688 !important;
}

.teal-border {
  border-color: #009688 !important;
}

.teal-border-top {
  border-top-color: #009688 !important;
}

.teal-border-right {
  border-right-color: #009688 !important;
}

.teal-border-bottom {
  border-bottom-color: #009688 !important;
}

.teal-border-left {
  border-left-color: #009688 !important;
}

.teal-600-bg {
  background-color: #00897B !important;
}

.md-teal-600-bg {
  background-color: #00897B !important;
  color: rgb(255, 255, 255);
}

.teal-600-fg {
  color: #00897B !important;
}

.teal-600-border {
  border-color: #00897B !important;
}

.teal-600-border-top {
  border-top-color: #00897B !important;
}

.teal-600-border-right {
  border-right-color: #00897B !important;
}

.teal-600-border-bottom {
  border-bottom-color: #00897B !important;
}

.teal-600-border-left {
  border-left-color: #00897B !important;
}

.teal-700-bg {
  background-color: #00796B !important;
}

.md-teal-700-bg {
  background-color: #00796B !important;
  color: rgb(255, 255, 255);
}

.teal-700-fg {
  color: #00796B !important;
}

.teal-700-border {
  border-color: #00796B !important;
}

.teal-700-border-top {
  border-top-color: #00796B !important;
}

.teal-700-border-right {
  border-right-color: #00796B !important;
}

.teal-700-border-bottom {
  border-bottom-color: #00796B !important;
}

.teal-700-border-left {
  border-left-color: #00796B !important;
}

.teal-800-bg {
  background-color: #00695C !important;
}

.md-teal-800-bg {
  background-color: #00695C !important;
  color: rgba(255, 255, 255, 0.87);
}

.teal-800-fg {
  color: #00695C !important;
}

.teal-800-border {
  border-color: #00695C !important;
}

.teal-800-border-top {
  border-top-color: #00695C !important;
}

.teal-800-border-right {
  border-right-color: #00695C !important;
}

.teal-800-border-bottom {
  border-bottom-color: #00695C !important;
}

.teal-800-border-left {
  border-left-color: #00695C !important;
}

.teal-900-bg {
  background-color: #004D40 !important;
}

.md-teal-900-bg {
  background-color: #004D40 !important;
  color: rgba(255, 255, 255, 0.87);
}

.teal-900-fg {
  color: #004D40 !important;
}

.teal-900-border {
  border-color: #004D40 !important;
}

.teal-900-border-top {
  border-top-color: #004D40 !important;
}

.teal-900-border-right {
  border-right-color: #004D40 !important;
}

.teal-900-border-bottom {
  border-bottom-color: #004D40 !important;
}

.teal-900-border-left {
  border-left-color: #004D40 !important;
}

.teal-A100-bg {
  background-color: #A7FFEB !important;
}

.md-teal-A100-bg {
  background-color: #A7FFEB !important;
  color: rgba(0, 0, 0, 0.87);
}

.teal-A100-fg {
  color: #A7FFEB !important;
}

.teal-A100-border {
  border-color: #A7FFEB !important;
}

.teal-A100-border-top {
  border-top-color: #A7FFEB !important;
}

.teal-A100-border-right {
  border-right-color: #A7FFEB !important;
}

.teal-A100-border-bottom {
  border-bottom-color: #A7FFEB !important;
}

.teal-A100-border-left {
  border-left-color: #A7FFEB !important;
}

.teal-A200-bg {
  background-color: #64FFDA !important;
}

.md-teal-A200-bg {
  background-color: #64FFDA !important;
  color: rgba(0, 0, 0, 0.87);
}

.teal-A200-fg {
  color: #64FFDA !important;
}

.teal-A200-border {
  border-color: #64FFDA !important;
}

.teal-A200-border-top {
  border-top-color: #64FFDA !important;
}

.teal-A200-border-right {
  border-right-color: #64FFDA !important;
}

.teal-A200-border-bottom {
  border-bottom-color: #64FFDA !important;
}

.teal-A200-border-left {
  border-left-color: #64FFDA !important;
}

.teal-A400-bg {
  background-color: #1DE9B6 !important;
}

.md-teal-A400-bg {
  background-color: #1DE9B6 !important;
  color: rgba(0, 0, 0, 0.87);
}

.teal-A400-fg {
  color: #1DE9B6 !important;
}

.teal-A400-border {
  border-color: #1DE9B6 !important;
}

.teal-A400-border-top {
  border-top-color: #1DE9B6 !important;
}

.teal-A400-border-right {
  border-right-color: #1DE9B6 !important;
}

.teal-A400-border-bottom {
  border-bottom-color: #1DE9B6 !important;
}

.teal-A400-border-left {
  border-left-color: #1DE9B6 !important;
}

.teal-A700-bg {
  background-color: #00BFA5 !important;
}

.md-teal-A700-bg {
  background-color: #00BFA5 !important;
  color: rgba(0, 0, 0, 0.87);
}

.teal-A700-fg {
  color: #00BFA5 !important;
}

.teal-A700-border {
  border-color: #00BFA5 !important;
}

.teal-A700-border-top {
  border-top-color: #00BFA5 !important;
}

.teal-A700-border-right {
  border-right-color: #00BFA5 !important;
}

.teal-A700-border-bottom {
  border-bottom-color: #00BFA5 !important;
}

.teal-A700-border-left {
  border-left-color: #00BFA5 !important;
}

.green-50-bg {
  background-color: #E8F5E9 !important;
}

.md-green-50-bg {
  background-color: #E8F5E9 !important;
  color: rgba(0, 0, 0, 0.87);
}

.green-50-fg {
  color: #E8F5E9 !important;
}

.green-50-border {
  border-color: #E8F5E9 !important;
}

.green-50-border-top {
  border-top-color: #E8F5E9 !important;
}

.green-50-border-right {
  border-right-color: #E8F5E9 !important;
}

.green-50-border-bottom {
  border-bottom-color: #E8F5E9 !important;
}

.green-50-border-left {
  border-left-color: #E8F5E9 !important;
}

.green-100-bg {
  background-color: #C8E6C9 !important;
}

.md-green-100-bg {
  background-color: #C8E6C9 !important;
  color: rgba(0, 0, 0, 0.87);
}

.green-100-fg {
  color: #C8E6C9 !important;
}

.green-100-border {
  border-color: #C8E6C9 !important;
}

.green-100-border-top {
  border-top-color: #C8E6C9 !important;
}

.green-100-border-right {
  border-right-color: #C8E6C9 !important;
}

.green-100-border-bottom {
  border-bottom-color: #C8E6C9 !important;
}

.green-100-border-left {
  border-left-color: #C8E6C9 !important;
}

.green-200-bg {
  background-color: #A5D6A7 !important;
}

.md-green-200-bg {
  background-color: #A5D6A7 !important;
  color: rgba(0, 0, 0, 0.87);
}

.green-200-fg {
  color: #A5D6A7 !important;
}

.green-200-border {
  border-color: #A5D6A7 !important;
}

.green-200-border-top {
  border-top-color: #A5D6A7 !important;
}

.green-200-border-right {
  border-right-color: #A5D6A7 !important;
}

.green-200-border-bottom {
  border-bottom-color: #A5D6A7 !important;
}

.green-200-border-left {
  border-left-color: #A5D6A7 !important;
}

.green-300-bg {
  background-color: #81C784 !important;
}

.md-green-300-bg {
  background-color: #81C784 !important;
  color: rgba(0, 0, 0, 0.87);
}

.green-300-fg {
  color: #81C784 !important;
}

.green-300-border {
  border-color: #81C784 !important;
}

.green-300-border-top {
  border-top-color: #81C784 !important;
}

.green-300-border-right {
  border-right-color: #81C784 !important;
}

.green-300-border-bottom {
  border-bottom-color: #81C784 !important;
}

.green-300-border-left {
  border-left-color: #81C784 !important;
}

.green-400-bg {
  background-color: #66BB6A !important;
}

.md-green-400-bg {
  background-color: #66BB6A !important;
  color: rgba(0, 0, 0, 0.87);
}

.green-400-fg {
  color: #66BB6A !important;
}

.green-400-border {
  border-color: #66BB6A !important;
}

.green-400-border-top {
  border-top-color: #66BB6A !important;
}

.green-400-border-right {
  border-right-color: #66BB6A !important;
}

.green-400-border-bottom {
  border-bottom-color: #66BB6A !important;
}

.green-400-border-left {
  border-left-color: #66BB6A !important;
}

.green-500-bg {
  background-color: #4CAF50 !important;
}

.md-green-500-bg {
  background-color: #4CAF50 !important;
  color: rgba(0, 0, 0, 0.87);
}

.green-500-fg {
  color: #4CAF50 !important;
}

.green-500-border {
  border-color: #4CAF50 !important;
}

.green-500-border-top {
  border-top-color: #4CAF50 !important;
}

.green-500-border-right {
  border-right-color: #4CAF50 !important;
}

.green-500-border-bottom {
  border-bottom-color: #4CAF50 !important;
}

.green-500-border-left {
  border-left-color: #4CAF50 !important;
}

.md-green-bg {
  background-color: #4CAF50 !important;
  color: rgba(0, 0, 0, 0.87);
}

.green-bg {
  background-color: #4CAF50 !important;
}

.green-fg {
  color: #4CAF50 !important;
}

.green-border {
  border-color: #4CAF50 !important;
}

.green-border-top {
  border-top-color: #4CAF50 !important;
}

.green-border-right {
  border-right-color: #4CAF50 !important;
}

.green-border-bottom {
  border-bottom-color: #4CAF50 !important;
}

.green-border-left {
  border-left-color: #4CAF50 !important;
}

.green-600-bg {
  background-color: #43A047 !important;
}

.md-green-600-bg {
  background-color: #43A047 !important;
  color: rgb(255, 255, 255);
}

.green-600-fg {
  color: #43A047 !important;
}

.green-600-border {
  border-color: #43A047 !important;
}

.green-600-border-top {
  border-top-color: #43A047 !important;
}

.green-600-border-right {
  border-right-color: #43A047 !important;
}

.green-600-border-bottom {
  border-bottom-color: #43A047 !important;
}

.green-600-border-left {
  border-left-color: #43A047 !important;
}

.green-700-bg {
  background-color: #388E3C !important;
}

.md-green-700-bg {
  background-color: #388E3C !important;
  color: rgb(255, 255, 255);
}

.green-700-fg {
  color: #388E3C !important;
}

.green-700-border {
  border-color: #388E3C !important;
}

.green-700-border-top {
  border-top-color: #388E3C !important;
}

.green-700-border-right {
  border-right-color: #388E3C !important;
}

.green-700-border-bottom {
  border-bottom-color: #388E3C !important;
}

.green-700-border-left {
  border-left-color: #388E3C !important;
}

.green-800-bg {
  background-color: #2E7D32 !important;
}

.md-green-800-bg {
  background-color: #2E7D32 !important;
  color: rgba(255, 255, 255, 0.87);
}

.green-800-fg {
  color: #2E7D32 !important;
}

.green-800-border {
  border-color: #2E7D32 !important;
}

.green-800-border-top {
  border-top-color: #2E7D32 !important;
}

.green-800-border-right {
  border-right-color: #2E7D32 !important;
}

.green-800-border-bottom {
  border-bottom-color: #2E7D32 !important;
}

.green-800-border-left {
  border-left-color: #2E7D32 !important;
}

.green-900-bg {
  background-color: #1B5E20 !important;
}

.md-green-900-bg {
  background-color: #1B5E20 !important;
  color: rgba(255, 255, 255, 0.87);
}

.green-900-fg {
  color: #1B5E20 !important;
}

.green-900-border {
  border-color: #1B5E20 !important;
}

.green-900-border-top {
  border-top-color: #1B5E20 !important;
}

.green-900-border-right {
  border-right-color: #1B5E20 !important;
}

.green-900-border-bottom {
  border-bottom-color: #1B5E20 !important;
}

.green-900-border-left {
  border-left-color: #1B5E20 !important;
}

.green-A100-bg {
  background-color: #B9F6CA !important;
}

.md-green-A100-bg {
  background-color: #B9F6CA !important;
  color: rgba(0, 0, 0, 0.87);
}

.green-A100-fg {
  color: #B9F6CA !important;
}

.green-A100-border {
  border-color: #B9F6CA !important;
}

.green-A100-border-top {
  border-top-color: #B9F6CA !important;
}

.green-A100-border-right {
  border-right-color: #B9F6CA !important;
}

.green-A100-border-bottom {
  border-bottom-color: #B9F6CA !important;
}

.green-A100-border-left {
  border-left-color: #B9F6CA !important;
}

.green-A200-bg {
  background-color: #69F0AE !important;
}

.md-green-A200-bg {
  background-color: #69F0AE !important;
  color: rgba(0, 0, 0, 0.87);
}

.green-A200-fg {
  color: #69F0AE !important;
}

.green-A200-border {
  border-color: #69F0AE !important;
}

.green-A200-border-top {
  border-top-color: #69F0AE !important;
}

.green-A200-border-right {
  border-right-color: #69F0AE !important;
}

.green-A200-border-bottom {
  border-bottom-color: #69F0AE !important;
}

.green-A200-border-left {
  border-left-color: #69F0AE !important;
}

.green-A400-bg {
  background-color: #00E676 !important;
}

.md-green-A400-bg {
  background-color: #00E676 !important;
  color: rgba(0, 0, 0, 0.87);
}

.green-A400-fg {
  color: #00E676 !important;
}

.green-A400-border {
  border-color: #00E676 !important;
}

.green-A400-border-top {
  border-top-color: #00E676 !important;
}

.green-A400-border-right {
  border-right-color: #00E676 !important;
}

.green-A400-border-bottom {
  border-bottom-color: #00E676 !important;
}

.green-A400-border-left {
  border-left-color: #00E676 !important;
}

.green-A700-bg {
  background-color: #00C853 !important;
}

.md-green-A700-bg {
  background-color: #00C853 !important;
  color: rgba(0, 0, 0, 0.87);
}

.green-A700-fg {
  color: #00C853 !important;
}

.green-A700-border {
  border-color: #00C853 !important;
}

.green-A700-border-top {
  border-top-color: #00C853 !important;
}

.green-A700-border-right {
  border-right-color: #00C853 !important;
}

.green-A700-border-bottom {
  border-bottom-color: #00C853 !important;
}

.green-A700-border-left {
  border-left-color: #00C853 !important;
}

.light-green-50-bg {
  background-color: #F1F8E9 !important;
}

.md-light-green-50-bg {
  background-color: #F1F8E9 !important;
  color: rgba(0, 0, 0, 0.87);
}

.light-green-50-fg {
  color: #F1F8E9 !important;
}

.light-green-50-border {
  border-color: #F1F8E9 !important;
}

.light-green-50-border-top {
  border-top-color: #F1F8E9 !important;
}

.light-green-50-border-right {
  border-right-color: #F1F8E9 !important;
}

.light-green-50-border-bottom {
  border-bottom-color: #F1F8E9 !important;
}

.light-green-50-border-left {
  border-left-color: #F1F8E9 !important;
}

.light-green-100-bg {
  background-color: #DCEDC8 !important;
}

.md-light-green-100-bg {
  background-color: #DCEDC8 !important;
  color: rgba(0, 0, 0, 0.87);
}

.light-green-100-fg {
  color: #DCEDC8 !important;
}

.light-green-100-border {
  border-color: #DCEDC8 !important;
}

.light-green-100-border-top {
  border-top-color: #DCEDC8 !important;
}

.light-green-100-border-right {
  border-right-color: #DCEDC8 !important;
}

.light-green-100-border-bottom {
  border-bottom-color: #DCEDC8 !important;
}

.light-green-100-border-left {
  border-left-color: #DCEDC8 !important;
}

.light-green-200-bg {
  background-color: #C5E1A5 !important;
}

.md-light-green-200-bg {
  background-color: #C5E1A5 !important;
  color: rgba(0, 0, 0, 0.87);
}

.light-green-200-fg {
  color: #C5E1A5 !important;
}

.light-green-200-border {
  border-color: #C5E1A5 !important;
}

.light-green-200-border-top {
  border-top-color: #C5E1A5 !important;
}

.light-green-200-border-right {
  border-right-color: #C5E1A5 !important;
}

.light-green-200-border-bottom {
  border-bottom-color: #C5E1A5 !important;
}

.light-green-200-border-left {
  border-left-color: #C5E1A5 !important;
}

.light-green-300-bg {
  background-color: #AED581 !important;
}

.md-light-green-300-bg {
  background-color: #AED581 !important;
  color: rgba(0, 0, 0, 0.87);
}

.light-green-300-fg {
  color: #AED581 !important;
}

.light-green-300-border {
  border-color: #AED581 !important;
}

.light-green-300-border-top {
  border-top-color: #AED581 !important;
}

.light-green-300-border-right {
  border-right-color: #AED581 !important;
}

.light-green-300-border-bottom {
  border-bottom-color: #AED581 !important;
}

.light-green-300-border-left {
  border-left-color: #AED581 !important;
}

.light-green-400-bg {
  background-color: #9CCC65 !important;
}

.md-light-green-400-bg {
  background-color: #9CCC65 !important;
  color: rgba(0, 0, 0, 0.87);
}

.light-green-400-fg {
  color: #9CCC65 !important;
}

.light-green-400-border {
  border-color: #9CCC65 !important;
}

.light-green-400-border-top {
  border-top-color: #9CCC65 !important;
}

.light-green-400-border-right {
  border-right-color: #9CCC65 !important;
}

.light-green-400-border-bottom {
  border-bottom-color: #9CCC65 !important;
}

.light-green-400-border-left {
  border-left-color: #9CCC65 !important;
}

.light-green-500-bg {
  background-color: #8BC34A !important;
}

.md-light-green-500-bg {
  background-color: #8BC34A !important;
  color: rgba(0, 0, 0, 0.87);
}

.light-green-500-fg {
  color: #8BC34A !important;
}

.light-green-500-border {
  border-color: #8BC34A !important;
}

.light-green-500-border-top {
  border-top-color: #8BC34A !important;
}

.light-green-500-border-right {
  border-right-color: #8BC34A !important;
}

.light-green-500-border-bottom {
  border-bottom-color: #8BC34A !important;
}

.light-green-500-border-left {
  border-left-color: #8BC34A !important;
}

.md-light-green-bg {
  background-color: #8BC34A !important;
  color: rgba(0, 0, 0, 0.87);
}

.light-green-bg {
  background-color: #8BC34A !important;
}

.light-green-fg {
  color: #8BC34A !important;
}

.light-green-border {
  border-color: #8BC34A !important;
}

.light-green-border-top {
  border-top-color: #8BC34A !important;
}

.light-green-border-right {
  border-right-color: #8BC34A !important;
}

.light-green-border-bottom {
  border-bottom-color: #8BC34A !important;
}

.light-green-border-left {
  border-left-color: #8BC34A !important;
}

.light-green-600-bg {
  background-color: #7CB342 !important;
}

.md-light-green-600-bg {
  background-color: #7CB342 !important;
  color: rgba(0, 0, 0, 0.87);
}

.light-green-600-fg {
  color: #7CB342 !important;
}

.light-green-600-border {
  border-color: #7CB342 !important;
}

.light-green-600-border-top {
  border-top-color: #7CB342 !important;
}

.light-green-600-border-right {
  border-right-color: #7CB342 !important;
}

.light-green-600-border-bottom {
  border-bottom-color: #7CB342 !important;
}

.light-green-600-border-left {
  border-left-color: #7CB342 !important;
}

.light-green-700-bg {
  background-color: #689F38 !important;
}

.md-light-green-700-bg {
  background-color: #689F38 !important;
  color: rgb(255, 255, 255);
}

.light-green-700-fg {
  color: #689F38 !important;
}

.light-green-700-border {
  border-color: #689F38 !important;
}

.light-green-700-border-top {
  border-top-color: #689F38 !important;
}

.light-green-700-border-right {
  border-right-color: #689F38 !important;
}

.light-green-700-border-bottom {
  border-bottom-color: #689F38 !important;
}

.light-green-700-border-left {
  border-left-color: #689F38 !important;
}

.light-green-800-bg {
  background-color: #558B2F !important;
}

.md-light-green-800-bg {
  background-color: #558B2F !important;
  color: rgb(255, 255, 255);
}

.light-green-800-fg {
  color: #558B2F !important;
}

.light-green-800-border {
  border-color: #558B2F !important;
}

.light-green-800-border-top {
  border-top-color: #558B2F !important;
}

.light-green-800-border-right {
  border-right-color: #558B2F !important;
}

.light-green-800-border-bottom {
  border-bottom-color: #558B2F !important;
}

.light-green-800-border-left {
  border-left-color: #558B2F !important;
}

.light-green-900-bg {
  background-color: #33691E !important;
}

.md-light-green-900-bg {
  background-color: #33691E !important;
  color: rgb(255, 255, 255);
}

.light-green-900-fg {
  color: #33691E !important;
}

.light-green-900-border {
  border-color: #33691E !important;
}

.light-green-900-border-top {
  border-top-color: #33691E !important;
}

.light-green-900-border-right {
  border-right-color: #33691E !important;
}

.light-green-900-border-bottom {
  border-bottom-color: #33691E !important;
}

.light-green-900-border-left {
  border-left-color: #33691E !important;
}

.light-green-A100-bg {
  background-color: #CCFF90 !important;
}

.md-light-green-A100-bg {
  background-color: #CCFF90 !important;
  color: rgba(0, 0, 0, 0.87);
}

.light-green-A100-fg {
  color: #CCFF90 !important;
}

.light-green-A100-border {
  border-color: #CCFF90 !important;
}

.light-green-A100-border-top {
  border-top-color: #CCFF90 !important;
}

.light-green-A100-border-right {
  border-right-color: #CCFF90 !important;
}

.light-green-A100-border-bottom {
  border-bottom-color: #CCFF90 !important;
}

.light-green-A100-border-left {
  border-left-color: #CCFF90 !important;
}

.light-green-A200-bg {
  background-color: #B2FF59 !important;
}

.md-light-green-A200-bg {
  background-color: #B2FF59 !important;
  color: rgba(0, 0, 0, 0.87);
}

.light-green-A200-fg {
  color: #B2FF59 !important;
}

.light-green-A200-border {
  border-color: #B2FF59 !important;
}

.light-green-A200-border-top {
  border-top-color: #B2FF59 !important;
}

.light-green-A200-border-right {
  border-right-color: #B2FF59 !important;
}

.light-green-A200-border-bottom {
  border-bottom-color: #B2FF59 !important;
}

.light-green-A200-border-left {
  border-left-color: #B2FF59 !important;
}

.light-green-A400-bg {
  background-color: #76FF03 !important;
}

.md-light-green-A400-bg {
  background-color: #76FF03 !important;
  color: rgba(0, 0, 0, 0.87);
}

.light-green-A400-fg {
  color: #76FF03 !important;
}

.light-green-A400-border {
  border-color: #76FF03 !important;
}

.light-green-A400-border-top {
  border-top-color: #76FF03 !important;
}

.light-green-A400-border-right {
  border-right-color: #76FF03 !important;
}

.light-green-A400-border-bottom {
  border-bottom-color: #76FF03 !important;
}

.light-green-A400-border-left {
  border-left-color: #76FF03 !important;
}

.light-green-A700-bg {
  background-color: #64DD17 !important;
}

.md-light-green-A700-bg {
  background-color: #64DD17 !important;
  color: rgba(0, 0, 0, 0.87);
}

.light-green-A700-fg {
  color: #64DD17 !important;
}

.light-green-A700-border {
  border-color: #64DD17 !important;
}

.light-green-A700-border-top {
  border-top-color: #64DD17 !important;
}

.light-green-A700-border-right {
  border-right-color: #64DD17 !important;
}

.light-green-A700-border-bottom {
  border-bottom-color: #64DD17 !important;
}

.light-green-A700-border-left {
  border-left-color: #64DD17 !important;
}

.lime-50-bg {
  background-color: #F9FBE7 !important;
}

.md-lime-50-bg {
  background-color: #F9FBE7 !important;
  color: rgba(0, 0, 0, 0.87);
}

.lime-50-fg {
  color: #F9FBE7 !important;
}

.lime-50-border {
  border-color: #F9FBE7 !important;
}

.lime-50-border-top {
  border-top-color: #F9FBE7 !important;
}

.lime-50-border-right {
  border-right-color: #F9FBE7 !important;
}

.lime-50-border-bottom {
  border-bottom-color: #F9FBE7 !important;
}

.lime-50-border-left {
  border-left-color: #F9FBE7 !important;
}

.lime-100-bg {
  background-color: #F0F4C3 !important;
}

.md-lime-100-bg {
  background-color: #F0F4C3 !important;
  color: rgba(0, 0, 0, 0.87);
}

.lime-100-fg {
  color: #F0F4C3 !important;
}

.lime-100-border {
  border-color: #F0F4C3 !important;
}

.lime-100-border-top {
  border-top-color: #F0F4C3 !important;
}

.lime-100-border-right {
  border-right-color: #F0F4C3 !important;
}

.lime-100-border-bottom {
  border-bottom-color: #F0F4C3 !important;
}

.lime-100-border-left {
  border-left-color: #F0F4C3 !important;
}

.lime-200-bg {
  background-color: #E6EE9C !important;
}

.md-lime-200-bg {
  background-color: #E6EE9C !important;
  color: rgba(0, 0, 0, 0.87);
}

.lime-200-fg {
  color: #E6EE9C !important;
}

.lime-200-border {
  border-color: #E6EE9C !important;
}

.lime-200-border-top {
  border-top-color: #E6EE9C !important;
}

.lime-200-border-right {
  border-right-color: #E6EE9C !important;
}

.lime-200-border-bottom {
  border-bottom-color: #E6EE9C !important;
}

.lime-200-border-left {
  border-left-color: #E6EE9C !important;
}

.lime-300-bg {
  background-color: #DCE775 !important;
}

.md-lime-300-bg {
  background-color: #DCE775 !important;
  color: rgba(0, 0, 0, 0.87);
}

.lime-300-fg {
  color: #DCE775 !important;
}

.lime-300-border {
  border-color: #DCE775 !important;
}

.lime-300-border-top {
  border-top-color: #DCE775 !important;
}

.lime-300-border-right {
  border-right-color: #DCE775 !important;
}

.lime-300-border-bottom {
  border-bottom-color: #DCE775 !important;
}

.lime-300-border-left {
  border-left-color: #DCE775 !important;
}

.lime-400-bg {
  background-color: #D4E157 !important;
}

.md-lime-400-bg {
  background-color: #D4E157 !important;
  color: rgba(0, 0, 0, 0.87);
}

.lime-400-fg {
  color: #D4E157 !important;
}

.lime-400-border {
  border-color: #D4E157 !important;
}

.lime-400-border-top {
  border-top-color: #D4E157 !important;
}

.lime-400-border-right {
  border-right-color: #D4E157 !important;
}

.lime-400-border-bottom {
  border-bottom-color: #D4E157 !important;
}

.lime-400-border-left {
  border-left-color: #D4E157 !important;
}

.lime-500-bg {
  background-color: #CDDC39 !important;
}

.md-lime-500-bg {
  background-color: #CDDC39 !important;
  color: rgba(0, 0, 0, 0.87);
}

.lime-500-fg {
  color: #CDDC39 !important;
}

.lime-500-border {
  border-color: #CDDC39 !important;
}

.lime-500-border-top {
  border-top-color: #CDDC39 !important;
}

.lime-500-border-right {
  border-right-color: #CDDC39 !important;
}

.lime-500-border-bottom {
  border-bottom-color: #CDDC39 !important;
}

.lime-500-border-left {
  border-left-color: #CDDC39 !important;
}

.md-lime-bg {
  background-color: #CDDC39 !important;
  color: rgba(0, 0, 0, 0.87);
}

.lime-bg {
  background-color: #CDDC39 !important;
}

.lime-fg {
  color: #CDDC39 !important;
}

.lime-border {
  border-color: #CDDC39 !important;
}

.lime-border-top {
  border-top-color: #CDDC39 !important;
}

.lime-border-right {
  border-right-color: #CDDC39 !important;
}

.lime-border-bottom {
  border-bottom-color: #CDDC39 !important;
}

.lime-border-left {
  border-left-color: #CDDC39 !important;
}

.lime-600-bg {
  background-color: #C0CA33 !important;
}

.md-lime-600-bg {
  background-color: #C0CA33 !important;
  color: rgba(0, 0, 0, 0.87);
}

.lime-600-fg {
  color: #C0CA33 !important;
}

.lime-600-border {
  border-color: #C0CA33 !important;
}

.lime-600-border-top {
  border-top-color: #C0CA33 !important;
}

.lime-600-border-right {
  border-right-color: #C0CA33 !important;
}

.lime-600-border-bottom {
  border-bottom-color: #C0CA33 !important;
}

.lime-600-border-left {
  border-left-color: #C0CA33 !important;
}

.lime-700-bg {
  background-color: #AFB42B !important;
}

.md-lime-700-bg {
  background-color: #AFB42B !important;
  color: rgba(0, 0, 0, 0.87);
}

.lime-700-fg {
  color: #AFB42B !important;
}

.lime-700-border {
  border-color: #AFB42B !important;
}

.lime-700-border-top {
  border-top-color: #AFB42B !important;
}

.lime-700-border-right {
  border-right-color: #AFB42B !important;
}

.lime-700-border-bottom {
  border-bottom-color: #AFB42B !important;
}

.lime-700-border-left {
  border-left-color: #AFB42B !important;
}

.lime-800-bg {
  background-color: #9E9D24 !important;
}

.md-lime-800-bg {
  background-color: #9E9D24 !important;
  color: rgba(0, 0, 0, 0.87);
}

.lime-800-fg {
  color: #9E9D24 !important;
}

.lime-800-border {
  border-color: #9E9D24 !important;
}

.lime-800-border-top {
  border-top-color: #9E9D24 !important;
}

.lime-800-border-right {
  border-right-color: #9E9D24 !important;
}

.lime-800-border-bottom {
  border-bottom-color: #9E9D24 !important;
}

.lime-800-border-left {
  border-left-color: #9E9D24 !important;
}

.lime-900-bg {
  background-color: #827717 !important;
}

.md-lime-900-bg {
  background-color: #827717 !important;
  color: rgb(255, 255, 255);
}

.lime-900-fg {
  color: #827717 !important;
}

.lime-900-border {
  border-color: #827717 !important;
}

.lime-900-border-top {
  border-top-color: #827717 !important;
}

.lime-900-border-right {
  border-right-color: #827717 !important;
}

.lime-900-border-bottom {
  border-bottom-color: #827717 !important;
}

.lime-900-border-left {
  border-left-color: #827717 !important;
}

.lime-A100-bg {
  background-color: #F4FF81 !important;
}

.md-lime-A100-bg {
  background-color: #F4FF81 !important;
  color: rgba(0, 0, 0, 0.87);
}

.lime-A100-fg {
  color: #F4FF81 !important;
}

.lime-A100-border {
  border-color: #F4FF81 !important;
}

.lime-A100-border-top {
  border-top-color: #F4FF81 !important;
}

.lime-A100-border-right {
  border-right-color: #F4FF81 !important;
}

.lime-A100-border-bottom {
  border-bottom-color: #F4FF81 !important;
}

.lime-A100-border-left {
  border-left-color: #F4FF81 !important;
}

.lime-A200-bg {
  background-color: #EEFF41 !important;
}

.md-lime-A200-bg {
  background-color: #EEFF41 !important;
  color: rgba(0, 0, 0, 0.87);
}

.lime-A200-fg {
  color: #EEFF41 !important;
}

.lime-A200-border {
  border-color: #EEFF41 !important;
}

.lime-A200-border-top {
  border-top-color: #EEFF41 !important;
}

.lime-A200-border-right {
  border-right-color: #EEFF41 !important;
}

.lime-A200-border-bottom {
  border-bottom-color: #EEFF41 !important;
}

.lime-A200-border-left {
  border-left-color: #EEFF41 !important;
}

.lime-A400-bg {
  background-color: #C6FF00 !important;
}

.md-lime-A400-bg {
  background-color: #C6FF00 !important;
  color: rgba(0, 0, 0, 0.87);
}

.lime-A400-fg {
  color: #C6FF00 !important;
}

.lime-A400-border {
  border-color: #C6FF00 !important;
}

.lime-A400-border-top {
  border-top-color: #C6FF00 !important;
}

.lime-A400-border-right {
  border-right-color: #C6FF00 !important;
}

.lime-A400-border-bottom {
  border-bottom-color: #C6FF00 !important;
}

.lime-A400-border-left {
  border-left-color: #C6FF00 !important;
}

.lime-A700-bg {
  background-color: #AEEA00 !important;
}

.md-lime-A700-bg {
  background-color: #AEEA00 !important;
  color: rgba(0, 0, 0, 0.87);
}

.lime-A700-fg {
  color: #AEEA00 !important;
}

.lime-A700-border {
  border-color: #AEEA00 !important;
}

.lime-A700-border-top {
  border-top-color: #AEEA00 !important;
}

.lime-A700-border-right {
  border-right-color: #AEEA00 !important;
}

.lime-A700-border-bottom {
  border-bottom-color: #AEEA00 !important;
}

.lime-A700-border-left {
  border-left-color: #AEEA00 !important;
}

.yellow-50-bg {
  background-color: #FFFDE7 !important;
}

.md-yellow-50-bg {
  background-color: #FFFDE7 !important;
  color: rgba(0, 0, 0, 0.87);
}

.yellow-50-fg {
  color: #FFFDE7 !important;
}

.yellow-50-border {
  border-color: #FFFDE7 !important;
}

.yellow-50-border-top {
  border-top-color: #FFFDE7 !important;
}

.yellow-50-border-right {
  border-right-color: #FFFDE7 !important;
}

.yellow-50-border-bottom {
  border-bottom-color: #FFFDE7 !important;
}

.yellow-50-border-left {
  border-left-color: #FFFDE7 !important;
}

.yellow-100-bg {
  background-color: #FFF9C4 !important;
}

.md-yellow-100-bg {
  background-color: #FFF9C4 !important;
  color: rgba(0, 0, 0, 0.87);
}

.yellow-100-fg {
  color: #FFF9C4 !important;
}

.yellow-100-border {
  border-color: #FFF9C4 !important;
}

.yellow-100-border-top {
  border-top-color: #FFF9C4 !important;
}

.yellow-100-border-right {
  border-right-color: #FFF9C4 !important;
}

.yellow-100-border-bottom {
  border-bottom-color: #FFF9C4 !important;
}

.yellow-100-border-left {
  border-left-color: #FFF9C4 !important;
}

.yellow-200-bg {
  background-color: #FFF59D !important;
}

.md-yellow-200-bg {
  background-color: #FFF59D !important;
  color: rgba(0, 0, 0, 0.87);
}

.yellow-200-fg {
  color: #FFF59D !important;
}

.yellow-200-border {
  border-color: #FFF59D !important;
}

.yellow-200-border-top {
  border-top-color: #FFF59D !important;
}

.yellow-200-border-right {
  border-right-color: #FFF59D !important;
}

.yellow-200-border-bottom {
  border-bottom-color: #FFF59D !important;
}

.yellow-200-border-left {
  border-left-color: #FFF59D !important;
}

.yellow-300-bg {
  background-color: #FFF176 !important;
}

.md-yellow-300-bg {
  background-color: #FFF176 !important;
  color: rgba(0, 0, 0, 0.87);
}

.yellow-300-fg {
  color: #FFF176 !important;
}

.yellow-300-border {
  border-color: #FFF176 !important;
}

.yellow-300-border-top {
  border-top-color: #FFF176 !important;
}

.yellow-300-border-right {
  border-right-color: #FFF176 !important;
}

.yellow-300-border-bottom {
  border-bottom-color: #FFF176 !important;
}

.yellow-300-border-left {
  border-left-color: #FFF176 !important;
}

.yellow-400-bg {
  background-color: #FFEE58 !important;
}

.md-yellow-400-bg {
  background-color: #FFEE58 !important;
  color: rgba(0, 0, 0, 0.87);
}

.yellow-400-fg {
  color: #FFEE58 !important;
}

.yellow-400-border {
  border-color: #FFEE58 !important;
}

.yellow-400-border-top {
  border-top-color: #FFEE58 !important;
}

.yellow-400-border-right {
  border-right-color: #FFEE58 !important;
}

.yellow-400-border-bottom {
  border-bottom-color: #FFEE58 !important;
}

.yellow-400-border-left {
  border-left-color: #FFEE58 !important;
}

.yellow-500-bg {
  background-color: #FFEB3B !important;
}

.md-yellow-500-bg {
  background-color: #FFEB3B !important;
  color: rgba(0, 0, 0, 0.87);
}

.yellow-500-fg {
  color: #FFEB3B !important;
}

.yellow-500-border {
  border-color: #FFEB3B !important;
}

.yellow-500-border-top {
  border-top-color: #FFEB3B !important;
}

.yellow-500-border-right {
  border-right-color: #FFEB3B !important;
}

.yellow-500-border-bottom {
  border-bottom-color: #FFEB3B !important;
}

.yellow-500-border-left {
  border-left-color: #FFEB3B !important;
}

.md-yellow-bg {
  background-color: #FFEB3B !important;
  color: rgba(0, 0, 0, 0.87);
}

.yellow-bg {
  background-color: #FFEB3B !important;
}

.yellow-fg {
  color: #FFEB3B !important;
}

.yellow-border {
  border-color: #FFEB3B !important;
}

.yellow-border-top {
  border-top-color: #FFEB3B !important;
}

.yellow-border-right {
  border-right-color: #FFEB3B !important;
}

.yellow-border-bottom {
  border-bottom-color: #FFEB3B !important;
}

.yellow-border-left {
  border-left-color: #FFEB3B !important;
}

.yellow-600-bg {
  background-color: #FDD835 !important;
}

.md-yellow-600-bg {
  background-color: #FDD835 !important;
  color: rgba(0, 0, 0, 0.87);
}

.yellow-600-fg {
  color: #FDD835 !important;
}

.yellow-600-border {
  border-color: #FDD835 !important;
}

.yellow-600-border-top {
  border-top-color: #FDD835 !important;
}

.yellow-600-border-right {
  border-right-color: #FDD835 !important;
}

.yellow-600-border-bottom {
  border-bottom-color: #FDD835 !important;
}

.yellow-600-border-left {
  border-left-color: #FDD835 !important;
}

.yellow-700-bg {
  background-color: #FBC02D !important;
}

.md-yellow-700-bg {
  background-color: #FBC02D !important;
  color: rgba(0, 0, 0, 0.87);
}

.yellow-700-fg {
  color: #FBC02D !important;
}

.yellow-700-border {
  border-color: #FBC02D !important;
}

.yellow-700-border-top {
  border-top-color: #FBC02D !important;
}

.yellow-700-border-right {
  border-right-color: #FBC02D !important;
}

.yellow-700-border-bottom {
  border-bottom-color: #FBC02D !important;
}

.yellow-700-border-left {
  border-left-color: #FBC02D !important;
}

.yellow-800-bg {
  background-color: #F9A825 !important;
}

.md-yellow-800-bg {
  background-color: #F9A825 !important;
  color: rgba(0, 0, 0, 0.87);
}

.yellow-800-fg {
  color: #F9A825 !important;
}

.yellow-800-border {
  border-color: #F9A825 !important;
}

.yellow-800-border-top {
  border-top-color: #F9A825 !important;
}

.yellow-800-border-right {
  border-right-color: #F9A825 !important;
}

.yellow-800-border-bottom {
  border-bottom-color: #F9A825 !important;
}

.yellow-800-border-left {
  border-left-color: #F9A825 !important;
}

.yellow-900-bg {
  background-color: #F57F17 !important;
}

.md-yellow-900-bg {
  background-color: #F57F17 !important;
  color: rgba(0, 0, 0, 0.87);
}

.yellow-900-fg {
  color: #F57F17 !important;
}

.yellow-900-border {
  border-color: #F57F17 !important;
}

.yellow-900-border-top {
  border-top-color: #F57F17 !important;
}

.yellow-900-border-right {
  border-right-color: #F57F17 !important;
}

.yellow-900-border-bottom {
  border-bottom-color: #F57F17 !important;
}

.yellow-900-border-left {
  border-left-color: #F57F17 !important;
}

.yellow-A100-bg {
  background-color: #FFFF8D !important;
}

.md-yellow-A100-bg {
  background-color: #FFFF8D !important;
  color: rgba(0, 0, 0, 0.87);
}

.yellow-A100-fg {
  color: #FFFF8D !important;
}

.yellow-A100-border {
  border-color: #FFFF8D !important;
}

.yellow-A100-border-top {
  border-top-color: #FFFF8D !important;
}

.yellow-A100-border-right {
  border-right-color: #FFFF8D !important;
}

.yellow-A100-border-bottom {
  border-bottom-color: #FFFF8D !important;
}

.yellow-A100-border-left {
  border-left-color: #FFFF8D !important;
}

.yellow-A200-bg {
  background-color: #FFFF00 !important;
}

.md-yellow-A200-bg {
  background-color: #FFFF00 !important;
  color: rgba(0, 0, 0, 0.87);
}

.yellow-A200-fg {
  color: #FFFF00 !important;
}

.yellow-A200-border {
  border-color: #FFFF00 !important;
}

.yellow-A200-border-top {
  border-top-color: #FFFF00 !important;
}

.yellow-A200-border-right {
  border-right-color: #FFFF00 !important;
}

.yellow-A200-border-bottom {
  border-bottom-color: #FFFF00 !important;
}

.yellow-A200-border-left {
  border-left-color: #FFFF00 !important;
}

.yellow-A400-bg {
  background-color: #FFEA00 !important;
}

.md-yellow-A400-bg {
  background-color: #FFEA00 !important;
  color: rgba(0, 0, 0, 0.87);
}

.yellow-A400-fg {
  color: #FFEA00 !important;
}

.yellow-A400-border {
  border-color: #FFEA00 !important;
}

.yellow-A400-border-top {
  border-top-color: #FFEA00 !important;
}

.yellow-A400-border-right {
  border-right-color: #FFEA00 !important;
}

.yellow-A400-border-bottom {
  border-bottom-color: #FFEA00 !important;
}

.yellow-A400-border-left {
  border-left-color: #FFEA00 !important;
}

.yellow-A700-bg {
  background-color: #FFD600 !important;
}

.md-yellow-A700-bg {
  background-color: #FFD600 !important;
  color: rgba(0, 0, 0, 0.87);
}

.yellow-A700-fg {
  color: #FFD600 !important;
}

.yellow-A700-border {
  border-color: #FFD600 !important;
}

.yellow-A700-border-top {
  border-top-color: #FFD600 !important;
}

.yellow-A700-border-right {
  border-right-color: #FFD600 !important;
}

.yellow-A700-border-bottom {
  border-bottom-color: #FFD600 !important;
}

.yellow-A700-border-left {
  border-left-color: #FFD600 !important;
}

.amber-50-bg {
  background-color: #FFF8E1 !important;
}

.md-amber-50-bg {
  background-color: #FFF8E1 !important;
  color: rgba(0, 0, 0, 0.87);
}

.amber-50-fg {
  color: #FFF8E1 !important;
}

.amber-50-border {
  border-color: #FFF8E1 !important;
}

.amber-50-border-top {
  border-top-color: #FFF8E1 !important;
}

.amber-50-border-right {
  border-right-color: #FFF8E1 !important;
}

.amber-50-border-bottom {
  border-bottom-color: #FFF8E1 !important;
}

.amber-50-border-left {
  border-left-color: #FFF8E1 !important;
}

.amber-100-bg {
  background-color: #FFECB3 !important;
}

.md-amber-100-bg {
  background-color: #FFECB3 !important;
  color: rgba(0, 0, 0, 0.87);
}

.amber-100-fg {
  color: #FFECB3 !important;
}

.amber-100-border {
  border-color: #FFECB3 !important;
}

.amber-100-border-top {
  border-top-color: #FFECB3 !important;
}

.amber-100-border-right {
  border-right-color: #FFECB3 !important;
}

.amber-100-border-bottom {
  border-bottom-color: #FFECB3 !important;
}

.amber-100-border-left {
  border-left-color: #FFECB3 !important;
}

.amber-200-bg {
  background-color: #FFE082 !important;
}

.md-amber-200-bg {
  background-color: #FFE082 !important;
  color: rgba(0, 0, 0, 0.87);
}

.amber-200-fg {
  color: #FFE082 !important;
}

.amber-200-border {
  border-color: #FFE082 !important;
}

.amber-200-border-top {
  border-top-color: #FFE082 !important;
}

.amber-200-border-right {
  border-right-color: #FFE082 !important;
}

.amber-200-border-bottom {
  border-bottom-color: #FFE082 !important;
}

.amber-200-border-left {
  border-left-color: #FFE082 !important;
}

.amber-300-bg {
  background-color: #FFD54F !important;
}

.md-amber-300-bg {
  background-color: #FFD54F !important;
  color: rgba(0, 0, 0, 0.87);
}

.amber-300-fg {
  color: #FFD54F !important;
}

.amber-300-border {
  border-color: #FFD54F !important;
}

.amber-300-border-top {
  border-top-color: #FFD54F !important;
}

.amber-300-border-right {
  border-right-color: #FFD54F !important;
}

.amber-300-border-bottom {
  border-bottom-color: #FFD54F !important;
}

.amber-300-border-left {
  border-left-color: #FFD54F !important;
}

.amber-400-bg {
  background-color: #FFCA28 !important;
}

.md-amber-400-bg {
  background-color: #FFCA28 !important;
  color: rgba(0, 0, 0, 0.87);
}

.amber-400-fg {
  color: #FFCA28 !important;
}

.amber-400-border {
  border-color: #FFCA28 !important;
}

.amber-400-border-top {
  border-top-color: #FFCA28 !important;
}

.amber-400-border-right {
  border-right-color: #FFCA28 !important;
}

.amber-400-border-bottom {
  border-bottom-color: #FFCA28 !important;
}

.amber-400-border-left {
  border-left-color: #FFCA28 !important;
}

.amber-500-bg {
  background-color: #FFC107 !important;
}

.md-amber-500-bg {
  background-color: #FFC107 !important;
  color: rgba(0, 0, 0, 0.87);
}

.amber-500-fg {
  color: #FFC107 !important;
}

.amber-500-border {
  border-color: #FFC107 !important;
}

.amber-500-border-top {
  border-top-color: #FFC107 !important;
}

.amber-500-border-right {
  border-right-color: #FFC107 !important;
}

.amber-500-border-bottom {
  border-bottom-color: #FFC107 !important;
}

.amber-500-border-left {
  border-left-color: #FFC107 !important;
}

.md-amber-bg {
  background-color: #FFC107 !important;
  color: rgba(0, 0, 0, 0.87);
}

.amber-bg {
  background-color: #FFC107 !important;
}

.amber-fg {
  color: #FFC107 !important;
}

.amber-border {
  border-color: #FFC107 !important;
}

.amber-border-top {
  border-top-color: #FFC107 !important;
}

.amber-border-right {
  border-right-color: #FFC107 !important;
}

.amber-border-bottom {
  border-bottom-color: #FFC107 !important;
}

.amber-border-left {
  border-left-color: #FFC107 !important;
}

.amber-600-bg {
  background-color: #FFB300 !important;
}

.md-amber-600-bg {
  background-color: #FFB300 !important;
  color: rgba(0, 0, 0, 0.87);
}

.amber-600-fg {
  color: #FFB300 !important;
}

.amber-600-border {
  border-color: #FFB300 !important;
}

.amber-600-border-top {
  border-top-color: #FFB300 !important;
}

.amber-600-border-right {
  border-right-color: #FFB300 !important;
}

.amber-600-border-bottom {
  border-bottom-color: #FFB300 !important;
}

.amber-600-border-left {
  border-left-color: #FFB300 !important;
}

.amber-700-bg {
  background-color: #FFA000 !important;
}

.md-amber-700-bg {
  background-color: #FFA000 !important;
  color: rgba(0, 0, 0, 0.87);
}

.amber-700-fg {
  color: #FFA000 !important;
}

.amber-700-border {
  border-color: #FFA000 !important;
}

.amber-700-border-top {
  border-top-color: #FFA000 !important;
}

.amber-700-border-right {
  border-right-color: #FFA000 !important;
}

.amber-700-border-bottom {
  border-bottom-color: #FFA000 !important;
}

.amber-700-border-left {
  border-left-color: #FFA000 !important;
}

.amber-800-bg {
  background-color: #FF8F00 !important;
}

.md-amber-800-bg {
  background-color: #FF8F00 !important;
  color: rgba(0, 0, 0, 0.87);
}

.amber-800-fg {
  color: #FF8F00 !important;
}

.amber-800-border {
  border-color: #FF8F00 !important;
}

.amber-800-border-top {
  border-top-color: #FF8F00 !important;
}

.amber-800-border-right {
  border-right-color: #FF8F00 !important;
}

.amber-800-border-bottom {
  border-bottom-color: #FF8F00 !important;
}

.amber-800-border-left {
  border-left-color: #FF8F00 !important;
}

.amber-900-bg {
  background-color: #FF6F00 !important;
}

.md-amber-900-bg {
  background-color: #FF6F00 !important;
  color: rgba(0, 0, 0, 0.87);
}

.amber-900-fg {
  color: #FF6F00 !important;
}

.amber-900-border {
  border-color: #FF6F00 !important;
}

.amber-900-border-top {
  border-top-color: #FF6F00 !important;
}

.amber-900-border-right {
  border-right-color: #FF6F00 !important;
}

.amber-900-border-bottom {
  border-bottom-color: #FF6F00 !important;
}

.amber-900-border-left {
  border-left-color: #FF6F00 !important;
}

.amber-A100-bg {
  background-color: #FFE57F !important;
}

.md-amber-A100-bg {
  background-color: #FFE57F !important;
  color: rgba(0, 0, 0, 0.87);
}

.amber-A100-fg {
  color: #FFE57F !important;
}

.amber-A100-border {
  border-color: #FFE57F !important;
}

.amber-A100-border-top {
  border-top-color: #FFE57F !important;
}

.amber-A100-border-right {
  border-right-color: #FFE57F !important;
}

.amber-A100-border-bottom {
  border-bottom-color: #FFE57F !important;
}

.amber-A100-border-left {
  border-left-color: #FFE57F !important;
}

.amber-A200-bg {
  background-color: #FFD740 !important;
}

.md-amber-A200-bg {
  background-color: #FFD740 !important;
  color: rgba(0, 0, 0, 0.87);
}

.amber-A200-fg {
  color: #FFD740 !important;
}

.amber-A200-border {
  border-color: #FFD740 !important;
}

.amber-A200-border-top {
  border-top-color: #FFD740 !important;
}

.amber-A200-border-right {
  border-right-color: #FFD740 !important;
}

.amber-A200-border-bottom {
  border-bottom-color: #FFD740 !important;
}

.amber-A200-border-left {
  border-left-color: #FFD740 !important;
}

.amber-A400-bg {
  background-color: #FFC400 !important;
}

.md-amber-A400-bg {
  background-color: #FFC400 !important;
  color: rgba(0, 0, 0, 0.87);
}

.amber-A400-fg {
  color: #FFC400 !important;
}

.amber-A400-border {
  border-color: #FFC400 !important;
}

.amber-A400-border-top {
  border-top-color: #FFC400 !important;
}

.amber-A400-border-right {
  border-right-color: #FFC400 !important;
}

.amber-A400-border-bottom {
  border-bottom-color: #FFC400 !important;
}

.amber-A400-border-left {
  border-left-color: #FFC400 !important;
}

.amber-A700-bg {
  background-color: #FFAB00 !important;
}

.md-amber-A700-bg {
  background-color: #FFAB00 !important;
  color: rgba(0, 0, 0, 0.87);
}

.amber-A700-fg {
  color: #FFAB00 !important;
}

.amber-A700-border {
  border-color: #FFAB00 !important;
}

.amber-A700-border-top {
  border-top-color: #FFAB00 !important;
}

.amber-A700-border-right {
  border-right-color: #FFAB00 !important;
}

.amber-A700-border-bottom {
  border-bottom-color: #FFAB00 !important;
}

.amber-A700-border-left {
  border-left-color: #FFAB00 !important;
}

.orange-50-bg {
  background-color: #FFF3E0 !important;
}

.md-orange-50-bg {
  background-color: #FFF3E0 !important;
  color: rgba(0, 0, 0, 0.87);
}

.orange-50-fg {
  color: #FFF3E0 !important;
}

.orange-50-border {
  border-color: #FFF3E0 !important;
}

.orange-50-border-top {
  border-top-color: #FFF3E0 !important;
}

.orange-50-border-right {
  border-right-color: #FFF3E0 !important;
}

.orange-50-border-bottom {
  border-bottom-color: #FFF3E0 !important;
}

.orange-50-border-left {
  border-left-color: #FFF3E0 !important;
}

.orange-100-bg {
  background-color: #FFE0B2 !important;
}

.md-orange-100-bg {
  background-color: #FFE0B2 !important;
  color: rgba(0, 0, 0, 0.87);
}

.orange-100-fg {
  color: #FFE0B2 !important;
}

.orange-100-border {
  border-color: #FFE0B2 !important;
}

.orange-100-border-top {
  border-top-color: #FFE0B2 !important;
}

.orange-100-border-right {
  border-right-color: #FFE0B2 !important;
}

.orange-100-border-bottom {
  border-bottom-color: #FFE0B2 !important;
}

.orange-100-border-left {
  border-left-color: #FFE0B2 !important;
}

.orange-200-bg {
  background-color: #FFCC80 !important;
}

.md-orange-200-bg {
  background-color: #FFCC80 !important;
  color: rgba(0, 0, 0, 0.87);
}

.orange-200-fg {
  color: #FFCC80 !important;
}

.orange-200-border {
  border-color: #FFCC80 !important;
}

.orange-200-border-top {
  border-top-color: #FFCC80 !important;
}

.orange-200-border-right {
  border-right-color: #FFCC80 !important;
}

.orange-200-border-bottom {
  border-bottom-color: #FFCC80 !important;
}

.orange-200-border-left {
  border-left-color: #FFCC80 !important;
}

.orange-300-bg {
  background-color: #FFB74D !important;
}

.md-orange-300-bg {
  background-color: #FFB74D !important;
  color: rgba(0, 0, 0, 0.87);
}

.orange-300-fg {
  color: #FFB74D !important;
}

.orange-300-border {
  border-color: #FFB74D !important;
}

.orange-300-border-top {
  border-top-color: #FFB74D !important;
}

.orange-300-border-right {
  border-right-color: #FFB74D !important;
}

.orange-300-border-bottom {
  border-bottom-color: #FFB74D !important;
}

.orange-300-border-left {
  border-left-color: #FFB74D !important;
}

.orange-400-bg {
  background-color: #FFA726 !important;
}

.md-orange-400-bg {
  background-color: #FFA726 !important;
  color: rgba(0, 0, 0, 0.87);
}

.orange-400-fg {
  color: #FFA726 !important;
}

.orange-400-border {
  border-color: #FFA726 !important;
}

.orange-400-border-top {
  border-top-color: #FFA726 !important;
}

.orange-400-border-right {
  border-right-color: #FFA726 !important;
}

.orange-400-border-bottom {
  border-bottom-color: #FFA726 !important;
}

.orange-400-border-left {
  border-left-color: #FFA726 !important;
}

.orange-500-bg {
  background-color: #FF9800 !important;
}

.md-orange-500-bg {
  background-color: #FF9800 !important;
  color: rgba(0, 0, 0, 0.87);
}

.orange-500-fg {
  color: #FF9800 !important;
}

.orange-500-border {
  border-color: #FF9800 !important;
}

.orange-500-border-top {
  border-top-color: #FF9800 !important;
}

.orange-500-border-right {
  border-right-color: #FF9800 !important;
}

.orange-500-border-bottom {
  border-bottom-color: #FF9800 !important;
}

.orange-500-border-left {
  border-left-color: #FF9800 !important;
}

.md-orange-bg {
  background-color: #FF9800 !important;
  color: rgba(0, 0, 0, 0.87);
}

.orange-bg {
  background-color: #FF9800 !important;
}

.orange-fg {
  color: #FF9800 !important;
}

.orange-border {
  border-color: #FF9800 !important;
}

.orange-border-top {
  border-top-color: #FF9800 !important;
}

.orange-border-right {
  border-right-color: #FF9800 !important;
}

.orange-border-bottom {
  border-bottom-color: #FF9800 !important;
}

.orange-border-left {
  border-left-color: #FF9800 !important;
}

.orange-600-bg {
  background-color: #FB8C00 !important;
}

.md-orange-600-bg {
  background-color: #FB8C00 !important;
  color: rgba(0, 0, 0, 0.87);
}

.orange-600-fg {
  color: #FB8C00 !important;
}

.orange-600-border {
  border-color: #FB8C00 !important;
}

.orange-600-border-top {
  border-top-color: #FB8C00 !important;
}

.orange-600-border-right {
  border-right-color: #FB8C00 !important;
}

.orange-600-border-bottom {
  border-bottom-color: #FB8C00 !important;
}

.orange-600-border-left {
  border-left-color: #FB8C00 !important;
}

.orange-700-bg {
  background-color: #F57C00 !important;
}

.md-orange-700-bg {
  background-color: #F57C00 !important;
  color: rgba(0, 0, 0, 0.87);
}

.orange-700-fg {
  color: #F57C00 !important;
}

.orange-700-border {
  border-color: #F57C00 !important;
}

.orange-700-border-top {
  border-top-color: #F57C00 !important;
}

.orange-700-border-right {
  border-right-color: #F57C00 !important;
}

.orange-700-border-bottom {
  border-bottom-color: #F57C00 !important;
}

.orange-700-border-left {
  border-left-color: #F57C00 !important;
}

.orange-800-bg {
  background-color: #EF6C00 !important;
}

.md-orange-800-bg {
  background-color: #EF6C00 !important;
  color: rgb(255, 255, 255);
}

.orange-800-fg {
  color: #EF6C00 !important;
}

.orange-800-border {
  border-color: #EF6C00 !important;
}

.orange-800-border-top {
  border-top-color: #EF6C00 !important;
}

.orange-800-border-right {
  border-right-color: #EF6C00 !important;
}

.orange-800-border-bottom {
  border-bottom-color: #EF6C00 !important;
}

.orange-800-border-left {
  border-left-color: #EF6C00 !important;
}

.orange-900-bg {
  background-color: #E65100 !important;
}

.md-orange-900-bg {
  background-color: #E65100 !important;
  color: rgb(255, 255, 255);
}

.orange-900-fg {
  color: #E65100 !important;
}

.orange-900-border {
  border-color: #E65100 !important;
}

.orange-900-border-top {
  border-top-color: #E65100 !important;
}

.orange-900-border-right {
  border-right-color: #E65100 !important;
}

.orange-900-border-bottom {
  border-bottom-color: #E65100 !important;
}

.orange-900-border-left {
  border-left-color: #E65100 !important;
}

.orange-A100-bg {
  background-color: #FFD180 !important;
}

.md-orange-A100-bg {
  background-color: #FFD180 !important;
  color: rgba(0, 0, 0, 0.87);
}

.orange-A100-fg {
  color: #FFD180 !important;
}

.orange-A100-border {
  border-color: #FFD180 !important;
}

.orange-A100-border-top {
  border-top-color: #FFD180 !important;
}

.orange-A100-border-right {
  border-right-color: #FFD180 !important;
}

.orange-A100-border-bottom {
  border-bottom-color: #FFD180 !important;
}

.orange-A100-border-left {
  border-left-color: #FFD180 !important;
}

.orange-A200-bg {
  background-color: #FFAB40 !important;
}

.md-orange-A200-bg {
  background-color: #FFAB40 !important;
  color: rgba(0, 0, 0, 0.87);
}

.orange-A200-fg {
  color: #FFAB40 !important;
}

.orange-A200-border {
  border-color: #FFAB40 !important;
}

.orange-A200-border-top {
  border-top-color: #FFAB40 !important;
}

.orange-A200-border-right {
  border-right-color: #FFAB40 !important;
}

.orange-A200-border-bottom {
  border-bottom-color: #FFAB40 !important;
}

.orange-A200-border-left {
  border-left-color: #FFAB40 !important;
}

.orange-A400-bg {
  background-color: #FF9100 !important;
}

.md-orange-A400-bg {
  background-color: #FF9100 !important;
  color: rgba(0, 0, 0, 0.87);
}

.orange-A400-fg {
  color: #FF9100 !important;
}

.orange-A400-border {
  border-color: #FF9100 !important;
}

.orange-A400-border-top {
  border-top-color: #FF9100 !important;
}

.orange-A400-border-right {
  border-right-color: #FF9100 !important;
}

.orange-A400-border-bottom {
  border-bottom-color: #FF9100 !important;
}

.orange-A400-border-left {
  border-left-color: #FF9100 !important;
}

.orange-A700-bg {
  background-color: #FF6D00 !important;
}

.md-orange-A700-bg {
  background-color: #FF6D00 !important;
  color: rgba(0, 0, 0, 0.87);
}

.orange-A700-fg {
  color: #FF6D00 !important;
}

.orange-A700-border {
  border-color: #FF6D00 !important;
}

.orange-A700-border-top {
  border-top-color: #FF6D00 !important;
}

.orange-A700-border-right {
  border-right-color: #FF6D00 !important;
}

.orange-A700-border-bottom {
  border-bottom-color: #FF6D00 !important;
}

.orange-A700-border-left {
  border-left-color: #FF6D00 !important;
}

.deep-orange-50-bg {
  background-color: #FBE9E7 !important;
}

.md-deep-orange-50-bg {
  background-color: #FBE9E7 !important;
  color: rgba(0, 0, 0, 0.87);
}

.deep-orange-50-fg {
  color: #FBE9E7 !important;
}

.deep-orange-50-border {
  border-color: #FBE9E7 !important;
}

.deep-orange-50-border-top {
  border-top-color: #FBE9E7 !important;
}

.deep-orange-50-border-right {
  border-right-color: #FBE9E7 !important;
}

.deep-orange-50-border-bottom {
  border-bottom-color: #FBE9E7 !important;
}

.deep-orange-50-border-left {
  border-left-color: #FBE9E7 !important;
}

.deep-orange-100-bg {
  background-color: #FFCCBC !important;
}

.md-deep-orange-100-bg {
  background-color: #FFCCBC !important;
  color: rgba(0, 0, 0, 0.87);
}

.deep-orange-100-fg {
  color: #FFCCBC !important;
}

.deep-orange-100-border {
  border-color: #FFCCBC !important;
}

.deep-orange-100-border-top {
  border-top-color: #FFCCBC !important;
}

.deep-orange-100-border-right {
  border-right-color: #FFCCBC !important;
}

.deep-orange-100-border-bottom {
  border-bottom-color: #FFCCBC !important;
}

.deep-orange-100-border-left {
  border-left-color: #FFCCBC !important;
}

.deep-orange-200-bg {
  background-color: #FFAB91 !important;
}

.md-deep-orange-200-bg {
  background-color: #FFAB91 !important;
  color: rgba(0, 0, 0, 0.87);
}

.deep-orange-200-fg {
  color: #FFAB91 !important;
}

.deep-orange-200-border {
  border-color: #FFAB91 !important;
}

.deep-orange-200-border-top {
  border-top-color: #FFAB91 !important;
}

.deep-orange-200-border-right {
  border-right-color: #FFAB91 !important;
}

.deep-orange-200-border-bottom {
  border-bottom-color: #FFAB91 !important;
}

.deep-orange-200-border-left {
  border-left-color: #FFAB91 !important;
}

.deep-orange-300-bg {
  background-color: #FF8A65 !important;
}

.md-deep-orange-300-bg {
  background-color: #FF8A65 !important;
  color: rgba(0, 0, 0, 0.87);
}

.deep-orange-300-fg {
  color: #FF8A65 !important;
}

.deep-orange-300-border {
  border-color: #FF8A65 !important;
}

.deep-orange-300-border-top {
  border-top-color: #FF8A65 !important;
}

.deep-orange-300-border-right {
  border-right-color: #FF8A65 !important;
}

.deep-orange-300-border-bottom {
  border-bottom-color: #FF8A65 !important;
}

.deep-orange-300-border-left {
  border-left-color: #FF8A65 !important;
}

.deep-orange-400-bg {
  background-color: #FF7043 !important;
}

.md-deep-orange-400-bg {
  background-color: #FF7043 !important;
  color: rgba(0, 0, 0, 0.87);
}

.deep-orange-400-fg {
  color: #FF7043 !important;
}

.deep-orange-400-border {
  border-color: #FF7043 !important;
}

.deep-orange-400-border-top {
  border-top-color: #FF7043 !important;
}

.deep-orange-400-border-right {
  border-right-color: #FF7043 !important;
}

.deep-orange-400-border-bottom {
  border-bottom-color: #FF7043 !important;
}

.deep-orange-400-border-left {
  border-left-color: #FF7043 !important;
}

.deep-orange-500-bg {
  background-color: #FF5722 !important;
}

.md-deep-orange-500-bg {
  background-color: #FF5722 !important;
  color: rgb(255, 255, 255);
}

.deep-orange-500-fg {
  color: #FF5722 !important;
}

.deep-orange-500-border {
  border-color: #FF5722 !important;
}

.deep-orange-500-border-top {
  border-top-color: #FF5722 !important;
}

.deep-orange-500-border-right {
  border-right-color: #FF5722 !important;
}

.deep-orange-500-border-bottom {
  border-bottom-color: #FF5722 !important;
}

.deep-orange-500-border-left {
  border-left-color: #FF5722 !important;
}

.md-deep-orange-bg {
  background-color: #FF5722 !important;
  color: rgb(255, 255, 255);
}

.deep-orange-bg {
  background-color: #FF5722 !important;
}

.deep-orange-fg {
  color: #FF5722 !important;
}

.deep-orange-border {
  border-color: #FF5722 !important;
}

.deep-orange-border-top {
  border-top-color: #FF5722 !important;
}

.deep-orange-border-right {
  border-right-color: #FF5722 !important;
}

.deep-orange-border-bottom {
  border-bottom-color: #FF5722 !important;
}

.deep-orange-border-left {
  border-left-color: #FF5722 !important;
}

.deep-orange-600-bg {
  background-color: #F4511E !important;
}

.md-deep-orange-600-bg {
  background-color: #F4511E !important;
  color: rgb(255, 255, 255);
}

.deep-orange-600-fg {
  color: #F4511E !important;
}

.deep-orange-600-border {
  border-color: #F4511E !important;
}

.deep-orange-600-border-top {
  border-top-color: #F4511E !important;
}

.deep-orange-600-border-right {
  border-right-color: #F4511E !important;
}

.deep-orange-600-border-bottom {
  border-bottom-color: #F4511E !important;
}

.deep-orange-600-border-left {
  border-left-color: #F4511E !important;
}

.deep-orange-700-bg {
  background-color: #E64A19 !important;
}

.md-deep-orange-700-bg {
  background-color: #E64A19 !important;
  color: rgb(255, 255, 255);
}

.deep-orange-700-fg {
  color: #E64A19 !important;
}

.deep-orange-700-border {
  border-color: #E64A19 !important;
}

.deep-orange-700-border-top {
  border-top-color: #E64A19 !important;
}

.deep-orange-700-border-right {
  border-right-color: #E64A19 !important;
}

.deep-orange-700-border-bottom {
  border-bottom-color: #E64A19 !important;
}

.deep-orange-700-border-left {
  border-left-color: #E64A19 !important;
}

.deep-orange-800-bg {
  background-color: #D84315 !important;
}

.md-deep-orange-800-bg {
  background-color: #D84315 !important;
  color: rgb(255, 255, 255);
}

.deep-orange-800-fg {
  color: #D84315 !important;
}

.deep-orange-800-border {
  border-color: #D84315 !important;
}

.deep-orange-800-border-top {
  border-top-color: #D84315 !important;
}

.deep-orange-800-border-right {
  border-right-color: #D84315 !important;
}

.deep-orange-800-border-bottom {
  border-bottom-color: #D84315 !important;
}

.deep-orange-800-border-left {
  border-left-color: #D84315 !important;
}

.deep-orange-900-bg {
  background-color: #BF360C !important;
}

.md-deep-orange-900-bg {
  background-color: #BF360C !important;
  color: rgb(255, 255, 255);
}

.deep-orange-900-fg {
  color: #BF360C !important;
}

.deep-orange-900-border {
  border-color: #BF360C !important;
}

.deep-orange-900-border-top {
  border-top-color: #BF360C !important;
}

.deep-orange-900-border-right {
  border-right-color: #BF360C !important;
}

.deep-orange-900-border-bottom {
  border-bottom-color: #BF360C !important;
}

.deep-orange-900-border-left {
  border-left-color: #BF360C !important;
}

.deep-orange-A100-bg {
  background-color: #FF9E80 !important;
}

.md-deep-orange-A100-bg {
  background-color: #FF9E80 !important;
  color: rgba(0, 0, 0, 0.87);
}

.deep-orange-A100-fg {
  color: #FF9E80 !important;
}

.deep-orange-A100-border {
  border-color: #FF9E80 !important;
}

.deep-orange-A100-border-top {
  border-top-color: #FF9E80 !important;
}

.deep-orange-A100-border-right {
  border-right-color: #FF9E80 !important;
}

.deep-orange-A100-border-bottom {
  border-bottom-color: #FF9E80 !important;
}

.deep-orange-A100-border-left {
  border-left-color: #FF9E80 !important;
}

.deep-orange-A200-bg {
  background-color: #FF6E40 !important;
}

.md-deep-orange-A200-bg {
  background-color: #FF6E40 !important;
  color: rgba(0, 0, 0, 0.87);
}

.deep-orange-A200-fg {
  color: #FF6E40 !important;
}

.deep-orange-A200-border {
  border-color: #FF6E40 !important;
}

.deep-orange-A200-border-top {
  border-top-color: #FF6E40 !important;
}

.deep-orange-A200-border-right {
  border-right-color: #FF6E40 !important;
}

.deep-orange-A200-border-bottom {
  border-bottom-color: #FF6E40 !important;
}

.deep-orange-A200-border-left {
  border-left-color: #FF6E40 !important;
}

.deep-orange-A400-bg {
  background-color: #FF3D00 !important;
}

.md-deep-orange-A400-bg {
  background-color: #FF3D00 !important;
  color: rgb(255, 255, 255);
}

.deep-orange-A400-fg {
  color: #FF3D00 !important;
}

.deep-orange-A400-border {
  border-color: #FF3D00 !important;
}

.deep-orange-A400-border-top {
  border-top-color: #FF3D00 !important;
}

.deep-orange-A400-border-right {
  border-right-color: #FF3D00 !important;
}

.deep-orange-A400-border-bottom {
  border-bottom-color: #FF3D00 !important;
}

.deep-orange-A400-border-left {
  border-left-color: #FF3D00 !important;
}

.deep-orange-A700-bg {
  background-color: #DD2C00 !important;
}

.md-deep-orange-A700-bg {
  background-color: #DD2C00 !important;
  color: rgb(255, 255, 255);
}

.deep-orange-A700-fg {
  color: #DD2C00 !important;
}

.deep-orange-A700-border {
  border-color: #DD2C00 !important;
}

.deep-orange-A700-border-top {
  border-top-color: #DD2C00 !important;
}

.deep-orange-A700-border-right {
  border-right-color: #DD2C00 !important;
}

.deep-orange-A700-border-bottom {
  border-bottom-color: #DD2C00 !important;
}

.deep-orange-A700-border-left {
  border-left-color: #DD2C00 !important;
}

.brown-50-bg {
  background-color: #EFEBE9 !important;
}

.md-brown-50-bg {
  background-color: #EFEBE9 !important;
  color: rgba(0, 0, 0, 0.87);
}

.brown-50-fg {
  color: #EFEBE9 !important;
}

.brown-50-border {
  border-color: #EFEBE9 !important;
}

.brown-50-border-top {
  border-top-color: #EFEBE9 !important;
}

.brown-50-border-right {
  border-right-color: #EFEBE9 !important;
}

.brown-50-border-bottom {
  border-bottom-color: #EFEBE9 !important;
}

.brown-50-border-left {
  border-left-color: #EFEBE9 !important;
}

.brown-100-bg {
  background-color: #D7CCC8 !important;
}

.md-brown-100-bg {
  background-color: #D7CCC8 !important;
  color: rgba(0, 0, 0, 0.87);
}

.brown-100-fg {
  color: #D7CCC8 !important;
}

.brown-100-border {
  border-color: #D7CCC8 !important;
}

.brown-100-border-top {
  border-top-color: #D7CCC8 !important;
}

.brown-100-border-right {
  border-right-color: #D7CCC8 !important;
}

.brown-100-border-bottom {
  border-bottom-color: #D7CCC8 !important;
}

.brown-100-border-left {
  border-left-color: #D7CCC8 !important;
}

.brown-200-bg {
  background-color: #BCAAA4 !important;
}

.md-brown-200-bg {
  background-color: #BCAAA4 !important;
  color: rgba(0, 0, 0, 0.87);
}

.brown-200-fg {
  color: #BCAAA4 !important;
}

.brown-200-border {
  border-color: #BCAAA4 !important;
}

.brown-200-border-top {
  border-top-color: #BCAAA4 !important;
}

.brown-200-border-right {
  border-right-color: #BCAAA4 !important;
}

.brown-200-border-bottom {
  border-bottom-color: #BCAAA4 !important;
}

.brown-200-border-left {
  border-left-color: #BCAAA4 !important;
}

.brown-300-bg {
  background-color: #A1887F !important;
}

.md-brown-300-bg {
  background-color: #A1887F !important;
  color: rgb(255, 255, 255);
}

.brown-300-fg {
  color: #A1887F !important;
}

.brown-300-border {
  border-color: #A1887F !important;
}

.brown-300-border-top {
  border-top-color: #A1887F !important;
}

.brown-300-border-right {
  border-right-color: #A1887F !important;
}

.brown-300-border-bottom {
  border-bottom-color: #A1887F !important;
}

.brown-300-border-left {
  border-left-color: #A1887F !important;
}

.brown-400-bg {
  background-color: #8D6E63 !important;
}

.md-brown-400-bg {
  background-color: #8D6E63 !important;
  color: rgb(255, 255, 255);
}

.brown-400-fg {
  color: #8D6E63 !important;
}

.brown-400-border {
  border-color: #8D6E63 !important;
}

.brown-400-border-top {
  border-top-color: #8D6E63 !important;
}

.brown-400-border-right {
  border-right-color: #8D6E63 !important;
}

.brown-400-border-bottom {
  border-bottom-color: #8D6E63 !important;
}

.brown-400-border-left {
  border-left-color: #8D6E63 !important;
}

.brown-500-bg {
  background-color: #795548 !important;
}

.md-brown-500-bg {
  background-color: #795548 !important;
  color: rgba(255, 255, 255, 0.87);
}

.brown-500-fg {
  color: #795548 !important;
}

.brown-500-border {
  border-color: #795548 !important;
}

.brown-500-border-top {
  border-top-color: #795548 !important;
}

.brown-500-border-right {
  border-right-color: #795548 !important;
}

.brown-500-border-bottom {
  border-bottom-color: #795548 !important;
}

.brown-500-border-left {
  border-left-color: #795548 !important;
}

.md-brown-bg {
  background-color: #795548 !important;
  color: rgba(255, 255, 255, 0.87);
}

.brown-bg {
  background-color: #795548 !important;
}

.brown-fg {
  color: #795548 !important;
}

.brown-border {
  border-color: #795548 !important;
}

.brown-border-top {
  border-top-color: #795548 !important;
}

.brown-border-right {
  border-right-color: #795548 !important;
}

.brown-border-bottom {
  border-bottom-color: #795548 !important;
}

.brown-border-left {
  border-left-color: #795548 !important;
}

.brown-600-bg {
  background-color: #6D4C41 !important;
}

.md-brown-600-bg {
  background-color: #6D4C41 !important;
  color: rgba(255, 255, 255, 0.87);
}

.brown-600-fg {
  color: #6D4C41 !important;
}

.brown-600-border {
  border-color: #6D4C41 !important;
}

.brown-600-border-top {
  border-top-color: #6D4C41 !important;
}

.brown-600-border-right {
  border-right-color: #6D4C41 !important;
}

.brown-600-border-bottom {
  border-bottom-color: #6D4C41 !important;
}

.brown-600-border-left {
  border-left-color: #6D4C41 !important;
}

.brown-700-bg {
  background-color: #5D4037 !important;
}

.md-brown-700-bg {
  background-color: #5D4037 !important;
  color: rgba(255, 255, 255, 0.87);
}

.brown-700-fg {
  color: #5D4037 !important;
}

.brown-700-border {
  border-color: #5D4037 !important;
}

.brown-700-border-top {
  border-top-color: #5D4037 !important;
}

.brown-700-border-right {
  border-right-color: #5D4037 !important;
}

.brown-700-border-bottom {
  border-bottom-color: #5D4037 !important;
}

.brown-700-border-left {
  border-left-color: #5D4037 !important;
}

.brown-800-bg {
  background-color: #4E342E !important;
}

.md-brown-800-bg {
  background-color: #4E342E !important;
  color: rgba(255, 255, 255, 0.87);
}

.brown-800-fg {
  color: #4E342E !important;
}

.brown-800-border {
  border-color: #4E342E !important;
}

.brown-800-border-top {
  border-top-color: #4E342E !important;
}

.brown-800-border-right {
  border-right-color: #4E342E !important;
}

.brown-800-border-bottom {
  border-bottom-color: #4E342E !important;
}

.brown-800-border-left {
  border-left-color: #4E342E !important;
}

.brown-900-bg {
  background-color: #3E2723 !important;
}

.md-brown-900-bg {
  background-color: #3E2723 !important;
  color: rgba(255, 255, 255, 0.87);
}

.brown-900-fg {
  color: #3E2723 !important;
}

.brown-900-border {
  border-color: #3E2723 !important;
}

.brown-900-border-top {
  border-top-color: #3E2723 !important;
}

.brown-900-border-right {
  border-right-color: #3E2723 !important;
}

.brown-900-border-bottom {
  border-bottom-color: #3E2723 !important;
}

.brown-900-border-left {
  border-left-color: #3E2723 !important;
}

.brown-A100-bg {
  background-color: #D7CCC8 !important;
}

.md-brown-A100-bg {
  background-color: #D7CCC8 !important;
  color: rgba(255, 255, 255, 0.87);
}

.brown-A100-fg {
  color: #D7CCC8 !important;
}

.brown-A100-border {
  border-color: #D7CCC8 !important;
}

.brown-A100-border-top {
  border-top-color: #D7CCC8 !important;
}

.brown-A100-border-right {
  border-right-color: #D7CCC8 !important;
}

.brown-A100-border-bottom {
  border-bottom-color: #D7CCC8 !important;
}

.brown-A100-border-left {
  border-left-color: #D7CCC8 !important;
}

.brown-A200-bg {
  background-color: #BCAAA4 !important;
}

.md-brown-A200-bg {
  background-color: #BCAAA4 !important;
  color: rgba(255, 255, 255, 0.87);
}

.brown-A200-fg {
  color: #BCAAA4 !important;
}

.brown-A200-border {
  border-color: #BCAAA4 !important;
}

.brown-A200-border-top {
  border-top-color: #BCAAA4 !important;
}

.brown-A200-border-right {
  border-right-color: #BCAAA4 !important;
}

.brown-A200-border-bottom {
  border-bottom-color: #BCAAA4 !important;
}

.brown-A200-border-left {
  border-left-color: #BCAAA4 !important;
}

.brown-A400-bg {
  background-color: #8D6E63 !important;
}

.md-brown-A400-bg {
  background-color: #8D6E63 !important;
  color: rgba(255, 255, 255, 0.87);
}

.brown-A400-fg {
  color: #8D6E63 !important;
}

.brown-A400-border {
  border-color: #8D6E63 !important;
}

.brown-A400-border-top {
  border-top-color: #8D6E63 !important;
}

.brown-A400-border-right {
  border-right-color: #8D6E63 !important;
}

.brown-A400-border-bottom {
  border-bottom-color: #8D6E63 !important;
}

.brown-A400-border-left {
  border-left-color: #8D6E63 !important;
}

.brown-A700-bg {
  background-color: #5D4037 !important;
}

.md-brown-A700-bg {
  background-color: #5D4037 !important;
  color: rgba(255, 255, 255, 0.87);
}

.brown-A700-fg {
  color: #5D4037 !important;
}

.brown-A700-border {
  border-color: #5D4037 !important;
}

.brown-A700-border-top {
  border-top-color: #5D4037 !important;
}

.brown-A700-border-right {
  border-right-color: #5D4037 !important;
}

.brown-A700-border-bottom {
  border-bottom-color: #5D4037 !important;
}

.brown-A700-border-left {
  border-left-color: #5D4037 !important;
}

.grey-50-bg {
  background-color: #FAFAFA !important;
}

.md-grey-50-bg {
  background-color: #FAFAFA !important;
  color: rgba(0, 0, 0, 0.87);
}

.grey-50-fg {
  color: #FAFAFA !important;
}

.grey-50-border {
  border-color: #FAFAFA !important;
}

.grey-50-border-top {
  border-top-color: #FAFAFA !important;
}

.grey-50-border-right {
  border-right-color: #FAFAFA !important;
}

.grey-50-border-bottom {
  border-bottom-color: #FAFAFA !important;
}

.grey-50-border-left {
  border-left-color: #FAFAFA !important;
}

.grey-100-bg {
  background-color: #F5F5F5 !important;
}

.md-grey-100-bg {
  background-color: #F5F5F5 !important;
  color: rgba(0, 0, 0, 0.87);
}

.grey-100-fg {
  color: #F5F5F5 !important;
}

.grey-100-border {
  border-color: #F5F5F5 !important;
}

.grey-100-border-top {
  border-top-color: #F5F5F5 !important;
}

.grey-100-border-right {
  border-right-color: #F5F5F5 !important;
}

.grey-100-border-bottom {
  border-bottom-color: #F5F5F5 !important;
}

.grey-100-border-left {
  border-left-color: #F5F5F5 !important;
}

.grey-200-bg {
  background-color: #EEEEEE !important;
}

.md-grey-200-bg {
  background-color: #EEEEEE !important;
  color: rgba(0, 0, 0, 0.87);
}

.grey-200-fg {
  color: #EEEEEE !important;
}

.grey-200-border {
  border-color: #EEEEEE !important;
}

.grey-200-border-top {
  border-top-color: #EEEEEE !important;
}

.grey-200-border-right {
  border-right-color: #EEEEEE !important;
}

.grey-200-border-bottom {
  border-bottom-color: #EEEEEE !important;
}

.grey-200-border-left {
  border-left-color: #EEEEEE !important;
}

.grey-300-bg {
  background-color: #E0E0E0 !important;
}

.md-grey-300-bg {
  background-color: #E0E0E0 !important;
  color: rgba(0, 0, 0, 0.87);
}

.grey-300-fg {
  color: #E0E0E0 !important;
}

.grey-300-border {
  border-color: #E0E0E0 !important;
}

.grey-300-border-top {
  border-top-color: #E0E0E0 !important;
}

.grey-300-border-right {
  border-right-color: #E0E0E0 !important;
}

.grey-300-border-bottom {
  border-bottom-color: #E0E0E0 !important;
}

.grey-300-border-left {
  border-left-color: #E0E0E0 !important;
}

.grey-400-bg {
  background-color: #BDBDBD !important;
}

.md-grey-400-bg {
  background-color: #BDBDBD !important;
  color: rgba(0, 0, 0, 0.87);
}

.grey-400-fg {
  color: #BDBDBD !important;
}

.grey-400-border {
  border-color: #BDBDBD !important;
}

.grey-400-border-top {
  border-top-color: #BDBDBD !important;
}

.grey-400-border-right {
  border-right-color: #BDBDBD !important;
}

.grey-400-border-bottom {
  border-bottom-color: #BDBDBD !important;
}

.grey-400-border-left {
  border-left-color: #BDBDBD !important;
}

.grey-500-bg {
  background-color: #9E9E9E !important;
}

.md-grey-500-bg {
  background-color: #9E9E9E !important;
  color: rgba(0, 0, 0, 0.87);
}

.grey-500-fg {
  color: #9E9E9E !important;
}

.grey-500-border {
  border-color: #9E9E9E !important;
}

.grey-500-border-top {
  border-top-color: #9E9E9E !important;
}

.grey-500-border-right {
  border-right-color: #9E9E9E !important;
}

.grey-500-border-bottom {
  border-bottom-color: #9E9E9E !important;
}

.grey-500-border-left {
  border-left-color: #9E9E9E !important;
}

.md-grey-bg {
  background-color: #9E9E9E !important;
  color: rgba(0, 0, 0, 0.87);
}

.grey-bg {
  background-color: #9E9E9E !important;
}

.grey-fg {
  color: #9E9E9E !important;
}

.grey-border {
  border-color: #9E9E9E !important;
}

.grey-border-top {
  border-top-color: #9E9E9E !important;
}

.grey-border-right {
  border-right-color: #9E9E9E !important;
}

.grey-border-bottom {
  border-bottom-color: #9E9E9E !important;
}

.grey-border-left {
  border-left-color: #9E9E9E !important;
}

.grey-600-bg {
  background-color: #757575 !important;
}

.md-grey-600-bg {
  background-color: #757575 !important;
  color: rgba(255, 255, 255, 0.87);
}

.grey-600-fg {
  color: #757575 !important;
}

.grey-600-border {
  border-color: #757575 !important;
}

.grey-600-border-top {
  border-top-color: #757575 !important;
}

.grey-600-border-right {
  border-right-color: #757575 !important;
}

.grey-600-border-bottom {
  border-bottom-color: #757575 !important;
}

.grey-600-border-left {
  border-left-color: #757575 !important;
}

.grey-700-bg {
  background-color: #616161 !important;
}

.md-grey-700-bg {
  background-color: #616161 !important;
  color: rgba(255, 255, 255, 0.87);
}

.grey-700-fg {
  color: #616161 !important;
}

.grey-700-border {
  border-color: #616161 !important;
}

.grey-700-border-top {
  border-top-color: #616161 !important;
}

.grey-700-border-right {
  border-right-color: #616161 !important;
}

.grey-700-border-bottom {
  border-bottom-color: #616161 !important;
}

.grey-700-border-left {
  border-left-color: #616161 !important;
}

.grey-800-bg {
  background-color: #424242 !important;
}

.md-grey-800-bg {
  background-color: #424242 !important;
  color: rgba(255, 255, 255, 0.87);
}

.grey-800-fg {
  color: #424242 !important;
}

.grey-800-border {
  border-color: #424242 !important;
}

.grey-800-border-top {
  border-top-color: #424242 !important;
}

.grey-800-border-right {
  border-right-color: #424242 !important;
}

.grey-800-border-bottom {
  border-bottom-color: #424242 !important;
}

.grey-800-border-left {
  border-left-color: #424242 !important;
}

.grey-900-bg {
  background-color: #212121 !important;
}

.md-grey-900-bg {
  background-color: #212121 !important;
  color: rgba(255, 255, 255, 0.87);
}

.grey-900-fg {
  color: #212121 !important;
}

.grey-900-border {
  border-color: #212121 !important;
}

.grey-900-border-top {
  border-top-color: #212121 !important;
}

.grey-900-border-right {
  border-right-color: #212121 !important;
}

.grey-900-border-bottom {
  border-bottom-color: #212121 !important;
}

.grey-900-border-left {
  border-left-color: #212121 !important;
}

.grey-1000-bg {
  background-color: #000000 !important;
}

.md-grey-1000-bg {
  background-color: #000000 !important;
}

.grey-1000-fg {
  color: #000000 !important;
}

.grey-1000-border {
  border-color: #000000 !important;
}

.grey-1000-border-top {
  border-top-color: #000000 !important;
}

.grey-1000-border-right {
  border-right-color: #000000 !important;
}

.grey-1000-border-bottom {
  border-bottom-color: #000000 !important;
}

.grey-1000-border-left {
  border-left-color: #000000 !important;
}

.grey-A100-bg {
  background-color: #FFFFFF !important;
}

.md-grey-A100-bg {
  background-color: #FFFFFF !important;
  color: rgba(0, 0, 0, 0.87);
}

.grey-A100-fg {
  color: #FFFFFF !important;
}

.grey-A100-border {
  border-color: #FFFFFF !important;
}

.grey-A100-border-top {
  border-top-color: #FFFFFF !important;
}

.grey-A100-border-right {
  border-right-color: #FFFFFF !important;
}

.grey-A100-border-bottom {
  border-bottom-color: #FFFFFF !important;
}

.grey-A100-border-left {
  border-left-color: #FFFFFF !important;
}

.grey-A200-bg {
  background-color: #EEEEEE !important;
}

.md-grey-A200-bg {
  background-color: #EEEEEE !important;
  color: rgba(0, 0, 0, 0.87);
}

.grey-A200-fg {
  color: #EEEEEE !important;
}

.grey-A200-border {
  border-color: #EEEEEE !important;
}

.grey-A200-border-top {
  border-top-color: #EEEEEE !important;
}

.grey-A200-border-right {
  border-right-color: #EEEEEE !important;
}

.grey-A200-border-bottom {
  border-bottom-color: #EEEEEE !important;
}

.grey-A200-border-left {
  border-left-color: #EEEEEE !important;
}

.grey-A400-bg {
  background-color: #BDBDBD !important;
}

.md-grey-A400-bg {
  background-color: #BDBDBD !important;
  color: rgba(0, 0, 0, 0.87);
}

.grey-A400-fg {
  color: #BDBDBD !important;
}

.grey-A400-border {
  border-color: #BDBDBD !important;
}

.grey-A400-border-top {
  border-top-color: #BDBDBD !important;
}

.grey-A400-border-right {
  border-right-color: #BDBDBD !important;
}

.grey-A400-border-bottom {
  border-bottom-color: #BDBDBD !important;
}

.grey-A400-border-left {
  border-left-color: #BDBDBD !important;
}

.grey-A700-bg {
  background-color: #616161 !important;
}

.md-grey-A700-bg {
  background-color: #616161 !important;
  color: rgba(0, 0, 0, 0.87);
}

.grey-A700-fg {
  color: #616161 !important;
}

.grey-A700-border {
  border-color: #616161 !important;
}

.grey-A700-border-top {
  border-top-color: #616161 !important;
}

.grey-A700-border-right {
  border-right-color: #616161 !important;
}

.grey-A700-border-bottom {
  border-bottom-color: #616161 !important;
}

.grey-A700-border-left {
  border-left-color: #616161 !important;
}

.blue-grey-50-bg {
  background-color: #ECEFF1 !important;
}

.md-blue-grey-50-bg {
  background-color: #ECEFF1 !important;
  color: rgba(0, 0, 0, 0.87);
}

.blue-grey-50-fg {
  color: #ECEFF1 !important;
}

.blue-grey-50-border {
  border-color: #ECEFF1 !important;
}

.blue-grey-50-border-top {
  border-top-color: #ECEFF1 !important;
}

.blue-grey-50-border-right {
  border-right-color: #ECEFF1 !important;
}

.blue-grey-50-border-bottom {
  border-bottom-color: #ECEFF1 !important;
}

.blue-grey-50-border-left {
  border-left-color: #ECEFF1 !important;
}

.blue-grey-100-bg {
  background-color: #CFD8DC !important;
}

.md-blue-grey-100-bg {
  background-color: #CFD8DC !important;
  color: rgba(0, 0, 0, 0.87);
}

.blue-grey-100-fg {
  color: #CFD8DC !important;
}

.blue-grey-100-border {
  border-color: #CFD8DC !important;
}

.blue-grey-100-border-top {
  border-top-color: #CFD8DC !important;
}

.blue-grey-100-border-right {
  border-right-color: #CFD8DC !important;
}

.blue-grey-100-border-bottom {
  border-bottom-color: #CFD8DC !important;
}

.blue-grey-100-border-left {
  border-left-color: #CFD8DC !important;
}

.blue-grey-200-bg {
  background-color: #B0BEC5 !important;
}

.md-blue-grey-200-bg {
  background-color: #B0BEC5 !important;
  color: rgba(0, 0, 0, 0.87);
}

.blue-grey-200-fg {
  color: #B0BEC5 !important;
}

.blue-grey-200-border {
  border-color: #B0BEC5 !important;
}

.blue-grey-200-border-top {
  border-top-color: #B0BEC5 !important;
}

.blue-grey-200-border-right {
  border-right-color: #B0BEC5 !important;
}

.blue-grey-200-border-bottom {
  border-bottom-color: #B0BEC5 !important;
}

.blue-grey-200-border-left {
  border-left-color: #B0BEC5 !important;
}

.blue-grey-300-bg {
  background-color: #90A4AE !important;
}

.md-blue-grey-300-bg {
  background-color: #90A4AE !important;
  color: rgba(0, 0, 0, 0.87);
}

.blue-grey-300-fg {
  color: #90A4AE !important;
}

.blue-grey-300-border {
  border-color: #90A4AE !important;
}

.blue-grey-300-border-top {
  border-top-color: #90A4AE !important;
}

.blue-grey-300-border-right {
  border-right-color: #90A4AE !important;
}

.blue-grey-300-border-bottom {
  border-bottom-color: #90A4AE !important;
}

.blue-grey-300-border-left {
  border-left-color: #90A4AE !important;
}

.blue-grey-400-bg {
  background-color: #78909C !important;
}

.md-blue-grey-400-bg {
  background-color: #78909C !important;
  color: rgb(255, 255, 255);
}

.blue-grey-400-fg {
  color: #78909C !important;
}

.blue-grey-400-border {
  border-color: #78909C !important;
}

.blue-grey-400-border-top {
  border-top-color: #78909C !important;
}

.blue-grey-400-border-right {
  border-right-color: #78909C !important;
}

.blue-grey-400-border-bottom {
  border-bottom-color: #78909C !important;
}

.blue-grey-400-border-left {
  border-left-color: #78909C !important;
}

.blue-grey-500-bg {
  background-color: #607D8B !important;
}

.md-blue-grey-500-bg {
  background-color: #607D8B !important;
  color: rgb(255, 255, 255);
}

.blue-grey-500-fg {
  color: #607D8B !important;
}

.blue-grey-500-border {
  border-color: #607D8B !important;
}

.blue-grey-500-border-top {
  border-top-color: #607D8B !important;
}

.blue-grey-500-border-right {
  border-right-color: #607D8B !important;
}

.blue-grey-500-border-bottom {
  border-bottom-color: #607D8B !important;
}

.blue-grey-500-border-left {
  border-left-color: #607D8B !important;
}

.md-blue-grey-bg {
  background-color: #607D8B !important;
  color: rgb(255, 255, 255);
}

.blue-grey-bg {
  background-color: #607D8B !important;
}

.blue-grey-fg {
  color: #607D8B !important;
}

.blue-grey-border {
  border-color: #607D8B !important;
}

.blue-grey-border-top {
  border-top-color: #607D8B !important;
}

.blue-grey-border-right {
  border-right-color: #607D8B !important;
}

.blue-grey-border-bottom {
  border-bottom-color: #607D8B !important;
}

.blue-grey-border-left {
  border-left-color: #607D8B !important;
}

.blue-grey-600-bg {
  background-color: #546E7A !important;
}

.md-blue-grey-600-bg {
  background-color: #546E7A !important;
  color: rgba(255, 255, 255, 0.87);
}

.blue-grey-600-fg {
  color: #546E7A !important;
}

.blue-grey-600-border {
  border-color: #546E7A !important;
}

.blue-grey-600-border-top {
  border-top-color: #546E7A !important;
}

.blue-grey-600-border-right {
  border-right-color: #546E7A !important;
}

.blue-grey-600-border-bottom {
  border-bottom-color: #546E7A !important;
}

.blue-grey-600-border-left {
  border-left-color: #546E7A !important;
}

.blue-grey-700-bg {
  background-color: #455A64 !important;
}

.md-blue-grey-700-bg {
  background-color: #455A64 !important;
  color: rgba(255, 255, 255, 0.87);
}

.blue-grey-700-fg {
  color: #455A64 !important;
}

.blue-grey-700-border {
  border-color: #455A64 !important;
}

.blue-grey-700-border-top {
  border-top-color: #455A64 !important;
}

.blue-grey-700-border-right {
  border-right-color: #455A64 !important;
}

.blue-grey-700-border-bottom {
  border-bottom-color: #455A64 !important;
}

.blue-grey-700-border-left {
  border-left-color: #455A64 !important;
}

.blue-grey-800-bg {
  background-color: #37474F !important;
}

.md-blue-grey-800-bg {
  background-color: #37474F !important;
  color: rgba(255, 255, 255, 0.87);
}

.blue-grey-800-fg {
  color: #37474F !important;
}

.blue-grey-800-border {
  border-color: #37474F !important;
}

.blue-grey-800-border-top {
  border-top-color: #37474F !important;
}

.blue-grey-800-border-right {
  border-right-color: #37474F !important;
}

.blue-grey-800-border-bottom {
  border-bottom-color: #37474F !important;
}

.blue-grey-800-border-left {
  border-left-color: #37474F !important;
}

.blue-grey-900-bg {
  background-color: #263238 !important;
}

.md-blue-grey-900-bg {
  background-color: #263238 !important;
  color: rgba(255, 255, 255, 0.87);
}

.blue-grey-900-fg {
  color: #263238 !important;
}

.blue-grey-900-border {
  border-color: #263238 !important;
}

.blue-grey-900-border-top {
  border-top-color: #263238 !important;
}

.blue-grey-900-border-right {
  border-right-color: #263238 !important;
}

.blue-grey-900-border-bottom {
  border-bottom-color: #263238 !important;
}

.blue-grey-900-border-left {
  border-left-color: #263238 !important;
}

.blue-grey-A100-bg {
  background-color: #CFD8DC !important;
}

.md-blue-grey-A100-bg {
  background-color: #CFD8DC !important;
  color: rgba(255, 255, 255, 0.87);
}

.blue-grey-A100-fg {
  color: #CFD8DC !important;
}

.blue-grey-A100-border {
  border-color: #CFD8DC !important;
}

.blue-grey-A100-border-top {
  border-top-color: #CFD8DC !important;
}

.blue-grey-A100-border-right {
  border-right-color: #CFD8DC !important;
}

.blue-grey-A100-border-bottom {
  border-bottom-color: #CFD8DC !important;
}

.blue-grey-A100-border-left {
  border-left-color: #CFD8DC !important;
}

.blue-grey-A200-bg {
  background-color: #B0BEC5 !important;
}

.md-blue-grey-A200-bg {
  background-color: #B0BEC5 !important;
  color: rgba(255, 255, 255, 0.87);
}

.blue-grey-A200-fg {
  color: #B0BEC5 !important;
}

.blue-grey-A200-border {
  border-color: #B0BEC5 !important;
}

.blue-grey-A200-border-top {
  border-top-color: #B0BEC5 !important;
}

.blue-grey-A200-border-right {
  border-right-color: #B0BEC5 !important;
}

.blue-grey-A200-border-bottom {
  border-bottom-color: #B0BEC5 !important;
}

.blue-grey-A200-border-left {
  border-left-color: #B0BEC5 !important;
}

.blue-grey-A400-bg {
  background-color: #78909C !important;
}

.md-blue-grey-A400-bg {
  background-color: #78909C !important;
  color: rgba(255, 255, 255, 0.87);
}

.blue-grey-A400-fg {
  color: #78909C !important;
}

.blue-grey-A400-border {
  border-color: #78909C !important;
}

.blue-grey-A400-border-top {
  border-top-color: #78909C !important;
}

.blue-grey-A400-border-right {
  border-right-color: #78909C !important;
}

.blue-grey-A400-border-bottom {
  border-bottom-color: #78909C !important;
}

.blue-grey-A400-border-left {
  border-left-color: #78909C !important;
}

.blue-grey-A700-bg {
  background-color: #455A64 !important;
}

.md-blue-grey-A700-bg {
  background-color: #455A64 !important;
  color: rgba(255, 255, 255, 0.87);
}

.blue-grey-A700-fg {
  color: #455A64 !important;
}

.blue-grey-A700-border {
  border-color: #455A64 !important;
}

.blue-grey-A700-border-top {
  border-top-color: #455A64 !important;
}

.blue-grey-A700-border-right {
  border-right-color: #455A64 !important;
}

.blue-grey-A700-border-bottom {
  border-bottom-color: #455A64 !important;
}

.blue-grey-A700-border-left {
  border-left-color: #455A64 !important;
}

.white-500-bg {
  background-color: #FFFFFF !important;
}

.md-white-500-bg {
  background-color: #FFFFFF !important;
  color: rgba(0, 0, 0, 0.87);
}

.white-500-fg {
  color: #FFFFFF !important;
}

.white-500-border {
  border-color: #FFFFFF !important;
}

.white-500-border-top {
  border-top-color: #FFFFFF !important;
}

.white-500-border-right {
  border-right-color: #FFFFFF !important;
}

.white-500-border-bottom {
  border-bottom-color: #FFFFFF !important;
}

.white-500-border-left {
  border-left-color: #FFFFFF !important;
}

.md-white-bg {
  background-color: #FFFFFF !important;
  color: rgba(0, 0, 0, 0.87);
}

.white-bg {
  background-color: #FFFFFF !important;
}

.white-fg {
  color: #FFFFFF !important;
}

.white-border {
  border-color: #FFFFFF !important;
}

.white-border-top {
  border-top-color: #FFFFFF !important;
}

.white-border-right {
  border-right-color: #FFFFFF !important;
}

.white-border-bottom {
  border-bottom-color: #FFFFFF !important;
}

.white-border-left {
  border-left-color: #FFFFFF !important;
}

.black-500-bg {
  background-color: #000000 !important;
}

.md-black-500-bg {
  background-color: #000000 !important;
  color: rgba(255, 255, 255, 0.87);
}

.black-500-fg {
  color: #000000 !important;
}

.black-500-border {
  border-color: #000000 !important;
}

.black-500-border-top {
  border-top-color: #000000 !important;
}

.black-500-border-right {
  border-right-color: #000000 !important;
}

.black-500-border-bottom {
  border-bottom-color: #000000 !important;
}

.black-500-border-left {
  border-left-color: #000000 !important;
}

.md-black-bg {
  background-color: #000000 !important;
  color: rgba(255, 255, 255, 0.87);
}

.black-bg {
  background-color: #000000 !important;
}

.black-fg {
  color: #000000 !important;
}

.black-border {
  border-color: #000000 !important;
}

.black-border-top {
  border-top-color: #000000 !important;
}

.black-border-right {
  border-right-color: #000000 !important;
}

.black-border-bottom {
  border-bottom-color: #000000 !important;
}

.black-border-left {
  border-left-color: #000000 !important;
}

/*----------------------------------------------------------------*/
/*  Icons
/*----------------------------------------------------------------*/
md-icon[md-font-icon],
i {
  font-size: 24px;
  width: 24px;
  height: 24px;
  line-height: 24px;
}
md-icon[md-font-icon].s4,
i.s4 {
  font-size: 4px !important;
  width: 4px !important;
  height: 4px !important;
  line-height: 4px !important;
}
md-icon[md-font-icon].s6,
i.s6 {
  font-size: 6px !important;
  width: 6px !important;
  height: 6px !important;
  line-height: 6px !important;
}
md-icon[md-font-icon].s8,
i.s8 {
  font-size: 8px !important;
  width: 8px !important;
  height: 8px !important;
  line-height: 8px !important;
}
md-icon[md-font-icon].s10,
i.s10 {
  font-size: 10px !important;
  width: 10px !important;
  height: 10px !important;
  line-height: 10px !important;
}
md-icon[md-font-icon].s12,
i.s12 {
  font-size: 12px !important;
  width: 12px !important;
  height: 12px !important;
  line-height: 12px !important;
}
md-icon[md-font-icon].s14,
i.s14 {
  font-size: 14px !important;
  width: 14px !important;
  height: 14px !important;
  line-height: 14px !important;
}
md-icon[md-font-icon].s16,
i.s16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
  line-height: 16px !important;
}
md-icon[md-font-icon].s18,
i.s18 {
  font-size: 18px !important;
  width: 18px !important;
  height: 18px !important;
  line-height: 18px !important;
}
md-icon[md-font-icon].s20,
i.s20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
  line-height: 20px !important;
}
md-icon[md-font-icon].s22,
i.s22 {
  font-size: 22px !important;
  width: 22px !important;
  height: 22px !important;
  line-height: 22px !important;
}
md-icon[md-font-icon].s24,
i.s24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
  line-height: 24px !important;
}
md-icon[md-font-icon].s26,
i.s26 {
  font-size: 26px !important;
  width: 26px !important;
  height: 26px !important;
  line-height: 26px !important;
}
md-icon[md-font-icon].s28,
i.s28 {
  font-size: 28px !important;
  width: 28px !important;
  height: 28px !important;
  line-height: 28px !important;
}
md-icon[md-font-icon].s30,
i.s30 {
  font-size: 30px !important;
  width: 30px !important;
  height: 30px !important;
  line-height: 30px !important;
}
md-icon[md-font-icon].s32,
i.s32 {
  font-size: 32px !important;
  width: 32px !important;
  height: 32px !important;
  line-height: 32px !important;
}
md-icon[md-font-icon].s34,
i.s34 {
  font-size: 34px !important;
  width: 34px !important;
  height: 34px !important;
  line-height: 34px !important;
}
md-icon[md-font-icon].s36,
i.s36 {
  font-size: 36px !important;
  width: 36px !important;
  height: 36px !important;
  line-height: 36px !important;
}
md-icon[md-font-icon].s38,
i.s38 {
  font-size: 38px !important;
  width: 38px !important;
  height: 38px !important;
  line-height: 38px !important;
}
md-icon[md-font-icon].s40,
i.s40 {
  font-size: 40px !important;
  width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
}
md-icon[md-font-icon].s42,
i.s42 {
  font-size: 42px !important;
  width: 42px !important;
  height: 42px !important;
  line-height: 42px !important;
}
md-icon[md-font-icon].s44,
i.s44 {
  font-size: 44px !important;
  width: 44px !important;
  height: 44px !important;
  line-height: 44px !important;
}
md-icon[md-font-icon].s46,
i.s46 {
  font-size: 46px !important;
  width: 46px !important;
  height: 46px !important;
  line-height: 46px !important;
}
md-icon[md-font-icon].s48,
i.s48 {
  font-size: 48px !important;
  width: 48px !important;
  height: 48px !important;
  line-height: 48px !important;
}
md-icon[md-font-icon].s50,
i.s50 {
  font-size: 50px !important;
  width: 50px !important;
  height: 50px !important;
  line-height: 50px !important;
}
md-icon[md-font-icon].s52,
i.s52 {
  font-size: 52px !important;
  width: 52px !important;
  height: 52px !important;
  line-height: 52px !important;
}
md-icon[md-font-icon].s54,
i.s54 {
  font-size: 54px !important;
  width: 54px !important;
  height: 54px !important;
  line-height: 54px !important;
}
md-icon[md-font-icon].s56,
i.s56 {
  font-size: 56px !important;
  width: 56px !important;
  height: 56px !important;
  line-height: 56px !important;
}
md-icon[md-font-icon].s58,
i.s58 {
  font-size: 58px !important;
  width: 58px !important;
  height: 58px !important;
  line-height: 58px !important;
}
md-icon[md-font-icon].s60,
i.s60 {
  font-size: 60px !important;
  width: 60px !important;
  height: 60px !important;
  line-height: 60px !important;
}
md-icon[md-font-icon].s62,
i.s62 {
  font-size: 62px !important;
  width: 62px !important;
  height: 62px !important;
  line-height: 62px !important;
}
md-icon[md-font-icon].s64,
i.s64 {
  font-size: 64px !important;
  width: 64px !important;
  height: 64px !important;
  line-height: 64px !important;
}
md-icon[md-font-icon].s66,
i.s66 {
  font-size: 66px !important;
  width: 66px !important;
  height: 66px !important;
  line-height: 66px !important;
}
md-icon[md-font-icon].s68,
i.s68 {
  font-size: 68px !important;
  width: 68px !important;
  height: 68px !important;
  line-height: 68px !important;
}
md-icon[md-font-icon].s70,
i.s70 {
  font-size: 70px !important;
  width: 70px !important;
  height: 70px !important;
  line-height: 70px !important;
}
md-icon[md-font-icon].s72,
i.s72 {
  font-size: 72px !important;
  width: 72px !important;
  height: 72px !important;
  line-height: 72px !important;
}
md-icon[md-font-icon].s74,
i.s74 {
  font-size: 74px !important;
  width: 74px !important;
  height: 74px !important;
  line-height: 74px !important;
}
md-icon[md-font-icon].s76,
i.s76 {
  font-size: 76px !important;
  width: 76px !important;
  height: 76px !important;
  line-height: 76px !important;
}
md-icon[md-font-icon].s78,
i.s78 {
  font-size: 78px !important;
  width: 78px !important;
  height: 78px !important;
  line-height: 78px !important;
}
md-icon[md-font-icon].s80,
i.s80 {
  font-size: 80px !important;
  width: 80px !important;
  height: 80px !important;
  line-height: 80px !important;
}
md-icon[md-font-icon].s82,
i.s82 {
  font-size: 82px !important;
  width: 82px !important;
  height: 82px !important;
  line-height: 82px !important;
}
md-icon[md-font-icon].s84,
i.s84 {
  font-size: 84px !important;
  width: 84px !important;
  height: 84px !important;
  line-height: 84px !important;
}
md-icon[md-font-icon].s86,
i.s86 {
  font-size: 86px !important;
  width: 86px !important;
  height: 86px !important;
  line-height: 86px !important;
}
md-icon[md-font-icon].s88,
i.s88 {
  font-size: 88px !important;
  width: 88px !important;
  height: 88px !important;
  line-height: 88px !important;
}
md-icon[md-font-icon].s90,
i.s90 {
  font-size: 90px !important;
  width: 90px !important;
  height: 90px !important;
  line-height: 90px !important;
}
md-icon[md-font-icon].s92,
i.s92 {
  font-size: 92px !important;
  width: 92px !important;
  height: 92px !important;
  line-height: 92px !important;
}
md-icon[md-font-icon].s94,
i.s94 {
  font-size: 94px !important;
  width: 94px !important;
  height: 94px !important;
  line-height: 94px !important;
}
md-icon[md-font-icon].s96,
i.s96 {
  font-size: 96px !important;
  width: 96px !important;
  height: 96px !important;
  line-height: 96px !important;
}
md-icon[md-font-icon].s98,
i.s98 {
  font-size: 98px !important;
  width: 98px !important;
  height: 98px !important;
  line-height: 98px !important;
}
md-icon[md-font-icon].s100,
i.s100 {
  font-size: 100px !important;
  width: 100px !important;
  height: 100px !important;
  line-height: 100px !important;
}
md-icon[md-font-icon].s102,
i.s102 {
  font-size: 102px !important;
  width: 102px !important;
  height: 102px !important;
  line-height: 102px !important;
}
md-icon[md-font-icon].s104,
i.s104 {
  font-size: 104px !important;
  width: 104px !important;
  height: 104px !important;
  line-height: 104px !important;
}
md-icon[md-font-icon].s106,
i.s106 {
  font-size: 106px !important;
  width: 106px !important;
  height: 106px !important;
  line-height: 106px !important;
}
md-icon[md-font-icon].s108,
i.s108 {
  font-size: 108px !important;
  width: 108px !important;
  height: 108px !important;
  line-height: 108px !important;
}
md-icon[md-font-icon].s110,
i.s110 {
  font-size: 110px !important;
  width: 110px !important;
  height: 110px !important;
  line-height: 110px !important;
}
md-icon[md-font-icon].s112,
i.s112 {
  font-size: 112px !important;
  width: 112px !important;
  height: 112px !important;
  line-height: 112px !important;
}
md-icon[md-font-icon].s114,
i.s114 {
  font-size: 114px !important;
  width: 114px !important;
  height: 114px !important;
  line-height: 114px !important;
}
md-icon[md-font-icon].s116,
i.s116 {
  font-size: 116px !important;
  width: 116px !important;
  height: 116px !important;
  line-height: 116px !important;
}
md-icon[md-font-icon].s118,
i.s118 {
  font-size: 118px !important;
  width: 118px !important;
  height: 118px !important;
  line-height: 118px !important;
}
md-icon[md-font-icon].s120,
i.s120 {
  font-size: 120px !important;
  width: 120px !important;
  height: 120px !important;
  line-height: 120px !important;
}
md-icon[md-font-icon].s122,
i.s122 {
  font-size: 122px !important;
  width: 122px !important;
  height: 122px !important;
  line-height: 122px !important;
}
md-icon[md-font-icon].s124,
i.s124 {
  font-size: 124px !important;
  width: 124px !important;
  height: 124px !important;
  line-height: 124px !important;
}
md-icon[md-font-icon].s126,
i.s126 {
  font-size: 126px !important;
  width: 126px !important;
  height: 126px !important;
  line-height: 126px !important;
}
md-icon[md-font-icon].s128,
i.s128 {
  font-size: 128px !important;
  width: 128px !important;
  height: 128px !important;
  line-height: 128px !important;
}
md-icon[md-font-icon].s130,
i.s130 {
  font-size: 130px !important;
  width: 130px !important;
  height: 130px !important;
  line-height: 130px !important;
}
md-icon[md-font-icon].s132,
i.s132 {
  font-size: 132px !important;
  width: 132px !important;
  height: 132px !important;
  line-height: 132px !important;
}
md-icon[md-font-icon].s134,
i.s134 {
  font-size: 134px !important;
  width: 134px !important;
  height: 134px !important;
  line-height: 134px !important;
}
md-icon[md-font-icon].s136,
i.s136 {
  font-size: 136px !important;
  width: 136px !important;
  height: 136px !important;
  line-height: 136px !important;
}
md-icon[md-font-icon].s138,
i.s138 {
  font-size: 138px !important;
  width: 138px !important;
  height: 138px !important;
  line-height: 138px !important;
}
md-icon[md-font-icon].s140,
i.s140 {
  font-size: 140px !important;
  width: 140px !important;
  height: 140px !important;
  line-height: 140px !important;
}
md-icon[md-font-icon].s142,
i.s142 {
  font-size: 142px !important;
  width: 142px !important;
  height: 142px !important;
  line-height: 142px !important;
}
md-icon[md-font-icon].s144,
i.s144 {
  font-size: 144px !important;
  width: 144px !important;
  height: 144px !important;
  line-height: 144px !important;
}
md-icon[md-font-icon].s146,
i.s146 {
  font-size: 146px !important;
  width: 146px !important;
  height: 146px !important;
  line-height: 146px !important;
}
md-icon[md-font-icon].s148,
i.s148 {
  font-size: 148px !important;
  width: 148px !important;
  height: 148px !important;
  line-height: 148px !important;
}
md-icon[md-font-icon].s150,
i.s150 {
  font-size: 150px !important;
  width: 150px !important;
  height: 150px !important;
  line-height: 150px !important;
}
md-icon[md-font-icon].s152,
i.s152 {
  font-size: 152px !important;
  width: 152px !important;
  height: 152px !important;
  line-height: 152px !important;
}
md-icon[md-font-icon].s154,
i.s154 {
  font-size: 154px !important;
  width: 154px !important;
  height: 154px !important;
  line-height: 154px !important;
}
md-icon[md-font-icon].s156,
i.s156 {
  font-size: 156px !important;
  width: 156px !important;
  height: 156px !important;
  line-height: 156px !important;
}
md-icon[md-font-icon].s158,
i.s158 {
  font-size: 158px !important;
  width: 158px !important;
  height: 158px !important;
  line-height: 158px !important;
}
md-icon[md-font-icon].s160,
i.s160 {
  font-size: 160px !important;
  width: 160px !important;
  height: 160px !important;
  line-height: 160px !important;
}
md-icon[md-font-icon].s162,
i.s162 {
  font-size: 162px !important;
  width: 162px !important;
  height: 162px !important;
  line-height: 162px !important;
}
md-icon[md-font-icon].s164,
i.s164 {
  font-size: 164px !important;
  width: 164px !important;
  height: 164px !important;
  line-height: 164px !important;
}
md-icon[md-font-icon].s166,
i.s166 {
  font-size: 166px !important;
  width: 166px !important;
  height: 166px !important;
  line-height: 166px !important;
}
md-icon[md-font-icon].s168,
i.s168 {
  font-size: 168px !important;
  width: 168px !important;
  height: 168px !important;
  line-height: 168px !important;
}
md-icon[md-font-icon].s170,
i.s170 {
  font-size: 170px !important;
  width: 170px !important;
  height: 170px !important;
  line-height: 170px !important;
}
md-icon[md-font-icon].s172,
i.s172 {
  font-size: 172px !important;
  width: 172px !important;
  height: 172px !important;
  line-height: 172px !important;
}
md-icon[md-font-icon].s174,
i.s174 {
  font-size: 174px !important;
  width: 174px !important;
  height: 174px !important;
  line-height: 174px !important;
}
md-icon[md-font-icon].s176,
i.s176 {
  font-size: 176px !important;
  width: 176px !important;
  height: 176px !important;
  line-height: 176px !important;
}
md-icon[md-font-icon].s178,
i.s178 {
  font-size: 178px !important;
  width: 178px !important;
  height: 178px !important;
  line-height: 178px !important;
}
md-icon[md-font-icon].s180,
i.s180 {
  font-size: 180px !important;
  width: 180px !important;
  height: 180px !important;
  line-height: 180px !important;
}
md-icon[md-font-icon].s182,
i.s182 {
  font-size: 182px !important;
  width: 182px !important;
  height: 182px !important;
  line-height: 182px !important;
}
md-icon[md-font-icon].s184,
i.s184 {
  font-size: 184px !important;
  width: 184px !important;
  height: 184px !important;
  line-height: 184px !important;
}
md-icon[md-font-icon].s186,
i.s186 {
  font-size: 186px !important;
  width: 186px !important;
  height: 186px !important;
  line-height: 186px !important;
}
md-icon[md-font-icon].s188,
i.s188 {
  font-size: 188px !important;
  width: 188px !important;
  height: 188px !important;
  line-height: 188px !important;
}
md-icon[md-font-icon].s190,
i.s190 {
  font-size: 190px !important;
  width: 190px !important;
  height: 190px !important;
  line-height: 190px !important;
}
md-icon[md-font-icon].s192,
i.s192 {
  font-size: 192px !important;
  width: 192px !important;
  height: 192px !important;
  line-height: 192px !important;
}
md-icon[md-font-icon].s194,
i.s194 {
  font-size: 194px !important;
  width: 194px !important;
  height: 194px !important;
  line-height: 194px !important;
}
md-icon[md-font-icon].s196,
i.s196 {
  font-size: 196px !important;
  width: 196px !important;
  height: 196px !important;
  line-height: 196px !important;
}
md-icon[md-font-icon].s198,
i.s198 {
  font-size: 198px !important;
  width: 198px !important;
  height: 198px !important;
  line-height: 198px !important;
}
md-icon[md-font-icon].s200,
i.s200 {
  font-size: 200px !important;
  width: 200px !important;
  height: 200px !important;
  line-height: 200px !important;
}
md-icon[md-font-icon].s202,
i.s202 {
  font-size: 202px !important;
  width: 202px !important;
  height: 202px !important;
  line-height: 202px !important;
}
md-icon[md-font-icon].s204,
i.s204 {
  font-size: 204px !important;
  width: 204px !important;
  height: 204px !important;
  line-height: 204px !important;
}
md-icon[md-font-icon].s206,
i.s206 {
  font-size: 206px !important;
  width: 206px !important;
  height: 206px !important;
  line-height: 206px !important;
}
md-icon[md-font-icon].s208,
i.s208 {
  font-size: 208px !important;
  width: 208px !important;
  height: 208px !important;
  line-height: 208px !important;
}
md-icon[md-font-icon].s210,
i.s210 {
  font-size: 210px !important;
  width: 210px !important;
  height: 210px !important;
  line-height: 210px !important;
}
md-icon[md-font-icon].s212,
i.s212 {
  font-size: 212px !important;
  width: 212px !important;
  height: 212px !important;
  line-height: 212px !important;
}
md-icon[md-font-icon].s214,
i.s214 {
  font-size: 214px !important;
  width: 214px !important;
  height: 214px !important;
  line-height: 214px !important;
}
md-icon[md-font-icon].s216,
i.s216 {
  font-size: 216px !important;
  width: 216px !important;
  height: 216px !important;
  line-height: 216px !important;
}
md-icon[md-font-icon].s218,
i.s218 {
  font-size: 218px !important;
  width: 218px !important;
  height: 218px !important;
  line-height: 218px !important;
}
md-icon[md-font-icon].s220,
i.s220 {
  font-size: 220px !important;
  width: 220px !important;
  height: 220px !important;
  line-height: 220px !important;
}
md-icon[md-font-icon].s222,
i.s222 {
  font-size: 222px !important;
  width: 222px !important;
  height: 222px !important;
  line-height: 222px !important;
}
md-icon[md-font-icon].s224,
i.s224 {
  font-size: 224px !important;
  width: 224px !important;
  height: 224px !important;
  line-height: 224px !important;
}
md-icon[md-font-icon].s226,
i.s226 {
  font-size: 226px !important;
  width: 226px !important;
  height: 226px !important;
  line-height: 226px !important;
}
md-icon[md-font-icon].s228,
i.s228 {
  font-size: 228px !important;
  width: 228px !important;
  height: 228px !important;
  line-height: 228px !important;
}
md-icon[md-font-icon].s230,
i.s230 {
  font-size: 230px !important;
  width: 230px !important;
  height: 230px !important;
  line-height: 230px !important;
}
md-icon[md-font-icon].s232,
i.s232 {
  font-size: 232px !important;
  width: 232px !important;
  height: 232px !important;
  line-height: 232px !important;
}
md-icon[md-font-icon].s234,
i.s234 {
  font-size: 234px !important;
  width: 234px !important;
  height: 234px !important;
  line-height: 234px !important;
}
md-icon[md-font-icon].s236,
i.s236 {
  font-size: 236px !important;
  width: 236px !important;
  height: 236px !important;
  line-height: 236px !important;
}
md-icon[md-font-icon].s238,
i.s238 {
  font-size: 238px !important;
  width: 238px !important;
  height: 238px !important;
  line-height: 238px !important;
}
md-icon[md-font-icon].s240,
i.s240 {
  font-size: 240px !important;
  width: 240px !important;
  height: 240px !important;
  line-height: 240px !important;
}
md-icon[md-font-icon].s242,
i.s242 {
  font-size: 242px !important;
  width: 242px !important;
  height: 242px !important;
  line-height: 242px !important;
}
md-icon[md-font-icon].s244,
i.s244 {
  font-size: 244px !important;
  width: 244px !important;
  height: 244px !important;
  line-height: 244px !important;
}
md-icon[md-font-icon].s246,
i.s246 {
  font-size: 246px !important;
  width: 246px !important;
  height: 246px !important;
  line-height: 246px !important;
}
md-icon[md-font-icon].s248,
i.s248 {
  font-size: 248px !important;
  width: 248px !important;
  height: 248px !important;
  line-height: 248px !important;
}
md-icon[md-font-icon].s250,
i.s250 {
  font-size: 250px !important;
  width: 250px !important;
  height: 250px !important;
  line-height: 250px !important;
}
md-icon[md-font-icon].s252,
i.s252 {
  font-size: 252px !important;
  width: 252px !important;
  height: 252px !important;
  line-height: 252px !important;
}
md-icon[md-font-icon].s254,
i.s254 {
  font-size: 254px !important;
  width: 254px !important;
  height: 254px !important;
  line-height: 254px !important;
}
md-icon[md-font-icon].s256,
i.s256 {
  font-size: 256px !important;
  width: 256px !important;
  height: 256px !important;
  line-height: 256px !important;
}

@font-face {
  font-family: "icomoon";
  src: url("../assets/icons/fonts/icomoon.eot?5km1tg");
  src: url("../assets/icons/fonts/icomoon.eot?#iefix5km1tg") format("embedded-opentype"), url("../assets/icons/fonts/icomoon.ttf?5km1tg") format("truetype"), url("../assets/icons/fonts/icomoon.woff?5km1tg") format("woff"), url("../assets/icons/fonts/icomoon.svg?5km1tg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^=icon-], [class*=" icon-"] {
  font-family: "icomoon", sans-serif;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-account-alert:before {
  content: "\e600";
}

.icon-account-box-outline:before {
  content: "\e601";
}

.icon-account-box:before {
  content: "\e602";
}

.icon-account-check:before {
  content: "\e603";
}

.icon-account-circle:before {
  content: "\e604";
}

.icon-account-key:before {
  content: "\e605";
}

.icon-account-location:before {
  content: "\e606";
}

.icon-account-minus:before {
  content: "\e607";
}

.icon-account-multiple-outline:before {
  content: "\e608";
}

.icon-account-multiple-plus:before {
  content: "\e609";
}

.icon-account-multiple:before {
  content: "\e60a";
}

.icon-account-network:before {
  content: "\e60b";
}

.icon-account-outline:before {
  content: "\e60c";
}

.icon-account-plus:before {
  content: "\e60d";
}

.icon-account-remove:before {
  content: "\e60e";
}

.icon-account-search:before {
  content: "\e60f";
}

.icon-account-switch:before {
  content: "\e610";
}

.icon-account:before {
  content: "\e611";
}

.icon-airballoon:before {
  content: "\e612";
}

.icon-airplane-off:before {
  content: "\e613";
}

.icon-airplane:before {
  content: "\e614";
}

.icon-alarm-check:before {
  content: "\e615";
}

.icon-alarm-multiple:before {
  content: "\e616";
}

.icon-alarm-off:before {
  content: "\e617";
}

.icon-alarm-plus:before {
  content: "\e618";
}

.icon-alarm:before {
  content: "\e619";
}

.icon-album:before {
  content: "\e61a";
}

.icon-alert-box:before {
  content: "\e61b";
}

.icon-alert-circle:before {
  content: "\e61c";
}

.icon-alert-octagon:before {
  content: "\e61d";
}

.icon-alert:before {
  content: "\e61e";
}

.icon-alpha:before {
  content: "\e61f";
}

.icon-alphabetical:before {
  content: "\e620";
}

.icon-amazon-clouddrive:before {
  content: "\e621";
}

.icon-amazon:before {
  content: "\e622";
}

.icon-ambulance:before {
  content: "\e623";
}

.icon-android-debug-bridge:before {
  content: "\e624";
}

.icon-android-studio:before {
  content: "\e625";
}

.icon-android:before {
  content: "\e626";
}

.icon-apple-finder:before {
  content: "\e627";
}

.icon-apple-ios:before {
  content: "\e628";
}

.icon-apple-mobileme:before {
  content: "\e629";
}

.icon-apple-safari:before {
  content: "\e62a";
}

.icon-apple:before {
  content: "\e62b";
}

.icon-appnet:before {
  content: "\e62c";
}

.icon-apps:before {
  content: "\e62d";
}

.icon-archive:before {
  content: "\e62e";
}

.icon-arrange-bring-forward:before {
  content: "\e62f";
}

.icon-arrange-bring-to-front:before {
  content: "\e630";
}

.icon-arrange-send-backward:before {
  content: "\e631";
}

.icon-arrange-send-to-back:before {
  content: "\e632";
}

.icon-arrow-all:before {
  content: "\e633";
}

.icon-arrow-bottom-left:before {
  content: "\e634";
}

.icon-arrow-bottom-right:before {
  content: "\e635";
}

.icon-arrow-collapse:before {
  content: "\e636";
}

.icon-arrow-down-bold-circle-outline:before {
  content: "\e637";
}

.icon-arrow-down-bold-circle:before {
  content: "\e638";
}

.icon-arrow-down-bold-hexagon-outline:before {
  content: "\e639";
}

.icon-arrow-down-bold:before {
  content: "\e63a";
}

.icon-arrow-down:before {
  content: "\e63b";
}

.icon-arrow-expand:before {
  content: "\e63c";
}

.icon-arrow-left-bold-circle-outline:before {
  content: "\e63d";
}

.icon-arrow-left-bold-circle:before {
  content: "\e63e";
}

.icon-arrow-left-bold-hexagon-outline:before {
  content: "\e63f";
}

.icon-arrow-left-bold:before {
  content: "\e640";
}

.icon-arrow-left:before {
  content: "\e641";
}

.icon-arrow-right-bold-circle-outline:before {
  content: "\e642";
}

.icon-arrow-right-bold-circle:before {
  content: "\e643";
}

.icon-arrow-right-bold-hexagon-outline:before {
  content: "\e644";
}

.icon-arrow-right-bold:before {
  content: "\e645";
}

.icon-arrow-right:before {
  content: "\e646";
}

.icon-arrow-top-left:before {
  content: "\e647";
}

.icon-arrow-top-right:before {
  content: "\e648";
}

.icon-arrow-up-bold-circle-outline:before {
  content: "\e649";
}

.icon-arrow-up-bold-circle:before {
  content: "\e64a";
}

.icon-arrow-up-bold-hexagon-outline:before {
  content: "\e64b";
}

.icon-arrow-up-bold:before {
  content: "\e64c";
}

.icon-arrow-up:before {
  content: "\e64d";
}

.icon-at:before {
  content: "\e64e";
}

.icon-attachment:before {
  content: "\e64f";
}

.icon-audiobook:before {
  content: "\e650";
}

.icon-auto-fix:before {
  content: "\e651";
}

.icon-auto-upload:before {
  content: "\e652";
}

.icon-baby:before {
  content: "\e653";
}

.icon-backburger:before {
  content: "\e654";
}

.icon-backup-restore:before {
  content: "\e655";
}

.icon-bank:before {
  content: "\e656";
}

.icon-barcode:before {
  content: "\e657";
}

.icon-barley:before {
  content: "\e658";
}

.icon-barrel:before {
  content: "\e659";
}

.icon-basecamp:before {
  content: "\e65a";
}

.icon-basket-fill:before {
  content: "\e65b";
}

.icon-basket-unfill:before {
  content: "\e65c";
}

.icon-basket:before {
  content: "\e65d";
}

.icon-battery-10:before {
  content: "\e65e";
}

.icon-battery-20:before {
  content: "\e65f";
}

.icon-battery-30:before {
  content: "\e660";
}

.icon-battery-40:before {
  content: "\e661";
}

.icon-battery-50:before {
  content: "\e662";
}

.icon-battery-60:before {
  content: "\e663";
}

.icon-battery-70:before {
  content: "\e664";
}

.icon-battery-80:before {
  content: "\e665";
}

.icon-battery-90:before {
  content: "\e666";
}

.icon-battery-alert:before {
  content: "\e667";
}

.icon-battery-charging-20:before {
  content: "\e668";
}

.icon-battery-charging-30:before {
  content: "\e669";
}

.icon-battery-charging-40:before {
  content: "\e66a";
}

.icon-battery-charging-60:before {
  content: "\e66b";
}

.icon-battery-charging-80:before {
  content: "\e66c";
}

.icon-battery-charging-90:before {
  content: "\e66d";
}

.icon-battery-charging-100:before {
  content: "\e66e";
}

.icon-battery-minus:before {
  content: "\e66f";
}

.icon-battery-negative:before {
  content: "\e670";
}

.icon-battery-outline:before {
  content: "\e671";
}

.icon-battery-plus:before {
  content: "\e672";
}

.icon-battery-positive:before {
  content: "\e673";
}

.icon-battery-standard:before {
  content: "\e674";
}

.icon-battery-unknown:before {
  content: "\e675";
}

.icon-battery:before {
  content: "\e676";
}

.icon-beach:before {
  content: "\e677";
}

.icon-beaker-empty-outline:before {
  content: "\e678";
}

.icon-beaker-empty:before {
  content: "\e679";
}

.icon-beaker-outline:before {
  content: "\e67a";
}

.icon-beaker:before {
  content: "\e67b";
}

.icon-beats:before {
  content: "\e67c";
}

.icon-beer:before {
  content: "\e67d";
}

.icon-behance:before {
  content: "\e67e";
}

.icon-bell-off:before {
  content: "\e67f";
}

.icon-bell-outline:before {
  content: "\e680";
}

.icon-bell-ring-outline:before {
  content: "\e681";
}

.icon-bell-ring:before {
  content: "\e682";
}

.icon-bell-sleep:before {
  content: "\e683";
}

.icon-bell:before {
  content: "\e684";
}

.icon-beta:before {
  content: "\e685";
}

.icon-bike:before {
  content: "\e686";
}

.icon-bing:before {
  content: "\e687";
}

.icon-binoculars:before {
  content: "\e688";
}

.icon-bio:before {
  content: "\e689";
}

.icon-bitbucket:before {
  content: "\e68a";
}

.icon-black-mesa:before {
  content: "\e68b";
}

.icon-blackberry:before {
  content: "\e68c";
}

.icon-blinds:before {
  content: "\e68d";
}

.icon-block-helper:before {
  content: "\e68e";
}

.icon-blogger:before {
  content: "\e68f";
}

.icon-bluetooth-audio:before {
  content: "\e690";
}

.icon-bluetooth-connect:before {
  content: "\e691";
}

.icon-bluetooth-settings:before {
  content: "\e692";
}

.icon-bluetooth-transfer:before {
  content: "\e693";
}

.icon-bluetooth:before {
  content: "\e694";
}

.icon-blur-linear:before {
  content: "\e695";
}

.icon-blur-off:before {
  content: "\e696";
}

.icon-blur-radial:before {
  content: "\e697";
}

.icon-blur:before {
  content: "\e698";
}

.icon-bone:before {
  content: "\e699";
}

.icon-book-multiple-variant:before {
  content: "\e69a";
}

.icon-book-multiple:before {
  content: "\e69b";
}

.icon-book-open:before {
  content: "\e69c";
}

.icon-book-variant:before {
  content: "\e69d";
}

.icon-book:before {
  content: "\e69e";
}

.icon-bookmark-check:before {
  content: "\e69f";
}

.icon-bookmark-music:before {
  content: "\e6a0";
}

.icon-bookmark-outline-plus:before {
  content: "\e6a1";
}

.icon-bookmark-outline:before {
  content: "\e6a2";
}

.icon-bookmark-plus:before {
  content: "\e6a3";
}

.icon-bookmark-remove:before {
  content: "\e6a4";
}

.icon-bookmark:before {
  content: "\e6a5";
}

.icon-border-all:before {
  content: "\e6a6";
}

.icon-border-bottom:before {
  content: "\e6a7";
}

.icon-border-color:before {
  content: "\e6a8";
}

.icon-border-horizontal:before {
  content: "\e6a9";
}

.icon-border-inside:before {
  content: "\e6aa";
}

.icon-border-left:before {
  content: "\e6ab";
}

.icon-border-none:before {
  content: "\e6ac";
}

.icon-border-outside:before {
  content: "\e6ad";
}

.icon-border-right:before {
  content: "\e6ae";
}

.icon-border-top:before {
  content: "\e6af";
}

.icon-border-vertical:before {
  content: "\e6b0";
}

.icon-bowling:before {
  content: "\e6b1";
}

.icon-box-download:before {
  content: "\e6b2";
}

.icon-box-upload:before {
  content: "\e6b3";
}

.icon-box:before {
  content: "\e6b4";
}

.icon-briefcase-checked:before {
  content: "\e6b5";
}

.icon-briefcase-download:before {
  content: "\e6b6";
}

.icon-briefcase-upload:before {
  content: "\e6b7";
}

.icon-briefcase:before {
  content: "\e6b8";
}

.icon-brightness-1:before {
  content: "\e6b9";
}

.icon-brightness-2:before {
  content: "\e6ba";
}

.icon-brightness-3:before {
  content: "\e6bb";
}

.icon-brightness-4:before {
  content: "\e6bc";
}

.icon-brightness-5:before {
  content: "\e6bd";
}

.icon-brightness-6:before {
  content: "\e6be";
}

.icon-brightness-7:before {
  content: "\e6bf";
}

.icon-brightness-auto:before {
  content: "\e6c0";
}

.icon-brightness:before {
  content: "\e6c1";
}

.icon-broom:before {
  content: "\e6c2";
}

.icon-brush:before {
  content: "\e6c3";
}

.icon-bug:before {
  content: "\e6c4";
}

.icon-bulletin-board:before {
  content: "\e6c5";
}

.icon-bullhorn:before {
  content: "\e6c6";
}

.icon-bus:before {
  content: "\e6c7";
}

.icon-cake-variant:before {
  content: "\e6c8";
}

.icon-cake:before {
  content: "\e6c9";
}

.icon-calculator:before {
  content: "\e6ca";
}

.icon-calendar-blank:before {
  content: "\e6cb";
}

.icon-calendar-check-multiple:before {
  content: "\e6cc";
}

.icon-calendar-check:before {
  content: "\e6cd";
}

.icon-calendar-clock:before {
  content: "\e6ce";
}

.icon-calendar-multiple:before {
  content: "\e6cf";
}

.icon-calendar-plus:before {
  content: "\e6d0";
}

.icon-calendar-remove:before {
  content: "\e6d1";
}

.icon-calendar-select:before {
  content: "\e6d2";
}

.icon-calendar-text:before {
  content: "\e6d3";
}

.icon-calendar-today:before {
  content: "\e6d4";
}

.icon-calendar:before {
  content: "\e6d5";
}

.icon-camcorder-box-off:before {
  content: "\e6d6";
}

.icon-camcorder-box:before {
  content: "\e6d7";
}

.icon-camcorder-off:before {
  content: "\e6d8";
}

.icon-camcorder:before {
  content: "\e6d9";
}

.icon-camera-front-variant:before {
  content: "\e6da";
}

.icon-camera-front:before {
  content: "\e6db";
}

.icon-camera-iris:before {
  content: "\e6dc";
}

.icon-camera-party-mode:before {
  content: "\e6dd";
}

.icon-camera-rear-variant:before {
  content: "\e6de";
}

.icon-camera-rear:before {
  content: "\e6df";
}

.icon-camera-switch:before {
  content: "\e6e0";
}

.icon-camera-timer:before {
  content: "\e6e1";
}

.icon-camera:before {
  content: "\e6e2";
}

.icon-cancel:before {
  content: "\e6e3";
}

.icon-candycane:before {
  content: "\e6e4";
}

.icon-car-wash:before {
  content: "\e6e5";
}

.icon-car:before {
  content: "\e6e6";
}

.icon-carrot:before {
  content: "\e6e7";
}

.icon-cart-outline:before {
  content: "\e6e8";
}

.icon-cart:before {
  content: "\e6e9";
}

.icon-cash-100:before {
  content: "\e6ea";
}

.icon-cash-multiple:before {
  content: "\e6eb";
}

.icon-cash-usd:before {
  content: "\e6ec";
}

.icon-cash:before {
  content: "\e6ed";
}

.icon-cast-connected:before {
  content: "\e6ee";
}

.icon-cast:before {
  content: "\e6ef";
}

.icon-castle:before {
  content: "\e6f0";
}

.icon-cat:before {
  content: "\e6f1";
}

.icon-cellphone-android:before {
  content: "\e6f2";
}

.icon-cellphone-dock:before {
  content: "\e6f3";
}

.icon-cellphone-iphone:before {
  content: "\e6f4";
}

.icon-cellphone-link-off:before {
  content: "\e6f5";
}

.icon-cellphone-link:before {
  content: "\e6f6";
}

.icon-cellphone-settings:before {
  content: "\e6f7";
}

.icon-cellphone:before {
  content: "\e6f8";
}

.icon-chair-school:before {
  content: "\e6f9";
}

.icon-chart-arc:before {
  content: "\e6fa";
}

.icon-chart-areaspline:before {
  content: "\e6fb";
}

.icon-chart-bar:before {
  content: "\e6fc";
}

.icon-chart-histogram:before {
  content: "\e6fd";
}

.icon-chart-line:before {
  content: "\e6fe";
}

.icon-chart-pie:before {
  content: "\e6ff";
}

.icon-check-all:before {
  content: "\e700";
}

.icon-check-bookmark:before {
  content: "\e701";
}

.icon-check-circle:before {
  content: "\e702";
}

.icon-check:before {
  content: "\e703";
}

.icon-checkbox-blank-circle-outline:before {
  content: "\e704";
}

.icon-checkbox-blank-circle:before {
  content: "\e705";
}

.icon-checkbox-blank-outline:before {
  content: "\e706";
}

.icon-checkbox-blank:before {
  content: "\e707";
}

.icon-checkbox-marked-circle-outline:before {
  content: "\e708";
}

.icon-checkbox-marked-circle:before {
  content: "\e709";
}

.icon-checkbox-marked-outline:before {
  content: "\e70a";
}

.icon-checkbox-marked:before {
  content: "\e70b";
}

.icon-checkbox-multiple-blank-outline:before {
  content: "\e70c";
}

.icon-checkbox-multiple-blank:before {
  content: "\e70d";
}

.icon-checkbox-multiple-marked-outline:before {
  content: "\e70e";
}

.icon-checkbox-multiple-marked:before {
  content: "\e70f";
}

.icon-checkerboard:before {
  content: "\e710";
}

.icon-chevron-double-down:before {
  content: "\e711";
}

.icon-chevron-double-left:before {
  content: "\e712";
}

.icon-chevron-double-right:before {
  content: "\e713";
}

.icon-chevron-double-up:before {
  content: "\e714";
}

.icon-chevron-down:before {
  content: "\e715";
}

.icon-chevron-left:before {
  content: "\e716";
}

.icon-chevron-right:before {
  content: "\e717";
}

.icon-chevron-up:before {
  content: "\e718";
}

.icon-church:before {
  content: "\e719";
}

.icon-cisco-webex:before {
  content: "\e71a";
}

.icon-city:before {
  content: "\e71b";
}

.icon-clapperboard:before {
  content: "\e71c";
}

.icon-clipboard-account:before {
  content: "\e71d";
}

.icon-clipboard-alert:before {
  content: "\e71e";
}

.icon-clipboard-arrow-down:before {
  content: "\e71f";
}

.icon-clipboard-arrow-left:before {
  content: "\e720";
}

.icon-clipboard-check:before {
  content: "\e721";
}

.icon-clipboard-outline:before {
  content: "\e722";
}

.icon-clipboard-text:before {
  content: "\e723";
}

.icon-clipboard:before {
  content: "\e724";
}

.icon-clippy:before {
  content: "\e725";
}

.icon-clock-fast:before {
  content: "\e726";
}

.icon-clock:before {
  content: "\e727";
}

.icon-close-circle-outline:before {
  content: "\e728";
}

.icon-close-circle:before {
  content: "\e729";
}

.icon-close-network:before {
  content: "\e72a";
}

.icon-close:before {
  content: "\e72b";
}

.icon-closed-caption:before {
  content: "\e72c";
}

.icon-cloud-check:before {
  content: "\e72d";
}

.icon-cloud-circle:before {
  content: "\e72e";
}

.icon-cloud-download:before {
  content: "\e72f";
}

.icon-cloud-outline-off:before {
  content: "\e730";
}

.icon-cloud-outline:before {
  content: "\e731";
}

.icon-cloud-upload:before {
  content: "\e732";
}

.icon-cloud:before {
  content: "\e733";
}

.icon-code-array:before {
  content: "\e734";
}

.icon-code-braces:before {
  content: "\e735";
}

.icon-code-equal:before {
  content: "\e736";
}

.icon-code-greater-than-or-equal:before {
  content: "\e737";
}

.icon-code-greater-than:before {
  content: "\e738";
}

.icon-code-less-than-or-equal:before {
  content: "\e739";
}

.icon-code-less-than:before {
  content: "\e73a";
}

.icon-code-not-equal-variant:before {
  content: "\e73b";
}

.icon-code-not-equal:before {
  content: "\e73c";
}

.icon-code-string:before {
  content: "\e73d";
}

.icon-code-tags:before {
  content: "\e73e";
}

.icon-codepen:before {
  content: "\e73f";
}

.icon-coffee-to-go:before {
  content: "\e740";
}

.icon-coffee:before {
  content: "\e741";
}

.icon-cog-box:before {
  content: "\e742";
}

.icon-cog:before {
  content: "\e743";
}

.icon-coin:before {
  content: "\e744";
}

.icon-color-helper:before {
  content: "\e745";
}

.icon-comment-account-outline:before {
  content: "\e746";
}

.icon-comment-account:before {
  content: "\e747";
}

.icon-comment-alert-outline:before {
  content: "\e748";
}

.icon-comment-alert:before {
  content: "\e749";
}

.icon-comment-check-outline:before {
  content: "\e74a";
}

.icon-comment-check:before {
  content: "\e74b";
}

.icon-comment-multipe-outline:before {
  content: "\e74c";
}

.icon-comment-outline:before {
  content: "\e74d";
}

.icon-comment-plus-outline:before {
  content: "\e74e";
}

.icon-comment-processing-outline:before {
  content: "\e74f";
}

.icon-comment-processing:before {
  content: "\e750";
}

.icon-comment-remove-outline:before {
  content: "\e751";
}

.icon-comment-text-outline:before {
  content: "\e752";
}

.icon-comment-text:before {
  content: "\e753";
}

.icon-comment:before {
  content: "\e754";
}

.icon-compare:before {
  content: "\e755";
}

.icon-compass-outline:before {
  content: "\e756";
}

.icon-compass:before {
  content: "\e757";
}

.icon-console:before {
  content: "\e758";
}

.icon-content-copy:before {
  content: "\e759";
}

.icon-content-cut:before {
  content: "\e75a";
}

.icon-content-duplicate:before {
  content: "\e75b";
}

.icon-content-paste:before {
  content: "\e75c";
}

.icon-content-save-all:before {
  content: "\e75d";
}

.icon-content-save:before {
  content: "\e75e";
}

.icon-contrast-box:before {
  content: "\e75f";
}

.icon-contrast-circle:before {
  content: "\e760";
}

.icon-contrast:before {
  content: "\e761";
}

.icon-controller-xbox:before {
  content: "\e762";
}

.icon-cow:before {
  content: "\e763";
}

.icon-credit-card-multiple:before {
  content: "\e764";
}

.icon-credit-card:before {
  content: "\e765";
}

.icon-crop-free:before {
  content: "\e766";
}

.icon-crop-landscape:before {
  content: "\e767";
}

.icon-crop-portrait:before {
  content: "\e768";
}

.icon-crop-square:before {
  content: "\e769";
}

.icon-crop:before {
  content: "\e76a";
}

.icon-crosshairs-gps:before {
  content: "\e76b";
}

.icon-crosshairs:before {
  content: "\e76c";
}

.icon-crown:before {
  content: "\e76d";
}

.icon-cube-outline:before {
  content: "\e76e";
}

.icon-cube-unfolded:before {
  content: "\e76f";
}

.icon-cube:before {
  content: "\e770";
}

.icon-cup-water:before {
  content: "\e771";
}

.icon-cup:before {
  content: "\e772";
}

.icon-currency-btc:before {
  content: "\e773";
}

.icon-currency-eur:before {
  content: "\e774";
}

.icon-currency-gbp:before {
  content: "\e775";
}

.icon-currency-inr:before {
  content: "\e776";
}

.icon-currency-rub:before {
  content: "\e777";
}

.icon-currency-try:before {
  content: "\e778";
}

.icon-currency-usd:before {
  content: "\e779";
}

.icon-cursor-default-outline:before {
  content: "\e77a";
}

.icon-cursor-default:before {
  content: "\e77b";
}

.icon-cursor-move:before {
  content: "\e77c";
}

.icon-cursor-pointer:before {
  content: "\e77d";
}

.icon-data:before {
  content: "\e77e";
}

.icon-debug-step-into:before {
  content: "\e77f";
}

.icon-debug-step-out:before {
  content: "\e780";
}

.icon-debug-step-over:before {
  content: "\e781";
}

.icon-decimal-decrease:before {
  content: "\e782";
}

.icon-decimal-increase:before {
  content: "\e783";
}

.icon-delete-variant:before {
  content: "\e784";
}

.icon-delete:before {
  content: "\e785";
}

.icon-deskphone:before {
  content: "\e786";
}

.icon-desktop-mac:before {
  content: "\e787";
}

.icon-desktop-tower:before {
  content: "\e788";
}

.icon-details:before {
  content: "\e789";
}

.icon-deviantart:before {
  content: "\e78a";
}

.icon-diamond:before {
  content: "\e78b";
}

.icon-dice-1:before {
  content: "\e78c";
}

.icon-dice-2:before {
  content: "\e78d";
}

.icon-dice-3:before {
  content: "\e78e";
}

.icon-dice-4:before {
  content: "\e78f";
}

.icon-dice-5:before {
  content: "\e790";
}

.icon-dice-6:before {
  content: "\e791";
}

.icon-dice:before {
  content: "\e792";
}

.icon-directions:before {
  content: "\e793";
}

.icon-disk-alert:before {
  content: "\e794";
}

.icon-disqus-outline:before {
  content: "\e795";
}

.icon-disqus:before {
  content: "\e796";
}

.icon-division-box:before {
  content: "\e797";
}

.icon-division:before {
  content: "\e798";
}

.icon-dns:before {
  content: "\e799";
}

.icon-document:before {
  content: "\e79a";
}

.icon-domain:before {
  content: "\e79b";
}

.icon-dots-horizontal:before {
  content: "\e79c";
}

.icon-dots-vertical:before {
  content: "\e79d";
}

.icon-download:before {
  content: "\e79e";
}

.icon-drag-horizontal:before {
  content: "\e79f";
}

.icon-drag-vertical:before {
  content: "\e7a0";
}

.icon-drag:before {
  content: "\e7a1";
}

.icon-drawing-box:before {
  content: "\e7a2";
}

.icon-drawing:before {
  content: "\e7a3";
}

.icon-dribbble-box:before {
  content: "\e7a4";
}

.icon-dribbble:before {
  content: "\e7a5";
}

.icon-drone:before {
  content: "\e7a6";
}

.icon-dropbox:before {
  content: "\e7a7";
}

.icon-drupal:before {
  content: "\e7a8";
}

.icon-duck:before {
  content: "\e7a9";
}

.icon-dumbbell:before {
  content: "\e7aa";
}

.icon-earth-off:before {
  content: "\e7ab";
}

.icon-earth:before {
  content: "\e7ac";
}

.icon-ebay:before {
  content: "\e7ad";
}

.icon-edge:before {
  content: "\e7ae";
}

.icon-eject:before {
  content: "\e7af";
}

.icon-elevation-decline:before {
  content: "\e7b0";
}

.icon-elevation-rise:before {
  content: "\e7b1";
}

.icon-elevator:before {
  content: "\e7b2";
}

.icon-email-open:before {
  content: "\e7b3";
}

.icon-email-secure:before {
  content: "\e7b4";
}

.icon-email:before {
  content: "\e7b5";
}

.icon-emoticon-cool:before {
  content: "\e7b6";
}

.icon-emoticon-devil:before {
  content: "\e7b7";
}

.icon-emoticon-happy:before {
  content: "\e7b8";
}

.icon-emoticon-neutral:before {
  content: "\e7b9";
}

.icon-emoticon-poop:before {
  content: "\e7ba";
}

.icon-emoticon-sad:before {
  content: "\e7bb";
}

.icon-emoticon-tongue:before {
  content: "\e7bc";
}

.icon-emoticon:before {
  content: "\e7bd";
}

.icon-engine-outline:before {
  content: "\e7be";
}

.icon-engine:before {
  content: "\e7bf";
}

.icon-equal-box:before {
  content: "\e7c0";
}

.icon-equal:before {
  content: "\e7c1";
}

.icon-eraser:before {
  content: "\e7c2";
}

.icon-escalator:before {
  content: "\e7c3";
}

.icon-etsy:before {
  content: "\e7c4";
}

.icon-evernote:before {
  content: "\e7c5";
}

.icon-exclamation:before {
  content: "\e7c6";
}

.icon-exit-to-app:before {
  content: "\e7c7";
}

.icon-export:before {
  content: "\e7c8";
}

.icon-eye-off:before {
  content: "\e7c9";
}

.icon-eye:before {
  content: "\e7ca";
}

.icon-eyedropper-variant:before {
  content: "\e7cb";
}

.icon-eyedropper:before {
  content: "\e7cc";
}

.icon-facebook-box:before {
  content: "\e7cd";
}

.icon-facebook:before {
  content: "\e7ce";
}

.icon-factory:before {
  content: "\e7cf";
}

.icon-fan:before {
  content: "\e7d0";
}

.icon-fast-forward:before {
  content: "\e7d1";
}

.icon-ferry:before {
  content: "\e7d2";
}

.icon-file-cloud:before {
  content: "\e7d3";
}

.icon-file-delimited:before {
  content: "\e7d4";
}

.icon-file-document-box:before {
  content: "\e7d5";
}

.icon-file-document:before {
  content: "\e7d6";
}

.icon-file-excel-box:before {
  content: "\e7d7";
}

.icon-file-excel:before {
  content: "\e7d8";
}

.icon-file-find:before {
  content: "\e7d9";
}

.icon-file-hidden:before {
  content: "\e7da";
}

.icon-file-image-box:before {
  content: "\e7db";
}

.icon-file-image:before {
  content: "\e7dc";
}

.icon-file-multiple:before {
  content: "\e7dd";
}

.icon-file-music:before {
  content: "\e7de";
}

.icon-file-outline:before {
  content: "\e7df";
}

.icon-file-pdf-box:before {
  content: "\e7e0";
}

.icon-file-pdf:before {
  content: "\e7e1";
}

.icon-file-powerpoint-box:before {
  content: "\e7e2";
}

.icon-file-powerpoint:before {
  content: "\e7e3";
}

.icon-file-presentation-box:before {
  content: "\e7e4";
}

.icon-file-video:before {
  content: "\e7e5";
}

.icon-file-word-box:before {
  content: "\e7e6";
}

.icon-file-word:before {
  content: "\e7e7";
}

.icon-file-xml:before {
  content: "\e7e8";
}

.icon-file:before {
  content: "\e7e9";
}

.icon-fill:before {
  content: "\e7ea";
}

.icon-film:before {
  content: "\e7eb";
}

.icon-filmstrip-off:before {
  content: "\e7ec";
}

.icon-filmstrip:before {
  content: "\e7ed";
}

.icon-filter-outline:before {
  content: "\e7ee";
}

.icon-filter-remove-outline:before {
  content: "\e7ef";
}

.icon-filter-remove:before {
  content: "\e7f0";
}

.icon-filter-variant:before {
  content: "\e7f1";
}

.icon-filter:before {
  content: "\e7f2";
}

.icon-fire:before {
  content: "\e7f3";
}

.icon-fish:before {
  content: "\e7f4";
}

.icon-flag-checkered-variant:before {
  content: "\e7f5";
}

.icon-flag-checkered:before {
  content: "\e7f6";
}

.icon-flag-outline-variant:before {
  content: "\e7f7";
}

.icon-flag-outline:before {
  content: "\e7f8";
}

.icon-flag-triangle:before {
  content: "\e7f9";
}

.icon-flag-variant:before {
  content: "\e7fa";
}

.icon-flag:before {
  content: "\e7fb";
}

.icon-flash-auto:before {
  content: "\e7fc";
}

.icon-flash-off:before {
  content: "\e7fd";
}

.icon-flash:before {
  content: "\e7fe";
}

.icon-flashlight-off:before {
  content: "\e7ff";
}

.icon-flashlight:before {
  content: "\e800";
}

.icon-flattr:before {
  content: "\e801";
}

.icon-flickr-after:before {
  content: "\e802";
}

.icon-flickr-before:before {
  content: "\e803";
}

.icon-flip-to-back:before {
  content: "\e804";
}

.icon-flip-to-front:before {
  content: "\e805";
}

.icon-floppy:before {
  content: "\e806";
}

.icon-flower:before {
  content: "\e807";
}

.icon-folder-account:before {
  content: "\e808";
}

.icon-folder-download:before {
  content: "\e809";
}

.icon-folder-google-drive:before {
  content: "\e80a";
}

.icon-folder-image:before {
  content: "\e80b";
}

.icon-folder-lock-open:before {
  content: "\e80c";
}

.icon-folder-lock:before {
  content: "\e80d";
}

.icon-folder-move:before {
  content: "\e80e";
}

.icon-folder-multiple-image:before {
  content: "\e80f";
}

.icon-folder-multiple-outline:before {
  content: "\e810";
}

.icon-folder-multiple:before {
  content: "\e811";
}

.icon-folder-outline-lock:before {
  content: "\e812";
}

.icon-folder-outline:before {
  content: "\e813";
}

.icon-folder-plus:before {
  content: "\e814";
}

.icon-folder-remove:before {
  content: "\e815";
}

.icon-folder-upload:before {
  content: "\e816";
}

.icon-folder:before {
  content: "\e817";
}

.icon-food-apple:before {
  content: "\e818";
}

.icon-food-variant:before {
  content: "\e819";
}

.icon-food:before {
  content: "\e81a";
}

.icon-football-helmet:before {
  content: "\e81b";
}

.icon-football:before {
  content: "\e81c";
}

.icon-format-align-center:before {
  content: "\e81d";
}

.icon-format-align-justify:before {
  content: "\e81e";
}

.icon-format-align-left:before {
  content: "\e81f";
}

.icon-format-align-right:before {
  content: "\e820";
}

.icon-format-bold:before {
  content: "\e821";
}

.icon-format-clear:before {
  content: "\e822";
}

.icon-format-color-fill:before {
  content: "\e823";
}

.icon-format-color:before {
  content: "\e824";
}

.icon-format-float-center:before {
  content: "\e825";
}

.icon-format-float-left:before {
  content: "\e826";
}

.icon-format-float-none:before {
  content: "\e827";
}

.icon-format-float-right:before {
  content: "\e828";
}

.icon-format-header-1:before {
  content: "\e829";
}

.icon-format-header-2:before {
  content: "\e82a";
}

.icon-format-header-3:before {
  content: "\e82b";
}

.icon-format-header-4:before {
  content: "\e82c";
}

.icon-format-header-5:before {
  content: "\e82d";
}

.icon-format-header-6:before {
  content: "\e82e";
}

.icon-format-header-decrease:before {
  content: "\e82f";
}

.icon-format-header-down:before {
  content: "\e830";
}

.icon-format-header-equal:before {
  content: "\e831";
}

.icon-format-header-increase:before {
  content: "\e832";
}

.icon-format-header-pound:before {
  content: "\e833";
}

.icon-format-header-up:before {
  content: "\e834";
}

.icon-format-indent-decrease:before {
  content: "\e835";
}

.icon-format-indent-increase:before {
  content: "\e836";
}

.icon-format-italic:before {
  content: "\e837";
}

.icon-format-line-spacing:before {
  content: "\e838";
}

.icon-format-list-bulleted:before {
  content: "\e839";
}

.icon-format-list-numbers:before {
  content: "\e83a";
}

.icon-format-object-inline:before {
  content: "\e83b";
}

.icon-format-object-square:before {
  content: "\e83c";
}

.icon-format-object-tight:before {
  content: "\e83d";
}

.icon-format-object-top-bottom:before {
  content: "\e83e";
}

.icon-format-paint:before {
  content: "\e83f";
}

.icon-format-paragraph:before {
  content: "\e840";
}

.icon-format-quote:before {
  content: "\e841";
}

.icon-format-size:before {
  content: "\e842";
}

.icon-format-strikethrough:before {
  content: "\e843";
}

.icon-format-subscript:before {
  content: "\e844";
}

.icon-format-superscript:before {
  content: "\e845";
}

.icon-format-text:before {
  content: "\e846";
}

.icon-format-textdirection-l-to-r:before {
  content: "\e847";
}

.icon-format-textdirection-r-to-l:before {
  content: "\e848";
}

.icon-format-underline:before {
  content: "\e849";
}

.icon-forum:before {
  content: "\e84a";
}

.icon-forward:before {
  content: "\e84b";
}

.icon-foursquare:before {
  content: "\e84c";
}

.icon-fridge:before {
  content: "\e84d";
}

.icon-fullscreen-exit:before {
  content: "\e84e";
}

.icon-fullscreen:before {
  content: "\e84f";
}

.icon-function:before {
  content: "\e850";
}

.icon-gamepad-variant:before {
  content: "\e851";
}

.icon-gamepad:before {
  content: "\e852";
}

.icon-gas-station:before {
  content: "\e853";
}

.icon-gavel:before {
  content: "\e854";
}

.icon-gender-female:before {
  content: "\e855";
}

.icon-gender-male-female:before {
  content: "\e856";
}

.icon-gender-male:before {
  content: "\e857";
}

.icon-gender-transgender:before {
  content: "\e858";
}

.icon-gift:before {
  content: "\e859";
}

.icon-git:before {
  content: "\e85a";
}

.icon-github-box:before {
  content: "\e85b";
}

.icon-github:before {
  content: "\e85c";
}

.icon-glass-flute:before {
  content: "\e85d";
}

.icon-glass-mug:before {
  content: "\e85e";
}

.icon-glass-stange:before {
  content: "\e85f";
}

.icon-glass-tulip:before {
  content: "\e860";
}

.icon-glasses:before {
  content: "\e861";
}

.icon-gmail:before {
  content: "\e862";
}

.icon-google-chrome:before {
  content: "\e863";
}

.icon-google-circles-communities:before {
  content: "\e864";
}

.icon-google-circles-extended:before {
  content: "\e865";
}

.icon-google-circles-invite:before {
  content: "\e866";
}

.icon-google-circles:before {
  content: "\e867";
}

.icon-google-controller-off:before {
  content: "\e868";
}

.icon-google-controller:before {
  content: "\e869";
}

.icon-google-drive:before {
  content: "\e86a";
}

.icon-google-earth:before {
  content: "\e86b";
}

.icon-google-glass:before {
  content: "\e86c";
}

.icon-google-maps:before {
  content: "\e86d";
}

.icon-google-pages:before {
  content: "\e86e";
}

.icon-google-play:before {
  content: "\e86f";
}

.icon-google-plus-box:before {
  content: "\e870";
}

.icon-google-plus:before {
  content: "\e871";
}

.icon-google:before {
  content: "\e872";
}

.icon-grid-off:before {
  content: "\e873";
}

.icon-grid:before {
  content: "\e874";
}

.icon-group:before {
  content: "\e875";
}

.icon-hand-pointing-right:before {
  content: "\e876";
}

.icon-hanger:before {
  content: "\e877";
}

.icon-hangouts:before {
  content: "\e878";
}

.icon-harddisk:before {
  content: "\e879";
}

.icon-headphones-box:before {
  content: "\e87a";
}

.icon-headphones-dots:before {
  content: "\e87b";
}

.icon-headphones:before {
  content: "\e87c";
}

.icon-headset-dock:before {
  content: "\e87d";
}

.icon-headset-off:before {
  content: "\e87e";
}

.icon-headset:before {
  content: "\e87f";
}

.icon-heart-box-outline:before {
  content: "\e880";
}

.icon-heart-box:before {
  content: "\e881";
}

.icon-heart-broken:before {
  content: "\e882";
}

.icon-heart-outline:before {
  content: "\e883";
}

.icon-heart:before {
  content: "\e884";
}

.icon-help-circle:before {
  content: "\e885";
}

.icon-help:before {
  content: "\e886";
}

.icon-hexagon-outline:before {
  content: "\e887";
}

.icon-hexagon:before {
  content: "\e888";
}

.icon-history:before {
  content: "\e889";
}

.icon-hololens:before {
  content: "\e88a";
}

.icon-home-modern:before {
  content: "\e88b";
}

.icon-home-variant:before {
  content: "\e88c";
}

.icon-home:before {
  content: "\e88d";
}

.icon-hops:before {
  content: "\e88e";
}

.icon-hospital-building:before {
  content: "\e88f";
}

.icon-hospital-marker:before {
  content: "\e890";
}

.icon-hospital:before {
  content: "\e891";
}

.icon-hotel:before {
  content: "\e892";
}

.icon-houzz-box:before {
  content: "\e893";
}

.icon-human-child:before {
  content: "\e894";
}

.icon-human-male-female:before {
  content: "\e895";
}

.icon-human:before {
  content: "\e896";
}

.icon-image-album:before {
  content: "\e897";
}

.icon-image-area-close:before {
  content: "\e898";
}

.icon-image-area:before {
  content: "\e899";
}

.icon-image-broken:before {
  content: "\e89a";
}

.icon-image-filter-black-white:before {
  content: "\e89b";
}

.icon-image-filter-center-focus:before {
  content: "\e89c";
}

.icon-image-filter-drama:before {
  content: "\e89d";
}

.icon-image-filter-frames:before {
  content: "\e89e";
}

.icon-image-filter-hdr:before {
  content: "\e89f";
}

.icon-image-filter-none:before {
  content: "\e8a0";
}

.icon-image-filter-tilt-shift:before {
  content: "\e8a1";
}

.icon-image-filter-vintage:before {
  content: "\e8a2";
}

.icon-image-filter:before {
  content: "\e8a3";
}

.icon-image:before {
  content: "\e8a4";
}

.icon-import:before {
  content: "\e8a5";
}

.icon-inbox:before {
  content: "\e8a6";
}

.icon-indent:before {
  content: "\e8a7";
}

.icon-information-outline:before {
  content: "\e8a8";
}

.icon-information:before {
  content: "\e8a9";
}

.icon-instagram:before {
  content: "\e8aa";
}

.icon-instapaper:before {
  content: "\e8ab";
}

.icon-internet-explorer:before {
  content: "\e8ac";
}

.icon-invert-colors:before {
  content: "\e8ad";
}

.icon-jira:before {
  content: "\e8ae";
}

.icon-jsfiddle:before {
  content: "\e8af";
}

.icon-keg:before {
  content: "\e8b0";
}

.icon-key-change:before {
  content: "\e8b1";
}

.icon-key-minus:before {
  content: "\e8b2";
}

.icon-key-plus:before {
  content: "\e8b3";
}

.icon-key-remove:before {
  content: "\e8b4";
}

.icon-key-variant:before {
  content: "\e8b5";
}

.icon-key:before {
  content: "\e8b6";
}

.icon-keyboard-backspace:before {
  content: "\e8b7";
}

.icon-keyboard-caps:before {
  content: "\e8b8";
}

.icon-keyboard-close:before {
  content: "\e8b9";
}

.icon-keyboard-off:before {
  content: "\e8ba";
}

.icon-keyboard-return:before {
  content: "\e8bb";
}

.icon-keyboard-tab:before {
  content: "\e8bc";
}

.icon-keyboard-variant:before {
  content: "\e8bd";
}

.icon-keyboard:before {
  content: "\e8be";
}

.icon-label-outline:before {
  content: "\e8bf";
}

.icon-label:before {
  content: "\e8c0";
}

.icon-language-csharp:before {
  content: "\e8c1";
}

.icon-language-css3:before {
  content: "\e8c2";
}

.icon-language-html5:before {
  content: "\e8c3";
}

.icon-language-javascript:before {
  content: "\e8c4";
}

.icon-language-python-text:before {
  content: "\e8c5";
}

.icon-language-python:before {
  content: "\e8c6";
}

.icon-laptop-chromebook:before {
  content: "\e8c7";
}

.icon-laptop-mac:before {
  content: "\e8c8";
}

.icon-laptop-windows:before {
  content: "\e8c9";
}

.icon-laptop:before {
  content: "\e8ca";
}

.icon-lastfm:before {
  content: "\e8cb";
}

.icon-launch:before {
  content: "\e8cc";
}

.icon-layers-off:before {
  content: "\e8cd";
}

.icon-layers:before {
  content: "\e8ce";
}

.icon-leaf:before {
  content: "\e8cf";
}

.icon-library-books:before {
  content: "\e8d0";
}

.icon-library-music:before {
  content: "\e8d1";
}

.icon-library-plus:before {
  content: "\e8d2";
}

.icon-library:before {
  content: "\e8d3";
}

.icon-lightbulb-outline:before {
  content: "\e8d4";
}

.icon-lightbulb:before {
  content: "\e8d5";
}

.icon-link-off:before {
  content: "\e8d6";
}

.icon-link-variant-off:before {
  content: "\e8d7";
}

.icon-link-variant:before {
  content: "\e8d8";
}

.icon-link:before {
  content: "\e8d9";
}

.icon-linkedin-box:before {
  content: "\e8da";
}

.icon-linkedin:before {
  content: "\e8db";
}

.icon-linode:before {
  content: "\e8dc";
}

.icon-linux:before {
  content: "\e8dd";
}

.icon-lock-outline:before {
  content: "\e8de";
}

.icon-lock-unlocked-outline:before {
  content: "\e8df";
}

.icon-lock-unlocked:before {
  content: "\e8e0";
}

.icon-lock:before {
  content: "\e8e1";
}

.icon-login:before {
  content: "\e8e2";
}

.icon-logout:before {
  content: "\e8e3";
}

.icon-looks:before {
  content: "\e8e4";
}

.icon-loupe:before {
  content: "\e8e5";
}

.icon-lumx:before {
  content: "\e8e6";
}

.icon-magnet-on:before {
  content: "\e8e7";
}

.icon-magnet:before {
  content: "\e8e8";
}

.icon-magnify-minus:before {
  content: "\e8e9";
}

.icon-magnify-plus:before {
  content: "\e8ea";
}

.icon-magnify:before {
  content: "\e8eb";
}

.icon-mail-ru:before {
  content: "\e8ec";
}

.icon-map-marker-circle:before {
  content: "\e8ed";
}

.icon-map-marker-multiple:before {
  content: "\e8ee";
}

.icon-map-marker-off:before {
  content: "\e8ef";
}

.icon-map-marker-radius:before {
  content: "\e8f0";
}

.icon-map-marker:before {
  content: "\e8f1";
}

.icon-map:before {
  content: "\e8f2";
}

.icon-margin:before {
  content: "\e8f3";
}

.icon-markdown:before {
  content: "\e8f4";
}

.icon-marker-check:before {
  content: "\e8f5";
}

.icon-martini:before {
  content: "\e8f6";
}

.icon-material-ui:before {
  content: "\e8f7";
}

.icon-maxcdn:before {
  content: "\e8f8";
}

.icon-medium:before {
  content: "\e8f9";
}

.icon-memory:before {
  content: "\e8fa";
}

.icon-menu-down:before {
  content: "\e8fb";
}

.icon-menu-left:before {
  content: "\e8fc";
}

.icon-menu-right:before {
  content: "\e8fd";
}

.icon-menu-up:before {
  content: "\e8fe";
}

.icon-menu:before {
  content: "\e8ff";
}

.icon-message-alert:before {
  content: "\e900";
}

.icon-message-draw:before {
  content: "\e901";
}

.icon-message-image:before {
  content: "\e902";
}

.icon-message-processing:before {
  content: "\e903";
}

.icon-message-reply:before {
  content: "\e904";
}

.icon-message-text-outline:before {
  content: "\e905";
}

.icon-message-text:before {
  content: "\e906";
}

.icon-message-video:before {
  content: "\e907";
}

.icon-message:before {
  content: "\e908";
}

.icon-microphone-dots:before {
  content: "\e909";
}

.icon-microphone-off:before {
  content: "\e90a";
}

.icon-microphone-outline:before {
  content: "\e90b";
}

.icon-microphone-settings:before {
  content: "\e90c";
}

.icon-microphone-variant-off:before {
  content: "\e90d";
}

.icon-microphone-variant:before {
  content: "\e90e";
}

.icon-microphone:before {
  content: "\e90f";
}

.icon-minus-box:before {
  content: "\e910";
}

.icon-minus-circle-outline:before {
  content: "\e911";
}

.icon-minus-circle:before {
  content: "\e912";
}

.icon-minus-network:before {
  content: "\e913";
}

.icon-minus:before {
  content: "\e914";
}

.icon-monitor-multiple:before {
  content: "\e915";
}

.icon-monitor:before {
  content: "\e916";
}

.icon-more:before {
  content: "\e917";
}

.icon-mouse-off:before {
  content: "\e918";
}

.icon-mouse-variant-off:before {
  content: "\e919";
}

.icon-mouse-variant:before {
  content: "\e91a";
}

.icon-mouse:before {
  content: "\e91b";
}

.icon-movie:before {
  content: "\e91c";
}

.icon-multiplication-box:before {
  content: "\e91d";
}

.icon-multiplication:before {
  content: "\e91e";
}

.icon-music-box-outline:before {
  content: "\e91f";
}

.icon-music-box:before {
  content: "\e920";
}

.icon-music-circle:before {
  content: "\e921";
}

.icon-music-note-eighth:before {
  content: "\e922";
}

.icon-music-note-half:before {
  content: "\e923";
}

.icon-music-note-off:before {
  content: "\e924";
}

.icon-music-note-quarter:before {
  content: "\e925";
}

.icon-music-note-sixteenth:before {
  content: "\e926";
}

.icon-music-note-whole:before {
  content: "\e927";
}

.icon-music-note:before {
  content: "\e928";
}

.icon-nature-people:before {
  content: "\e929";
}

.icon-nature:before {
  content: "\e92a";
}

.icon-navigation:before {
  content: "\e92b";
}

.icon-needle:before {
  content: "\e92c";
}

.icon-nest-protect:before {
  content: "\e92d";
}

.icon-nest-thermostat:before {
  content: "\e92e";
}

.icon-newspaper:before {
  content: "\e92f";
}

.icon-nfc-tap:before {
  content: "\e930";
}

.icon-nfc-variant:before {
  content: "\e931";
}

.icon-nfc:before {
  content: "\e932";
}

.icon-no:before {
  content: "\e933";
}

.icon-not-equal:before {
  content: "\e934";
}

.icon-note-outline:before {
  content: "\e935";
}

.icon-note-text:before {
  content: "\e936";
}

.icon-note:before {
  content: "\e937";
}

.icon-numeric-0-box-multiple-outline:before {
  content: "\e938";
}

.icon-numeric-0-box-outline:before {
  content: "\e939";
}

.icon-numeric-0-box:before {
  content: "\e93a";
}

.icon-numeric-1-box-multiple-outline:before {
  content: "\e93b";
}

.icon-numeric-1-box-outline:before {
  content: "\e93c";
}

.icon-numeric-1-box:before {
  content: "\e93d";
}

.icon-numeric-2-box-multiple-outline:before {
  content: "\e93e";
}

.icon-numeric-2-box-outline:before {
  content: "\e93f";
}

.icon-numeric-2-box:before {
  content: "\e940";
}

.icon-numeric-3-box-multiple-outline:before {
  content: "\e941";
}

.icon-numeric-3-box-outline:before {
  content: "\e942";
}

.icon-numeric-3-box:before {
  content: "\e943";
}

.icon-numeric-4-box-multiple-outline:before {
  content: "\e944";
}

.icon-numeric-4-box-outline:before {
  content: "\e945";
}

.icon-numeric-4-box:before {
  content: "\e946";
}

.icon-numeric-5-box-multiple-outline:before {
  content: "\e947";
}

.icon-numeric-5-box-outline:before {
  content: "\e948";
}

.icon-numeric-5-box:before {
  content: "\e949";
}

.icon-numeric-6-box-multiple-outline:before {
  content: "\e94a";
}

.icon-numeric-6-box-outline:before {
  content: "\e94b";
}

.icon-numeric-6-box:before {
  content: "\e94c";
}

.icon-numeric-7-box-multiple-outline:before {
  content: "\e94d";
}

.icon-numeric-7-box-outline:before {
  content: "\e94e";
}

.icon-numeric-7-box:before {
  content: "\e94f";
}

.icon-numeric-8-box-multiple-outline:before {
  content: "\e950";
}

.icon-numeric-8-box-outline:before {
  content: "\e951";
}

.icon-numeric-8-box:before {
  content: "\e952";
}

.icon-numeric-9-box-multiple-outline:before {
  content: "\e953";
}

.icon-numeric-9-box-outline:before {
  content: "\e954";
}

.icon-numeric-9-box:before {
  content: "\e955";
}

.icon-numeric-9-plus-box-multiple-outline:before {
  content: "\e956";
}

.icon-numeric-9-plus-box-outline:before {
  content: "\e957";
}

.icon-numeric-9-plus-box:before {
  content: "\e958";
}

.icon-numeric:before {
  content: "\e959";
}

.icon-nutriton:before {
  content: "\e95a";
}

.icon-odnoklassniki:before {
  content: "\e95b";
}

.icon-office:before {
  content: "\e95c";
}

.icon-oil:before {
  content: "\e95d";
}

.icon-omega:before {
  content: "\e95e";
}

.icon-onedrive:before {
  content: "\e95f";
}

.icon-open-in-app:before {
  content: "\e960";
}

.icon-open-in-new:before {
  content: "\e961";
}

.icon-ornament-variant:before {
  content: "\e962";
}

.icon-ornament:before {
  content: "\e963";
}

.icon-outbox:before {
  content: "\e964";
}

.icon-outdent:before {
  content: "\e965";
}

.icon-owl:before {
  content: "\e966";
}

.icon-package-down:before {
  content: "\e967";
}

.icon-package-up:before {
  content: "\e968";
}

.icon-package-variant-closed:before {
  content: "\e969";
}

.icon-package-variant:before {
  content: "\e96a";
}

.icon-package:before {
  content: "\e96b";
}

.icon-palette-advanced:before {
  content: "\e96c";
}

.icon-palette:before {
  content: "\e96d";
}

.icon-panda:before {
  content: "\e96e";
}

.icon-pandora:before {
  content: "\e96f";
}

.icon-panorama-fisheye:before {
  content: "\e970";
}

.icon-panorama-horizontal:before {
  content: "\e971";
}

.icon-panorama-vertical:before {
  content: "\e972";
}

.icon-panorama-wide-angle:before {
  content: "\e973";
}

.icon-panorama:before {
  content: "\e974";
}

.icon-paper-cut-vertical:before {
  content: "\e975";
}

.icon-paperclip:before {
  content: "\e976";
}

.icon-parking:before {
  content: "\e977";
}

.icon-pause-circle-outline:before {
  content: "\e978";
}

.icon-pause-circle:before {
  content: "\e979";
}

.icon-pause-octagon-outline:before {
  content: "\e97a";
}

.icon-pause-octagon:before {
  content: "\e97b";
}

.icon-pause:before {
  content: "\e97c";
}

.icon-paw:before {
  content: "\e97d";
}

.icon-pen:before {
  content: "\e97e";
}

.icon-pencil-box-outline:before {
  content: "\e97f";
}

.icon-pencil-box:before {
  content: "\e980";
}

.icon-pencil:before {
  content: "\e981";
}

.icon-people:before {
  content: "\e982";
}

.icon-percent:before {
  content: "\e983";
}

.icon-person-box:before {
  content: "\e984";
}

.icon-person-minus:before {
  content: "\e985";
}

.icon-person-plus:before {
  content: "\e986";
}

.icon-pharmacy:before {
  content: "\e987";
}

.icon-phone-bluetooth:before {
  content: "\e988";
}

.icon-phone-dots:before {
  content: "\e989";
}

.icon-phone-forward:before {
  content: "\e98a";
}

.icon-phone-hangup:before {
  content: "\e98b";
}

.icon-phone-in-talk:before {
  content: "\e98c";
}

.icon-phone-incoming:before {
  content: "\e98d";
}

.icon-phone-locked:before {
  content: "\e98e";
}

.icon-phone-log:before {
  content: "\e98f";
}

.icon-phone-missed:before {
  content: "\e990";
}

.icon-phone-outgoing:before {
  content: "\e991";
}

.icon-phone-paused:before {
  content: "\e992";
}

.icon-phone:before {
  content: "\e993";
}

.icon-picture:before {
  content: "\e994";
}

.icon-pig:before {
  content: "\e995";
}

.icon-pill:before {
  content: "\e996";
}

.icon-pin-off:before {
  content: "\e997";
}

.icon-pin:before {
  content: "\e998";
}

.icon-pine-tree-box:before {
  content: "\e999";
}

.icon-pine-tree:before {
  content: "\e99a";
}

.icon-pinterest-box:before {
  content: "\e99b";
}

.icon-pinterest:before {
  content: "\e99c";
}

.icon-pizza:before {
  content: "\e99d";
}

.icon-plane:before {
  content: "\e99e";
}

.icon-play-box-outline:before {
  content: "\e99f";
}

.icon-play-circle-outline:before {
  content: "\e9a0";
}

.icon-play-circle:before {
  content: "\e9a1";
}

.icon-play:before {
  content: "\e9a2";
}

.icon-playlist-minus:before {
  content: "\e9a3";
}

.icon-playlist-plus:before {
  content: "\e9a4";
}

.icon-playstation:before {
  content: "\e9a5";
}

.icon-plus-box:before {
  content: "\e9a6";
}

.icon-plus-circle-outline:before {
  content: "\e9a7";
}

.icon-plus-circle:before {
  content: "\e9a8";
}

.icon-plus-network:before {
  content: "\e9a9";
}

.icon-plus-one:before {
  content: "\e9aa";
}

.icon-plus:before {
  content: "\e9ab";
}

.icon-pocket:before {
  content: "\e9ac";
}

.icon-poll-box:before {
  content: "\e9ad";
}

.icon-poll:before {
  content: "\e9ae";
}

.icon-polymer:before {
  content: "\e9af";
}

.icon-popcorn:before {
  content: "\e9b0";
}

.icon-pound-box:before {
  content: "\e9b1";
}

.icon-pound:before {
  content: "\e9b2";
}

.icon-power-settings:before {
  content: "\e9b3";
}

.icon-power-socket:before {
  content: "\e9b4";
}

.icon-power:before {
  content: "\e9b5";
}

.icon-presentation-play:before {
  content: "\e9b6";
}

.icon-presentation:before {
  content: "\e9b7";
}

.icon-printer-3d:before {
  content: "\e9b8";
}

.icon-printer:before {
  content: "\e9b9";
}

.icon-pulse:before {
  content: "\e9ba";
}

.icon-puzzle:before {
  content: "\e9bb";
}

.icon-qrcode:before {
  content: "\e9bc";
}

.icon-quadcopter:before {
  content: "\e9bd";
}

.icon-quality-high:before {
  content: "\e9be";
}

.icon-question-mark-circle:before {
  content: "\e9bf";
}

.icon-quick-reply:before {
  content: "\e9c0";
}

.icon-quicktime:before {
  content: "\e9c1";
}

.icon-rabbit:before {
  content: "\e9c2";
}

.icon-radiator:before {
  content: "\e9c3";
}

.icon-radio-tower:before {
  content: "\e9c4";
}

.icon-radio:before {
  content: "\e9c5";
}

.icon-radioactive:before {
  content: "\e9c6";
}

.icon-radiobox-blank:before {
  content: "\e9c7";
}

.icon-radiobox-marked:before {
  content: "\e9c8";
}

.icon-raspberrypi:before {
  content: "\e9c9";
}

.icon-rdio:before {
  content: "\e9ca";
}

.icon-read:before {
  content: "\e9cb";
}

.icon-readability:before {
  content: "\e9cc";
}

.icon-receipt:before {
  content: "\e9cd";
}

.icon-recycle:before {
  content: "\e9ce";
}

.icon-reddit:before {
  content: "\e9cf";
}

.icon-redo-variant:before {
  content: "\e9d0";
}

.icon-redo:before {
  content: "\e9d1";
}

.icon-refresh:before {
  content: "\e9d2";
}

.icon-relative-scale:before {
  content: "\e9d3";
}

.icon-reload:before {
  content: "\e9d4";
}

.icon-remote:before {
  content: "\e9d5";
}

.icon-rename-box:before {
  content: "\e9d6";
}

.icon-repeat-off:before {
  content: "\e9d7";
}

.icon-repeat-once:before {
  content: "\e9d8";
}

.icon-repeat:before {
  content: "\e9d9";
}

.icon-replay:before {
  content: "\e9da";
}

.icon-reply-all:before {
  content: "\e9db";
}

.icon-reply:before {
  content: "\e9dc";
}

.icon-reproduction:before {
  content: "\e9dd";
}

.icon-resize-bottom-right:before {
  content: "\e9de";
}

.icon-responsive:before {
  content: "\e9df";
}

.icon-restore:before {
  content: "\e9e0";
}

.icon-rewind:before {
  content: "\e9e1";
}

.icon-ribbon:before {
  content: "\e9e2";
}

.icon-road:before {
  content: "\e9e3";
}

.icon-rocket:before {
  content: "\e9e4";
}

.icon-rotate-3d:before {
  content: "\e9e5";
}

.icon-rotate-left-variant:before {
  content: "\e9e6";
}

.icon-rotate-left:before {
  content: "\e9e7";
}

.icon-rotate-right-variant:before {
  content: "\e9e8";
}

.icon-rotate-right:before {
  content: "\e9e9";
}

.icon-routes:before {
  content: "\e9ea";
}

.icon-rss-box:before {
  content: "\e9eb";
}

.icon-ruler:before {
  content: "\e9ec";
}

.icon-run:before {
  content: "\e9ed";
}

.icon-sale:before {
  content: "\e9ee";
}

.icon-satellite-variant:before {
  content: "\e9ef";
}

.icon-satellite:before {
  content: "\e9f0";
}

.icon-scale-bathroom:before {
  content: "\e9f1";
}

.icon-scale:before {
  content: "\e9f2";
}

.icon-school:before {
  content: "\e9f3";
}

.icon-screen-rotation-lock:before {
  content: "\e9f4";
}

.icon-screen-rotation:before {
  content: "\e9f5";
}

.icon-script:before {
  content: "\e9f6";
}

.icon-security-network:before {
  content: "\e9f7";
}

.icon-security:before {
  content: "\e9f8";
}

.icon-select-all:before {
  content: "\e9f9";
}

.icon-select-inverse:before {
  content: "\e9fa";
}

.icon-select-off:before {
  content: "\e9fb";
}

.icon-select:before {
  content: "\e9fc";
}

.icon-send:before {
  content: "\e9fd";
}

.icon-server-minus:before {
  content: "\e9fe";
}

.icon-server-network-off:before {
  content: "\e9ff";
}

.icon-server-network:before {
  content: "\ea00";
}

.icon-server-off:before {
  content: "\ea01";
}

.icon-server-plus:before {
  content: "\ea02";
}

.icon-server-remove:before {
  content: "\ea03";
}

.icon-server-security:before {
  content: "\ea04";
}

.icon-server:before {
  content: "\ea05";
}

.icon-shape-plus:before {
  content: "\ea06";
}

.icon-share-variant:before {
  content: "\ea07";
}

.icon-share:before {
  content: "\ea08";
}

.icon-shield-outline:before {
  content: "\ea09";
}

.icon-shield:before {
  content: "\ea0a";
}

.icon-shopping-music:before {
  content: "\ea0b";
}

.icon-shopping:before {
  content: "\ea0c";
}

.icon-shuffle:before {
  content: "\ea0d";
}

.icon-sigma:before {
  content: "\ea0e";
}

.icon-sign-caution:before {
  content: "\ea0f";
}

.icon-signal:before {
  content: "\ea10";
}

.icon-silverware-fork:before {
  content: "\ea11";
}

.icon-silverware-spoon:before {
  content: "\ea12";
}

.icon-silverware-variant:before {
  content: "\ea13";
}

.icon-silverware:before {
  content: "\ea14";
}

.icon-sim-alert:before {
  content: "\ea15";
}

.icon-sim:before {
  content: "\ea16";
}

.icon-sitemap:before {
  content: "\ea17";
}

.icon-skip-next:before {
  content: "\ea18";
}

.icon-skip-previous:before {
  content: "\ea19";
}

.icon-skype-business:before {
  content: "\ea1a";
}

.icon-skype:before {
  content: "\ea1b";
}

.icon-sleep-off:before {
  content: "\ea1c";
}

.icon-sleep:before {
  content: "\ea1d";
}

.icon-smoking-off:before {
  content: "\ea1e";
}

.icon-smoking:before {
  content: "\ea1f";
}

.icon-snapchat:before {
  content: "\ea20";
}

.icon-snowman:before {
  content: "\ea21";
}

.icon-sofa:before {
  content: "\ea22";
}

.icon-sort-alphabetical:before {
  content: "\ea23";
}

.icon-sort-ascending:before {
  content: "\ea24";
}

.icon-sort-descending:before {
  content: "\ea25";
}

.icon-sort-numeric:before {
  content: "\ea26";
}

.icon-sort-variant:before {
  content: "\ea27";
}

.icon-sort:before {
  content: "\ea28";
}

.icon-soundcloud:before {
  content: "\ea29";
}

.icon-source-fork:before {
  content: "\ea2a";
}

.icon-source-pull:before {
  content: "\ea2b";
}

.icon-speaker-off:before {
  content: "\ea2c";
}

.icon-speaker:before {
  content: "\ea2d";
}

.icon-speedometer:before {
  content: "\ea2e";
}

.icon-spellcheck:before {
  content: "\ea2f";
}

.icon-spotify:before {
  content: "\ea30";
}

.icon-spotlight-beam:before {
  content: "\ea31";
}

.icon-spotlight:before {
  content: "\ea32";
}

.icon-spreadsheet:before {
  content: "\ea33";
}

.icon-square-inc-cash:before {
  content: "\ea34";
}

.icon-square-inc:before {
  content: "\ea35";
}

.icon-stackoverflow:before {
  content: "\ea36";
}

.icon-star-circle:before {
  content: "\ea37";
}

.icon-star-half:before {
  content: "\ea38";
}

.icon-star-of-david:before {
  content: "\ea39";
}

.icon-star-outline:before {
  content: "\ea3a";
}

.icon-star:before {
  content: "\ea3b";
}

.icon-steam:before {
  content: "\ea3c";
}

.icon-stethoscope:before {
  content: "\ea3d";
}

.icon-stocking:before {
  content: "\ea3e";
}

.icon-stop:before {
  content: "\ea3f";
}

.icon-store-24-hour:before {
  content: "\ea40";
}

.icon-store:before {
  content: "\ea41";
}

.icon-stove:before {
  content: "\ea42";
}

.icon-subway:before {
  content: "\ea43";
}

.icon-sunglasses:before {
  content: "\ea44";
}

.icon-swap-horizontal:before {
  content: "\ea45";
}

.icon-swap-vertical:before {
  content: "\ea46";
}

.icon-swim:before {
  content: "\ea47";
}

.icon-sword:before {
  content: "\ea48";
}

.icon-sync-alert:before {
  content: "\ea49";
}

.icon-sync-off:before {
  content: "\ea4a";
}

.icon-sync:before {
  content: "\ea4b";
}

.icon-tab-unselected:before {
  content: "\ea4c";
}

.icon-tab:before {
  content: "\ea4d";
}

.icon-table-column-plus-after:before {
  content: "\ea4e";
}

.icon-table-column-plus-before:before {
  content: "\ea4f";
}

.icon-table-column-remove:before {
  content: "\ea50";
}

.icon-table-column-width:before {
  content: "\ea51";
}

.icon-table-edit:before {
  content: "\ea52";
}

.icon-table-large:before {
  content: "\ea53";
}

.icon-table-row-height:before {
  content: "\ea54";
}

.icon-table-row-plus-after:before {
  content: "\ea55";
}

.icon-table-row-plus-before:before {
  content: "\ea56";
}

.icon-table-row-remove:before {
  content: "\ea57";
}

.icon-table:before {
  content: "\ea58";
}

.icon-tablet-android:before {
  content: "\ea59";
}

.icon-tablet-ipad:before {
  content: "\ea5a";
}

.icon-tablet:before {
  content: "\ea5b";
}

.icon-tag-faces:before {
  content: "\ea5c";
}

.icon-tag-multiple:before {
  content: "\ea5d";
}

.icon-tag-outline:before {
  content: "\ea5e";
}

.icon-tag-text-outline:before {
  content: "\ea5f";
}

.icon-tag:before {
  content: "\ea60";
}

.icon-taxi:before {
  content: "\ea61";
}

.icon-teamviewer:before {
  content: "\ea62";
}

.icon-telegram:before {
  content: "\ea63";
}

.icon-television-guide:before {
  content: "\ea64";
}

.icon-television:before {
  content: "\ea65";
}

.icon-temperature-celsius:before {
  content: "\ea66";
}

.icon-temperature-fahrenheit:before {
  content: "\ea67";
}

.icon-temperature-kelvin:before {
  content: "\ea68";
}

.icon-tennis:before {
  content: "\ea69";
}

.icon-tent:before {
  content: "\ea6a";
}

.icon-terrain:before {
  content: "\ea6b";
}

.icon-text-to-speech-off:before {
  content: "\ea6c";
}

.icon-text-to-speech:before {
  content: "\ea6d";
}

.icon-texture:before {
  content: "\ea6e";
}

.icon-theater:before {
  content: "\ea6f";
}

.icon-theme-light-dark:before {
  content: "\ea70";
}

.icon-thermometer-lines:before {
  content: "\ea71";
}

.icon-thermometer:before {
  content: "\ea72";
}

.icon-thumb-down-outline:before {
  content: "\ea73";
}

.icon-thumb-down:before {
  content: "\ea74";
}

.icon-thumb-up-outline:before {
  content: "\ea75";
}

.icon-thumb-up:before {
  content: "\ea76";
}

.icon-thumbs-up-down:before {
  content: "\ea77";
}

.icon-ticket-account:before {
  content: "\ea78";
}

.icon-ticket:before {
  content: "\ea79";
}

.icon-tie:before {
  content: "\ea7a";
}

.icon-tile-four:before {
  content: "\ea7b";
}

.icon-timelapse:before {
  content: "\ea7c";
}

.icon-timer-3:before {
  content: "\ea7d";
}

.icon-timer-10:before {
  content: "\ea7e";
}

.icon-timer-off:before {
  content: "\ea7f";
}

.icon-timer-sand:before {
  content: "\ea80";
}

.icon-timer:before {
  content: "\ea81";
}

.icon-timetable:before {
  content: "\ea82";
}

.icon-toggle-switch-off:before {
  content: "\ea83";
}

.icon-toggle-switch-on:before {
  content: "\ea84";
}

.icon-tooltip-edit:before {
  content: "\ea85";
}

.icon-tooltip-image:before {
  content: "\ea86";
}

.icon-tooltip-outline-plus:before {
  content: "\ea87";
}

.icon-tooltip-outline:before {
  content: "\ea88";
}

.icon-tooltip-text:before {
  content: "\ea89";
}

.icon-tooltip:before {
  content: "\ea8a";
}

.icon-tor:before {
  content: "\ea8b";
}

.icon-traffic-light:before {
  content: "\ea8c";
}

.icon-train:before {
  content: "\ea8d";
}

.icon-tram:before {
  content: "\ea8e";
}

.icon-transcribe-close:before {
  content: "\ea8f";
}

.icon-transcribe:before {
  content: "\ea90";
}

.icon-transfer:before {
  content: "\ea91";
}

.icon-trash:before {
  content: "\ea92";
}

.icon-tree:before {
  content: "\ea93";
}

.icon-trello:before {
  content: "\ea94";
}

.icon-trending-down:before {
  content: "\ea95";
}

.icon-trending-neutral:before {
  content: "\ea96";
}

.icon-trending-up:before {
  content: "\ea97";
}

.icon-truck:before {
  content: "\ea98";
}

.icon-tshirt-crew:before {
  content: "\ea99";
}

.icon-tshirt-v:before {
  content: "\ea9a";
}

.icon-tumblr-reblog:before {
  content: "\ea9b";
}

.icon-tumblr:before {
  content: "\ea9c";
}

.icon-twitch:before {
  content: "\ea9d";
}

.icon-twitter-box:before {
  content: "\ea9e";
}

.icon-twitter-circle:before {
  content: "\ea9f";
}

.icon-twitter-retweet:before {
  content: "\eaa0";
}

.icon-twitter:before {
  content: "\eaa1";
}

.icon-ubuntu:before {
  content: "\eaa2";
}

.icon-umbrella-outline:before {
  content: "\eaa3";
}

.icon-umbrella:before {
  content: "\eaa4";
}

.icon-undo-variant:before {
  content: "\eaa5";
}

.icon-undo:before {
  content: "\eaa6";
}

.icon-unfold-less:before {
  content: "\eaa7";
}

.icon-unfold-more:before {
  content: "\eaa8";
}

.icon-ungroup:before {
  content: "\eaa9";
}

.icon-untappd:before {
  content: "\eaaa";
}

.icon-upload:before {
  content: "\eaab";
}

.icon-usb:before {
  content: "\eaac";
}

.icon-vector-curve:before {
  content: "\eaad";
}

.icon-vector-point:before {
  content: "\eaae";
}

.icon-vector-square:before {
  content: "\eaaf";
}

.icon-verified:before {
  content: "\eab0";
}

.icon-vibration:before {
  content: "\eab1";
}

.icon-video-off:before {
  content: "\eab2";
}

.icon-video-switch:before {
  content: "\eab3";
}

.icon-video:before {
  content: "\eab4";
}

.icon-view-agenda:before {
  content: "\eab5";
}

.icon-view-array:before {
  content: "\eab6";
}

.icon-view-carousel:before {
  content: "\eab7";
}

.icon-view-column:before {
  content: "\eab8";
}

.icon-view-dashboard:before {
  content: "\eab9";
}

.icon-view-day:before {
  content: "\eaba";
}

.icon-view-headline:before {
  content: "\eabb";
}

.icon-view-list:before {
  content: "\eabc";
}

.icon-view-module:before {
  content: "\eabd";
}

.icon-view-quilt:before {
  content: "\eabe";
}

.icon-view-stream:before {
  content: "\eabf";
}

.icon-view-week:before {
  content: "\eac0";
}

.icon-vimeo:before {
  content: "\eac1";
}

.icon-vine:before {
  content: "\eac2";
}

.icon-vk-box:before {
  content: "\eac3";
}

.icon-vk-circle:before {
  content: "\eac4";
}

.icon-vk:before {
  content: "\eac5";
}

.icon-voicemail:before {
  content: "\eac6";
}

.icon-volume-high:before {
  content: "\eac7";
}

.icon-volume-low:before {
  content: "\eac8";
}

.icon-volume-medium:before {
  content: "\eac9";
}

.icon-volume-off:before {
  content: "\eaca";
}

.icon-volume:before {
  content: "\eacb";
}

.icon-vpn:before {
  content: "\eacc";
}

.icon-walk:before {
  content: "\eacd";
}

.icon-wallet-giftcard:before {
  content: "\eace";
}

.icon-wallet-membership:before {
  content: "\eacf";
}

.icon-wallet-travel:before {
  content: "\ead0";
}

.icon-wallet:before {
  content: "\ead1";
}

.icon-washing-machine:before {
  content: "\ead2";
}

.icon-watch-export:before {
  content: "\ead3";
}

.icon-watch-import:before {
  content: "\ead4";
}

.icon-watch:before {
  content: "\ead5";
}

.icon-water-off:before {
  content: "\ead6";
}

.icon-water-pump:before {
  content: "\ead7";
}

.icon-water:before {
  content: "\ead8";
}

.icon-weather-cloudy:before {
  content: "\ead9";
}

.icon-weather-fog:before {
  content: "\eada";
}

.icon-weather-hail:before {
  content: "\eadb";
}

.icon-weather-lightning:before {
  content: "\eadc";
}

.icon-weather-night:before {
  content: "\eadd";
}

.icon-weather-partlycloudy:before {
  content: "\eade";
}

.icon-weather-pouring:before {
  content: "\eadf";
}

.icon-weather-rainy:before {
  content: "\eae0";
}

.icon-weather-snowy:before {
  content: "\eae1";
}

.icon-weather-sunny:before {
  content: "\eae2";
}

.icon-weather-sunset-down:before {
  content: "\eae3";
}

.icon-weather-sunset-up:before {
  content: "\eae4";
}

.icon-weather-sunset:before {
  content: "\eae5";
}

.icon-weather-windy-variant:before {
  content: "\eae6";
}

.icon-weather-windy:before {
  content: "\eae7";
}

.icon-web:before {
  content: "\eae8";
}

.icon-webcam:before {
  content: "\eae9";
}

.icon-weight-kilogram:before {
  content: "\eaea";
}

.icon-weight:before {
  content: "\eaeb";
}

.icon-whatsapp:before {
  content: "\eaec";
}

.icon-wheelchair-accessibility:before {
  content: "\eaed";
}

.icon-white-balance-auto:before {
  content: "\eaee";
}

.icon-white-balance-incandescent:before {
  content: "\eaef";
}

.icon-white-balance-irradescent:before {
  content: "\eaf0";
}

.icon-white-balance-sunny:before {
  content: "\eaf1";
}

.icon-wifi:before {
  content: "\eaf2";
}

.icon-wii:before {
  content: "\eaf3";
}

.icon-wikipedia:before {
  content: "\eaf4";
}

.icon-window-close:before {
  content: "\eaf5";
}

.icon-window-closed:before {
  content: "\eaf6";
}

.icon-window-maximize:before {
  content: "\eaf7";
}

.icon-window-minimize:before {
  content: "\eaf8";
}

.icon-window-open:before {
  content: "\eaf9";
}

.icon-window-restore:before {
  content: "\eafa";
}

.icon-windows:before {
  content: "\eafb";
}

.icon-wordpress:before {
  content: "\eafc";
}

.icon-worker:before {
  content: "\eafd";
}

.icon-wunderlist:before {
  content: "\eafe";
}

.icon-xbox-controller-off:before {
  content: "\eaff";
}

.icon-xbox-controller:before {
  content: "\eb00";
}

.icon-xbox:before {
  content: "\eb01";
}

.icon-xda:before {
  content: "\eb02";
}

.icon-xml:before {
  content: "\eb03";
}

.icon-yeast:before {
  content: "\eb04";
}

.icon-yelp:before {
  content: "\eb05";
}

.icon-youtube-play:before {
  content: "\eb06";
}

.icon-youtube:before {
  content: "\eb07";
}

.icon-zip-box:before {
  content: "\eb08";
}

.icon-triangle-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #E0E0E0;
}

.icon-triangle-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #E0E0E0;
}

.icon-pet-i-down:before {
  content: "j";
}

.icon-pet-i-up:before {
  font-family: "FontAwesome", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  content: "j";
}

.icon-black-up-triangle:before {
  content: "▲";
  font-family: "FontAwesome", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 900;
  font-size: 1rem;
  position: absolute;
  margin-left: 4px;
}

.icon-black-down-triangle:before {
  content: "▼";
  font-family: "FontAwesome", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 900;
  font-size: 1rem;
  position: absolute;
  margin-left: 4px;
}

.icon-point-up {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  width: 1px;
  height: 1px;
}

/*-----------------------------------------------------------------------*/
/* Contains custom elements, components and general styles that
/* follows Google(TM) Material Design(TM) specs
/*
/* https://www.google.com/design/spec/material-design/introduction.html
/*-----------------------------------------------------------------------*/
/*----------------------------------------------------------------*/
/*  Avatars
/*----------------------------------------------------------------*/
.avatar {
  width: 40px;
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 0 8px 0 0;
  border-radius: 50%;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  color: #FFFFFF;
}
.avatar.square {
  border-radius: 0;
}
.avatar.small {
  width: 20px;
  min-width: 20px;
  height: 20px;
  line-height: 20px;
}
.avatar.big {
  width: 72px;
  min-width: 72px;
  height: 72px;
  line-height: 72px;
}
.avatar.huge {
  width: 96px;
  min-width: 96px;
  height: 96px;
  line-height: 96px;
}

/*----------------------------------------------------------------*/
/*  Forms
/*----------------------------------------------------------------*/
.form-wrapper {
  background: #FFFFFF;
  padding: 16px;
}
.form-wrapper .form-title {
  font-size: 21px;
  padding: 8px 0;
}

/*----------------------------------------------------------------*/
/*  Navigation - Simple
/*----------------------------------------------------------------*/
.navigation-simple .item {
  position: relative;
  cursor: pointer;
  text-align: left;
  margin: 0;
  padding: 0 24px;
  text-transform: none;
  line-height: 48px;
  max-height: 48px;
  height: 48px;
}
.navigation-simple .item md-icon {
  margin: 0 16px 0 0;
}
.navigation-simple .item .title {
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
}
.navigation-simple .subheader {
  font-size: 13px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  font-weight: 500;
  margin-top: 8px;
}
.navigation-simple .subheader.light {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.navigation-simple md-divider {
  margin: 8px 0;
}

/*----------------------------------------------------------------*/
/*  Pagination
/*----------------------------------------------------------------*/
.simple-pagination {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  background-color: #FFFFFF;
  padding: 0 8px;
}
.simple-pagination .pagination-item {
  min-width: 48px;
  min-height: 56px;
  line-height: 56px;
  border-radius: 0;
  margin: 0;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.54);
}
.simple-pagination .pagination-item:hover {
  color: rgba(0, 0, 0, 0.87);
}
.simple-pagination .pagination-item.active {
  cursor: default;
  color: rgba(0, 0, 0, 0.87);
}
.simple-pagination .pagination-item.disabled {
  cursor: default;
  color: rgba(0, 0, 0, 0.26);
}

/*----------------------------------------------------------------*/
/*  Price Tables
/*----------------------------------------------------------------*/
.price-tables .price-table {
  position: relative;
  background-color: #FFFFFF;
  width: 280px;
  border-radius: 2px;
  margin: 24px 24px 0 0;
  overflow: hidden;
}
.price-tables .price-table.style-1 .package-type {
  font-size: 17px;
  padding: 16px 24px;
}
.price-tables .price-table.style-1 .package-type .sale {
  font-size: 13px;
  font-weight: 600;
}
.price-tables .price-table.style-1 .price {
  padding: 32px 32px 16px 32px;
}
.price-tables .price-table.style-1 .price .currency {
  padding-right: 4px;
  font-size: 24px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
}
.price-tables .price-table.style-1 .price .value {
  font-size: 72px;
  font-weight: 300;
  line-height: 1;
}
.price-tables .price-table.style-1 .price .period {
  padding: 0 0 5px 4px;
  font-size: 17px;
  color: rgba(0, 0, 0, 0.54);
}
.price-tables .price-table.style-1 md-divider {
  margin: 16px 32px;
}
.price-tables .price-table.style-1 .terms {
  padding: 16px 32px;
  font-size: 15px;
}
.price-tables .price-table.style-1 .cta-button {
  margin: 16px auto 32px auto;
  width: 128px;
}
.price-tables .price-table.style-2 .badge {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: 11px;
  font-weight: 500;
  padding: 6px 8px;
}
.price-tables .price-table.style-2 .package-type {
  padding: 48px 32px 24px 32px;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}
.price-tables .price-table.style-2 .price {
  padding: 0 32px 8px 32px;
}
.price-tables .price-table.style-2 .price .currency {
  padding-right: 4px;
  font-size: 24px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
}
.price-tables .price-table.style-2 .price .value {
  font-size: 56px;
  font-weight: 300;
  line-height: 1;
}
.price-tables .price-table.style-2 .period {
  padding: 0 32px;
  font-size: 15px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
  text-align: center;
}
.price-tables .price-table.style-2 .terms {
  padding: 32px;
  font-size: 15px;
}
.price-tables .price-table.style-2 .terms .term {
  padding-bottom: 8px;
}
.price-tables .price-table.style-2 .terms .term:last-child {
  padding-bottom: 0;
}
.price-tables .price-table.style-2 .cta-button {
  margin: 8px auto 32px auto;
  width: 128px;
}
.price-tables .price-table.style-3 .package-type {
  padding: 32px;
  text-align: center;
}
.price-tables .price-table.style-3 .package-type .title {
  font-size: 34px;
}
.price-tables .price-table.style-3 .package-type .subtitle {
  font-size: 17px;
  color: rgba(0, 0, 0, 0.54);
  font-weight: 500;
}
.price-tables .price-table.style-3 .price {
  padding: 16px 32px;
}
.price-tables .price-table.style-3 .price .currency {
  padding-right: 4px;
  font-size: 15px;
  font-weight: 500;
}
.price-tables .price-table.style-3 .price .value {
  font-size: 34px;
  font-weight: 300;
  line-height: 1;
}
.price-tables .price-table.style-3 .price .period {
  padding-left: 4px;
  text-align: center;
}
.price-tables .price-table.style-3 .terms {
  margin: 32px;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.54);
}
.price-tables .price-table.style-3 .terms .term {
  padding-bottom: 16px;
}
.price-tables .price-table.style-3 .terms .term:last-child {
  padding-bottom: 0;
}
.price-tables .price-table.style-3 .cta-button {
  margin: 8px 32px;
}
.price-tables .price-table.style-3 .note {
  padding: 8px 32px 16px 32px;
  text-align: center;
  color: rgba(0, 0, 0, 0.54);
}

/*----------------------------------------------------------------*/
/*  Table - Simple
/*----------------------------------------------------------------*/
.simple-table-container {
  background: #FFFFFF;
}
.simple-table-container .table-title {
  font-size: 20px;
  padding: 24px;
}

table.simple {
  width: 100%;
  border: none;
  border-spacing: 0;
  text-align: left;
}
table.simple thead tr th {
  padding: 16px 8px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  white-space: nowrap;
  min-width: 120px;
}
table.simple thead tr th:first-child {
  padding-left: 24px;
}
table.simple thead tr th:last-child {
  padding-right: 24px;
}
table.simple tbody tr td {
  padding: 16px 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
table.simple tbody tr td:first-child {
  padding-left: 24px;
}
table.simple tbody tr td:last-child {
  padding-right: 24px;
}
table.simple tbody tr:last-child td {
  border-bottom: none;
}
table.simple.clickable tbody tr {
  cursor: pointer;
}
table.simple.clickable tbody tr:hover {
  background: rgba(0, 0, 0, 0.03);
}

/*----------------------------------------------------------------*/
/*  Typography
/*----------------------------------------------------------------*/
html {
  font-size: 62.5%;
  font-family: "Roboto", "Helvetica Neue", "Arial", sans-serif;
  line-height: 1.4;
  letter-spacing: -0.1px;
}

body {
  font-size: 1.3rem;
}

html, body {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

form {
  font-size: 1.6rem;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: normal;
}

h1, .h1 {
  font-size: 2.4rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.6rem;
}

h4, .h4 {
  font-size: 1.4rem;
}

h5, .h5 {
  font-size: 1.3rem;
}

h6, .h6 {
  font-size: 1.2rem;
}

a {
  text-decoration: none;
}
a:hover, a:active {
  text-decoration: underline;
}

abbr {
  cursor: help;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.54);
}

blockquote {
  border-left: 3px solid rgba(0, 0, 0, 0.12);
  font-style: italic;
  margin: 1em 0;
  padding-left: 16px;
}
blockquote footer {
  font-style: normal;
}
blockquote footer:before {
  content: "— ";
}
blockquote.reverse {
  border-left: none;
  border-right: 3px solid rgba(0, 0, 0, 0.12);
  text-align: right;
  padding-left: 0;
  padding-right: 16px;
}
blockquote.reverse footer:before {
  content: "";
}
blockquote.reverse footer:after {
  content: "— ";
}

code {
  font-family: "Monaco", "Menlo", "Consolas", "Ubuntu Mono", monospace;
}
code:not(.highlight) {
  background: rgba(0, 0, 0, 0.065);
  color: #106CC8;
  margin: 0 1px;
  padding: 2px 3px;
  border-radius: 2px;
}

dl dt {
  font-weight: bold;
}
dl dd {
  margin-bottom: 1em;
}

mark {
  background: #F7F49A;
}

pre {
  line-height: 1.6;
  margin: 8px 16px;
  white-space: pre-wrap;
}

small {
  font-size: 80%;
}

table thead tr th {
  text-align: left;
}

.text-italic {
  font-style: italic;
}

.text-semibold {
  font-weight: 600;
}

.text-bold, strong {
  font-weight: 700;
}

.text-strike {
  text-decoration: line-through;
}

.text-super {
  vertical-align: super;
}

.text-sub {
  vertical-align: sub;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-weight-100 {
  font-weight: 100;
}

.font-weight-200 {
  font-weight: 200;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-800 {
  font-weight: 800;
}

.font-weight-900 {
  font-weight: 900;
}

.font-size-2 {
  font-size: 2px;
}

.font-size-4 {
  font-size: 4px;
}

.font-size-6 {
  font-size: 6px;
}

.font-size-8 {
  font-size: 8px;
}

.font-size-10 {
  font-size: 10px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-22 {
  font-size: 22px;
}

.font-size-24 {
  font-size: 24px;
}

.font-size-26 {
  font-size: 26px;
}

.font-size-28 {
  font-size: 28px;
}

.font-size-30 {
  font-size: 30px;
}

.font-size-32 {
  font-size: 32px;
}

.font-size-34 {
  font-size: 34px;
}

.font-size-36 {
  font-size: 36px;
}

.font-size-38 {
  font-size: 38px;
}

.font-size-40 {
  font-size: 40px;
}

.font-size-42 {
  font-size: 42px;
}

.font-size-44 {
  font-size: 44px;
}

.font-size-46 {
  font-size: 46px;
}

.font-size-48 {
  font-size: 48px;
}

.font-size-50 {
  font-size: 50px;
}

.font-size-52 {
  font-size: 52px;
}

.font-size-54 {
  font-size: 54px;
}

.font-size-56 {
  font-size: 56px;
}

.font-size-58 {
  font-size: 58px;
}

.font-size-60 {
  font-size: 60px;
}

.font-size-62 {
  font-size: 62px;
}

.font-size-64 {
  font-size: 64px;
}

.font-size-66 {
  font-size: 66px;
}

.font-size-68 {
  font-size: 68px;
}

.font-size-70 {
  font-size: 70px;
}

.font-size-72 {
  font-size: 72px;
}

.font-size-74 {
  font-size: 74px;
}

.font-size-76 {
  font-size: 76px;
}

.font-size-78 {
  font-size: 78px;
}

.font-size-80 {
  font-size: 80px;
}

.font-size-82 {
  font-size: 82px;
}

.font-size-84 {
  font-size: 84px;
}

.font-size-86 {
  font-size: 86px;
}

.font-size-88 {
  font-size: 88px;
}

.font-size-90 {
  font-size: 90px;
}

.font-size-92 {
  font-size: 92px;
}

.font-size-94 {
  font-size: 94px;
}

.font-size-96 {
  font-size: 96px;
}

.font-size-98 {
  font-size: 98px;
}

.font-size-100 {
  font-size: 100px;
}

.font-size-102 {
  font-size: 102px;
}

.font-size-104 {
  font-size: 104px;
}

.font-size-106 {
  font-size: 106px;
}

.font-size-108 {
  font-size: 108px;
}

.font-size-110 {
  font-size: 110px;
}

.font-size-112 {
  font-size: 112px;
}

.font-size-114 {
  font-size: 114px;
}

.font-size-116 {
  font-size: 116px;
}

.font-size-118 {
  font-size: 118px;
}

.font-size-120 {
  font-size: 120px;
}

.line-height-2 {
  line-height: 2px;
}

.line-height-4 {
  line-height: 4px;
}

.line-height-6 {
  line-height: 6px;
}

.line-height-8 {
  line-height: 8px;
}

.line-height-10 {
  line-height: 10px;
}

.line-height-12 {
  line-height: 12px;
}

.line-height-14 {
  line-height: 14px;
}

.line-height-16 {
  line-height: 16px;
}

.line-height-18 {
  line-height: 18px;
}

.line-height-20 {
  line-height: 20px;
}

.line-height-22 {
  line-height: 22px;
}

.line-height-24 {
  line-height: 24px;
}

.line-height-26 {
  line-height: 26px;
}

.line-height-28 {
  line-height: 28px;
}

.line-height-30 {
  line-height: 30px;
}

.line-height-32 {
  line-height: 32px;
}

.line-height-34 {
  line-height: 34px;
}

.line-height-36 {
  line-height: 36px;
}

.line-height-38 {
  line-height: 38px;
}

.line-height-40 {
  line-height: 40px;
}

.line-height-42 {
  line-height: 42px;
}

.line-height-44 {
  line-height: 44px;
}

.line-height-46 {
  line-height: 46px;
}

.line-height-48 {
  line-height: 48px;
}

.line-height-50 {
  line-height: 50px;
}

.line-height-52 {
  line-height: 52px;
}

.line-height-54 {
  line-height: 54px;
}

.line-height-56 {
  line-height: 56px;
}

.line-height-58 {
  line-height: 58px;
}

.line-height-60 {
  line-height: 60px;
}

.line-height-62 {
  line-height: 62px;
}

.line-height-64 {
  line-height: 64px;
}

.line-height-66 {
  line-height: 66px;
}

.line-height-68 {
  line-height: 68px;
}

.line-height-70 {
  line-height: 70px;
}

.line-height-72 {
  line-height: 72px;
}

.line-height-74 {
  line-height: 74px;
}

.line-height-76 {
  line-height: 76px;
}

.line-height-78 {
  line-height: 78px;
}

.line-height-80 {
  line-height: 80px;
}

.line-height-82 {
  line-height: 82px;
}

.line-height-84 {
  line-height: 84px;
}

.line-height-86 {
  line-height: 86px;
}

.line-height-88 {
  line-height: 88px;
}

.line-height-90 {
  line-height: 90px;
}

.line-height-92 {
  line-height: 92px;
}

.line-height-94 {
  line-height: 94px;
}

.line-height-96 {
  line-height: 96px;
}

.line-height-98 {
  line-height: 98px;
}

.line-height-100 {
  line-height: 100px;
}

.line-height-102 {
  line-height: 102px;
}

.line-height-104 {
  line-height: 104px;
}

.line-height-106 {
  line-height: 106px;
}

.line-height-108 {
  line-height: 108px;
}

.line-height-110 {
  line-height: 110px;
}

.line-height-112 {
  line-height: 112px;
}

.line-height-114 {
  line-height: 114px;
}

.line-height-116 {
  line-height: 116px;
}

.line-height-118 {
  line-height: 118px;
}

.line-height-120 {
  line-height: 120px;
}

.text-boxed, .text-boxed-light {
  border-radius: 2px;
  padding: 4px 8px;
  margin: 0 8px;
  font-size: 11px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
  background-color: rgba(0, 0, 0, 0.12);
  white-space: nowrap;
}

.text-boxed-light {
  background-color: rgba(255, 255, 255, 0.7);
}

.text-truncate {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-nowrap {
  white-space: nowrap;
}

.angular-google-map-container {
  height: 400px;
}

.moment-picker .moment-picker-container {
  width: 296px;
  border: none;
  border-radius: 2px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  margin-left: 0;
  padding: 8px;
}
.moment-picker .moment-picker-container th,
.moment-picker .moment-picker-container td {
  min-width: 0;
  width: 40px;
  height: 40px;
  padding: 8px;
  text-shadow: none;
  border-radius: 35px;
}
.moment-picker .moment-picker-container th:hover,
.moment-picker .moment-picker-container td:hover {
  background: #F5F5F5;
  background-image: none;
}
.moment-picker .moment-picker-container td.selected {
  background: #03A9F4;
  background-image: none;
}
.moment-picker .moment-picker-container .header-view th {
  border-radius: 0;
  white-space: nowrap;
  width: auto;
}
.moment-picker .moment-picker-container .header-view th:first-child, .moment-picker .moment-picker-container .header-view th:last-child {
  width: 20%;
}

/*----------------------------------------------------------------*/
/*  @ Chart C3
/*----------------------------------------------------------------*/
.c3 {
  transition: all 300ms ease;
  /*-- Chart --*/
  /*-- Line --*/
  /*-- Point --*/
  /*-- Axis --*/
  /*-- Grid --*/
  /*-- Text on Chart --*/
  /*-- Bar --*/
  /*-- Focus --*/
  /*-- Region --*/
  /*-- Brush --*/
  /*-- Select - Drag --*/
  /*-- Legend --*/
  /*-- Tooltip --*/
  /*-- Area --*/
  /*-- Arc --*/
}
.c3 svg {
  font: 12px RobotoDraft, Roboto, "Helvetica Neue", sans-serif;
}
.c3 path, .c3 line {
  fill: none;
  stroke: #000;
}
.c3 text {
  font-family: "Roboto", "Helvetica Neue", "Arial", sans-serif;
  user-select: none;
}
.c3 .c3-legend-item-tile, .c3 .c3-xgrid-focus, .c3 .c3-ygrid, .c3 .c3-event-rect, .c3 .c3-bars path {
  shape-rendering: crispEdges;
}
.c3 .c3-chart-line {
  transition: all 300ms ease;
}
.c3 .c3-line {
  stroke-width: 1px;
}
.c3.stroke-3 .c3-line {
  stroke-width: 3px;
}
.c3.stroke-2 .c3-line {
  stroke-width: 2px;
}
.c3 .c3-circle {
  transition: all 300ms ease;
  stroke: white;
  stroke-width: 2;
}
.c3 .c3-circle._expanded_ {
  stroke-width: 3;
  stroke: white;
}
.c3 .c3-chart-arc path {
  stroke: #fff;
}
.c3 .c3-chart-arc text {
  fill: #fff;
  font-size: 13px;
}
.c3 .c3-grid line {
  stroke: rgba(0, 0, 0, 0.04);
  stroke-width: 1px;
  stroke-dasharray: 0;
}
.c3 .c3-grid text {
  fill: #aaa;
}
.c3 .c3-axis .tick {
  stroke-width: 0;
}
.c3 .c3-axis text {
  fill: rgba(0, 0, 0, 0.33);
}
.c3 .c3-axis line, .c3 .c3-axis path {
  stroke: rgba(0, 0, 0, 0.04);
}
.c3 .c3-xgrid, .c3 .c3-ygrid {
  stroke-dasharray: 0 0;
}
.c3 .c3-text.c3-empty {
  fill: #808080;
  font-size: 2em;
}
.c3 .c3-bar {
  stroke-width: 0;
}
.c3 .c3-bar._expanded_ {
  fill-opacity: 0.75;
}
.c3 .c3-target.c3-focused {
  opacity: 1;
}
.c3 .c3-target.c3-focused path.c3-line, .c3 .c3-target.c3-focused .c3-target.c3-focused path.c3-step {
  stroke-width: 3px;
  transition: all 300ms ease;
}
.c3 .c3-target.c3-defocused {
  opacity: 0.3 !important;
}
.c3 .c3-region {
  fill: steelblue;
  fill-opacity: 0.1;
}
.c3 .c3-brush .extent {
  fill-opacity: 0.1;
}
.c3 .c3-legend-item {
  font-size: 12px;
}
.c3 .c3-legend-item-hidden {
  opacity: 0.15;
}
.c3 .c3-legend-background {
  opacity: 0.75;
  fill: white;
  stroke: lightgray;
  stroke-width: 1;
}
.c3 .c3-tooltip-container {
  z-index: 10;
}
.c3 .c3-tooltip {
  border-collapse: collapse;
  border-spacing: 0;
  background-color: #fff;
  empty-cells: show;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.098), 0 1px 10px 0 rgba(0, 0, 0, 0.084);
  opacity: 1;
  border-radius: 2px;
  overflow: hidden;
  padding: 4px 0;
}
.c3 .c3-tooltip tr {
  border: none;
}
.c3 .c3-tooltip th {
  background-color: transparent;
  font-size: 14px;
  font-weight: 400;
  padding: 8px 12px;
  text-align: left;
  color: rgba(0, 0, 0, 0.54);
}
.c3 .c3-tooltip td {
  font-size: 13px;
  padding: 4px 12px !important;
  background-color: #fff;
  border-left: none;
  color: rgba(0, 0, 0, 0.84);
}
.c3 .c3-tooltip td > span {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 6px;
}
.c3 .c3-tooltip td.value {
  font-size: 14px !important;
  text-align: right;
  color: rgba(0, 0, 0, 0.84);
}
.c3 .c3-area {
  stroke-width: 0;
  opacity: 0.2;
}
.c3 .c3-chart-arcs-title {
  dominant-baseline: middle;
  font-size: 1.3em;
}
.c3 .c3-chart-arcs .c3-chart-arcs-background {
  fill: #e0e0e0;
  stroke: none;
}
.c3 .c3-chart-arcs .c3-chart-arcs-gauge-unit {
  fill: #000;
  font-size: 16px;
}
.c3 .c3-chart-arcs .c3-chart-arcs-gauge-max {
  fill: #777;
}
.c3 .c3-chart-arcs .c3-chart-arcs-gauge-min {
  fill: #777;
}
.c3 .c3-chart-arc .c3-gauge-value {
  fill: #000;
}

.ct-label {
  font-size: 13px;
}

.ct-chart-donut .ct-label,
.ct-chart-pie .ct-label {
  font-size: 13px;
}

.chart-legend,
.bar-legend,
.line-legend,
.pie-legend,
.radar-legend,
.polararea-legend,
.doughnut-legend {
  margin-top: 15px;
}

/* DataTable Specific Content Rules & Fixes */
table.dataTable {
  width: 100%;
  margin: 0 auto;
  clear: both;
  border-spacing: 0;
}
table.dataTable thead tr {
  background: #FFFFFF;
}
table.dataTable thead th {
  text-align: left;
  vertical-align: middle;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 16px 8px;
}
table.dataTable thead th:first-child {
  padding-left: 24px;
}
table.dataTable thead th:last-child {
  border-right: none;
  padding-right: 24px;
}
table.dataTable thead th:active {
  outline: none;
}
table.dataTable thead th.dt-head-left {
  text-align: left;
}
table.dataTable thead th.dt-head-center {
  text-align: center;
}
table.dataTable thead th.dt-head-right {
  text-align: right;
}
table.dataTable thead th.dt-head-justify {
  text-align: justify;
}
table.dataTable thead th.dt-head-nowrap {
  white-space: nowrap;
}
table.dataTable thead th .table-header .column-title {
  display: block;
  font-weight: 600;
  white-space: nowrap;
}
table.dataTable thead th .table-header .selectize-control {
  margin-top: 15px;
}
table.dataTable thead th .table-header .selectize-control.single .selectize-input {
  padding: 7px 10px;
}
table.dataTable thead th .table-header .selectize-control.single .selectize-input:after {
  right: 10px;
  border-width: 5px 4px 0 4px;
  border-color: rgba(0, 0, 0, 0.12) transparent transparent transparent;
}
table.dataTable thead th .table-header .column-search-input {
  display: inline-block;
  margin-top: 15px;
  width: 100%;
  height: 33px;
}
table.dataTable thead td {
  padding: 15px;
  vertical-align: middle;
}
table.dataTable thead td:active {
  outline: none;
}
table.dataTable thead td.dt-head-left {
  text-align: left;
}
table.dataTable thead td.dt-head-center {
  text-align: center;
}
table.dataTable thead td.dt-head-right {
  text-align: right;
}
table.dataTable thead td.dt-head-justify {
  text-align: justify;
}
table.dataTable thead td.dt-head-nowrap {
  white-space: nowrap;
}
table.dataTable thead .sorting, table.dataTable thead .sorting_asc, table.dataTable thead .sorting_desc {
  background: none;
}
table.dataTable thead .sorting .table-header .column-title, table.dataTable thead .sorting_asc .table-header .column-title, table.dataTable thead .sorting_desc .table-header .column-title {
  cursor: pointer;
}
table.dataTable thead .sorting .table-header .column-title:after, table.dataTable thead .sorting_asc .table-header .column-title:after, table.dataTable thead .sorting_desc .table-header .column-title:after {
  position: relative;
  font-family: "icomoon", sans-serif;
  font-weight: normal;
  margin-left: 10px;
  top: 2px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54);
}
table.dataTable thead .sorting .table-header .column-title:after {
  content: "\eaa8";
}
table.dataTable thead .sorting_asc .table-header .column-title:after {
  content: "\e718";
}
table.dataTable thead .sorting_desc .table-header .column-title:after {
  content: "\e715";
}
table.dataTable tfoot th {
  font-weight: 600;
  text-align: left;
  padding: 10px 18px 6px 18px;
  border-top: 1px solid #111111;
}
table.dataTable tfoot th.dt-head-left {
  text-align: left;
}
table.dataTable tfoot th.dt-head-center {
  text-align: center;
}
table.dataTable tfoot th.dt-head-right {
  text-align: right;
}
table.dataTable tfoot th.dt-head-justify {
  text-align: justify;
}
table.dataTable tfoot th.dt-head-nowrap {
  white-space: nowrap;
}
table.dataTable tfoot td {
  padding: 10px 18px 6px 18px;
  border-top: 1px solid #111111;
}
table.dataTable tfoot td.dt-head-left {
  text-align: left;
}
table.dataTable tfoot td.dt-head-center {
  text-align: center;
}
table.dataTable tfoot td.dt-head-right {
  text-align: right;
}
table.dataTable tfoot td.dt-head-justify {
  text-align: justify;
}
table.dataTable tfoot td.dt-head-nowrap {
  white-space: nowrap;
}
table.dataTable tbody tr {
  background-color: #FFFFFF;
}
table.dataTable tbody tr.selected {
  background-color: #B0BED9;
}
table.dataTable tbody tr.details-open td.detail-column i {
  background: #FFFFFF;
}
table.dataTable tbody tr.details-row.odd tr {
  background: #FFFFFF;
}
table.dataTable tbody tr.details-row > td {
  padding: 0;
}
table.dataTable tbody th {
  padding: 14px 15px;
  vertical-align: middle;
}
table.dataTable tbody th.dt-body-left {
  text-align: left;
}
table.dataTable tbody th.dt-body-center {
  text-align: center;
}
table.dataTable tbody th.dt-body-right {
  text-align: right;
}
table.dataTable tbody th.dt-body-justify {
  text-align: justify;
}
table.dataTable tbody th.dt-body-nowrap {
  white-space: nowrap;
}
table.dataTable tbody td {
  padding: 16px 8px;
  vertical-align: middle;
}
table.dataTable tbody td:first-child {
  padding-left: 24px;
}
table.dataTable tbody td:last-child {
  padding-right: 24px;
}
table.dataTable tbody td.dt-body-left {
  text-align: left;
}
table.dataTable tbody td.dt-body-center {
  text-align: center;
}
table.dataTable tbody td.dt-body-right {
  text-align: right;
}
table.dataTable tbody td.dt-body-justify {
  text-align: justify;
}
table.dataTable tbody td.dt-body-nowrap {
  white-space: nowrap;
}
table.dataTable th.dt-left {
  text-align: left;
}
table.dataTable th.dt-center {
  text-align: center;
}
table.dataTable th.dt-right {
  text-align: right;
}
table.dataTable th.dt-justify {
  text-align: justify;
}
table.dataTable th.dt-nowrap {
  white-space: nowrap;
}
table.dataTable td.dt-left {
  text-align: left;
}
table.dataTable td.dt-center {
  text-align: center;
}
table.dataTable td.dataTables_empty {
  height: 51px;
  text-align: center;
}
table.dataTable td.dt-right {
  text-align: right;
}
table.dataTable td.dt-justify {
  text-align: justify;
}
table.dataTable td.dt-nowrap {
  white-space: nowrap;
}
table.dataTable.row-border thead {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
table.dataTable.row-border tbody tr:first-child th {
  border-top: none;
}
table.dataTable.row-border tbody tr:first-child td {
  border-top: none;
}
table.dataTable.row-border tbody tr:last-child td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
table.dataTable.row-border tbody th {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
table.dataTable.row-border tbody td {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
table.dataTable.cell-border tbody tr:first-child th {
  border-top: none;
}
table.dataTable.cell-border tbody tr:first-child td {
  border-top: none;
}
table.dataTable.cell-border tbody th {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
table.dataTable.cell-border tbody th:last-child {
  border-right: none;
}
table.dataTable.cell-border tbody td {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
table.dataTable.cell-border tbody td:last-child {
  border-right: none;
}
table.dataTable.stripe tbody tr.odd {
  background-color: #FFFFFF;
}
table.dataTable.stripe tbody tr.odd.selected {
  background-color: #ABB9D3;
}
table.dataTable.hover tbody tr:hover {
  background-color: whitesmoke;
}
table.dataTable.hover tbody tr:hover.selected {
  background-color: #A9B7D1;
}
table.dataTable.hover tbody tr.odd:hover {
  background-color: whitesmoke;
}
table.dataTable.hover tbody tr.odd:hover.selected {
  background-color: #A9B7D1;
}
table.dataTable.hover tbody tr.even:hover {
  background-color: whitesmoke;
}
table.dataTable.hover tbody tr.even:hover.selected {
  background-color: #A9B7D1;
}
table.dataTable.order-column tbody tr > .sorting_1 {
  background-color: #F9F9F9;
}
table.dataTable.order-column tbody tr > .sorting_2 {
  background-color: #F9F9F9;
}
table.dataTable.order-column tbody tr > .sorting_3 {
  background-color: #F9F9F9;
}
table.dataTable.order-column tbody tr.selected > .sorting_1 {
  background-color: #ACBAD4;
}
table.dataTable.order-column tbody tr.selected > .sorting_2 {
  background-color: #ACBAD4;
}
table.dataTable.order-column tbody tr.selected > .sorting_3 {
  background-color: #ACBAD4;
}
table.dataTable.order-column.stripe tbody tr.odd > .sorting_1 {
  background-color: #F1F1F1;
}
table.dataTable.order-column.stripe tbody tr.odd > .sorting_2 {
  background-color: #F3F3F3;
}
table.dataTable.order-column.stripe tbody tr.odd > .sorting_3 {
  background-color: whitesmoke;
}
table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_1 {
  background-color: #A6B3CD;
}
table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_2 {
  background-color: #A7B5CE;
}
table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_3 {
  background-color: #A9B6D0;
}
table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {
  background-color: #F9F9F9;
}
table.dataTable.order-column.stripe tbody tr.even > .sorting_2 {
  background-color: #FBFBFB;
}
table.dataTable.order-column.stripe tbody tr.even > .sorting_3 {
  background-color: #FDFDFD;
}
table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_1 {
  background-color: #ACBAD4;
}
table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_2 {
  background-color: #ADBBD6;
}
table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_3 {
  background-color: #AFBDD8;
}
table.dataTable.order-column.hover tbody tr:hover > .sorting_1 {
  background-color: #EAEAEA;
}
table.dataTable.order-column.hover tbody tr:hover > .sorting_2 {
  background-color: #EBEBEB;
}
table.dataTable.order-column.hover tbody tr:hover > .sorting_3 {
  background-color: #EEEEEE;
}
table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_1 {
  background-color: #A1AEC7;
}
table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_2 {
  background-color: #A2AFC8;
}
table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_3 {
  background-color: #A4B2CB;
}
table.dataTable.order-column.hover tbody tr.odd:hover > .sorting_1 {
  background-color: #EAEAEA;
}
table.dataTable.order-column.hover tbody tr.odd:hover > .sorting_2 {
  background-color: #EBEBEB;
}
table.dataTable.order-column.hover tbody tr.odd:hover > .sorting_3 {
  background-color: #EEEEEE;
}
table.dataTable.order-column.hover tbody tr.odd:hover.selected > .sorting_1 {
  background-color: #A1AEC7;
}
table.dataTable.order-column.hover tbody tr.odd:hover.selected > .sorting_2 {
  background-color: #A2AFC8;
}
table.dataTable.order-column.hover tbody tr.odd:hover.selected > .sorting_3 {
  background-color: #A4B2CB;
}
table.dataTable.order-column.hover tbody tr.even:hover > .sorting_1 {
  background-color: #EAEAEA;
}
table.dataTable.order-column.hover tbody tr.even:hover > .sorting_2 {
  background-color: #EBEBEB;
}
table.dataTable.order-column.hover tbody tr.even:hover > .sorting_3 {
  background-color: #EEEEEE;
}
table.dataTable.order-column.hover tbody tr.even:hover.selected > .sorting_1 {
  background-color: #A1AEC7;
}
table.dataTable.order-column.hover tbody tr.even:hover.selected > .sorting_2 {
  background-color: #A2AFC8;
}
table.dataTable.order-column.hover tbody tr.even:hover.selected > .sorting_3 {
  background-color: #A4B2CB;
}
table.dataTable.no-footer {
  border-bottom: none;
}
table.dataTable.nowrap th {
  white-space: nowrap;
}
table.dataTable.nowrap td {
  white-space: nowrap;
}
table.dataTable.compact thead th {
  padding: 5px 9px;
}
table.dataTable.compact thead td {
  padding: 5px 9px;
}
table.dataTable.compact tfoot th {
  padding: 5px 9px 3px 9px;
}
table.dataTable.compact tfoot td {
  padding: 5px 9px 3px 9px;
}
table.dataTable.compact tbody th {
  padding: 4px 5px;
}
table.dataTable.compact tbody td {
  padding: 4px 5px;
}
table.dataTable .show-details {
  padding: 5px 10px;
  font-weight: bold;
}
table.dataTable .action-buttons {
  display: flex;
  justify-content: center;
}
table.dataTable .action-buttons .button {
  padding: 8px;
}

/*
 * Control feature layout
 */
.dataTables_wrapper {
  display: flex;
  flex-direction: column;
}
.dataTables_wrapper .top {
  padding: 16px 24px;
}
.dataTables_wrapper .bottom {
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  padding: 16px 8px;
}
.dataTables_wrapper .bottom .left {
  display: flex;
  flex: 0 0 50%;
  max-width: 50%;
  align-self: center;
  padding: 0 8px;
}
.dataTables_wrapper .bottom .left .length {
  display: block;
}
.dataTables_wrapper .bottom .right {
  display: flex;
  flex: 0 0 50%;
  max-width: 50%;
  align-self: center;
  justify-content: flex-end;
  padding: 0 8px;
}
.dataTables_wrapper .bottom .right .info {
  display: block;
  align-self: center;
}
.dataTables_wrapper .bottom .right .info .dataTables_info {
  padding: 0 8px;
  font-weight: 600;
}
.dataTables_wrapper .bottom .right .pagination {
  display: block;
  align-self: center;
  white-space: nowrap;
}
.dataTables_wrapper .bottom .right .pagination .dataTables_paginate {
  padding: 0;
  margin: 0 !important;
}
.dataTables_wrapper .dataTables_filter {
  float: right;
}
.dataTables_wrapper .dataTables_filter input {
  margin-left: 0.5em;
  border: 1px solid rgba(0, 0, 0, 0.12);
  height: 24px;
  padding: 4px 8px;
}
.dataTables_wrapper .dataTables_paginate .paginate_button {
  background: #FFFFFF;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 9px 12px;
  border-radius: 2px;
  cursor: pointer;
  user-select: none;
  margin: 0 2px;
}
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  box-shadow: inset 999em 0 0 0 rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.87) !important;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  background: none;
}
.dataTables_wrapper .dataTables_paginate .paginate_button:active {
  box-shadow: inset 999em 0 0 0 rgba(0, 0, 0, 0.08), inset 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 0 0 rgba(255, 255, 255, 0.35);
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  color: rgba(0, 0, 0, 0.54) !important;
  border-color: rgba(0, 0, 0, 0.12) !important;
  background: #FFFFFF !important;
  box-shadow: none !important;
  cursor: default;
}
.dataTables_wrapper .dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 40px;
  margin-left: -50%;
  margin-top: -25px;
  padding-top: 20px;
  text-align: center;
  font-size: 1.2em;
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(25%, rgba(255, 255, 255, 0.9)), color-stop(75%, rgba(255, 255, 255, 0.9)), color-stop(100%, rgba(255, 255, 255, 0)));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  /* Chrome10+,Safari5.1+ */
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  /* FF3.6+ */
  background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  /* IE10+ */
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  /* Opera 11.10+ */
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  /* W3C */
}
.dataTables_wrapper .dataTables_scroll {
  display: flex;
  flex: 1;
  flex-direction: column;
  clear: both;
  /* Clear the table border & shadows */
}
.dataTables_wrapper .dataTables_scroll table.dataTable {
  box-shadow: none;
}
.dataTables_wrapper .dataTables_scroll table.dataTable.row-border thead {
  border: none;
}
.dataTables_wrapper .dataTables_scroll .dataTables_scrollHead {
  display: flex;
  min-height: 95px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.095), 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 2px 1px 0 rgba(0, 0, 0, 0.05);
  background: #FFFFFF;
  overflow: visible !important;
}
.dataTables_wrapper .dataTables_scroll .dataTables_scrollHead tr th:last-child .table-header .column-title {
  position: relative;
  left: 7px;
}
.dataTables_wrapper .dataTables_scroll .dataTables_scrollBody {
  display: flex;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.095), 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 2px 1px 0 rgba(0, 0, 0, 0.05);
  overflow-x: hidden !important;
  overflow-y: scroll !important;
  -webkit-overflow-scrolling: touch;
}
.dataTables_wrapper .dataTables_scroll .dataTables_scrollBody th > .dataTables_sizing {
  height: 0;
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important;
}
.dataTables_wrapper .dataTables_scroll .dataTables_scrollBody td > .dataTables_sizing {
  height: 0;
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important;
}
.dataTables_wrapper.no-footer .dataTables_scrollHead table,
.dataTables_wrapper.no-footer .dataTables_scrollBody table {
  border-bottom: none;
}
.dataTables_wrapper:after {
  visibility: hidden;
  display: block;
  content: "";
  clear: both;
  height: 0;
}

/* Responsive datatable */
table.dataTable.dtr-inline.collapsed.dtr-inline.collapsed > tbody > tr > td:first-child,
table.dataTable.dtr-inline.collapsed.dtr-inline.collapsed > tbody > tr > th:first-child {
  padding-left: 40px;
}
table.dataTable.dtr-inline.collapsed.dtr-inline.collapsed > tbody > tr > td:first-child:before,
table.dataTable.dtr-inline.collapsed.dtr-inline.collapsed > tbody > tr > th:first-child:before {
  content: "+";
  width: 15px;
  height: 15px;
  line-height: 15px;
  top: 50%;
  margin-top: -7px;
  left: 8px;
  color: rgba(0, 0, 0, 0.54);
  background: none;
  border: none;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.54);
  font-weight: bold;
}
table.dataTable.dtr-inline.collapsed.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before,
table.dataTable.dtr-inline.collapsed.dtr-inline.collapsed > tbody > tr.parent > th:first-child:before {
  background: none;
}
table.dataTable.dtr-inline.collapsed > tbody > tr.child ul li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 8px 0;
}
table.dataTable.dtr-inline.collapsed > tbody > tr.child ul li:first-child {
  padding-top: 0;
}
table.dataTable.dtr-inline.collapsed > tbody > tr.child ul li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

@media screen and (max-width: 1280px) {
  .dataTables_wrapper .bottom {
    flex-wrap: wrap;
  }
  .dataTables_wrapper .bottom .left {
    flex: 0 0 100%;
    max-width: none;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .dataTables_wrapper .bottom .right {
    flex: 0 0 100%;
    max-width: none;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
/*

github.com style (c) Vasily Polovnyov <vast@whiteants.net>

*/
hljs,
[hljs] {
  display: block;
  overflow-x: auto;
  color: #333;
  background: #f8f8f8;
  -webkit-text-size-adjust: none;
}

.hljs-comment,
.diff .hljs-header {
  color: #998;
  font-style: italic;
}

.hljs-keyword,
.css .rule .hljs-keyword,
.hljs-winutils,
.nginx .hljs-title,
.hljs-subst,
.hljs-request,
.hljs-status {
  color: #333;
  font-weight: bold;
}

.hljs-number,
.hljs-hexcolor,
.ruby .hljs-constant {
  color: #008080;
}

.hljs-string,
.hljs-tag .hljs-value,
.hljs-doctag,
.tex .hljs-formula {
  color: #d14;
}

.hljs-title,
.hljs-id,
.scss .hljs-preprocessor {
  color: #900;
  font-weight: bold;
}

.hljs-list .hljs-keyword,
.hljs-subst {
  font-weight: normal;
}

.hljs-class .hljs-title,
.hljs-type,
.vhdl .hljs-literal,
.tex .hljs-command {
  color: #458;
  font-weight: bold;
}

.hljs-tag,
.hljs-tag .hljs-title,
.hljs-rule .hljs-property,
.django .hljs-tag .hljs-keyword {
  color: #000080;
  font-weight: normal;
}

.hljs-attribute,
.hljs-variable,
.lisp .hljs-body,
.hljs-name {
  color: #008080;
}

.hljs-regexp {
  color: #009926;
}

.hljs-symbol,
.ruby .hljs-symbol .hljs-string,
.lisp .hljs-keyword,
.clojure .hljs-keyword,
.scheme .hljs-keyword,
.tex .hljs-special,
.hljs-prompt {
  color: #990073;
}

.hljs-built_in {
  color: #0086b3;
}

.hljs-preprocessor,
.hljs-pragma,
.hljs-pi,
.hljs-doctype,
.hljs-shebang,
.hljs-cdata {
  color: #999;
  font-weight: bold;
}

.hljs-deletion {
  background: #fdd;
}

.hljs-addition {
  background: #dfd;
}

.diff .hljs-change {
  background: #0086b3;
}

.hljs-chunk {
  color: #aaa;
}

nvd3 {
  display: block;
  width: 100%;
  height: 100%;
}
nvd3.remove-x-lines .nv-x .tick line {
  display: none;
}
nvd3.remove-y-lines .nv-y .tick line {
  display: none;
}
nvd3.remove-line-stroke .nv-groups path.nv-line {
  stroke-width: 0 !important;
}
nvd3.remove-opacity .nv-groups .nv-group {
  fill-opacity: 1 !important;
}
nvd3.show-line-points .nv-line .nv-scatter .nv-groups .nv-point {
  fill-opacity: 1 !important;
  stroke-opacity: 1 !important;
}

.nvd3 text {
  font-family: "Roboto", "Helvetica Neue", "Arial", sans-serif;
}
.nvd3 line.nv-guideline {
  stroke: rgba(0, 0, 0, 0.54);
}
.nvd3 .nv-groups .nv-point.hover {
  stroke-width: 3px !important;
  fill-opacity: 1 !important;
  stroke-opacity: 1 !important;
}

.nvtooltip {
  background: none;
  color: white;
  padding: 0;
  border: none;
}
.nvtooltip.gravity-n:after {
  display: block;
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  left: 50%;
  bottom: 100%;
  margin-left: -5px;
  border: 5px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.nvtooltip.gravity-s:after {
  display: block;
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border: 5px solid transparent;
  border-top-color: rgba(0, 0, 0, 0.87);
}
.nvtooltip.gravity-e:after {
  display: block;
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: 50%;
  right: 0;
  margin-top: -6px;
  margin-right: -11px;
  border: 6px solid transparent;
  border-left-color: rgba(0, 0, 0, 0.87);
}
.nvtooltip.gravity-w:after {
  display: block;
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: 50%;
  margin-top: -6px;
  margin-left: -11px;
  border: 6px solid transparent;
  border-right-color: rgba(0, 0, 0, 0.87);
}
.nvtooltip table {
  background: rgba(0, 0, 0, 0.87);
  padding: 8px 12px;
  margin: 0;
  border-radius: 2px;
}
.nvtooltip table tbody tr td.legend-color-guide div {
  border: none;
}
.nvtooltip table tbody tr td:last-child {
  padding-right: 0;
}

.ps-container:hover .ps-in-scrolling {
  pointer-events: auto;
}
.ps-container .ps-in-scrolling {
  pointer-events: auto;
}
.ps-container > .ps-scrollbar-x-rail {
  background: none !important;
  margin: 4px 0;
  height: 4px;
  z-index: 49;
}
.ps-container > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  height: 4px;
}
.ps-container > .ps-scrollbar-y-rail {
  background: none !important;
  margin: 4px 0;
  width: 4px;
  z-index: 49;
}
.ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  width: 4px;
}

.ta-scroll-window.form-control {
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.ta-group {
  margin-bottom: 16px;
}
.ta-group .md-button {
  width: 40px;
  min-width: 40px;
  line-height: 40px;
  height: 40px;
  min-height: 40px;
  padding: 8px;
}

/*----------------------------------------------------------------*/
/*  Helpers
/*----------------------------------------------------------------*/
.no-margin {
  margin-right: 0;
}

.no-radius {
  border-radius: 0;
}

.divider-vertical {
  background: rgba(0, 0, 0, 0.12);
  height: 24px;
  width: 1px;
  margin: 0 8px;
}

.hidden {
  visibility: hidden;
  opacity: 0;
}

[fill-width] {
  min-width: 100%;
}

[fill-height] {
  min-height: 100%;
}

.full-height {
  height: 100% !important;
}

a img {
  display: block;
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
  border: none;
}

.scrollable {
  display: block;
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.scrollable.only-x {
  overflow-x: auto;
  overflow-y: hidden;
}
.scrollable.only-y {
  overflow-x: hidden;
  overflow-y: auto;
}

.flex-scrollable {
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.flex-scrollable.only-x {
  overflow-x: auto;
  overflow-y: hidden;
}
.flex-scrollable.only-y {
  overflow-x: hidden;
  overflow-y: auto;
}

.background-image-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.align-vertical:before {
  display: inline-block;
  height: 100%;
  content: "";
  vertical-align: middle;
}
.align-vertical .align-vertical-top,
.align-vertical .align-vertical-middle,
.align-vertical .align-vertical-bottom {
  display: inline-block;
}
.align-vertical .align-vertical-top {
  vertical-align: top;
}
.align-vertical .align-vertical-middle {
  vertical-align: middle;
}
.align-vertical .align-vertical-bottom {
  vertical-align: bottom;
}

.padding-0,
.p-0 {
  padding: 0px;
}

.padding-top-0,
.pt-0 {
  padding-top: 0px;
}

.padding-right-0,
.pr-0 {
  padding-right: 0px;
}

.padding-bottom-0,
.pb-0 {
  padding-bottom: 0px;
}

.padding-left-0,
.pl-0 {
  padding-left: 0px;
}

.padding-horizontal-0,
.ph-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.padding-vertical-0,
.pv-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.padding-5,
.p-5 {
  padding: 5px;
}

.padding-top-5,
.pt-5 {
  padding-top: 5px;
}

.padding-right-5,
.pr-5 {
  padding-right: 5px;
}

.padding-bottom-5,
.pb-5 {
  padding-bottom: 5px;
}

.padding-left-5,
.pl-5 {
  padding-left: 5px;
}

.padding-horizontal-5,
.ph-5 {
  padding-left: 5px;
  padding-right: 5px;
}

.padding-vertical-5,
.pv-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.padding-10,
.p-10 {
  padding: 10px;
}

.padding-top-10,
.pt-10 {
  padding-top: 10px;
}

.padding-right-10,
.pr-10 {
  padding-right: 10px;
}

.padding-bottom-10,
.pb-10 {
  padding-bottom: 10px;
}

.padding-left-10,
.pl-10 {
  padding-left: 10px;
}

.padding-horizontal-10,
.ph-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.padding-vertical-10,
.pv-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.padding-15,
.p-15 {
  padding: 15px;
}

.padding-top-15,
.pt-15 {
  padding-top: 15px;
}

.padding-right-15,
.pr-15 {
  padding-right: 15px;
}

.padding-bottom-15,
.pb-15 {
  padding-bottom: 15px;
}

.padding-left-15,
.pl-15 {
  padding-left: 15px;
}

.padding-horizontal-15,
.ph-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.padding-vertical-15,
.pv-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.padding-20,
.p-20 {
  padding: 20px;
}

.padding-top-20,
.pt-20 {
  padding-top: 20px;
}

.padding-right-20,
.pr-20 {
  padding-right: 20px;
}

.padding-bottom-20,
.pb-20 {
  padding-bottom: 20px;
}

.padding-left-20,
.pl-20 {
  padding-left: 20px;
}

.padding-horizontal-20,
.ph-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.padding-vertical-20,
.pv-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.padding-25,
.p-25 {
  padding: 25px;
}

.padding-top-25,
.pt-25 {
  padding-top: 25px;
}

.padding-right-25,
.pr-25 {
  padding-right: 25px;
}

.padding-bottom-25,
.pb-25 {
  padding-bottom: 25px;
}

.padding-left-25,
.pl-25 {
  padding-left: 25px;
}

.padding-horizontal-25,
.ph-25 {
  padding-left: 25px;
  padding-right: 25px;
}

.padding-vertical-25,
.pv-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.padding-30,
.p-30 {
  padding: 30px;
}

.padding-top-30,
.pt-30 {
  padding-top: 30px;
}

.padding-right-30,
.pr-30 {
  padding-right: 30px;
}

.padding-bottom-30,
.pb-30 {
  padding-bottom: 30px;
}

.padding-left-30,
.pl-30 {
  padding-left: 30px;
}

.padding-horizontal-30,
.ph-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.padding-vertical-30,
.pv-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.padding-35,
.p-35 {
  padding: 35px;
}

.padding-top-35,
.pt-35 {
  padding-top: 35px;
}

.padding-right-35,
.pr-35 {
  padding-right: 35px;
}

.padding-bottom-35,
.pb-35 {
  padding-bottom: 35px;
}

.padding-left-35,
.pl-35 {
  padding-left: 35px;
}

.padding-horizontal-35,
.ph-35 {
  padding-left: 35px;
  padding-right: 35px;
}

.padding-vertical-35,
.pv-35 {
  padding-top: 35px;
  padding-bottom: 35px;
}

.padding-40,
.p-40 {
  padding: 40px;
}

.padding-top-40,
.pt-40 {
  padding-top: 40px;
}

.padding-right-40,
.pr-40 {
  padding-right: 40px;
}

.padding-bottom-40,
.pb-40 {
  padding-bottom: 40px;
}

.padding-left-40,
.pl-40 {
  padding-left: 40px;
}

.padding-horizontal-40,
.ph-40 {
  padding-left: 40px;
  padding-right: 40px;
}

.padding-vertical-40,
.pv-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.padding-45,
.p-45 {
  padding: 45px;
}

.padding-top-45,
.pt-45 {
  padding-top: 45px;
}

.padding-right-45,
.pr-45 {
  padding-right: 45px;
}

.padding-bottom-45,
.pb-45 {
  padding-bottom: 45px;
}

.padding-left-45,
.pl-45 {
  padding-left: 45px;
}

.padding-horizontal-45,
.ph-45 {
  padding-left: 45px;
  padding-right: 45px;
}

.padding-vertical-45,
.pv-45 {
  padding-top: 45px;
  padding-bottom: 45px;
}

.padding-50,
.p-50 {
  padding: 50px;
}

.padding-top-50,
.pt-50 {
  padding-top: 50px;
}

.padding-right-50,
.pr-50 {
  padding-right: 50px;
}

.padding-bottom-50,
.pb-50 {
  padding-bottom: 50px;
}

.padding-left-50,
.pl-50 {
  padding-left: 50px;
}

.padding-horizontal-50,
.ph-50 {
  padding-left: 50px;
  padding-right: 50px;
}

.padding-vertical-50,
.pv-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.padding-55,
.p-55 {
  padding: 55px;
}

.padding-top-55,
.pt-55 {
  padding-top: 55px;
}

.padding-right-55,
.pr-55 {
  padding-right: 55px;
}

.padding-bottom-55,
.pb-55 {
  padding-bottom: 55px;
}

.padding-left-55,
.pl-55 {
  padding-left: 55px;
}

.padding-horizontal-55,
.ph-55 {
  padding-left: 55px;
  padding-right: 55px;
}

.padding-vertical-55,
.pv-55 {
  padding-top: 55px;
  padding-bottom: 55px;
}

.padding-60,
.p-60 {
  padding: 60px;
}

.padding-top-60,
.pt-60 {
  padding-top: 60px;
}

.padding-right-60,
.pr-60 {
  padding-right: 60px;
}

.padding-bottom-60,
.pb-60 {
  padding-bottom: 60px;
}

.padding-left-60,
.pl-60 {
  padding-left: 60px;
}

.padding-horizontal-60,
.ph-60 {
  padding-left: 60px;
  padding-right: 60px;
}

.padding-vertical-60,
.pv-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.padding-65,
.p-65 {
  padding: 65px;
}

.padding-top-65,
.pt-65 {
  padding-top: 65px;
}

.padding-right-65,
.pr-65 {
  padding-right: 65px;
}

.padding-bottom-65,
.pb-65 {
  padding-bottom: 65px;
}

.padding-left-65,
.pl-65 {
  padding-left: 65px;
}

.padding-horizontal-65,
.ph-65 {
  padding-left: 65px;
  padding-right: 65px;
}

.padding-vertical-65,
.pv-65 {
  padding-top: 65px;
  padding-bottom: 65px;
}

.padding-70,
.p-70 {
  padding: 70px;
}

.padding-top-70,
.pt-70 {
  padding-top: 70px;
}

.padding-right-70,
.pr-70 {
  padding-right: 70px;
}

.padding-bottom-70,
.pb-70 {
  padding-bottom: 70px;
}

.padding-left-70,
.pl-70 {
  padding-left: 70px;
}

.padding-horizontal-70,
.ph-70 {
  padding-left: 70px;
  padding-right: 70px;
}

.padding-vertical-70,
.pv-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.padding-75,
.p-75 {
  padding: 75px;
}

.padding-top-75,
.pt-75 {
  padding-top: 75px;
}

.padding-right-75,
.pr-75 {
  padding-right: 75px;
}

.padding-bottom-75,
.pb-75 {
  padding-bottom: 75px;
}

.padding-left-75,
.pl-75 {
  padding-left: 75px;
}

.padding-horizontal-75,
.ph-75 {
  padding-left: 75px;
  padding-right: 75px;
}

.padding-vertical-75,
.pv-75 {
  padding-top: 75px;
  padding-bottom: 75px;
}

.padding-80,
.p-80 {
  padding: 80px;
}

.padding-top-80,
.pt-80 {
  padding-top: 80px;
}

.padding-right-80,
.pr-80 {
  padding-right: 80px;
}

.padding-bottom-80,
.pb-80 {
  padding-bottom: 80px;
}

.padding-left-80,
.pl-80 {
  padding-left: 80px;
}

.padding-horizontal-80,
.ph-80 {
  padding-left: 80px;
  padding-right: 80px;
}

.padding-vertical-80,
.pv-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.padding-85,
.p-85 {
  padding: 85px;
}

.padding-top-85,
.pt-85 {
  padding-top: 85px;
}

.padding-right-85,
.pr-85 {
  padding-right: 85px;
}

.padding-bottom-85,
.pb-85 {
  padding-bottom: 85px;
}

.padding-left-85,
.pl-85 {
  padding-left: 85px;
}

.padding-horizontal-85,
.ph-85 {
  padding-left: 85px;
  padding-right: 85px;
}

.padding-vertical-85,
.pv-85 {
  padding-top: 85px;
  padding-bottom: 85px;
}

.padding-90,
.p-90 {
  padding: 90px;
}

.padding-top-90,
.pt-90 {
  padding-top: 90px;
}

.padding-right-90,
.pr-90 {
  padding-right: 90px;
}

.padding-bottom-90,
.pb-90 {
  padding-bottom: 90px;
}

.padding-left-90,
.pl-90 {
  padding-left: 90px;
}

.padding-horizontal-90,
.ph-90 {
  padding-left: 90px;
  padding-right: 90px;
}

.padding-vertical-90,
.pv-90 {
  padding-top: 90px;
  padding-bottom: 90px;
}

.padding-95,
.p-95 {
  padding: 95px;
}

.padding-top-95,
.pt-95 {
  padding-top: 95px;
}

.padding-right-95,
.pr-95 {
  padding-right: 95px;
}

.padding-bottom-95,
.pb-95 {
  padding-bottom: 95px;
}

.padding-left-95,
.pl-95 {
  padding-left: 95px;
}

.padding-horizontal-95,
.ph-95 {
  padding-left: 95px;
  padding-right: 95px;
}

.padding-vertical-95,
.pv-95 {
  padding-top: 95px;
  padding-bottom: 95px;
}

.padding-100,
.p-100 {
  padding: 100px;
}

.padding-top-100,
.pt-100 {
  padding-top: 100px;
}

.padding-right-100,
.pr-100 {
  padding-right: 100px;
}

.padding-bottom-100,
.pb-100 {
  padding-bottom: 100px;
}

.padding-left-100,
.pl-100 {
  padding-left: 100px;
}

.padding-horizontal-100,
.ph-100 {
  padding-left: 100px;
  padding-right: 100px;
}

.padding-vertical-100,
.pv-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.padding-8,
.p-8 {
  padding: 8px;
}

.padding-top-8,
.pt-8 {
  padding-top: 8px;
}

.padding-right-8,
.pr-8 {
  padding-right: 8px;
}

.padding-bottom-8,
.pb-8 {
  padding-bottom: 8px;
}

.padding-left-8,
.pl-8 {
  padding-left: 8px;
}

.padding-horizontal-8,
.ph-8 {
  padding-left: 8px;
  padding-right: 8px;
}

.padding-vertical-8,
.pv-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.padding-16,
.p-16 {
  padding: 16px;
}

.padding-top-16,
.pt-16 {
  padding-top: 16px;
}

.padding-right-16,
.pr-16 {
  padding-right: 16px;
}

.padding-bottom-16,
.pb-16 {
  padding-bottom: 16px;
}

.padding-left-16,
.pl-16 {
  padding-left: 16px;
}

.padding-horizontal-16,
.ph-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.padding-vertical-16,
.pv-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.padding-24,
.p-24 {
  padding: 24px;
}

.padding-top-24,
.pt-24 {
  padding-top: 24px;
}

.padding-right-24,
.pr-24 {
  padding-right: 24px;
}

.padding-bottom-24,
.pb-24 {
  padding-bottom: 24px;
}

.padding-left-24,
.pl-24 {
  padding-left: 24px;
}

.padding-horizontal-24,
.ph-24 {
  padding-left: 24px;
  padding-right: 24px;
}

.padding-vertical-24,
.pv-24 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.padding-32,
.p-32 {
  padding: 32px;
}

.padding-top-32,
.pt-32 {
  padding-top: 32px;
}

.padding-right-32,
.pr-32 {
  padding-right: 32px;
}

.padding-bottom-32,
.pb-32 {
  padding-bottom: 32px;
}

.padding-left-32,
.pl-32 {
  padding-left: 32px;
}

.padding-horizontal-32,
.ph-32 {
  padding-left: 32px;
  padding-right: 32px;
}

.padding-vertical-32,
.pv-32 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.padding-48,
.p-48 {
  padding: 48px;
}

.padding-top-48,
.pt-48 {
  padding-top: 48px;
}

.padding-right-48,
.pr-48 {
  padding-right: 48px;
}

.padding-bottom-48,
.pb-48 {
  padding-bottom: 48px;
}

.padding-left-48,
.pl-48 {
  padding-left: 48px;
}

.padding-horizontal-48,
.ph-48 {
  padding-left: 48px;
  padding-right: 48px;
}

.padding-vertical-48,
.pv-48 {
  padding-top: 48px;
  padding-bottom: 48px;
}

.padding-56,
.p-56 {
  padding: 56px;
}

.padding-top-56,
.pt-56 {
  padding-top: 56px;
}

.padding-right-56,
.pr-56 {
  padding-right: 56px;
}

.padding-bottom-56,
.pb-56 {
  padding-bottom: 56px;
}

.padding-left-56,
.pl-56 {
  padding-left: 56px;
}

.padding-horizontal-56,
.ph-56 {
  padding-left: 56px;
  padding-right: 56px;
}

.padding-vertical-56,
.pv-56 {
  padding-top: 56px;
  padding-bottom: 56px;
}

.padding-64,
.p-64 {
  padding: 64px;
}

.padding-top-64,
.pt-64 {
  padding-top: 64px;
}

.padding-right-64,
.pr-64 {
  padding-right: 64px;
}

.padding-bottom-64,
.pb-64 {
  padding-bottom: 64px;
}

.padding-left-64,
.pl-64 {
  padding-left: 64px;
}

.padding-horizontal-64,
.ph-64 {
  padding-left: 64px;
  padding-right: 64px;
}

.padding-vertical-64,
.pv-64 {
  padding-top: 64px;
  padding-bottom: 64px;
}

.padding-72,
.p-72 {
  padding: 72px;
}

.padding-top-72,
.pt-72 {
  padding-top: 72px;
}

.padding-right-72,
.pr-72 {
  padding-right: 72px;
}

.padding-bottom-72,
.pb-72 {
  padding-bottom: 72px;
}

.padding-left-72,
.pl-72 {
  padding-left: 72px;
}

.padding-horizontal-72,
.ph-72 {
  padding-left: 72px;
  padding-right: 72px;
}

.padding-vertical-72,
.pv-72 {
  padding-top: 72px;
  padding-bottom: 72px;
}

.padding-88,
.p-88 {
  padding: 88px;
}

.padding-top-88,
.pt-88 {
  padding-top: 88px;
}

.padding-right-88,
.pr-88 {
  padding-right: 88px;
}

.padding-bottom-88,
.pb-88 {
  padding-bottom: 88px;
}

.padding-left-88,
.pl-88 {
  padding-left: 88px;
}

.padding-horizontal-88,
.ph-88 {
  padding-left: 88px;
  padding-right: 88px;
}

.padding-vertical-88,
.pv-88 {
  padding-top: 88px;
  padding-bottom: 88px;
}

.padding-96,
.p-96 {
  padding: 96px;
}

.padding-top-96,
.pt-96 {
  padding-top: 96px;
}

.padding-right-96,
.pr-96 {
  padding-right: 96px;
}

.padding-bottom-96,
.pb-96 {
  padding-bottom: 96px;
}

.padding-left-96,
.pl-96 {
  padding-left: 96px;
}

.padding-horizontal-96,
.ph-96 {
  padding-left: 96px;
  padding-right: 96px;
}

.padding-vertical-96,
.pv-96 {
  padding-top: 96px;
  padding-bottom: 96px;
}

.margin-0,
.m-0 {
  margin: 0px;
}

.margin-top-0,
.mt-0 {
  margin-top: 0px;
}

.margin-right-0,
.mr-0 {
  margin-right: 0px;
}

.margin-bottom-0,
.mb-0 {
  margin-bottom: 0px;
}

.margin-left-0,
.ml-0 {
  margin-left: 0px;
}

.margin-horizontal-0,
.mh-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.margin-vertical-0,
.mv-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.margin-5,
.m-5 {
  margin: 5px;
}

.margin-top-5,
.mt-5 {
  margin-top: 5px;
}

.margin-right-5,
.mr-5 {
  margin-right: 5px;
}

.margin-bottom-5,
.mb-5 {
  margin-bottom: 5px;
}

.margin-left-5,
.ml-5 {
  margin-left: 5px;
}

.margin-horizontal-5,
.mh-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.margin-vertical-5,
.mv-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.margin-10,
.m-10 {
  margin: 10px;
}

.margin-top-10,
.mt-10 {
  margin-top: 10px;
}

.margin-right-10,
.mr-10 {
  margin-right: 10px;
}

.margin-bottom-10,
.mb-10 {
  margin-bottom: 10px;
}

.margin-left-10,
.ml-10 {
  margin-left: 10px;
}

.margin-horizontal-10,
.mh-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.margin-vertical-10,
.mv-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.margin-15,
.m-15 {
  margin: 15px;
}

.margin-top-15,
.mt-15 {
  margin-top: 15px;
}

.margin-right-15,
.mr-15 {
  margin-right: 15px;
}

.margin-bottom-15,
.mb-15 {
  margin-bottom: 15px;
}

.margin-left-15,
.ml-15 {
  margin-left: 15px;
}

.margin-horizontal-15,
.mh-15 {
  margin-left: 15px;
  margin-right: 15px;
}

.margin-vertical-15,
.mv-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.margin-20,
.m-20 {
  margin: 20px;
}

.margin-top-20,
.mt-20 {
  margin-top: 20px;
}

.margin-right-20,
.mr-20 {
  margin-right: 20px;
}

.margin-bottom-20,
.mb-20 {
  margin-bottom: 20px;
}

.margin-left-20,
.ml-20 {
  margin-left: 20px;
}

.margin-horizontal-20,
.mh-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.margin-vertical-20,
.mv-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.margin-25,
.m-25 {
  margin: 25px;
}

.margin-top-25,
.mt-25 {
  margin-top: 25px;
}

.margin-right-25,
.mr-25 {
  margin-right: 25px;
}

.margin-bottom-25,
.mb-25 {
  margin-bottom: 25px;
}

.margin-left-25,
.ml-25 {
  margin-left: 25px;
}

.margin-horizontal-25,
.mh-25 {
  margin-left: 25px;
  margin-right: 25px;
}

.margin-vertical-25,
.mv-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.margin-30,
.m-30 {
  margin: 30px;
}

.margin-top-30,
.mt-30 {
  margin-top: 30px;
}

.margin-right-30,
.mr-30 {
  margin-right: 30px;
}

.margin-bottom-30,
.mb-30 {
  margin-bottom: 30px;
}

.margin-left-30,
.ml-30 {
  margin-left: 30px;
}

.margin-horizontal-30,
.mh-30 {
  margin-left: 30px;
  margin-right: 30px;
}

.margin-vertical-30,
.mv-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.margin-35,
.m-35 {
  margin: 35px;
}

.margin-top-35,
.mt-35 {
  margin-top: 35px;
}

.margin-right-35,
.mr-35 {
  margin-right: 35px;
}

.margin-bottom-35,
.mb-35 {
  margin-bottom: 35px;
}

.margin-left-35,
.ml-35 {
  margin-left: 35px;
}

.margin-horizontal-35,
.mh-35 {
  margin-left: 35px;
  margin-right: 35px;
}

.margin-vertical-35,
.mv-35 {
  margin-top: 35px;
  margin-bottom: 35px;
}

.margin-40,
.m-40 {
  margin: 40px;
}

.margin-top-40,
.mt-40 {
  margin-top: 40px;
}

.margin-right-40,
.mr-40 {
  margin-right: 40px;
}

.margin-bottom-40,
.mb-40 {
  margin-bottom: 40px;
}

.margin-left-40,
.ml-40 {
  margin-left: 40px;
}

.margin-horizontal-40,
.mh-40 {
  margin-left: 40px;
  margin-right: 40px;
}

.margin-vertical-40,
.mv-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.margin-45,
.m-45 {
  margin: 45px;
}

.margin-top-45,
.mt-45 {
  margin-top: 45px;
}

.margin-right-45,
.mr-45 {
  margin-right: 45px;
}

.margin-bottom-45,
.mb-45 {
  margin-bottom: 45px;
}

.margin-left-45,
.ml-45 {
  margin-left: 45px;
}

.margin-horizontal-45,
.mh-45 {
  margin-left: 45px;
  margin-right: 45px;
}

.margin-vertical-45,
.mv-45 {
  margin-top: 45px;
  margin-bottom: 45px;
}

.margin-50,
.m-50 {
  margin: 50px;
}

.margin-top-50,
.mt-50 {
  margin-top: 50px;
}

.margin-right-50,
.mr-50 {
  margin-right: 50px;
}

.margin-bottom-50,
.mb-50 {
  margin-bottom: 50px;
}

.margin-left-50,
.ml-50 {
  margin-left: 50px;
}

.margin-horizontal-50,
.mh-50 {
  margin-left: 50px;
  margin-right: 50px;
}

.margin-vertical-50,
.mv-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.margin-55,
.m-55 {
  margin: 55px;
}

.margin-top-55,
.mt-55 {
  margin-top: 55px;
}

.margin-right-55,
.mr-55 {
  margin-right: 55px;
}

.margin-bottom-55,
.mb-55 {
  margin-bottom: 55px;
}

.margin-left-55,
.ml-55 {
  margin-left: 55px;
}

.margin-horizontal-55,
.mh-55 {
  margin-left: 55px;
  margin-right: 55px;
}

.margin-vertical-55,
.mv-55 {
  margin-top: 55px;
  margin-bottom: 55px;
}

.margin-60,
.m-60 {
  margin: 60px;
}

.margin-top-60,
.mt-60 {
  margin-top: 60px;
}

.margin-right-60,
.mr-60 {
  margin-right: 60px;
}

.margin-bottom-60,
.mb-60 {
  margin-bottom: 60px;
}

.margin-left-60,
.ml-60 {
  margin-left: 60px;
}

.margin-horizontal-60,
.mh-60 {
  margin-left: 60px;
  margin-right: 60px;
}

.margin-vertical-60,
.mv-60 {
  margin-top: 60px;
  margin-bottom: 60px;
}

.margin-65,
.m-65 {
  margin: 65px;
}

.margin-top-65,
.mt-65 {
  margin-top: 65px;
}

.margin-right-65,
.mr-65 {
  margin-right: 65px;
}

.margin-bottom-65,
.mb-65 {
  margin-bottom: 65px;
}

.margin-left-65,
.ml-65 {
  margin-left: 65px;
}

.margin-horizontal-65,
.mh-65 {
  margin-left: 65px;
  margin-right: 65px;
}

.margin-vertical-65,
.mv-65 {
  margin-top: 65px;
  margin-bottom: 65px;
}

.margin-70,
.m-70 {
  margin: 70px;
}

.margin-top-70,
.mt-70 {
  margin-top: 70px;
}

.margin-right-70,
.mr-70 {
  margin-right: 70px;
}

.margin-bottom-70,
.mb-70 {
  margin-bottom: 70px;
}

.margin-left-70,
.ml-70 {
  margin-left: 70px;
}

.margin-horizontal-70,
.mh-70 {
  margin-left: 70px;
  margin-right: 70px;
}

.margin-vertical-70,
.mv-70 {
  margin-top: 70px;
  margin-bottom: 70px;
}

.margin-75,
.m-75 {
  margin: 75px;
}

.margin-top-75,
.mt-75 {
  margin-top: 75px;
}

.margin-right-75,
.mr-75 {
  margin-right: 75px;
}

.margin-bottom-75,
.mb-75 {
  margin-bottom: 75px;
}

.margin-left-75,
.ml-75 {
  margin-left: 75px;
}

.margin-horizontal-75,
.mh-75 {
  margin-left: 75px;
  margin-right: 75px;
}

.margin-vertical-75,
.mv-75 {
  margin-top: 75px;
  margin-bottom: 75px;
}

.margin-80,
.m-80 {
  margin: 80px;
}

.margin-top-80,
.mt-80 {
  margin-top: 80px;
}

.margin-right-80,
.mr-80 {
  margin-right: 80px;
}

.margin-bottom-80,
.mb-80 {
  margin-bottom: 80px;
}

.margin-left-80,
.ml-80 {
  margin-left: 80px;
}

.margin-horizontal-80,
.mh-80 {
  margin-left: 80px;
  margin-right: 80px;
}

.margin-vertical-80,
.mv-80 {
  margin-top: 80px;
  margin-bottom: 80px;
}

.margin-85,
.m-85 {
  margin: 85px;
}

.margin-top-85,
.mt-85 {
  margin-top: 85px;
}

.margin-right-85,
.mr-85 {
  margin-right: 85px;
}

.margin-bottom-85,
.mb-85 {
  margin-bottom: 85px;
}

.margin-left-85,
.ml-85 {
  margin-left: 85px;
}

.margin-horizontal-85,
.mh-85 {
  margin-left: 85px;
  margin-right: 85px;
}

.margin-vertical-85,
.mv-85 {
  margin-top: 85px;
  margin-bottom: 85px;
}

.margin-90,
.m-90 {
  margin: 90px;
}

.margin-top-90,
.mt-90 {
  margin-top: 90px;
}

.margin-right-90,
.mr-90 {
  margin-right: 90px;
}

.margin-bottom-90,
.mb-90 {
  margin-bottom: 90px;
}

.margin-left-90,
.ml-90 {
  margin-left: 90px;
}

.margin-horizontal-90,
.mh-90 {
  margin-left: 90px;
  margin-right: 90px;
}

.margin-vertical-90,
.mv-90 {
  margin-top: 90px;
  margin-bottom: 90px;
}

.margin-95,
.m-95 {
  margin: 95px;
}

.margin-top-95,
.mt-95 {
  margin-top: 95px;
}

.margin-right-95,
.mr-95 {
  margin-right: 95px;
}

.margin-bottom-95,
.mb-95 {
  margin-bottom: 95px;
}

.margin-left-95,
.ml-95 {
  margin-left: 95px;
}

.margin-horizontal-95,
.mh-95 {
  margin-left: 95px;
  margin-right: 95px;
}

.margin-vertical-95,
.mv-95 {
  margin-top: 95px;
  margin-bottom: 95px;
}

.margin-100,
.m-100 {
  margin: 100px;
}

.margin-top-100,
.mt-100 {
  margin-top: 100px;
}

.margin-right-100,
.mr-100 {
  margin-right: 100px;
}

.margin-bottom-100,
.mb-100 {
  margin-bottom: 100px;
}

.margin-left-100,
.ml-100 {
  margin-left: 100px;
}

.margin-horizontal-100,
.mh-100 {
  margin-left: 100px;
  margin-right: 100px;
}

.margin-vertical-100,
.mv-100 {
  margin-top: 100px;
  margin-bottom: 100px;
}

.margin-8,
.m-8 {
  margin: 8px;
}

.margin-top-8,
.mt-8 {
  margin-top: 8px;
}

.margin-right-8,
.mr-8 {
  margin-right: 8px;
}

.margin-bottom-8,
.mb-8 {
  margin-bottom: 8px;
}

.margin-left-8,
.ml-8 {
  margin-left: 8px;
}

.margin-horizontal-8,
.mh-8 {
  margin-left: 8px;
  margin-right: 8px;
}

.margin-vertical-8,
.mv-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.margin-16,
.m-16 {
  margin: 16px;
}

.margin-top-16,
.mt-16 {
  margin-top: 16px;
}

.margin-right-16,
.mr-16 {
  margin-right: 16px;
}

.margin-bottom-16,
.mb-16 {
  margin-bottom: 16px;
}

.margin-left-16,
.ml-16 {
  margin-left: 16px;
}

.margin-horizontal-16,
.mh-16 {
  margin-left: 16px;
  margin-right: 16px;
}

.margin-vertical-16,
.mv-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.margin-24,
.m-24 {
  margin: 24px;
}

.margin-top-24,
.mt-24 {
  margin-top: 24px;
}

.margin-right-24,
.mr-24 {
  margin-right: 24px;
}

.margin-bottom-24,
.mb-24 {
  margin-bottom: 24px;
}

.margin-left-24,
.ml-24 {
  margin-left: 24px;
}

.margin-horizontal-24,
.mh-24 {
  margin-left: 24px;
  margin-right: 24px;
}

.margin-vertical-24,
.mv-24 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.margin-32,
.m-32 {
  margin: 32px;
}

.margin-top-32,
.mt-32 {
  margin-top: 32px;
}

.margin-right-32,
.mr-32 {
  margin-right: 32px;
}

.margin-bottom-32,
.mb-32 {
  margin-bottom: 32px;
}

.margin-left-32,
.ml-32 {
  margin-left: 32px;
}

.margin-horizontal-32,
.mh-32 {
  margin-left: 32px;
  margin-right: 32px;
}

.margin-vertical-32,
.mv-32 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.margin-48,
.m-48 {
  margin: 48px;
}

.margin-top-48,
.mt-48 {
  margin-top: 48px;
}

.margin-right-48,
.mr-48 {
  margin-right: 48px;
}

.margin-bottom-48,
.mb-48 {
  margin-bottom: 48px;
}

.margin-left-48,
.ml-48 {
  margin-left: 48px;
}

.margin-horizontal-48,
.mh-48 {
  margin-left: 48px;
  margin-right: 48px;
}

.margin-vertical-48,
.mv-48 {
  margin-top: 48px;
  margin-bottom: 48px;
}

.margin-56,
.m-56 {
  margin: 56px;
}

.margin-top-56,
.mt-56 {
  margin-top: 56px;
}

.margin-right-56,
.mr-56 {
  margin-right: 56px;
}

.margin-bottom-56,
.mb-56 {
  margin-bottom: 56px;
}

.margin-left-56,
.ml-56 {
  margin-left: 56px;
}

.margin-horizontal-56,
.mh-56 {
  margin-left: 56px;
  margin-right: 56px;
}

.margin-vertical-56,
.mv-56 {
  margin-top: 56px;
  margin-bottom: 56px;
}

.margin-64,
.m-64 {
  margin: 64px;
}

.margin-top-64,
.mt-64 {
  margin-top: 64px;
}

.margin-right-64,
.mr-64 {
  margin-right: 64px;
}

.margin-bottom-64,
.mb-64 {
  margin-bottom: 64px;
}

.margin-left-64,
.ml-64 {
  margin-left: 64px;
}

.margin-horizontal-64,
.mh-64 {
  margin-left: 64px;
  margin-right: 64px;
}

.margin-vertical-64,
.mv-64 {
  margin-top: 64px;
  margin-bottom: 64px;
}

.margin-72,
.m-72 {
  margin: 72px;
}

.margin-top-72,
.mt-72 {
  margin-top: 72px;
}

.margin-right-72,
.mr-72 {
  margin-right: 72px;
}

.margin-bottom-72,
.mb-72 {
  margin-bottom: 72px;
}

.margin-left-72,
.ml-72 {
  margin-left: 72px;
}

.margin-horizontal-72,
.mh-72 {
  margin-left: 72px;
  margin-right: 72px;
}

.margin-vertical-72,
.mv-72 {
  margin-top: 72px;
  margin-bottom: 72px;
}

.margin-88,
.m-88 {
  margin: 88px;
}

.margin-top-88,
.mt-88 {
  margin-top: 88px;
}

.margin-right-88,
.mr-88 {
  margin-right: 88px;
}

.margin-bottom-88,
.mb-88 {
  margin-bottom: 88px;
}

.margin-left-88,
.ml-88 {
  margin-left: 88px;
}

.margin-horizontal-88,
.mh-88 {
  margin-left: 88px;
  margin-right: 88px;
}

.margin-vertical-88,
.mv-88 {
  margin-top: 88px;
  margin-bottom: 88px;
}

.margin-96,
.m-96 {
  margin: 96px;
}

.margin-top-96,
.mt-96 {
  margin-top: 96px;
}

.margin-right-96,
.mr-96 {
  margin-right: 96px;
}

.margin-bottom-96,
.mb-96 {
  margin-bottom: 96px;
}

.margin-left-96,
.ml-96 {
  margin-left: 96px;
}

.margin-horizontal-96,
.mh-96 {
  margin-left: 96px;
  margin-right: 96px;
}

.margin-vertical-96,
.mv-96 {
  margin-top: 96px;
  margin-bottom: 96px;
}

.height-0,
.h-0 {
  height: 0px !important;
  min-height: 0px !important;
  max-height: 0px !important;
}

.width-0,
.w-0 {
  width: 0px !important;
  min-width: 0px !important;
  max-width: 0px !important;
}

.height-5,
.h-5 {
  height: 5px !important;
  min-height: 5px !important;
  max-height: 5px !important;
}

.width-5,
.w-5 {
  width: 5px !important;
  min-width: 5px !important;
  max-width: 5px !important;
}

.height-10,
.h-10 {
  height: 10px !important;
  min-height: 10px !important;
  max-height: 10px !important;
}

.width-10,
.w-10 {
  width: 10px !important;
  min-width: 10px !important;
  max-width: 10px !important;
}

.height-15,
.h-15 {
  height: 15px !important;
  min-height: 15px !important;
  max-height: 15px !important;
}

.width-15,
.w-15 {
  width: 15px !important;
  min-width: 15px !important;
  max-width: 15px !important;
}

.height-20,
.h-20 {
  height: 20px !important;
  min-height: 20px !important;
  max-height: 20px !important;
}

.width-20,
.w-20 {
  width: 20px !important;
  min-width: 20px !important;
  max-width: 20px !important;
}

.height-25,
.h-25 {
  height: 25px !important;
  min-height: 25px !important;
  max-height: 25px !important;
}

.width-25,
.w-25 {
  width: 25px !important;
  min-width: 25px !important;
  max-width: 25px !important;
}

.height-30,
.h-30 {
  height: 30px !important;
  min-height: 30px !important;
  max-height: 30px !important;
}

.width-30,
.w-30 {
  width: 30px !important;
  min-width: 30px !important;
  max-width: 30px !important;
}

.height-35,
.h-35 {
  height: 35px !important;
  min-height: 35px !important;
  max-height: 35px !important;
}

.width-35,
.w-35 {
  width: 35px !important;
  min-width: 35px !important;
  max-width: 35px !important;
}

.height-40,
.h-40 {
  height: 40px !important;
  min-height: 40px !important;
  max-height: 40px !important;
}

.width-40,
.w-40 {
  width: 40px !important;
  min-width: 40px !important;
  max-width: 40px !important;
}

.height-45,
.h-45 {
  height: 45px !important;
  min-height: 45px !important;
  max-height: 45px !important;
}

.width-45,
.w-45 {
  width: 45px !important;
  min-width: 45px !important;
  max-width: 45px !important;
}

.height-50,
.h-50 {
  height: 50px !important;
  min-height: 50px !important;
  max-height: 50px !important;
}

.width-50,
.w-50 {
  width: 50px !important;
  min-width: 50px !important;
  max-width: 50px !important;
}

.height-55,
.h-55 {
  height: 55px !important;
  min-height: 55px !important;
  max-height: 55px !important;
}

.width-55,
.w-55 {
  width: 55px !important;
  min-width: 55px !important;
  max-width: 55px !important;
}

.height-60,
.h-60 {
  height: 60px !important;
  min-height: 60px !important;
  max-height: 60px !important;
}

.width-60,
.w-60 {
  width: 60px !important;
  min-width: 60px !important;
  max-width: 60px !important;
}

.height-65,
.h-65 {
  height: 65px !important;
  min-height: 65px !important;
  max-height: 65px !important;
}

.width-65,
.w-65 {
  width: 65px !important;
  min-width: 65px !important;
  max-width: 65px !important;
}

.height-70,
.h-70 {
  height: 70px !important;
  min-height: 70px !important;
  max-height: 70px !important;
}

.width-70,
.w-70 {
  width: 70px !important;
  min-width: 70px !important;
  max-width: 70px !important;
}

.height-75,
.h-75 {
  height: 75px !important;
  min-height: 75px !important;
  max-height: 75px !important;
}

.width-75,
.w-75 {
  width: 75px !important;
  min-width: 75px !important;
  max-width: 75px !important;
}

.height-80,
.h-80 {
  height: 80px !important;
  min-height: 80px !important;
  max-height: 80px !important;
}

.width-80,
.w-80 {
  width: 80px !important;
  min-width: 80px !important;
  max-width: 80px !important;
}

.height-85,
.h-85 {
  height: 85px !important;
  min-height: 85px !important;
  max-height: 85px !important;
}

.width-85,
.w-85 {
  width: 85px !important;
  min-width: 85px !important;
  max-width: 85px !important;
}

.height-90,
.h-90 {
  height: 90px !important;
  min-height: 90px !important;
  max-height: 90px !important;
}

.width-90,
.w-90 {
  width: 90px !important;
  min-width: 90px !important;
  max-width: 90px !important;
}

.height-95,
.h-95 {
  height: 95px !important;
  min-height: 95px !important;
  max-height: 95px !important;
}

.width-95,
.w-95 {
  width: 95px !important;
  min-width: 95px !important;
  max-width: 95px !important;
}

.height-100,
.h-100 {
  height: 100px !important;
  min-height: 100px !important;
  max-height: 100px !important;
}

.width-100,
.w-100 {
  width: 100px !important;
  min-width: 100px !important;
  max-width: 100px !important;
}

.height-105,
.h-105 {
  height: 105px !important;
  min-height: 105px !important;
  max-height: 105px !important;
}

.width-105,
.w-105 {
  width: 105px !important;
  min-width: 105px !important;
  max-width: 105px !important;
}

.height-110,
.h-110 {
  height: 110px !important;
  min-height: 110px !important;
  max-height: 110px !important;
}

.width-110,
.w-110 {
  width: 110px !important;
  min-width: 110px !important;
  max-width: 110px !important;
}

.height-115,
.h-115 {
  height: 115px !important;
  min-height: 115px !important;
  max-height: 115px !important;
}

.width-115,
.w-115 {
  width: 115px !important;
  min-width: 115px !important;
  max-width: 115px !important;
}

.height-120,
.h-120 {
  height: 120px !important;
  min-height: 120px !important;
  max-height: 120px !important;
}

.width-120,
.w-120 {
  width: 120px !important;
  min-width: 120px !important;
  max-width: 120px !important;
}

.height-125,
.h-125 {
  height: 125px !important;
  min-height: 125px !important;
  max-height: 125px !important;
}

.width-125,
.w-125 {
  width: 125px !important;
  min-width: 125px !important;
  max-width: 125px !important;
}

.height-130,
.h-130 {
  height: 130px !important;
  min-height: 130px !important;
  max-height: 130px !important;
}

.width-130,
.w-130 {
  width: 130px !important;
  min-width: 130px !important;
  max-width: 130px !important;
}

.height-135,
.h-135 {
  height: 135px !important;
  min-height: 135px !important;
  max-height: 135px !important;
}

.width-135,
.w-135 {
  width: 135px !important;
  min-width: 135px !important;
  max-width: 135px !important;
}

.height-140,
.h-140 {
  height: 140px !important;
  min-height: 140px !important;
  max-height: 140px !important;
}

.width-140,
.w-140 {
  width: 140px !important;
  min-width: 140px !important;
  max-width: 140px !important;
}

.height-145,
.h-145 {
  height: 145px !important;
  min-height: 145px !important;
  max-height: 145px !important;
}

.width-145,
.w-145 {
  width: 145px !important;
  min-width: 145px !important;
  max-width: 145px !important;
}

.height-150,
.h-150 {
  height: 150px !important;
  min-height: 150px !important;
  max-height: 150px !important;
}

.width-150,
.w-150 {
  width: 150px !important;
  min-width: 150px !important;
  max-width: 150px !important;
}

.height-155,
.h-155 {
  height: 155px !important;
  min-height: 155px !important;
  max-height: 155px !important;
}

.width-155,
.w-155 {
  width: 155px !important;
  min-width: 155px !important;
  max-width: 155px !important;
}

.height-160,
.h-160 {
  height: 160px !important;
  min-height: 160px !important;
  max-height: 160px !important;
}

.width-160,
.w-160 {
  width: 160px !important;
  min-width: 160px !important;
  max-width: 160px !important;
}

.height-165,
.h-165 {
  height: 165px !important;
  min-height: 165px !important;
  max-height: 165px !important;
}

.width-165,
.w-165 {
  width: 165px !important;
  min-width: 165px !important;
  max-width: 165px !important;
}

.height-170,
.h-170 {
  height: 170px !important;
  min-height: 170px !important;
  max-height: 170px !important;
}

.width-170,
.w-170 {
  width: 170px !important;
  min-width: 170px !important;
  max-width: 170px !important;
}

.height-175,
.h-175 {
  height: 175px !important;
  min-height: 175px !important;
  max-height: 175px !important;
}

.width-175,
.w-175 {
  width: 175px !important;
  min-width: 175px !important;
  max-width: 175px !important;
}

.height-180,
.h-180 {
  height: 180px !important;
  min-height: 180px !important;
  max-height: 180px !important;
}

.width-180,
.w-180 {
  width: 180px !important;
  min-width: 180px !important;
  max-width: 180px !important;
}

.height-185,
.h-185 {
  height: 185px !important;
  min-height: 185px !important;
  max-height: 185px !important;
}

.width-185,
.w-185 {
  width: 185px !important;
  min-width: 185px !important;
  max-width: 185px !important;
}

.height-190,
.h-190 {
  height: 190px !important;
  min-height: 190px !important;
  max-height: 190px !important;
}

.width-190,
.w-190 {
  width: 190px !important;
  min-width: 190px !important;
  max-width: 190px !important;
}

.height-195,
.h-195 {
  height: 195px !important;
  min-height: 195px !important;
  max-height: 195px !important;
}

.width-195,
.w-195 {
  width: 195px !important;
  min-width: 195px !important;
  max-width: 195px !important;
}

.height-200,
.h-200 {
  height: 200px !important;
  min-height: 200px !important;
  max-height: 200px !important;
}

.width-200,
.w-200 {
  width: 200px !important;
  min-width: 200px !important;
  max-width: 200px !important;
}

.height-205,
.h-205 {
  height: 205px !important;
  min-height: 205px !important;
  max-height: 205px !important;
}

.width-205,
.w-205 {
  width: 205px !important;
  min-width: 205px !important;
  max-width: 205px !important;
}

.height-210,
.h-210 {
  height: 210px !important;
  min-height: 210px !important;
  max-height: 210px !important;
}

.width-210,
.w-210 {
  width: 210px !important;
  min-width: 210px !important;
  max-width: 210px !important;
}

.height-215,
.h-215 {
  height: 215px !important;
  min-height: 215px !important;
  max-height: 215px !important;
}

.width-215,
.w-215 {
  width: 215px !important;
  min-width: 215px !important;
  max-width: 215px !important;
}

.height-220,
.h-220 {
  height: 220px !important;
  min-height: 220px !important;
  max-height: 220px !important;
}

.width-220,
.w-220 {
  width: 220px !important;
  min-width: 220px !important;
  max-width: 220px !important;
}

.height-225,
.h-225 {
  height: 225px !important;
  min-height: 225px !important;
  max-height: 225px !important;
}

.width-225,
.w-225 {
  width: 225px !important;
  min-width: 225px !important;
  max-width: 225px !important;
}

.height-230,
.h-230 {
  height: 230px !important;
  min-height: 230px !important;
  max-height: 230px !important;
}

.width-230,
.w-230 {
  width: 230px !important;
  min-width: 230px !important;
  max-width: 230px !important;
}

.height-235,
.h-235 {
  height: 235px !important;
  min-height: 235px !important;
  max-height: 235px !important;
}

.width-235,
.w-235 {
  width: 235px !important;
  min-width: 235px !important;
  max-width: 235px !important;
}

.height-240,
.h-240 {
  height: 240px !important;
  min-height: 240px !important;
  max-height: 240px !important;
}

.width-240,
.w-240 {
  width: 240px !important;
  min-width: 240px !important;
  max-width: 240px !important;
}

.height-245,
.h-245 {
  height: 245px !important;
  min-height: 245px !important;
  max-height: 245px !important;
}

.width-245,
.w-245 {
  width: 245px !important;
  min-width: 245px !important;
  max-width: 245px !important;
}

.height-250,
.h-250 {
  height: 250px !important;
  min-height: 250px !important;
  max-height: 250px !important;
}

.width-250,
.w-250 {
  width: 250px !important;
  min-width: 250px !important;
  max-width: 250px !important;
}

.height-255,
.h-255 {
  height: 255px !important;
  min-height: 255px !important;
  max-height: 255px !important;
}

.width-255,
.w-255 {
  width: 255px !important;
  min-width: 255px !important;
  max-width: 255px !important;
}

.height-260,
.h-260 {
  height: 260px !important;
  min-height: 260px !important;
  max-height: 260px !important;
}

.width-260,
.w-260 {
  width: 260px !important;
  min-width: 260px !important;
  max-width: 260px !important;
}

.height-265,
.h-265 {
  height: 265px !important;
  min-height: 265px !important;
  max-height: 265px !important;
}

.width-265,
.w-265 {
  width: 265px !important;
  min-width: 265px !important;
  max-width: 265px !important;
}

.height-270,
.h-270 {
  height: 270px !important;
  min-height: 270px !important;
  max-height: 270px !important;
}

.width-270,
.w-270 {
  width: 270px !important;
  min-width: 270px !important;
  max-width: 270px !important;
}

.height-275,
.h-275 {
  height: 275px !important;
  min-height: 275px !important;
  max-height: 275px !important;
}

.width-275,
.w-275 {
  width: 275px !important;
  min-width: 275px !important;
  max-width: 275px !important;
}

.height-280,
.h-280 {
  height: 280px !important;
  min-height: 280px !important;
  max-height: 280px !important;
}

.width-280,
.w-280 {
  width: 280px !important;
  min-width: 280px !important;
  max-width: 280px !important;
}

.height-285,
.h-285 {
  height: 285px !important;
  min-height: 285px !important;
  max-height: 285px !important;
}

.width-285,
.w-285 {
  width: 285px !important;
  min-width: 285px !important;
  max-width: 285px !important;
}

.height-290,
.h-290 {
  height: 290px !important;
  min-height: 290px !important;
  max-height: 290px !important;
}

.width-290,
.w-290 {
  width: 290px !important;
  min-width: 290px !important;
  max-width: 290px !important;
}

.height-295,
.h-295 {
  height: 295px !important;
  min-height: 295px !important;
  max-height: 295px !important;
}

.width-295,
.w-295 {
  width: 295px !important;
  min-width: 295px !important;
  max-width: 295px !important;
}

.height-300,
.h-300 {
  height: 300px !important;
  min-height: 300px !important;
  max-height: 300px !important;
}

.width-300,
.w-300 {
  width: 300px !important;
  min-width: 300px !important;
  max-width: 300px !important;
}

.height-305,
.h-305 {
  height: 305px !important;
  min-height: 305px !important;
  max-height: 305px !important;
}

.width-305,
.w-305 {
  width: 305px !important;
  min-width: 305px !important;
  max-width: 305px !important;
}

.height-310,
.h-310 {
  height: 310px !important;
  min-height: 310px !important;
  max-height: 310px !important;
}

.width-310,
.w-310 {
  width: 310px !important;
  min-width: 310px !important;
  max-width: 310px !important;
}

.height-315,
.h-315 {
  height: 315px !important;
  min-height: 315px !important;
  max-height: 315px !important;
}

.width-315,
.w-315 {
  width: 315px !important;
  min-width: 315px !important;
  max-width: 315px !important;
}

.height-320,
.h-320 {
  height: 320px !important;
  min-height: 320px !important;
  max-height: 320px !important;
}

.width-320,
.w-320 {
  width: 320px !important;
  min-width: 320px !important;
  max-width: 320px !important;
}

.height-325,
.h-325 {
  height: 325px !important;
  min-height: 325px !important;
  max-height: 325px !important;
}

.width-325,
.w-325 {
  width: 325px !important;
  min-width: 325px !important;
  max-width: 325px !important;
}

.height-330,
.h-330 {
  height: 330px !important;
  min-height: 330px !important;
  max-height: 330px !important;
}

.width-330,
.w-330 {
  width: 330px !important;
  min-width: 330px !important;
  max-width: 330px !important;
}

.height-335,
.h-335 {
  height: 335px !important;
  min-height: 335px !important;
  max-height: 335px !important;
}

.width-335,
.w-335 {
  width: 335px !important;
  min-width: 335px !important;
  max-width: 335px !important;
}

.height-340,
.h-340 {
  height: 340px !important;
  min-height: 340px !important;
  max-height: 340px !important;
}

.width-340,
.w-340 {
  width: 340px !important;
  min-width: 340px !important;
  max-width: 340px !important;
}

.height-345,
.h-345 {
  height: 345px !important;
  min-height: 345px !important;
  max-height: 345px !important;
}

.width-345,
.w-345 {
  width: 345px !important;
  min-width: 345px !important;
  max-width: 345px !important;
}

.height-350,
.h-350 {
  height: 350px !important;
  min-height: 350px !important;
  max-height: 350px !important;
}

.width-350,
.w-350 {
  width: 350px !important;
  min-width: 350px !important;
  max-width: 350px !important;
}

.height-355,
.h-355 {
  height: 355px !important;
  min-height: 355px !important;
  max-height: 355px !important;
}

.width-355,
.w-355 {
  width: 355px !important;
  min-width: 355px !important;
  max-width: 355px !important;
}

.height-360,
.h-360 {
  height: 360px !important;
  min-height: 360px !important;
  max-height: 360px !important;
}

.width-360,
.w-360 {
  width: 360px !important;
  min-width: 360px !important;
  max-width: 360px !important;
}

.height-365,
.h-365 {
  height: 365px !important;
  min-height: 365px !important;
  max-height: 365px !important;
}

.width-365,
.w-365 {
  width: 365px !important;
  min-width: 365px !important;
  max-width: 365px !important;
}

.height-370,
.h-370 {
  height: 370px !important;
  min-height: 370px !important;
  max-height: 370px !important;
}

.width-370,
.w-370 {
  width: 370px !important;
  min-width: 370px !important;
  max-width: 370px !important;
}

.height-375,
.h-375 {
  height: 375px !important;
  min-height: 375px !important;
  max-height: 375px !important;
}

.width-375,
.w-375 {
  width: 375px !important;
  min-width: 375px !important;
  max-width: 375px !important;
}

.height-380,
.h-380 {
  height: 380px !important;
  min-height: 380px !important;
  max-height: 380px !important;
}

.width-380,
.w-380 {
  width: 380px !important;
  min-width: 380px !important;
  max-width: 380px !important;
}

.height-385,
.h-385 {
  height: 385px !important;
  min-height: 385px !important;
  max-height: 385px !important;
}

.width-385,
.w-385 {
  width: 385px !important;
  min-width: 385px !important;
  max-width: 385px !important;
}

.height-390,
.h-390 {
  height: 390px !important;
  min-height: 390px !important;
  max-height: 390px !important;
}

.width-390,
.w-390 {
  width: 390px !important;
  min-width: 390px !important;
  max-width: 390px !important;
}

.height-395,
.h-395 {
  height: 395px !important;
  min-height: 395px !important;
  max-height: 395px !important;
}

.width-395,
.w-395 {
  width: 395px !important;
  min-width: 395px !important;
  max-width: 395px !important;
}

.height-400,
.h-400 {
  height: 400px !important;
  min-height: 400px !important;
  max-height: 400px !important;
}

.width-400,
.w-400 {
  width: 400px !important;
  min-width: 400px !important;
  max-width: 400px !important;
}

.height-405,
.h-405 {
  height: 405px !important;
  min-height: 405px !important;
  max-height: 405px !important;
}

.width-405,
.w-405 {
  width: 405px !important;
  min-width: 405px !important;
  max-width: 405px !important;
}

.height-410,
.h-410 {
  height: 410px !important;
  min-height: 410px !important;
  max-height: 410px !important;
}

.width-410,
.w-410 {
  width: 410px !important;
  min-width: 410px !important;
  max-width: 410px !important;
}

.height-415,
.h-415 {
  height: 415px !important;
  min-height: 415px !important;
  max-height: 415px !important;
}

.width-415,
.w-415 {
  width: 415px !important;
  min-width: 415px !important;
  max-width: 415px !important;
}

.height-420,
.h-420 {
  height: 420px !important;
  min-height: 420px !important;
  max-height: 420px !important;
}

.width-420,
.w-420 {
  width: 420px !important;
  min-width: 420px !important;
  max-width: 420px !important;
}

.height-425,
.h-425 {
  height: 425px !important;
  min-height: 425px !important;
  max-height: 425px !important;
}

.width-425,
.w-425 {
  width: 425px !important;
  min-width: 425px !important;
  max-width: 425px !important;
}

.height-430,
.h-430 {
  height: 430px !important;
  min-height: 430px !important;
  max-height: 430px !important;
}

.width-430,
.w-430 {
  width: 430px !important;
  min-width: 430px !important;
  max-width: 430px !important;
}

.height-435,
.h-435 {
  height: 435px !important;
  min-height: 435px !important;
  max-height: 435px !important;
}

.width-435,
.w-435 {
  width: 435px !important;
  min-width: 435px !important;
  max-width: 435px !important;
}

.height-440,
.h-440 {
  height: 440px !important;
  min-height: 440px !important;
  max-height: 440px !important;
}

.width-440,
.w-440 {
  width: 440px !important;
  min-width: 440px !important;
  max-width: 440px !important;
}

.height-445,
.h-445 {
  height: 445px !important;
  min-height: 445px !important;
  max-height: 445px !important;
}

.width-445,
.w-445 {
  width: 445px !important;
  min-width: 445px !important;
  max-width: 445px !important;
}

.height-450,
.h-450 {
  height: 450px !important;
  min-height: 450px !important;
  max-height: 450px !important;
}

.width-450,
.w-450 {
  width: 450px !important;
  min-width: 450px !important;
  max-width: 450px !important;
}

.height-455,
.h-455 {
  height: 455px !important;
  min-height: 455px !important;
  max-height: 455px !important;
}

.width-455,
.w-455 {
  width: 455px !important;
  min-width: 455px !important;
  max-width: 455px !important;
}

.height-460,
.h-460 {
  height: 460px !important;
  min-height: 460px !important;
  max-height: 460px !important;
}

.width-460,
.w-460 {
  width: 460px !important;
  min-width: 460px !important;
  max-width: 460px !important;
}

.height-465,
.h-465 {
  height: 465px !important;
  min-height: 465px !important;
  max-height: 465px !important;
}

.width-465,
.w-465 {
  width: 465px !important;
  min-width: 465px !important;
  max-width: 465px !important;
}

.height-470,
.h-470 {
  height: 470px !important;
  min-height: 470px !important;
  max-height: 470px !important;
}

.width-470,
.w-470 {
  width: 470px !important;
  min-width: 470px !important;
  max-width: 470px !important;
}

.height-475,
.h-475 {
  height: 475px !important;
  min-height: 475px !important;
  max-height: 475px !important;
}

.width-475,
.w-475 {
  width: 475px !important;
  min-width: 475px !important;
  max-width: 475px !important;
}

.height-480,
.h-480 {
  height: 480px !important;
  min-height: 480px !important;
  max-height: 480px !important;
}

.width-480,
.w-480 {
  width: 480px !important;
  min-width: 480px !important;
  max-width: 480px !important;
}

.height-485,
.h-485 {
  height: 485px !important;
  min-height: 485px !important;
  max-height: 485px !important;
}

.width-485,
.w-485 {
  width: 485px !important;
  min-width: 485px !important;
  max-width: 485px !important;
}

.height-490,
.h-490 {
  height: 490px !important;
  min-height: 490px !important;
  max-height: 490px !important;
}

.width-490,
.w-490 {
  width: 490px !important;
  min-width: 490px !important;
  max-width: 490px !important;
}

.height-495,
.h-495 {
  height: 495px !important;
  min-height: 495px !important;
  max-height: 495px !important;
}

.width-495,
.w-495 {
  width: 495px !important;
  min-width: 495px !important;
  max-width: 495px !important;
}

.height-500,
.h-500 {
  height: 500px !important;
  min-height: 500px !important;
  max-height: 500px !important;
}

.width-500,
.w-500 {
  width: 500px !important;
  min-width: 500px !important;
  max-width: 500px !important;
}

.height-8,
.h-8 {
  height: 8px !important;
  min-height: 8px !important;
  max-height: 8px !important;
}

.width-8,
.w-8 {
  width: 8px !important;
  min-width: 8px !important;
  max-width: 8px !important;
}

.height-16,
.h-16 {
  height: 16px !important;
  min-height: 16px !important;
  max-height: 16px !important;
}

.width-16,
.w-16 {
  width: 16px !important;
  min-width: 16px !important;
  max-width: 16px !important;
}

.height-24,
.h-24 {
  height: 24px !important;
  min-height: 24px !important;
  max-height: 24px !important;
}

.width-24,
.w-24 {
  width: 24px !important;
  min-width: 24px !important;
  max-width: 24px !important;
}

.height-32,
.h-32 {
  height: 32px !important;
  min-height: 32px !important;
  max-height: 32px !important;
}

.width-32,
.w-32 {
  width: 32px !important;
  min-width: 32px !important;
  max-width: 32px !important;
}

.height-48,
.h-48 {
  height: 48px !important;
  min-height: 48px !important;
  max-height: 48px !important;
}

.width-48,
.w-48 {
  width: 48px !important;
  min-width: 48px !important;
  max-width: 48px !important;
}

.height-56,
.h-56 {
  height: 56px !important;
  min-height: 56px !important;
  max-height: 56px !important;
}

.width-56,
.w-56 {
  width: 56px !important;
  min-width: 56px !important;
  max-width: 56px !important;
}

.height-64,
.h-64 {
  height: 64px !important;
  min-height: 64px !important;
  max-height: 64px !important;
}

.width-64,
.w-64 {
  width: 64px !important;
  min-width: 64px !important;
  max-width: 64px !important;
}

.height-72,
.h-72 {
  height: 72px !important;
  min-height: 72px !important;
  max-height: 72px !important;
}

.width-72,
.w-72 {
  width: 72px !important;
  min-width: 72px !important;
  max-width: 72px !important;
}

.height-88,
.h-88 {
  height: 88px !important;
  min-height: 88px !important;
  max-height: 88px !important;
}

.width-88,
.w-88 {
  width: 88px !important;
  min-width: 88px !important;
  max-width: 88px !important;
}

.height-96,
.h-96 {
  height: 96px !important;
  min-height: 96px !important;
  max-height: 96px !important;
}

.width-96,
.w-96 {
  width: 96px !important;
  min-width: 96px !important;
  max-width: 96px !important;
}

.height-104,
.h-104 {
  height: 104px !important;
  min-height: 104px !important;
  max-height: 104px !important;
}

.width-104,
.w-104 {
  width: 104px !important;
  min-width: 104px !important;
  max-width: 104px !important;
}

.height-112,
.h-112 {
  height: 112px !important;
  min-height: 112px !important;
  max-height: 112px !important;
}

.width-112,
.w-112 {
  width: 112px !important;
  min-width: 112px !important;
  max-width: 112px !important;
}

.height-128,
.h-128 {
  height: 128px !important;
  min-height: 128px !important;
  max-height: 128px !important;
}

.width-128,
.w-128 {
  width: 128px !important;
  min-width: 128px !important;
  max-width: 128px !important;
}

.height-136,
.h-136 {
  height: 136px !important;
  min-height: 136px !important;
  max-height: 136px !important;
}

.width-136,
.w-136 {
  width: 136px !important;
  min-width: 136px !important;
  max-width: 136px !important;
}

.height-144,
.h-144 {
  height: 144px !important;
  min-height: 144px !important;
  max-height: 144px !important;
}

.width-144,
.w-144 {
  width: 144px !important;
  min-width: 144px !important;
  max-width: 144px !important;
}

.height-152,
.h-152 {
  height: 152px !important;
  min-height: 152px !important;
  max-height: 152px !important;
}

.width-152,
.w-152 {
  width: 152px !important;
  min-width: 152px !important;
  max-width: 152px !important;
}

.height-168,
.h-168 {
  height: 168px !important;
  min-height: 168px !important;
  max-height: 168px !important;
}

.width-168,
.w-168 {
  width: 168px !important;
  min-width: 168px !important;
  max-width: 168px !important;
}

.height-176,
.h-176 {
  height: 176px !important;
  min-height: 176px !important;
  max-height: 176px !important;
}

.width-176,
.w-176 {
  width: 176px !important;
  min-width: 176px !important;
  max-width: 176px !important;
}

.height-184,
.h-184 {
  height: 184px !important;
  min-height: 184px !important;
  max-height: 184px !important;
}

.width-184,
.w-184 {
  width: 184px !important;
  min-width: 184px !important;
  max-width: 184px !important;
}

.height-192,
.h-192 {
  height: 192px !important;
  min-height: 192px !important;
  max-height: 192px !important;
}

.width-192,
.w-192 {
  width: 192px !important;
  min-width: 192px !important;
  max-width: 192px !important;
}

.height-208,
.h-208 {
  height: 208px !important;
  min-height: 208px !important;
  max-height: 208px !important;
}

.width-208,
.w-208 {
  width: 208px !important;
  min-width: 208px !important;
  max-width: 208px !important;
}

.height-216,
.h-216 {
  height: 216px !important;
  min-height: 216px !important;
  max-height: 216px !important;
}

.width-216,
.w-216 {
  width: 216px !important;
  min-width: 216px !important;
  max-width: 216px !important;
}

.height-224,
.h-224 {
  height: 224px !important;
  min-height: 224px !important;
  max-height: 224px !important;
}

.width-224,
.w-224 {
  width: 224px !important;
  min-width: 224px !important;
  max-width: 224px !important;
}

.height-232,
.h-232 {
  height: 232px !important;
  min-height: 232px !important;
  max-height: 232px !important;
}

.width-232,
.w-232 {
  width: 232px !important;
  min-width: 232px !important;
  max-width: 232px !important;
}

.height-248,
.h-248 {
  height: 248px !important;
  min-height: 248px !important;
  max-height: 248px !important;
}

.width-248,
.w-248 {
  width: 248px !important;
  min-width: 248px !important;
  max-width: 248px !important;
}

.height-256,
.h-256 {
  height: 256px !important;
  min-height: 256px !important;
  max-height: 256px !important;
}

.width-256,
.w-256 {
  width: 256px !important;
  min-width: 256px !important;
  max-width: 256px !important;
}

.height-264,
.h-264 {
  height: 264px !important;
  min-height: 264px !important;
  max-height: 264px !important;
}

.width-264,
.w-264 {
  width: 264px !important;
  min-width: 264px !important;
  max-width: 264px !important;
}

.height-272,
.h-272 {
  height: 272px !important;
  min-height: 272px !important;
  max-height: 272px !important;
}

.width-272,
.w-272 {
  width: 272px !important;
  min-width: 272px !important;
  max-width: 272px !important;
}

.height-288,
.h-288 {
  height: 288px !important;
  min-height: 288px !important;
  max-height: 288px !important;
}

.width-288,
.w-288 {
  width: 288px !important;
  min-width: 288px !important;
  max-width: 288px !important;
}

.height-296,
.h-296 {
  height: 296px !important;
  min-height: 296px !important;
  max-height: 296px !important;
}

.width-296,
.w-296 {
  width: 296px !important;
  min-width: 296px !important;
  max-width: 296px !important;
}

.height-304,
.h-304 {
  height: 304px !important;
  min-height: 304px !important;
  max-height: 304px !important;
}

.width-304,
.w-304 {
  width: 304px !important;
  min-width: 304px !important;
  max-width: 304px !important;
}

.height-312,
.h-312 {
  height: 312px !important;
  min-height: 312px !important;
  max-height: 312px !important;
}

.width-312,
.w-312 {
  width: 312px !important;
  min-width: 312px !important;
  max-width: 312px !important;
}

.height-328,
.h-328 {
  height: 328px !important;
  min-height: 328px !important;
  max-height: 328px !important;
}

.width-328,
.w-328 {
  width: 328px !important;
  min-width: 328px !important;
  max-width: 328px !important;
}

.height-336,
.h-336 {
  height: 336px !important;
  min-height: 336px !important;
  max-height: 336px !important;
}

.width-336,
.w-336 {
  width: 336px !important;
  min-width: 336px !important;
  max-width: 336px !important;
}

.height-344,
.h-344 {
  height: 344px !important;
  min-height: 344px !important;
  max-height: 344px !important;
}

.width-344,
.w-344 {
  width: 344px !important;
  min-width: 344px !important;
  max-width: 344px !important;
}

.height-352,
.h-352 {
  height: 352px !important;
  min-height: 352px !important;
  max-height: 352px !important;
}

.width-352,
.w-352 {
  width: 352px !important;
  min-width: 352px !important;
  max-width: 352px !important;
}

.height-368,
.h-368 {
  height: 368px !important;
  min-height: 368px !important;
  max-height: 368px !important;
}

.width-368,
.w-368 {
  width: 368px !important;
  min-width: 368px !important;
  max-width: 368px !important;
}

.height-376,
.h-376 {
  height: 376px !important;
  min-height: 376px !important;
  max-height: 376px !important;
}

.width-376,
.w-376 {
  width: 376px !important;
  min-width: 376px !important;
  max-width: 376px !important;
}

.height-384,
.h-384 {
  height: 384px !important;
  min-height: 384px !important;
  max-height: 384px !important;
}

.width-384,
.w-384 {
  width: 384px !important;
  min-width: 384px !important;
  max-width: 384px !important;
}

.height-392,
.h-392 {
  height: 392px !important;
  min-height: 392px !important;
  max-height: 392px !important;
}

.width-392,
.w-392 {
  width: 392px !important;
  min-width: 392px !important;
  max-width: 392px !important;
}

.height-408,
.h-408 {
  height: 408px !important;
  min-height: 408px !important;
  max-height: 408px !important;
}

.width-408,
.w-408 {
  width: 408px !important;
  min-width: 408px !important;
  max-width: 408px !important;
}

.height-416,
.h-416 {
  height: 416px !important;
  min-height: 416px !important;
  max-height: 416px !important;
}

.width-416,
.w-416 {
  width: 416px !important;
  min-width: 416px !important;
  max-width: 416px !important;
}

.height-424,
.h-424 {
  height: 424px !important;
  min-height: 424px !important;
  max-height: 424px !important;
}

.width-424,
.w-424 {
  width: 424px !important;
  min-width: 424px !important;
  max-width: 424px !important;
}

.height-432,
.h-432 {
  height: 432px !important;
  min-height: 432px !important;
  max-height: 432px !important;
}

.width-432,
.w-432 {
  width: 432px !important;
  min-width: 432px !important;
  max-width: 432px !important;
}

.height-448,
.h-448 {
  height: 448px !important;
  min-height: 448px !important;
  max-height: 448px !important;
}

.width-448,
.w-448 {
  width: 448px !important;
  min-width: 448px !important;
  max-width: 448px !important;
}

.height-456,
.h-456 {
  height: 456px !important;
  min-height: 456px !important;
  max-height: 456px !important;
}

.width-456,
.w-456 {
  width: 456px !important;
  min-width: 456px !important;
  max-width: 456px !important;
}

.height-464,
.h-464 {
  height: 464px !important;
  min-height: 464px !important;
  max-height: 464px !important;
}

.width-464,
.w-464 {
  width: 464px !important;
  min-width: 464px !important;
  max-width: 464px !important;
}

.height-472,
.h-472 {
  height: 472px !important;
  min-height: 472px !important;
  max-height: 472px !important;
}

.width-472,
.w-472 {
  width: 472px !important;
  min-width: 472px !important;
  max-width: 472px !important;
}

.height-488,
.h-488 {
  height: 488px !important;
  min-height: 488px !important;
  max-height: 488px !important;
}

.width-488,
.w-488 {
  width: 488px !important;
  min-width: 488px !important;
  max-width: 488px !important;
}

.height-496,
.h-496 {
  height: 496px !important;
  min-height: 496px !important;
  max-height: 496px !important;
}

.width-496,
.w-496 {
  width: 496px !important;
  min-width: 496px !important;
  max-width: 496px !important;
}

.height-504,
.h-504 {
  height: 504px !important;
  min-height: 504px !important;
  max-height: 504px !important;
}

.width-504,
.w-504 {
  width: 504px !important;
  min-width: 504px !important;
  max-width: 504px !important;
}

.height-512,
.h-512 {
  height: 512px !important;
  min-height: 512px !important;
  max-height: 512px !important;
}

.width-512,
.w-512 {
  width: 512px !important;
  min-width: 512px !important;
  max-width: 512px !important;
}

.height-528,
.h-528 {
  height: 528px !important;
  min-height: 528px !important;
  max-height: 528px !important;
}

.width-528,
.w-528 {
  width: 528px !important;
  min-width: 528px !important;
  max-width: 528px !important;
}

.height-536,
.h-536 {
  height: 536px !important;
  min-height: 536px !important;
  max-height: 536px !important;
}

.width-536,
.w-536 {
  width: 536px !important;
  min-width: 536px !important;
  max-width: 536px !important;
}

.height-544,
.h-544 {
  height: 544px !important;
  min-height: 544px !important;
  max-height: 544px !important;
}

.width-544,
.w-544 {
  width: 544px !important;
  min-width: 544px !important;
  max-width: 544px !important;
}

.height-552,
.h-552 {
  height: 552px !important;
  min-height: 552px !important;
  max-height: 552px !important;
}

.width-552,
.w-552 {
  width: 552px !important;
  min-width: 552px !important;
  max-width: 552px !important;
}

.height-568,
.h-568 {
  height: 568px !important;
  min-height: 568px !important;
  max-height: 568px !important;
}

.width-568,
.w-568 {
  width: 568px !important;
  min-width: 568px !important;
  max-width: 568px !important;
}

.height-576,
.h-576 {
  height: 576px !important;
  min-height: 576px !important;
  max-height: 576px !important;
}

.width-576,
.w-576 {
  width: 576px !important;
  min-width: 576px !important;
  max-width: 576px !important;
}

.height-584,
.h-584 {
  height: 584px !important;
  min-height: 584px !important;
  max-height: 584px !important;
}

.width-584,
.w-584 {
  width: 584px !important;
  min-width: 584px !important;
  max-width: 584px !important;
}

.height-592,
.h-592 {
  height: 592px !important;
  min-height: 592px !important;
  max-height: 592px !important;
}

.width-592,
.w-592 {
  width: 592px !important;
  min-width: 592px !important;
  max-width: 592px !important;
}

.height-608,
.h-608 {
  height: 608px !important;
  min-height: 608px !important;
  max-height: 608px !important;
}

.width-608,
.w-608 {
  width: 608px !important;
  min-width: 608px !important;
  max-width: 608px !important;
}

.height-616,
.h-616 {
  height: 616px !important;
  min-height: 616px !important;
  max-height: 616px !important;
}

.width-616,
.w-616 {
  width: 616px !important;
  min-width: 616px !important;
  max-width: 616px !important;
}

.height-624,
.h-624 {
  height: 624px !important;
  min-height: 624px !important;
  max-height: 624px !important;
}

.width-624,
.w-624 {
  width: 624px !important;
  min-width: 624px !important;
  max-width: 624px !important;
}

.height-632,
.h-632 {
  height: 632px !important;
  min-height: 632px !important;
  max-height: 632px !important;
}

.width-632,
.w-632 {
  width: 632px !important;
  min-width: 632px !important;
  max-width: 632px !important;
}

.height-648,
.h-648 {
  height: 648px !important;
  min-height: 648px !important;
  max-height: 648px !important;
}

.width-648,
.w-648 {
  width: 648px !important;
  min-width: 648px !important;
  max-width: 648px !important;
}

.height-656,
.h-656 {
  height: 656px !important;
  min-height: 656px !important;
  max-height: 656px !important;
}

.width-656,
.w-656 {
  width: 656px !important;
  min-width: 656px !important;
  max-width: 656px !important;
}

.height-664,
.h-664 {
  height: 664px !important;
  min-height: 664px !important;
  max-height: 664px !important;
}

.width-664,
.w-664 {
  width: 664px !important;
  min-width: 664px !important;
  max-width: 664px !important;
}

.height-672,
.h-672 {
  height: 672px !important;
  min-height: 672px !important;
  max-height: 672px !important;
}

.width-672,
.w-672 {
  width: 672px !important;
  min-width: 672px !important;
  max-width: 672px !important;
}

.height-688,
.h-688 {
  height: 688px !important;
  min-height: 688px !important;
  max-height: 688px !important;
}

.width-688,
.w-688 {
  width: 688px !important;
  min-width: 688px !important;
  max-width: 688px !important;
}

.height-696,
.h-696 {
  height: 696px !important;
  min-height: 696px !important;
  max-height: 696px !important;
}

.width-696,
.w-696 {
  width: 696px !important;
  min-width: 696px !important;
  max-width: 696px !important;
}

.height-704,
.h-704 {
  height: 704px !important;
  min-height: 704px !important;
  max-height: 704px !important;
}

.width-704,
.w-704 {
  width: 704px !important;
  min-width: 704px !important;
  max-width: 704px !important;
}

.height-712,
.h-712 {
  height: 712px !important;
  min-height: 712px !important;
  max-height: 712px !important;
}

.width-712,
.w-712 {
  width: 712px !important;
  min-width: 712px !important;
  max-width: 712px !important;
}

.height-728,
.h-728 {
  height: 728px !important;
  min-height: 728px !important;
  max-height: 728px !important;
}

.width-728,
.w-728 {
  width: 728px !important;
  min-width: 728px !important;
  max-width: 728px !important;
}

.height-736,
.h-736 {
  height: 736px !important;
  min-height: 736px !important;
  max-height: 736px !important;
}

.width-736,
.w-736 {
  width: 736px !important;
  min-width: 736px !important;
  max-width: 736px !important;
}

.height-744,
.h-744 {
  height: 744px !important;
  min-height: 744px !important;
  max-height: 744px !important;
}

.width-744,
.w-744 {
  width: 744px !important;
  min-width: 744px !important;
  max-width: 744px !important;
}

.height-752,
.h-752 {
  height: 752px !important;
  min-height: 752px !important;
  max-height: 752px !important;
}

.width-752,
.w-752 {
  width: 752px !important;
  min-width: 752px !important;
  max-width: 752px !important;
}

.height-768,
.h-768 {
  height: 768px !important;
  min-height: 768px !important;
  max-height: 768px !important;
}

.width-768,
.w-768 {
  width: 768px !important;
  min-width: 768px !important;
  max-width: 768px !important;
}

.height-776,
.h-776 {
  height: 776px !important;
  min-height: 776px !important;
  max-height: 776px !important;
}

.width-776,
.w-776 {
  width: 776px !important;
  min-width: 776px !important;
  max-width: 776px !important;
}

.height-784,
.h-784 {
  height: 784px !important;
  min-height: 784px !important;
  max-height: 784px !important;
}

.width-784,
.w-784 {
  width: 784px !important;
  min-width: 784px !important;
  max-width: 784px !important;
}

.height-792,
.h-792 {
  height: 792px !important;
  min-height: 792px !important;
  max-height: 792px !important;
}

.width-792,
.w-792 {
  width: 792px !important;
  min-width: 792px !important;
  max-width: 792px !important;
}

.border,
.b {
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.border-top,
.bt {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.border-right,
.br {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.border-bottom,
.bb {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.border-left,
.bl {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.border-horizontal,
.bh {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.border-vertical,
.bv {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.brad-1 {
  border-radius: 1px;
}

.brad-2 {
  border-radius: 2px;
}

.brad-3 {
  border-radius: 3px;
}

.brad-4 {
  border-radius: 4px;
}

.brad-5 {
  border-radius: 5px;
}

.brad-6 {
  border-radius: 6px;
}

.brad-7 {
  border-radius: 7px;
}

.brad-8 {
  border-radius: 8px;
}

.brad-9 {
  border-radius: 9px;
}

.brad-10 {
  border-radius: 10px;
}

.brad-11 {
  border-radius: 11px;
}

.brad-12 {
  border-radius: 12px;
}

/*----------------------------------------------------------------*/
/*  Print
/*----------------------------------------------------------------*/
@media all {
  /* Never show page break in normal view */
  .page-break {
    display: none;
  }
}
@media print {
  /* Page Styles */
  @page {}
  /* Page break */
  .page-break {
    display: block;
    break-after: always;
    page-break-after: always;
  }
  /* General styles */
  #main #vertical-navigation {
    display: none;
  }
  #main #toolbar {
    display: none;
  }
  #main #quick-panel {
    display: none;
  }
  #main #content {
    position: relative !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    background: none !important;
  }
  ms-theme-options {
    display: none;
  }
  .ps-scrollbar-x-rail,
  .ps-scrollbar-y-rail {
    display: none !important;
  }
  /* Printable page specific styles */
  .printable {
    overflow: visible !important;
  }
  .printable .template-layout {
    display: inline !important;
  }
}
#managaAccessPanel #manageAccess-members tbody {
  display: block;
  max-height: 300px;
  overflow-y: auto;
}
#managaAccessPanel #manageAccess-members tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
#managaAccessPanel #manageAccess-members .container .avatar-wrapper .image {
  height: 36px !important;
}
#managaAccessPanel .members {
  width: 100%;
}
#managaAccessPanel .action-container {
  margin-top: 20px;
}
#managaAccessPanel .action-container .btn-send {
  margin: 0;
  float: right;
}

.pannel-container {
  height: 100%;
}
.pannel-container .moreInfoTitleContainer {
  width: 100%;
}
.pannel-container .moreInfoTitleContainer .closeBtn {
  text-align: right;
}
.pannel-container .moreInfoTitleContainer .title {
  font-size: 30px;
  letter-spacing: 0;
  line-height: 36px;
  color: #000;
}
.pannel-container .moreInfoTitleContainer .status {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: 8px;
}
.pannel-container .moreInfoDivide {
  box-sizing: border-box;
  height: 1px;
  width: 540px;
  border: 1px solid #D8DCE6;
  margin: 23.5px 0;
  border-top: none;
}
.pannel-container .moreInfoPaticipants .title {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 15px;
  color: #000000;
  margin-bottom: 8px;
}
.pannel-container .moreInfoPaticipants .members {
  flex-wrap: wrap;
  width: 92%;
}
.pannel-container .moreInfoPaticipants .members .image {
  width: 41px;
  min-width: 41px;
  max-width: 41px;
  line-height: 41px;
  margin-right: 4px;
  margin-bottom: 8px;
  border-radius: 50%;
  text-align: center;
  height: 40px;
  cursor: pointer;
  background-color: #6D717D;
  font-weight: 500;
  color: #fff;
}

.ms-theme-options-backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 49;
}

.ms-theme-options {
  position: fixed;
  top: 160px;
  right: 0;
  z-index: 50;
}
.ms-theme-options.open .ms-theme-options-panel {
  transform: translateX(0);
}
.ms-theme-options.open .ms-theme-options-panel .ms-theme-options-panel-button {
  opacity: 1;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.ms-theme-options.open .ms-theme-options-list {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.ms-theme-options .ms-theme-options-panel {
  position: absolute;
  top: 0;
  right: 0;
  width: 268px;
  transform: translateX(220px);
  transition: transform 0.225s ease-in-out;
  pointer-events: none;
}
.ms-theme-options .ms-theme-options-panel .ms-theme-options-panel-button {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  width: 48px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  cursor: pointer;
  border-radius: 0;
  margin: 0;
  pointer-events: auto;
  opacity: 0.75;
}
.ms-theme-options .ms-theme-options-panel .ms-theme-options-panel-button:hover {
  opacity: 1;
}
.ms-theme-options .ms-theme-options-panel .ms-theme-options-list {
  width: 220px;
  min-width: 220px;
  max-width: 220px;
  background: #FFFFFF;
  z-index: 12;
  pointer-events: auto;
}
.ms-theme-options .ms-theme-options-panel .ms-theme-options-list .theme-option {
  padding: 16px;
}
.ms-theme-options .ms-theme-options-panel .ms-theme-options-list .theme-option md-radio-button {
  margin: 8px;
}
.ms-theme-options .ms-theme-options-panel .ms-theme-options-list .theme-option .option-title {
  font-size: 14px;
  font-weight: 500;
  padding: 0 8px 12px 8px;
}
.ms-theme-options .ms-theme-options-panel .ms-theme-options-list .theme-option .theme {
  padding: 4px 8px;
}
.ms-theme-options .ms-theme-options-panel .ms-theme-options-list .theme-option .theme .theme-button {
  border-right: 8px solid;
  text-align: left;
}

.my-minutes-checkbox {
  box-sizing: border-box;
  height: 16px;
  width: 16px;
  border-radius: 3px;
  position: relative;
}
.my-minutes-checkbox.uncheck {
  border: 1px solid #D9D9D9;
  background: linear-gradient(0deg, #F6F7F9 0%, #FFFFFF 100%);
  box-shadow: inset 0 2px 0 0 rgba(255, 255, 255, 0.05), 0 1px 1px 0 rgba(22, 29, 37, 0.05);
}
.my-minutes-checkbox.checked {
  border: 1px solid #008F8A;
  background-color: #00A19C;
  box-shadow: inset 0 2px 0 0 rgba(255, 255, 255, 0.06), 0 1px 1px 0 rgba(19, 31, 21, 0.1);
}
.my-minutes-checkbox.checked::before {
  position: absolute;
  content: "";
  display: block;
  top: 3px;
  left: 3px;
  width: 8px;
  height: 6px;
  border-style: solid;
  border-color: #fff;
  border-width: 2px 2px 0px 0px;
  transform: rotate(130deg);
}

ms-nav {
  display: block;
  position: relative;
  color: rgba(255, 255, 255, 0.7);
  user-select: none;
  z-index: 70;
}
ms-nav ms-nav-item {
  display: block;
  position: relative;
  line-height: 48px;
}
ms-nav ms-nav-item.ms-nav-title {
  line-height: 32px;
  font-size: 12px;
  font-weight: 500;
  margin-top: 8px;
  padding: 0 24px;
  width: 100%;
  color: rgba(255, 255, 255, 0.3);
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
ms-nav ms-nav-item .ms-nav-button {
  display: flex;
  align-self: flex-start;
  align-items: center;
  position: relative;
  cursor: pointer;
  padding: 0 48px 0 24px;
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
}
ms-nav ms-nav-item .ms-nav-button:not(.active):hover {
  background: rgba(0, 0, 0, 0.1);
  text-decoration: none;
}
ms-nav ms-nav-item .ms-nav-button .ms-nav-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: opacity 0.2s ease-in-out 0.1s;
}
ms-nav ms-nav-item .ms-nav-button .arrow {
  position: absolute;
  top: 16px;
  right: 24px;
  margin: 0;
  transition: transform 0.3s ease-in-out, opacity 0.2s ease-in-out 0.1s;
}
ms-nav ms-nav-item .ms-nav-button .ms-nav-badge {
  position: absolute;
  top: 50%;
  right: 24px;
  min-width: 20px;
  height: 20px;
  line-height: 20px;
  padding: 0 7px;
  font-size: 11px;
  font-weight: bold;
  border-radius: 20px;
  transform: translateY(-50%);
  transition: opacity 0.2s ease-in-out 0.1s;
}
ms-nav ms-nav-item .ms-nav-button.active {
  color: #FFFFFF !important;
}
ms-nav ms-nav-item .ms-nav-button.active .ms-nav-badge {
  background: #FFFFFF !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
ms-nav ms-nav-item .ms-nav-button.active i {
  color: #FFFFFF;
}
ms-nav ms-nav-item i {
  color: rgba(255, 255, 255, 0.7);
  margin: 0 16px 0 0;
}
ms-nav .ms-nav-toggle ms-nav-toggle-items {
  display: none;
  overflow: hidden;
}
ms-nav .ms-nav-toggle ms-nav-toggle-items.expand-animation {
  transition: height 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
ms-nav .ms-nav-toggle ms-nav-toggle-items.collapse-animation {
  transition: height 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
ms-nav .ms-nav-toggle ms-nav-toggle-items ms-nav-item {
  /* Level 1 */
  /* Level 2 */
}
ms-nav .ms-nav-toggle ms-nav-toggle-items ms-nav-item .ms-nav-button {
  padding-left: 58px;
}
ms-nav .ms-nav-toggle ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item {
  /* Level 3 */
}
ms-nav .ms-nav-toggle ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item .ms-nav-button {
  padding-left: 68px;
}
ms-nav .ms-nav-toggle ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item {
  /* Level 4 */
}
ms-nav .ms-nav-toggle ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item .ms-nav-button {
  padding-left: 78px;
}
ms-nav .ms-nav-toggle ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item {
  /* Level 5 */
}
ms-nav .ms-nav-toggle ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item .ms-nav-button {
  padding-left: 88px;
}
ms-nav .ms-nav-toggle ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item .ms-nav-button {
  padding-left: 98px;
}
ms-nav .ms-nav-toggle.active > .ms-nav-button {
  color: rgb(255, 255, 255);
}
ms-nav .ms-nav-toggle.active > .ms-nav-button a,
ms-nav .ms-nav-toggle.active > .ms-nav-button md-icon {
  color: rgb(255, 255, 255);
}
ms-nav .ms-nav-toggle[collapsed=false] > .ms-nav-button > .arrow {
  transform: rotate(90deg);
}
ms-nav > .ms-nav-toggle[collapsed=false] {
  background-color: rgba(0, 0, 0, 0.12);
}

@media only screen and (min-width: 1280px) {
  .ms-nav-folded #ms-nav-fold-close-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    z-index: 998;
  }
  .ms-nav-folded #ms-nav-fold-open-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
  }
  .ms-nav-folded:not(.ms-nav-folded-open) ms-nav ms-nav-item.ms-nav-title {
    height: 32px;
  }
  .ms-nav-folded:not(.ms-nav-folded-open) ms-nav ms-nav-item.ms-nav-title:before {
    content: "";
    display: block;
    position: relative;
    top: 12px;
    width: 16px;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
  }
  .ms-nav-folded:not(.ms-nav-folded-open) ms-nav ms-nav-item.ms-nav-title span {
    display: none;
  }
  .ms-nav-folded:not(.ms-nav-folded-open) ms-nav ms-nav-item .ms-nav-button {
    height: 48px;
  }
  .ms-nav-folded:not(.ms-nav-folded-open) ms-nav ms-nav-item .ms-nav-button > .ms-nav-label,
  .ms-nav-folded:not(.ms-nav-folded-open) ms-nav ms-nav-item .ms-nav-button > .ms-nav-badge,
  .ms-nav-folded:not(.ms-nav-folded-open) ms-nav ms-nav-item .ms-nav-button > .arrow {
    opacity: 0;
    visibility: hidden;
  }
}
/*----------------------------------------------------------------*/
/*  ms-navigation
/*----------------------------------------------------------------*/
.ms-navigation {
  display: block;
  position: relative;
  color: rgba(255, 255, 255, 0.7);
  user-select: none;
}
.ms-navigation ul,
.ms-navigation li {
  padding: 0;
  margin: 0;
}
.ms-navigation ul {
  display: block;
  overflow: hidden;
}
.ms-navigation ul li {
  position: relative;
}
.ms-navigation ul li.collapsed ul {
  display: none;
}
.ms-navigation ul li.tag-header {
  padding: 24px 0 0 24px;
  height: 18px;
  width: 29px;
  color: #9ea0a5;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 8px;
}
.ms-navigation ul li.tag-section {
  padding: 12px 0;
}
.ms-navigation ul li.tag-section md-chips-wrap {
  margin: 4px 24px;
  padding: 0;
  font-family: "Inter Medium", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 12px;
}
.ms-navigation ul li.tag-section md-chips-wrap md-chip {
  background-color: #333333;
}
.ms-navigation ul li.tag-section md-chips-wrap md-chip .search-tagname {
  font-size: 12px;
  color: #fff;
  letter-spacing: 0;
  line-height: 16px;
}
.ms-navigation ul li.tag-section md-autocomplete input::-webkit-input-placeholder {
  color: #999999;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 16px;
}
.ms-navigation ul li.tag-section md-autocomplete input:-moz-placeholder { /* Firefox 18- */
  color: #999999;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 12px;
}
.ms-navigation ul li.tag-section md-autocomplete input::-moz-placeholder { /* Firefox 19+ */
  color: #999999;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 12px;
}
.ms-navigation ul li.tag-section md-autocomplete input:-ms-input-placeholder {
  color: #999999;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 12px;
}
.ms-navigation ul li.tag-section md-icon {
  color: rgba(255, 255, 255, 0.7);
}
.ms-navigation ul li.tag-section md-icon.icon-information {
  padding-left: 8px;
}
.ms-navigation ul li .tag-list {
  color: rgba(255, 255, 255, 0.7);
  padding-left: 12px;
  margin-bottom: 8px;
}
.ms-navigation ul li .search-box {
  margin: auto;
  margin-top: 8px;
  margin-bottom: 8px;
  color: #999999;
  font-size: 12px;
  line-height: 12px;
  box-sizing: border-box;
  height: 36px;
  width: 140px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background-color: #ffffff;
}
.ms-navigation ul li .search-box md-autocomplete-wrap {
  box-shadow: none;
  height: 100%;
}
.ms-navigation ul li .search-box button {
  margin-top: 2.5px !important;
}
.ms-navigation ul li .search-box input {
  height: 100%;
  padding: 8px;
  width: 100%;
  border-radius: 4px;
  background-color: transparent !important;
}
.ms-navigation ul li .md-chip-content {
  padding: 0px 12px;
}
.ms-navigation ul li .md-chip-content md-chip-template span {
  font-size: 10px;
}
.ms-navigation ul li .md-chip-input-container {
  display: none;
}
.ms-navigation ul li md-chip {
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.24);
  color: rgba(255, 255, 255, 0.5);
  margin: 0 8px 8px 0;
  padding: 0;
}
.ms-navigation ul li md-chip md-icon {
  margin-left: 3px;
  height: 14px !important;
  width: 14px !important;
  min-height: 14px;
  min-width: 14px;
}
.ms-navigation ul li md-chip:hover {
  cursor: pointer;
  border: 1px solid #00a19c;
  color: #00a19c;
}
.ms-navigation ul li .md-chip-remove-container {
  line-height: 14px;
}
.ms-navigation ul li .md-chip-remove-container .md-chip-remove {
  top: 8px;
  right: 6px;
  height: 14px;
  width: 14px;
}
.ms-navigation .create-button {
  display: flex;
  justify-content: center;
  padding: 16px 16px 0px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.ms-navigation .create-button .md-button {
  width: 100%;
}
.ms-navigation .create-button .button-green {
  color: #ffffff;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 500;
  letter-spacing: 0;
  line-height: 15px;
  text-transform: none;
}
.ms-navigation .create-button .button-green span {
  font-size: 12px;
}
.ms-navigation .parent:nth-child(2) {
  display: none;
}
.ms-navigation .animate-height {
  transition: height 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.ms-navigation .ms-navigation-node .ms-navigation-item {
  display: block;
  position: relative;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.7);
}
.ms-navigation .ms-navigation-node .ms-navigation-item:hover {
  background-color: rgba(0, 161, 156, 0.05);
}
.ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button {
  display: flex;
  position: relative;
  width: 100%;
  height: 48px;
  line-height: 48px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-right: 16px;
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
}
.ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button .icon {
  color: #6d7278;
  margin: 0 16px 0 0;
}
.ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button span {
  white-space: nowrap;
  overflow: hidden;
  opacity: 1;
  transition: opacity 0.25s ease-in-out 0.1s;
}
.ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button a {
  white-space: nowrap;
  overflow: hidden;
  opacity: 1;
  transition: opacity 0.25s ease-in-out 0.1s;
}
.ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button .arrow {
  margin: 0;
  opacity: 1;
  transition: transform 0.3s ease-in-out, opacity 0.25s ease-in-out 0.1s;
}
.ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button .badge {
  position: absolute;
  top: 50%;
  right: 16px;
  min-width: 20px;
  height: 20px;
  line-height: 20px;
  padding: 0 7px;
  font-size: 11px;
  font-weight: bold;
  border-radius: 20px;
  transform: translateY(-50%);
  transition: opacity 0.2s ease-in-out 0.1s;
}
.ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button .border {
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background-color: transparent;
}
.ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button.active {
  color: #ffffff;
  background-color: rgba(0, 161, 156, 0.05) !important;
}
.ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button.active .border {
  background-color: #00a19c;
}
.ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button.active .icon {
  color: #00a19c;
}
.ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button.active .badge {
  background: #ffffff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button.active .img-icon {
  display: none;
}
.ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button.active .img-icon-active {
  display: block;
}
.ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button .img-icon-active {
  display: none;
}
.ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button .title {
  color: #ffffff;
  font-family: "Inter Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
}
.ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button .title-nav {
  height: 18px;
  width: 68px;
  color: #9ea0a5;
  font-family: "Inter Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
}
.ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button .img-icon,
.ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button .img-icon-active {
  width: auto;
  height: 14px;
  margin-right: 16px;
  margin-left: 2px;
}
.ms-navigation .ms-navigation-node:not(.collapsed) > .ms-navigation-item > .ms-navigation-button .arrow {
  transform: rotate(90deg);
}
.ms-navigation .ms-navigation-node.group > .ms-navigation-item {
  font-size: 12px;
  font-weight: 500;
  margin-top: 8px;
  cursor: auto;
  background: none !important;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ms-navigation .ms-navigation-node.group > .ms-navigation-item > .ms-navigation-button {
  height: 32px;
  line-height: 32px;
  color: rgba(255, 255, 255, 0.3);
}
.ms-navigation .ms-navigation-node:not(.group).has-children:not(.collapsed) {
  background-color: rgba(0, 0, 0, 0.12);
}
.ms-navigation .ms-navigation-node:not(.group).has-children.collapsing {
  background-color: rgba(0, 0, 0, 0.12);
}
.ms-navigation .ms-navigation-node:not(.group).has-children .has-children:not(.collapsed) {
  background: none;
}
.ms-navigation li .ms-navigation-button {
  padding-left: 24px;
}
.ms-navigation li li .ms-navigation-button {
  padding-left: 56px;
}
.ms-navigation li li li .ms-navigation-button {
  padding-left: 72px;
}
.ms-navigation li li li li .ms-navigation-button {
  padding-left: 88px;
}
.ms-navigation li li li li li .ms-navigation-button {
  padding-left: 104px;
}
.ms-navigation li.group .ms-navigation-button {
  padding-left: 24px;
}
.ms-navigation li.group li .ms-navigation-button {
  padding-left: 24px;
}
.ms-navigation li.group li li .ms-navigation-button {
  padding-left: 56px;
}
.ms-navigation li.group li li li .ms-navigation-button {
  padding-left: 72px;
}
.ms-navigation li.group li li li li .ms-navigation-button {
  padding-left: 88px;
}

#horizontal-navigation {
  background: #ffffff;
  z-index: 54;
}
#horizontal-navigation .ms-navigation-horizontal {
  display: flex;
  position: relative;
  user-select: none;
}
#horizontal-navigation .ms-navigation-horizontal ul,
#horizontal-navigation .ms-navigation-horizontal li {
  padding: 0;
  margin: 0;
  list-style: none;
}
#horizontal-navigation .ms-navigation-horizontal .horizontal {
  display: flex;
  flex: 1;
}
#horizontal-navigation .ms-navigation-horizontal .horizontal li {
  position: relative;
  cursor: pointer;
}
#horizontal-navigation .ms-navigation-horizontal .horizontal li:not(.active):hover {
  background: rgba(0, 0, 0, 0.12);
}
#horizontal-navigation .ms-navigation-horizontal .horizontal > li > .ms-navigation-horizontal-item > .ms-navigation-horizontal-button {
  padding: 20px 24px;
}
#horizontal-navigation .ms-navigation-horizontal .horizontal > li > .ms-navigation-horizontal-item > .ms-navigation-horizontal-button .arrow {
  display: none;
}
#horizontal-navigation .ms-navigation-horizontal .horizontal > li:last-child {
  margin-right: 0;
}
#horizontal-navigation .ms-navigation-horizontal .horizontal .has-children ul {
  display: none;
  position: absolute;
  background: #ffffff;
  z-index: 999;
  min-width: 200px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  top: 0;
  left: 100%;
}
#horizontal-navigation .ms-navigation-horizontal .horizontal .has-children:hover > ul {
  display: block;
}
#horizontal-navigation .ms-navigation-horizontal .horizontal > .has-children > ul {
  top: 100%;
  left: 0;
}
#horizontal-navigation .ms-navigation-horizontal .horizontal .ms-navigation-horizontal-item .ms-navigation-horizontal-button {
  display: flex;
  align-items: center;
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  white-space: nowrap;
  padding: 14px 16px;
  text-decoration: none;
}
#horizontal-navigation .ms-navigation-horizontal .horizontal .ms-navigation-horizontal-item .ms-navigation-horizontal-button .icon {
  color: rgba(0, 0, 0, 0.54);
  margin-right: 8px;
}
#horizontal-navigation .ms-navigation-horizontal .horizontal .ms-navigation-horizontal-item .ms-navigation-horizontal-button .badge {
  min-width: 20px;
  height: 20px;
  line-height: 20px;
  padding: 0 7px;
  font-size: 11px;
  font-weight: bold;
  border-radius: 20px;
  transition: opacity 0.2s ease-in-out 0.1s;
}
#horizontal-navigation .ms-navigation-horizontal .horizontal .ms-navigation-horizontal-item .ms-navigation-horizontal-button .arrow {
  margin-left: 8px;
}
#horizontal-navigation .ms-navigation-horizontal .horizontal .ms-navigation-horizontal-item .ms-navigation-horizontal-button.active {
  color: #ffffff;
}
#horizontal-navigation .ms-navigation-horizontal .horizontal .ms-navigation-horizontal-item .ms-navigation-horizontal-button.active .badge {
  background: #ffffff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
#horizontal-navigation .ms-navigation-horizontal .horizontal .ms-navigation-horizontal-item .ms-navigation-horizontal-button.active .icon {
  color: #ffffff !important;
}

@media only screen and (min-width: 960px) {
  body.ms-navigation-folded:not(.ms-navigation-folded-open) .tag-header,
  body.ms-navigation-folded:not(.ms-navigation-folded-open) .tag-section,
  body.ms-navigation-folded:not(.ms-navigation-folded-open) .create-button {
    display: none;
  }
  body.ms-navigation-folded:not(.ms-navigation-folded-open) .ms-navigation .animate-height {
    transition: none;
  }
  body.ms-navigation-folded:not(.ms-navigation-folded-open) .ms-navigation .ms-navigation-node .ms-navigation-item .ms-navigation-button .icon {
    margin: 0;
  }
  body.ms-navigation-folded:not(.ms-navigation-folded-open) .ms-navigation .ms-navigation-node .ms-navigation-item .ms-navigation-button .title,
  body.ms-navigation-folded:not(.ms-navigation-folded-open) .ms-navigation .ms-navigation-node .ms-navigation-item .ms-navigation-button .arrow,
  body.ms-navigation-folded:not(.ms-navigation-folded-open) .ms-navigation .ms-navigation-node .ms-navigation-item .ms-navigation-button .badge {
    opacity: 0;
    visibility: hidden;
  }
  body.ms-navigation-folded:not(.ms-navigation-folded-open) .ms-navigation .ms-navigation-node.group > .ms-navigation-item .ms-navigation-button:before {
    content: "";
    display: block;
    position: relative;
    width: 16px;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
  }
  body.ms-navigation-folded:not(.ms-navigation-folded-open) #ms-navigation-fold-expander {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
  }
  body.ms-navigation-folded #ms-navigation-fold-collapser {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 59;
  }
}
@media only screen and (max-width: 960px) {
  body.ms-navigation-horizontal-mobile-menu-active #horizontal-navigation .navigation-toggle {
    height: 64px;
    margin-right: 16px;
  }
  body.ms-navigation-horizontal-mobile-menu-active #horizontal-navigation .navigation-toggle .md-button {
    margin: 0;
    width: 64px;
    height: 64px;
  }
  body.ms-navigation-horizontal-mobile-menu-active #horizontal-navigation .ms-navigation-horizontal {
    display: block !important;
    background: #ffffff;
  }
  #horizontal-navigation {
    z-index: 60;
  }
  #horizontal-navigation .ms-navigation-horizontal {
    position: absolute;
    display: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0 0 16px 0;
    z-index: 9998;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  #horizontal-navigation .ms-navigation-horizontal .horizontal {
    flex-direction: column;
    min-height: 100%;
    width: 100%;
  }
  #horizontal-navigation .ms-navigation-horizontal .horizontal li:not(.active):hover {
    background: none;
  }
  #horizontal-navigation .ms-navigation-horizontal .horizontal li:not(.active) .ms-navigation-horizontal-item {
    margin: 4px;
  }
  #horizontal-navigation .ms-navigation-horizontal .horizontal li:not(.active) .ms-navigation-horizontal-item:hover {
    background: rgba(0, 0, 0, 0.12);
  }
  #horizontal-navigation .ms-navigation-horizontal .horizontal > li {
    padding: 0 12px;
  }
  #horizontal-navigation .ms-navigation-horizontal .horizontal > li > .ms-navigation-horizontal-item > .ms-navigation-horizontal-button .arrow {
    display: block;
  }
  #horizontal-navigation .ms-navigation-horizontal .horizontal .has-children ul {
    position: relative;
    top: 0;
    left: 0;
    box-shadow: none;
  }
  #horizontal-navigation .ms-navigation-horizontal .horizontal .has-children:hover > ul {
    display: none;
  }
  #horizontal-navigation .ms-navigation-horizontal .horizontal .has-children .expanded > .ms-navigation-horizontal-button .arrow {
    transform: rotate(90deg);
  }
  #horizontal-navigation .ms-navigation-horizontal .horizontal .has-children .expanded + ul {
    display: block;
    margin-left: 16px;
  }
  #horizontal-navigation .ms-navigation-horizontal .horizontal .ms-navigation-horizontal-item {
    min-height: 48px;
    height: 48px;
  }
}
.help {
  margin-bottom: 40px;
  margin-top: 20px;
}
.help .help-link :link {
  text-decoration: none;
}
.help .help-link :visited {
  text-decoration: none;
}
.help .help-link:hover {
  cursor: pointer;
  text-decoration: none;
}
.help .help-link :active {
  text-decoration: none !important;
}
.help .help-link div {
  display: flex;
  position: relative;
  width: 100%;
  height: 48px;
  line-height: 48px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-right: 16px;
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 26px;
}
.help .help-link div .icon-help {
  margin: 0 16px 0 0;
  width: auto;
  height: 14px;
}
.help .help-link div span {
  color: #ffffff;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
}

/*----------------------------------------------------------------*/
/*  ms-responsive-table
/*----------------------------------------------------------------*/
.ms-responsive-table-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
}

/*----------------------------------------------------------------*/
/*  ms-scroll
/*----------------------------------------------------------------*/
.ms-scroll {
  overflow: hidden !important;
}

#splash-screen {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #3C4252;
  z-index: 99999;
}
#splash-screen.ng-leave {
  opacity: 1;
  transition: all linear 200ms;
}
#splash-screen.ng-leave-active {
  opacity: 0;
}
#splash-screen .center {
  display: block;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
#splash-screen .logo {
  justify-content: center;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
#splash-screen .logo .logo-image {
  display: block;
  background: white;
  width: 48px;
  min-width: 48px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
  border-radius: 2px;
}
#splash-screen .logo .logo-text {
  color: #FFFFFF;
  margin-top: 16px;
  font-size: 16px;
}
#splash-screen .spinner-wrapper {
  display: block;
  position: relative;
  width: 100%;
  min-height: 100px;
  height: 100px;
}
#splash-screen .spinner-wrapper .spinner {
  position: absolute;
  overflow: hidden;
  left: 50%;
  margin-left: -50px;
  animation: outer-rotate 2.91667s linear infinite;
}
#splash-screen .spinner-wrapper .spinner .inner {
  width: 100px;
  height: 100px;
  position: relative;
  animation: sporadic-rotate 5.25s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}
#splash-screen .spinner-wrapper .spinner .inner .gap {
  position: absolute;
  left: 49px;
  right: 49px;
  top: 0;
  bottom: 0;
  border-top: 10px solid;
  box-sizing: border-box;
}
#splash-screen .spinner-wrapper .spinner .inner .left,
#splash-screen .spinner-wrapper .spinner .inner .right {
  position: absolute;
  top: 0;
  height: 100px;
  width: 50px;
  overflow: hidden;
}
#splash-screen .spinner-wrapper .spinner .inner .left .half-circle,
#splash-screen .spinner-wrapper .spinner .inner .right .half-circle {
  position: absolute;
  top: 0;
  width: 100px;
  height: 100px;
  box-sizing: border-box;
  border: 10px solid white;
  border-bottom-color: transparent;
  border-radius: 50%;
}
#splash-screen .spinner-wrapper .spinner .inner .left {
  left: 0;
}
#splash-screen .spinner-wrapper .spinner .inner .left .half-circle {
  left: 0;
  border-right-color: transparent;
  animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
  -webkit-animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}
#splash-screen .spinner-wrapper .spinner .inner .right {
  right: 0;
}
#splash-screen .spinner-wrapper .spinner .inner .right .half-circle {
  right: 0;
  border-left-color: transparent;
  animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
  -webkit-animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}
@keyframes outer-rotate {
  0% {
    transform: rotate(0deg) scale(0.5);
  }
  100% {
    transform: rotate(360deg) scale(0.5);
  }
}
@keyframes left-wobble {
  0%, 100% {
    transform: rotate(130deg);
  }
  50% {
    transform: rotate(-5deg);
  }
}
@keyframes right-wobble {
  0%, 100% {
    transform: rotate(-130deg);
  }
  50% {
    transform: rotate(5deg);
  }
}
@keyframes sporadic-rotate {
  12.5% {
    transform: rotate(135deg);
  }
  25% {
    transform: rotate(270deg);
  }
  37.5% {
    transform: rotate(405deg);
  }
  50% {
    transform: rotate(540deg);
  }
  62.5% {
    transform: rotate(675deg);
  }
  75% {
    transform: rotate(810deg);
  }
  87.5% {
    transform: rotate(945deg);
  }
  100% {
    transform: rotate(1080deg);
  }
}

.angularjs-datetime-picker {
  color: #333;
  font: normal 14px sans-serif;
  display: inline-block;
  background: #fff;
  height: auto;
  width: 298px;
  border: 1px solid #D8DCE6;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
  z-index: 999;
}
.angularjs-datetime-picker .select-date {
  padding: 16px 13px 16px 16px;
  display: flex;
  justify-content: space-between;
}
.angularjs-datetime-picker .select-date label {
  color: #999999;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1ch;
}
.angularjs-datetime-picker .select-date span {
  box-sizing: border-box;
  height: 36px;
  width: 181px;
  border: 1px solid #D8DCE6;
  border-radius: 4px;
  background-color: #FFFFFF;
  text-align: right;
  color: #3E3F42;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 12px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  padding: 12px 14px;
}
.angularjs-datetime-picker .adp-month {
  text-align: center;
  line-height: 22px;
  padding: 0px 16px;
  text-transform: capitalize;
  font-weight: bold;
  position: relative;
}
.angularjs-datetime-picker .adp-month button {
  color: #555;
  font: normal 14px sans-serif;
  outline: none;
  position: absolute;
  background: transparent;
  border: none;
  cursor: pointer;
}
.angularjs-datetime-picker .adp-month button:hover {
  color: #333;
}
.angularjs-datetime-picker .adp-month button.adp-prev {
  left: 27px;
}
.angularjs-datetime-picker .adp-month button.adp-prev > img {
  position: relative;
  top: 7px;
}
.angularjs-datetime-picker .adp-month button.adp-next {
  right: 27px;
}
.angularjs-datetime-picker .adp-month button.adp-next > img {
  position: relative;
  top: 7px;
}
.angularjs-datetime-picker .adp-month span {
  opacity: 0.75;
  color: #212B36;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
}
.angularjs-datetime-picker > .adp-days {
  width: 266px;
  height: auto;
  margin: 10px 16px 16px 16px;
  text-align: center;
}
.angularjs-datetime-picker > .adp-days .adp-day-of-week {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  border: 1px solid transparent;
  float: left;
  width: 38px;
  font-weight: 500;
  text-transform: capitalize;
  color: #637381;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 6px;
}
.angularjs-datetime-picker > .adp-days .adp-day {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  float: left;
  width: 38px;
  box-shadow: 1px 0 0 0 #c4cdd5, 0 1px 0 0 #c4cdd5, 1px 1px 0 0 #c4cdd5, 1px 0 0 0 #c4cdd5 inset, 0 1px 0 0 #c4cdd5 inset;
  color: #637381;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1ch;
}
.angularjs-datetime-picker > .adp-days .adp-day:not(.selectable) {
  cursor: default;
  width: 38px;
  height: 36px;
}
.angularjs-datetime-picker > .adp-days .adp-day:not(.selectable) > span {
  opacity: 0.15;
}
.angularjs-datetime-picker > .adp-days .adp-day.selectable {
  cursor: pointer;
  width: 38px;
  height: 36px;
}
.angularjs-datetime-picker > .adp-days .adp-day.selectable:hover {
  background: #4DBDBA;
  color: #fff;
}
.angularjs-datetime-picker > .adp-days .adp-day.selected {
  background-color: #00A19C;
  color: #fff;
}
.angularjs-datetime-picker > .adp-days .adp-day.today {
  background-color: #02ddd6;
  color: #fff;
}
.angularjs-datetime-picker > .adp-days:after {
  content: "";
  display: block;
  clear: left;
  height: 0;
}
.angularjs-datetime-picker hr {
  border-top: 1px solid #d8dce6;
  border-bottom: none;
}
.angularjs-datetime-picker input[type=range] {
  width: 150px;
}
.angularjs-datetime-picker .btn-cf {
  height: 40px;
  width: 267px;
  border-radius: 4px;
  background-color: #00A19C;
  color: #fff;
  margin: 0 16px 16px;
}

.adp-time {
  padding: 9.5px 13px 9.5px 16px;
}
.adp-time .start-time {
  display: flex;
  margin-bottom: 4px;
}
.adp-time .end-time {
  display: flex;
  margin-bottom: 4px;
}

.time-label {
  width: 100px;
  color: #9EA0A5;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 12px;
  padding-top: 9px;
}

.datepicker-dropdown-select {
  position: relative;
  display: inline-block;
}
.datepicker-dropdown-select .btn-select {
  position: relative;
  box-sizing: border-box;
  height: 36px;
  width: 55px;
  border: 1px solid #D8DCE6;
  border-radius: 4px;
  background-color: #FFFFFF;
}
.datepicker-dropdown-select .btn-select-text {
  text-align: right;
  padding-right: 20px;
  font-size: 12px;
  color: #3E3F42;
}
.datepicker-dropdown-select .btn-select-icon img {
  top: 11px;
  position: absolute;
  right: 6px;
}
.datepicker-dropdown-select .datepicker-dropdown-menu {
  display: none;
  position: absolute;
  border: 1px solid #d8dce6;
  border-radius: 4px;
  width: 125%;
  background: #fff;
  bottom: 0px;
  padding-left: 0;
  z-index: 1000;
  max-height: 140px;
  overflow: auto;
}
.datepicker-dropdown-select .datepicker-dropdown-menu .time-item {
  height: 27px;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 16px;
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;
}
.datepicker-dropdown-select .datepicker-dropdown-menu .time-item:last-child {
  border-bottom: none;
}
.datepicker-dropdown-select .datepicker-dropdown-menu .time-item:hover {
  background-color: #00a19c;
  color: #fff;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
}
.datepicker-dropdown-select .datepicker-dropdown-menu.show {
  display: block;
}

.time-container {
  display: flex;
}
.time-container .time-period {
  margin-left: 8px;
}

.my-loading .loader {
  height: 0;
  padding: 0;
  margin: 0;
}

.title-minutes {
  height: 112px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.title-minutes h1 {
  margin: 0 5%;
  height: 32px;
  color: #000000;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 112px;
}

#discussionAttachment {
  padding: 8px;
}
#discussionAttachment .header {
  margin-bottom: 24px;
}
#discussionAttachment .header .index {
  margin-right: 16px;
}
#discussionAttachment .header .title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}
#discussionAttachment .attachment {
  margin: 0 8px 16px;
  background: white;
  box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.11), 0 0 1px rgba(0, 0, 0, 0.11), 0px 1px 2px rgba(0, 0, 0, 0.22);
}
#discussionAttachment .attachment a {
  padding: 12px 8px;
}
#discussionAttachment .attachment md-icon {
  margin: 0 8px 0 0;
}
#discussionAttachment .topic::-webkit-scrollbar {
  width: 4px;
}
#discussionAttachment .topic::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.38);
}

.comment-area .comment-header {
  color: #00a19c;
}
.comment-area .comment-box {
  border: 1px solid rgba(0, 0, 0, 0.12);
  min-height: 48px;
  padding: 8px 16px 16px;
  background: #f6f7f8;
}
.comment-area .comment-box .comment-item {
  padding: 8px 0;
}
.comment-area .comment-box .comment-item span {
  background-color: transparent !important;
}
.comment-area .comment-box .comment-item .comment-tagging {
  font-weight: bold;
}
.comment-area .comment-box .comment-item img.avatar-img {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-top: 5px;
  margin-right: 8px;
  background-color: #00a19c;
  border-radius: 50%;
}
.comment-area .comment-box .comment-item .comment-content {
  width: 100%;
}
.comment-area .comment-box .comment-item .comment-content .commentator {
  height: 17px;
  color: #6d7278;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
}
.comment-area .comment-box .comment-item .comment-content .message {
  color: rgba(0, 0, 0, 0.85);
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  letter-spacing: 0;
  word-break: break-word;
}
.comment-area .comment-box .comment-item .comment-content .date {
  float: right;
  color: rgba(0, 0, 0, 0.54);
  font-size: 1.2rem;
}
.comment-area .comment-box .comment-action {
  margin-top: 8px;
  padding: 4px 4px 44px 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: white;
  position: relative;
}
.comment-area .comment-box .comment-action .control-box {
  position: absolute;
  bottom: 0px;
  right: 0px;
  left: 0px;
  height: 41px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  background-color: #f6f7f8;
}
.comment-area .comment-box .comment-action .control-box .btnSend {
  position: absolute;
  bottom: 4px;
  right: 4px;
  box-sizing: border-box;
  height: 43px;
  width: 125px;
  border: 1px solid #00a19c;
  border-radius: 4px;
  background-color: #ffffff;
  color: #00a19c;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
}
.comment-area .comment-box .comment-action .control-box .btnSend:hover {
  cursor: pointer;
}
.comment-area .comment-box .comment-action .control-box .btnSend:disabled {
  color: #b3e3e1;
  border-color: #b3e3e1;
}
.comment-area .comment-box .comment-action .control-box .btnSend span {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
}
.comment-area .comment-box .comment-action .comment-input {
  min-height: 100px;
  resize: none;
  width: 100%;
  padding: 8px;
  font-size: 14px;
}
.comment-area .comment-box .comment-emptycomment {
  color: #999999;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 13px;
  letter-spacing: 0;
  line-height: 22px;
}

@media (max-width: 959px) {
  .comment-area {
    display: block;
  }
}
.user-search {
  border-radius: 6px;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
  outline: 0;
}

.user-search:hover {
  cursor: pointer;
}

.user-search .list-group-item.active {
  color: #fff;
  background-color: #428bca;
  border-color: #428bca;
}

.user-search .list-group-item.active .text-muted {
  color: #ccc;
}

.user-search .list-group-item.active .text-primary {
  color: #fff;
}

.user-search .list-group-item {
  padding: 5px;
}

.user-search .user-photo {
  max-width: 30px;
  max-height: 30px;
  border-radius: 15px;
}

.section-heading {
  margin-top: 30px;
}

.list-group {
  margin-bottom: 20px;
  padding-left: 0;
}

.list-group-item {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #ddd;
}
.list-group-item span {
  padding-left: 5px;
  vertical-align: middle;
}
.list-group-item .menu-highlighted {
  padding-left: 0px !important;
  vertical-align: bottom;
  font-weight: bold;
}

.list-group-item:first-child {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.list-group-item:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.comment-tagging {
  background-color: #eee;
}

.email {
  display: none;
}

.empty-photo {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-top: 5px;
  margin-right: 8px;
  background-color: #00a19c;
  border-radius: 50%;
  color: white;
  padding-top: 3px;
  text-align: center;
  font-weight: bold;
}

img.emoji {
  height: 20px;
  widows: 20px;
}

#discussionItem .discussion-item {
  position: absolute;
  width: 100%;
  height: 50%;
  max-height: 40%;
  padding: 8px;
}
#discussionItem .discussion-item .header {
  align-items: center;
}
#discussionItem .discussion-item .header .index {
  margin-right: 16px;
}
#discussionItem .discussion-item .header .title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}
#discussionItem .discussion-item .header .type {
  padding: 0 4px;
  margin-right: 16px;
  border: 2px solid #fdb924;
  border-radius: 2px;
  color: #fdb924;
}
#discussionItem .discussion-item .header .assigned {
  text-align: right;
}
#discussionItem .discussion-item .header .assigned .assignee {
  line-height: 24px;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#discussionItem .discussion-item .header .assigned .due-date {
  color: rgba(0, 0, 0, 0.54);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#discussionItem .discussion-item .header img {
  width: 36px;
  height: 36px;
  margin-left: 8px;
  background-color: #00a19c;
  border-radius: 50%;
}
#discussionItem .discussion-item .topic {
  overflow: auto;
  max-height: calc(100% - 64px);
  padding: 0 8px 8px;
  margin: 16px 0 8px;
}
#discussionItem .discussion-item .topic .attachment {
  padding: 4px;
  margin-bottom: 8px;
  background: white;
  box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.11), 0 0 1px rgba(0, 0, 0, 0.11), 0px 1px 2px rgba(0, 0, 0, 0.22);
}
#discussionItem .discussion-item .topic .attachment md-icon {
  margin: 0 4px 0 0;
}
#discussionItem .discussion-item .topic::-webkit-scrollbar {
  width: 4px;
}
#discussionItem .discussion-item .topic::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.38);
}
#discussionItem .comment-area {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60%;
}
#discussionItem .comment-area .comment-header {
  padding: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
#discussionItem .comment-area .comment-box {
  overflow: auto;
  width: 100%;
  height: calc(100% - 90px);
  padding: 8px;
}
#discussionItem .comment-area .comment-box .comment-item {
  padding: 8px 0;
}
#discussionItem .comment-area .comment-box .comment-item img {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  background-color: #00a19c;
  border-radius: 50%;
}
#discussionItem .comment-area .comment-box .comment-item .comment-content .commentator {
  margin-right: 8px;
  color: #00a19c;
}
#discussionItem .comment-area .comment-box::-webkit-scrollbar {
  width: 4px;
}
#discussionItem .comment-area .comment-box::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.38);
}
#discussionItem .comment-area .comment-action {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  align-items: center;
  background: white;
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.13), 0 0 3px rgba(0, 0, 0, 0.13), 0px 3px 6px rgba(0, 0, 0, 0.26);
}
#discussionItem .comment-area .comment-action .comment-input {
  resize: none;
  width: 100%;
  padding: 16px 8px;
}

#create-minutes-at-draft-mode-confirm {
  width: 576px;
  height: auto;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
}
#create-minutes-at-draft-mode-confirm md-dialog-content {
  padding: 36px 28px 30px 36px;
  height: 100%;
}
#create-minutes-at-draft-mode-confirm .title-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
#create-minutes-at-draft-mode-confirm .title-container .icon-container {
  height: 48px;
  width: 48px;
  background-color: rgba(0, 161, 156, 0.2);
  border-radius: 50%;
  position: relative;
}
#create-minutes-at-draft-mode-confirm .title-container .icon-container .img {
  object-fit: contain;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#create-minutes-at-draft-mode-confirm .title-container .title {
  height: 26px;
  color: #000000;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 26px;
  margin-left: 24px;
}
#create-minutes-at-draft-mode-confirm .contentArea {
  margin: 20px 0 30px;
}
#create-minutes-at-draft-mode-confirm .contentArea .content {
  height: 22px;
  color: rgba(0, 0, 0, 0.75);
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
}
#create-minutes-at-draft-mode-confirm .btn-close {
  float: right;
  margin-top: 5px;
  margin-right: 5px;
  color: #9E9E9E;
}
#create-minutes-at-draft-mode-confirm .btn-border-gray {
  box-sizing: border-box;
  height: 42px;
  width: 122px;
  border: 1px solid #E2E5ED;
  border-radius: 4px;
  box-shadow: none;
  color: #787878;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  text-transform: none;
}
#create-minutes-at-draft-mode-confirm .btn-cancel {
  background-color: #fff !important;
}
#create-minutes-at-draft-mode-confirm .btn-send, #create-minutes-at-draft-mode-confirm .btn-danger {
  height: 40px;
  width: 120px;
  border-radius: 4px;
  text-transform: none;
}

#detail-draf-confirm {
  height: auto;
  width: 576px;
}
#detail-draf-confirm md-dialog-content {
  padding: 36px 28px 30px 36px;
  height: 100%;
}
#detail-draf-confirm .title-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
#detail-draf-confirm .title-container .title {
  height: 26px;
  color: #000000;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 26px;
}
#detail-draf-confirm .title-container .img {
  object-fit: contain;
  margin-right: 24px;
}
#detail-draf-confirm .contentArea {
  margin: 20px 0 30px;
}
#detail-draf-confirm .contentArea .content {
  height: 22px;
  color: rgba(0, 0, 0, 0.75);
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
}
#detail-draf-confirm .btn-close {
  float: right;
  margin-top: 5px;
  margin-right: 5px;
  color: #9E9E9E;
}
#detail-draf-confirm .btn-border-gray {
  box-sizing: border-box;
  height: 42px;
  width: 122px;
  border: 1px solid #E2E5ED;
  border-radius: 4px;
  box-shadow: none;
  color: #787878;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  text-transform: none;
}
#detail-draf-confirm .btn-send, #detail-draf-confirm .btn-danger {
  width: 160px;
  height: 40px;
  text-transform: none;
}

#message-dialog {
  height: auto;
  width: 576px;
}
#message-dialog md-dialog-content {
  padding: 36px 28px 0 36px;
  height: 100%;
}
#message-dialog .title-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
#message-dialog .title-container .title {
  height: 26px;
  color: #000000;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 26px;
}
#message-dialog .title-container .img {
  object-fit: contain;
  margin-right: 24px;
}
#message-dialog .contentArea {
  margin: 20px 0 30px;
}
#message-dialog .contentArea .content {
  height: 22px;
  color: rgba(0, 0, 0, 0.75);
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
}
#message-dialog .btn-close {
  float: right;
  margin-top: 5px;
  margin-right: 5px;
  color: #9E9E9E;
}
#message-dialog .btn-send {
  height: 40px;
  width: 120px;
  margin-right: 28px;
  margin-bottom: 28px;
}
#message-dialog .icon-close-circle {
  line-height: 48px;
  margin-left: 0px;
  margin-right: 20px;
}

.send-tab-log-container md-toolbar.md-default-theme:not(.md-menu-toolbar),
.send-tab-log-container md-toolbar:not(.md-menu-toolbar) {
  background-color: transparent !important;
  color: rgba(255, 255, 255, 0.87);
}
.send-tab-log-container form {
  background-color: white !important;
}
.send-tab-log-container .send-tab-log-toolbar {
  margin-top: 30px;
}
.send-tab-log-container .send-tab-log-toolbar .title {
  height: 26px;
  color: #000000;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 26px;
}
.send-tab-log-container .send-tab-log-toolbar .send-tab-icon-log {
  margin: 32px;
}
.send-tab-log-container .send-tab-log-toolbar .md-icon-button {
  margin-right: 32px;
}
.send-tab-log-container .first-row {
  height: 44px;
  border-radius: 4px 4px 0 0;
  background-color: #eeeeee;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 14px;
}
.send-tab-log-container #send-tab-log-table {
  overflow-x: auto;
}
.send-tab-log-container #send-tab-log-table table {
  border-collapse: collapse;
  width: 960px;
  table-layout: fixed;
}
.send-tab-log-container #send-tab-log-table th,
.send-tab-log-container #send-tab-log-table td {
  text-align: left;
  padding: 20px;
  color: rgba(0, 0, 0, 0.75);
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 11px !important;
}
.send-tab-log-container #send-tab-log-table tr {
  border: 1px solid #eaedf3;
}
.send-tab-log-container #send-tab-log-table th {
  height: 44px;
  border-radius: 4px 4px 0 0;
  background-color: #eeeeee;
  box-shadow: 0px 1px 0px 1px #eaedf3;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 14px;
  color: #9EA0A5 !important;
  text-transform: uppercase;
}

md-virtual-repeat-container {
  width: 300px;
  min-height: 75px;
  max-height: 200px;
}
md-virtual-repeat-container .list-participants {
  box-sizing: border-box;
  width: 305px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
md-virtual-repeat-container .list-participants li {
  height: auto;
}
md-virtual-repeat-container .list-participants li .container {
  padding: 8px 0;
}
md-virtual-repeat-container .list-participants li .container .username-ap {
  height: 16px;
  color: #000000;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 16px;
}
md-virtual-repeat-container .list-participants li .container .email-ap {
  height: 15px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
}
md-virtual-repeat-container .list-participants li .container .avatar-wrapper {
  margin-left: 10px;
  margin-right: 5px;
  position: relative;
}
md-virtual-repeat-container .list-participants li .container .avatar-wrapper img {
  width: 24px;
  height: 24px;
}
md-virtual-repeat-container .list-participants li .container .avatar-wrapper .status {
  position: absolute;
  bottom: -3px;
  right: 5px;
}
md-virtual-repeat-container .list-participants li .container .avatar-wrapper .image {
  width: 24px;
  min-width: 24px;
  max-width: 24px;
  line-height: 24px;
  margin: 0;
  border-radius: 50%;
  text-align: center;
  color: white;
  background-color: #402b53;
}
md-virtual-repeat-container .list-participants li .container .avatar-wrapper .avatar-ap {
  width: 24px;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
}
md-virtual-repeat-container .list-participants::before {
  content: "Suggestions";
  color: #9ea0a5;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 14px;
  text-transform: uppercase;
  padding: 8px 207px 4px 13px;
  display: block;
}

#add-participants {
  padding: 60px;
  min-height: 91%;
}
#add-participants .icon-add-user {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 11px;
  left: 11px;
}
#add-participants .clear-all {
  position: absolute;
  right: 20px;
  top: 10px;
  border: none;
  background-color: transparent;
  color: #999999;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 20px;
}
#add-participants .icon-user {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translate(0, -50%);
}
#add-participants .avatar-wrapper {
  position: relative;
}
#add-participants .avatar-wrapper img {
  width: 24px;
  height: 24px;
}
#add-participants .avatar-wrapper .status {
  position: absolute;
  bottom: -3px;
  right: 5px;
}
#add-participants .avatar-wrapper .image {
  width: 24px;
  min-width: 24px;
  max-width: 24px;
  line-height: 24px;
  margin: 0;
  border-radius: 50%;
  text-align: center;
  color: white;
  background-color: #402b53;
}
#add-participants .avatar-wrapper .avatar-ap {
  width: 24px;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
}
#add-participants .close-icon {
  margin-top: 8px;
}
#add-participants .add-btn {
  color: #00a19c;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 13px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  box-sizing: border-box;
  height: 40px;
  width: 66px;
  border: 1px solid #00a19c;
  border-radius: 4px;
  background-color: #ffffff;
  margin-left: 5px;
}
#add-participants .add-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
#add-participants md-chips.md-default-theme .md-chips,
#add-participants md-chips .md-chips {
  box-shadow: 0 0px rgba(0, 0, 0, 0.12);
  padding-left: 40px;
  padding-right: 70px;
  padding-bottom: 4px;
  box-sizing: border-box;
  min-height: 40px;
  max-height: 114px;
  border: 1px solid #cbcbcb;
  border-radius: 4px;
  background: #ffffff;
  overflow-x: auto;
}
#add-participants md-chips.md-default-theme md-chip,
#add-participants md-chips md-chip {
  min-height: 32px;
  border-radius: 4px;
  margin-top: 4px;
  background: #eee;
}
#add-participants md-chips.md-default-theme md-chip .username-ap,
#add-participants md-chips md-chip .username-ap {
  margin-left: 5px;
  font-size: 13px;
  color: #000000;
  letter-spacing: 0;
  line-height: 16px;
}
#add-participants md-chips md-chip-template {
  height: 32px;
}
#add-participants md-input-container {
  margin: 0;
  padding: 0;
  position: relative;
}
#add-participants md-input-container .md-chip-input-container {
  min-height: 32px;
  margin-top: 4px;
}
#add-participants md-input-container label {
  display: none;
}
#add-participants .md-chips {
  padding: 0 0 0 3px;
}
#add-participants .lop {
  height: 19px;
  color: #000000;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  margin-top: 24px;
  margin-bottom: 24px;
}
#add-participants .lop .minute-no {
  height: 15px;
  width: 163px;
  color: #999999;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  text-align: right;
  float: right;
  font-weight: 400;
}
#add-participants .btn-remove-user {
  margin-top: 5px;
  margin-right: 2px;
}
#add-participants .btn-remove-user .icon-window-close {
  font-size: 20px;
  color: #9e9e9e;
}
#add-participants md-autocomplete-wrap input::-webkit-input-placeholder {
  color: #999999;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 13px;
  letter-spacing: 0;
  line-height: 16px;
}

@media (min-width: 960px) {
  .md-padding {
    padding: 16px 0 0 0;
  }
}
.table-members {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #eee;
  box-shadow: 0 1px 0 0 #eaedf3;
  background-color: #fff;
}
.table-members .name {
  padding-left: 30px;
}
.table-members .name .container .username-list {
  color: #3e3f42;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 22px;
}
.table-members .name .container .email-list {
  color: #9ea0a5;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
}
.table-members .name .container .avatar-wrapper {
  margin-right: 10px;
  position: relative;
}
.table-members .name .container .avatar-wrapper img {
  width: 36px;
  height: 36px;
}
.table-members .name .container .avatar-wrapper .status {
  position: absolute;
  bottom: -3px;
  right: 5px;
}
.table-members .name .container .avatar-wrapper .image {
  width: 36px !important;
  min-width: 36px !important;
  max-width: 36px !important;
  line-height: 36px !important;
  margin: 0;
  border-radius: 50%;
  text-align: center;
  color: white;
  background-color: #402b53;
}
.table-members .name .container .avatar-wrapper .avatar-list {
  width: 36px;
  min-width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 50%;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
}
.table-members .attendance {
  width: 10%;
}
.table-members .role {
  width: 17%;
}
.table-members .view {
  width: 7%;
}
.table-members .edit {
  width: 8%;
}
.table-members .comment {
  width: 7%;
}
.table-members .download {
  width: 8.5%;
}
.table-members .remove {
  width: 4%;
  padding-right: 30px;
}
.table-members th {
  padding: 15px 0px;
  text-align: left;
  background-color: #ffffff;
  color: #9ea0a5;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 14px;
  text-transform: uppercase;
  border-bottom: 1px solid #eee;
}
.table-members td {
  text-align: left;
  padding: 18px 0px;
}
.table-members td .switch {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 38px;
}
.table-members td .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.table-members td .switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #eaedf3;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.table-members td .switch .slider.round {
  border-radius: 10px;
}
.table-members td .switch .slider.round:before {
  border-radius: 50%;
}
.table-members td .switch .slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
}
.table-members td .switch input:checked + .slider {
  background-color: #00a19c;
}
.table-members td .switch input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}
.table-members td .switch input:disabled:checked + .slider {
  cursor: not-allowed;
  background-color: #b3e3e1;
}
.table-members td .switch input:disabled + .slider {
  cursor: not-allowed;
}
.table-members td.remove {
  text-align: center;
}
.table-members td.remove .close {
  height: 15.43px;
  width: 15.43px;
  border-radius: 8px;
  background-color: #6d7278;
  text-align: center;
  color: white;
  position: relative;
}
.table-members td.remove .close:hover {
  color: white;
  background-color: red;
}
.table-members td.remove .icon-window-close {
  font-size: 9px;
  position: absolute;
  top: 4px;
  left: -4.3px;
}
.table-members td.role .dropdown-role-select {
  position: relative;
  display: inline-block;
}
.table-members td.role .dropdown-role-select .btn-select {
  box-sizing: border-box;
  height: 38px;
  width: 156px;
  border: 1px solid #d8dce6;
  border-radius: 4px;
  background-color: #ffffff;
  display: flex;
  padding: 7px 14px 7px 16px;
}
.table-members td.role .dropdown-role-select .btn-select .btn-select-text {
  height: 22px;
  width: 114px;
  color: #3e3f42;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  text-align: left;
}
.table-members td.role .dropdown-role-select .btn-select .btn-select-icon {
  height: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: auto 0;
}
.table-members td.role .dropdown-role-select .btn-select .btn-select-icon .icon-triangle-up {
  border-bottom: 3px solid #9ea0a5;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
}
.table-members td.role .dropdown-role-select .btn-select .btn-select-icon .icon-triangle-down {
  border-top: 3px solid #9ea0a5;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
}
.table-members td.role .dropdown-role-select .btn-select:disabled {
  cursor: not-allowed;
}
.table-members td.role .dropdown-role-select .btn-select:disabled .btn-select-text {
  color: rgba(0, 0, 0, 0.25);
}
.table-members td.role .dropdown-role-select .dropdown-menu {
  display: none;
  position: absolute;
  border: 1px solid #d8dce6;
  border-radius: 4px;
  width: 100%;
  background: #fff;
  top: 39px;
  padding-left: 0px;
  z-index: 1000;
}
.table-members td.role .dropdown-role-select .dropdown-menu .role-item {
  padding: 12px 16px 13px 16px;
  height: 41px;
  border-bottom: 1px solid #d8dce6;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 16px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  cursor: default;
}
.table-members td.role .dropdown-role-select .dropdown-menu .role-item:last-child {
  border-bottom: none;
}
.table-members td.role .dropdown-role-select .dropdown-menu .role-item:hover {
  background-color: #00a19c;
  color: #fff;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
}
.table-members td.role .dropdown-role-select .dropdown-menu.show {
  display: block;
}
.table-members tr {
  border-bottom: solid 1px #eaedf3;
}

#bar-buttons .send-minute .send-area span {
  color: #ffffff;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
}
#bar-buttons .send-minute .send-area .next-icon {
  position: relative;
  top: 4px;
  margin-left: 14px;
}
#bar-buttons .send-minute .send-area .saving-area {
  font-size: 14px;
}
#bar-buttons .btn-cancel {
  height: 42px;
  width: 138px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
}

#preview .review-view {
  margin: 35px 60px 47px 60px;
}
#preview .review-view .header {
  color: #000000;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
}
#preview .review-view .header-detailinfo,
#preview .review-view .review-attachments,
#preview .review-view .review-agenda,
#preview .review-view .review-discussion {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #EAEDF3;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
  margin-bottom: 20px;
}
#preview .review-view .header-detailinfo {
  height: auto;
  padding: 44px 60px 60px 50px;
}
#preview .review-view .header-detailinfo .header-logo {
  margin-bottom: 25px;
  margin-left: 5px;
}
#preview .review-view .header-detailinfo .header-detail {
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
  margin-bottom: 32px;
}
#preview .review-view .header-detailinfo .header-detail .header-left {
  width: 86%;
}
#preview .review-view .header-detailinfo .header-detail .header-left .header-title {
  margin-bottom: 10px;
}
#preview .review-view .header-detailinfo .header-detail .header-left .header-title span {
  color: #000000;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 30px;
  letter-spacing: 0;
  line-height: 36px;
}
#preview .review-view .header-detailinfo .header-detail .header-left .minutes-tags {
  padding-left: 2px;
}
#preview .review-view .header-detailinfo .header-detail .header-left .minutes-tags .tag {
  display: inline-block;
  padding: 4px 8px;
  height: 24px;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
  background-color: #FFFFFF;
  color: rgba(0, 0, 0, 0.5);
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 10px;
}
#preview .review-view .header-detailinfo .header-detail .header-left .minutes-tags .tag:not(:last-child) {
  margin-right: 11px;
}
#preview .review-view .header-detailinfo .header-detail .header-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  width: 180px;
}
#preview .review-view .header-detailinfo .header-detail .header-right .minutes-classification {
  color: #3E3F42;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  text-align: right;
  margin-bottom: 6px;
}
#preview .review-view .header-detailinfo .header-detail .header-right .minutes-status {
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  text-align: right;
  margin-bottom: 8px;
}
#preview .review-view .header-detailinfo .header-detail .header-right .minutes-no {
  color: #3E3F42;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  text-align: right;
}
#preview .review-view .header-detailinfo .detail-info {
  padding-left: 10px;
  margin-right: 180px;
}
#preview .review-view .header-detailinfo .detail-info .meeting-detail-item {
  display: table-row;
}
#preview .review-view .header-detailinfo .detail-info .meeting-detail-item .detail-title {
  display: table-cell;
  padding: 5px 24px 5px 8px;
  color: #9EA0A5;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  width: 146px;
}
#preview .review-view .header-detailinfo .detail-info .meeting-detail-item .detail-content, #preview .review-view .header-detailinfo .detail-info .meeting-detail-item .detail-content .displayname {
  color: #3E3F42;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
}
#preview .review-view .header-detailinfo .detail-info .meeting-detail-item .detail-content .displayname {
  display: inline-block;
}
#preview .review-view .review-attachments {
  height: 162px;
  padding: 40px 28px 40px 60px;
}
#preview .review-view .review-attachments .attachment-container {
  align-self: flex-start;
  align-items: center;
  overflow-x: auto;
  margin-top: 12px;
  padding-bottom: 10px;
}
#preview .review-view .review-attachments .attachment-container::-webkit-scrollbar,
#preview .review-view .review-attachments .attachment-container .md-virtual-repeat-scroller::-webkit-scrollbar {
  height: 8px;
}
#preview .review-view .review-attachments .attachment-container::-webkit-scrollbar-thumb,
#preview .review-view .review-attachments .attachment-container .md-virtual-repeat-scroller::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}
#preview .review-view .review-attachments .attachment-container .attachment:last-of-type {
  padding: 0;
}
#preview .review-view .review-attachments .attachment-container .attachment {
  box-sizing: border-box;
  height: 50px;
  width: 202px;
  min-width: 202px;
  border: 1px solid #d8dce6;
  border-radius: 4px;
  background-color: #ffffff;
  margin-right: 16px;
  position: relative;
}
#preview .review-view .review-attachments .attachment-container .attachment .file-icon {
  height: 24px;
  width: 24px;
  margin: 13px 4px 13px 15px;
  font-size: 24px;
  line-height: 24px;
}
#preview .review-view .review-attachments .attachment-container .attachment .delete-icon {
  width: 10px;
  height: 10px;
  right: 9px;
  top: 17px;
  position: absolute;
}
#preview .review-view .review-attachments .attachment-container .attachment .delete-icon:hover {
  cursor: pointer;
}
#preview .review-view .review-attachments .attachment-container .attachment .delete-icon i {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.3);
  font-weight: bolder;
}
#preview .review-view .review-attachments .attachment-container .attachment .file-name {
  height: 15px;
  color: #000000;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  width: 156px;
  padding-right: 8px;
  display: inline !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word !important;
  white-space: nowrap;
}
#preview .review-view .review-attachments .attachment-container .attachment a {
  text-decoration: none;
}
#preview .review-view .review-agenda {
  padding: 40px 60px;
  height: auto;
}
#preview .review-view .review-agenda .agenda-content p {
  margin-top: 12px;
  color: rgba(0, 0, 0, 0.75);
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
}
#preview .review-view .review-discussion .preview-checkbox {
  margin-top: 3px;
}
#preview .review-view .review-discussion .header-container {
  padding: 40px 60px 24px 60px;
}
#preview .review-view .review-discussion .header-container .header {
  padding-top: 1px;
}
#preview .review-view .review-discussion .header-container .preview-checkbox {
  margin-right: 8px;
}
#preview .review-view .review-discussion .discussion-content .discussion-point {
  border-top: 1px solid #D8DCE6;
  padding: 20px 60px 40px 60px;
}
#preview .review-view .review-discussion .discussion-content .discussion-point .point-title {
  color: #00A19C;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 12px;
  margin-left: 8px;
}
#preview .review-view .review-discussion .discussion-content .discussion-point .discussion-description-preview p {
  color: rgba(0, 0, 0, 0.75);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  margin-left: 8px;
  margin-bottom: 16px;
}
#preview .review-view .review-discussion .discussion-content .discussion-point .point-file {
  margin-bottom: 24px;
}
#preview .review-view .review-discussion .discussion-content .discussion-point .topic-attachment-container {
  overflow-y: hidden;
  overflow-x: auto;
  padding-bottom: 10px;
  margin-bottom: 24px;
  margin-left: 24px;
}
#preview .review-view .review-discussion .discussion-content .discussion-point .topic-attachment-container::-webkit-scrollbar {
  height: 8px;
}
#preview .review-view .review-discussion .discussion-content .discussion-point .topic-attachment-container::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}
#preview .review-view .review-discussion .discussion-content .discussion-point .topic-attachment-container .topic-attachment {
  box-sizing: border-box;
  height: 50px;
  width: 202px;
  min-width: 202px;
  border: 1px solid #d8dce6;
  border-radius: 4px;
  background-color: #ffffff;
  margin-right: 16px;
  position: relative;
}
#preview .review-view .review-discussion .discussion-content .discussion-point .topic-attachment-container .topic-attachment .file-icon {
  height: 24px;
  width: 24px;
  margin: 13px 4px 13px 15px;
  font-size: 24px;
  line-height: 24px;
}
#preview .review-view .review-discussion .discussion-content .discussion-point .topic-attachment-container .topic-attachment .delete-icon {
  width: 10px;
  height: 10px;
  right: 9px;
  top: 17px;
  position: absolute;
}
#preview .review-view .review-discussion .discussion-content .discussion-point .topic-attachment-container .topic-attachment .delete-icon:hover {
  cursor: pointer;
}
#preview .review-view .review-discussion .discussion-content .discussion-point .topic-attachment-container .topic-attachment .delete-icon i {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.3);
  font-weight: bolder;
}
#preview .review-view .review-discussion .discussion-content .discussion-point .topic-attachment-container .topic-attachment .file-name {
  height: 15px;
  color: #000000;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  width: 156px;
  padding-right: 8px;
  display: inline !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word !important;
  white-space: nowrap;
}
#preview .review-view .review-discussion .discussion-content .discussion-point .topic-attachment-container .topic-attachment a {
  text-decoration: none;
}
#preview .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table {
  border-collapse: collapse;
  width: 100%;
  border-spacing: 5px;
}
#preview .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table thead tr {
  border-bottom: 1px solid #D8DCE6;
}
#preview .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table tr:not(:last-child) {
  border-bottom: 1px solid #D8DCE6;
}
#preview .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table th {
  color: #6B6C6F;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: left;
  padding: 0 10px 16px 0px;
  font-weight: 400;
}
#preview .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table td {
  padding: 16px 20px 16px 0px;
}
#preview .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table td, #preview .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table .topic-no, #preview .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table .topic-name p,
#preview .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table .discussionItem-actionType,
#preview .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table .discussionItem-displayName,
#preview .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table .discussionItem-duedate {
  margin: 0;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  vertical-align: top;
  color: rgba(0, 0, 0, 0.75);
}
#preview .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table .topic-name p {
  text-align: justify;
}
#preview .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table .topic-no {
  margin-left: 12px;
  margin-right: 20px;
  color: #6B6C6F;
}
#preview .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table #topic {
  width: 50%;
  padding-left: 24px;
}
#preview .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table #type {
  width: 10%;
}
#preview .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table #assignedto {
  width: 20%;
}
#preview .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table #duedate {
  width: 20%;
}
#preview .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table .topic-content {
  display: flex;
}
@media all and (max-width: 1920px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #preview .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table .topic-content {
    width: 650px;
  }
}
@media all and (max-width: 1600px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #preview .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table .topic-content {
    width: 500px;
  }
}
@media all and (max-width: 1366px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #preview .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table .topic-content {
    width: 450px;
  }
}
@media all and (max-width: 1024px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #preview .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table .topic-content {
    width: 350px;
  }
}
@media all and (max-width: 768px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #preview .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table .topic-content {
    width: 300px;
  }
}

#extractBar {
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  background-color: #000 !important;
}
#extractBar .selected {
  padding-left: 8px;
}

#extractBar.ng-hide {
  opacity: 0;
}

#extractBar.ng-hide-add,
#extractBar.ng-hide-remove {
  transition: all ease-out 0.15s;
}

#bar-buttons .btn-edit {
  height: 42px;
  width: 138px;
}
#bar-buttons .btn-download, #bar-buttons .btn-email {
  height: 42px;
  width: 159px;
}
#bar-buttons .btn-download span, #bar-buttons .btn-email span, #bar-buttons .btn-edit span, #bar-buttons .btn-log span {
  color: #787878;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
}
#bar-buttons .btn-download {
  margin-right: 12px;
}
#bar-buttons .send-minute {
  position: relative;
}
#bar-buttons .send-minute .btn-send:focus + .send-list {
  display: block;
}
#bar-buttons .send-minute .send-area .send-icon {
  top: 1px;
  margin-left: 14px;
}
#bar-buttons .send-minute .send-list {
  position: absolute;
  display: none;
  width: 229px;
  height: 252px;
  box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  background-color: white;
  top: -252px;
  right: 0px;
  z-index: 1;
}
#bar-buttons .send-minute .send-list .send-option {
  width: 229px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
  color: rgba(0, 0, 0, 0.85);
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  cursor: pointer;
}
#bar-buttons .send-minute .send-list .send-option .img-icon {
  margin-right: 8px;
}
#bar-buttons .send-minute .send-list .send-option .option-description {
  height: 28px;
  width: 158px;
  color: #6D7278;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 10px;
  letter-spacing: 0;
  line-height: 14px;
  font-weight: normal;
}
#bar-buttons .send-minute .send-list .send-option[disabled] {
  opacity: 0.3;
  cursor: not-allowed;
}
#bar-buttons .send-minute .send-list .send-option:hover {
  background-color: #E0F4F3;
}
#bar-buttons .send-minute .send-list .send-option:focus {
  background-color: #B3E3E1;
}
#bar-buttons .send-minute .send-list:hover {
  display: block;
}

.send-title {
  font-weight: bold;
  padding: 30px 0 50px 0;
  padding-left: 60px;
  background-color: white;
  margin-bottom: 2px;
  margin-top: 0;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
}

.has-status-pending-approval {
  color: #fdb924 !important;
}

.has-status-approved {
  color: #4caf50 !important;
}

.has-status-pending-review {
  color: #0b69ff !important;
}

.has-status-sent {
  color: #4caf50 !important;
}

.send-comment-count {
  color: rgba(0, 0, 0, 0.75);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  text-transform: none;
}

.container-icon-comment {
  position: relative;
}
.container-icon-comment .new-comment {
  position: absolute;
  top: -10px;
  right: -10px;
  height: 23px;
  width: 23px;
  background-color: #00a19c;
  border-radius: 50%;
  color: #ffffff;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center;
}

.send-btn-comment-child-item,
.send-btn-comment-item {
  margin: 0;
  padding-top: 10px !important;
  position: relative;
  top: -17px;
  height: 60px;
}

.send-btn-comment-child-item {
  margin-right: -18px;
}

.send-btn-sec-edit {
  box-sizing: border-box;
  height: 42px;
  width: 158px;
  border: 1px solid #e2e5ed;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  margin: 0 5px;
}

.send-btn-sec-resend-noti {
  box-sizing: border-box;
  height: 42px;
  width: 190px;
  border: 1px solid #e2e5ed;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  margin: 0 5px;
}

.send-btn-sec-send-approval {
  height: 42px;
  width: 192px;
  border-radius: 4px;
  background-color: #00a19c;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  margin: 0 5px;
}

.send .review-view {
  margin: 24px 60px 47px 60px;
}
.send .review-view .action-btn {
  margin-bottom: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.send .review-view .action-btn .btn {
  padding: 7px 18px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
}
.send .review-view .action-btn .btn .png-icon {
  margin: 0px;
}
.send .review-view .header {
  color: #000000;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
}
.send .review-view .header-detailinfo,
.send .review-view .review-attachments,
.send .review-view .review-agenda,
.send .review-view .review-discussion {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #eaedf3;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
  margin-bottom: 20px;
}
.send .review-view .no-access-modal {
  height: 218px;
  width: 576px;
  padding: 36px 28px 30px 36px;
  margin: 52px auto;
  box-sizing: border-box;
  border: 1px solid #eaedf3;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
}
.send .review-view .no-access-modal .title-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.send .review-view .no-access-modal .title-container .title {
  margin-left: 24px;
  height: 26px;
  color: #000000;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 26px;
}
.send .review-view .no-access-modal .title-container .img {
  object-fit: contain;
}
.send .review-view .no-access-modal .contentArea {
  width: 504px;
  margin-top: 24px;
}
.send .review-view .no-access-modal .contentArea .content {
  height: 22px;
  color: rgba(0, 0, 0, 0.75);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
}
.send .review-view .header-detailinfo {
  height: auto;
  padding: 44px 60px 60px 50px;
}
.send .review-view .header-detailinfo .header-logo {
  margin-bottom: 25px;
  margin-left: 5px;
}
.send .review-view .header-detailinfo .header-detail {
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
  margin-bottom: 32px;
}
.send .review-view .header-detailinfo .header-detail .header-left {
  width: 86%;
}
.send .review-view .header-detailinfo .header-detail .header-left .header-title {
  margin-bottom: 10px;
}
.send .review-view .header-detailinfo .header-detail .header-left .header-title span {
  color: #000000;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 30px;
  letter-spacing: 0;
  line-height: 36px;
}
.send .review-view .header-detailinfo .header-detail .header-left .minutes-tags {
  padding-left: 2px;
}
.send .review-view .header-detailinfo .header-detail .header-left .minutes-tags .tag {
  display: inline-block;
  padding: 4px 8px;
  height: 24px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.5);
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 10px;
}
.send .review-view .header-detailinfo .header-detail .header-left .minutes-tags .tag:not(:last-child) {
  margin-right: 11px;
}
.send .review-view .header-detailinfo .header-detail .header-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  width: 180px;
}
.send .review-view .header-detailinfo .header-detail .header-right .minutes-classification {
  color: #3e3f42;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  text-align: right;
  margin-bottom: 6px;
}
.send .review-view .header-detailinfo .header-detail .header-right .minutes-status {
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  text-align: right;
  margin-bottom: 8px;
}
.send .review-view .header-detailinfo .header-detail .header-right .minutes-no {
  color: #3e3f42;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  text-align: right;
}
.send .review-view .header-detailinfo .detail-info {
  padding-left: 10px;
  margin-right: 180px;
}
.send .review-view .header-detailinfo .detail-info .meeting-detail-item {
  display: table-row;
}
.send .review-view .header-detailinfo .detail-info .meeting-detail-item .detail-title {
  display: table-cell;
  padding: 5px 24px 5px 8px;
  color: #9ea0a5;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  width: 146px;
}
.send .review-view .header-detailinfo .detail-info .meeting-detail-item .detail-content, .send .review-view .header-detailinfo .detail-info .meeting-detail-item .detail-content .displayname, .send #preview .review-view .header-detailinfo .detail-info .meeting-detail-item .detail-content .displayname, #preview .send .review-view .header-detailinfo .detail-info .meeting-detail-item .detail-content .displayname {
  color: #3e3f42;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
}
.send .review-view .header-detailinfo .detail-info .meeting-detail-item .detail-content .displayname, .send #preview .review-view .header-detailinfo .detail-info .meeting-detail-item .detail-content .displayname .displayname, #preview .send .review-view .header-detailinfo .detail-info .meeting-detail-item .detail-content .displayname .displayname {
  display: inline-block;
}
.send .review-view .review-attachments {
  height: 162px;
  padding: 40px 28px 40px 60px;
}
.send .review-view .review-attachments .attachment-container {
  align-self: flex-start;
  align-items: center;
  overflow-x: auto;
  margin-top: 12px;
  padding-bottom: 10px;
}
.send .review-view .review-attachments .attachment-container::-webkit-scrollbar,
.send .review-view .review-attachments .attachment-container .md-virtual-repeat-scroller::-webkit-scrollbar {
  height: 8px;
}
.send .review-view .review-attachments .attachment-container::-webkit-scrollbar-thumb,
.send .review-view .review-attachments .attachment-container .md-virtual-repeat-scroller::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}
.send .review-view .review-attachments .attachment-container .attachment:last-of-type {
  padding: 0;
}
.send .review-view .review-attachments .attachment-container .attachment {
  box-sizing: border-box;
  height: 50px;
  width: 202px;
  min-width: 202px;
  border: 1px solid #d8dce6;
  border-radius: 4px;
  background-color: #ffffff;
  margin-right: 16px;
  position: relative;
}
.send .review-view .review-attachments .attachment-container .attachment .file-icon {
  height: 24px;
  width: 24px;
  margin: 13px 4px 13px 15px;
  font-size: 24px;
  line-height: 24px;
}
.send .review-view .review-attachments .attachment-container .attachment .delete-icon {
  width: 10px;
  height: 10px;
  right: 9px;
  top: 17px;
  position: absolute;
}
.send .review-view .review-attachments .attachment-container .attachment .delete-icon:hover {
  cursor: pointer;
}
.send .review-view .review-attachments .attachment-container .attachment .delete-icon i {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.3);
  font-weight: bolder;
}
.send .review-view .review-attachments .attachment-container .attachment .file-name {
  height: 15px;
  color: #000000;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  width: 156px;
  padding-right: 8px;
  display: inline !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word !important;
  white-space: nowrap;
}
.send .review-view .review-attachments .attachment-container .attachment a {
  text-decoration: none;
}
.send .review-view .review-agenda {
  padding: 40px 60px;
  height: auto;
}
.send .review-view .review-agenda .agenda-content p {
  margin-top: 12px;
  color: rgba(0, 0, 0, 0.75);
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
}
.send .review-view .review-discussion .preview-checkbox {
  margin-top: 3px;
}
.send .review-view .review-discussion .header-container {
  padding: 40px 60px 24px 60px;
}
.send .review-view .review-discussion .header-container .header {
  padding-top: 1px;
}
.send .review-view .review-discussion .header-container .preview-checkbox {
  margin-right: 8px;
}
.send .review-view .review-discussion .discussion-content .control-box {
  border-top: none;
  background-color: transparent;
}
.send .review-view .review-discussion .discussion-content .discussion-point {
  border-top: 1px solid #d8dce6;
  padding: 20px 60px 40px 60px;
}
.send .review-view .review-discussion .discussion-content .discussion-point .point-title {
  color: #00a19c;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 12px;
  margin-left: 8px;
}
.send .review-view .review-discussion .discussion-content .discussion-point .discussion-description-preview p {
  color: rgba(0, 0, 0, 0.75);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  margin-left: 8px;
  margin-bottom: 16px;
}
.send .review-view .review-discussion .discussion-content .discussion-point .point-file {
  margin-bottom: 24px;
}
.send .review-view .review-discussion .discussion-content .discussion-point .topic-attachment-container {
  overflow-y: hidden;
  overflow-x: auto;
  padding-bottom: 10px;
  margin-bottom: 24px;
  margin-left: 24px;
}
.send .review-view .review-discussion .discussion-content .discussion-point .topic-attachment-container::-webkit-scrollbar {
  height: 8px;
}
.send .review-view .review-discussion .discussion-content .discussion-point .topic-attachment-container::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}
.send .review-view .review-discussion .discussion-content .discussion-point .topic-attachment-container .topic-attachment {
  box-sizing: border-box;
  height: 50px;
  width: 202px;
  min-width: 202px;
  border: 1px solid #d8dce6;
  border-radius: 4px;
  background-color: #ffffff;
  margin-right: 16px;
  position: relative;
}
.send .review-view .review-discussion .discussion-content .discussion-point .topic-attachment-container .topic-attachment .file-icon {
  height: 24px;
  width: 24px;
  margin: 13px 4px 13px 15px;
  font-size: 24px;
  line-height: 24px;
}
.send .review-view .review-discussion .discussion-content .discussion-point .topic-attachment-container .topic-attachment .delete-icon {
  width: 10px;
  height: 10px;
  right: 9px;
  top: 17px;
  position: absolute;
}
.send .review-view .review-discussion .discussion-content .discussion-point .topic-attachment-container .topic-attachment .delete-icon:hover {
  cursor: pointer;
}
.send .review-view .review-discussion .discussion-content .discussion-point .topic-attachment-container .topic-attachment .delete-icon i {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.3);
  font-weight: bolder;
}
.send .review-view .review-discussion .discussion-content .discussion-point .topic-attachment-container .topic-attachment .file-name {
  height: 15px;
  color: #000000;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  width: 156px;
  padding-right: 8px;
  display: inline !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word !important;
  white-space: nowrap;
}
.send .review-view .review-discussion .discussion-content .discussion-point .topic-attachment-container .topic-attachment a {
  text-decoration: none;
}
.send .review-view .review-discussion .discussion-content .discussion-point .discussion-item .control-box {
  border-top: none;
  background-color: transparent;
}
.send .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table {
  border-collapse: collapse;
  width: 100%;
  border-spacing: 5px;
  table-layout: fixed;
}
.send .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table thead tr {
  border-bottom: 1px solid #d8dce6;
}
.send .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table tr:not(:last-child) {
  border-bottom: 1px solid #d8dce6;
}
.send .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table th {
  color: #6b6c6f;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: left;
  padding: 0 10px 16px 0px;
  font-weight: 400;
}
.send .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table td {
  padding: 16px 20px 16px 0px;
}
.send .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table td,
.send .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table .topic-no,
.send .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table .topic-name p,
.send .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table .discussionItem-actionType,
.send .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table .discussionItem-displayName,
.send .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table .discussionItem-duedate {
  margin: 0;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  vertical-align: top;
  color: rgba(0, 0, 0, 0.75);
}
.send .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table .topic-name p {
  text-align: justify;
}
.send .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table .topic-no {
  margin-left: 12px;
  margin-right: 20px;
  color: #6b6c6f;
}
.send .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table #topic {
  width: 50%;
  padding-left: 24px;
}
.send .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table #type {
  width: 10%;
}
.send .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table #assignedto {
  width: 20%;
}
.send .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table #duedate {
  width: 20%;
}
.send .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table .topic-content {
  display: flex;
}
@media all and (max-width: 1920px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .send .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table .topic-content {
    width: 650px;
  }
}
@media all and (max-width: 1600px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .send .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table .topic-content {
    width: 500px;
  }
}
@media all and (max-width: 1366px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .send .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table .topic-content {
    width: 450px;
  }
}
@media all and (max-width: 1024px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .send .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table .topic-content {
    width: 350px;
  }
}
@media all and (max-width: 768px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .send .review-view .review-discussion .discussion-content .discussion-point .discussion-item .discussion-item-table .topic-content {
    width: 300px;
  }
}

#extractBar {
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  background-color: #000 !important;
}
#extractBar .selected {
  padding-left: 8px;
}

#extractBar.ng-hide {
  opacity: 0;
}

#extractBar.ng-hide-add,
#extractBar.ng-hide-remove {
  transition: all ease-out 0.15s;
}

#bar-buttons .send-btn-log {
  box-sizing: border-box;
  height: 42px;
  width: 158px;
  border: 1px solid #e2e5ed;
  border-radius: 4px;
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
}
#bar-buttons .send-btn-approve {
  height: 42px;
  width: 126px;
  border-radius: 4px;
  background-color: #00a19c;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
}
#bar-buttons .send-btn-move-to-archive {
  height: 42px;
  width: 169px;
  border-radius: 4px;
  background-color: #00a19c;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
}

.warning-container {
  padding: 0px 60px 0px 60px;
  background-color: #fef8e9;
}

.warning-container * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.warning-container > div {
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  color: rgba(0, 0, 0, 0.8) !important;
  font-size: 14px !important;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}

.warning-container .toast-message {
  color: rgba(0, 0, 0, 0.8) !important;
  font-size: 14px !important;
}

.warning-container > .toast-info {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=") !important;
}

.warning-container > .toast-error {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=") !important;
}

.warning-container > .toast-success {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==") !important;
}

.warning-container > .toast-warning {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=") !important;
}

.warning-container .toast-icon_warning {
  width: 100% !important;
}

.btn-markAsReviewed {
  width: auto !important;
  padding: 12px 23px;
}

#writeminutes {
  width: 90%;
  margin: auto;
}
#writeminutes .header .left {
  height: 20px;
  width: 54px;
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
}
#writeminutes .header .right {
  height: 14px;
  width: 172px;
  color: #9ea0a5;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 14px;
}
#writeminutes .details {
  box-sizing: border-box;
  min-height: 338px;
  border: 1px solid #d8dce6;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
}
#writeminutes .details > div {
  padding: 20px;
}
#writeminutes .details > div md-input-container {
  width: 100%;
  margin-bottom: 8px;
}
#writeminutes .details > div md-input-container .md-input {
  color: #000;
}
#writeminutes .details > div md-input-container label {
  height: 14px;
  color: #9ea0a5;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 14px;
  margin: 0;
}
#writeminutes .details > div md-input-container input::placeholder,
#writeminutes .details > div md-input-container input::-webkit-input-placeholder,
#writeminutes .details > div md-input-container input:-ms-input-placeholder,
#writeminutes .details > div md-input-container input::-moz-placeholder {
  height: 16px;
  color: #999999 !important;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 16px;
}
#writeminutes .details > div md-input-container input,
#writeminutes .details > div md-input-container md-select {
  box-sizing: border-box;
  height: 40px;
  border: 1px solid #d8dce6;
  border-radius: 4px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 13px;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: 8px;
}
#writeminutes .details > div md-input-container .input-border,
#writeminutes .details > div md-input-container md-select md-select-value {
  padding-left: 16px;
}
#writeminutes .details > div md-input-container.series-container .series md-autocomplete-wrap {
  box-shadow: none;
}
#writeminutes .details > div md-input-container.series-container .series input {
  height: 100%;
}
#writeminutes .details > div md-input-container.series-container .series button {
  display: none;
}
#writeminutes .details > div md-input-container.series-container .series md-progress-linear {
  display: none;
}
#writeminutes .details > div .has-series-error input {
  border-color: #d50000;
}
#writeminutes .details > div .series-error {
  margin-top: 10px;
  font-size: 11px;
  letter-spacing: 0;
  color: #d50000;
}
#writeminutes .details > div .series-tooltip {
  margin-top: 10px;
  color: #9EA0A5;
  font-size: 11px;
  letter-spacing: 0;
  line-height: 14px;
}
#writeminutes .details > div .has-location-error {
  border-color: #d50000;
}
#writeminutes .details > div .location-error {
  color: #d50000;
  font-size: 11px;
  letter-spacing: 0;
}
#writeminutes .details > div .has-startdate-error {
  border-color: #d50000 !important;
}
#writeminutes .details > div .has-startdate-error .startdate-error {
  color: #d50000;
  font-size: 11px;
  letter-spacing: 0;
  margin-left: 0;
}
#writeminutes .details > div .series-loading {
  margin-top: 7px;
}
#writeminutes .details > div md-input-container.date-time label {
  left: 0;
}
#writeminutes .details > div md-input-container.date-time #wm-datetime {
  padding-left: 38px;
  padding-right: 25px;
}
#writeminutes .details > div md-input-container:not(.md-input-focused):not(.md-input-invalid) label.md-required:after {
  color: #d50000;
}
#writeminutes .details > div .seriesno {
  width: 100px;
}
#writeminutes .details > div .seriesno label {
  height: 14px;
  color: #9ea0a5;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 14px;
  text-transform: uppercase;
}
#writeminutes .details > div .seriesno input {
  box-sizing: border-box;
  height: 40px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding-left: 10px;
  font-size: 13px;
  background-image: none;
}
#writeminutes .details > div md-input-container.md-input-container1 {
  width: 100%;
  margin: 16px 0 8px;
}
#writeminutes .details > div md-input-container.md-input-container1 md-chips-wrap {
  margin: 0;
  padding: 0;
  box-shadow: none;
  box-sizing: border-box;
  border: 1px solid #d8dce6;
  border-radius: 4px;
  margin-top: 6px;
  margin-bottom: 10px;
}
#writeminutes .details > div md-input-container.md-input-container1 md-chips-wrap .md-chip-input-container {
  padding: 0;
  height: 32px;
  margin: 4px;
}
#writeminutes .details > div md-input-container.md-input-container1 md-chips-wrap .md-chip-input-container input {
  height: 32px;
  border: none;
  margin-top: 0;
}
#writeminutes .details > div md-input-container.md-input-container1 md-chip {
  line-height: 32px;
  margin: 4px;
  height: 32px;
  border-radius: 16px;
  color: #00a19c;
  font-weight: 500;
  letter-spacing: 0;
  background-color: transparent;
  box-sizing: border-box;
  border: 1px solid #00a19c;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
#writeminutes .details > div md-input-container.md-input-container1 md-chip .md-chip-content {
  height: inherit;
  line-height: 28px;
}
#writeminutes .details > div md-input-container.md-input-container1 md-chip .md-chip-content span {
  font-size: 12px !important;
}
#writeminutes .details > div md-input-container.md-input-container1 md-chip .md-chip-remove-container {
  right: 5px;
}
#writeminutes .details > div md-input-container.md-input-container1 md-chip .md-chip-remove-container .icon-window-close {
  line-height: 32px;
  height: 10px;
  width: 10px;
  color: #999999;
  font-size: 13px;
}
#writeminutes .details > div .security-mdselect {
  border-bottom: none;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
}
#writeminutes .details > div .security-mdselect .md-select-value .md-text {
  font-size: 14px;
  color: #3e3f42;
  position: relative;
  top: -1px;
}
#writeminutes .details > div .security-mdselect .md-select-value .md-select-icon {
  background: url("../../../../../../assets/icons/dropdownicon.png") no-repeat 100% 100%;
  color: transparent;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
}
#writeminutes .details > div .security-mdselect .md-default-theme:not([disabled]):focus .md-select-value, #writeminutes .details > div .security-mdselect:not([disabled]):focus .md-select-value {
  border-bottom: 1px solid #d8dce6;
}

md-card.minutes-attachment {
  margin: 0;
  box-sizing: border-box;
  border: 1px solid #d8dce6;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
}

.minutes-attachment {
  overflow-x: auto;
  padding: 24px;
  margin-bottom: 40px !important;
}
.minutes-attachment::-webkit-scrollbar,
.minutes-attachment .md-virtual-repeat-scroller::-webkit-scrollbar {
  height: 8px;
}
.minutes-attachment::-webkit-scrollbar-thumb,
.minutes-attachment .md-virtual-repeat-scroller::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.minutes-attachment .title {
  margin-bottom: 16px;
}
.minutes-attachment .attachment-container {
  align-self: flex-start;
  align-items: center;
}
.minutes-attachment .attachment-container .attachment:last-of-type {
  padding: 0;
}
.minutes-attachment .attachment-container .attachment {
  box-sizing: border-box;
  height: 50px;
  width: 200px;
  border: 1px solid #d8dce6;
  border-radius: 4px;
  background-color: #ffffff;
  margin-right: 16px;
  position: relative;
}
.minutes-attachment .attachment-container .attachment .file-icon {
  height: 24px;
  width: 24px;
  margin: 13px 4px 13px 15px;
  font-size: 24px;
  line-height: 24px;
}
.minutes-attachment .attachment-container .attachment .delete-icon {
  width: 10px;
  height: 10px;
  right: 9px;
  top: 17px;
  position: absolute;
}
.minutes-attachment .attachment-container .attachment .delete-icon:hover {
  cursor: pointer;
}
.minutes-attachment .attachment-container .attachment .delete-icon i {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.3);
  font-weight: bolder;
}
.minutes-attachment .attachment-container .attachment .file-name {
  height: 15px;
  color: #000000;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  width: 130px;
  display: inline !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word !important;
  white-space: nowrap;
}
.minutes-attachment .attachment-container .attachment a {
  text-decoration: none;
}
.minutes-attachment .add-attachment {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  margin-right: 16px;
  color: #00a19c;
  box-sizing: border-box;
  height: 54px;
  width: 146px;
  border: 1px dashed #00a19c;
  background-color: #fafafa;
}
.minutes-attachment .add-attachment .add-button {
  margin: 0 0 0 6px;
  color: #00a19c;
}
.minutes-attachment .add-attachment .add-button:disabled {
  cursor: not-allowed;
}
.minutes-attachment .add-attachment #fileInput {
  display: none;
}
.minutes-attachment .add-attachment .text {
  height: 16px;
  color: #00a19c;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 16px;
  margin-right: 8px;
}
.minutes-attachment .no-attachment {
  height: 16px;
  color: #999999;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 16px;
}
.minutes-attachment .attachment a {
  color: #00a19c;
}

.wm-title {
  height: auto;
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 12px;
}
.wm-title .md-icon-button {
  top: -4px;
  margin: 0 4px;
}
.wm-title .minute-no {
  height: 15px;
  width: 163px;
  color: #999999;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  text-align: right;
  float: right;
  font-weight: 400;
}

.wm-section2 {
  margin-top: 50px;
}

.wm-section1 {
  margin-top: 20px;
}

.wm-section3 {
  margin-top: 50px;
}
.wm-section3 .agenda {
  margin: 0;
  margin-bottom: 40px;
  padding: 24px;
  box-sizing: border-box;
  min-height: 221px;
  width: 100%;
  border: 1px solid #d8dce6;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
}
.wm-section3 .agenda .agenda-item {
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.wm-section3 .agenda .agenda-item .md-resize-wrapper {
  width: 100%;
}
.wm-section3 .agenda .fr-view p {
  font-size: 14px !important;
}
.wm-section3 .agenda md-input-container {
  margin: 0;
  box-sizing: border-box;
  border: 1px solid #d8dce6;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
}
.wm-section3 .agenda md-input-container.has-agenda-error {
  border-color: #d50000;
}
.wm-section3 .agenda .agenda-error {
  color: #d50000;
  font-size: 11px;
  letter-spacing: 0;
  margin: 10px 0;
}

md-card.minutes-discussion {
  margin: 0;
  box-sizing: border-box;
  border: 1px solid #d8dce6;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
}

.wm-section4 {
  margin-top: 50px;
}
.wm-section4 .minutes-discussion-container {
  border-radius: 4px;
  background-color: rgba(217, 217, 217, 0.5);
}
.wm-section4 .minutes-discussion-container .add-discussion .button-add-discussion-point {
  box-sizing: border-box;
  height: 43px;
  width: 177px;
  border: 1px solid #00a19c;
  border-radius: 4px;
  background-color: #ffffff;
  color: #00a19c;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  margin-right: 24px;
  margin-bottom: 24px;
}
.wm-section4 .minutes-discussion-container .add-discussion .button-add-discussion-point:hover {
  cursor: pointer;
}
.wm-section4 .minutes-discussion {
  background-color: rgba(217, 217, 217, 0.5) !important;
  position: relative;
}
.wm-section4 .minutes-discussion .discussion-point:not(.first-discussion-point) {
  margin-top: 4px;
}
.wm-section4 .minutes-discussion .discussion-point {
  background-color: white;
  padding: 0 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.wm-section4 .minutes-discussion .discussion-point md-input-container {
  background: white;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-header {
  padding: 0 24px;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-header .header-main md-input-container input,
.wm-section4 .minutes-discussion .discussion-point .discussion-header .header-main md-input-container md-select-value {
  padding-bottom: 1px !important;
  border: none;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-header .header-main md-input-container input::-webkit-input-placeholder {
  color: #999999;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 13px;
  letter-spacing: 0;
  line-height: 16px;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-header .header-main md-input-container input:-ms-input-placeholder {
  color: #999999 !important;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 13px;
  letter-spacing: 0;
  line-height: 16px;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-header .header-main .discussion-title {
  padding: 12px 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  line-height: 16px;
  box-sizing: border-box;
  height: 40px;
  border: 1px solid #d8dce6;
  border-radius: 4px;
  background-color: #ffffff;
  color: #000000;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-header .header-main .discussion-title:-ms-input-placeholder {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  color: #999999 !important;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-header .header-main .has-discussion-title-error {
  border-color: #d50000;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-header .header-main .discussion-title-error {
  margin-top: 10px;
  color: #d50000;
  font-size: 11px;
  letter-spacing: 0;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-header .header-main md-input-container.md-default-theme .md-input::-webkit-input-placeholder,
.wm-section4 .minutes-discussion .discussion-point .discussion-header .header-main md-input-container .md-input::-webkit-input-placeholder {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  color: #999999;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-header .header-main .discussion-index {
  background: none;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-header .header-main .discussion-index md-select md-select-value {
  min-width: 48px;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-header .header-main .discussion-index md-select md-select-value .md-text {
  font-size: 1.8rem;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-header .header-main .discussion-index md-select md-select-value .md-select-icon {
  width: auto;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-header .header-main .delete-discussion.has-disabled:hover {
  cursor: not-allowed;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-header .header-main .expand-discussion md-icon {
  color: #000000;
  position: relative;
  top: 2px;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-header .discussion-description {
  display: flex;
  align-items: center;
  min-height: 72px;
  margin: 0 0 16px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-header .discussion-description .md-resize-wrapper {
  width: 100%;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-header .discussion-description .fr-placeholder {
  color: #999999;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px !important;
  letter-spacing: 0;
  line-height: 22px !important;
  padding-left: 20px !important;
  padding-top: 12px !important;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-header .discussion-description .fr-view {
  padding-top: 12px;
  padding-left: 20px;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-header .discussion-description .fr-view p {
  font-size: 14px !important;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-header .topic-attachment-container {
  overflow-y: hidden;
  overflow-x: auto;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-header .topic-attachment-container .topic-attachment {
  box-sizing: border-box;
  height: 50px;
  width: 200px;
  min-width: 200px;
  border: 1px solid #d8dce6;
  border-radius: 4px;
  background-color: #ffffff;
  margin-right: 16px;
  position: relative;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-header .topic-attachment-container .topic-attachment .file-icon {
  height: 24px;
  width: 24px;
  margin: 13px 4px 13px 15px;
  font-size: 24px;
  line-height: 24px;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-header .topic-attachment-container .topic-attachment .delete-icon {
  width: 10px;
  height: 10px;
  right: 9px;
  top: 17px;
  position: absolute;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-header .topic-attachment-container .topic-attachment .delete-icon:hover {
  cursor: pointer;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-header .topic-attachment-container .topic-attachment .delete-icon i {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.3);
  font-weight: bolder;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-header .topic-attachment-container .topic-attachment .file-name {
  height: 15px;
  color: #000000;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  width: 130px;
  padding-right: 25px;
  display: inline !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word !important;
  white-space: nowrap;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-header .topic-attachment-container .topic-attachment a {
  text-decoration: none;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-header .topic-comment {
  margin-top: 14px;
}
.wm-section4 .minutes-discussion .discussion-point .add-attachment {
  display: flex;
  align-items: center;
  color: #00a19c;
}
.wm-section4 .minutes-discussion .discussion-point .add-attachment input {
  display: none;
}
.wm-section4 .minutes-discussion .discussion-point .add-attachment.disabled .icon-paperclip {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.21);
}
.wm-section4 .minutes-discussion .discussion-point .discussion-button {
  position: relative;
  border-radius: 0 !important;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-button .noti-number {
  position: absolute;
  top: -4px;
  right: -11px;
  line-height: 16px;
  font-size: 12px;
  color: white;
  background: #00a19c;
  text-align: center;
  height: 23px;
  width: 23px;
  border-radius: 50%;
  background-color: #00a19c;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-button .noti-number span {
  line-height: 16px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-65%, -50%);
  transform: translate(-65%, -50%);
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item {
  padding: 24px 0;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list {
  padding: 0 0;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item-placeholder {
  border-bottom: 1px solid transparent !important;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item-placeholder * {
  opacity: 0;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item {
  position: relative;
  align-items: center;
  padding: 16px 0px;
  margin: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  flex-wrap: wrap;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item md-input-container {
  margin: 0;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .action-area .md-resize-wrapper,
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .action-area input,
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .md-select-value {
  border: none;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .topic md-icon {
  margin: 0 16px 0 0;
  cursor: move;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .topic md-checkbox {
  width: 20px;
  max-width: 20px;
  margin: 0 16px 0 0;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .topic .index {
  width: 20px;
  margin: 0 16px 0 0;
  position: relative;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .topic .show-log {
  color: rgba(0, 0, 0, 0.85);
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 15px;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .topic .show-log:hover:after {
  content: "Log";
  position: absolute;
  width: 100%;
  left: 0;
  background: rgb(245, 245, 245);
  color: #00a19c;
  cursor: pointer;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .topic .topic-description {
  margin-bottom: 6px;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-radius: 4px;
  width: 100%;
  min-height: 137px;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .topic .topic-description .fr-placeholder {
  color: #999999;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px !important;
  letter-spacing: 0;
  line-height: 22px !important;
  padding-left: 20px !important;
  padding-top: 12px !important;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .topic .topic-description .fr-view {
  padding-left: 20px;
  padding-top: 12px;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .topic .topic-description .fr-view p {
  font-size: 14px;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .topic .topic-description.has-topic-error {
  border: 1px solid #d50000 !important;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .topic .topic-error {
  color: #d50000;
  font-size: 11px;
  letter-spacing: 0;
  margin: 10px 0;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .action-area {
  width: 223px;
  align-items: center;
  justify-content: flex-end;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .action-area md-input-container {
  background: none;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .action-area md-input-container .select-type-discussion .md-select-value {
  padding: 0;
  padding-left: 12px;
  height: 100%;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .action-area md-input-container .select-type-discussion .md-select-value .md-text {
  font-size: 14px;
  color: #3e3f42;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .action-area md-input-container .select-type-discussion .md-select-value .md-select-icon {
  background: url("../../../../../../assets/icons/dropdownicon.png") no-repeat 100% 100%;
  color: transparent;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .action-area .delete-icon {
  padding-bottom: 6px !important;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .wm-owner {
  margin: 10px 0;
  background-color: transparent;
  padding: 1px;
  border: 1px solid #d8dce6;
  height: 36px;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .wm-owner md-chips-wrap {
  padding: 0;
  box-shadow: none;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .wm-owner md-chips-wrap input {
  padding: 2px 6px !important;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .wm-owner .md-chip-input-container {
  width: 100%;
  margin: -4px;
  margin-left: 0;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .wm-owner md-chip {
  padding-left: 40px;
  width: 100%;
  height: 32px;
  border-radius: 4px;
  background-color: #eeeeee !important;
  background-color: transparent;
  margin: 0;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .wm-owner md-chip .chip-image {
  position: absolute;
  left: 8px;
  top: 6px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border: 0.1px solid rgba(0, 0, 0, 0.01);
  border-radius: 50%;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .wm-owner md-chip .chip-initial {
  background: rgba(0, 0, 0, 0.38);
  text-align: center;
  color: white;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .wm-owner md-chip .chip-content-text {
  height: 16px;
  color: #000000;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 16px;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .wm-owner .icon-close {
  font-size: 16px;
  padding-top: 4px;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .wm-owner md-autocomplete {
  border: none;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .wm-owner.has-owner-error {
  border-color: #d50000;
  border-radius: 4px;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .owner-error {
  color: #d50000;
  font-size: 11px;
  letter-spacing: 0;
  margin-bottom: 10px;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .subtopic {
  border: 1px solid #d8dce6;
  border-radius: 4px;
  background: white;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .subtopic .subtopic-dueDate {
  width: 100px;
  padding-left: 12px;
  padding-right: 8px;
  height: 36px;
  display: flex;
  align-items: center;
  color: #999999;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 12px;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .subtopic .subtopic-datepicker {
  width: 123px;
  background-color: transparent;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .subtopic .subtopic-datepicker input {
  border-bottom: none;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .subtopic .subtopic-datepicker .md-required {
  display: none;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .subtopic .duedate-error {
  position: absolute;
  top: 40px;
  left: -100px;
  color: #d50000;
  font-size: 11px;
  letter-spacing: 0;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .subtopic.has-duedate-error {
  border-color: #d50000;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item md-autocomplete {
  min-width: auto;
  height: 40px;
  line-height: 40px;
  padding: 3px;
  margin-bottom: 4px;
  border: 1px solid #d8dce6;
  border-radius: 4px;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item md-autocomplete md-autocomplete-wrap {
  box-shadow: none;
  height: 32px;
  line-height: 32px;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item md-autocomplete md-autocomplete-wrap input {
  height: 32px;
  line-height: 32px;
  color: #000000;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item md-autocomplete md-autocomplete-wrap input::placeholder {
  color: #999;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 12px;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item md-autocomplete md-autocomplete-wrap input::-webkit-input-placeholder {
  color: #999;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 12px;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item md-autocomplete md-autocomplete-wrap input::-moz-input-placeholder {
  color: #999;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 12px;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item md-autocomplete md-autocomplete-wrap input:-ms-input-placeholder {
  color: #999;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 12px;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item md-autocomplete md-autocomplete-wrap button {
  width: 24px;
  height: 24px;
  margin: 4px;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item md-autocomplete.selectedChip md-autocomplete-wrap {
  padding: 0 8px;
  border-radius: 32px;
  background: rgb(224, 224, 224);
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item md-autocomplete.selectedChip md-autocomplete-wrap input {
  border-radius: 32px;
  background: rgb(224, 224, 224);
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item md-autocomplete.selectedChip md-autocomplete-wrap md-icon {
  width: 18px;
  min-width: 18px;
  height: 18px;
  min-height: 18px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.54);
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item mdp-date-picker button {
  display: none;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item mdp-date-picker input {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}
.wm-section4 .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .comment-box {
  margin: 8px 0;
}
.wm-section4 .minutes-discussion .discussion-point .add-discussion-item-container {
  padding: 20px 24px;
}
.wm-section4 .minutes-discussion .discussion-point .add-discussion-item-container .add-discussion-item {
  box-sizing: border-box;
  height: 40px;
  width: 151px;
  border: 1px solid #00a19c;
  border-radius: 4px;
  background-color: #ffffff;
  color: #00a19c;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 15px;
}
.wm-section4 .minutes-discussion .discussion-point .add-discussion-item-container .add-discussion-item:hover {
  cursor: pointer;
}
.wm-section4 .minutes-discussion .discussion-point-expanded {
  border: none;
  margin-bottom: 2px;
}
.wm-section4 .minutes-discussion .add-discussion:first-of-type {
  margin-top: 0px;
}
.wm-section4 .dragable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  pointer-events: auto;
}

#bar-buttons .btn-log,
#bar-buttons .btn-preious {
  height: 42px;
  width: 158px;
}
#bar-buttons .btn-log span,
#bar-buttons .btn-preious span {
  color: rgba(0, 0, 0, 0.5);
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  margin-right: 7.5px;
}
#bar-buttons .btn-log {
  margin-left: 20px;
}
#bar-buttons .btn-preious {
  margin-right: 12px;
}
#bar-buttons .btn-preious .icon-previous {
  margin-top: 4px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #bar-buttons .send-minute {
    top: 2px;
  }
}

.wm-section5 {
  margin-top: 50px;
}
.wm-section5 selected-comment {
  margin-bottom: 79px;
}

selected-comment .comment-box {
  background-color: white;
}
selected-comment .control-box {
  background-color: white !important;
  border-top: none !important;
}

.wm-question-mark-tooltip {
  margin-left: 55px;
  height: auto;
  width: 349px;
  border-radius: 4px;
  background-color: #212121;
  box-shadow: 0 1px 4px 0 rgba(85, 85, 85, 0.2);
}
.wm-question-mark-tooltip .md-content {
  white-space: normal !important;
  background-color: #212121 !important;
  color: #FFFFFF;
  font-size: 10px !important;
  letter-spacing: 0 !important;
  line-height: 14px !important;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  padding: 5px 5px 5px 13px;
}
.wm-question-mark-tooltip .md-content span {
  font-size: 10px !important;
}

md-tooltip .md-content {
  white-space: normal !important;
  background-color: #212121 !important;
  color: #FFFFFF;
  font-size: 10px !important;
  letter-spacing: 0 !important;
  line-height: 14px !important;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  padding: 5px;
}

.progress-attachment-container {
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}
.progress-attachment-container .progress-attachments {
  flex: 0 200px;
  padding-right: 20px;
  margin-bottom: 20px;
  height: 21px;
}
.progress-attachment-container .progress-attachments .pa-filename {
  color: #000000;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 12px;
  letter-spacing: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
  height: 15px;
  white-space: nowrap;
}
.progress-attachment-container .progress-attachments .pa-bar {
  width: 200px;
  line-height: 4px;
  height: 4px;
  border-radius: 1px;
}
.progress-attachment-container .progress-attachments .pa-bar::-webkit-progress-bar {
  color: #eeeeee;
  background-color: #eeeeee;
  border-radius: 2px;
}
.progress-attachment-container .progress-attachments .pa-bar::-webkit-progress-value {
  color: #4CAF50;
  background-color: #4CAF50;
  border-radius: 2px;
}
.progress-attachment-container .progress-attachments .pa-bar::-moz-progress-bar {
  color: #eeeeee;
  background-color: #eeeeee;
  border-radius: 2px;
}
.progress-attachment-container .progress-attachments .pa-bar::-moz-progress-value {
  color: #4CAF50;
  background-color: #4CAF50;
  border-radius: 2px;
}
.progress-attachment-container .progress-attachments .pa-bar::-ms-fill {
  color: #4CAF50;
  background-color: #4CAF50;
  border-radius: 2px;
}
.progress-attachment-container .progress-attachments .pa-bar[value] {
  background-color: #eeeeee;
  color: #4CAF50;
  border-radius: 2px;
  position: relative;
  top: -9px;
}
.progress-attachment-container .progress-attachments .pa-bar[value]::-ms-fill {
  background-color: #eeeeee;
  color: #4CAF50;
  border-radius: 2px;
}
.progress-attachment-container .my-progress .md-mode-determinate {
  background-color: #EEEEEE !important;
  height: 4px;
  width: 200px;
  border-radius: 4px;
  margin-top: 5px;
}
.progress-attachment-container .my-progress .md-bar {
  background-color: #4CAF50 !important;
}

#new-toolbar {
  background-color: #ffffff;
}
#new-toolbar .progress-container {
  width: 5%;
  background-color: blue;
}
#new-toolbar .progress-container .progress-icon {
  position: relative;
  width: 100%;
  float: left;
  height: 64px;
}
#new-toolbar .progress-container .progress-icon #toolbar-progress-icon {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
#new-toolbar .nt-container {
  background-color: #ffffff;
  margin-left: 5%;
  margin-right: 5px;
  height: 64px;
}
#new-toolbar .nt-container .my-minutes {
  height: 20px;
  width: 156px;
  color: #000000;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 64px;
}
#new-toolbar .nt-container .bell {
  background-color: red;
  text-align: end;
  line-height: 64px;
  position: relative;
  margin-right: 20px;
}
#new-toolbar .nt-container .bell .badge {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #ffc003;
  text-align: center;
  position: relative;
  left: -15px;
  top: -5px;
}
#new-toolbar .nt-container .bell .badge span {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  color: #000000;
}
#new-toolbar .nt-container #menu-container {
  margin-right: 60px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
#new-toolbar .nt-container #menu-container .guide-container {
  height: 38px;
  border-right: 1px solid #D8D8D8;
  padding: 5px 10px;
}
#new-toolbar .nt-container #menu-container #menu-btn {
  height: 38px;
}
#new-toolbar .nt-container #menu-container #menu-btn .text {
  margin-right: 8px;
  margin-left: 8px;
  min-width: 60px;
}
#new-toolbar .nt-container #menu-container #menu-btn .text .title {
  color: #3e3f42;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 19px;
}
#new-toolbar .nt-container #menu-container #menu-btn .text .content {
  opacity: 0.75;
  color: #4b4b4b;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 11px;
  letter-spacing: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 14px;
}
#new-toolbar .nt-container #menu-container #menu {
  position: absolute;
  background-color: white;
  width: 100%;
  left: 0;
  top: -100px;
  padding-top: 20px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
#new-toolbar .nt-container #menu-container #menu .md-button {
  width: 100%;
  text-align: left;
  margin: 0;
}
#new-toolbar .nt-container #menu-container .avatar-wrapper {
  position: relative;
}
#new-toolbar .nt-container #menu-container .avatar-wrapper img {
  width: 36px;
  height: 36px;
}
#new-toolbar .nt-container #menu-container .avatar-wrapper .status {
  position: absolute;
  bottom: -3px;
  right: 5px;
}
#new-toolbar .nt-container #menu-container .avatar-wrapper .image {
  width: 36px;
  min-width: 36px;
  max-width: 36px;
  line-height: 36px;
  margin: 0;
  border-radius: 50%;
  text-align: center;
  color: white;
  background-color: #402b53;
}

.btn-logout > span {
  margin-left: 8px !important;
}

.tolowercase {
  text-transform: lowercase;
}

#detail {
  width: 100%;
  max-width: 1280px;
  padding: 16px 8px 8px;
  margin: auto;
  color: rgba(0, 0, 0, 0.87);
}
#detail .header {
  margin-bottom: 8px;
}
#detail .review-view .header {
  flex-direction: column;
  padding: 0;
  margin-bottom: 8px;
}
#detail .review-view .header .right {
  display: flex;
  flex-direction: row;
  align-items: center;
}
#detail .review-view .header .right .status-mobile {
  padding: 0 4px;
}
#detail .review-view .meeting-detail {
  padding: 8px;
  border-left: 4px solid #00a19c;
  background-color: rgba(255, 255, 255, 0.7);
}
#detail .review-view .meeting-detail .md-button {
  float: right;
}
#detail .review-view .meeting-detail .meeting-detail-item .detail-title {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.54);
}
#detail .review-view .meeting-detail .meeting-detail-item .detail-content, #detail #preview .review-view .meeting-detail .header-detailinfo .detail-info .meeting-detail-item .detail-content .displayname, #preview #detail .review-view .meeting-detail .header-detailinfo .detail-info .meeting-detail-item .detail-content .displayname, #detail #preview .review-view .header-detailinfo .detail-info .meeting-detail .meeting-detail-item .detail-content .displayname, #preview #detail .review-view .header-detailinfo .detail-info .meeting-detail .meeting-detail-item .detail-content .displayname, #detail .send .review-view .meeting-detail .header-detailinfo .detail-info .meeting-detail-item .detail-content .displayname, .send #detail .review-view .meeting-detail .header-detailinfo .detail-info .meeting-detail-item .detail-content .displayname, #detail .send .review-view .header-detailinfo .detail-info .meeting-detail .meeting-detail-item .detail-content .displayname, .send #detail .review-view .header-detailinfo .detail-info .meeting-detail .meeting-detail-item .detail-content .displayname {
  margin-bottom: 8px;
}
#detail .review-attachment {
  padding: 0 16px;
  margin-bottom: 40px;
}
#detail .review-attachment .title {
  margin-bottom: 16px;
  font-size: 16px;
}
#detail .review-attachment .attachment {
  width: 96px;
  margin-right: 16px;
}
#detail .review-attachment .attachment md-icon {
  width: 48px;
  height: 48px;
  margin: auto;
  margin-bottom: 8px;
  font-size: 48px;
  line-height: normal;
}
#detail .review-attachment .attachment .file-name {
  font-size: 1.2rem;
  text-align: center;
  max-width: 70px !important;
  margin-left: 8px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}
#detail .review-attachment .attachment:last-of-type {
  padding: 0;
}
#detail md-card {
  margin: 0 0 24px;
}
#detail md-card md-card-title {
  position: relative;
}
#detail .review-discussion .discussion-container {
  overflow: hidden;
}
#detail .review-discussion .discussion-container .discussion-title {
  color: #00a19c;
  padding: 0 0 8px;
  margin: 0 0 16px;
  border-bottom: 2px solid #00a19c;
}
#detail .review-discussion .discussion-container .discussion-description-container {
  align-items: flex-start;
}
#detail .review-discussion .discussion-container .discussion-description {
  width: 100%;
}
#detail .review-discussion .discussion-container .discussion-description p {
  margin: 0 0 8px 0;
}
#detail .review-discussion .discussion-container .discussion-description p:last-of-type {
  margin-bottom: 0;
}
#detail .review-discussion .discussion-container .attachment-container {
  display: block;
  white-space: nowrap;
  overflow-x: scroll !important;
  padding: 16px 4px;
}
#detail .review-discussion .discussion-container .attachment-container::-webkit-scrollbar,
#detail .review-discussion .discussion-container .attachment-container .md-virtual-repeat-scroller::-webkit-scrollbar {
  height: 8px;
}
#detail .review-discussion .discussion-container .attachment-container::-webkit-scrollbar-thumb,
#detail .review-discussion .discussion-container .attachment-container .md-virtual-repeat-scroller::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
#detail .review-discussion .discussion-container .attachment-container a {
  text-align: center;
}
#detail .review-discussion .discussion-container .attachment-container .attachment {
  display: inline-block;
  position: relative;
  width: 96px;
  height: 64px;
  padding: 8px;
  margin-right: 8px;
  background: rgba(0, 0, 0, 0.04);
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.12), 0 0 2px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.24);
}
#detail .review-discussion .discussion-container .attachment-container .attachment md-icon {
  margin: 4px auto;
  min-height: 24px;
}
#detail .review-discussion .discussion-container .attachment-container .attachment .file-name {
  font-size: 1.2rem;
  text-align: center;
  max-width: 70px !important;
  margin-left: 8px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: nowrap;
}
#detail .review-discussion .discussion-container .attachment-container .attachment:hover {
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.14), 0 0 4px rgba(0, 0, 0, 0.14), 0px 4px 8px rgba(0, 0, 0, 0.28);
}
#detail .review-discussion .discussion-container-collapse {
  height: 40px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
#detail .review-discussion .discussion-container-full {
  height: 100%;
}
@media (max-width: 959px) {
  #detail .review-discussion .discussion-container-less {
    position: absolute;
    width: calc(100% - 32px);
    height: 96px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) calc(100% - 24px), rgba(255, 255, 255, 0.87) 100%);
  }
}
#detail .review-discussion md-card-content .discussion-list .list-item {
  width: 100%;
  min-height: 48px;
  padding: 16px 0;
  flex-wrap: wrap;
}
#detail .review-discussion md-card-content .discussion-list .list-item .assigned .assignee {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#detail .review-discussion md-card-content .discussion-list .list-item .assigned img {
  width: 36px;
  height: 36px;
  margin-left: 8px;
  background-color: #00a19c;
  border-radius: 50%;
}
#detail .review-discussion md-card-content .discussion-list .list-item .assigned .due-date {
  color: rgba(0, 0, 0, 0.54);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#detail .review-discussion md-card-content .discussion-list .list-item .topic .topic-name p,
#detail .review-discussion md-card-content .discussion-list .list-item .topic .topic-name ol {
  margin: 0;
}
#detail .review-discussion md-card-content .discussion-list .list-item:last-of-type {
  padding-bottom: 0;
}
#detail .review-discussion .mobile-action-area {
  margin: 8px 0 8px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
}
#detail .review-discussion .mobile-action-area .md-button {
  line-height: normal;
  margin: 6px auto;
  white-space: normal;
  text-transform: none;
}
#detail .review-discussion .mobile-action-area .md-button .icon-notification {
  display: flex;
  align-items: center;
  position: relative;
  height: 36px;
}
#detail .review-discussion .mobile-action-area .md-button .icon-notification md-icon {
  margin-right: 16px;
}
#detail .review-discussion .mobile-action-area .md-button .icon-notification .comment-count {
  right: 8px;
}
#detail .review-discussion .mobile-action-area .md-button .button-text {
  text-align: left;
}
#detail .review-discussion .mobile-action-area:last-of-type {
  margin-bottom: 0;
}
#detail .comment-area {
  margin: 16px 0;
}
@media (max-width: 959px) {
  #detail selected-comment {
    display: block;
  }
}
#detail .comment-button {
  position: relative;
  border-radius: 0 !important;
}
#detail .comment-button .comment-count {
  position: absolute;
  top: 1px;
  right: 0px;
  line-height: 16px;
  font-size: 12px;
  border-radius: 16px;
  width: 16px;
  height: 16px;
  color: white;
  background: #00a19c;
  text-align: center;
}

@media (max-width: 960px) {
  .bottom-bar {
    padding: 18px 0px !important;
  }
}
@media (min-width: 960px) {
  #detail {
    padding: 40px;
    padding-bottom: 150px;
  }
  #detail .header {
    margin-bottom: 16px;
  }
  #detail .review-view .header {
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
  }
  #detail .review-view .header .right {
    flex-direction: column;
    align-items: flex-end;
  }
  #detail .review-view .meeting-detail .meeting-detail-item {
    display: table-row;
  }
  #detail .review-view .meeting-detail .meeting-detail-item .detail-title {
    display: table-cell;
    padding: 8px 24px 8px 8px;
    font-family: "Museo Sans", sans-serif;
    font-size: 1.4rem;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.38);
    text-transform: uppercase;
  }
  #detail .review-attachment {
    padding: 0 16px;
    margin-bottom: 40px;
  }
  #detail .review-attachment .title {
    margin-bottom: 16px;
    font-size: 16px;
  }
  #detail .review-attachment .attachment {
    width: 96px;
    margin-right: 16px;
  }
  #detail .review-attachment .attachment md-icon {
    width: 48px;
    height: 48px;
    margin: auto;
    margin-bottom: 8px;
    font-size: 48px;
    line-height: normal;
  }
  #detail .review-attachment .attachment .file-name {
    font-size: 1.2rem;
    text-align: center;
    display: inline !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: nowrap;
  }
  #detail .review-attachment .attachment:last-of-type {
    padding: 0;
  }
  #detail md-card md-card-title {
    padding: 24px 24px 0;
  }
  #detail md-card md-card-content {
    padding: 0 24px 24px;
  }
  #detail .agenda {
    margin-bottom: 40px;
  }
  #detail .review-discussion .discussion-container {
    height: auto;
  }
  #detail .review-discussion .discussion-container .discussion-title {
    padding: 0 0 8px;
    margin: 0 0 16px;
  }
  #detail .review-discussion .discussion-container .discussion-title md-checkbox {
    top: -3px;
    margin: 0;
  }
  #detail .review-discussion .discussion-container .discussion-description {
    padding: 0 40px 0 0;
  }
  #detail .review-discussion md-card-content {
    padding-top: 40px;
  }
  #detail .review-discussion md-card-content .discussion-list .list-header {
    align-items: center;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  #detail .review-discussion md-card-content .discussion-list .list-header > div {
    padding-right: 16px;
    color: rgba(0, 0, 0, 0.54);
  }
  #detail .review-discussion md-card-content .discussion-list .list-header > div:first-of-type {
    padding-left: 40px;
  }
  #detail .review-discussion md-card-content .discussion-list .list-header > div:last-of-type {
    padding-right: 0;
  }
  #detail .review-discussion md-card-content .discussion-list .list-item-placeholder {
    background: rgba(0, 0, 0, 0.12);
  }
  #detail .review-discussion md-card-content .discussion-list .list-item-placeholder * {
    opacity: 0;
  }
  #detail .review-discussion md-card-content .discussion-list .list-item {
    min-height: 48px;
    align-items: flex-start;
    padding: 16px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    flex-wrap: wrap;
  }
  #detail .review-discussion md-card-content .discussion-list .list-item > div {
    padding-right: 40px;
  }
  #detail .review-discussion md-card-content .discussion-list .list-item .topic {
    align-items: flex-start;
  }
  #detail .review-discussion md-card-content .discussion-list .list-item .topic md-checkbox {
    width: 20px;
    max-width: 20px;
    margin: 0 24px 0 0;
  }
  #detail .review-discussion md-card-content .discussion-list .list-item .topic .index {
    width: 24px;
    min-width: 24px;
    margin: 0 16px 0 0;
    position: relative;
  }
  #detail .review-discussion md-card-content .discussion-list .list-item .topic .show-log:hover:after {
    content: "Log";
    position: absolute;
    width: 100%;
    left: 0;
    background: white;
    color: #00a19c;
    cursor: pointer;
  }
  #detail .review-discussion md-card-content .discussion-list .list-item .topic .topic-name {
    display: block;
  }
  #detail .review-discussion md-card-content .discussion-list .list-item .topic p:first-of-type {
    margin-top: 0;
  }
  #detail .review-discussion md-card-content .discussion-list .list-item .topic p:last-of-type {
    margin-bottom: 0;
  }
  #detail .review-discussion md-card-content .discussion-list .list-item .date-comment {
    align-items: flex-start;
    justify-content: flex-end;
    padding-right: 0;
  }
  #detail .review-discussion md-card-content .discussion-list .list-item:last-of-type {
    padding-bottom: 16px;
    margin-bottom: 24px;
  }
  #detail .review-discussion .comment-button-container {
    width: 88px;
    min-width: 88px;
    max-width: 88px;
    align-items: center;
    text-align: center;
    display: inline-block;
    margin-top: -8px;
  }
  #detail .review-discussion:last-of-type {
    margin-bottom: 40px;
  }
  .send-minute {
    position: relative;
  }
  .send-minute .btn-send:focus + .send-list {
    display: block;
  }
  .send-minute .send-list {
    position: absolute;
    display: none;
    width: 229px;
    height: 252px;
    box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    background-color: white;
    top: -252px;
    right: 0px;
    z-index: 1;
  }
  .send-minute .send-list .send-option {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 20px;
    color: rgba(0, 0, 0, 0.85);
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    cursor: pointer;
  }
  .send-minute .send-list .send-option .img-icon {
    margin-right: 8px;
  }
  .send-minute .send-list .send-option .option-description {
    height: 28px;
    width: 158px;
    color: #6D7278;
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 10px;
    letter-spacing: 0;
    line-height: 14px;
    font-weight: normal;
  }
  .send-minute .send-list .send-option[disabled] {
    opacity: 0.3;
    cursor: not-allowed;
  }
  .send-minute .send-list:hover {
    display: block;
  }
  selected-comment:not(.general-comment) {
    display: none;
    width: 100%;
  }
}
#extractBar {
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  background-color: #68468b !important;
}
#extractBar .selected {
  padding-left: 8px;
}

md-input-container .md-errors-spacer {
  display: none;
}

.detail-loading {
  padding: 1em;
  background: white;
  color: #777;
  align-items: center;
  justify-content: center;
  font-family: "Gill sans", sans-serif;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
}
.detail-loading .loader-style {
  position: absolute;
}

#extractBar.ng-hide {
  opacity: 0;
}

#extractBar.ng-hide-add,
#extractBar.ng-hide-remove {
  transition: all ease-out 0.15s;
}

.done:hover:after,
.confirm:hover:after {
  content: "Undo?";
  position: absolute;
  width: 100%;
  left: 0;
  background: white;
  color: #fdb924;
  cursor: pointer;
}

#bar-buttons {
  display: flex;
  align-items: center;
  width: 100%;
}

.not-supported {
  padding: 2px 8px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.87);
  color: #fdb924;
}

@media (max-width: 320px) {
  .md-dialog-container .md-button {
    min-width: 90px !important;
  }
  .md-dialog-container button span {
    font-size: 9px !important;
  }
}
.md-icon-button.md-raised.md-button.md-default-theme.md-ink-ripple.md-accent {
  margin: 0px !important;
  padding: 0px !important;
}

.btn-preview {
  box-sizing: border-box;
  height: 42px;
  width: 138px;
  border: 1px solid #777;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.5);
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
}

.btn-send {
  box-sizing: border-box;
  height: 42px;
  width: 138px;
  border-radius: 4px;
  text-align: center;
  background-color: #00a19c;
  color: #FFFFFF;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  text-transform: none;
  margin: 4px 6px;
}
.btn-send:hover {
  background-color: #00a19c !important;
}
.btn-send:disabled {
  opacity: 0.38;
  cursor: not-allowed;
}

.btn-danger {
  box-sizing: border-box;
  height: 42px;
  width: 138px;
  border-radius: 4px;
  text-align: center;
  background-color: #D50000;
  color: #FFFFFF;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
}
.btn-danger:hover {
  background-color: #D50000 !important;
}

.btn-delete {
  box-sizing: border-box;
  height: 42px;
  width: 138px;
  border: 1px solid #d50000;
  border-radius: 4px;
  color: #D50000;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
}

.bottom-bar {
  height: 78px;
  background-color: #FFFFFF;
  box-shadow: 0 -5px 6px 0 rgba(0, 0, 0, 0.08);
  padding: 18px 60px;
}

.send-area {
  display: flex;
  justify-content: center;
  position: relative;
}
.send-area .send-icon {
  margin-left: 8px;
  height: 11px;
  position: relative;
  top: 2px;
}

#home-page-container {
  background-color: #fbfbfd;
  /******************************** Minutes Preview ********************************/
}
#home-page-container #list .empty-state {
  width: 100%;
  height: 100%;
  text-align: center;
  margin-top: 80px;
}
#home-page-container #list .empty-state .title {
  color: #00a19c;
}
#home-page-container #list .container {
  position: relative;
  padding: 40px 0;
  margin-left: 0;
}
#home-page-container #list .container .list-title {
  width: 90%;
  margin: 0 auto;
  font-size: 15px;
  line-height: 19px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 400;
}
#home-page-container #list .container .search-wrapper {
  width: 100%;
  height: 48px;
  padding-left: 16px;
  padding-right: 2px;
  background: white;
  border: 1px solid #d3d3d3;
  border-radius: 6px;
}
#home-page-container #list .container .search-wrapper md-icon {
  margin-right: 8px;
}
#home-page-container #list .container .search-wrapper .search-tag {
  margin-right: 8px;
  padding: 6px 12px;
  background: #3c3835;
  display: flex;
  align-items: center;
  border-radius: 16px;
  color: rgba(255, 255, 255, 0.5);
}
#home-page-container #list .container .search-wrapper .search-tag md-icon.icon-close {
  margin-right: 0px;
  margin-left: 8px;
  font-size: 14px;
  min-width: 14px;
  min-height: 14px;
  width: 14px;
  height: 14px;
  line-height: 14px;
  color: rgb(97, 97, 97);
}
#home-page-container #list .container .search-wrapper .search-bar md-autocomplete-wrap {
  box-shadow: none;
}
#home-page-container #list .container .search-wrapper .search-bar md-autocomplete-wrap input {
  padding: 0 !important;
  font-size: inherit;
}
#home-page-container #list .container .search-wrapper .search-bar md-autocomplete-wrap button {
  margin: 5px 0px 0px 0px;
}
#home-page-container #list .container .minute-list-box {
  overflow-y: scroll;
  width: 90%;
  align-items: center;
  border-bottom: 1px solid #eee;
  border-radius: 0 0 4px 4px;
}
#home-page-container #list .container .minute-list-box .white-bg {
  background-color: white;
}
#home-page-container #list .container .minute-list-box::-webkit-scrollbar {
  width: 8px;
}
#home-page-container #list .container .minute-list-box::-webkit-scrollbar-track {
  background: white;
  border: 1px solid #eee;
  border-left: none;
  border-top: none;
}
#home-page-container #list .container .minute-list-box::-webkit-scrollbar-thumb {
  background: #eee;
}
#home-page-container #list .container #listView {
  width: 100%;
  align-items: center;
  box-shadow: none;
}
#home-page-container #list .container #listView .list-container {
  position: relative;
  cursor: pointer;
}
#home-page-container #list .container #listView .list-container .sender .image {
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  line-height: 40px;
  margin: 0 16px 0 0;
  border-radius: 50%;
  text-align: center;
  color: white;
  height: 40px;
}
#home-page-container #list .container #listView .list-container .sender .sender-name-container {
  display: inline-block;
  word-break: break-all;
}
#home-page-container #list .container #listView .list-container .sender .sender-name-container .sender-displayName {
  color: #3e3f42;
  letter-spacing: 0;
  line-height: 22px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 13px;
  font-weight: 500;
  width: 100%;
  display: inline-block;
  word-break: break-all;
}
#home-page-container #list .container #listView .list-container .sender .sender-name-container .sender-email {
  color: #9EA0A5;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  width: 100%;
  display: inline-block;
  word-break: break-all;
}
#home-page-container #list .container #listView .list-container .subject-seriesname, #home-page-container #list .container #listView .list-container .subject-seriesSequenceNumber {
  color: #3E3F42;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 15px;
}
#home-page-container #list .container #listView .list-container .subject {
  margin-right: 16px;
}
#home-page-container #list .container #listView .list-container .subject div {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
#home-page-container #list .container #listView .list-container .subject .subject-title {
  color: #6D7278;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 11px;
  letter-spacing: 0;
  line-height: 15px;
  padding-top: 5px;
}
#home-page-container #list .container #listView .list-container .status {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14px;
}
#home-page-container #list .container #listView .list-container .date {
  width: auto;
  align-self: flex-start;
}
#home-page-container #list .container #listView .list-container .list-item-hidden {
  display: none;
}
#home-page-container #list .container #listView .list {
  display: flex;
  width: 100%;
  min-height: 72px;
  position: relative;
  padding: 16px;
  background: white;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
  align-items: center;
  border: 1px solid #eee;
  border-top: none;
}
#home-page-container #list .container #listView .list .list-container {
  min-height: 36px;
  align-items: center;
  cursor: pointer;
}
#home-page-container #list .container #listView .list .list-container.fixed {
  position: fixed;
  top: 64px;
  margin-left: -16px;
  background: white;
  padding: 16px;
  z-index: 1;
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.12), 0 0 2px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.24);
}
#home-page-container #list .container #listView .list .list-container.fixed .subject {
  font-size: 2rem;
  display: block !important;
  font-family: "Museo Sans 700", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
#home-page-container #list .container #listView .list .list-container.fixed .status {
  position: relative;
}
#home-page-container #list .container #listView .list .list-container .list-mobile-right {
  width: 115px;
  text-align: right;
}
#home-page-container #list .container #listView .list .list-container .list-mobile-right .status-background {
  padding: 4px 10px;
  border-radius: 12px;
  color: white;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14px;
}
#home-page-container #list .container #listView .list:last-of-type {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
#home-page-container #list .container #listView .mobile-notification {
  padding-right: 8px;
  border-right: 8px solid #00a19c !important;
}
#home-page-container #list .container #listView .list-expanded {
  width: 100%;
  z-index: 2;
  padding: 16px 16px 24px;
  border-top: transparent;
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.12), 0 0 2px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.24);
}
#home-page-container #list .container #listView .list-expanded:last-of-type {
  border-bottom: transparent;
}
@media (min-width: 600px) {
  #home-page-container #list .container #listView {
    padding: 8px 16px;
  }
  #home-page-container #list .container #listView .list-expanded {
    width: 100%;
    z-index: 2;
    padding: 16px 16px 24px;
    margin: 24px 0;
    border: none;
    box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.18), 0 0 8px rgba(0, 0, 0, 0.18), 0px 8px 16px rgba(0, 0, 0, 0.36);
  }
  #home-page-container #list .container #listView .list-expanded:first-of-type {
    margin: 0 0 24px;
  }
  #home-page-container #list .container #listView .loadmore {
    margin-top: 10px;
    color: #00a19c;
  }
}
@media (min-width: 960px) {
  #home-page-container #list .container {
    padding-left: 0;
    margin-left: 0;
  }
  #home-page-container #list .container .search-container {
    width: 90%;
    margin: 16px auto 24px;
    border-bottom: 1px solid #eee;
    padding-bottom: 25px;
  }
  #home-page-container #list .container .search-container .search-wrapper {
    width: 41%;
    height: auto;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  #home-page-container #list .container .search-container .search-focus {
    box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.14), 0 0 4px rgba(0, 0, 0, 0.14), 0px 4px 8px rgba(0, 0, 0, 0.28);
  }
  #home-page-container #list .container .search-container md-autocomplete input::-webkit-input-placeholder {
    color: #999999;
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 16px;
  }
  #home-page-container #list .container .search-container md-autocomplete input:-moz-placeholder { /* Firefox 18- */
    color: #999999;
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 16px;
  }
  #home-page-container #list .container .search-container md-autocomplete input::-moz-placeholder { /* Firefox 19+ */
    color: #999999;
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 16px;
  }
  #home-page-container #list .container .search-container md-autocomplete input:-ms-input-placeholder {
    color: #999999;
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 16px;
  }
  #home-page-container #list .container .search-container_recyclebin {
    border-bottom: none;
  }
  #home-page-container #list .container .sender {
    flex: 25;
  }
  #home-page-container #list .container .subject {
    flex: 32;
  }
  #home-page-container #list .container .meetingNo {
    flex: 15;
  }
  #home-page-container #list .container .status {
    width: 130px;
  }
  #home-page-container #list .container .status-recyclebin {
    width: 60px;
  }
  #home-page-container #list .container .date {
    flex: 15;
  }
  #home-page-container #list .container .commnent {
    width: 10px;
  }
  #home-page-container #list .container .recyclebin-action {
    width: 50px;
    display: flex;
    justify-content: space-between;
  }
  #home-page-container #list .container .recyclebin-action .icon_restore {
    width: 20px;
    height: 20px;
    background: url("./../../../../assets/icons/Restore - Normal.png") no-repeat;
  }
  #home-page-container #list .container .recyclebin-action .icon_restore:hover {
    cursor: pointer;
    background: url("./../../../../assets/icons/Restore - Hovered.png") no-repeat;
  }
  #home-page-container #list .container .recyclebin-action .icon_trash {
    width: 20px;
    height: 20px;
    background: url("./../../../../assets/icons/Trash Bin.png") no-repeat;
  }
  #home-page-container #list .container .recyclebin-action .icon_trash:hover {
    cursor: pointer;
    background: url("./../../../../assets/icons/Trash Bin_Active.png") no-repeat;
  }
  #home-page-container #list .container .header {
    width: 90%;
    height: auto;
    min-height: 0;
    max-height: none;
    padding: 12px 24px;
    margin: auto;
    background: white;
    border: 1px solid #eee;
    color: #9ea0a5;
    border-radius: 4px 4px 0 0;
  }
  #home-page-container #list .container .header .header-item {
    margin-right: 24px;
    display: flex;
  }
  #home-page-container #list .container .header .header-item .sort-icon {
    margin-left: 10px;
    margin-top: 4px;
  }
  #home-page-container #list .container .header .header-item:last-of-type {
    margin-right: 0;
  }
  #home-page-container #list .container .header .title {
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 11px;
  }
  #home-page-container #list .container .header .status {
    margin-right: 44px;
  }
  #home-page-container #list .container .header .status-recyclebin {
    margin-right: 22px;
  }
  #home-page-container #list .container #listView {
    width: 100%;
    padding: 0;
    align-items: center;
    box-shadow: none;
  }
  #home-page-container #list .container #listView .list-container .list-item {
    margin-right: 24px;
  }
  #home-page-container #list .container #listView .list-container .list-item:last-of-type {
    margin-right: 0;
  }
  #home-page-container #list .container #listView .list-container .sender {
    display: flex !important;
    align-items: center;
  }
  #home-page-container #list .container #listView .list-container .sender span {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }
  #home-page-container #list .container #listView .list-container .subject-seriesname, #home-page-container #list .container #listView .list-container .subject-seriesSequenceNumber {
    color: #3E3F42;
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 15px;
  }
  #home-page-container #list .container #listView .list-container .status {
    position: relative;
    background: none;
    display: flex;
    justify-content: space-between;
  }
  #home-page-container #list .container #listView .list-container .status .status-background {
    padding: 4px 8px;
    border-radius: 12px;
    color: white;
    font-size: 11px;
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 600;
    letter-spacing: 0;
    line-height: 14px;
  }
  #home-page-container #list .container #listView .list-container .date, #home-page-container #list .container #listView .list-container .meetingNo {
    align-self: center;
    color: #9EA0A5;
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    font-weight: 400;
  }
  #home-page-container #list .container #listView .list-container .list-item-hidden.status {
    display: none;
  }
  #home-page-container #list .container #listView .list {
    padding: 15px 24px;
  }
  #home-page-container #list .container #listView .list .list-container.fixed {
    padding: 24px;
    margin-left: -24px;
  }
  #home-page-container #list .container #listView .list .list-container.fixed .subject {
    display: block !important;
    flex: 20;
    font-size: 2rem;
    font-family: "Museo Sans 700", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
  #home-page-container #list .container #listView .list .list-container.fixed .status {
    display: block !important;
    font-size: 1.6rem;
    text-align: right;
    font-family: "Museo Sans 700", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
  #home-page-container #list .container #listView .list-expanded {
    padding: 24px;
    margin: 48px 0;
  }
  #home-page-container #list .container #listView .list-expanded:first-of-type {
    margin: 0 0 48px;
  }
}
#home-page-container #minutesContent {
  display: none;
  width: 100%;
  padding: 16px 0;
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
#home-page-container #minutesContent .minutes-review {
  align-items: center;
  padding: 8px 16px;
  margin: 0 -16px 24px;
  background: rgba(253, 185, 36, 0.12);
}
#home-page-container #minutesContent .minutes-header {
  padding: 0 16px 0 16px;
  margin-bottom: 24px;
}
#home-page-container #minutesContent .minutes-header .left .subject {
  font-size: 2.4rem;
}
#home-page-container #minutesContent .minutes-header .left .date {
  color: rgba(0, 0, 0, 0.54);
}
#home-page-container #minutesContent .minutes-header .left .location {
  color: rgba(0, 0, 0, 0.54);
}
#home-page-container #minutesContent .minutes-detail {
  padding: 8px;
  margin-bottom: 24px;
  border-left: 4px solid #00a19c;
  background-color: rgba(0, 0, 0, 0.04);
}
#home-page-container #minutesContent .minutes-detail .detail-item-mobile .detail-title {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.54);
}
#home-page-container #minutesContent .minutes-detail .detail-item-mobile .detail-content, #home-page-container #minutesContent .minutes-detail .detail-item-mobile #preview .review-view .header-detailinfo .detail-info .meeting-detail-item .detail-content .displayname, #preview .review-view .header-detailinfo .detail-info .meeting-detail-item .detail-content #home-page-container #minutesContent .minutes-detail .detail-item-mobile .displayname, #home-page-container #minutesContent .minutes-detail .detail-item-mobile .send .review-view .header-detailinfo .detail-info .meeting-detail-item .detail-content .displayname, .send .review-view .header-detailinfo .detail-info .meeting-detail-item .detail-content #home-page-container #minutesContent .minutes-detail .detail-item-mobile .displayname {
  margin-bottom: 8px;
}
#home-page-container #minutesContent .minutes-attachment {
  margin-bottom: 24px;
}
#home-page-container #minutesContent .minutes-attachment .title {
  margin-bottom: 8px;
  font-size: 16px;
}
#home-page-container #minutesContent .minutes-attachment .attachment {
  width: 64px;
  margin-right: 24px;
  font-size: 1.2rem;
  text-align: center;
  overflow: hidden;
  word-wrap: break-word;
}
#home-page-container #minutesContent .minutes-attachment .attachment img {
  width: 36px;
  height: 36px;
  margin: auto;
}
#home-page-container #minutesContent .minutes-attachment .attachment:last-of-type {
  padding: 0;
}
#home-page-container #minutesContent .minutes-discussion {
  margin-bottom: 24px;
}
#home-page-container #minutesContent .minutes-discussion .discussion-header {
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.54);
}
#home-page-container #minutesContent .minutes-discussion .discussion-item .discussion-title {
  padding: 12px 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
#home-page-container #minutesContent .view-detail {
  text-align: right;
}
#home-page-container #minutesContent table,
#home-page-container #minutesContent th,
#home-page-container #minutesContent td {
  border: 1px solid black;
}
@media (min-width: 960px) {
  #home-page-container #minutesContent #minutesContent {
    padding: 24px 16px 16px;
  }
  #home-page-container #minutesContent #minutesContent .minutes-header .right {
    text-align: right;
  }
  #home-page-container #minutesContent #minutesContent .minutes-header .right .status {
    font-size: 2rem;
  }
  #home-page-container #minutesContent #minutesContent .minutes-detail {
    margin-bottom: 40px;
  }
  #home-page-container #minutesContent #minutesContent .minutes-detail .detail-item {
    display: table-row;
  }
  #home-page-container #minutesContent #minutesContent .minutes-detail .detail-item .detail-title {
    display: table-cell;
    padding: 8px 24px 8px 8px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.38);
    text-transform: uppercase;
  }
  #home-page-container #minutesContent #minutesContent .minutes-attachment {
    margin-bottom: 40px;
  }
  #home-page-container #minutesContent #minutesContent .minutes-attachment .title {
    margin-bottom: 8px;
    font-size: 16px;
  }
  #home-page-container #minutesContent #minutesContent .minutes-attachment .attachment {
    width: 64px;
    margin-right: 24px;
    font-size: 1.2rem;
    text-align: center;
    overflow: hidden;
    word-wrap: break-word;
  }
  #home-page-container #minutesContent #minutesContent .minutes-attachment .attachment img {
    width: 36px;
    height: 36px;
    margin: auto;
  }
  #home-page-container #minutesContent #minutesContent .minutes-attachment .attachment:last-of-type {
    padding: 0;
  }
  #home-page-container #minutesContent #minutesContent .minutes-discussion {
    margin-bottom: 40px;
  }
  #home-page-container #minutesContent #minutesContent .minutes-discussion .discussion-header {
    margin-bottom: 16px;
  }
  #home-page-container #minutesContent #minutesContent .minutes-discussion .discussion-item {
    margin-bottom: 24px;
  }
  #home-page-container #minutesContent #minutesContent .minutes-discussion .discussion-item .discussion-title {
    font: 1.6rem "Museo Sans 700", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #00a19c;
    padding: 0 0 4px;
    border-bottom: 2px solid #00a19c;
    margin-bottom: 8px;
  }
  #home-page-container #minutesContent #minutesContent .minutes-discussion .discussion-item .discussion-description {
    margin-bottom: 24px;
  }
  #home-page-container #minutesContent #minutesContent .minutes-discussion .discussion-item .discussion-list .list-header {
    align-items: center;
    padding-bottom: 4px;
    margin-bottom: 8px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  }
  #home-page-container #minutesContent #minutesContent .minutes-discussion .discussion-item .discussion-list .list-header > div {
    padding-right: 16px;
    color: rgba(0, 0, 0, 0.54);
  }
  #home-page-container #minutesContent #minutesContent .minutes-discussion .discussion-item .discussion-list .list-header > div:last-of-type {
    padding: 0;
  }
  #home-page-container #minutesContent #minutesContent .minutes-discussion .discussion-item .discussion-list .list-item {
    min-height: 24px;
    align-items: center;
  }
  #home-page-container #minutesContent #minutesContent .minutes-discussion .discussion-item .discussion-list .list-item > div > div {
    padding-right: 16px;
  }
  #home-page-container #minutesContent #minutesContent .minutes-discussion .discussion-item .discussion-list .list-item > div > div:last-of-type {
    padding: 0;
  }
  #home-page-container #minutesContent #minutesContent .minutes-discussion .discussion-item .discussion-list .list-item .topic {
    align-items: center;
  }
  #home-page-container #minutesContent #minutesContent .minutes-discussion .discussion-item .discussion-list .list-item .topic md-icon {
    margin: 0 16px 0 0;
  }
  #home-page-container #minutesContent #minutesContent .minutes-discussion .discussion-item .discussion-list .list-item .topic md-checkbox {
    width: 20px;
    max-width: 20px;
    margin: 0 16px 0 0;
  }
  #home-page-container #minutesContent #minutesContent .minutes-discussion .discussion-item .discussion-list .list-item .topic .index {
    width: 20px;
    margin: 0 16px 0 0;
  }
  #home-page-container #minutesContent #minutesContent .minutes-discussion .discussion-item .discussion-list .list-item:last-of-type {
    margin-bottom: 40px;
  }
}
#home-page-container #minutesContent .topic-item {
  position: relative;
  padding: 16px;
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  text-transform: none;
}
#home-page-container #minutesContent .topic-item.topic-item-placeholder {
  background: rgba(0, 0, 0, 0.12);
}
#home-page-container #minutesContent .topic-item.topic-item-placeholder * {
  opacity: 0;
}
#home-page-container #minutesContent .topic-item .index {
  padding-left: 24px;
}
#home-page-container #minutesContent .topic-item .handle {
  display: none;
  position: absolute;
  height: 100%;
  width: 24px;
  top: 0;
  left: 0;
  cursor: move;
  text-align: center;
}
#home-page-container #minutesContent .topic-item .handle md-icon {
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
#home-page-container #minutesContent .topic-item-ghost {
  opacity: 1 !important;
  background: #ffffff;
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  border: none;
}
#home-page-container #minutesContent .topic-item-ghost .md-ripple-container {
  display: none;
}
#home-page-container #minutesContent .topic-item-ghost .comment-content {
  display: none;
}
#home-page-container #minutesContent .bg-avatar {
  height: 40px;
  width: 40px;
}
#home-page-container .title-minutes {
  height: 112px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
#home-page-container .title-minutes h1 {
  margin: 0 5%;
  height: 32px;
  color: #000000;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 112px;
}

.tooltip-action span {
  font-size: 10px !important;
}

#todo {
  overflow: hidden;
  height: 100%;
  min-height: 100%;
}
#todo .container {
  height: 100%;
}
#todo .empty-state {
  width: 100%;
  height: 100%;
  text-align: center;
  margin: 24px 0;
}
#todo .empty-state .title {
  color: #00a19c;
}
#todo .search-wrapper {
  position: relative;
  width: 100%;
  height: 48px;
  min-height: 48px;
  line-height: 48px;
  padding: 0 16px;
  background: white;
  margin-bottom: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
#todo .search-wrapper .progress-linear {
  position: absolute;
  bottom: 0px;
  left: 0px;
}
#todo .search-wrapper .progress-linear .md-mode-indeterminate {
  height: 3px;
}
#todo .search-wrapper md-icon {
  margin-right: 8px;
}
#todo .search-wrapper .search-bar md-autocomplete-wrap {
  box-shadow: none;
}
#todo .search-wrapper .search-bar::-ms-clear {
  display: none !important;
}
#todo .search-wrapper input::-ms-clear {
  display: none !important;
}
#todo .view {
  height: 48px;
  min-height: 48px;
  line-height: 48px;
  text-align: right;
}
#todo .view span {
  margin-right: 16px;
}
#todo .view md-icon {
  margin-right: 4px;
}
#todo .view .selected {
  color: #00a19c;
}
#todo .todo-list {
  overflow: auto;
  margin: 0;
  background: white;
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.12), 0 0 2px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.24);
}
#todo .todo-list .header {
  height: 48px;
  min-height: 48px;
  line-height: 48px;
  align-items: center;
  padding: 0 128px 0 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
#todo .todo-list .header-by-me {
  padding-right: 128px;
}
#todo .todo-list .content {
  overflow-y: overlay;
}
#todo .todo-list .content .topic {
  margin: 0 16px 8px 0;
}
#todo .todo-list .content .due-date {
  color: rgba(0, 0, 0, 0.54);
}
#todo .todo-list .content .single-list {
  position: relative;
  align-items: flex-start;
}
#todo .todo-list .content .single-list md-checkbox .md-label {
  display: block;
  margin-left: 40px;
}
#todo .todo-list .content .single-list .todo-item {
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
#todo .todo-list .content .single-list .todo-item .tag-container {
  margin: 8px 0 0;
}
#todo .todo-list .content .single-list .todo-item .tag-container .tag {
  cursor: pointer;
  padding: 2px 4px;
  margin-right: 8px;
  background: rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}
#todo .todo-list .content .single-list .todo-item .topic p {
  margin: 0px;
  font-family: "Museo Sans 700", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.6rem;
}
#todo .todo-list .content .single-list .pet-green {
  color: #00a19c;
}
#todo .todo-list .content .single-list .pet-green-disabled {
  color: rgba(0, 161, 156, 0.38);
}
#todo .todo-list .content .single-list .done {
  position: relative;
  width: 104px;
  text-align: center;
}
#todo .todo-list .content .single-list .done:hover:after {
  content: "Undo?";
  position: absolute;
  width: 100%;
  left: 0;
  background: white;
  font-family: "Museo Sans 300", sans-serif;
  font-size: 1.3rem;
  color: #fdb924;
  cursor: pointer;
}
#todo .todo-list .content .group-list {
  padding: 16px;
}
#todo .todo-list .content .group-list .title {
  font-weight: bold;
  padding-bottom: 10px;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.54);
}
#todo .todo-list .content .group-list .item {
  display: flex;
  align-items: flex-start;
  padding: 10px 0 10px 15px;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.12);
}
#todo .todo-list .content .group-list .item .checked-completed {
  width: 48px;
  margin-top: 0;
  margin-right: 16px;
  text-align: center;
  color: #00a19c;
}
#todo .todo-list .content .group-list .item .topic {
  display: flex;
}
#todo .todo-list .content .group-list .item .topic p {
  margin: 0px;
  font-size: 1.6rem;
}
#todo .todo-list .content .group-list .item:last-of-type {
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
}
#todo .todo-list .content .group-list .closed {
  position: relative;
  width: 104px;
  text-align: center;
}
#todo .todo-list .md-button {
  margin-top: 0;
}
#todo .todo-list .width-104 {
  width: 104px;
}
#todo .todo-list .content::-webkit-scrollbar {
  width: 8px;
}
#todo .todo-list .content::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

#todo-new .container {
  padding: 40px 0;
}
#todo-new .container .action-tabs {
  margin: 0 5%;
  border-bottom: 1px solid #E2E5ED;
  padding-bottom: 30px;
}
#todo-new .container .action-tabs .tab-item {
  box-sizing: border-box;
  height: 81px;
  width: 361px;
  border: 1px solid #00A19C;
  border-radius: 4px;
  background-color: #00A19C;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.07);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
#todo-new .container .action-tabs .tab-item .tab-name {
  color: #FFFFFF;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  margin-left: 9%;
}
#todo-new .container .action-tabs .tab-item .action-number {
  height: 44px;
  width: 44px;
  background-color: #FFFFFF;
  border-radius: 50%;
  padding: 9px;
  text-align: center;
  margin-right: 4%;
}
#todo-new .container .action-tabs .tab-item .action-number span {
  color: #00A19C;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}

@media (min-width: 600px) {
  #todo .view, #todo .todo-list {
    width: 90%;
    margin: 0 auto;
  }
}
@media (min-width: 960px) {
  #todo .container {
    padding: 24px 24px 0;
  }
  #todo .container .search-container {
    width: 90%;
    margin: 16px auto 24px;
  }
  #todo .container .search-container .search-wrapper {
    width: 40%;
    height: 40px;
    min-height: 40px;
    line-height: 40px;
    align-self: flex-end;
    margin: auto;
    margin-right: 0;
    border-bottom: none;
    box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.12), 0 0 2px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.24);
  }
  #todo .container .search-container .search-focus {
    box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.14), 0 0 4px rgba(0, 0, 0, 0.14), 0px 4px 8px rgba(0, 0, 0, 0.28);
  }
  #todo .container .assigned {
    width: 160px;
    max-width: 160px;
    margin-right: 16px;
  }
  #todo .container .content .topic {
    margin: 0 16px 0 0;
  }
  #todo .container .content .due-date {
    width: 48px;
    text-align: center;
    margin-right: 16px;
  }
  #todo-new .container {
    padding: 24px 24px 0;
  }
  #todo-new .container .search-container {
    width: 90%;
    margin: 16px auto 24px;
  }
  #todo-new .container .search-container .search-wrapper {
    width: 40%;
    height: 40px;
    min-height: 40px;
    line-height: 40px;
    align-self: flex-start;
    margin: auto;
    margin-left: 0;
    border-bottom: none;
    box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.12), 0 0 2px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.24);
  }
  #todo-new .container .search-container .search-focus {
    box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.14), 0 0 4px rgba(0, 0, 0, 0.14), 0px 4px 8px rgba(0, 0, 0, 0.28);
  }
  #todo-new .container .assigned {
    width: 160px;
    max-width: 160px;
    margin-right: 16px;
  }
  #todo-new .container .content .topic {
    margin: 0 16px 0 0;
  }
  #todo-new .container .content .due-date {
    width: 48px;
    text-align: center;
    margin-right: 16px;
  }
}
.todo-loader {
  height: 50px;
  padding: 1em;
  margin: 0 auto 1em;
  display: inline-block;
  vertical-align: top;
  /*
    Set the color of the icon
  */
}
.todo-loader svg path,
.todo-loader svg rect {
  fill: #00A19C;
}
.todo-loader svg {
  width: 40px;
  height: 40px;
}

#tnc {
  padding: 32px;
}
#tnc strong {
  color: #00a19c;
  font-family: "Museo Sans 700", sans-serif;
}

#create-myminutes {
  background-color: #fbfbfd;
}
#create-myminutes .title {
  font-weight: bold;
  padding: 30px 0 50px 0;
  padding-left: 60px;
  background-color: white;
  margin-bottom: 2px;
  margin-top: 0;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
}
#create-myminutes .bg-create-minute {
  background-color: #fbfbfd;
  height: 99%;
  margin-top: 2px;
}
#create-myminutes md-tab-item {
  height: 56px;
  text-transform: none;
}
#create-myminutes md-tab-item.md-tab {
  color: #6d7278;
}
#create-myminutes md-tab-item.md-tab span {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
}
#create-myminutes md-tab-item.md-tab.md-active {
  color: #000000;
}
#create-myminutes md-tab-item:first-child {
  padding-left: 60px;
}
#create-myminutes .md-disabled {
  color: #6d7278 !important;
  cursor: not-allowed !important;
  opacity: 1;
  pointer-events: none;
}
#create-myminutes .disable-012 {
  color: #b3e3e1 !important;
}
#create-myminutes .enable-3 {
  color: #00a19c !important;
  cursor: pointer;
}
#create-myminutes md-pagination-wrapper {
  width: 100% !important;
  height: 56px;
  cursor: not-allowed;
}
#create-myminutes md-tabs {
  height: 80vh;
}
#create-myminutes md-content {
  height: 100%;
}
#create-myminutes md-ink-bar {
  height: 4px;
}
#create-myminutes md-tabs-wrapper {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !important;
  height: 56px;
}
#create-myminutes md-tabs-canvas {
  height: 56px;
}
#create-myminutes md-tabs-content-wrapper {
  top: 58px;
}
#create-myminutes .btn-border-gray {
  box-sizing: border-box;
  border: 1px solid #e2e5ed;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.5);
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
}
#create-myminutes .btn-container {
  position: absolute;
  right: calc(10% - 38px);
  z-index: 1;
}
#create-myminutes .btn-container .btn-save {
  margin: 8px 0;
  padding: 11.5px 15.5px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.07);
  height: 41px;
  width: 82px;
}
#create-myminutes .btn-container .btn-save .content-area span {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
}

.btn-border-gray {
  box-sizing: border-box;
  border: 1px solid #e2e5ed;
  border-radius: 4px;
  box-shadow: none;
  color: #787878;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  text-transform: none;
}

.toast-top-right {
  top: 95px;
  right: 36px;
}

#toast-container > div {
  box-shadow: none;
  height: auto !important;
  padding: 15px 15px 10px 50px;
}

#toast-container > :hover {
  box-shadow: none;
}

md-tab-data {
  opacity: 1;
  display: table;
  right: 0;
  left: initial;
  z-index: 20;
}

.logger-error-empty-field {
  color: #d50000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  vertical-align: middle;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .logger-error-empty-field {
    color: #d50000;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    vertical-align: middle;
  }
}
#for-admin .admin-nav {
  height: 56px;
  background: #fff;
  border-bottom: 4px solid #00A19C;
  padding: 20px 60px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}
#for-admin .for-admin-container {
  padding: 60px;
  min-height: 91%;
}
#for-admin .for-admin-container .lop {
  height: 19px;
  color: #000000;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  margin-top: 24px;
  margin-bottom: 24px;
}
#for-admin .for-admin-container .lop .minute-no {
  height: 15px;
  width: 163px;
  color: #999999;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  text-align: right;
  float: right;
  font-weight: 400;
}

#bar-buttons .send-minute .send-area span {
  color: #ffffff;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
}
#bar-buttons .send-minute .send-area .next-icon {
  position: relative;
  top: 4px;
  margin-left: 14px;
}
#bar-buttons .send-minute .send-area .saving-area {
  font-size: 14px;
}
#bar-buttons .btn-cancel {
  height: 42px;
  width: 138px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
}

#maintenance {
  height: 100%;
  background: url("/assets/images/backgrounds/march.jpg") no-repeat;
  background-size: cover;
}
#maintenance #maintenance-form-wrapper {
  flex: 1 0 auto;
  padding: 32px;
}
#maintenance #maintenance-form-wrapper #maintenance-form {
  max-width: 384px;
  padding: 32px;
  background: #FFFFFF;
  text-align: center;
}
#maintenance #maintenance-form-wrapper #maintenance-form .logo {
  width: 128px;
  height: 128px;
  line-height: 128px;
  font-size: 86px;
  font-weight: 500;
  margin: 32px auto;
  color: rgb(255, 255, 255);
  border-radius: 2px;
}
#maintenance #maintenance-form-wrapper #maintenance-form .title {
  font-size: 17px;
  margin-top: 16px;
}
#maintenance #maintenance-form-wrapper #maintenance-form .subtitle {
  margin: 16px 0;
  max-width: 300px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 15px;
}

@media screen and (max-width: 600px) {
  #maintenance #maintenance-form-wrapper {
    padding: 16px;
  }
  #maintenance #maintenance-form-wrapper #maintenance-form {
    padding: 24px;
    width: 100%;
  }
}
.nav {
  margin: auto;
}

#vertical-navigation {
  width: 240px;
  min-width: 240px;
  max-width: 240px;
  overflow-x: hidden;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2), width 0.1s linear, min-width 0.1s linear, max-width 0.1s linear;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  background-color: #171717 !important;
}
#vertical-navigation .navigation-header {
  height: 64px;
  min-height: 64px;
  background-color: #000000 !important;
  padding: 0 16px 0 24px;
}
#vertical-navigation .navigation-header .logo {
  min-width: 36px !important;
}
#vertical-navigation .navigation-header .logo .logo-image {
  margin: 0 2px 0 0;
  display: block;
  width: 28.69px !important;
  height: 35.54px !important;
  line-height: 54px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 2px;
}
#vertical-navigation .navigation-header .logo .logo-image img {
  width: 100% !important;
  height: auto;
  object-fit: cover;
}
#vertical-navigation .navigation-header .logo .logo-text {
  font-weight: bold;
  color: white;
}
#vertical-navigation .navigation-header .fold-toggle {
  transition: transform 0.3s ease-in-out 0.1s;
  transform: rotate(0deg);
  margin: 0;
  padding: 8px;
  width: 34px !important;
  height: 34px !important;
  cursor: pointer;
}
#vertical-navigation:not(.md-locked-open) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

@media only screen and (min-width: 960px) {
  .ms-navigation-folded #content-container {
    margin-left: 64px;
  }
  .ms-navigation-folded #vertical-navigation {
    position: absolute;
  }
  .ms-navigation-folded #vertical-navigation .navigation-header .fold-toggle {
    transform: rotate(180deg);
    opacity: 0;
  }
  .ms-navigation-folded.ms-navigation-folded-open #vertical-navigation .navigation-header .fold-toggle {
    transition: transform 0.3s ease-in-out 0.1s, opacity 0.3s ease-in-out 0.2s;
    opacity: 1;
  }
  .ms-navigation-folded:not(.ms-navigation-folded-open) #vertical-navigation {
    position: absolute;
    width: 64px;
    min-width: 64px;
    max-width: 64px;
  }
  .ms-navigation-folded:not(.ms-navigation-folded-open) #vertical-navigation .navigation-header {
    padding: 0 16px;
  }
  .ms-navigation-folded:not(.ms-navigation-folded-open) #vertical-navigation .navigation-header .logo-text {
    display: none;
  }
}
#edit md-card {
  background: white;
}
#edit .detail-header {
  margin-bottom: 24px;
}
#edit .detail-header .review-view .header {
  padding: 0 8px;
  margin-bottom: 8px;
}
#edit .detail-header .review-view .meeting-detail {
  padding: 8px;
  border-left: 4px solid #00a19c;
  background-color: rgba(255, 255, 255, 0.7);
}
#edit .detail-header .review-view .meeting-detail .md-button {
  float: right;
}
#edit .detail-header .review-view .meeting-detail .meeting-detail-item .detail-title {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.54);
}
#edit .detail-header .review-view .meeting-detail .meeting-detail-item .detail-content, #edit .detail-header #preview .review-view .meeting-detail .header-detailinfo .detail-info .meeting-detail-item .detail-content .displayname, #preview #edit .detail-header .review-view .meeting-detail .header-detailinfo .detail-info .meeting-detail-item .detail-content .displayname, #edit .detail-header #preview .review-view .header-detailinfo .detail-info .meeting-detail .meeting-detail-item .detail-content .displayname, #preview #edit .detail-header .review-view .header-detailinfo .detail-info .meeting-detail .meeting-detail-item .detail-content .displayname, #edit .detail-header .send .review-view .meeting-detail .header-detailinfo .detail-info .meeting-detail-item .detail-content .displayname, .send #edit .detail-header .review-view .meeting-detail .header-detailinfo .detail-info .meeting-detail-item .detail-content .displayname, #edit .detail-header .send .review-view .header-detailinfo .detail-info .meeting-detail .meeting-detail-item .detail-content .displayname, .send #edit .detail-header .review-view .header-detailinfo .detail-info .meeting-detail .meeting-detail-item .detail-content .displayname {
  margin-bottom: 8px;
}
#edit .attachment-header {
  margin-bottom: 16px;
}
#edit .minutes-attachment {
  overflow-x: scroll;
  padding: 24px;
  margin-bottom: 24px;
}
#edit .minutes-attachment::-webkit-scrollbar, #edit .minutes-attachment .md-virtual-repeat-scroller::-webkit-scrollbar {
  height: 8px;
}
#edit .minutes-attachment::-webkit-scrollbar-thumb, #edit .minutes-attachment .md-virtual-repeat-scroller::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
#edit .minutes-attachment .attachment-container {
  align-self: flex-start;
  align-items: center;
}
#edit .minutes-attachment .attachment-container .attachment {
  width: 72px;
  margin-right: 8px;
  position: relative;
}
#edit .minutes-attachment .attachment-container .attachment .file-icon {
  width: 36px;
  height: 36px;
  margin: auto;
  margin-bottom: 4px;
  font-size: 36px;
  line-height: normal;
}
#edit .minutes-attachment .attachment-container .attachment .delete-icon {
  right: 0;
  position: absolute;
  display: none;
}
#edit .minutes-attachment .attachment-container .attachment:hover .delete-icon {
  display: block;
}
#edit .minutes-attachment .attachment-container .attachment .file-name {
  font-size: 1.2rem;
  text-align: center;
  display: inline !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: nowrap;
}
#edit .minutes-attachment .attachment-container .attachment:last-of-type {
  padding: 0;
}
#edit .topic-attachment-container {
  white-space: nowrap;
  display: block;
  padding: 16px;
  overflow-x: scroll !important;
}
#edit .topic-attachment-container::-webkit-scrollbar, #edit .topic-attachment-container .md-virtual-repeat-scroller::-webkit-scrollbar {
  height: 8px;
}
#edit .topic-attachment-container::-webkit-scrollbar-thumb, #edit .topic-attachment-container .md-virtual-repeat-scroller::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
#edit .topic-attachment {
  display: inline-block;
  position: relative;
  width: 96px;
  margin-right: 8px;
}
#edit .topic-attachment .delete-icon {
  position: absolute;
  right: 0;
  display: none;
}
#edit .topic-attachment:hover .delete-icon {
  display: block;
}
#edit .topic-attachment .file-icon {
  width: 36px;
  height: 36px;
  margin: auto;
  margin-bottom: 4px;
  font-size: 36px;
  line-height: normal;
}
#edit .topic-attachment .file-name {
  width: 96px;
  font-size: 1.2rem;
  text-align: center;
  display: inline !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: nowrap;
}
#edit .comment-button {
  position: relative;
  border-radius: 0 !important;
}
#edit .comment-button .comment-count {
  position: absolute;
  top: 0px;
  right: 0px;
  line-height: 16px;
  font-size: 12px;
  border-radius: 16px;
  width: 16px;
  height: 16px;
  color: white;
  background: #00a19c;
  text-align: center;
}
#edit .attachment-button {
  position: relative;
  border-radius: 0 !important;
}
#edit .attachment-button .attachment-count {
  position: absolute;
  top: -8px;
  right: -4px;
  line-height: 16px;
  font-size: 12px;
  border-radius: 16px;
  width: 16px;
  height: 16px;
  color: white;
  background: #00a19c;
  text-align: center;
}

.md-button.disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

@media (min-width: 960px) {
  #edit .header {
    align-items: flex-end;
    margin-bottom: 16px;
  }
  #edit .header .right {
    text-align: right;
  }
  #edit .detail-header {
    margin-bottom: 40px;
  }
  #edit .detail-header .review-view .header {
    padding: 0 16px;
    margin-bottom: 24px;
  }
  #edit .detail-header .review-view .header .right {
    text-align: right;
  }
  #edit .detail-header .review-view .meeting-detail .meeting-detail-item {
    display: table-row;
  }
  #edit .detail-header .review-view .meeting-detail .meeting-detail-item .detail-title {
    display: table-cell;
    padding: 8px 24px 8px 8px;
    font-family: "Museo Sans", sans-serif;
    font-size: 1.4rem;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.38);
    text-transform: uppercase;
  }
  #edit .detail-header .edit-view .main-detail {
    margin: 0;
  }
  #edit .detail-header .edit-view .main-detail .meeting-detail {
    padding: 24px 24px 0;
    margin-bottom: 24px;
  }
  #edit .detail-header .edit-view .main-detail .meeting-detail .series-container {
    margin-bottom: 18px;
  }
  #edit .detail-header .edit-view .main-detail .meeting-detail .series-container .label {
    padding-left: 3px;
    transform: scale(0.75);
    transform-origin: left top;
    color: rgba(0, 0, 0, 0.38);
  }
  #edit .detail-header .edit-view .main-detail .meeting-detail .series-container .series {
    height: 30px;
    line-height: 30px;
    border-bottom: 1px dotted rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.38);
  }
  #edit .detail-header .edit-view .main-detail .meeting-detail .title-container {
    padding-left: 0;
  }
  #edit .detail-header .edit-view .main-detail .meeting-detail mdp-time-picker {
    margin-right: 24px;
  }
  #edit .detail-header .edit-view .main-detail .meeting-detail .date mdp-date-picker {
    margin-right: 80px;
  }
  #edit .detail-header .edit-view .main-detail .meeting-detail .date mdp-time-picker {
    margin-right: 24px;
  }
  #edit .detail-header .edit-view .main-detail .meeting-detail .date mdp-date-picker md-input-container, #edit .detail-header .edit-view .main-detail .meeting-detail .date mdp-time-picker md-input-container {
    width: auto;
  }
  #edit .detail-header .edit-view .main-detail .meeting-detail .date mdp-date-picker .md-button, #edit .detail-header .edit-view .main-detail .meeting-detail .date mdp-time-picker .md-button {
    display: none;
  }
  #edit .detail-header .edit-view .main-detail .people-detail {
    padding: 0 24px 24px;
  }
  #edit .detail-header .edit-view .main-detail .people-detail .header {
    margin-bottom: 16px;
    color: rgba(0, 0, 0, 0.54);
  }
  #edit .detail-header .edit-view .main-detail .people-detail .body {
    padding: 0 16px;
  }
  #edit .detail-header .edit-view .main-detail .people-detail .body .action-area div {
    padding: 16px 0;
    margin-right: 40px;
    color: #00a19c;
    align-items: center;
    display: flex;
  }
  #edit .detail-header .edit-view .main-detail .people-detail .body .action-area div md-icon {
    margin-right: 4px;
    color: #00a19c;
  }
  #edit .detail-header .edit-view .main-detail .people-detail .body md-chip {
    padding-left: 40px;
  }
  #edit .detail-header .edit-view .main-detail .people-detail .body md-chip .chip-image {
    position: absolute;
    left: 0;
    width: 32px;
    height: 32px;
    line-height: 32px;
    border: 0.1px solid rgba(0, 0, 0, 0.01);
    border-radius: 50%;
  }
  #edit .detail-header .edit-view .main-detail .people-detail .body md-chip .chip-initial {
    background: rgba(0, 0, 0, 0.38);
    text-align: center;
    color: white;
  }
  #edit .detail-header .edit-view .main-detail md-input-container {
    width: 100%;
  }
  #edit .detail-header .edit-view .main-detail md-input-container md-chips md-chips-wrap {
    padding: 0 0 4px;
  }
  #edit .detail-header .edit-view .main-detail md-input-container md-chips md-chips-wrap .md-chip-input-container {
    margin: 4px 8px 0 0;
  }
  #edit .detail-header .edit-view .main-detail md-input-container md-chips md-chips-wrap .md-chip-input-container md-autocomplete md-autocomplete-wrap input {
    width: 365px;
    padding: 2px 2px 1px !important;
  }
  #edit .detail-header .edit-view .main-detail md-input-container md-chips md-chips-wrap md-chip {
    height: 32px;
    line-height: 32px;
    padding-right: 28px;
    margin: 4px 8px 0 0;
  }
  #edit .detail-header .edit-view .main-detail md-input-container md-chips md-chips-wrap md-chip md-chip-template strong {
    font-weight: normal;
  }
  #edit .detail-header .edit-view .main-detail md-input-container md-chips md-chips-wrap md-chip .md-chip-remove-container {
    line-height: normal;
  }
  #edit .detail-header .edit-view .main-detail md-input-container md-chips md-chips-wrap md-chip .md-chip-remove-container button {
    width: 24px;
    height: 24px;
    margin: 4px;
  }
  #edit .detail-header .edit-view .main-detail md-input-container md-chips md-chips-wrap md-chip .md-chip-remove-container button md-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #edit .detail-header .edit-view .main-detail md-input-container md-chips md-chips-wrap md-chip .md-chip-remove-container button icon-close-circle {
    margin-right: 16px;
  }
  #edit .detail-header .edit-view .main-detail md-input-container md-chips md-chips-wrap md-chip .md-chip-remove-container button .icon-close:before {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.38);
  }
  #edit .detail-header .edit-view .main-detail md-input-container md-chips md-chips-wrap md-chip .md-chip-remove-container button .icon-close-circle:before {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.38);
  }
  #edit .detail-header .edit-view .main-detail md-input-container md-chips md-chips-wrap md-chip .md-chip-remove-container button:hover md-icon:before {
    color: #d9272c;
  }
  #edit .detail-header .edit-view .main-detail md-input-container .md-chips:not(.md-readonly) .md-chip:not(.md-readonly) {
    padding-right: 28px;
  }
  #edit .minutes-attachment {
    margin-bottom: 40px !important;
  }
  #edit .minutes-attachment .title {
    margin-bottom: 16px;
  }
  #edit .minutes-attachment .attachment-container .attachment {
    width: 96px;
    margin-right: 16px;
    position: relative;
  }
  #edit .minutes-attachment .attachment-container .attachment .file-icon {
    width: 48px;
    height: 48px;
    margin: auto;
    margin-bottom: 8px;
    font-size: 48px;
    line-height: normal;
  }
  #edit .minutes-attachment .attachment-container .attachment .delete-icon {
    right: 0;
    position: absolute;
    display: none;
  }
  #edit .minutes-attachment .attachment-container .attachment:hover .delete-icon {
    display: block;
  }
  #edit .minutes-attachment .attachment-container .attachment .file-name {
    font-size: 1.2rem;
    text-align: center;
    display: inline !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: nowrap;
  }
  #edit .minutes-attachment .attachment-container .attachment:last-of-type {
    padding: 0;
  }
  #edit .minutes-attachment .add-attachment {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    margin-right: 16px;
    border: 2px dashed #00a19c;
    color: #00a19c;
  }
  #edit .minutes-attachment .add-attachment .add-button {
    margin: 0 0 8px;
    color: #00a19c;
  }
  #edit .minutes-attachment .add-attachment .add-button:disabled {
    cursor: not-allowed;
  }
  #edit .minutes-attachment .add-attachment #fileInput {
    display: none;
  }
  #edit .minutes-attachment .attachment a {
    color: #00a19c;
  }
  #edit .agenda-header {
    margin-bottom: 16px;
  }
  #edit .agenda {
    margin-bottom: 40px;
    padding: 24px;
  }
  #edit .agenda .agenda-item {
    display: flex;
    align-items: center;
    margin: 0;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
  }
  #edit .agenda .agenda-item .md-resize-wrapper {
    width: 100%;
  }
  #edit .minutes-discussion {
    position: relative;
    padding: 24px;
    background: white;
  }
  #edit .minutes-discussion .discussion-point:first-of-type {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
  #edit .minutes-discussion .discussion-point {
    padding: 0 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  #edit .minutes-discussion .discussion-point md-input-container {
    background: white;
  }
  #edit .minutes-discussion .discussion-point .discussion-header .header-main .title {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
  }
  #edit .minutes-discussion .discussion-point .discussion-header .header-main .title input::-webkit-input-placeholder {
    font-size: 2rem;
  }
  #edit .minutes-discussion .discussion-point .discussion-header .header-main .title.title-focused {
    border: 2px solid rgba(0, 0, 0, 0.87);
    padding: 1px;
  }
  #edit .minutes-discussion .discussion-point .discussion-header .header-main md-input-container input, #edit .minutes-discussion .discussion-point .discussion-header .header-main md-input-container md-select-value {
    padding-bottom: 1px !important;
    border: none;
  }
  #edit .minutes-discussion .discussion-point .discussion-header .header-main md-input-container input::-webkit-input-placeholder {
    font-size: 1.3rem;
  }
  #edit .minutes-discussion .discussion-point .discussion-header .header-main .discussion-title {
    border-style: none;
  }
  #edit .minutes-discussion .discussion-point .discussion-header .header-main .discussion-index {
    background: none;
  }
  #edit .minutes-discussion .discussion-point .discussion-header .header-main .discussion-index md-select md-select-value {
    min-width: 48px;
  }
  #edit .minutes-discussion .discussion-point .discussion-header .header-main .discussion-index md-select md-select-value .md-text {
    font-size: 1.8rem;
  }
  #edit .minutes-discussion .discussion-point .discussion-header .header-main .discussion-index md-select md-select-value .md-select-icon {
    width: auto;
  }
  #edit .minutes-discussion .discussion-point .discussion-header .add-attachment {
    display: flex;
    align-items: center;
    color: #00a19c;
  }
  #edit .minutes-discussion .discussion-point .discussion-header .add-attachment input {
    display: none;
  }
  #edit .minutes-discussion .discussion-point .discussion-header .add-attachment.disabled .icon-paperclip {
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.21);
  }
  #edit .minutes-discussion .discussion-point .discussion-header .discussion-description {
    display: flex;
    align-items: center;
    min-height: 72px;
    margin: 0 0 16px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
  }
  #edit .minutes-discussion .discussion-point .discussion-header .discussion-description .md-resize-wrapper {
    width: 100%;
  }
  #edit .minutes-discussion .discussion-point .discussion-item {
    padding: 24px 0;
  }
  #edit .minutes-discussion .discussion-point .discussion-item .discussion-list {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
  #edit .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item-placeholder {
    background: rgba(0, 0, 0, 0.12) !important;
    border-bottom: 1px solid transparent !important;
  }
  #edit .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item-placeholder * {
    opacity: 0;
  }
  #edit .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item {
    position: relative;
    align-items: center;
    padding: 16px;
    margin: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    flex-wrap: wrap;
  }
  #edit .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item md-input-container {
    margin: 0;
  }
  #edit .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .action-area .md-resize-wrapper, #edit .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .action-area input, #edit .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .md-select-value {
    border: none;
  }
  #edit .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .topic {
    align-items: center;
  }
  #edit .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .topic md-icon {
    margin: 0 16px 0 0;
    cursor: move;
  }
  #edit .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .topic md-checkbox {
    width: 20px;
    max-width: 20px;
    margin: 0 16px 0 0;
  }
  #edit .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .topic .index {
    width: 20px;
    margin: 0 16px 0 0;
    position: relative;
  }
  #edit .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .topic .show-log:hover:after {
    content: "Log";
    position: absolute;
    width: 100%;
    left: 0;
    background: rgb(245, 245, 245);
    color: #00a19c;
    cursor: pointer;
  }
  #edit .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .topic .topic-description {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
  }
  #edit .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .action-area {
    width: 240px;
    align-items: center;
    justify-content: flex-end;
  }
  #edit .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .action-area md-input-container {
    background: none;
  }
  #edit .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .subtopic {
    border: 1px solid rgba(0, 0, 0, 0.12);
    background: white;
  }
  #edit .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .subtopic .subtopic-dueDate {
    background: white;
    color: rgba(0, 0, 0, 0.38);
    padding-left: 8px;
    padding-right: 8px;
    height: 36px;
    display: flex;
    align-items: center;
  }
  #edit .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item md-autocomplete {
    min-width: auto;
    height: 40px;
    line-height: 40px;
    padding: 3px;
    margin-bottom: 4px;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  #edit .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item md-autocomplete md-autocomplete-wrap {
    box-shadow: none;
    height: 32px;
    line-height: 32px;
  }
  #edit .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item md-autocomplete md-autocomplete-wrap input {
    height: 32px;
    line-height: 32px;
    padding: 0 4px !important;
  }
  #edit .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item md-autocomplete md-autocomplete-wrap input::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
  #edit .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item md-autocomplete md-autocomplete-wrap button {
    width: 24px;
    height: 24px;
    margin: 4px;
  }
  #edit .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item md-autocomplete.selectedChip md-autocomplete-wrap {
    padding: 0 8px;
    border-radius: 32px;
    background: rgb(224, 224, 224);
  }
  #edit .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item md-autocomplete.selectedChip md-autocomplete-wrap input {
    border-radius: 32px;
    background: rgb(224, 224, 224);
  }
  #edit .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item md-autocomplete.selectedChip md-autocomplete-wrap md-icon {
    width: 18px;
    min-width: 18px;
    height: 18px;
    min-height: 18px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.54);
  }
  #edit .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item mdp-date-picker button {
    display: none;
  }
  #edit .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item mdp-date-picker input {
    font-family: "Museo Sans 700", sans-serif !important;
  }
  #edit .minutes-discussion .discussion-point .discussion-item .discussion-list .list-item .comment-box {
    margin: 8px 0;
  }
  #edit .minutes-discussion .discussion-point .add-discussion-item {
    display: flex;
    align-items: center;
    margin-top: 16px;
    color: #00a19c;
    cursor: pointer;
  }
  #edit .minutes-discussion .discussion-point .add-discussion-item md-icon {
    margin: 0 4px 0 0;
    color: #00a19c;
  }
  #edit .minutes-discussion .discussion-point-expanded {
    border: none;
    box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.12), 0 0 2px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.24);
    background: rgba(0, 0, 0, 0.04);
    margin-bottom: 2px;
  }
  #edit .minutes-discussion .add-discussion:first-of-type {
    margin-top: 0px;
  }
  #edit .button-green {
    float: right;
  }
}
.list-item-ghost {
  align-items: center;
  padding: 16px;
  background: white;
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.12), 0 0 2px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.24);
  flex-wrap: wrap;
}
.list-item-ghost md-input-container {
  margin: 0;
}
.list-item-ghost .md-resize-wrapper, .list-item-ghost input, .list-item-ghost .md-select-value {
  border: none;
}
.list-item-ghost .topic {
  align-items: center;
}
.list-item-ghost .topic md-icon {
  margin: 0 16px 0 0;
  cursor: move;
}
.list-item-ghost .topic md-checkbox {
  width: 20px;
  max-width: 20px;
  margin: 0 16px 0 0;
}
.list-item-ghost .topic .index {
  width: 20px;
  margin: 0 16px 0 0;
}
.list-item-ghost .topic .topic-description {
  padding: 8px 16px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.list-item-ghost .action-area {
  width: 240px;
  align-items: center;
  justify-content: flex-end;
}
.list-item-ghost .action-area md-input-container {
  background: none;
}
.list-item-ghost md-autocomplete {
  min-width: auto;
  height: 40px;
  line-height: 40px;
  padding: 3px;
  margin-bottom: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.list-item-ghost md-autocomplete md-autocomplete-wrap {
  box-shadow: none;
  height: 32px;
  line-height: 32px;
}
.list-item-ghost md-autocomplete md-autocomplete-wrap input {
  height: 32px;
  line-height: 32px;
}
.list-item-ghost md-autocomplete md-autocomplete-wrap input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.list-item-ghost md-autocomplete md-autocomplete-wrap button {
  width: 24px;
  height: 24px;
  margin: 4px;
}
.list-item-ghost md-autocomplete.selectedChip md-autocomplete-wrap {
  border-radius: 32px;
  background: rgb(224, 224, 224);
}
.list-item-ghost md-autocomplete.selectedChip md-autocomplete-wrap input {
  border-radius: 32px;
  background: rgb(224, 224, 224);
}
.list-item-ghost md-autocomplete.selectedChip md-autocomplete-wrap md-icon {
  width: 18px;
  min-width: 18px;
  height: 18px;
  min-height: 18px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.54);
}
.list-item-ghost mdp-date-picker {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.list-item-ghost mdp-date-picker button {
  display: none;
}
.list-item-ghost mdp-date-picker input {
  font-family: "Museo Sans 700", sans-serif !important;
}
.list-item-ghost selected-comment {
  display: none;
}

#bar-buttons {
  display: flex;
  align-items: center;
}
#bar-buttons .toolbar-button {
  text-transform: none;
}

.fr-toolbar {
  visibility: visible;
  margin: -40px 1px 0px 1px;
  box-shadow: none !important;
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.fr-box.fr-basic .fr-element.fr-view {
  padding-bottom: 44px;
}

.fr-box.fr-basic.fr-bottom .fr-wrapper {
  box-shadow: none;
}

md-tooltip.multi .md-content {
  width: 400px;
  max-width: 400px;
  padding: 8px;
  height: auto !important;
  white-space: normal;
  text-overflow: clip !important;
}

.resend-email-user-checkbox {
  min-height: initial !important;
}

.txtTag .md-chip-input-container,
.txtMember .md-chip-input-container,
.txtChairman .md-chip-input-container,
.txtInvitees .md-chip-input-container,
.txtAbsentees .md-chip-input-container {
  min-width: 360px;
}

#authorize .authorize-loading {
  margin: 16px 0 24px 0;
  color: #68468b;
}
#authorize .authorize-loading path {
  stroke: white;
}

#login {
  height: 100%;
  min-height: 100vh;
  display: flex;
  padding: 24px 16px;
  align-items: center;
  background: linear-gradient(rgb(0, 177, 166), rgb(0, 127, 123) 100%);
}
#login .login-header {
  display: flex;
  align-items: center;
}
#login .login-header .login-logo {
  max-width: 128px;
  max-height: 160px;
}
@media (min-width: 600px) {
  #login .login-header .login-logo {
    max-width: 160px;
    max-height: 200px;
  }
}
#login .login-body {
  display: flex;
  width: 100%;
  margin: 16px 0 0;
  align-items: center;
}
#login .login-body .login-title {
  margin-bottom: 16px;
  font-size: 34px;
  color: white;
}
@media (min-width: 600px) {
  #login .login-body .login-title {
    margin-bottom: 24px;
    font-size: 48px;
  }
}
@media (min-width: 960px) {
  #login .login-body .login-title {
    font-size: 54px;
  }
}
#login .login-body .login-form {
  display: flex;
  width: 100%;
  align-items: center;
}
#login .login-body .login-form .login-form-content {
  width: 100%;
  min-width: 288px;
  max-width: 480px;
  background: white;
  border-radius: 4px;
}
#login .login-body .login-form .login-form-content div {
  padding: 0;
}
#login .login-body .login-form .login-form-content div .login-domain,
#login .login-body .login-form .login-form-content div .login-id,
#login .login-body .login-form .login-form-content div .login-password {
  display: flex;
  padding: 0;
  margin: 0;
}
#login .login-body .login-form .login-form-content div .login-domain md-select,
#login .login-body .login-form .login-form-content div .login-id md-select,
#login .login-body .login-form .login-form-content div .login-password md-select {
  width: 100%;
}
#login .login-body .login-form .login-form-content div .login-domain md-select-value,
#login .login-body .login-form .login-form-content div .login-id input,
#login .login-body .login-form .login-form-content div .login-password input {
  height: 48px;
  padding: 0 24px;
  border: none;
  -ms-flex-preferred-size: auto;
}
#login .login-body .login-form .login-form-content div md-input-container.md-input-invalid .md-input::placeholder {
  color: #d9272c;
}
#login .login-body .login-form .login-form-content .divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
#login .login-body .login-button {
  width: 100%;
  min-width: 288px;
  max-width: 480px;
  margin: 16px 0 0 0;
  color: white;
  justify-content: center;
}
#login .login-body .social-buttons {
  width: 100%;
  max-width: 480px;
  margin-top: 24px;
}
#login .login-body .social-buttons img {
  padding: 4px 4px 4px 8px;
  width: 36px;
  line-height: 36px;
  border-right: none;
}
#login .login-body .social-buttons a {
  height: 36px;
  line-height: 24px;
  margin-top: 0px;
  padding-left: 48px;
  border-radius: 3px !important;
  color: rgba(0, 0, 0, 0.56);
  font-weight: bold;
}
#login .login-body .social-buttons .btn-petronas-social {
  background-color: white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2), 0px 1px 3px rgba(0, 0, 0, 0.1);
}
#login .login-body .social-buttons span {
  margin-top: -2px;
  border-right: none;
}
#login .login-body .social-buttons .btn {
  margin-top: 8px;
  width: 100%;
  min-width: 200px;
}
#login .login-body .login-loading {
  margin: 16px 0 24px 0;
  color: #68468b;
}
#login .login-body .login-loading path {
  stroke: white;
}
#login .login-body .login-invalid {
  opacity: 0.87;
  display: flex;
  align-items: center;
  margin: 16px 0 0 0;
  color: #d9272c;
  font-weight: bold;
}
#login .login-body .login-invalid .material-icons {
  margin-right: 4px;
  color: #d9272c;
}
@media (min-width: 600px) {
  #login .login-body {
    margin: 24px 0 0;
  }
}
#login .login-footer {
  display: block;
  width: 100%;
  padding-top: 16px;
  margin-top: 24px;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
#login .login-footer span,
#login .login-footer div,
#login .login-footer a {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
}
@media (min-width: 720px) {
  #login .login-footer span,
  #login .login-footer div,
  #login .login-footer a {
    font-size: 14px;
  }
}
#login .login-footer .login-service-desk {
  display: flex;
  margin-bottom: 4px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
#login .login-footer .login-service-desk a {
  display: flex;
  align-items: center;
  margin: 0 8px;
}
#login .login-footer .login-service-desk a md-icon {
  color: rgba(255, 255, 255, 0.7);
  margin: 0 4px 0 0;
}
#login .md-select-value .md-text,
#login .md-select-value .md-select-icon {
  color: rgba(0, 0, 0, 0.87);
}

@media (min-width: 960px) {
  #logo {
    padding: 24px;
  }
}
.login-page {
  min-height: 100%;
  width: 100vw;
  overflow: hidden;
}

.login-box {
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: auto;
}

#error-unsupport-domain {
  height: 100vh;
  width: 100vw;
}
#error-unsupport-domain .content .logo-box img {
  width: 140px;
  height: auto;
}
#error-unsupport-domain .content .title-box,
#error-unsupport-domain .content .message-box {
  color: #e6e6e6;
}
#error-unsupport-domain .content .title-box {
  padding-top: 20px;
  font-size: 23px;
  font-weight: 500;
}
#error-unsupport-domain .content .message-box {
  padding-top: 5px;
  font-size: 16px;
}
#error-unsupport-domain .content .logout-button-box {
  padding-top: 20px;
}
#error-unsupport-domain .content .logout-button-box button {
  background-color: white !important;
  padding: 2px 20px !important;
}
#error-unsupport-domain .content .logout-button-box button span {
  font-family: Roboto, sans-serif !important;
  font-weight: bolder;
  font-size: 11px;
}

#error-404 .content {
  width: 90%;
  max-width: 512px;
  margin-top: 128px;
}
#error-404 .content .error-code {
  font-size: 112px;
  text-align: center;
  line-height: 1;
  margin-bottom: 16px;
  font-weight: 500;
}
#error-404 .content .message {
  font-size: 24px;
  text-align: center;
  color: rgba(0, 0, 0, 0.54);
}
#error-404 .content .search {
  width: 100%;
  height: 56px;
  line-height: 56px;
  margin: 48px auto 16px auto;
  padding: 16px;
  background: #FFFFFF;
}
#error-404 .content .search input {
  padding: 0 0 0 16px;
}
#error-404 .content .back-link {
  font-size: 15px;
  text-align: center;
}

#error-500 .content {
  width: 90%;
  max-width: 512px;
  margin-top: 128px;
}
#error-500 .content .error-code {
  font-size: 112px;
  line-height: 1;
  text-align: center;
  margin-bottom: 16px;
  font-weight: 500;
}
#error-500 .content .message {
  font-size: 24px;
  text-align: center;
  color: rgba(0, 0, 0, 0.54);
}
#error-500 .content .sub-message {
  font-size: 17px;
  text-align: center;
  color: rgba(0, 0, 0, 0.54);
  margin: 16px auto 48px auto;
}
#error-500 .content .report-link {
  text-align: center;
  font-size: 15px;
}

selected-comment {
  display: block;
}

.clickable {
  cursor: pointer !important;
}

#log-table {
  overflow-x: auto;
}
#log-table table {
  border-collapse: collapse;
  width: 960px;
  table-layout: fixed;
}
#log-table th,
#log-table td {
  text-align: left;
  padding: 8px;
}
#log-table tr:nth-child(even) {
  background-color: #f2f2f2;
}
#log-table th {
  background-color: #00a19c;
  color: white;
}

.loader {
  height: 100px;
  padding: 1em;
  margin: 0 auto 1em;
  display: inline-block;
  vertical-align: top;
  /*
    Set the color of the icon
  */
}
.loader svg path,
.loader svg rect {
  fill: #00a19c;
}
.loader svg {
  width: 80px;
  height: 80px;
}

md-progress-circular.create-loader {
  margin-right: 8px;
}
md-progress-circular.create-loader path {
  stroke: white;
}

/* Froala Editor */
.md-resize-wrapper {
  padding: 0;
}
.md-resize-wrapper .fr-box.fr-basic > div:not(.fr-wrapper):not(.fr-toolbar) {
  display: none;
}
.md-resize-wrapper .fr-box.fr-basic .fr-element.fr-view ol,
.md-resize-wrapper .fr-box.fr-basic .fr-element.fr-view ul {
  padding-left: 16px;
}
.md-resize-wrapper p:first-of-type {
  margin-top: 0;
}
.md-resize-wrapper p:last-of-type {
  margin-bottom: 0;
}

.mdp-timepicker .mdp-timepicker-selected-time span {
  font-size: 3rem;
}

[md-theme=default] .md-primary-bg {
  background-color: #3c3835 !important;
}

md-toolbar.md-default-theme:not(.md-menu-toolbar),
md-toolbar:not(.md-menu-toolbar) {
  background-color: #3c3835 !important;
}

md-dialog.mdp-timepicker md-dialog-content {
  padding: 0;
}

.create-minutes-dialog md-chips md-chips-wrap {
  padding: 0 0 4px;
}
.create-minutes-dialog md-chips md-chips-wrap .md-chip-input-container {
  margin: 4px 8px 0 0;
}
.create-minutes-dialog md-chips md-chips-wrap .md-chip-input-container md-autocomplete md-autocomplete-wrap input {
  width: 508px;
  padding: 2px 2px 1px !important;
}
.create-minutes-dialog md-chips md-chips-wrap md-chip {
  height: 32px;
  line-height: 32px;
  padding-right: 28px;
  margin: 4px 8px 0 0;
}
.create-minutes-dialog md-chips md-chips-wrap md-chip md-chip-template strong {
  font-weight: normal;
}
.create-minutes-dialog md-chips md-chips-wrap md-chip .md-chip-remove-container {
  line-height: normal;
}
.create-minutes-dialog md-chips md-chips-wrap md-chip .md-chip-remove-container button {
  width: 24px;
  height: 24px;
  margin: 4px;
}
.create-minutes-dialog md-chips md-chips-wrap md-chip .md-chip-remove-container button md-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.create-minutes-dialog md-chips md-chips-wrap md-chip .md-chip-remove-container button .icon-close:before {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.38);
}
.create-minutes-dialog md-chips md-chips-wrap md-chip .md-chip-remove-container button .icon-close-circle:before {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.38);
}
.create-minutes-dialog md-chips md-chips-wrap md-chip .md-chip-remove-container button:hover md-icon:before {
  color: #d9272c;
}
.create-minutes-dialog .md-chips:not(.md-readonly) .md-chip:not(.md-readonly) {
  padding-right: 28px;
}

.fr-checkbox-line {
  display: none;
}

.autocomplete-minutes-list li {
  border-bottom: 1px solid #ccc;
  height: auto;
  white-space: normal;
  padding: 0 !important;
}

@media (min-width: 600px) {
  .mdp-timepicker md-dialog-content {
    display: flex;
    flex-direction: row;
    overflow: auto;
    min-width: 480px;
  }
  :not(.portrait).mdp-timepicker .mdp-timepicker-time {
    min-width: 138px;
  }
}
.md-open-menu-container .md-menu-bar-menu.md-dense {
  padding: 8px 0;
}
.md-open-menu-container .md-menu-bar-menu.md-dense .md-in-menu-bar {
  height: 48px;
}
.md-open-menu-container .md-menu-bar-menu.md-dense .md-in-menu-bar .md-button {
  height: 48px;
}

/* Custom md-complete angular material */
.md-autocomplete-suggestions li {
  padding: 0 !important;
}

.md-autocomplete-wraper-mod {
  width: 100%;
  height: 100%;
}

.md-autocomplete-container-mod {
  padding: 0 15px !important;
  width: 100%;
  height: 100%;
}

.disabled-mod {
  cursor: not-allowed !important;
  opacity: 0.8;
}

.tolowercase {
  text-transform: lowercase;
}

.display-column {
  display: flex;
  flex-direction: column;
}

.toast-icon {
  background-image: url("/assets/icons/tick.png") !important;
  background-color: #edf7ed;
  color: #4aaf50 !important;
  min-height: 48px;
  width: 645px !important;
}
.toast-icon button {
  color: #4aaf50;
}

.toast-icon_warning {
  background-image: url("/assets/icons/Yellow Warning.png") !important;
  background-color: #FEF8E9;
  color: #000 !important;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  min-height: 48px;
  width: 645px !important;
}
.toast-icon_warning button {
  color: #000000;
  position: absolute !important;
  right: 0.3em !important;
  top: 39% !important;
  transform: translateY(-50%) !important;
}
.toast-icon_warning .toast-message {
  padding-top: 2px;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .toast-icon_warning .toast-message {
    margin-bottom: 5px;
    padding-top: 0px;
  }
}

.toast-icon_error {
  background-image: url("/assets/icons/Error Icon.png") !important;
  background-color: #FAE5E5;
  color: #D50000 !important;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  min-height: 48px;
  width: 645px !important;
}
.toast-icon_error button {
  position: absolute;
  right: 0.3em !important;
  top: 39% !important;
  transform: translateY(-50%) !important;
  color: #D50000;
}

.png-icon {
  margin: 5px auto auto;
  padding: 0;
  display: inline-block;
  background-repeat: no-repeat no-repeat;
  pointer-events: none;
}

.logger-success {
  min-height: 16px;
  color: #4CAF50;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  vertical-align: middle;
}

.logger-warning {
  min-height: 16px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  vertical-align: middle;
}

.logger-error {
  min-height: 16px;
  color: #D50000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  vertical-align: middle;
}

.my-datepicker-is-showing .my-scroll-mask {
  z-index: 99;
}

.my-scroll-mask {
  position: absolute;
  background-color: transparent;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.my-scroll-mask .my-scroll-mask-bar {
  display: block;
  position: absolute;
  background-color: #fafafa;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 65;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
}

.common-panel {
  height: 100%;
  background: #fff;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);
  padding: 60px;
}
.common-panel.moreInfo-pannel {
  width: 660px;
}
.common-panel.manageAccess-panel {
  width: 80%;
}