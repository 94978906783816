#for-admin{
    .admin-nav{
        height: 56px;
        background: #fff;
        border-bottom: 4px solid #00A19C;
        padding: 20px 60px;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
    }
    .for-admin-container{
        padding: 60px;
        min-height: 91%;
        .lop {
            height: 19px;
            color: #000000;
            font-family: $font_Inter;
            font-size: 15px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 19px;
            margin-top: 24px;
            margin-bottom: 24px;
            .minute-no {
              height: 15px;
              width: 163px;
              color: #999999;
              font-family: $font_Inter;
              font-size: 12px;
              letter-spacing: 0;
              line-height: 15px;
              text-align: right;
              float: right;
              font-weight: 400;
            }
          }
    }
}

#bar-buttons {
    .send-minute {
      .send-area {
        span {
          color: #ffffff;
          font-family: $font_Inter;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 17px;
        }
        .next-icon {
          position: relative;
          top: 4px;
          margin-left: 14px;
        }
        .saving-area {
          font-size: 14px;
        }
      }
    }
  
    .btn-cancel {
      height: 42px;
      width: 138px;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 22px;
    }
  }
  