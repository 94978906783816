.send-tab-log-container {
  md-toolbar.md-default-theme:not(.md-menu-toolbar),
  md-toolbar:not(.md-menu-toolbar) {
    background-color: transparent !important;
    color: rgba(255, 255, 255, 0.87);
  }
  form {
    background-color: white !important;
  }

  .send-tab-log-toolbar {
    margin-top: 30px;
    .title {
      height: 26px;
      color: #000000;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 26px;
    }

    .send-tab-icon-log {
      margin: 32px;
    }
    .md-icon-button {
      margin-right: 32px;
    }
  }

  .first-row {
    height: 44px;
    border-radius: 4px 4px 0 0;
    background-color: #eeeeee;
    font-family: $font_Inter;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 14px;
  }

  #send-tab-log-table {
    overflow-x: auto;
    table {
      border-collapse: collapse;
      width: 960px;
      table-layout: fixed;
    }
    th,
    td {
      text-align: left;
      padding: 20px;
      color: rgba(0,0,0,0.75);
      font-family: $font_Inter;
      font-size: 11px!important;
    }
    tr {
      border: 1px solid #eaedf3;
    }
    th {
      height: 44px;
      border-radius: 4px 4px 0 0;
      background-color: #eeeeee;
      box-shadow: 0px 1px 0px 1px #eaedf3;

      font-size: 11px;
      font-weight: 500;
      letter-spacing: 0.5px;
      line-height: 14px;      
      color: #9EA0A5!important;
      text-transform: uppercase;
    }
  }
}
