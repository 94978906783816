.my-minutes-checkbox {
  box-sizing: border-box;
  height: 16px;
  width: 16px;
  border-radius: 3px;
  position: relative;
  &.uncheck{
    border: 1px solid #D9D9D9;
    background: linear-gradient(0deg, #F6F7F9 0%, #FFFFFF 100%);
    box-shadow: inset 0 2px 0 0 rgba(255,255,255,0.05), 0 1px 1px 0 rgba(22,29,37,0.05);
  }
  &.checked{
    border: 1px solid #008F8A;
    background-color: #00A19C;
    box-shadow: inset 0 2px 0 0 rgba(255,255,255,0.06), 0 1px 1px 0 rgba(19,31,21,0.1);
  }
  &.checked::before{
    position: absolute;
    content: '';
    display: block;
    top: 3px;
    left: 3px;
    width: 8px;
    height: 6px;
    border-style: solid;
    border-color: #fff;
    border-width:2px 2px 0px 0px;
    transform: rotate(130deg);
  }

}
