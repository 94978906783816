#preview{
    .review-view{
        margin: 35px 60px 47px 60px;
        .header{
            color: #000000;
            font-family: $font_Inter;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 20px;
        }

        .header-detailinfo,
        .review-attachments,
        .review-agenda,
        .review-discussion
         {
            width: 100%;
            box-sizing: border-box;
            border: 1px solid #EAEDF3;
            border-radius: 8px;
            background-color: #FFFFFF;
            box-shadow: 0 1px 3px 0 rgba(0,0,0,0.04);
            margin-bottom: 20px;
        }

        .header-detailinfo{
           height: auto;
           padding: 44px 60px 60px 50px;
            .header-logo{
                margin-bottom: 25px;
                margin-left: 5px;
            }
            .header-detail{
                display: flex;
                justify-content: space-between;
                padding-left: 15px;
                margin-bottom: 32px;
                .header-left{
                    width: 86%;
                    .header-title{
                        margin-bottom: 10px;
                        span{
                            color: #000000;
                            font-family: $font_Inter;
                            font-size: 30px;
                            letter-spacing: 0;
                            line-height: 36px;
                        }
                    }
                    .minutes-tags{
                      padding-left: 2px;
                        .tag{
                            display: inline-block;
                            padding: 4px 8px;
                            height: 24px;
                            border: 1px solid #D9D9D9;
                            border-radius: 4px;
                            background-color: #FFFFFF;
                            color: rgba(0,0,0,0.5);
                            font-size: 11px;
                            font-weight: 500;
                            letter-spacing: 0;
                            line-height: 16px;
                            margin-bottom: 10px;
                        }
                        .tag:not(:last-child){
                            margin-right: 11px;
                        }
                    }
                }

                .header-right{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    font-family: $font_Inter;
                    width: 180px;
                    .minutes-classification{
                        color: #3E3F42;
                        font-size: 12px;
                        letter-spacing: 0;
                        line-height: 15px;
                        text-align: right;
                        margin-bottom: 6px;
                    }
                    .minutes-status{
                        color: #000000;
                        font-size: 18px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 21px;
                        text-align: right;
                        margin-bottom: 8px;
                    }
                    .minutes-no{
                        color: #3E3F42;
                        font-size: 12px;
                        letter-spacing: 0;
                        line-height: 15px;
                        text-align: right;
                    }
                }
            }

            .detail-info{
                padding-left: 10px;
                margin-right: 180px;
                .meeting-detail-item{
                    display: table-row;
                    .detail-title{
                        display: table-cell;
                        padding: 5px 24px 5px 8px;
                        color: #9EA0A5;
                        font-family: $font_Inter;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 22px;
                        width: 146px;
                    }

                    .detail-content{
                        color: #3E3F42;
                        font-family: $font_Inter;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 22px;
                        .displayname{
                            @extend .detail-content;
                            display: inline-block;
                        }
                    }
                }
            }
        }

        .review-attachments{
            height: 162px;
            padding: 40px 28px 40px 60px;
            .attachment-container {
                align-self: flex-start;
                align-items: center;
                overflow-x: auto;
                margin-top: 12px;
                padding-bottom: 10px;
                &::-webkit-scrollbar,
                .md-virtual-repeat-scroller::-webkit-scrollbar {
                    height: 8px;
                }
                &::-webkit-scrollbar-thumb,
                .md-virtual-repeat-scroller::-webkit-scrollbar-thumb {
                    background-color: darkgrey;
                }
                .attachment:last-of-type {
                    padding: 0;
                }
                .attachment {
                    box-sizing: border-box;
                    height: 50px;
                    width: 202px;
                    min-width: 202px;
                    border: 1px solid #d8dce6;
                    border-radius: 4px;
                    background-color: #ffffff;
                    margin-right: 16px;
                    position: relative;
                    .file-icon {
                        height: 24px;
                        width: 24px;
                        margin: 13px 4px 13px 15px;
                        font-size: 24px;
                        line-height: 24px;
                    }
                    .delete-icon {
                        width: 10px;
                        height: 10px;
                        right: 9px;
                        top: 17px;
                        position: absolute;
                        &:hover {
                            cursor: pointer;
                        }
                        i {
                            font-size: 16px;
                            color: rgba(0, 0, 0, 0.3);
                            font-weight: bolder;
                        }
                    }
                    .file-name {
                        height: 15px;
                        color: #000000;
                        font-size: 12px;
                        letter-spacing: 0;
                        line-height: 15px;
                        width: 156px;
                        padding-right: 8px;
                        display: inline !important;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-wrap: break-word !important;
                        white-space: nowrap;
                    }
                    a {
                        text-decoration: none;
                    }
                }
            }
        }

        .review-agenda{
            padding: 40px 60px;
            height: auto;

            .agenda-content{
                p{
                  margin-top: 12px;
                  color: rgba(0,0,0,0.75);
                  font-family: $font_Inter;
                  font-size: 14px;
                  letter-spacing: 0;
                  line-height: 22px;
                }

            }
        }

        .review-discussion{
            .preview-checkbox{
                margin-top: 3px;
            }
            .header-container{
                padding: 40px 60px 24px 60px;
                .header{
                    padding-top: 1px;
                }
                .preview-checkbox{
                    margin-right: 8px;
                }
            }
            .discussion-content{
                .discussion-point{
                    border-top: 1px solid #D8DCE6;
                    padding: 20px 60px 40px 60px;
                    .point-title{
                        color: #00A19C;
                        font-size: 16px;
                        letter-spacing: 0;
                        line-height: 24px;
                        margin-bottom: 12px;
                        margin-left: 8px;
                    }
                    .discussion-description-preview p{
                        color: rgba(0,0,0,0.75);
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 22px;
                        margin-left: 8px;
                        margin-bottom: 16px;
                    }
                    .point-file{
                        margin-bottom: 24px;
                    }
                    .topic-attachment-container {
                        overflow-y: hidden;
                        overflow-x: auto;
                        padding-bottom: 10px;
                        
                        margin-bottom: 24px;
                        margin-left: 24px;
                        &::-webkit-scrollbar {
                            height: 8px;
                        }
                        &::-webkit-scrollbar-thumb {
                            background-color: darkgrey;
                        }
                      .topic-attachment {
                          box-sizing: border-box;
                          height: 50px;
                          width: 202px;
                          min-width: 202px;
                          border: 1px solid #d8dce6;
                          border-radius: 4px;
                          background-color: #ffffff;
                          margin-right: 16px;
                          position: relative;
                          .file-icon {
                              height: 24px;
                              width: 24px;
                              margin: 13px 4px 13px 15px;
                              font-size: 24px;
                              line-height: 24px;
                          }
                          .delete-icon {
                              width: 10px;
                              height: 10px;
                              right: 9px;
                              top: 17px;
                              position: absolute;
                              &:hover {
                                  cursor: pointer;
                              }
                              i {
                                  font-size: 16px;
                                  color: rgba(0, 0, 0, 0.3);
                                  font-weight: bolder;
                              }
                          }
                          .file-name {
                            height: 15px;
                            color: #000000;
                            font-size: 12px;
                            letter-spacing: 0;
                            line-height: 15px;
                            width: 156px;
                            padding-right: 8px;

                            display: inline !important;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            word-wrap: break-word !important;
                            white-space: nowrap;
                          }
                          a {
                              text-decoration: none;
                          }
                      }
                  }
                    .discussion-item{
                        .discussion-item-table{
                            border-collapse: collapse;
                            width: 100%;
                            border-spacing: 5px;
                            thead tr{
                              border-bottom: 1px solid #D8DCE6;
                            }
                            tr:not(:last-child) {
                                border-bottom: 1px solid #D8DCE6;
                            }
                            th{
                                color: #6B6C6F;
                                font-size: 14px;
                                letter-spacing: 0;
                                line-height: 22px;
                                text-align: left;
                                padding: 0 10px 16px 0px;
                                font-weight: 400;
                            }
                            td{
                                padding: 16px 20px 16px 0px;

                            }
                            td, .topic-no, .topic-name p,
                            .discussionItem-actionType,
                            .discussionItem-displayName,
                            .discussionItem-duedate {
                                margin: 0;
                                font-size: 14px;
                                letter-spacing: 0;
                                line-height: 22px;
                                vertical-align: top;
                                color: rgba(0,0,0,0.75);
                            }
                            .topic-name p{
                                text-align: justify;
                            }
                            .topic-no{
                                margin-left: 12px;
                                margin-right: 20px;
                                color: #6B6C6F;
                            }
                            #topic{
                                width: 50%;
                                padding-left: 24px;
                            }
                            #type{
                                width: 10%;
                            }
                            #assignedto{
                                width: 20%;
                            }
                            #duedate{
                                width: 20%;
                            }

                            .topic-content{
                                display: flex;
                                @media all and (max-width: 1920px)  and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                                    width: 650px;
                                }
                                @media all and (max-width: 1600px)  and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                                    width: 500px;
                                }
                                @media all and (max-width: 1366px)  and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                                    width: 450px;
                                }
                                @media all and (max-width: 1024px)  and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                                    width: 350px;
                                }
                                @media all and (max-width: 768px)  and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                                    width: 300px;
                                }
                            }

                        }
                    }
                }
            }
        }
    }

}
#extractBar {
    position: fixed;
    display: flex;
    align-items: center;
    top: 0;
    background-color: #000 !important;
    .selected {
      padding-left: 8px;
    }
  }

#extractBar.ng-hide {
opacity: 0;
}

#extractBar.ng-hide-add,
#extractBar.ng-hide-remove {
transition: all ease-out 0.15s;
}
#bar-buttons{
    .btn-edit{
        height: 42px;
        width: 138px;
    }
    .btn-download, .btn-email{
      height: 42px;
      width: 159px;
    }
    .btn-download, .btn-email, .btn-edit, .btn-log{
      span{
        color: #787878;
        font-family: $font_Inter;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 22px;
        text-align: center;
      }
    }
    .btn-download{
      margin-right: 12px;
    }
    .send-minute{
        position: relative;
        .btn-send:focus{
          & + .send-list{
            display: block;
          }
        }
        .send-area{
            .send-icon{
                top: 1px;
                margin-left: 14px;
            }
        }
        .send-list{
          position: absolute;
          display: none;
          width: 229px;
          height: 252px;
          box-shadow:  0 0 10px -2px rgba(0, 0, 0, 0.25);
          border-radius: 4px;
          background-color: white;
          top: -252px;
          right: 0px;
          z-index: 1;
          .send-option{
            width: 229px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            border-bottom: 1px solid rgba(0,0,0,0.1);
            padding: 20px;
            color: rgba(0,0,0,0.85);
            font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue",
        Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Noto Color Emoji";
            font-size: 13px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 16px;
            cursor: pointer;
            .img-icon{
              margin-right: 8px;
            }
            .option-description{
              height: 28px;
              width: 158px;
              color: #6D7278;
              font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue",
        Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Noto Color Emoji";
              font-size: 10px;
              letter-spacing: 0;
              line-height: 14px;
              font-weight: normal;
            }
          }
          .send-option[disabled]{
            opacity: 0.3;
            cursor: not-allowed;
          }
          .send-option:hover {
              background-color: #E0F4F3;
          }
          .send-option:focus {
            background-color:#B3E3E1;
        }
        }
        .send-list:hover{
          display: block;
        }
      }
}
