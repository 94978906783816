.comment-area {
  //border: 1px solid rgba(0, 0, 0, 0.12);

  .comment-header {
    color: $color_petronas_green;
  }
  .comment-box {
    border: 1px solid rgba(0, 0, 0, 0.12);
    min-height: 48px;
    padding: 8px 16px 16px;
    //background: rgba(0,0,0,.04);
    background: #f6f7f8;
    .comment-item {
      span {
        background-color: transparent !important;
      }
      .comment-tagging {
        font-weight: bold;
      }
      padding: 8px 0;
      img.avatar-img {
        display: inline-block;
        width: 24px;
        height: 24px;
        margin-top: 5px;
        margin-right: 8px;
        background-color: $color_petronas_green;
        border-radius: 50%;
      }
      .comment-content {
        width: 100%;
        .commentator {
          height: 17px;
          color: #6d7278;
          font-family: $font_Inter;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 17px;
        }
        .message {
          color: rgba(0, 0, 0, 0.85);
          font-family: $font_Inter;
          font-size: 14px;
          letter-spacing: 0;
          word-break: break-word;
        }
        .date {
          float: right;
          color: rgba(0, 0, 0, 0.54);
          font-size: 1.2rem;
        }
      }
    }
    .comment-action {
      margin-top: 8px;
      padding: 4px 4px 44px 4px;
      border: 1px solid rgba(0, 0, 0, 0.12);
      background: white;
      position: relative;
      .control-box {
        position: absolute;
        bottom: 0px;
        right: 0px;
        left: 0px;
        height: 41px;
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        background-color: #f6f7f8;
        .btnSend {
          position: absolute;
          bottom: 4px;
          right: 4px;

          box-sizing: border-box;
          height: 43px;
          width: 125px;
          border: 1px solid #00a19c;
          border-radius: 4px;
          background-color: #ffffff;
          color: #00a19c;
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 18px;
          &:hover {
            cursor: pointer;
          }

          &:disabled {
            color: #b3e3e1;
            border-color: #b3e3e1;
          }
          span {
            font-family: $font_Inter;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 18px;
          }
        }
      }
      .comment-input {
        min-height: 100px;
        resize: none;
        width: 100%;
        padding: 8px;
        font-size: 14px;
      }
    }
    .comment-emptycomment {
      color: #999999;
      font-family: $font_Inter;
      font-size: 13px;
      letter-spacing: 0;
      line-height: 22px;
    }
  }
}
@media (max-width: 959px) {
  .comment-area {
    display: block;
  }
}

.user-search {
  border-radius: 6px;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
  outline: 0;
}

.user-search:hover {
  cursor: pointer;
}

.user-search .list-group-item.active {
  color: #fff;
  background-color: #428bca;
  border-color: #428bca;
}

.user-search .list-group-item.active .text-muted {
  color: #ccc;
}

.user-search .list-group-item.active .text-primary {
  color: #fff;
}

.user-search .list-group-item {
  padding: 5px;
}

.user-search .user-photo {
  max-width: 30px;
  max-height: 30px;
  border-radius: 15px;
}

.section-heading {
  margin-top: 30px;
}

.list-group {
  margin-bottom: 20px;
  padding-left: 0;
}
.list-group-item {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #ddd;
  span {
    padding-left: 5px;
    vertical-align: middle;
  }
  .menu-highlighted {
    padding-left: 0px !important;
    vertical-align: bottom;
    font-weight: bold;
  }
}
.list-group-item:first-child {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.list-group-item:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.comment-tagging {
  background-color: #eee;
}

.email {
  display: none;
}

.empty-photo {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-top: 5px;
  margin-right: 8px;
  background-color: $color_petronas_green;
  border-radius: 50%;
  color: white;
  padding-top: 3px;
  text-align: center;
  font-weight: bold;
}

img.emoji {
  height: 20px;
  widows: 20px;
}
