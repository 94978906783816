.pannel-container{
  height: 100%;
  .moreInfoTitleContainer{
    width: 100%;
    .closeBtn{
      text-align: right;
    }
    .title{
      font-size: 30px;
      letter-spacing: 0;
      line-height: 36px;
      color: #000;
    }
    .status{
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 21px;
      margin-top: 8px;
    }
  }
  .moreInfoDivide{
    box-sizing: border-box;
    height: 1px;
    width: 540px;
    border: 1px solid #D8DCE6;
    margin: 23.5px 0;
    border-top: none;
  }
  .moreInfoPaticipants{
    .title{
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 15px;
      color: #000000;
      margin-bottom: 8px;
    }

    .members{
      flex-wrap: wrap;
      width: 92%;
      .image{
        width: 41px;
        min-width: 41px;
        max-width: 41px;
        line-height: 41px;
        margin-right: 4px;
        margin-bottom: 8px;
        border-radius: 50%;
        text-align: center;
        height: 40px;
        cursor: pointer;
        background-color: #6D717D ;
        font-weight: 500;
        color: #fff;
      }
    }
  }
}
