.send-title {
  font-weight: bold;
  padding: 30px 0 50px 0;
  padding-left: $marginLeftHomePages;
  background-color: white;
  margin-bottom: 2px;
  margin-top: 0;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
}
.has-status-pending-approval {
  color: #fdb924 !important;
}

.has-status-approved {
  color: #4caf50 !important;
}
.has-status-pending-review {
  color: #0b69ff !important;
}
.has-status-sent {
  color: #4caf50 !important;
}
.send-comment-count {
  color: rgba(0, 0, 0, 0.75);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  text-transform: none;
}
.container-icon-comment {
  position: relative;
  .new-comment {
    position: absolute;
    top: -10px;
    right: -10px;

    height: 23px;
    width: 23px;
    background-color: #00a19c;
    border-radius: 50%;

    color: #ffffff;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 23px;
    text-align: center;
  }
}
.send-btn-comment-child-item,
.send-btn-comment-item {
  margin: 0;
  padding-top: 10px !important;
  position: relative;
  top: -17px;
  height: 60px;
}
.send-btn-comment-child-item {
  margin-right: -18px;
}
.send-btn-sec-edit {
  box-sizing: border-box;
  height: 42px;
  width: 158px;
  border: 1px solid #e2e5ed;
  border-radius: 4px;

  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;

  margin: 0 5px;
}
.send-btn-sec-resend-noti {
  box-sizing: border-box;
  height: 42px;
  width: 190px;
  border: 1px solid #e2e5ed;
  border-radius: 4px;

  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;

  margin: 0 5px;
}
.send-btn-sec-send-approval {
  height: 42px;
  width: 192px;
  border-radius: 4px;
  background-color: #00a19c;

  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;

  margin: 0 5px;
}
.send {
  .review-view {
    margin: 24px 60px 47px 60px;
    .action-btn{
      margin-bottom: 24px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .btn{
        .png-icon{
          margin: 0px;
        }
        padding: 7px 18px;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 15px;
        text-align: center;
      }
    }
    .header {
      color: #000000;
      font-family: $font_Inter;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 20px;
    }

    .header-detailinfo,
    .review-attachments,
    .review-agenda,
    .review-discussion {
      width: 100%;
      box-sizing: border-box;
      border: 1px solid #eaedf3;
      border-radius: 8px;
      background-color: #ffffff;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
      margin-bottom: 20px;
    }

    .no-access-modal {
      height: 218px;
      width: 576px;
      padding: 36px 28px 30px 36px;
      margin: 52px auto;
      box-sizing: border-box;
      border: 1px solid #eaedf3;
      border-radius: 8px;
      background-color: #ffffff;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
      .title-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .title {
          margin-left: 24px;
          height: 26px;
          color: #000000;
          font-size: 24px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 26px;
        }
        .img {
          object-fit: contain;
        }
      }

      .contentArea {
        width: 504px;
        margin-top: 24px;
        .content {
          height: 22px;
          color: rgba(0, 0, 0, 0.75);
          font-size: 14px;
          letter-spacing: 0;
          line-height: 22px;
        }
      }
    }

    .header-detailinfo {
      height: auto;
      padding: 44px 60px 60px 50px;
      .header-logo {
        margin-bottom: 25px;
        margin-left: 5px;
      }
      .header-detail {
        display: flex;
        justify-content: space-between;
        padding-left: 15px;
        margin-bottom: 32px;
        .header-left {
          width: 86%;
          .header-title {
            margin-bottom: 10px;
            span {
              color: #000000;
              font-family: $font_Inter;
              font-size: 30px;
              letter-spacing: 0;
              line-height: 36px;
            }
          }
          .minutes-tags {
            padding-left: 2px;
            .tag {
              display: inline-block;
              padding: 4px 8px;
              height: 24px;
              border: 1px solid #d9d9d9;
              border-radius: 4px;
              background-color: #ffffff;
              color: rgba(0, 0, 0, 0.5);
              font-size: 11px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 16px;
              margin-bottom: 10px;
            }
            .tag:not(:last-child) {
              margin-right: 11px;
            }
          }
        }

        .header-right {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          font-family: $font_Inter;
          width: 180px;
          .minutes-classification {
            color: #3e3f42;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 15px;
            text-align: right;
            margin-bottom: 6px;
          }
          .minutes-status {
            color: #000000;
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 21px;
            text-align: right;
            margin-bottom: 8px;
          }
          .minutes-no {
            color: #3e3f42;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 15px;
            text-align: right;
          }
        }
      }

      .detail-info {
        padding-left: 10px;
        margin-right: 180px;
        .meeting-detail-item {
          display: table-row;
          .detail-title {
            display: table-cell;
            padding: 5px 24px 5px 8px;
            color: #9ea0a5;
            font-family: $font_Inter;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 22px;
            width: 146px;
          }

          .detail-content {
            color: #3e3f42;
            font-family: $font_Inter;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 22px;
            .displayname{
              @extend .detail-content;
              display: inline-block;
          }
          }
        }
      }
    }

    .review-attachments {
      height: 162px;
      padding: 40px 28px 40px 60px;
      .attachment-container {
        align-self: flex-start;
        align-items: center;
        overflow-x: auto;
        margin-top: 12px;
        padding-bottom: 10px;
        &::-webkit-scrollbar,
        .md-virtual-repeat-scroller::-webkit-scrollbar {
          height: 8px;
        }
        &::-webkit-scrollbar-thumb,
        .md-virtual-repeat-scroller::-webkit-scrollbar-thumb {
          background-color: darkgrey;
        }
        .attachment:last-of-type {
          padding: 0;
        }
        .attachment {
          box-sizing: border-box;
          height: 50px;
          width: 202px;
          min-width: 202px;
          border: 1px solid #d8dce6;
          border-radius: 4px;
          background-color: #ffffff;
          margin-right: 16px;
          position: relative;
          .file-icon {
            height: 24px;
            width: 24px;
            margin: 13px 4px 13px 15px;
            font-size: 24px;
            line-height: 24px;
          }
          .delete-icon {
            width: 10px;
            height: 10px;
            right: 9px;
            top: 17px;
            position: absolute;
            &:hover {
              cursor: pointer;
            }
            i {
              font-size: 16px;
              color: rgba(0, 0, 0, 0.3);
              font-weight: bolder;
            }
          }
          .file-name {
            height: 15px;
            color: #000000;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 15px;
            width: 156px;
            padding-right: 8px;
            display: inline !important;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word !important;
            white-space: nowrap;
          }
          a {
            text-decoration: none;
          }
        }
      }
    }

    .review-agenda {
      padding: 40px 60px;
      height: auto;

      .agenda-content {
        p {
          margin-top: 12px;
          color: rgba(0, 0, 0, 0.75);
          font-family: $font_Inter;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 22px;
        }
      }
    }

    .review-discussion {
      .preview-checkbox {
        margin-top: 3px;
      }
      .header-container {
        padding: 40px 60px 24px 60px;
        .header {
          padding-top: 1px;
        }
        .preview-checkbox {
          margin-right: 8px;
        }
      }
      .discussion-content {
        .control-box {
          border-top: none;
          background-color: transparent;
        }
        .discussion-point {
          border-top: 1px solid #d8dce6;
          padding: 20px 60px 40px 60px;
          .point-title {
            color: #00a19c;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
            margin-bottom: 12px;
            margin-left: 8px;
          }
          .discussion-description-preview p {
            color: rgba(0, 0, 0, 0.75);
            font-size: 14px;
            letter-spacing: 0;
            line-height: 22px;
            margin-left: 8px;
            margin-bottom: 16px;
          }
          .point-file {
            margin-bottom: 24px;
          }
          .topic-attachment-container {
            overflow-y: hidden;
            overflow-x: auto;
            padding-bottom: 10px;

            margin-bottom: 24px;
            margin-left: 24px;
            &::-webkit-scrollbar {
              height: 8px;
            }
            &::-webkit-scrollbar-thumb {
              background-color: darkgrey;
            }
            .topic-attachment {
              box-sizing: border-box;
              height: 50px;
              width: 202px;
              min-width: 202px;
              border: 1px solid #d8dce6;
              border-radius: 4px;
              background-color: #ffffff;
              margin-right: 16px;
              position: relative;
              .file-icon {
                height: 24px;
                width: 24px;
                margin: 13px 4px 13px 15px;
                font-size: 24px;
                line-height: 24px;
              }
              .delete-icon {
                width: 10px;
                height: 10px;
                right: 9px;
                top: 17px;
                position: absolute;
                &:hover {
                  cursor: pointer;
                }
                i {
                  font-size: 16px;
                  color: rgba(0, 0, 0, 0.3);
                  font-weight: bolder;
                }
              }
              .file-name {
                height: 15px;
                color: #000000;
                font-size: 12px;
                letter-spacing: 0;
                line-height: 15px;
                width: 156px;
                padding-right: 8px;

                display: inline !important;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                word-wrap: break-word !important;
                white-space: nowrap;
              }
              a {
                text-decoration: none;
              }
            }
          }
          .discussion-item {
            .control-box {
              border-top: none;
              background-color: transparent;
            }
            .discussion-item-table {
              border-collapse: collapse;
              width: 100%;
              border-spacing: 5px;
              table-layout: fixed;
              thead tr {
                border-bottom: 1px solid #d8dce6;
              }
              tr:not(:last-child) {
                border-bottom: 1px solid #d8dce6;
              }
              th {
                color: #6b6c6f;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 22px;
                text-align: left;
                padding: 0 10px 16px 0px;
                font-weight: 400;
              }
              td {
                padding: 16px 20px 16px 0px;
              }
              td,
              .topic-no,
              .topic-name p,
              .discussionItem-actionType,
              .discussionItem-displayName,
              .discussionItem-duedate {
                margin: 0;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 22px;
                vertical-align: top;
                color: rgba(0, 0, 0, 0.75);
              }
              .topic-name p {
                text-align: justify;
              }
              .topic-no {
                margin-left: 12px;
                margin-right: 20px;
                color: #6b6c6f;
              }
              #topic {
                width: 50%;
                padding-left: 24px;
              }
              #type {
                width: 10%;
              }
              #assignedto {
                width: 20%;
              }
              #duedate {
                width: 20%;
              }

              .topic-content {
                display: flex;
                @media all and (max-width: 1920px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                  width: 650px;
                }
                @media all and (max-width: 1600px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                  width: 500px;
                }
                @media all and (max-width: 1366px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                  width: 450px;
                }
                @media all and (max-width: 1024px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                  width: 350px;
                }
                @media all and (max-width: 768px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                  width: 300px;
                }
              }
            }
          }
        }
      }
    }
  }
}
#extractBar {
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  background-color: #000 !important;
  .selected {
    padding-left: 8px;
  }
}

#extractBar.ng-hide {
  opacity: 0;
}

#extractBar.ng-hide-add,
#extractBar.ng-hide-remove {
  transition: all ease-out 0.15s;
}
#bar-buttons {
  .send-btn-log {
    box-sizing: border-box;
    height: 42px;
    width: 158px;
    border: 1px solid #e2e5ed;
    border-radius: 4px;
    background-color: #ffffff;

    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
  }
  .send-btn-approve {
    height: 42px;
    width: 126px;
    border-radius: 4px;
    background-color: #00a19c;

    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
  }
  .send-btn-move-to-archive {
    height: 42px;
    width: 169px;
    border-radius: 4px;
    background-color: #00a19c;

    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
  }
}

.warning-container {
  padding: 0px 60px 0px 60px;
  background-color: #fef8e9;
}
.warning-container * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.warning-container > div {
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  color: rgba(0, 0, 0, 0.8) !important;
  font-size: 14px !important;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}

.warning-container .toast-message{
  color: rgba(0,0,0,0.8)!important;
  font-size: 14px!important;
}

.warning-container > .toast-info {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=") !important;
}
.warning-container > .toast-error {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=") !important;
}
.warning-container > .toast-success {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==") !important;
}
.warning-container > .toast-warning {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=") !important;
}

.warning-container .toast-icon_warning {
  width: 100% !important;
}

.btn-markAsReviewed{
  width: auto !important;
  padding: 12px 23px;
}
