#discussionAttachment {
    padding: 8px;
    .header {
        margin-bottom: 24px;
        .index {
            margin-right: 16px;
        }
        .title {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
        }
    }
    .attachment {
        margin: 0 8px 16px;
        background: white;
        box-shadow: 0 -1px 1px rgba(0, 0, 0, .11), 0 0 1px rgba(0, 0, 0, .11), 0px 1px 2px rgba(0, 0, 0, .22);
        a {
            padding: 12px 8px;
        }
        md-icon {
            margin: 0 8px 0 0;
        }
    }
    .topic::-webkit-scrollbar {
        width: 4px;
    }
    .topic::-webkit-scrollbar-thumb {
        background-color: rgba(0,0,0,.38);
    }
}
