#login {
  height: 100%;
  min-height: 100vh;
  display: flex;
  padding: 24px 16px;
  align-items: center; // background: linear-gradient(rgba(50, 192, 186, 1), rgba(0, 141, 135, 1) 100%);
  background: linear-gradient(rgba(0, 177, 166, 1), rgba(0, 127, 123, 1) 100%);
  .login-header {
      display: flex;
      align-items: center;
      .login-logo {
          max-width: 128px;
          max-height: 160px;
      }
      @media(min-width:600px) {
          .login-logo {
              max-width: 160px;
              max-height: 200px;
          }
      }
  }
  .login-body {
      display: flex;
      width: 100%;
      margin: 16px 0 0;
      align-items: center;
      .login-title {
          margin-bottom: 16px;
          font-size: 34px;
          color: white;
      }
      @media(min-width:600px) {
          .login-title {
              margin-bottom: 24px;
              font-size: 48px;
          }
      }
      @media(min-width:960px) {
          .login-title {
              font-size: 54px;
          }
      }
      .login-form {
          display: flex;
          width: 100%;
          align-items: center;
          .login-form-content {
              width: 100%;
              min-width: 288px;
              max-width: 480px;
              background: white;
              border-radius: 4px;
              div {
                  padding: 0;
                  .login-domain,
                  .login-id,
                  .login-password {
                      display: flex;
                      padding: 0;
                      margin: 0;
                      md-select {
                          width: 100%;
                      }
                  }
                  .login-domain md-select-value,
                  .login-id input,
                  .login-password input {
                      height: 48px;
                      padding: 0 24px;
                      border: none;
                      -ms-flex-preferred-size: auto;
                  }
                  md-input-container.md-input-invalid .md-input::placeholder {
                      color: $color_petronas_red;
                  }
              }
              .divider {
                  border-bottom: 1px solid rgba(0, 0, 0, .12);
              }
          }
      }
      .login-button {
          width: 100%;
          min-width: 288px;
          max-width: 480px;
          margin: 16px 0 0 0;
          color: white;
          justify-content: center;
      }
      .social-buttons {
          width: 100%;
          max-width: 480px;
          margin-top: 24px;
          img {
              padding: 4px 4px 4px 8px;
              width: 36px;
              line-height: 36px;
              border-right: none;
          }
          a {
              height: 36px;
              line-height: 24px;
              margin-top: 0px;
              padding-left: 48px;
              border-radius: 3px !important;
              color: rgba(0, 0, 0, 0.56);
              font-weight: bold;
          }
          .btn-petronas-social {
              background-color: white;
              box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2), 0px 1px 3px rgba(0, 0, 0, 0.1);
          }
          span {
              margin-top: -2px;
              border-right: none;
          }
          .btn {
              margin-top: 8px;
              width: 100%;
              min-width: 200px;
          }
      }
      .login-loading {
          margin: 16px 0 24px 0;
          color: $color_petronas_violet;
          path {
              stroke: white;
          }
      }
      .login-invalid {
          opacity: .87;
          display: flex;
          align-items: center;
          margin: 16px 0 0 0;
          color: $color_petronas_red;
          font-weight: bold;
          .material-icons {
              margin-right: 4px;
              color: $color_petronas_red;
          }
      }
  }
  @media(min-width:600px) {
      .login-body {
          margin: 24px 0 0;
      }
  }
  .login-footer {
      display: block;
      width: 100%;
      padding-top: 16px;
      margin-top: 24px;
      align-items: center;
      border-top: 1px solid rgba(255, 255, 255, .12);
      span,
      div,
      a {
          font-size: 12px;
          color: rgba(255, 255, 255, .7);
      }
      @media(min-width:720px) {
          span,
          div,
          a {
              font-size: 14px;
          }
      }
      .login-service-desk {
          display: flex;
          margin-bottom: 4px;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          a {
              display: flex;
              align-items: center;
              margin: 0 8px;
              md-icon {
                  color: rgba(255, 255, 255, .7);
                  margin: 0 4px 0 0;
              }
          }
      }
  }
  .md-select-value .md-text,
  .md-select-value .md-select-icon {
      color: rgba(0, 0, 0, .87);
  }
}

@media(min-width:960px) {
  #logo {
      padding: 24px;
  }
}

.login-page {
  min-height: 100%;
  width: 100vw;
  overflow: hidden;
}

.login-box {
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: auto;
}