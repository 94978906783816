.title-minutes {
    height: 112px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    h1 {
        margin: 0 5%;
        height: 32px;
        color: #000000;
        font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
            "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 112px;
    }
}