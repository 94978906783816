#create-minutes-at-draft-mode-confirm {
  width: 576px;
  height: auto;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.05);
  md-dialog-content{
      padding: 36px 28px 30px 36px;
      height: 100%;
  }
  .title-container{
    .icon-container{
      height: 48px;
      width: 48px;
      background-color: rgba(0,161,156,0.2);
      border-radius: 50%;
      position: relative;

      .img{
        object-fit: contain;
        // margin-right: 24px;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    }
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .title {
        height: 26px;
        color: #000000;
        font-family: $font_Inter;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 26px;
        margin-left: 24px;
    }
  }

  .contentArea{
      margin: 20px 0 30px;
      .content {
          height: 22px;
          color: rgba(0, 0, 0, 0.75);
          font-family: $font_Inter;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 22px;
      }
  }


  .btn-close{
      float: right;
      margin-top: 5px;
      margin-right: 5px;
      color: #9E9E9E;
  }

  .btn-border-gray{
      box-sizing: border-box;
      height: 42px;
      width: 122px;
      border: 1px solid #E2E5ED;
      border-radius: 4px;
      box-shadow: none;
      color:#787878;
      font-family: $font_Inter;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 16px;
      text-align: center;
      text-transform: none;
  }
  .btn-cancel{
    background-color: #fff !important;
  }
  .btn-send, .btn-danger{
    height: 40px;
    width: 120px;
    border-radius: 4px;
    text-transform: none;
  }
}
