$dis-left: 16px;
$height-40: 40px;
$height-38: 38px;
$height-chip: 32px;
$padding-discussion: 16px;
$color-error: #d50000;




#writeminutes {
  width: 90%;
  margin: auto;

  .header {
    .left {
      height: 20px;
      width: 54px;
      color: #000000;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 20px;
    }
    .right {
      height: 14px;
      width: 172px;
      color: #9ea0a5;
      font-size: 11px;
      font-weight: 500;
      letter-spacing: 0.5px;
      line-height: 14px;
    }
  }

  .details {
    box-sizing: border-box;
    min-height: 338px;
    border: 1px solid #d8dce6;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);

    > div {
      padding: 20px;
      md-input-container {
        width: 100%;
        margin-bottom: 8px;
        .md-input{
          color: #000;
        }
        label {
          height: 14px;
          color: #9ea0a5;
          font-size: 11px;
          font-weight: 500;
          letter-spacing: 0.5px;
          line-height: 14px;
          margin: 0;
        }
        input::placeholder,
        input::-webkit-input-placeholder,
        input:-ms-input-placeholder,
        input::-moz-placeholder {
          height: 16px;
          color: #999999 !important;
          font-size: 13px;
          letter-spacing: 0;
          line-height: 16px;
        }
        input,
        md-select {
          box-sizing: border-box;
          height: 40px;
          border: 1px solid #d8dce6;
          border-radius: 4px;
          font-family: $font_Inter;
          font-size: 13px;
          letter-spacing: 0;
          line-height: 16px;
          margin-top: 8px;
        }

        .input-border,
        md-select md-select-value {
          padding-left: $dis-left;
        }
      }

      md-input-container.series-container {
        .series {
          md-autocomplete-wrap {
            box-shadow: none;
          }
          input {
            height: 100%;
          }
          button {
            display: none;
          }
          md-progress-linear {
            display: none;
          }
        }
      }

      .has-series-error input {
        border-color: $color-error;
      }
      .series-error {
        margin-top: 10px;
        font-size: 11px;
        letter-spacing: 0;
        color: $color-error;
      }

      .series-tooltip{
        margin-top: 10px;
        color: #9EA0A5;
        font-size: 11px;
        letter-spacing: 0;
        line-height: 14px;
      }

      .has-location-error {
        border-color: $color-error;
      }
      .location-error {
        color: $color-error;
        font-size: 11px;
        letter-spacing: 0;
      }

      .has-startdate-error {
        border-color: $color-error !important;

        .startdate-error {
          color: $color-error;
          font-size: 11px;
          letter-spacing: 0;
          margin-left: 0;
        }
      }
      .series-loading{
        margin-top: 7px;
      }
      md-input-container.date-time {
        label {
          left: 0;
        }
        #wm-datetime{
          padding-left: 38px;
          padding-right: 25px;
        }
      }

      md-input-container:not(.md-input-focused):not(.md-input-invalid) label.md-required:after {
        color: $color-error;
      }

      .seriesno {
        width: 100px;
        label {
          height: 14px;
          color: #9ea0a5;
          font-size: 11px;
          font-weight: 500;
          letter-spacing: 0.5px;
          line-height: 14px;
          text-transform: uppercase;
        }
        input {
          box-sizing: border-box;
          height: 40px;
          border: 1px solid #d9d9d9;
          border-radius: 4px;
          padding-left: 10px;
          font-size: 13px;
          background-image: none;
        }
      }
      md-input-container.md-input-container1 {
        width: 100%;
        margin: 16px 0 8px;
        md-chips-wrap {
          margin: 0;
          padding: 0;
          box-shadow: none;
          box-sizing: border-box;
          border: 1px solid #d8dce6;
          border-radius: 4px;

          margin-top: 6px;
          margin-bottom: 10px;

          .md-chip-input-container {
            padding: 0;
            height: $height-chip;
            margin: 4px;

            input {
              height: $height-chip;
              border: none;
              margin-top: 0;
            }
          }
        }

        md-chip {
          line-height: $height-chip;
          margin: 4px;
          height: $height-chip;
          border-radius: $height-chip * 0.5;

          color: #00a19c;
          font-weight: 500;
          letter-spacing: 0;
          background-color: transparent;

          box-sizing: border-box;
          border: 1px solid #00a19c;
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);

          .md-chip-content {
            height: inherit;
            line-height: 28px;
            span {
              font-size: 12px !important;
            }
          }

          .md-chip-remove-container {
            right: 5px;
            .icon-window-close {
              line-height: $height-chip;
              height: 10px;
              width: 10px;
              color: #999999;
              font-size: 13px;
            }
          }
        }
      }

      .security-mdselect {
        border-bottom: none;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
        .md-select-value {
          .md-text {
            font-size: 14px;
            color: #3e3f42;
            position: relative;
            top: -1px;
          }
        }
        .md-select-value .md-select-icon {
          background: url("../../../../../../assets/icons/dropdownicon.png") no-repeat 100% 100%;
          color: transparent;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translate(0, -50%);
          -ms-transform: translate(0, -50%);
        }
        .md-default-theme:not([disabled]):focus .md-select-value,
        &:not([disabled]):focus .md-select-value {
          border-bottom: 1px solid #d8dce6;
        }
      }
    }
  }
}

md-card.minutes-attachment {
  margin: 0;
  box-sizing: border-box;
  border: 1px solid #d8dce6;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
}

.minutes-attachment {
  overflow-x: auto;
  padding: 24px;
  margin-bottom: 40px !important;
  &::-webkit-scrollbar,
  .md-virtual-repeat-scroller::-webkit-scrollbar {
    height: 8px;
  }
  &::-webkit-scrollbar-thumb,
  .md-virtual-repeat-scroller::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
  .title {
    margin-bottom: 16px;
  }
  .attachment-container {
    align-self: flex-start;
    align-items: center;
    .attachment:last-of-type {
      padding: 0;
    }
    .attachment {
      box-sizing: border-box;
      height: 50px;
      width: 200px;
      border: 1px solid #d8dce6;
      border-radius: 4px;
      background-color: #ffffff;
      margin-right: 16px;
      position: relative;
      .file-icon {
        height: 24px;
        width: 24px;
        margin: 13px 4px 13px 15px;
        font-size: 24px;
        line-height: 24px;
      }
      .delete-icon {
        width: 10px;
        height: 10px;
        right: 9px;
        top: 17px;
        position: absolute;
        &:hover {
          cursor: pointer;
        }
        i {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.3);
          font-weight: bolder;
        }
      }
      .file-name {
        height: 15px;
        color: #000000;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 15px;
        width: 130px;

        display: inline !important;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word !important;
        white-space: nowrap;
      }
      a {
        text-decoration: none;
      }
    }
  }
  .add-attachment {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    margin-right: 16px;
    color: $color_petronas_green;
    .add-button {
      margin: 0 0 0 6px;
      color: $color_petronas_green;
      &:disabled {
        cursor: not-allowed;
      }
    }
    #fileInput {
      display: none;
    }
    box-sizing: border-box;
    height: 54px;
    width: 146px;
    border: 1px dashed #00a19c;
    background-color: #fafafa;

    .text {
      height: 16px;
      color: #00a19c;
      font-size: 13px;
      letter-spacing: 0;
      line-height: 16px;
      margin-right: 8px;
    }
  }
  .no-attachment {
    height: 16px;
    color: #999999;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 16px;
  }
  .attachment {
    a {
      color: $color_petronas_green;
    }
  }
}

.wm-title {
  height: auto;
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 12px;
  .md-icon-button {
    top: -4px;
    margin: 0 4px;
  }

  .minute-no {
    height: 15px;
    width: 163px;
    color: #999999;
    font-family: $font_Inter;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 15px;
    text-align: right;
    float: right;
    font-weight: 400;
  }
}

.wm-section2 {
  margin-top: 50px;
}

.wm-section1 {
  margin-top: 20px;
}

.wm-section3 {
  margin-top: 50px;
  .agenda {
    margin: 0;
    margin-bottom: 40px;
    padding: 24px;
    .agenda-item {
      // display: flex;
      // align-items: center;
      margin: 0;
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      .md-resize-wrapper {
        width: 100%;
      }
    }

    box-sizing: border-box;
    min-height: 221px;
    width: 100%;
    border: 1px solid #d8dce6;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);

    .fr-view {
      p {
        font-size: 14px !important;
      }
    }

    md-input-container {
      margin: 0;
      box-sizing: border-box;
      border: 1px solid #d8dce6;
      border-radius: 4px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
    }

    md-input-container.has-agenda-error {
      border-color: $color-error;
    }

    .agenda-error {
      color: $color-error;
      font-size: 11px;
      letter-spacing: 0;
      margin: 10px 0;
    }
  }
}

md-card.minutes-discussion {
  margin: 0;
  box-sizing: border-box;
  border: 1px solid #d8dce6;
  // border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
}

.wm-section4 {
  margin-top: 50px;
  .minutes-discussion-container {
    border-radius: 4px;
    background-color: rgba(217, 217, 217, 0.5);
    // padding-bottom: 100px;

    .add-discussion {
      .button-add-discussion-point {
        box-sizing: border-box;
        height: 43px;
        width: 177px;
        border: 1px solid #00a19c;
        border-radius: 4px;
        background-color: #ffffff;

        color: #00a19c;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 17px;

        margin-right: 24px;
        margin-bottom: 24px;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .minutes-discussion {
    background-color: rgba(217, 217, 217, 0.5) !important;
    position: relative;
    // background: white;
    .discussion-point:not(.first-discussion-point) {
      margin-top: 4px;
    }
    .discussion-point {
      background-color: white;
      // margin-bottom: 4px;
      padding: 0 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      md-input-container {
        background: white;
      }
      .discussion-header {
        padding: 0 24px;
        .header-main {
          md-input-container {
            input,
            md-select-value {
              padding-bottom: 1px !important;
              border: none;
            }
            input::-webkit-input-placeholder{
              color: #999999;
              font-family: $font_Inter;
              font-size: 13px;
              letter-spacing: 0;
              line-height: 16px;
            }
            input:-ms-input-placeholder {
              color: #999999 !important;
              font-family: $font_Inter;
              font-size: 13px;
              letter-spacing: 0;
              line-height: 16px;
            }
          }
          .discussion-title {
            padding: 12px 20px;
            font-family: $font_Inter;
            font-size: 14px;
            line-height: 16px;
            box-sizing: border-box;
            height: 40px;
            border: 1px solid #d8dce6;
            border-radius: 4px;
            background-color: #ffffff;
            color: #000000;
          }
          .discussion-title:-ms-input-placeholder {
            font-size: 14px;
            letter-spacing: 0;
            line-height: 16px;
            color: #999999 !important;
          }
          .has-discussion-title-error {
            border-color: $color-error;
          }
          .discussion-title-error {
            margin-top: 10px;
            color: $color-error;
            font-size: 11px;
            letter-spacing: 0;
          }
          md-input-container.md-default-theme .md-input::-webkit-input-placeholder,
          md-input-container .md-input::-webkit-input-placeholder {
            font-size: 14px;
            letter-spacing: 0;
            line-height: 16px;
            color: #999999;
          }
          .discussion-index {
            background: none;
            md-select {
              md-select-value {
                min-width: 48px;
                .md-text {
                  font-size: 1.8rem;
                }
                .md-select-icon {
                  width: auto;
                }
              }
            }
          }

          .delete-discussion.has-disabled{
            // opacity: 0.6;
            &:hover{
              cursor:not-allowed;
            }

          }
          .expand-discussion {
            md-icon {
              color: #000000;
              position: relative;
              top: 2px;
            }
          }
        }

        .discussion-description {
          display: flex;
          align-items: center;
          min-height: 72px;
          // padding: 8px 16px;
          margin: 0 0 16px;
          border: 1px solid rgba(0, 0, 0, 0.12);
          border-radius: 4px;
          .md-resize-wrapper {
            width: 100%;
          }
          .fr-placeholder {
            color: #999999;
            font-family: $font_Inter;
            font-size: 14px !important;
            letter-spacing: 0;
            line-height: 22px !important;
            padding-left: 20px !important;
            padding-top: 12px !important;
          }
          .fr-view {
            padding-top: 12px;
            padding-left: 20px;
            p {
              font-size: 14px !important;
            }
          }
        }

        .topic-attachment-container {
          overflow-y: hidden;
          overflow-x: auto;
          .topic-attachment {
            box-sizing: border-box;
            height: 50px;
            width: 200px;
            min-width: 200px;
            border: 1px solid #d8dce6;
            border-radius: 4px;
            background-color: #ffffff;
            margin-right: 16px;
            position: relative;
            .file-icon {
              height: 24px;
              width: 24px;
              margin: 13px 4px 13px 15px;
              font-size: 24px;
              line-height: 24px;
            }
            .delete-icon {
              width: 10px;
              height: 10px;
              right: 9px;
              top: 17px;
              position: absolute;
              &:hover {
                cursor: pointer;
              }
              i {
                font-size: 16px;
                color: rgba(0, 0, 0, 0.3);
                font-weight: bolder;
              }
            }
            .file-name {
              height: 15px;
              color: #000000;
              font-size: 12px;
              letter-spacing: 0;
              line-height: 15px;
              width: 130px;
              padding-right: 25px;

              display: inline !important;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              word-wrap: break-word !important;
              white-space: nowrap;
            }
            a {
              text-decoration: none;
            }
          }
        }

        .topic-comment{
          margin-top: 14px;
        }
      }
      .add-attachment {
        display: flex;
        align-items: center;
        color: $color_petronas_green;
        input {
          display: none;
        }
        &.disabled {
          .icon-paperclip {
            cursor: not-allowed;
            color: rgba(0, 0, 0, 0.21);
          }
        }
      }
      .discussion-button {
        position: relative;
        border-radius: 0 !important;
        .noti-number {
          position: absolute;
          top: -4px;
          right: -11px;
          line-height: 16px;
          font-size: 12px;
          color: white;
          background: $color_petronas_green;
          text-align: center;
          height: 23px;
          width: 23px;
          border-radius: 50%;
          background-color: #00a19c;

          span {
            line-height: 16px;
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            -ms-transform: translate(-65%, -50%);
            transform: translate(-65%, -50%);
          }
        }
      }
      .discussion-item {
        padding: 24px 0;
        .discussion-list {
          padding: 0 0;
          // border-top: 1px solid rgba(0, 0, 0, 0.12);
          .list-item-placeholder {
            // background: rgba(0, 0, 0, 0.12) !important;
            border-bottom: 1px solid transparent !important;
            * {
              opacity: 0;
            }
          }
          .list-item {
            position: relative;
            align-items: center;
            padding: 16px 0px;
            margin: 0;
            border-top: 1px solid rgba(0, 0, 0, 0.12);
            flex-wrap: wrap;
            md-input-container {
              margin: 0;
            }
            .action-area .md-resize-wrapper,
            .action-area input,
            .md-select-value {
              border: none;
            }
            .topic {
              md-icon {
                margin: 0 16px 0 0;
                cursor: move;
              }
              md-checkbox {
                width: 20px;
                max-width: 20px;
                margin: 0 16px 0 0;
              }
              .index {
                width: 20px;
                margin: 0 16px 0 0;
                position: relative;
              }
              .show-log {
                color: rgba(0, 0, 0, 0.85);
                font-family: $font_Inter;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 15px;
              }
              .show-log:hover:after {
                content: "Log";
                position: absolute;
                width: 100%;
                left: 0;
                background: rgb(245, 245, 245);
                color: $color_petronas_green;
                cursor: pointer;
              }
              .topic-description {
                // padding: 8px 16px;
                margin-bottom: 6px;
                border: 1px solid rgba(0, 0, 0, 0.12) !important;
                border-radius: 4px;
                width: 100%;
                min-height: 137px;
                .fr-placeholder {
                  color: #999999;
                  font-family: $font_Inter;
                  font-size: 14px !important;
                  letter-spacing: 0;
                  line-height: 22px !important;
                  padding-left: 20px !important;
                  padding-top: 12px !important;
                }
                .fr-view {
                  padding-left: 20px;
                  padding-top: 12px;
                  p {
                    font-size: 14px;
                  }
                }
              }

              .topic-description.has-topic-error {
                border: 1px solid $color-error !important;
              }

              .topic-error {
                color: $color-error;
                font-size: 11px;
                letter-spacing: 0;
                margin: 10px 0;
              }
            }
            .action-area {
              width: 223px;
              align-items: center;
              justify-content: flex-end;
              md-input-container {
                background: none;
                .select-type-discussion {
                  .md-select-value {
                    padding: 0;
                    padding-left: 12px;
                    height: 100%;
                    .md-text {
                      font-size: 14px;
                      color: #3e3f42;
                    }
                  }
                  .md-select-value .md-select-icon {
                    background: url("../../../../../../assets/icons/dropdownicon.png") no-repeat 100% 100%;
                    color: transparent;
                  }
                }
              }

              .delete-icon {
                padding-bottom: 6px !important;
              }
            }
            .wm-owner {
              margin: 10px 0;
              background-color: transparent;
              padding: 1px;
              border: 1px solid #d8dce6;
              height: 36px;
              md-chips-wrap {
                padding: 0;
                box-shadow: none;

                input {
                  padding: 2px 6px !important;
                }
              }
              .md-chip-input-container {
                width: 100%;
                margin: -4px;
                margin-left: 0;
              }
              md-chip {
                padding-left: 40px;
                width: 100%;
                height: 32px;
                border-radius: 4px;
                background-color: #eeeeee !important;
                .chip-image {
                  position: absolute;
                  left: 8px;
                  top: 6px;
                  width: 20px;
                  height: 20px;
                  line-height: 20px;
                  border: 0.1px solid rgba(0, 0, 0, 0.01);
                  border-radius: 50%;
                }
                .chip-initial {
                  background: rgba(0, 0, 0, 0.38);
                  text-align: center;
                  color: white;
                }
                .chip-content-text {
                  height: 16px;
                  color: #000000;
                  font-size: 13px;
                  letter-spacing: 0;
                  line-height: 16px;
                }
                background-color: transparent;
                margin: 0;
              }
              .icon-close {
                font-size: 16px;
                padding-top: 4px;
              }
              md-autocomplete {
                border: none;
              }
            }

            .wm-owner.has-owner-error {
              border-color: $color-error;
              border-radius: 4px;
            }

            .owner-error {
              color: $color-error;
              font-size: 11px;
              letter-spacing: 0;
              margin-bottom: 10px;
            }

            .subtopic {
              border: 1px solid #d8dce6;
              border-radius: 4px;
              background: white;
              .subtopic-dueDate {
                width: 100px;
                padding-left: 12px;
                padding-right: 8px;
                height: 36px;
                display: flex;
                align-items: center;
                color: #999999;
                font-family: $font_Inter;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 12px;
              }
              .subtopic-datepicker {
                width: 123px;
                background-color: transparent;
                input {
                  border-bottom: none;
                }
                .md-required{
                  display: none; // the * has an error UI so I must hide it
                }
              }
              .duedate-error {
                position: absolute;
                top: 40px;
                left: -100px;
                color: $color-error;
                font-size: 11px;
                letter-spacing: 0;
              }
            }

            .subtopic.has-duedate-error {
              border-color: $color-error;
            }

            md-autocomplete {
              min-width: auto;
              height: 40px;
              line-height: 40px;
              padding: 3px;
              margin-bottom: 4px;
              border: 1px solid #d8dce6;
              border-radius: 4px;
              md-autocomplete-wrap {
                box-shadow: none;
                height: 32px;
                line-height: 32px;
                input {
                  height: 32px;
                  line-height: 32px;
                  color: #000000;
                }
                input::placeholder {
                  color: #999;
                  font-family: $font_Inter;
                  font-size: 14px;
                  letter-spacing: 0;
                  line-height: 12px;
                }
                input::-webkit-input-placeholder {
                  color: #999;
                  font-family: $font_Inter;
                  font-size: 14px;
                  letter-spacing: 0;
                  line-height: 12px;
                }
                input::-moz-input-placeholder {
                  color: #999;
                  font-family: $font_Inter;
                  font-size: 14px;
                  letter-spacing: 0;
                  line-height: 12px;
                }
                input:-ms-input-placeholder {
                  color: #999;
                  font-family: $font_Inter;
                  font-size: 14px;
                  letter-spacing: 0;
                  line-height: 12px;
                }
                button {
                  width: 24px;
                  height: 24px;
                  margin: 4px;
                }
              }
            }
            md-autocomplete.selectedChip {
              md-autocomplete-wrap {
                padding: 0 8px;
                border-radius: 32px;
                background: rgb(224, 224, 224);
                input {
                  border-radius: 32px;
                  background: rgb(224, 224, 224);
                }
                md-icon {
                  width: 18px;
                  min-width: 18px;
                  height: 18px;
                  min-height: 18px;
                  line-height: 18px;
                  color: rgba(0, 0, 0, 0.54);
                }
              }
            }
            mdp-date-picker {
              // border: 1px solid rgba(0,0,0,.12);
              button {
                display: none;
              }
              input {
                font-family: $font_Inter !important;
              }
            }
            .comment-box {
              margin: 8px 0;
            }
          }
        }
      }

      .add-discussion-item-container {
        padding: 20px 24px;
        .add-discussion-item {
          box-sizing: border-box;
          height: 40px;
          width: 151px;
          border: 1px solid #00a19c;
          border-radius: 4px;
          background-color: #ffffff;

          color: #00a19c;
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 15px;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    .discussion-point-expanded {
      // border-top: 2px solid rgba(0,0,0,.38);
      // border-bottom: 2px solid rgba(0,0,0,.38);
      border: none;
      // background: rgba(0,0,0,.04);
      margin-bottom: 2px;
    }
    .add-discussion:first-of-type {
      margin-top: 0px;
    }
  }

  .dragable {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    pointer-events: auto;
  }
}

#bar-buttons {
  .btn-log,
  .btn-preious {
    height: 42px;
    width: 158px;
    span {
      color: rgba(0, 0, 0, 0.5);
      font-family: $font_Inter;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 17px;
      text-align: center;
      margin-right: 7.5px;
    }
  }
  .btn-log {
    margin-left: 20px;
  }
  .btn-preious {
    margin-right: 12px;
    .icon-previous {
      margin-top: 4px;
    }
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .send-minute {
      top: 2px;
    }
  }
}
.wm-section5 {
  margin-top: 50px;
  selected-comment{
    margin-bottom: 79px;
  }
}

selected-comment {
  .comment-box {
    background-color: white;
  }
  .control-box {
    background-color: white !important;
    border-top: none !important;
  }
}


.wm-question-mark-tooltip {
  margin-left: 55px;
  height: auto;
  width: 349px;
  border-radius: 4px;
  background-color: #212121;
  box-shadow: 0 1px 4px 0 rgba(85,85,85,0.2);
  .md-content{
    span{
      font-size: 10px !important;
    }
    white-space: normal !important;
    background-color: #212121 !important;
    color: #FFFFFF;
    font-size: 10px !important;
    letter-spacing: 0 !important;
    line-height: 14px !important;
    font-family: $font_Inter !important;
    padding: 5px 5px 5px 13px;
  }
}

md-tooltip{
  .md-content{
    white-space: normal !important;
    background-color: #212121 !important;
    color: #FFFFFF;
    font-size: 10px !important;
    letter-spacing: 0 !important;
    line-height: 14px !important;
    font-family: $font_Inter !important;
    padding: 5px;
  }
}

.progress-attachment-container {
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  .progress-attachments {
      flex: 0 200px;
      padding-right: 20px;
      margin-bottom: 20px;
      height: 21px;
      .pa-filename{
        color: #000000;
        font-family: $font_Inter;
        font-size: 12px;
        letter-spacing: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 200px;
        height: 15px;
        white-space: nowrap;
      }

      .pa-bar {
          width: 200px;
          line-height: 4px;
          height: 4px;
          border-radius: 1px;
          &::-webkit-progress-bar {
            color: #eeeeee;
            background-color: #eeeeee;
            border-radius: 2px;
          }
          &::-webkit-progress-value {
            color: #4CAF50;
            background-color: #4CAF50;
            border-radius: 2px;
          } // for firefox
          &::-moz-progress-bar {
            color: #eeeeee;
            background-color: #eeeeee;
            border-radius: 2px;
          }
          &::-moz-progress-value {
            color: #4CAF50;
            background-color: #4CAF50;
            border-radius: 2px;
          }
          &::-ms-fill{
            color: #4CAF50;
            background-color: #4CAF50;
            border-radius: 2px;
          }
      }

      .pa-bar[value]{
        background-color: #eeeeee;
        color: #4CAF50;
        border-radius: 2px;
        position: relative;
        top: -9px;
      }
      .pa-bar[value]::-ms-fill{
        background-color: #eeeeee;
        color: #4CAF50;
        border-radius: 2px;
      }

  }
  .my-progress{
    .md-mode-determinate{
        background-color: #EEEEEE !important;
        height: 4px;
        width: 200px;
        border-radius: 4px;
        margin-top: 5px;
    }
    .md-bar{
        background-color: #4CAF50 !important;
    }
  }
}


