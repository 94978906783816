#create-myminutes {
  background-color: #fbfbfd;
  .title {
    font-weight: bold;
    padding: 30px 0 50px 0;
    padding-left: $marginLeftHomePages;
    background-color: white;
    margin-bottom: 2px;
    margin-top: 0;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
  }

  .bg-create-minute {
    background-color: #fbfbfd;
    height: 99%;
    margin-top: 2px;
  }
  md-tab-item {
    height: 56px;
    text-transform: none;
    &.md-tab {
      color: #6d7278;
    }
    &.md-tab span {
      font-family: $font_Inter;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 16px;
    }
    &.md-tab.md-active {
      color: #000000;
    }
  }
  md-tab-item:first-child {
    padding-left: $marginLeftHomePages;
  }
  .md-disabled {
    color: #6d7278 !important;
    cursor: not-allowed !important;
    opacity: 1;
    pointer-events: none;
  }
  .disable-012 {
    color: #b3e3e1 !important;
  }
  .enable-3 {
    color: #00a19c !important;
    cursor: pointer;
  }
  md-pagination-wrapper {
    width: 100% !important;
    height: 56px;
    cursor: not-allowed;
  }

  md-tabs {
    height: 80vh;
  }

  md-content {
    height: 100%;
  }
  md-ink-bar {
    height: 4px;
  }
  md-tabs-wrapper {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !important;
    height: 56px;
  }
  md-tabs-canvas {
    height: 56px;
  }
  md-tabs-content-wrapper {
    top: 58px;
  }

  .btn-border-gray {
    box-sizing: border-box;
    border: 1px solid #e2e5ed;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.5);
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
      sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
  }

  .btn-container {
    position: absolute;
    right: calc(10% - 38px);
    z-index: 1;
    .btn-save {
      margin: 8px 0;
      padding: 11.5px 15.5px;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.07);
      height: 41px;
      width: 82px;
      .content-area {
        span {
          font-family: $font_Inter;
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 16px;
        }
      }
    }
  }
}

.btn-border-gray {
  box-sizing: border-box;
  border: 1px solid #e2e5ed;
  border-radius: 4px;
  box-shadow: none;
  color: #787878;
  font-family: $font_Inter;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  text-transform: none;
}
.toast-top-right {
  top: 95px;
  right: 36px;
}

#toast-container > div {
  box-shadow: none;
  height: auto !important;
  padding: 15px 15px 10px 50px;
}

#toast-container > :hover {
  box-shadow: none;
}

md-tab-data {
  opacity: 1;
  display: table;
  right: 0;
  left: initial;
  z-index: 20;
}

.logger-error-empty-field {
  color: #d50000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  vertical-align: middle;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .logger-error-empty-field {
    color: #d50000;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    vertical-align: middle;
  }
}
