#edit {
    md-card {
        background: white;
    }
    .detail-header {
        margin-bottom: 24px;
        .review-view {
            .header {
                padding: 0 8px;
                margin-bottom: 8px;
            }
            .meeting-detail {
                padding: 8px;
                border-left: 4px solid $color_petronas_green;
                background-color: rgba(255,255,255,.7);
                .md-button {
                    float: right;
                }
                .meeting-detail-item {
                    .detail-title {
                        font-size: 1.0rem;
                        color: rgba(0,0,0,.54);
                    }
                    .detail-content {
                        margin-bottom: 8px;
                    }
                }
            }
        }
    }
    .attachment-header {
        margin-bottom: 16px;
    }
    .minutes-attachment {
        &::-webkit-scrollbar,.md-virtual-repeat-scroller::-webkit-scrollbar {
            height: 8px;
        }
        &::-webkit-scrollbar-thumb, .md-virtual-repeat-scroller::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 1px solid slategrey;
        }
        overflow-x:scroll;
        padding: 24px;
        margin-bottom: 24px;  
        .attachment-container {
            align-self: flex-start;
            align-items: center;
            .attachment {
                width: 72px;
                margin-right: 8px;
                position: relative;
                .file-icon {
                    width: 36px;
                    height: 36px;
                    margin: auto;
                    margin-bottom: 4px;
                    font-size: 36px;
                    line-height: normal;
                }
                .delete-icon {
                        right: 0;
                        position: absolute;
                        display: none;
                    }
                &:hover {
                    .delete-icon {
                        display: block;
                    }    
                }
                .file-name {
                    font-size: 1.2rem;
                    text-align: center;
                    display: inline !important;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-wrap: break-word;
                    white-space: nowrap;
                }
            }
            .attachment:last-of-type {
                padding: 0;
            }
        }
    }
    .topic-attachment-container{
        white-space: nowrap;
        display: block;
        padding: 16px;
        overflow-x: scroll !important;
        &::-webkit-scrollbar,.md-virtual-repeat-scroller::-webkit-scrollbar {
            height: 8px;
        }
        &::-webkit-scrollbar-thumb, .md-virtual-repeat-scroller::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 1px solid slategrey;
        }
    }
    
    .topic-attachment {   
        display: inline-block;    
        position: relative;
        width: 96px;
        margin-right: 8px;
        .delete-icon {
            position: absolute;
            right: 0;
            display: none;
        }
        &:hover {
            .delete-icon {
                display: block;
            }    
        }
        
        .file-icon {
            width: 36px;
            height: 36px;
            margin: auto;
            margin-bottom: 4px;
            font-size: 36px;
            line-height: normal;
        }
        .file-name {
            width: 96px;
            font-size: 1.2rem;
            text-align: center;
            display: inline !important;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
            white-space: nowrap;
        }
    }
    .comment-button {
        position: relative;
        border-radius: 0 !important;
        .comment-count {
            position: absolute;
            top: 0px;
            right: 0px;
            line-height: 16px;
            font-size: 12px;
            border-radius: 16px;
            width: 16px;
            height: 16px;
            color: white;
            background: $color_petronas_green;
            text-align: center;
        }
    }
    .attachment-button {
        position: relative;
        border-radius: 0 !important;
        .attachment-count {
            position: absolute;
            top: -8px;
            right: -4px;
            line-height: 16px;
            font-size: 12px;
            border-radius: 16px;
            width: 16px;
            height: 16px;
            color: white;
            background: $color_petronas_green;
            text-align: center;
        }
    }
}

.md-button.disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
}

@media(min-width: 960px) {
    #edit {
        .header {
            align-items: flex-end;
            margin-bottom: 16px;
            .right {
                text-align: right;
            }
        }
        .detail-header {
            margin-bottom: 40px;
            .review-view {
                .header {
                    padding: 0 16px;
                    margin-bottom: 24px;
                    .right {
                        text-align: right;
                    }
                }
                .meeting-detail {
                    .meeting-detail-item {
                        display: table-row;           
                        .detail-title {
                            display: table-cell;
                            padding: 8px 24px 8px 8px;
                            font-family: "Museo Sans", sans-serif;
                            font-size: 1.4rem;
                            font-weight: 700;
                            color: rgba(0,0,0,.38);
                            text-transform: uppercase;   
                        }
                    }
                }
            }
            .edit-view {
                .main-detail {
                    margin: 0;
                    .meeting-detail {
                        padding: 24px 24px 0;
                        margin-bottom: 24px;
                        .series-container {
                            margin-bottom: 18px;
                            .label {
                                padding-left: 3px;
                                transform: scale(0.75);
                                transform-origin: left top;
                                color:rgba(0,0,0,.38);
                            }
                            .series {
                                height: 30px;
                                line-height: 30px;
                                border-bottom:1px dotted rgba(0,0,0,.12);
                                color:rgba(0,0,0,.38);
                            }
                        }
                        .title-container {
                            padding-left: 0;
                        }
                        mdp-time-picker {
                                margin-right: 24px;
                            }
                        .date {
                            mdp-date-picker {
                                margin-right: 80px;
                            }
                            mdp-time-picker {
                                margin-right: 24px;
                            }
                            mdp-date-picker, mdp-time-picker {
                                md-input-container {
                                    width: auto;
                                }
                                .md-button {
                                    display: none;
                                }
                            }
                        }
                    }
                    .people-detail {
                        padding: 0 24px 24px;
                        .header {
                            margin-bottom: 16px;
                            color: rgba(0,0,0,.54);
                        }
                        .body {
                            padding: 0 16px;
                            .action-area {
                                div {
                                    padding: 16px 0;
                                    margin-right: 40px;
                                    color: $color_petronas_green;
                                    align-items: center;
                                    display: flex;
                                    md-icon {
                                        margin-right: 4px;
                                        color: $color_petronas_green;
                                    }
                                }
                            }
                            
                            md-chip {
                                padding-left: 40px;
                                .chip-image {
                                    position: absolute;
                                    left: 0;
                                    width: 32px;
                                    height: 32px;
                                    line-height: 32px;
                                    border: 0.1px solid rgba(0,0,0,.01);
                                    border-radius: 50%;
                                }
                                .chip-initial {
                                    background: rgba(0,0,0,.38);
                                    text-align: center;
                                    color: white;
                                }
                            }
                        }
                    }
                    md-input-container {
                        width: 100%;
                        md-chips {
                            md-chips-wrap {
                                padding: 0 0 4px;
                                .md-chip-input-container {
                                    margin: 4px 8px 0 0;
                                    md-autocomplete {
                                        md-autocomplete-wrap {
                                            input {
                                                width: 365px;
                                                padding: 2px 2px 1px !important;
                                            }
                                        }
                                    }
                                }
                                md-chip {
                                    height: 32px;
                                    line-height: 32px;
                                    padding-right: 28px;
                                    margin: 4px 8px 0 0;
                                    md-chip-template {
                                        strong {
                                            font-weight: normal;
                                        }
                                    }
                                    .md-chip-remove-container {
                                        line-height: normal;
                                        button {
                                            width: 24px;
                                            height: 24px;
                                            margin: 4px;
                                            md-icon {
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                            }
                                            icon-close-circle{
                                                margin-right:16px;
                                            }
                                            .icon-close:before {
                                                font-size: 16px;
                                                color: rgba(0,0,0,.38);
                                            }
                                            .icon-close-circle:before {
                                                font-size: 20px;
                                                color: rgba(0,0,0,.38);
                                            }
                                        }
                                        button:hover md-icon:before {
                                            color: $color_petronas_red;
                                        }
                                    }
                                }
                            }
                        }
                        .md-chips:not(.md-readonly) .md-chip:not(.md-readonly) {
                            padding-right: 28px;
                        }
                    }
                }
            }
        }
        .minutes-attachment {
            margin-bottom: 40px !important;
            .title {
                margin-bottom: 16px;
            }
            .attachment-container {
                .attachment {
                    width: 96px;
                    margin-right: 16px;
                    position: relative;
                    .file-icon {
                        width: 48px;
                        height: 48px;
                        margin: auto;
                        margin-bottom: 8px;
                        font-size: 48px;
                        line-height: normal;
                    }
                    .delete-icon {
                        right: 0;
                        position: absolute;
                        display: none;
                    }
                    &:hover {
                        .delete-icon {
                            display: block;
                        }    
                    }
                    .file-name {
                        font-size: 1.2rem;
                        text-align: center;
                        display: inline !important;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-wrap: break-word;
                        white-space: nowrap;
                    }
                }
                .attachment:last-of-type {
                    padding: 0;
                }
            }
            .add-attachment {
                display: flex;
                align-items: center;
                padding: 8px 16px;
                margin-right: 16px;
                border: 2px dashed $color_petronas_green;
                color: $color_petronas_green;
                .add-button {
                    margin: 0 0 8px;
                    color: $color_petronas_green;
                    &:disabled {
                        cursor: not-allowed;
                    }
                }
                #fileInput {
                    display: none;
                }
            }
            .attachment {
                a {
                    color: $color_petronas_green;
                }
            }
        }
        .agenda-header {
            margin-bottom: 16px;
        }
        .agenda {
            margin-bottom: 40px;
            padding: 24px;
            .agenda-item {
                display: flex;
                align-items: center;
                margin: 0;
                border: 1px solid rgba(0,0,0,.12);
                border-radius: 4px;
                .md-resize-wrapper {
                    width: 100%;
                }
            }
        }
        .minutes-discussion {
            position: relative;
            padding: 24px;
            background: white;
            .discussion-point:first-of-type {
                border-top: 1px solid rgba(0,0,0,.12);
            }        
            .discussion-point {
                padding: 0 16px;
                border-bottom: 1px solid rgba(0,0,0,.12);
                md-input-container {
                    background: white;
                }
                .discussion-header {
                    .header-main {
                        .title {
                            border: 1px solid rgba(0,0,0,.12);
                            border-radius: 4px;
                            input::-webkit-input-placeholder {
                                font-size: 2.0rem;
                            }
                            &.title-focused {
                                border: 2px solid rgba(0,0,0,.87);
                                padding: 1px;
                            }
                        }
                        md-input-container {
                            input, md-select-value {
                                padding-bottom: 1px !important;
                                border: none;
                            }
                            input::-webkit-input-placeholder {
                                font-size: 1.3rem;
                            }
                        }
                        .discussion-title {
                            border-style: none;
                        }
                        .discussion-index {
                            background: none;
                            md-select {
                                md-select-value {
                                    min-width: 48px;
                                    .md-text {
                                        font-size: 1.8rem;
                                    }
                                    .md-select-icon {
                                        width: auto;
                                    }
                                }
                            }
                        }
                    }
                    .add-attachment {
                        display: flex;
                        align-items: center;
                        color: $color_petronas_green;
                        input {
                            display: none;
                        }
                        &.disabled {
                            .icon-paperclip {
                                cursor: not-allowed;
                                color: rgba(0,0,0,.21);
                            }
                        }
                    }
                    .discussion-description {
                        display: flex;
                        align-items: center;
                        min-height: 72px;
                        // padding: 8px 16px;
                        margin: 0 0 16px;
                        border: 1px solid rgba(0,0,0,.12);
                        border-radius: 4px;
                        .md-resize-wrapper {
                            width: 100%;
                        }
                    }
                }
                .discussion-item {
                    padding: 24px 0;
                    .discussion-list {
                        border-top: 1px solid rgba(0,0,0,.12);
                        .list-item-placeholder {
                            background: rgba(0, 0, 0, 0.12) !important;
                            border-bottom: 1px solid transparent !important;
                            * {
                                opacity: 0;
                            }
                        }
                        .list-item {
                            position: relative;
                            align-items: center;
                            padding: 16px;
                            margin: 0;
                            border-bottom: 1px solid rgba(0,0,0,.12);
                            flex-wrap: wrap;
                            md-input-container {
                                margin: 0;
                            }
                            .action-area .md-resize-wrapper, .action-area input, .md-select-value {
                                border: none;
                            }
                            .topic {
                                align-items: center;
                                md-icon {
                                    margin: 0 16px 0 0;
                                    cursor: move;
                                }
                                md-checkbox {
                                    width: 20px;
                                    max-width: 20px;
                                    margin: 0 16px 0 0;
                                }
                                .index {
                                    width: 20px;
                                    margin: 0 16px 0 0;
                                    position: relative;
                                }
                                .show-log:hover:after {
                                    content:'Log';
                                    position: absolute;
                                    width: 100%;
                                    left: 0;
                                    background: rgb(245,245,245);
                                    color: $color_petronas_green;
                                    cursor: pointer;
                                }
                                .topic-description {
                                    // padding: 8px 16px;
                                    border: 1px solid rgba(0,0,0,.12);
                                    border-radius: 4px;
                                }
                            }
                            .action-area {
                                width: 240px;
                                align-items: center;
                                justify-content: flex-end;
                                md-input-container {
                                    background:none
                                }
                            }
                            .subtopic {
                                border: 1px solid rgba(0, 0, 0, 0.12);
                                background: white;
                                .subtopic-dueDate {
                                    background: white;
                                    color: rgba(0,0,0,.38);
                                    padding-left: 8px;
                                    padding-right: 8px;
                                    height: 36px;
                                    display: flex;
                                    align-items: center;
                                }
                            }
                            
                            md-autocomplete {
                                min-width: auto;
                                height: 40px;
                                line-height: 40px;
                                padding: 3px;
                                margin-bottom: 4px;
                                border: 1px solid rgba(0,0,0,.12);
                                md-autocomplete-wrap {
                                    box-shadow: none;
                                    height: 32px;
                                    line-height: 32px;
                                    input {
                                        height: 32px;
                                        line-height: 32px;
                                        padding: 0 4px !important;
                                    }
                                    input::-webkit-input-placeholder {
                                        color: rgba(0,0,0,.38);
                                        
                                    }
                                    button {
                                        width: 24px;
                                        height: 24px;
                                        margin: 4px;
                                    }
                                }
                            }
                            md-autocomplete.selectedChip  {
                                md-autocomplete-wrap {
                                    padding: 0 8px;
                                    border-radius: 32px;
                                    background: rgb(224,224,224);
                                    input {
                                        border-radius: 32px;
                                        background: rgb(224,224,224);
                                    }
                                    md-icon {
                                        width: 18px;
                                        min-width: 18px;
                                        height: 18px;
                                        min-height: 18px;
                                        line-height: 18px;
                                        color: rgba(0,0,0,.54);
                                    }
                                }
                            }
                            mdp-date-picker {
                                // border: 1px solid rgba(0,0,0,.12);
                                button {
                                    display: none;
                                }
                                input {
                                    font-family: 'Museo Sans 700', sans-serif !important;
                                }
                            }
                            .comment-box {
                                margin: 8px 0;
                            }
                        }
                    }
                }
                .add-discussion-item {
                    display: flex;
                    align-items: center;
                    margin-top: 16px;
                    color: $color_petronas_green;
                    cursor: pointer;
                    md-icon {
                    margin: 0 4px 0 0;
                        color: $color_petronas_green;
                    }
                }
            }
            .discussion-point-expanded {
                // border-top: 2px solid rgba(0,0,0,.38);
                // border-bottom: 2px solid rgba(0,0,0,.38);
                border: none;
                box-shadow: 0 -1px 2px rgba(0, 0, 0, .12),  0 0 2px rgba(0, 0, 0, .12), 0px 2px 4px rgba(0, 0, 0, .24);
                background: rgba(0,0,0,.04);
                margin-bottom: 2px;
            }
            .add-discussion:first-of-type {
                margin-top: 0px;
            }
        }
        .button-green {
            float: right;  
        }
    }
}

// Discussion drag & drop
.list-item-ghost {
    align-items: center;
    padding: 16px;
    background: white;
    box-shadow: 0 -1px 2px rgba(0, 0, 0, .12),  0 0 2px rgba(0, 0, 0, .12), 0px 2px 4px rgba(0, 0, 0, .24);
    flex-wrap: wrap;
    md-input-container {
        margin: 0;
    }
    .md-resize-wrapper, input, .md-select-value {
        border: none;
    }
    .topic {
        align-items: center;
        md-icon {
            margin: 0 16px 0 0;
            cursor: move;
        }
        md-checkbox {
            width: 20px;
            max-width: 20px;
            margin: 0 16px 0 0;
        }
        .index {
            width: 20px;
            margin: 0 16px 0 0;
        }
        .topic-description {
            padding: 8px 16px;
            border: 1px solid rgba(0,0,0,.12);
            border-radius: 4px;
        }
    }
    .action-area {
        width: 240px;
        align-items: center;
        justify-content: flex-end;
        md-input-container {
            background:none
        }
    }
    md-autocomplete {
        min-width: auto;
        height: 40px;
        line-height: 40px;
        padding: 3px;
        margin-bottom: 4px;
        border: 1px solid rgba(0,0,0,.12);
        md-autocomplete-wrap {
            box-shadow: none;
            height: 32px;
            line-height: 32px;
            input {
                height: 32px;
                line-height: 32px;
            }
            input::-webkit-input-placeholder {
                color: rgba(0,0,0,.38);
            }
            button {
                width: 24px;
                height: 24px;
                margin: 4px;
            }
        }
    }
    md-autocomplete.selectedChip  {
        md-autocomplete-wrap {
            border-radius: 32px;
            background: rgb(224,224,224);
            input {
                border-radius: 32px;
                background: rgb(224,224,224);
            }
            md-icon {
                width: 18px;
                min-width: 18px;
                height: 18px;
                min-height: 18px;
                line-height: 18px;
                color: rgba(0,0,0,.54);
            }
        }
    }
    mdp-date-picker {
        border: 1px solid rgba(0,0,0,.12);
        button {
            display: none;
        }
        input {
            font-family: 'Museo Sans 700', sans-serif !important;
        }
    }
    selected-comment {
        display: none;
    }
}


#bar-buttons {
    display: flex;
    align-items: center;
    .toolbar-button {
        text-transform: none;
    }
}

.fr-toolbar {
    visibility: visible;
    margin: -40px 1px 0px 1px;
    box-shadow: none !important;
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.fr-box.fr-basic .fr-element.fr-view {
    padding-bottom: 44px;
}

.fr-box.fr-basic.fr-bottom .fr-wrapper {
    box-shadow: none;
    // border: 1px solid rgba(0, 0, 0, .12);
}

md-tooltip.multi .md-content {
    width: 400px;
    max-width: 400px;
    padding: 8px;
    height: auto !important;
    white-space: normal;
    text-overflow: clip !important;
}
.resend-email-user-checkbox{
    min-height: initial !important;
}

.txtTag .md-chip-input-container,
.txtMember .md-chip-input-container,
.txtChairman .md-chip-input-container,
.txtInvitees .md-chip-input-container,
.txtAbsentees .md-chip-input-container{
    min-width: 360px;
}
