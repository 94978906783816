$input-height: 40px;
$item-height: 32px;
$icon-add-height: 18px;
$space-left: 40px;
$avatar-dim: 24px;
$space-right: 70px;

md-virtual-repeat-container {
  width: 300px;
  min-height: 75px;
  max-height: 200px;
  .list-participants {
    box-sizing: border-box;
    width: 305px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    li {
      height: auto;

      .container {
        padding: 8px 0;
        .username-ap {
          height: 16px;
          color: #000000;
          font-size: 13px;
          letter-spacing: 0;
          line-height: 16px;
        }

        .email-ap {
          height: 15px;
          color: rgba(0, 0, 0, 0.25);
          font-size: 12px;
          letter-spacing: 0;
          line-height: 15px;
        }

        .avatar-wrapper {
          margin-left: 10px;
          margin-right: 5px;
          img {
            width: $avatar-dim;
            height: $avatar-dim;
          }
          position: relative;

          .status {
            position: absolute;
            bottom: -3px;
            right: 5px;
          }
          .image {
            width: $avatar-dim;
            min-width: $avatar-dim;
            max-width: $avatar-dim;
            line-height: $avatar-dim;
            margin: 0;
            border-radius: 50%;
            text-align: center;
            color: white;
            background-color: $color_petronas_purple;
          }

          .avatar-ap {
            width: 24px;
            min-width: 24px;
            height: 24px;
            line-height: 24px;
            // margin: 0 8px 0 0;
            border-radius: 50%;
            font-size: 17px;
            font-weight: 500;
            text-align: center;
            color: #ffffff;
          }
        }
      }
    }
  }
  .list-participants::before {
    content: "Suggestions";
    color: #9ea0a5;
    font-family: $font_Inter;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 14px;
    text-transform: uppercase;
    padding: 8px 207px 4px 13px;
    display: block;
  }
}

#add-participants {
  padding: 60px;
  min-height: 91%;
  .icon-add-user {
    width: $icon-add-height;
    height: $icon-add-height;
    position: absolute;
    top: ($input-height - $icon-add-height) * 0.5;
    left: ($space-left - $icon-add-height) * 0.5;
  }

  .clear-all {
    position: absolute;
    right: 20px;
    top: 10px;
    // top: 50%;
    // transform: translate(0, -50%);
    border: none;
    background-color: transparent;
    color: #999999;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 20px;
  }

  .icon-user {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translate(0, -50%);
  }

  .avatar-wrapper {
    img {
      width: $avatar-dim;
      height: $avatar-dim;
    }
    position: relative;

    .status {
      position: absolute;
      bottom: -3px;
      right: 5px;
    }
    .image {
      width: $avatar-dim;
      min-width: $avatar-dim;
      max-width: $avatar-dim;
      line-height: $avatar-dim;
      margin: 0;
      border-radius: 50%;
      text-align: center;
      color: white;
      background-color: $color_petronas_purple;
    }

    .avatar-ap {
      width: 24px;
      min-width: 24px;
      height: 24px;
      line-height: 24px;
      // margin: 0 8px 0 0;
      border-radius: 50%;
      font-size: 17px;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
    }
  }

  .close-icon {
    margin-top: 8px;
  }

  .add-btn {
    color: #00a19c;
    font-family: $font_Inter;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    box-sizing: border-box;
    height: $input-height;
    width: 66px;
    border: 1px solid #00a19c;
    border-radius: 4px;
    background-color: #ffffff;
    margin-left: 5px;
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  md-chips.md-default-theme .md-chips,
  md-chips .md-chips {
    box-shadow: 0 0px rgba(0, 0, 0, 0.12);
    padding-left: $space-left;
    padding-right: $space-right;
    padding-bottom: 4px;
    box-sizing: border-box;
    min-height: $input-height;
    max-height: $item-height * 3 + 18;
    border: 1px solid #cbcbcb;
    border-radius: 4px;
    background: #ffffff;
    overflow-x: auto;
  }

  md-chips.md-default-theme md-chip,
  md-chips md-chip {
    min-height: $item-height;
    border-radius: 4px;
    margin-top: ($input-height - $item-height) * 0.5;
    background: #eee;
    .username-ap {
      margin-left: 5px;
      font-size: 13px;
      color: #000000;
      letter-spacing: 0;
      line-height: 16px;
    }
  }

  md-chips md-chip-template {
    height: $item-height;
  }

  md-input-container {
    margin: 0;
    padding: 0;
    position: relative;
    .md-chip-input-container {
      min-height: $item-height;
      margin-top: ($input-height - $item-height) * 0.5;
    }
    label {
      display: none;
    }
  }

  .md-chips {
    padding: 0 0 0 3px;
  }

  .lop {
    height: 19px;
    color: #000000;
    font-family: $font_Inter;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
    margin-top: 24px;
    margin-bottom: 24px;
    .minute-no {
      height: 15px;
      width: 163px;
      color: #999999;
      font-family: $font_Inter;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 15px;
      text-align: right;
      float: right;
      font-weight: 400;
    }
  }

  .btn-remove-user {
    margin-top: 5px;
    margin-right: 2px;
    .icon-window-close {
      font-size: 20px;
      color: #9e9e9e;
    }
  }
  md-autocomplete-wrap {
    input::-webkit-input-placeholder {
      color: #999999;
      font-family: $font_Inter;
      font-size: 13px;
      letter-spacing: 0;
      line-height: 16px;
    }
  }
}

@media (min-width: 960px) {
  .md-padding {
    padding: 16px 0 0 0;
  }
}

.table-members {
  font-family: $font_Inter;
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #eee;
  box-shadow: 0 1px 0 0 #eaedf3;
  background-color: #fff;

  .name {
    padding-left: 30px;
    .container {
      .username-list {
        color: #3e3f42;
        font-size: 13px;
        letter-spacing: 0;
        line-height: 22px;
      }

      .email-list {
        color: #9ea0a5;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
      }
      .avatar-wrapper {
        margin-right: 10px;
        position: relative;

        img {
          width: 36px;
          height: 36px;
        }
        .status {
          position: absolute;
          bottom: -3px;
          right: 5px;
        }
        .image {
          width: 36px !important;
          min-width: 36px !important;
          max-width: 36px !important;
          line-height: 36px !important;
          margin: 0;
          border-radius: 50%;
          text-align: center;
          color: white;
          background-color: $color_petronas_purple;
        }
        .avatar-list {
          width: 36px;
          min-width: 36px;
          height: 36px;
          line-height: 36px;
          border-radius: 50%;
          font-size: 17px;
          font-weight: 500;
          text-align: center;
          color: #ffffff;
        }
      }
    }
  }
  .attendance {
    width: 10%;
  }
  .role {
    width: 17%;
  }
  .view {
    width: 7%;
  }
  .edit {
    width: 8%;
  }
  .comment {
    width: 7%;
  }
  .download {
    width: 8.5%;
  }
  .remove {
    width: 4%;
    padding-right: 30px;
  }
  th {
    padding: 15px 0px;
    text-align: left;
    background-color: #ffffff;
    color: #9ea0a5;
    font-family: $font_Inter;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 14px;
    text-transform: uppercase;
    border-bottom: 1px solid #eee;
  }

  td {
    text-align: left;
    padding: 18px 0px;
    .switch {
      position: relative;
      display: inline-block;
      height: 20px;
      width: 38px;
      input {
        opacity: 0;
        width: 0;
        height: 0;
      }
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #eaedf3;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        &.round {
          border-radius: 10px;
        }
        &.round:before {
          border-radius: 50%;
        }
      }
      .slider:before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
      }

      input:checked + .slider {
        background-color: #00a19c;
      }
      input:checked + .slider:before {
        -webkit-transform: translateX(18px);
        -ms-transform: translateX(18px);
        transform: translateX(18px);
      }
      input:disabled:checked + .slider {
        cursor: not-allowed;
        background-color: #b3e3e1;
      }
      input:disabled + .slider {
        cursor: not-allowed;
      }
    }
    &.remove {
      text-align: center;
      .close {
        height: 15.43px;
        width: 15.43px;
        border-radius: 8px;
        background-color: #6d7278;
        text-align: center;
        color: white;
        position: relative;

        &:hover {
          color: white;
          background-color: red;
        }
      }
      .icon-window-close {
        font-size: 9px;
        position: absolute;
        top: 4px;
        left: -4.3px;
      }
    }
    &.role {
      .dropdown-role-select {
        position: relative;
        display: inline-block;
        .btn-select {
          box-sizing: border-box;
          height: 38px;
          width: 156px;
          border: 1px solid #d8dce6;
          border-radius: 4px;
          background-color: #ffffff;
          display: flex;
          padding: 7px 14px 7px 16px;
          .btn-select-text {
            height: 22px;
            width: 114px;
            color: #3e3f42;
            font-family: $font_Inter;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 22px;
            text-align: left;
          }
          .btn-select-icon {
            height: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin: auto 0;
            .icon-triangle-up {
              border-bottom: 3px solid #9ea0a5;
              border-left: 3px solid transparent;
              border-right: 3px solid transparent;
            }
            .icon-triangle-down {
              border-top: 3px solid #9ea0a5;
              border-left: 3px solid transparent;
              border-right: 3px solid transparent;
            }
          }
        }
        .btn-select:disabled {
          cursor: not-allowed;
          .btn-select-text {
            color: rgba(0, 0, 0, 0.25);
          }
        }
        .dropdown-menu {
          display: none;
          position: absolute;
          border: 1px solid #d8dce6;
          border-radius: 4px;
          width: 100%;
          background: #fff;
          top: 39px;
          padding-left: 0px;
          z-index: 1000;
          .role-item {
            padding: 12px 16px 13px 16px;
            height: 41px;
            border-bottom: 1px solid #d8dce6;
            font-size: 13px;
            letter-spacing: 0;
            line-height: 16px;
            font-family: $font_Inter;
            cursor: default;
            &:last-child {
              border-bottom: none;
            }
            &:hover {
              background-color: #00a19c;
              color: #fff;
              box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
            }
          }
          &.show {
            display: block;
          }
        }
      }
    }
  }
  tr {
    border-bottom: solid 1px #eaedf3;
  }
}

#bar-buttons {
  .send-minute {
    .send-area {
      span {
        color: #ffffff;
        font-family: $font_Inter;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 17px;
      }
      .next-icon {
        position: relative;
        top: 4px;
        margin-left: 14px;
      }
      .saving-area {
        font-size: 14px;
      }
    }
  }

  .btn-cancel {
    height: 42px;
    width: 138px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
  }
}
