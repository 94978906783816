#detail-draf-confirm {
    height: auto;
    width: 576px;
    md-dialog-content{
        padding: 36px 28px 30px 36px;
        height: 100%;
    }
    .title-container{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .title {
            height: 26px;
            color: #000000;
            font-family: $font_Inter;
            font-size: 24px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 26px;
        }
        .img{
            object-fit: contain;
            margin-right: 24px;
        }
    }
    
    .contentArea{
        margin: 20px 0 30px;
        .content {
            height: 22px;
            color: rgba(0, 0, 0, 0.75);
            font-family: $font_Inter;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 22px;
        }
    }
    

    .btn-close{
        float: right;
        margin-top: 5px;
        margin-right: 5px;
        color: #9E9E9E;
    }

    .btn-border-gray{
        box-sizing: border-box;
        height: 42px;
        width: 122px;
        border: 1px solid #E2E5ED;
        border-radius: 4px;
        box-shadow: none;
        color:#787878;
        font-family: $font_Inter;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 16px;
        text-align: center;
        text-transform: none;
    }

    .btn-send, .btn-danger{
        width: 160px;
        height: 40px;
        text-transform: none;
    }
}
