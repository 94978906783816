#detail {
  width: 100%;
  max-width: 1280px;
  padding: 16px 8px 8px;
  margin: auto;
  color: rgba(0, 0, 0, 0.87);

  .header {
    margin-bottom: 8px;
  }
  .review-view {
    .header {
      flex-direction: column;
      padding: 0;
      margin-bottom: 8px;
      .right {
        display: flex;
        flex-direction: row;
        align-items: center;
        .status-mobile {
          padding: 0 4px;
        }
      }
    }
    .meeting-detail {
      padding: 8px;
      border-left: 4px solid $color_petronas_green;
      background-color: rgba(255, 255, 255, 0.7);
      .md-button {
        float: right;
      }
      .meeting-detail-item {
        .detail-title {
          font-size: 1rem;
          color: rgba(0, 0, 0, 0.54);
        }
        .detail-content {
          margin-bottom: 8px;
        }
      }
    }
  }

  .review-attachment {
    padding: 0 16px;
    margin-bottom: 40px;
    .title {
      margin-bottom: 16px;
      font-size: 16px;
    }
    .attachment {
      width: 96px;
      margin-right: 16px;
      md-icon {
        width: 48px;
        height: 48px;
        margin: auto;
        margin-bottom: 8px;
        font-size: 48px;
        line-height: normal;
      }
      .file-name {
        font-size: 1.2rem;
        text-align: center;
        max-width: 70px !important;
        margin-left: 8px;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
      }
    }
    .attachment:last-of-type {
      padding: 0;
    }
  }
  md-card {
    margin: 0 0 24px;
    md-card-title {
      position: relative;
    }
  }
  .review-discussion {
    .discussion-container {
      overflow: hidden;
      .discussion-title {
        color: $color_petronas_green;
        padding: 0 0 8px;
        margin: 0 0 16px;
        border-bottom: 2px solid $color_petronas_green;
      }
      .discussion-description-container {
        align-items: flex-start;
      }
      .discussion-description {
        width: 100%;
        p {
          margin: 0 0 8px 0;
        }
        p:last-of-type {
          margin-bottom: 0;
        }
      }
      .attachment-container {
        display: block;
        white-space: nowrap;
        overflow-x: scroll !important;
        &::-webkit-scrollbar,
        .md-virtual-repeat-scroller::-webkit-scrollbar {
          height: 8px;
        }
        &::-webkit-scrollbar-thumb,
        .md-virtual-repeat-scroller::-webkit-scrollbar-thumb {
          background-color: darkgrey;
          outline: 1px solid slategrey;
        }
        padding: 16px 4px;
        a {
          text-align: center;
        }
        .attachment {
          display: inline-block;
          position: relative;
          width: 96px;
          height: 64px;
          padding: 8px;
          margin-right: 8px;
          background: rgba(0, 0, 0, 0.04);
          box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.12), 0 0 2px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.24);
          md-icon {
            margin: 4px auto;
            min-height: 24px;
          }
          .file-name {
            font-size: 1.2rem;
            text-align: center;
            max-width: 70px !important;
            margin-left: 8px;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
            white-space: nowrap;
          }
        }
        .attachment:hover {
          box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.14), 0 0 4px rgba(0, 0, 0, 0.14), 0px 4px 8px rgba(0, 0, 0, 0.28);
        }
        // .attachment:before {
        //     content: "";
        //     position: absolute;
        //     bottom: -1px;
        //     right: -1px;
        //     border-width: 16px 16px 0 0;
        //     border-style: solid;
        //     border-color: rgba(0,0,0,.38) #fff;
        // }
      }
    }
    .discussion-container-collapse {
      height: 40px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .discussion-container-full {
      height: 100%;
    }
    @media (max-width: 959px) {
      .discussion-container-less {
        position: absolute;
        width: calc(100% - 32px);
        height: 96px;
        background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0) calc(100% - 24px),
          rgba(255, 255, 255, 0.87) 100%
        );
      }
    }
    md-card-content {
      .discussion-list {
        .list-item {
          width: 100%;
          min-height: 48px;
          padding: 16px 0;
          // border-bottom: 1px solid rgba(0,0,0,.12);
          flex-wrap: wrap;
          .assigned {
            .assignee {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            img {
              width: 36px;
              height: 36px;
              margin-left: 8px;
              background-color: $color_petronas_green;
              border-radius: 50%;
            }
            .due-date {
              color: rgba(0, 0, 0, 0.54);
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .topic {
            .topic-name {
              p,
              ol {
                margin: 0;
              }
            }
          }
        }
        .list-item:last-of-type {
          padding-bottom: 0;
        }
      }
    }
    .mobile-action-area {
      margin: 8px 0 8px;
      border-bottom: 2px solid rgba(0, 0, 0, 0.12);
      .md-button {
        line-height: normal;
        margin: 6px auto;
        white-space: normal;
        text-transform: none;
        .icon-notification {
          display: flex;
          align-items: center;
          position: relative;
          height: 36px;
          md-icon {
            margin-right: 16px;
          }
          .comment-count {
            right: 8px;
          }
        }
        .button-text {
          text-align: left;
        }
      }
    }
    .mobile-action-area:last-of-type {
      margin-bottom: 0;
    }
  }
  .comment-area {
    margin: 16px 0;
  }
  @media (max-width: 959px) {
    selected-comment {
      display: block;
    }
  }
  .comment-button {
    position: relative;
    border-radius: 0 !important;
    .comment-count {
      position: absolute;
      top: 1px;
      right: 0px;
      line-height: 16px;
      font-size: 12px;
      border-radius: 16px;
      width: 16px;
      height: 16px;
      color: white;
      background: $color_petronas_green;
      text-align: center;
    }
  }

  
}
@media (max-width: 960px) {
  .bottom-bar{
    padding: 18px 0px !important; 
  }
}

@media (min-width: 960px) {
  #detail {
    padding: 40px;
    padding-bottom: 150px;
    .header {
      margin-bottom: 16px;
    }
    .review-view {
      .header {
        flex-direction: row;
        align-items: center;
        margin-bottom: 24px;
        .right {
          flex-direction: column;
          align-items: flex-end;
        }
      }
      .meeting-detail {
        .meeting-detail-item {
          display: table-row;
          .detail-title {
            display: table-cell;
            padding: 8px 24px 8px 8px;
            font-family: "Museo Sans", sans-serif;
            font-size: 1.4rem;
            font-weight: 700;
            color: rgba(0, 0, 0, 0.38);
            text-transform: uppercase;
          }
        }
      }
    }
    .review-attachment {
      padding: 0 16px;
      margin-bottom: 40px;
      .title {
        margin-bottom: 16px;
        font-size: 16px;
      }
      .attachment {
        width: 96px;
        margin-right: 16px;
        md-icon {
          width: 48px;
          height: 48px;
          margin: auto;
          margin-bottom: 8px;
          font-size: 48px;
          line-height: normal;
        }
        .file-name {
          font-size: 1.2rem;
          text-align: center;
          display: inline !important;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: break-word;
          white-space: nowrap;
        }
      }
      .attachment:last-of-type {
        padding: 0;
      }
    }
    md-card {
      md-card-title {
        padding: 24px 24px 0;
      }
      md-card-content {
        padding: 0 24px 24px;
      }
    }
    .agenda {
      margin-bottom: 40px;
    }
    .review-discussion {
      .discussion-container {
        height: auto;
        .discussion-title {
          padding: 0 0 8px;
          margin: 0 0 16px;
          md-checkbox {
            top: -3px;
            margin: 0;
          }
        }
        .discussion-description {
          padding: 0 40px 0 0;
        }
      }
      md-card-content {
        padding-top: 40px;
        .discussion-list {
          .list-header {
            align-items: center;
            padding-bottom: 8px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            & > div {
              padding-right: 16px;
              color: rgba(0, 0, 0, 0.54);
            }
            & > div:first-of-type {
              padding-left: 40px;
            }
            & > div:last-of-type {
              padding-right: 0;
            }
          }
          .list-item-placeholder {
            background: rgba(0, 0, 0, 0.12);
            * {
              opacity: 0;
            }
          }
          .list-item {
            min-height: 48px;
            align-items: flex-start;
            padding: 16px 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            flex-wrap: wrap;
            & > div {
              padding-right: 40px;
            }
            .topic {
              align-items: flex-start;
              md-checkbox {
                width: 20px;
                max-width: 20px;
                margin: 0 24px 0 0;
              }
              .index {
                width: 24px;
                min-width: 24px;
                margin: 0 16px 0 0;
                position: relative;
              }
              .show-log:hover:after {
                content: "Log";
                position: absolute;
                width: 100%;
                left: 0;
                background: white;
                color: $color_petronas_green;
                cursor: pointer;
              }
              .topic-name {
                display: block;
              }
              p:first-of-type {
                margin-top: 0;
              }
              p:last-of-type {
                margin-bottom: 0;
              }
            }
            .date-comment {
              align-items: flex-start;
              justify-content: flex-end;
              padding-right: 0;
            }
          }
          .list-item:last-of-type {
            padding-bottom: 16px;
            margin-bottom: 24px;
          }
        }
      }
      .comment-button-container {
        width: 88px;
        min-width: 88px;
        max-width: 88px;
        align-items: center;
        text-align: center;
        display: inline-block;
        margin-top: -8px;
      }
    }
    .review-discussion:last-of-type {
      margin-bottom: 40px;
    }
    
  }
  .send-minute{
    position: relative;
    .btn-send:focus{
      & + .send-list{
        display: block;
      }
    }
    .send-list{
      position: absolute;
      display: none;
      width: 229px;
      height: 252px;
      box-shadow:  0 0 10px -2px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      background-color: white;
      top: -252px;
      right: 0px;
      z-index: 1;
      .send-option{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        border-bottom: 1px solid rgba(0,0,0,0.1);
        padding: 20px;
        color: rgba(0,0,0,0.85);
        font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue",
    Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
        font-size: 13px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 16px;
        cursor: pointer;
        .img-icon{
          margin-right: 8px;
        }
        .option-description{
          height: 28px;
          width: 158px;
          color: #6D7278;
          font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue",
    Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
          font-size: 10px;
          letter-spacing: 0;
          line-height: 14px;
          font-weight: normal;
        }
      }
      .send-option[disabled]{
        opacity: 0.3;
        cursor: not-allowed;
      }
    }
    .send-list:hover{
      display: block;
    }
  }
  
  selected-comment:not(.general-comment) {
    display: none;
    width: 100%;
  }
}

#extractBar {
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  background-color: $color_petronas_violet !important;
  .selected {
    padding-left: 8px;
  }
}

md-input-container .md-errors-spacer {
  display: none;
}

.detail-loading {
  padding: 1em;
  background: white;
  color: #777;
  align-items: center;
  justify-content: center;
  font-family: "Gill sans", sans-serif;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;

  .loader-style {
    position: absolute;
  }
}

#extractBar.ng-hide {
  opacity: 0;
}

#extractBar.ng-hide-add,
#extractBar.ng-hide-remove {
  transition: all ease-out 0.15s;
}

.done:hover:after,
.confirm:hover:after {
  content: "Undo?";
  position: absolute;
  width: 100%;
  left: 0;
  background: white;
  color: $color_petronas_yellow;
  cursor: pointer;
}

#bar-buttons {
  display: flex;
  align-items: center;
  width: 100%;
  // .toolbar-button {
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     text-transform: none;
  // }
}

.not-supported {
  padding: 2px 8px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.87);
  color: $color_petronas_yellow;
}
@media (max-width: 320px) {
  .md-dialog-container .md-button {
    min-width: 90px !important;
  }
  .md-dialog-container button span {
    font-size: 9px !important;
  }
}
.md-icon-button.md-raised.md-button.md-default-theme.md-ink-ripple.md-accent {
  margin: 0px !important;
  padding: 0px !important;
}

.btn-preview{
  box-sizing: border-box;
  height: 42px;
  width: 138px;
  border: 1px solid #777;
  border-radius: 4px;

  color: rgba(0,0,0,0.5);
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
}

.btn-send{
  box-sizing: border-box;
  height: 42px;
  width: 138px;
  border-radius: 4px;
  text-align: center;
  background-color: $color_petronas_green;
  color: #FFFFFF;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  text-transform: none;
  margin: 4px 6px;
  &:hover{
    background-color: $color_petronas_green !important;
  }
  &:disabled{
    opacity: 0.38;
    cursor: not-allowed;
  }
}
.btn-danger{
  box-sizing: border-box;
  height: 42px;
  width: 138px;
  border-radius: 4px;
  text-align: center;
  background-color: #D50000;
  color: #FFFFFF;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  &:hover{
    background-color: #D50000 !important;
  }
}
.btn-delete {
  box-sizing: border-box;
  height: 42px;
  width: 138px;
  border: 1px solid #d50000;
  border-radius: 4px;

  color: #D50000;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
}

.bottom-bar{
  height: 78px;
  background-color: #FFFFFF;
  box-shadow: 0 -5px 6px 0 rgba(0,0,0,0.08);
  padding: 18px 60px;
}

.send-area{
  display: flex;
  justify-content: center;
  position: relative;
  .send-icon{
    margin-left: 8px;
    height: 11px;
    position: relative;
    top: 2px;
  }
}