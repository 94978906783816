#message-dialog{
	height: auto;
	width: 576px;
	md-dialog-content{
        padding: 36px 28px 0 36px;
        height: 100%;
    }
	.title-container{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .title {
            height: 26px;
            color: #000000;
            font-family: $font_Inter;
            font-size: 24px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 26px;
        }
        .img{
            object-fit: contain;
            margin-right: 24px;
        }
    }
    
    .contentArea{
        margin: 20px 0 30px;
        .content {
            height: 22px;
            color: rgba(0, 0, 0, 0.75);
            font-family: $font_Inter;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 22px;
        }
	}
	.btn-close{
        float: right;
        margin-top: 5px;
        margin-right: 5px;
        color: #9E9E9E;
    }
	.btn-send{
		height: 40px;
  		width: 120px;
		margin-right: 28px;
    	margin-bottom: 28px;
	}

    .icon-close-circle{
        line-height: 48px;
        margin-left: 0px;
        margin-right: 20px;
    }
}