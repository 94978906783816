
.angularjs-datetime-picker {
	color: #333;
	font: normal 14px sans-serif;
	display: inline-block;
	background: #fff;
	height: auto;
	width: 298px;
	border: 1px solid #D8DCE6;
	border-radius: 4px;
	box-shadow: 0 1px 3px 0 rgba(0,0,0,0.04);
	z-index: 999;
	.select-date {
		padding: 16px 13px 16px 16px;
		display: flex;
		justify-content: space-between;
		label {
			color: #999999;
			font-size: 10px;
			letter-spacing: 0;
			line-height: 12px;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 1ch;
		}
		span{
			box-sizing: border-box;
			height: 36px;
			width: 181px;
			border: 1px solid #D8DCE6;
			border-radius: 4px;
			background-color: #FFFFFF;
			text-align: right;
			color: #3E3F42;
			font-family: $font_Inter;
			font-size: 12px;
			letter-spacing: 0;
			line-height: 12px;
			display: flex;
			align-items: center;
			flex-direction: row-reverse;
			padding: 12px 14px;
		}
	}
	.adp-month {
		text-align: center;
		line-height: 22px;
		padding: 0px 16px;
		text-transform: capitalize;
		font-weight: bold;
		position: relative;
		button {
			color: #555;
			font: normal 14px sans-serif;
			outline: none;
			position: absolute;
			background: transparent;
			border: none;
			cursor: pointer;
			&:hover {
				color: #333;
			}
		}
		button.adp-prev {
			left: 27px;
			>img {
				position: relative;
				top: 7px;
			}
		}
		button.adp-next {
			right: 27px;
			>img {
				position: relative;
				top: 7px;
			}
		}
		span {
			opacity: 0.75;
			color: #212B36;
			font-size: 12px;
			font-weight: 600;
			letter-spacing: 0;
			line-height: 24px;
		}
	}
	>.adp-days {
		width: 266px;
		height: auto;
		margin: 10px 16px 16px 16px;
		text-align: center;
		.adp-day-of-week {
			box-sizing: border-box;
			-moz-box-sizing: border-box;
			border: 1px solid transparent;
			float: left;
			width: 38px;
			font-weight: 500;
			text-transform: capitalize;
			color: #637381;
			font-size: 12px;
			letter-spacing: 0;
			line-height: 16px;
			margin-bottom: 6px;
		}
		.adp-day {
			box-sizing: border-box;
			-moz-box-sizing: border-box;
			float: left;
			width: 38px;
			// border: 1px solid #C4CDD5;
			box-shadow: 1px 0 0 0 #c4cdd5, 0 1px 0 0 #c4cdd5, 1px 1px 0 0 #c4cdd5, /* Just to fix the corner */ 1px 0 0 0 #c4cdd5 inset, 0 1px 0 0 #c4cdd5 inset;
			color: #637381;
			font-size: 14px;
			letter-spacing: 0;
			line-height: 20px;
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 1ch;
			&:not(.selectable) {
				cursor: default;
				width: 38px;
				height: 36px;
				>span {
					opacity: 0.15;
				}
			}
		}
		.adp-day.selectable {
			cursor: pointer;
			width: 38px;
			height: 36px;
			&:hover {
				background:#4DBDBA;
				color: #fff;
			}
		}
		.adp-day.selected {
			background-color: #00A19C;
			color: #fff;
		}
		.adp-day.today {
			background-color: #02ddd6;
			color: #fff;
		}
		&:after {
			content: '';
			display: block;
			clear: left;
			height: 0;
		}
	}
	hr{
		border-top: 1px solid #d8dce6;
		border-bottom: none;
	}
	input[type=range] {
		width: 150px;
	}
	.btn-cf {
		height: 40px;
		width: 267px;
		border-radius: 4px;
		background-color: #00A19C;
		color: #fff;
		margin: 0 16px 16px;
	}
}
.adp-time {
	padding: 9.5px 13px 9.5px 16px;
	.start-time {
		display: flex;
		margin-bottom: 4px;
	}
	.end-time {
		display: flex;
		margin-bottom: 4px;
	}
}
.time-label {
	width: 100px;
	color: #9EA0A5;
	font-size: 10px;
	letter-spacing: 0;
	line-height: 12px;
	padding-top: 9px;
}
.datepicker-dropdown-select {
	.btn-select {
		position: relative;
		box-sizing: border-box;
		height: 36px;
		width: 55px;
		border: 1px solid #D8DCE6;
		border-radius: 4px;
		background-color: #FFFFFF;
	}
	.btn-select-text{
		text-align: right;
		padding-right: 20px;
		font-size: 12px;
		color: #3E3F42;
	}
	.btn-select-icon{
		img{
			top: 11px;
			position: absolute;
			right: 6px;
		}
	}
	position: relative;
	display: inline-block;
	.datepicker-dropdown-menu {
    	display: none;
		position: absolute;
		border: 1px solid #d8dce6;
		border-radius: 4px;
		width: 125%;
		background: #fff;
		bottom: 0px;
		padding-left: 0;
		z-index: 1000;
		max-height: 140px;
    	overflow: auto;
		.time-item {
			height: 27px;
			font-size: 13px;
			letter-spacing: 0;
			line-height: 16px;
			cursor: default;
			display: flex;
			align-items: center;
      justify-content: center;
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        background-color: #00a19c;
        color: #fff;
        box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
      }
    }
    &.show{
      display: block;
    }
	}
}
.time-container {
	display: flex;
	.time-period {
		margin-left: 8px;
	}
}
