#managaAccessPanel{
    #manageAccess-members{
        tbody{
            display:block;
            max-height: 300px;
            overflow-y: auto;
        }
        tr{
            display:table;
            width:100%;
            table-layout:fixed;
        }
        .container {
            .avatar-wrapper{
                .image {
                    height: 36px !important;
                }
            }
        }
    }
    .members{
        width: 100%;
    }

    .action-container{
        margin-top: 20px;
        .btn-send{
            margin: 0;
            float: right;
        }
    }
}
